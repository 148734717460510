/* eslint-disable */
import { CustomPIXIComponent, applyDisplayObjectProps } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import CSS from "csstype";

export interface RectangleProperties extends Partial<PIXI.Graphics> {
  x: number;
  y: number;
  width: number;
  height: number;
  lineColor: number;
  lineWidth: number;
  lineAlpha: number;
  fillColor: number;
  fillAlpha: number;
  cursor?: CSS.Property.Cursor;
}

export default CustomPIXIComponent(
  {
    customDisplayObject: (props: RectangleProperties) => new PIXI.Graphics(),
    customApplyProps: function (
      instance: PIXI.Graphics,
      oldProps: RectangleProperties | undefined,
      newProps: RectangleProperties
    ) {
      instance.interactive = true;
      if (oldProps !== undefined) {
        instance.clear();
      }
      const { fillColor, x, y, width, height, lineColor, lineWidth, lineAlpha, fillAlpha, cursor, ...newPropsRest } =
        newProps;

      instance.beginFill(fillColor, fillAlpha);
      instance.lineStyle(lineWidth, lineColor, lineAlpha, 0);
      instance.drawRect(x, y, width, height);
      instance.endFill();
      instance.hitArea = new PIXI.Rectangle(x, y, width, height);

      if (cursor) {
        instance.cursor = cursor;
      } else {
        instance.cursor = "pointer";
      }

      if (oldProps) {
        const {
          fillColor: oldFill,
          x: oldX,
          y: oldY,
          width: oldWidth,
          height: oldHeight,
          lineColor: oldLineColour,
          lineWidth: oldLineWidth,
          fillAlpha: oldFillAlpha,
          cursor: oldCursor,
          ...oldPropsRest
        } = oldProps;

        applyDisplayObjectProps(instance.name, instance, oldPropsRest, newPropsRest);
      }
    },
  },
  "Rectangle"
);
