import React, { FC, useState } from "react";
import { useStores } from "../../hooks/useStores.hook";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { FreneticityButton } from "../styledElements";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";
import { ClassifyingDetectorClassTypeNumber, classLookup } from "../../workers/utils";
import { observer } from "mobx-react";

interface TogglePlayUntilNextCVCrossingClassesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TogglePlayUntilNextCVCrossingClassesModal: FC<TogglePlayUntilNextCVCrossingClassesModalProps> = observer(
  ({ isOpen, onClose }) => {
    const { playerUIStore } = useStores();
    const [toggleAll, setToggleAll] = useState(false);

    const classes = Object.keys(ClassifyingDetectorClassTypes)
      .filter(className => {
        return ClassifyingDetectorClassTypes[className] < 10000 && ClassifyingDetectorClassTypes[className] > 0;
      })
      .map(className => {
        const classNumber = ClassifyingDetectorClassTypes[className] as ClassifyingDetectorClassTypeNumber;
        return (
          <FormControlLabel
            key={classNumber}
            control={
              <Checkbox
                onChange={e => {
                  playerUIStore.setPlayUntilNextCVCrossingClass(classNumber, e.target.checked);
                }}
                checked={playerUIStore.playUntilNextCVCrossingClasses.has(classNumber)}
              />
            }
            label={classLookup[classNumber]}
          />
        );
      });
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <Typography>Pause when CV crossing has class:</Typography>
          <Button
            onClick={e => {
              setToggleAll(prev => !prev);
              Object.keys(ClassifyingDetectorClassTypes)
                .filter(className => {
                  return (
                    ClassifyingDetectorClassTypes[className] < 10000 && ClassifyingDetectorClassTypes[className] > 0
                  );
                })
                .forEach(className => {
                  playerUIStore.setPlayUntilNextCVCrossingClass(ClassifyingDetectorClassTypes[className], toggleAll);
                });
            }}
          >
            Toggle All
          </Button>
        </DialogTitle>
        <DialogContent>
          <FormGroup>{classes}</FormGroup>
        </DialogContent>
      </Dialog>
    );
  }
);
