export type SearchFragment = {
  exact: boolean;
  fragment: string;
  regex: RegExp | undefined;
};

export const getSearchFragments = (searchString: string) => {
  return searchString
    .split(" ")
    .filter(frag => frag !== "")
    .map((inFrag: string): SearchFragment => {
      const exact = inFrag.endsWith('"') && inFrag.startsWith('"');
      const isRegex = inFrag.endsWith("`") && inFrag.startsWith("`");
      const frag = exact || isRegex ? inFrag.slice(1, inFrag.length - 1) : inFrag;

      let regex: undefined | RegExp;
      if (isRegex) {
        try {
          regex = new RegExp(frag, "g");
        } catch (e) {
          console.log(e);
          regex = new RegExp(/nothing/, "g");
        }
      }
      return { fragment: frag, exact: exact, regex };
    });
};

export const getFilteredResults = <T>(
  list: T[],
  searchString: string,
  fieldsToSearch: { [fieldName: string]: boolean }
) => {
  const searchFragments = getSearchFragments(searchString);

  return list.filter(entity => {
    const alreadyMatchedFragments = new Set<string>();
    const matchCount = Object.entries(fieldsToSearch)
      .map(([field, shouldFilter]): number => {
        if (!shouldFilter) {
          return 0;
        }
        let sum = 0;
        for (const fragment of searchFragments) {
          if (fragment.exact) {
            if (typeof entity[field] !== "string") {
              const stringVal = (entity[field] as any)?.toString() ?? "";
              if (!alreadyMatchedFragments.has(fragment.fragment) && stringVal === fragment.fragment) {
                alreadyMatchedFragments.add(fragment.fragment);
                sum += 1;
              }
            } else {
              if (!alreadyMatchedFragments.has(fragment.fragment) && entity[field] === fragment.fragment) {
                alreadyMatchedFragments.add(fragment.fragment);
                sum += 1;
              }
            }
          } else if (fragment.regex !== undefined) {
            if (typeof entity[field] !== "string") {
              const stringVal = (entity[field] as any)?.toString() ?? "";
              if (!alreadyMatchedFragments.has(fragment.regex.source) && fragment.regex.test(stringVal)) {
                alreadyMatchedFragments.add(fragment.regex.source);
                sum += 1;
              }
            } else {
              if (!alreadyMatchedFragments.has(fragment.regex.source) && fragment.regex.test(entity[field])) {
                alreadyMatchedFragments.add(fragment.regex.source);
                sum += 1;
              }
            }
          } else {
            if (typeof entity[field] !== "string") {
              const stringVal = (entity[field] as any)?.toString() ?? "";
              if (!alreadyMatchedFragments.has(fragment.fragment) && stringVal.includes(fragment.fragment)) {
                alreadyMatchedFragments.add(fragment.fragment);
                sum += 1;
              }
            } else {
              if (!alreadyMatchedFragments.has(fragment.fragment) && entity[field].includes(fragment.fragment)) {
                alreadyMatchedFragments.add(fragment.fragment);
                sum += 1;
              }
            }
          }
        }
        return sum;
      })
      .reduce((prev, curr) => prev + curr);

    let shouldInclude = matchCount > 0;
    if (searchFragments.length > 1) {
      shouldInclude = matchCount >= searchFragments.length;
    }

    return shouldInclude;
  });
};
