/* eslint-disable */
// source: vivacity/config_v2/smart_junctions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.Controller', null, global);
goog.exportSymbol('proto.vivacity.config_v2.ControllerStream', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Country', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Phase', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Phase.AppearanceType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Phase.TerminationType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Phase.Type', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Region', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Stage', null, global);
goog.exportSymbol('proto.vivacity.config_v2.StageToPhase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Country.displayName = 'proto.vivacity.config_v2.Country';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Region = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Region, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Region.displayName = 'proto.vivacity.config_v2.Region';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Controller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Controller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Controller.displayName = 'proto.vivacity.config_v2.Controller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.ControllerStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.ControllerStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.ControllerStream.displayName = 'proto.vivacity.config_v2.ControllerStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.StageToPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.StageToPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.StageToPhase.displayName = 'proto.vivacity.config_v2.StageToPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Stage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Stage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Stage.displayName = 'proto.vivacity.config_v2.Stage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Phase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Phase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Phase.displayName = 'proto.vivacity.config_v2.Phase';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    code: (f = msg.getCode()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Country}
 */
proto.vivacity.config_v2.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Country;
  return proto.vivacity.config_v2.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Country}
 */
proto.vivacity.config_v2.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Country.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Country} returns this
*/
proto.vivacity.config_v2.Country.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Country} returns this
 */
proto.vivacity.config_v2.Country.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Country.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.StringValue code = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Country.prototype.getCode = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Country} returns this
*/
proto.vivacity.config_v2.Country.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Country} returns this
 */
proto.vivacity.config_v2.Country.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Country.prototype.hasCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.StringValue name = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Country.prototype.getName = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Country} returns this
*/
proto.vivacity.config_v2.Country.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Country} returns this
 */
proto.vivacity.config_v2.Country.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Country.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Region.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Region.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Region} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Region.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    countryId: (f = msg.getCountryId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    name: (f = msg.getName()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    nameAbbreviation: (f = msg.getNameAbbreviation()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    code: (f = msg.getCode()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    extraValues: (f = msg.getExtraValues()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Region}
 */
proto.vivacity.config_v2.Region.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Region;
  return proto.vivacity.config_v2.Region.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Region} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Region}
 */
proto.vivacity.config_v2.Region.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setCountryId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setNameAbbreviation(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setExtraValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Region.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Region.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Region} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Region.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getCountryId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNameAbbreviation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtraValues();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Region.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id country_id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Region.prototype.getCountryId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setCountryId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearCountryId = function() {
  return this.setCountryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasCountryId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.StringValue name = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Region.prototype.getName = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.StringValue name_abbreviation = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Region.prototype.getNameAbbreviation = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setNameAbbreviation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearNameAbbreviation = function() {
  return this.setNameAbbreviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasNameAbbreviation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.StringValue code = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Region.prototype.getCode = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue extra_values = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.Region.prototype.getExtraValues = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.Region} returns this
*/
proto.vivacity.config_v2.Region.prototype.setExtraValues = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Region} returns this
 */
proto.vivacity.config_v2.Region.prototype.clearExtraValues = function() {
  return this.setExtraValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Region.prototype.hasExtraValues = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Controller.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Controller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Controller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Controller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    name: (f = msg.getName()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    controllerSiteId: (f = msg.getControllerSiteId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    regionId: (f = msg.getRegionId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    viuId: (f = msg.getViuId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    isS4tc: (f = msg.getIsS4tc()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    isSj: (f = msg.getIsSj()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    ipAddress: (f = msg.getIpAddress()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    controllerHardwareModelId: (f = msg.getControllerHardwareModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    issueNumber: (f = msg.getIssueNumber()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    extraValues: (f = msg.getExtraValues()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Controller}
 */
proto.vivacity.config_v2.Controller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Controller;
  return proto.vivacity.config_v2.Controller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Controller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Controller}
 */
proto.vivacity.config_v2.Controller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setControllerSiteId(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setRegionId(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setViuId(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsS4tc(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsSj(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setIpAddress(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setControllerHardwareModelId(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setIssueNumber(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setExtraValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Controller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Controller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Controller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Controller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControllerSiteId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegionId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getViuId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getIsS4tc();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsSj();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIpAddress();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControllerHardwareModelId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getIssueNumber();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getExtraValues();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Controller.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.StringValue name = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Controller.prototype.getName = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.StringValue controller_site_id = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Controller.prototype.getControllerSiteId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setControllerSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearControllerSiteId = function() {
  return this.setControllerSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasControllerSiteId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id region_id = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Controller.prototype.getRegionId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setRegionId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearRegionId = function() {
  return this.setRegionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point location = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.Controller.prototype.getLocation = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id viu_id = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Controller.prototype.getViuId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setViuId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearViuId = function() {
  return this.setViuId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasViuId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_s4tc = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.Controller.prototype.getIsS4tc = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setIsS4tc = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearIsS4tc = function() {
  return this.setIsS4tc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasIsS4tc = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_sj = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.Controller.prototype.getIsSj = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setIsSj = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearIsSj = function() {
  return this.setIsSj(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasIsSj = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.StringValue ip_address = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Controller.prototype.getIpAddress = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setIpAddress = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearIpAddress = function() {
  return this.setIpAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasIpAddress = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id controller_hardware_model_id = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Controller.prototype.getControllerHardwareModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setControllerHardwareModelId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearControllerHardwareModelId = function() {
  return this.setControllerHardwareModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasControllerHardwareModelId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value issue_number = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.Controller.prototype.getIssueNumber = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setIssueNumber = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearIssueNumber = function() {
  return this.setIssueNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasIssueNumber = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue extra_values = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.Controller.prototype.getExtraValues = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.Controller} returns this
*/
proto.vivacity.config_v2.Controller.prototype.setExtraValues = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Controller} returns this
 */
proto.vivacity.config_v2.Controller.prototype.clearExtraValues = function() {
  return this.setExtraValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Controller.prototype.hasExtraValues = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.ControllerStream.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.ControllerStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.ControllerStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.ControllerStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    streamIndex: (f = msg.getStreamIndex()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    controllerId: (f = msg.getControllerId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    takeoverBitId: (f = msg.getTakeoverBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    controlBitId: (f = msg.getControlBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    velocityId: (f = msg.getVelocityId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    isEnabled: (f = msg.getIsEnabled()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    isForceEnabled: (f = msg.getIsForceEnabled()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    isScheduleEnabled: (f = msg.getIsScheduleEnabled()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    isDryRunning: (f = msg.getIsDryRunning()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    isCommissioning: (f = msg.getIsCommissioning()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    multipleSensorsCriticalThreshold: (f = msg.getMultipleSensorsCriticalThreshold()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    extraValues: (f = msg.getExtraValues()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.ControllerStream}
 */
proto.vivacity.config_v2.ControllerStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.ControllerStream;
  return proto.vivacity.config_v2.ControllerStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.ControllerStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.ControllerStream}
 */
proto.vivacity.config_v2.ControllerStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setStreamIndex(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setControllerId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setTakeoverBitId(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setControlBitId(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setVelocityId(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsEnabled(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsForceEnabled(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsScheduleEnabled(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsDryRunning(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsCommissioning(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setMultipleSensorsCriticalThreshold(value);
      break;
    case 14:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setExtraValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.ControllerStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.ControllerStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.ControllerStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.ControllerStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getStreamIndex();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getControllerId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getTakeoverBitId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getControlBitId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getVelocityId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsForceEnabled();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsScheduleEnabled();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDryRunning();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsCommissioning();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getMultipleSensorsCriticalThreshold();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExtraValues();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value stream_index = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getStreamIndex = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setStreamIndex = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearStreamIndex = function() {
  return this.setStreamIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasStreamIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id controller_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getControllerId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setControllerId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearControllerId = function() {
  return this.setControllerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasControllerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point location = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getLocation = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id takeover_bit_id = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getTakeoverBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setTakeoverBitId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearTakeoverBitId = function() {
  return this.setTakeoverBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasTakeoverBitId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id control_bit_id = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getControlBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setControlBitId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearControlBitId = function() {
  return this.setControlBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasControlBitId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id velocity_id = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getVelocityId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setVelocityId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearVelocityId = function() {
  return this.setVelocityId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasVelocityId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_enabled = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getIsEnabled = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setIsEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearIsEnabled = function() {
  return this.setIsEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasIsEnabled = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_force_enabled = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getIsForceEnabled = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setIsForceEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearIsForceEnabled = function() {
  return this.setIsForceEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasIsForceEnabled = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_schedule_enabled = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getIsScheduleEnabled = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setIsScheduleEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearIsScheduleEnabled = function() {
  return this.setIsScheduleEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasIsScheduleEnabled = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_dry_running = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getIsDryRunning = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setIsDryRunning = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearIsDryRunning = function() {
  return this.setIsDryRunning(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasIsDryRunning = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_commissioning = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getIsCommissioning = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setIsCommissioning = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearIsCommissioning = function() {
  return this.setIsCommissioning(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasIsCommissioning = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value multiple_sensors_critical_threshold = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getMultipleSensorsCriticalThreshold = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setMultipleSensorsCriticalThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearMultipleSensorsCriticalThreshold = function() {
  return this.setMultipleSensorsCriticalThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasMultipleSensorsCriticalThreshold = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue extra_values = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.ControllerStream.prototype.getExtraValues = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
*/
proto.vivacity.config_v2.ControllerStream.prototype.setExtraValues = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.ControllerStream} returns this
 */
proto.vivacity.config_v2.ControllerStream.prototype.clearExtraValues = function() {
  return this.setExtraValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.ControllerStream.prototype.hasExtraValues = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.StageToPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.StageToPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.StageToPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.StageToPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseId: (f = msg.getPhaseId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    stageId: (f = msg.getStageId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    isPhaseDemandingStage: (f = msg.getIsPhaseDemandingStage()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.StageToPhase}
 */
proto.vivacity.config_v2.StageToPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.StageToPhase;
  return proto.vivacity.config_v2.StageToPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.StageToPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.StageToPhase}
 */
proto.vivacity.config_v2.StageToPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setPhaseId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setStageId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setIsPhaseDemandingStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.StageToPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.StageToPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.StageToPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.StageToPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getStageId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getIsPhaseDemandingStage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id phase_id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.StageToPhase.prototype.getPhaseId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
*/
proto.vivacity.config_v2.StageToPhase.prototype.setPhaseId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
 */
proto.vivacity.config_v2.StageToPhase.prototype.clearPhaseId = function() {
  return this.setPhaseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.StageToPhase.prototype.hasPhaseId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id stage_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.StageToPhase.prototype.getStageId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
*/
proto.vivacity.config_v2.StageToPhase.prototype.setStageId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
 */
proto.vivacity.config_v2.StageToPhase.prototype.clearStageId = function() {
  return this.setStageId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.StageToPhase.prototype.hasStageId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue is_phase_demanding_stage = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.StageToPhase.prototype.getIsPhaseDemandingStage = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
*/
proto.vivacity.config_v2.StageToPhase.prototype.setIsPhaseDemandingStage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.StageToPhase} returns this
 */
proto.vivacity.config_v2.StageToPhase.prototype.clearIsPhaseDemandingStage = function() {
  return this.setIsPhaseDemandingStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.StageToPhase.prototype.hasIsPhaseDemandingStage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Stage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Stage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Stage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Stage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    stageNumber: (f = msg.getStageNumber()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    controllerStreamId: (f = msg.getControllerStreamId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    commandBitId: (f = msg.getCommandBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    responseBitId: (f = msg.getResponseBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    demandBitId: (f = msg.getDemandBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    demandResponseBitId: (f = msg.getDemandResponseBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    longStageThresholdSeconds: (f = msg.getLongStageThresholdSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedStageThresholdSeconds: (f = msg.getUnservedStageThresholdSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    longStageThresholdOffPeakSeconds: (f = msg.getLongStageThresholdOffPeakSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    longStageThresholdOvernightSeconds: (f = msg.getLongStageThresholdOvernightSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Stage}
 */
proto.vivacity.config_v2.Stage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Stage;
  return proto.vivacity.config_v2.Stage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Stage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Stage}
 */
proto.vivacity.config_v2.Stage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setStageNumber(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setControllerStreamId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setCommandBitId(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setResponseBitId(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setDemandBitId(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setDemandResponseBitId(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setLongStageThresholdSeconds(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedStageThresholdSeconds(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setLongStageThresholdOffPeakSeconds(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setLongStageThresholdOvernightSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Stage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Stage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Stage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Stage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getStageNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getCommandBitId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getResponseBitId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getDemandBitId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getDemandResponseBitId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getLongStageThresholdSeconds();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedStageThresholdSeconds();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLongStageThresholdOffPeakSeconds();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLongStageThresholdOvernightSeconds();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value stage_number = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.Stage.prototype.getStageNumber = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setStageNumber = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearStageNumber = function() {
  return this.setStageNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasStageNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id controller_stream_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getControllerStreamId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setControllerStreamId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearControllerStreamId = function() {
  return this.setControllerStreamId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasControllerStreamId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id command_bit_id = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getCommandBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setCommandBitId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearCommandBitId = function() {
  return this.setCommandBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasCommandBitId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id response_bit_id = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getResponseBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setResponseBitId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearResponseBitId = function() {
  return this.setResponseBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasResponseBitId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id demand_bit_id = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getDemandBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setDemandBitId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearDemandBitId = function() {
  return this.setDemandBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasDemandBitId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id demand_response_bit_id = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Stage.prototype.getDemandResponseBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setDemandResponseBitId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearDemandResponseBitId = function() {
  return this.setDemandResponseBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasDemandResponseBitId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value long_stage_threshold_seconds = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Stage.prototype.getLongStageThresholdSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setLongStageThresholdSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearLongStageThresholdSeconds = function() {
  return this.setLongStageThresholdSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasLongStageThresholdSeconds = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_stage_threshold_seconds = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Stage.prototype.getUnservedStageThresholdSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setUnservedStageThresholdSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearUnservedStageThresholdSeconds = function() {
  return this.setUnservedStageThresholdSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasUnservedStageThresholdSeconds = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value long_stage_threshold_off_peak_seconds = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Stage.prototype.getLongStageThresholdOffPeakSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setLongStageThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearLongStageThresholdOffPeakSeconds = function() {
  return this.setLongStageThresholdOffPeakSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasLongStageThresholdOffPeakSeconds = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value long_stage_threshold_overnight_seconds = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Stage.prototype.getLongStageThresholdOvernightSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Stage} returns this
*/
proto.vivacity.config_v2.Stage.prototype.setLongStageThresholdOvernightSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Stage} returns this
 */
proto.vivacity.config_v2.Stage.prototype.clearLongStageThresholdOvernightSeconds = function() {
  return this.setLongStageThresholdOvernightSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Stage.prototype.hasLongStageThresholdOvernightSeconds = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Phase.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Phase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Phase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Phase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    phaseLetter: (f = msg.getPhaseLetter()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    minGreenSeconds: (f = msg.getMinGreenSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    demandResponseBitId: (f = msg.getDemandResponseBitId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    controllerId: (f = msg.getControllerId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    unservedMaximumPhaseThresholdPeakSeconds: (f = msg.getUnservedMaximumPhaseThresholdPeakSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedMaximumPhaseThresholdOffPeakSeconds: (f = msg.getUnservedMaximumPhaseThresholdOffPeakSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedMaximumPhaseThresholdOvernightSeconds: (f = msg.getUnservedMaximumPhaseThresholdOvernightSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedDemandedPhaseThresholdPeakSeconds: (f = msg.getUnservedDemandedPhaseThresholdPeakSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedDemandedPhaseThresholdOffPeakSeconds: (f = msg.getUnservedDemandedPhaseThresholdOffPeakSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    unservedDemandedPhaseThresholdOvernightSeconds: (f = msg.getUnservedDemandedPhaseThresholdOvernightSeconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    typeId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    appearanceTypeId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    terminationTypeId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pedestrianDemandDelayMilliseconds: (f = msg.getPedestrianDemandDelayMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    pedestrianDemandHoldMilliseconds: (f = msg.getPedestrianDemandHoldMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    pedestrianClearanceMaxMilliseconds: (f = msg.getPedestrianClearanceMaxMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    pedestrianClearanceDelayGapChangeMilliseconds: (f = msg.getPedestrianClearanceDelayGapChangeMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    pedestrianClearanceDelayMaxGapChangeMilliseconds: (f = msg.getPedestrianClearanceDelayMaxGapChangeMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    pedestrianClearanceMinRedMilliseconds: (f = msg.getPedestrianClearanceMinRedMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Phase}
 */
proto.vivacity.config_v2.Phase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Phase;
  return proto.vivacity.config_v2.Phase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Phase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Phase}
 */
proto.vivacity.config_v2.Phase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setPhaseLetter(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setMinGreenSeconds(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setDemandResponseBitId(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setControllerId(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedMaximumPhaseThresholdPeakSeconds(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedMaximumPhaseThresholdOffPeakSeconds(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedMaximumPhaseThresholdOvernightSeconds(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedDemandedPhaseThresholdPeakSeconds(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedDemandedPhaseThresholdOffPeakSeconds(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setUnservedDemandedPhaseThresholdOvernightSeconds(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.config_v2.Phase.Type} */ (reader.readEnum());
      msg.setTypeId(value);
      break;
    case 14:
      var value = /** @type {!proto.vivacity.config_v2.Phase.AppearanceType} */ (reader.readEnum());
      msg.setAppearanceTypeId(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.config_v2.Phase.TerminationType} */ (reader.readEnum());
      msg.setTerminationTypeId(value);
      break;
    case 16:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianDemandDelayMilliseconds(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianDemandHoldMilliseconds(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianClearanceMaxMilliseconds(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianClearanceDelayGapChangeMilliseconds(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianClearanceDelayMaxGapChangeMilliseconds(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setPedestrianClearanceMinRedMilliseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Phase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Phase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Phase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Phase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getPhaseLetter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMinGreenSeconds();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getDemandResponseBitId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getControllerId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getUnservedMaximumPhaseThresholdPeakSeconds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedMaximumPhaseThresholdOffPeakSeconds();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedMaximumPhaseThresholdOvernightSeconds();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedDemandedPhaseThresholdPeakSeconds();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedDemandedPhaseThresholdOffPeakSeconds();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnservedDemandedPhaseThresholdOvernightSeconds();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.Phase.Type} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.Phase.AppearanceType} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.Phase.TerminationType} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getPedestrianDemandDelayMilliseconds();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPedestrianDemandHoldMilliseconds();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPedestrianClearanceMaxMilliseconds();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPedestrianClearanceDelayGapChangeMilliseconds();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPedestrianClearanceDelayMaxGapChangeMilliseconds();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPedestrianClearanceMinRedMilliseconds();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.Phase.Type = {
  UNKNOWN: 0,
  TRAFFIC: 1,
  FILTER_ARROW: 2,
  INDICATIVE_ARROW: 3,
  DUMMY: 4,
  SWITCHED_SIGN: 5,
  PEDESTRIAN: 6,
  PUFFIN: 7,
  TOUCAN_NEAR_SIDE: 8,
  TOUCAN_FAR_SIDE: 9
};

/**
 * @enum {number}
 */
proto.vivacity.config_v2.Phase.AppearanceType = {
  ALWAYS: 0,
  DEMANDED_AT_START_OF_STAGE: 1,
  DEMANDED_UNTIL_END_OF_STAGE: 2,
  DEMANDED_DURING_STAGE_UNTIL_WINDOW_TIME_EXPIRY: 3
};

/**
 * @enum {number}
 */
proto.vivacity.config_v2.Phase.TerminationType = {
  AT_END_OF_STAGE: 0,
  ASSOCIATED_PHASE_GAINS_ROW: 1,
  ASSOCIATED_PHASE_LOSES_ROW: 2,
  AT_END_OF_MIN_GREEN: 4,
  AT_END_OF_MAX_GREEN: 5,
  SUBJECT_TO_SPECIAL_CONDITIONING: 6
};

/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Phase.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.StringValue phase_letter = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Phase.prototype.getPhaseLetter = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPhaseLetter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPhaseLetter = function() {
  return this.setPhaseLetter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPhaseLetter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value min_green_seconds = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getMinGreenSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setMinGreenSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearMinGreenSeconds = function() {
  return this.setMinGreenSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasMinGreenSeconds = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id demand_response_bit_id = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Phase.prototype.getDemandResponseBitId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setDemandResponseBitId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearDemandResponseBitId = function() {
  return this.setDemandResponseBitId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasDemandResponseBitId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id controller_id = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.Phase.prototype.getControllerId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setControllerId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearControllerId = function() {
  return this.setControllerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasControllerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_maximum_phase_threshold_peak_seconds = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedMaximumPhaseThresholdPeakSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedMaximumPhaseThresholdPeakSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedMaximumPhaseThresholdPeakSeconds = function() {
  return this.setUnservedMaximumPhaseThresholdPeakSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedMaximumPhaseThresholdPeakSeconds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_maximum_phase_threshold_off_peak_seconds = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedMaximumPhaseThresholdOffPeakSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedMaximumPhaseThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedMaximumPhaseThresholdOffPeakSeconds = function() {
  return this.setUnservedMaximumPhaseThresholdOffPeakSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedMaximumPhaseThresholdOffPeakSeconds = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_maximum_phase_threshold_overnight_seconds = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedMaximumPhaseThresholdOvernightSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedMaximumPhaseThresholdOvernightSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedMaximumPhaseThresholdOvernightSeconds = function() {
  return this.setUnservedMaximumPhaseThresholdOvernightSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedMaximumPhaseThresholdOvernightSeconds = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_demanded_phase_threshold_peak_seconds = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedDemandedPhaseThresholdPeakSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedDemandedPhaseThresholdPeakSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedDemandedPhaseThresholdPeakSeconds = function() {
  return this.setUnservedDemandedPhaseThresholdPeakSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedDemandedPhaseThresholdPeakSeconds = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_demanded_phase_threshold_off_peak_seconds = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedDemandedPhaseThresholdOffPeakSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedDemandedPhaseThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedDemandedPhaseThresholdOffPeakSeconds = function() {
  return this.setUnservedDemandedPhaseThresholdOffPeakSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedDemandedPhaseThresholdOffPeakSeconds = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value unserved_demanded_phase_threshold_overnight_seconds = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getUnservedDemandedPhaseThresholdOvernightSeconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setUnservedDemandedPhaseThresholdOvernightSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearUnservedDemandedPhaseThresholdOvernightSeconds = function() {
  return this.setUnservedDemandedPhaseThresholdOvernightSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasUnservedDemandedPhaseThresholdOvernightSeconds = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Type type_id = 13;
 * @return {!proto.vivacity.config_v2.Phase.Type}
 */
proto.vivacity.config_v2.Phase.prototype.getTypeId = function() {
  return /** @type {!proto.vivacity.config_v2.Phase.Type} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.config_v2.Phase.Type} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.setTypeId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearTypeId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasTypeId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional AppearanceType appearance_type_id = 14;
 * @return {!proto.vivacity.config_v2.Phase.AppearanceType}
 */
proto.vivacity.config_v2.Phase.prototype.getAppearanceTypeId = function() {
  return /** @type {!proto.vivacity.config_v2.Phase.AppearanceType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vivacity.config_v2.Phase.AppearanceType} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.setAppearanceTypeId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearAppearanceTypeId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasAppearanceTypeId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TerminationType termination_type_id = 15;
 * @return {!proto.vivacity.config_v2.Phase.TerminationType}
 */
proto.vivacity.config_v2.Phase.prototype.getTerminationTypeId = function() {
  return /** @type {!proto.vivacity.config_v2.Phase.TerminationType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.config_v2.Phase.TerminationType} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.setTerminationTypeId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearTerminationTypeId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasTerminationTypeId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_demand_delay_milliseconds = 16;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianDemandDelayMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 16));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianDemandDelayMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianDemandDelayMilliseconds = function() {
  return this.setPedestrianDemandDelayMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianDemandDelayMilliseconds = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_demand_hold_milliseconds = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianDemandHoldMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianDemandHoldMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianDemandHoldMilliseconds = function() {
  return this.setPedestrianDemandHoldMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianDemandHoldMilliseconds = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_clearance_max_milliseconds = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianClearanceMaxMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianClearanceMaxMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianClearanceMaxMilliseconds = function() {
  return this.setPedestrianClearanceMaxMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianClearanceMaxMilliseconds = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_clearance_delay_gap_change_milliseconds = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianClearanceDelayGapChangeMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianClearanceDelayGapChangeMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianClearanceDelayGapChangeMilliseconds = function() {
  return this.setPedestrianClearanceDelayGapChangeMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianClearanceDelayGapChangeMilliseconds = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_clearance_delay_max_gap_change_milliseconds = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianClearanceDelayMaxGapChangeMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianClearanceDelayMaxGapChangeMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianClearanceDelayMaxGapChangeMilliseconds = function() {
  return this.setPedestrianClearanceDelayMaxGapChangeMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianClearanceDelayMaxGapChangeMilliseconds = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value pedestrian_clearance_min_red_milliseconds = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.Phase.prototype.getPedestrianClearanceMinRedMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Phase} returns this
*/
proto.vivacity.config_v2.Phase.prototype.setPedestrianClearanceMinRedMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Phase} returns this
 */
proto.vivacity.config_v2.Phase.prototype.clearPedestrianClearanceMinRedMilliseconds = function() {
  return this.setPedestrianClearanceMinRedMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Phase.prototype.hasPedestrianClearanceMinRedMilliseconds = function() {
  return jspb.Message.getField(this, 21) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
