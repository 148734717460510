import { ExtendedKeyMap } from "../../../interfaces/hydration/type-assertions";
import {
  autoFpsSelectButton,
  cameraButton,
  checkBoxOutlineBlankButton,
  dataObjectButton,
  fileDownloadButton,
  noteAddButton,
  polylineButton,
  routeButton,
  saveIconButton,
  slowDownButton,
  speedUpButton,
  zoomInButton,
} from "./icons";
import _ from "lodash";
import { ClassifyingDetectorClassTypes } from "../../../vivacity/core/classifying_detector_class_types_pb";

const makeClassCrossingKeyMap = (
  className: string,
  classNameFriendly: string,
  extraData: string,
  clockwiseKey: string,
  antiClockwiseKey: string
): ExtendedKeyMap => {
  return Object.fromEntries([
    [
      `ADD_${className}_COUNT_CLOCKWISE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly,
        extraData: `+CW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Add a ${_.lowerCase(className)} crossing the selected countline clockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                clockwiseKey,
                `LControl+${antiClockwiseKey}`,
                `RControl+${antiClockwiseKey}`,
                `LAlt+${antiClockwiseKey}`,
                `RAlt+${antiClockwiseKey}`,
              ]
            : [],
      },
    ],
    [
      `ADD_${className}_COUNT_ANTICLOCKWISE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly,
        extraData: `+ACW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Add a ${_.lowerCase(className)} crossing the selected countline anticlockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                antiClockwiseKey,
                `LControl+${clockwiseKey}`,
                `RControl+${clockwiseKey}`,
                `LAlt+${clockwiseKey}`,
                `RAlt+${clockwiseKey}`,
              ]
            : [],
      },
    ],
    [
      `DELETE_LAST_${className}_COUNT_CLOCKWISE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly,
        extraData: `-CW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Delete the last ${_.lowerCase(className)} crossing the selected countline clockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                `LShift+${_.toUpper(clockwiseKey)}`,
                `LControl+LShift+${_.toUpper(antiClockwiseKey)}`,
                `RControl+RShift+${_.toUpper(antiClockwiseKey)}`,
                `LAlt+LShift+${_.toUpper(antiClockwiseKey)}`,
                `RAlt+RShift+${_.toUpper(antiClockwiseKey)}`,
              ]
            : [],
      },
    ],
    [
      `DELETE_LAST_${className}_COUNT_ANTICLOCKWISE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly,
        extraData: `-ACW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Delete the last ${_.lowerCase(className)} crossing the selected countline anticlockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                `LShift+${_.toUpper(antiClockwiseKey)}`,
                `LControl+LShift+${_.toUpper(clockwiseKey)}`,
                `RControl+RShift+${_.toUpper(clockwiseKey)}`,
                `LAlt+LShift+${_.toUpper(clockwiseKey)}`,
                `RAlt+RShift+${_.toUpper(clockwiseKey)}`,
              ]
            : [],
      },
    ],
    [
      `ADD_${className}_COUNT_CLOCKWISE_WITH_PLATE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly + "+plate",
        extraData: `+CW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Add a ${_.lowerCase(className)} with ANPR plate read crossing the selected countline clockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                `\\+${clockwiseKey}`,
                `LControl+\\+${antiClockwiseKey}`,
                `RControl+\\+${antiClockwiseKey}`,
                `LAlt+\\+${antiClockwiseKey}`,
                `RAlt+\\+${antiClockwiseKey}`,
              ]
            : [],
      },
    ],
    [
      `ADD_${className}_COUNT_ANTICLOCKWISE_WITH_PLATE`,
      {
        action: "keypress",
        sequence: "",
        name: classNameFriendly + "+plate",
        extraData: `+ACW${extraData ? " (" + extraData + ")" : ""}`,
        group: "crossings",
        description: `Add a ${_.lowerCase(
          className
        )} with ANPR plate read crossing the selected countline anticlockwise`,
        sequences:
          clockwiseKey && antiClockwiseKey
            ? [
                `\\+${antiClockwiseKey}`,
                `LControl+\\+${clockwiseKey}`,
                `RControl+\\+${clockwiseKey}`,
                `LAlt+\\+${clockwiseKey}`,
                `RAlt+\\+${clockwiseKey}`,
              ]
            : [],
      },
    ],
  ]);
};

const defaultCrossingKeyMaps = Object.keys(ClassifyingDetectorClassTypes)
  .map(classType => {
    if (ClassifyingDetectorClassTypes[classType] <= 60 && ClassifyingDetectorClassTypes[classType] !== 0) {
      return makeClassCrossingKeyMap(
        classType,
        _.truncate(_.startCase(_.lowerCase(classType)), { length: 10 }),
        "",
        "",
        ""
      );
    } else {
      return {};
    }
  })
  .reduce((prev, curr) => {
    return { ...prev, ...curr };
  });

export const swallowDefaults: ExtendedKeyMap = {
  // This is a special handler to capture special keypresses during recording to prevent defaults
  // It's the last one so should never trigger instead of a sequence higher up
  SWALLOW_DEFAULTS_WHILE_RECORDING: {
    action: "keydown",
    sequence: "",
    name: "SWALLOW DEFAULTS",
    group: "DUMMY",
    description: "DUMMY PLACE",
    sequences: [
      // https://support.google.com/chrome/answer/157179
      // Some of these get handled by Chromium's C++ (pre-JS) and so defaults can't be prevented, but we try our best
      "LControl+r", // Chrome - Reload page
      "RControl+r", // Chrome - Reload page
      "LControl+LShift+r", // Chrome - Reload page ignoring cache
      "RControl+RShift+r", // Chrome - Reload page ignoring cache
      "LShift+F5", // Chrome - Reload page ignoring cache
      "RShift+F5", // Chrome - Reload page ignoring cache
      "LShift+tab", // Chrome - Browse clickable items moving backward
      "RShift+tab", // Chrome - Browse clickable items moving backward
      "LControl+f", // Chrome - Find
      "RControl+f", // Chrome - Find
      "LControl+n", // Chrome - New window
      "RControl+n", // Chrome - New window
      "LControl+LShift+n", // Chrome - New incognito window
      "RControl+RShift+n", // Chrome - New incognito window
      "LControl+t", // Chrome - New tab
      "RControl+t", // Chrome - New tab
      "LControl+LShift+t", // Chrome - Reopen closed tabs
      "RControl+RShift+t", // Chrome - Reopen closed tabs
      "LControl+tab", // Chrome - jump to next tab
      "RControl+tab", // Chrome - jump to next tab
      "LControl+pagedown", // Chrome - jump to next tab
      "RControl+pagedown", // Chrome - jump to next tab
      "LControl+1", // Chrome - jump to tab 1
      "RControl+1", // Chrome - jump to tab 1
      "LControl+2", // Chrome - jump to tab 2
      "RControl+2", // Chrome - jump to tab 2
      "LControl+3", // Chrome - jump to tab 3
      "RControl+3", // Chrome - jump to tab 3
      "LControl+4", // Chrome - jump to tab 4
      "RControl+4", // Chrome - jump to tab 4
      "LControl+5", // Chrome - jump to tab 5
      "RControl+5", // Chrome - jump to tab 5
      "LControl+6", // Chrome - jump to tab 6
      "RControl+6", // Chrome - jump to tab 6
      "LControl+7", // Chrome - jump to tab 7
      "RControl+7", // Chrome - jump to tab 7
      "LControl+8", // Chrome - jump to tab 8
      "RControl+8", // Chrome - jump to tab 8
      "LControl+9", // Chrome - jump to last tab
      "RControl+9", // Chrome - jump to last tab
      "LAlt+home", // Chrome - open home page in current tab
      "LAlt+left", // Chrome - open previous page from history in current tab
      "LAlt+right", // Chrome - open next page from history in current tab
      "RAlt+left", // Mac Chrome - Move your cursor to the beginning of the previous word in a text field
      "RAlt+right", // Mac Chrome - Move your cursor to the beginning of the previous word in a text field
      "LControl+w", // Chrome - close current tab
      "RControl+w", // Chrome - close current tab
      "LControl+F4", // Chrome - close current tab
      "RControl+F4", // Chrome - close current tab

      "LControl+LShift+w", // Chrome - close current window
      "RControl+RShift+w", // Chrome - close current window
      "LControl+LShift+F4", // Chrome - close current window
      "RControl+RShift+F4", // Chrome - close current window

      "LAlt+space", // Chrome - Open OS right click menu
      "LAlt+f", // Chrome - Open 3 dots menu
      "LAlt+e", // Chrome - Open 3 dots menu

      "LControl+LShift+pageup", // Chrome - move tab left
      "LControl+LShift+pagedown", // Chrome - move tab right
      "RControl+RShift+pageup", // Chrome - move tab left
      "RControl+RShift+pagedown", // Chrome - move tab right

      "LControl+LShift+b", // Chrome - show/hide bookmarks
      "RControl+RShift+b", // Chrome - show/hide bookmarks
      "LControl+LShift+o", // Chrome - open bookmark manager
      "RControl+RShift+o", // Chrome - open bookmark manager

      "LControl+h", // Chrome - open history
      "RControl+h", // Chrome - open history
      "LControl+j", // Chrome - open downloads
      "RControl+j", // Chrome - open downloads

      "LShift+esc", // Chrome - open task manager
      "RShift+esc", // Chrome - open task manager
      "LShift+LAlt+t", // Chrome - focus on first item in toolbar
      "RShift+LAlt+t", // Chrome - focus on first item in toolbar

      "LControl+g", // Chrome - jump to next match in search bar
      "RControl+g", // Chrome - jump to next match in search bar
      "LControl+LShift+g", // Chrome - jump to previous match in search bar
      "RControl+RShift+g", // Chrome - jump to previous match in search bar
      "LControl+LShift+j", // Chrome - open devtools
      "RControl+RShift+j", // Chrome - open devtools
      "LControl+LShift+del", // Chrome - open clear browsing data options
      "RControl+RShift+del", // Chrome - open clear browsing data options

      "LControl+LShift+m", // Chrome - login as a different user/guest
      "RControl+RShift+m", // Chrome - login as a different user/guest
      "LAlt+LShift+i", // Chrome - Open a feedback form
      "LAlt+RShift+i", // Chrome - Open a feedback form

      "LControl+F6", // Chrome - skip to web contents
      "RControl+F6", // Chrome - skip to web contents

      "LAlt+LShift+a", // Chrome - Focus on inactive dialogs
      "LAlt+RShift+a", // Chrome - Focus on inactive dialogs

      "LControl+k", // Chrome - Search from anywhere on the page
      "RControl+k", // Chrome - Search from anywhere on the page
      "LControl+e", // Chrome - Search from anywhere on the page
      "RControl+e", // Chrome - Search from anywhere on the page

      "LControl+l", // Chrome - Jump to the address bar
      "RControl+l", // Chrome - Jump to the address bar
      "LAlt+d", // Chrome - Jump to the address bar

      "LControl+F5", // Chrome - Move cursor to the address bar
      "RControl+F5", // Chrome - Move cursor to the address bar

      "LControl+p", // Chrome - Open options to print the current page
      "RControl+p", // Chrome - Open options to print the current page

      "LControl+s", // Chrome - Open options to save the current page
      "RControl+s", // Chrome - Open options to save the current page

      "LControl+o", // Chrome - Open file
      "RControl+o", // Chrome - Open file

      "LControl+u", // Chrome - Display non-editable HTML source code for the current page
      "RControl+u", // Chrome - Display non-editable HTML source code for the current page
      "LControl+d", // Chrome - Save your current webpage as a bookmark
      "RControl+d", // Chrome - Save your current webpage as a bookmark

      "LControl+LShift+d", // Chrome - Save all open tabs as bookmarks in a new folder
      "RControl+RShift+d", // Chrome - Save all open tabs as bookmarks in a new folder
      "LControl+plus", // Chrome - Make everything on the page bigger
      "RControl+plus", // Chrome - Make everything on the page bigger
      "LControl+-", // Chrome - Make everything on the page smaller
      "RControl+-", // Chrome - Make everything on the page smaller
      "LControl+0", // Chrome - Reset zoom
      "RControl+0", // Chrome - Reset zoom
      "space", // Chrome - Scroll down a webpage, a screen at a time
      "pagedown", // Chrome - Scroll down a webpage, a screen at a time
      "LShift+space", // Chrome - Scroll up a webpage, a screen at a time
      "RShift+space", // Chrome - Scroll up a webpage, a screen at a time
      "pageup", // Chrome - Scroll up a webpage, a screen at a time
      "home", // Chrome - Go to the top of the page
      "end", // Chrome - Go to the bottom of the page

      "LControl+left", // Chrome - Move your cursor to the beginning of the previous word in a text field
      "RControl+left", // Chrome - Move your cursor to the beginning of the previous word in a text field

      "LControl+right", // Chrome - Move your cursor to the next word
      "RControl+right", // Chrome - Move your cursor to the next word
      "LControl+backspace", // Chrome - Delete the previous word in a text field
      "RControl+backspace", // Chrome - Delete the previous word in a text field
      "RAlt+del", // Mac Chrome - Delete the previous word in a text field
      "LAlt+del", // Mac Chrome - Delete the previous word in a text field

      "LMeta+n", // Mac Chrome - New window
      "RMeta+n", // Mac Chrome - New window
      "LMeta+LShift+n", // Mac Chrome - New incognito window
      "RMeta+RShift+n", // Mac Chrome - New incognito window
      "LMeta+t", // Mac Chrome - New tab
      "RMeta+t", // Mac Chrome - New tab
      "LMeta+LShift+t", // Mac Chrome - Reopen closed tabs
      "RMeta+RShift+t", // Mac Chrome - Reopen closed tabs

      "LMeta+LAlt+right", // Mac Chrome - jump to next tab
      "RMeta+RAlt+right", // Mac Chrome - jump to next tab
      "LMeta+LAlt+left", // Mac Chrome - jump to next tab
      "RMeta+RAlt+left", // Mac Chrome - jump to next tab
      "LMeta+1", // Mac Chrome - jump to tab 1
      "RMeta+1", // Mac Chrome - jump to tab 1
      "LMeta+2", // Mac Chrome - jump to tab 2
      "RMeta+2", // Mac Chrome - jump to tab 2
      "LMeta+3", // Mac Chrome - jump to tab 3
      "RMeta+3", // Mac Chrome - jump to tab 3
      "LMeta+4", // Mac Chrome - jump to tab 4
      "RMeta+4", // Mac Chrome - jump to tab 4
      "LMeta+5", // Mac Chrome - jump to tab 5
      "RMeta+5", // Mac Chrome - jump to tab 5
      "LMeta+6", // Mac Chrome - jump to tab 6
      "RMeta+6", // Mac Chrome - jump to tab 6
      "LMeta+7", // Mac Chrome - jump to tab 7
      "RMeta+7", // Mac Chrome - jump to tab 7
      "LMeta+8", // Mac Chrome - jump to tab 8
      "RMeta+8", // Mac Chrome - jump to tab 8
      "LMeta+9", // Mac Chrome - jump to last tab
      "RMeta+9", // Mac Chrome - jump to last tab

      "LMeta+[", // Mac  Chrome - open previous page from history in current tab
      "RMeta+[", // Mac  Chrome - open previous page from history in current tab
      "LMeta+left", // Mac  Chrome - open previous page from history in current tab
      "RMeta+left", // Mac  Chrome - open previous page from history in current tab

      "LMeta+]", // Mac Chrome - open next page from history in current tab
      "RMeta+]", // Mac Chrome - open next page from history in current tab
      "LMeta+right", // Mac Chrome - open next page from history in current tab
      "RMeta+right", // Mac Chrome - open next page from history in current tab

      "LMeta+w", // Mac Chrome - close current tab
      "RMeta+w", // Mac Chrome - close current tab
      "LMeta+LShift+w", // Mac Chrome - close current window
      "RMeta+RShift+w", // Mac Chrome - close current window
      "LMeta+m", // Mac Chrome - minimise current window
      "RMeta+m", // Mac Chrome - minimise current window
      "LMeta+h", // Mac Chrome - hide current window
      "RMeta+h", // Mac Chrome - hide current window
      "LMeta+q", // Mac Chrome - quit
      "RMeta+q", // Mac Chrome - quit

      "LMeta+LShift+b", // Mac Chrome - show/hide bookmarks
      "RMeta+RShift+b", // Mac Chrome - show/hide bookmarks
      "LMeta+LAlt+b", // Mac Chrome - Open the Bookmark Manager
      "RMeta+RAlt+b", // Mac Chrome - Open the Bookmark Manager

      "LMeta+,", // Mac Chrome - Open the Settings page in a new tab
      "RMeta+,", // Mac Chrome - Open the Settings page in a new tab
      "LMeta+y", // Mac Chrome - Open the History page in a new tab
      "RMeta+y", // Mac Chrome - Open the History page in a new tab
      "LMeta+LShift+j", // Mac Chrome - Open the Downloads page in a new tab
      "RMeta+RShift+j", // Mac Chrome - Open the Downloads page in a new tab
      "LMeta+f", // Mac Chrome - Open the Find Bar to search the current page
      "RMeta+f", // Mac Chrome - Open the Find Bar to search the current page
      "LMeta+g", // Mac Chrome - Jump to the next match to your Find Bar search
      "RMeta+g", // Mac Chrome - Jump to the next match to your Find Bar search
      "LMeta+LShift+g", // Mac Chrome - Jump to the previous match to your Find Bar search
      "RMeta+RShift+g", // Mac Chrome - Jump to the previous match to your Find Bar search
      "LMeta+e", // Mac Chrome - When Find Bar is open, search for selected text
      "RMeta+e", // Mac Chrome - When Find Bar is open, search for selected text
      "LMeta+LAlt+i", // Mac Chrome - Open Developer Tools
      "RMeta+RAlt+i", // Mac Chrome - Open Developer Tools
      "LMeta+LShift+del", // Mac Chrome - Open the Clear Browsing Data options
      "RMeta+RShift+del", // Mac Chrome - Open the Clear Browsing Data options
      "LMeta+LShift+m", // Mac Chrome - Log in as a different user, browse as a Guest
      "RMeta+RShift+m", // Mac Chrome - Log in as a different user, browse as a Guest
      "LControl+F2", // Mac Chrome - Jump to the main menu bar
      "RControl+F2", // Mac Chrome - Jump to the main menu bar
      "LControl+F5", // Mac Chrome - Move cursor to the address bar
      "RControl+F5", // Mac Chrome - Move cursor to the address bar

      "LMeta+LAlt+up", // Mac Chrome - Cycle focus to unfocused dialog (if showing) and all toolbars
      "RMeta+RAlt+up", // Mac Chrome - Cycle focus to unfocused dialog (if showing) and all toolbars
      "LMeta+LAlt+down", // Mac Chrome - Cycle focus to unfocused dialog (if showing) and all toolbars
      "RMeta+RAlt+down", // Mac Chrome - Cycle focus to unfocused dialog (if showing) and all toolbars
      "LMeta+LAlt+LShift+i", // Mac Chrome - Open a feedback form
      "RMeta+RAlt+RShift+i", // Mac Chrome - Open a feedback form
      "LMeta+LAlt+LShift+a", // Mac Chrome - Focus on inactive dialogs
      "RMeta+RAlt+RShift+a", // Mac Chrome - Focus on inactive dialogs
      "LMeta+l", // Mac Chrome - Jump to the address bar
      "RMeta+l", // Mac Chrome - Jump to the address bar

      "LMeta+LShift+i", // Mac Chrome - Compose a new email message with a link to the current page
      "RMeta+RShift+i", // Mac Chrome - Compose a new email message with a link to the current page
      "LMeta+p", // Mac Chrome - Open options to print the current page
      "RMeta+p", // Mac Chrome - Open options to print the current page
      "LMeta+s", // Mac Chrome - Open options to save the current page
      "RMeta+s", // Mac Chrome - Open options to save the current page
      "LMeta+LAlt+p", // Mac Chrome - Open the Page Setup dialog
      "RMeta+RAlt+p", // Mac Chrome - Open the Page Setup dialog
      "LMeta+LShift+r", // Mac Chrome - Reload your current page, ignoring cached content
      "RMeta+RShift+r", // Mac Chrome - Reload your current page, ignoring cached content
      "LMeta+o", // Mac Chrome - Open file
      "RMeta+o", // Mac Chrome - Open file
      "LMeta+LAlt+u", // Mac Chrome - Display non-editable HTML source code for the current page
      "RMeta+RAlt+u", // Mac Chrome - Display non-editable HTML source code for the current page
      "LMeta+LAlt+j", // Mac Chrome - Open the JavaScript Console
      "RMeta+RAlt+j", // Mac Chrome - Open the JavaScript Console
      "LMeta+d", // Mac Chrome - Save your current webpage as a bookmark
      "RMeta+d", // Mac Chrome - Save your current webpage as a bookmark
      "LMeta+LShift+d", // Mac Chrome - Save all open tabs as bookmarks in a new folder
      "RMeta+RShift+d", // Mac Chrome - Save all open tabs as bookmarks in a new folder
      "LMeta+LControl+f", // Mac Chrome - Turn full-screen mode on or off
      "RMeta+RControl+f", // Mac Chrome - Turn full-screen mode on or off
      "LMeta+plus", // Mac Chrome - Make everything on the page bigger
      "RMeta+plus", // Mac Chrome - Make everything on the page bigger
      "LMeta+-", // Mac Chrome - Make everything on the page smaller
      "RMeta+-", // Mac Chrome - Make everything on the page smaller
      "LMeta+0", // Mac Chrome - Reset zoom
      "RMeta+0", // Mac Chrome - Reset zoom
      "LMeta+LAlt+f", // Mac Chrome - Search the web
      "RMeta+RAlt+f", // Mac Chrome - Search the web
      "LMeta+LShift+h", // Mac Chrome - Open your home page in the current tab
      "RMeta+RShift+h", // Mac Chrome - Open your home page in the current tab
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "enter",
      "return",
      "PrintScreen",
      "NumpadEnter",
      "esc",
      "tab",
      "backspace",
      "capslock",
      "LShift",
      "RShift",
      "LControl",
      "RControl",
      "LAlt",
      "RAlt",
      "RAltGraph",
      "LMeta",
      "RMeta",
      "space",
      "Numpad/",
      "NumLock",
      "up",
      "left",
      "down",
      "right",
      "PrintScreen",
      "ScrollLock",
      "Pause",
      "Numpad*",
      "Numpad-",
      "Numpad+",
      "NumpadEnter",
      ".",
      "Numpad0",
      "Numpad1",
      "Numpad2",
      "Numpad3",
      "Numpad4",
      "Numpad5",
      "Numpad5",
      "Numpad6",
      "Numpad7",
      "Numpad8",
      "Numpad9",
      "NumpadInsert",
      "Numpad ",
      "NumpadEnd",
      "NumpadArrowDown",
      "NumpadPageDown",
      "NumpadArrowLeft",
      "NumpadClear",
      "NumpadArrowRight",
      "NumpadHome",
      "NumpadArrowUp",
      "NumpadPageUp",
      "plus",
      "ContextMenu",
      "RContextMenu",
      "LContextMenu",
    ],
  },
};

export const createEmptyKeyMap = () => {
  const keyMap: ExtendedKeyMap = {
    PLAY_PAUSE: {
      action: "keypress",
      sequence: "",
      name: "Play/Pause",
      group: "playback",
      description: "Play or pause the video player",
      sequences: [],
    },
    STEP_FORWARD_2_SECONDS: {
      action: "keypress",
      sequence: "",
      name: "+2 secs",
      group: "playback",
      description: "Step the video forward by 2 seconds",
      sequences: [],
    },
    STEP_BACKWARD_2_SECONDS: {
      action: "keypress",
      sequence: "",
      name: "-2 secs",
      group: "playback",
      description: "Step the video backward by 2 seconds",
      sequences: [],
    },
    STEP_FORWARD_30_SECONDS: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "+30 secs",
      group: "playback",
      description: "Step the video forward by 30 seconds",
      sequences: [],
    },
    STEP_BACKWARD_30_SECONDS: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "-30 secs",
      group: "playback",
      description: "Step the video backward by 30 seconds",
      sequences: [],
    },
    STEP_BACKWARD_1_FRAME: {
      action: "keypress",
      sequence: "",
      name: "-1 frame",
      group: "playback",
      description: "Step the video backward by 1 frame",
      sequences: [],
    },
    STEP_FORWARD_1_FRAME: {
      action: "keypress",
      sequence: "",
      name: "+1 frame",
      group: "playback",
      description: "Step the video forward by 1 frame",
      sequences: [],
    },
    PLAYBACK_FASTER: {
      action: "keypress",
      sequence: "",
      name: speedUpButton,
      extraData: "speed +10%",
      group: "playback",
      description: "Increase playback speed by 10%",
      sequences: [],
    },
    PLAYBACK_SLOWER: {
      action: "keypress",
      sequence: "",
      name: slowDownButton,
      extraData: "speed -10%",
      group: "playback",
      description: "Decrease playback speed by 10%",
      sequences: [],
    },
    CAPTURE_IMAGE: {
      action: "keypress",
      sequence: "",
      name: cameraButton,
      extraData: "Capture",
      group: "playback",
      description: "Capture an image from the video and copy it to the clipboard",
      sequences: [],
    },
    TOGGLE_PLAY_UNTIL_NEXT_CV_CROSSING_MODE: {
      action: "keypress",
      sequence: "",
      name: autoFpsSelectButton,
      extraData: "EZ mode",
      group: "playback",
      description: "Toggle 'Play until next CV crossing' mode on/off",
      sequences: [],
    },
    TOGGLE_SHOW_COUNTLINES: {
      action: "keypress",
      sequence: "",
      name: polylineButton,
      extraData: "Show/Hide countlines",
      group: "playback",
      description: "Toggle whether to show or hide countlines on the video display",
      sequences: [],
    },
    TOGGLE_SHOW_TRACK_TAILS: {
      action: "keypress",
      sequence: "",
      name: routeButton,
      extraData: "Show/Hide tails",
      group: "playback",
      description: "Toggle whether to show or hide track tails on the video display",
      sequences: [],
    },
    TOGGLE_SHOW_BOXES: {
      action: "keypress",
      sequence: "",
      name: checkBoxOutlineBlankButton,
      extraData: "Show/Hide boxes",
      group: "playback",
      description: "Toggle whether to show or hide track boxes on the video display",
      sequences: [],
    },
    TOGGLE_ZOOM_ENABLED: {
      action: "keypress",
      sequence: "",
      name: zoomInButton,
      extraData: "Toggle zoom",
      group: "playback",
      description: "Toggle whether to enable zooming on the video display",
      sequences: [],
    },
    JUMP_TO_NEXT_CV_CROSSING: {
      action: "keypress",
      sequence: "",
      name: "",
      extraData: "Next CV Crossing",
      group: "playback",
      description: "Jump to frame of next CV crossing",
      sequences: [],
    },
    JUMP_TO_PREVIOUS_CV_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Prev CV Crossing",
      group: "playback",
      description: "Jump to frame of previous CV crossing",
      sequences: [],
    },
    JUMP_TO_NEXT_VALIDATION_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Next Validation Crossing",
      group: "playback",
      description: "Jump to frame of next validation crossing",
      sequences: [],
    },
    JUMP_TO_PREVIOUS_VALIDATION_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Previous Validation Crossing",
      group: "playback",
      description: "Jump to frame of previous validation crossing",
      sequences: [],
    },
    DELETE_LAST_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Delete last crossing",
      group: "validation",
      description: "Delete last validation crossing before current frame",
      sequences: [],
    },
    DELETE_NEXT_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Del next crossing",
      group: "validation",
      description: "Delete next validation crossing after current frame",
      sequences: [],
    },
    INSERT_CROSSING_DIALOG: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Add crossing",
      group: "validation",
      description: "Insert a new crossing at the current frame (dialog)",
      sequences: [],
    },
    INSERT_SAME_AS_LAST_CV_CROSSING: {
      action: "keypress",
      sequence: "",
      name: " ",
      extraData: "Copy last CV crossing",
      group: "validation",
      description: "Insert a new crossing with the same details as the last CV crossing",
      sequences: [],
    },
    ADD_NOTES: {
      action: "keypress",
      sequence: "",
      name: noteAddButton,
      extraData: "Add notes",
      group: "application",
      description: "Open the dialog to add notes to the current countline validation run",
      sequences: [],
    },
    SHOW_RESULTS_DIALOG: {
      action: "keypress",
      sequence: "",
      name: fileDownloadButton,
      extraData: "Show results",
      group: "application",
      description: "Open the dialog to show the results of the current countline validation run",
      sequences: [],
    },
    SHOW_SAVE_DIALOG: {
      action: "keypress",
      sequence: "",
      name: saveIconButton,
      extraData: "Show save",
      group: "application",
      description: "Open the dialog to show the saving options",
      sequences: [],
    },
    EDIT_DISPLAY_SETTINGS: {
      action: "keypress",
      sequence: "",
      name: dataObjectButton,
      extraData: "Edit display",
      group: "application",
      description: "Open the dialog to edit track head display parameters",
      sequences: [],
    },
    EXIT_PLAYER: {
      action: "keypress",
      sequence: "",
      name: "Exit",
      group: "application",
      description: "Exit the player",
      sequences: ["esc"],
    },
    ...defaultCrossingKeyMaps,
    ...swallowDefaults,
  };
  return keyMap;
};

export const createDefaultKeyMap = () => {
  let defaultKeymap = createEmptyKeyMap();
  defaultKeymap.PLAY_PAUSE.sequences.push("space");
  defaultKeymap.STEP_FORWARD_2_SECONDS.sequences.push("right");
  defaultKeymap.STEP_BACKWARD_2_SECONDS.sequences.push("left");
  defaultKeymap.STEP_FORWARD_30_SECONDS.sequences.push("pagedown");
  defaultKeymap.STEP_BACKWARD_30_SECONDS.sequences.push("pageup");
  defaultKeymap.STEP_BACKWARD_1_FRAME.sequences.push("down");
  defaultKeymap.STEP_FORWARD_1_FRAME.sequences.push("up");
  defaultKeymap.PLAYBACK_FASTER.sequences.push("n");
  defaultKeymap.PLAYBACK_SLOWER.sequences.push("v");
  defaultKeymap.CAPTURE_IMAGE.sequences.push("b");
  defaultKeymap.TOGGLE_PLAY_UNTIL_NEXT_CV_CROSSING_MODE.sequences.push("Numpad0");
  defaultKeymap.TOGGLE_SHOW_COUNTLINES.sequences.push("F4");
  defaultKeymap.TOGGLE_SHOW_TRACK_TAILS.sequences.push("F3");
  defaultKeymap.TOGGLE_SHOW_BOXES.sequences.push("F2");
  defaultKeymap.TOGGLE_ZOOM_ENABLED.sequences.push("F1");
  defaultKeymap.JUMP_TO_NEXT_CV_CROSSING.sequences.push("tab");
  defaultKeymap.JUMP_TO_PREVIOUS_CV_CROSSING.sequences.push("LShift+tab");
  defaultKeymap.JUMP_TO_NEXT_VALIDATION_CROSSING.sequences.push("RAltGraph+tab");
  defaultKeymap.JUMP_TO_PREVIOUS_VALIDATION_CROSSING.sequences.push("RAltGraph+LShift+tab");
  defaultKeymap.DELETE_LAST_CROSSING.sequences.push("backspace");
  defaultKeymap.DELETE_NEXT_CROSSING.sequences.push("del");
  defaultKeymap.INSERT_CROSSING_DIALOG.sequences.push("ins");
  defaultKeymap.INSERT_SAME_AS_LAST_CV_CROSSING.sequences.push("enter");
  defaultKeymap = {
    ...defaultKeymap,
    ...makeClassCrossingKeyMap("PEDESTRIAN", "Ped", "", "g", "h"),
    ...makeClassCrossingKeyMap("CYCLIST", "Bike", "", "w", "o"),
    ...makeClassCrossingKeyMap("MOTORBIKE", "Motorbike", "", "r", "u"),
    ...makeClassCrossingKeyMap("CAR", "Car", "", "f", "j"),
    ...makeClassCrossingKeyMap("TAXI", "Taxi", "", "q", "p"),
    ...makeClassCrossingKeyMap("VAN", "Van", "", "a", ";"),
    ...makeClassCrossingKeyMap("MINIBUS", "Minibus", "", "z", "."),
    ...makeClassCrossingKeyMap("BUS", "Bus", "", "e", "i"),
    ...makeClassCrossingKeyMap("RIGID", "Rigid", "", "s", "l"),
    ...makeClassCrossingKeyMap("TRUCK", "Truck", "", "d", "k"),
    ...makeClassCrossingKeyMap("EMERGENCY_CAR", "E. car", "", "c", "m"),
    ...makeClassCrossingKeyMap("EMERGENCY_VAN", "E. Van", "", "x", ","),
    ...makeClassCrossingKeyMap("FIRE_ENGINE", "Fire", "", "t", "y"),
  };
  return defaultKeymap;
};

export const DefaultKeymap = createDefaultKeyMap();
