export const nearMissTrackHeadTextFunc =
  ' \
const nearMisses = Object.fromEntries(trackHead.metadata?.nearMissesMap || []); \n\
const keyMetrics = []; \n\
\n\
const shortTermAverageSpeed = \n\
  trackHead.movement?.shortTermAverage?.gps?.magnitude ?? 0; \n\
const speedMph = Math.round(shortTermAverageSpeed * 2.23694); \n\
\n\
keyMetrics.push( \n\
  `Class: ${classLookup[trackHead.trackClass]}`,\n\
  `Speed: ${speedMph}mph`, \n\
); \n\
\n\
Object.values(nearMisses) \n\
  .reverse() \n\
  .forEach((nearMiss) => { \n\
    let incidentMetrics = [];\n\
    const { petStatus, proximalStatus, ttcStatus } = nearMiss; \n\
\n\
    if (petStatus) { \n\
      incidentMetrics.push( \n\
        `PET: ${Math.round( \n\
          Number(petStatus.currentPetMicroseconds) / 1000 \n\
        )}ms` \n\
      ); \n\
    } \n\
    if (proximalStatus) { \n\
      incidentMetrics.push( \n\
        `Distance: ${proximalStatus.currentDistanceMetres.toFixed(1)}m` \n\
      ); \n\
    } \n\
    if (ttcStatus) { \n\
      const ttcMs = Math.round( \n\
        Number(ttcStatus.currentTtcMicroseconds) / 1000 \n\
      ); \n\
      if (ttcMs < 1500) { \n\
        incidentMetrics.push(`TTC: ${ttcMs}ms`); \n\
      } \n\
    } \n\
    keyMetrics.push( \n\
      ...incidentMetrics, \n\
    ); \n\
  }); \n\
\n\
return `${keyMetrics.join("\\n")}`'.trim();
