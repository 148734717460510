import { action, computed, makeObservable, observable } from "mobx";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { VideoID } from "../domain";
import { Video } from "../interfaces";
import { assertGCSGCSListResponse, assertSignedVideoURL } from "../interfaces/hydration/type-assertions";
import { handleNon200 } from "./utils/promiseHandlers";
import { errString } from "./utils/errorString";
import { getGcsEndpoint, GcsEndpontPath, getApiEndpoint, ApiEndpointPath } from "./apiStore";
import { GCSListObject } from "../interfaces/hydration/gcs";
import { SnappiVideoCaptureRecordingRequested, SnappiVideoCaptureRecordingStarted } from "../vivacity/core/snappi_pb";
import { TriggerReason, VideoType } from "../enums/proto.enum";
import _ from "lodash";

export const FETCH_VIDEO_THUMBNAILS_EVENT = "FETCH_VIDEO_THUMBNAILS_EVENT";
export const FETCH_FIRST_VIDEO_THUMBNAIL_EVENT = "FETCH_FIRST_VIDEO_THUMBNAIL_EVENT";
export const FETCH_VIDEO_SIGNED_URL = "FETCH_VIDEO_SIGNED_URL";

export interface FetchVideoThumbnailsEvent {
  videoID: VideoID;
}

export class VideoStore extends ChildStore {
  @observable
  videos: Map<VideoID, Video> = new Map<VideoID, Video>();

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  getVideo(id: number | undefined) {
    if (!id) {
      return undefined;
    }
    return this.videos.get(id);
  }

  @action
  init() {
    window.addEventListener(FETCH_VIDEO_THUMBNAILS_EVENT, ((e: CustomEvent<FetchVideoThumbnailsEvent>) => {
      const { videoID } = e.detail;
      const video = this.videos.get(videoID);
      if (video) {
        this.fetchVersionedThumbnails(videoID, video.requestId, "");
      }
    }) as EventListener);
    window.addEventListener(FETCH_FIRST_VIDEO_THUMBNAIL_EVENT, ((e: CustomEvent<FetchVideoThumbnailsEvent>) => {
      const { videoID } = e.detail;
      const video = this.videos.get(videoID);
      if (video) {
        this.fetchVideoThumbnail(videoID).then(() => {});
      }
    }) as EventListener);
    window.addEventListener(FETCH_VIDEO_SIGNED_URL, ((e: CustomEvent<FetchVideoThumbnailsEvent>) => {
      const { videoID } = e.detail;
      const video = this.videos.get(videoID);
      if (video) {
        this.storeSignedURL(videoID).then(() => {});
      }
    }) as EventListener);
  }

  @action.bound
  setVideoThumbnail(id: VideoID, thumbnailURL: string) {
    const video = this.videos.get(id);
    if (!video) {
      console.error("WTF?? no video inside setVideoThumbnail()");
      return;
    }
    video.thumbnail = thumbnailURL;
    video.computerVisionRuns?.forEach(cvRunID => {
      const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(cvRunID);
      if (cvRun) {
        cvRun.thumbnail = video.thumbnail;
        cvRun.validationRuns?.forEach(valRunID => {
          const valRun = this.rootStore.validationRunStore.validationRuns.get(valRunID);
          if (valRun) {
            valRun.thumbnail = video.thumbnail;
          }
        });
      }
    });
  }

  @action.bound
  setVideo(id: VideoID, video: Video) {
    this.videos.set(id, video);
  }

  @action.bound
  upsertThumbnail(videoID: VideoID, newBlob: Blob) {
    const existingVideo = this.rootStore.videoStore.videos.get(videoID);
    if (existingVideo) {
      if (
        existingVideo.thumbnail &&
        !_.find(existingVideo.extraThumbnails, thumb => thumb.thumbnail === existingVideo.thumbnail)
      ) {
        existingVideo.extraThumbnails.push({
          thumbnail: existingVideo.thumbnail,
          generation: performance.now(),
        });
      }
      existingVideo.thumbnail = window.URL.createObjectURL(newBlob);
    }
  }

  @action.bound
  upsertVideo(
    videoID: number,
    requestID: string,
    inputVideo: SnappiVideoCaptureRecordingStarted.AsObject | SnappiVideoCaptureRecordingRequested.AsObject
  ) {
    const existingVideo = this.rootStore.videoStore.videos.get(videoID);

    if (existingVideo) {
      existingVideo.bitrate = inputVideo.bitrate;
      existingVideo.vpid = inputVideo.visionProgramId;
      existingVideo.width = inputVideo.width;
      existingVideo.height = inputVideo.height;
      existingVideo.frameRateDenominator = inputVideo.frameRateDenominator;
      existingVideo.frameRateNumerator = inputVideo.frameRateNumerator;
      existingVideo.bitrate = inputVideo.bitrate;
      existingVideo.buffers = inputVideo.buffers;
      existingVideo.requestedBy = inputVideo.deprecatedRequestedBy;
      existingVideo.triggerReason = TriggerReason[inputVideo.reason];
      existingVideo.triggerType = VideoType[inputVideo.videoType];
      existingVideo.startAt = new Date(inputVideo.requestedAtMicroseconds / 1000);
      existingVideo.shouldRetain = inputVideo.shouldRetain;
      this.setVideo(videoID, { ...existingVideo });
    } else {
      this.setVideo(videoID, {
        requestId: requestID,
        bitrate: inputVideo.bitrate,
        bucketPath: undefined,
        buffers: inputVideo.buffers,
        computerVisionRuns: undefined,
        downloadUrl: undefined,
        endAt: undefined,
        endFrame: undefined,
        errors: undefined,
        frameRateDenominator: inputVideo.frameRateDenominator,
        frameRateNumerator: inputVideo.frameRateNumerator,
        height: inputVideo.height,
        id: videoID,
        meta: undefined,
        rawVideoPath: undefined,
        recordingProgress: undefined,
        requestedBy: inputVideo.deprecatedRequestedBy,
        startAt: new Date(inputVideo.requestedAtMicroseconds / 1000),
        startFrame: undefined,
        status: "REQUESTED",
        thumbnail: undefined,
        thumbnailUrl: "",
        totalBytes: undefined,
        triggerReason: TriggerReason[inputVideo.reason],
        triggerType: VideoType[inputVideo.videoType],
        updatedAt: undefined,
        updatedBy: undefined,
        uploadingProgress: undefined,
        vpid: inputVideo.visionProgramId,
        width: inputVideo.width,
        extraThumbnails: [],
        shouldRetain: inputVideo.shouldRetain,
        ogDtfNearMissEvents: [],
      });
    }
    this.rootStore.entitiesStore.updateVideoByVPID(inputVideo.visionProgramId, videoID);
  }

  @action.bound
  async storeSignedURL(id: VideoID) {
    const video = this.videos.get(id);
    if (video && video.status === "UPLOADING_COMPLETE" && video.downloadUrl === undefined) {
      this.setVideoDownloadURL(id, "");
      this.rootStore.apiStore
        .authenticatedFetch(`${getGcsEndpoint(GcsEndpontPath.SIGNED_URL)}?video_id=${id.toString(10)}`)
        .then(handleNon200)
        .then(async resp => resp.json())
        .then(
          action(data => {
            const signedURL = assertSignedVideoURL(data);
            this.setVideoDownloadURL(id, signedURL.signed_url);
          })
        )
        .catch(
          action(async err =>
            this.rootStore.entitiesStore.setHydrationErrors("Could not storeSignedURL(): " + errString(err))
          )
        );
    }
  }

  @action.bound setVideoDownloadURL(id: VideoID, signedUrl: string) {
    const video = this.videos.get(id);
    if (video) {
      video.downloadUrl = signedUrl;
      video.computerVisionRuns?.forEach(cvRunID => {
        const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(cvRunID);
        if (cvRun) {
          cvRun.videoDownloadURL = video.downloadUrl;
          cvRun.validationRuns?.forEach(valRunID => {
            const valRun = this.rootStore.validationRunStore.validationRuns.get(valRunID);
            if (valRun) {
              valRun.videoDownloadURL = video.downloadUrl;
            }
          });
        }
      });
    } else {
      throw new Error("attempted to set download URL on non-existent video: " + id.toString(10));
    }
  }

  @action.bound
  async fetchVideoThumbnail(videoID: VideoID) {
    const video = this.videos.get(videoID);
    if (!video) {
      console.error("WTF???");
      return;
    }
    if (video.thumbnail) {
      return;
    }
    this.rootStore.apiStore
      .authenticatedFetch(`${video.thumbnailUrl}?alt=media`)
      .then(handleNon200)
      .then(
        action(async data => {
          const blob = await data.blob();
          const newBlob = new Blob([blob]);
          const blobUrl = window.URL.createObjectURL(newBlob);
          this.setVideoThumbnail(video.id, blobUrl);
        })
      )
      .catch(
        action(async err => {
          if (!errString(err).startsWith("Error: Unexpected response code 404")) {
            this.rootStore.entitiesStore.setHydrationErrors("Could not fetchVideoThumbnail(): " + errString(err));
          }
        })
      );
  }

  @action.bound
  fetchVersionedThumbnails(videoID: VideoID, requestID: string, pageToken: string) {
    const video = this.videos.get(videoID);
    if (video?.extraThumbnails.length) {
      return;
    }
    this.rootStore.apiStore
      .authenticatedFetch(
        `${getGcsEndpoint(GcsEndpontPath.LIST_THUMBNAIL_VERSIONS)}/${requestID}.jpeg?page_token=${pageToken}`
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(data => {
        const listResp = assertGCSGCSListResponse(data);

        listResp?.items?.forEach(gcsObj => {
          if ((video?.extraThumbnails.length ?? 0) > 20) {
            // Don't fetch more than 20 per video
            return;
          }
          this.rootStore.apiStore
            .authenticatedFetch(
              `${getGcsEndpoint(GcsEndpontPath.GET_VERSIONED_THUMBNAIL)}/${requestID}.jpeg?alt=media&generation=${
                gcsObj.generation
              }`
            )
            .then(handleNon200)
            .then(
              action(async thumb => {
                const blob = await thumb.blob();
                const newBlob = new Blob([blob]);
                await this.storeVersionedThumbnail(newBlob, videoID, requestID, gcsObj);
              })
            );
        });
        if (listResp.nextPageToken && (video?.extraThumbnails.length ?? 0) < 20) {
          this.fetchVersionedThumbnails(videoID, requestID, listResp.nextPageToken);
        }
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors(
            "Could not fetchVideoThumbnail() (versioned thumbnails): " + errString(err)
          )
        )
      );
  }

  @action
  async storeVersionedThumbnail(thumb: Blob, videoID: VideoID, requestID: string, gcsObj: GCSListObject) {
    const blobUrl = window.URL.createObjectURL(thumb);
    const video = this.videos.get(videoID);
    if (video) {
      video.extraThumbnails.push({
        thumbnail: blobUrl,
        generation: parseInt(gcsObj.generation, 10),
      });
      video.extraThumbnails.sort((a, b) => a.generation - b.generation);
      video.computerVisionRuns?.forEach(cvRunID => {
        const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(cvRunID);
        if (cvRun) {
          cvRun.extraThumbnails.push({
            thumbnail: blobUrl,
            generation: parseInt(gcsObj.generation, 10),
          });
          cvRun.validationRuns?.forEach(valRunID => {
            const valRun = this.rootStore.validationRunStore.validationRuns.get(valRunID);
            if (valRun) {
              valRun.extraThumbnails.push({
                thumbnail: blobUrl,
                generation: parseInt(gcsObj.generation, 10),
              });
            }
          });
        }
      });
    }
  }

  @computed
  get unvalidatedVideos() {
    const unvalidatedVideoIds: number[] = [];
    this.videos.forEach(video => {
      const unvalidatedComputerVisionRunIds = this.rootStore.cvRunStore.unvalidatedComputerVisionRuns;
      let isComputerVisionRunsIncomplete = false;
      video.computerVisionRuns?.forEach(runId => {
        if (unvalidatedComputerVisionRunIds.includes(runId)) {
          isComputerVisionRunsIncomplete = true;
        }
      });
      if (
        video.status !== "RECORDING_FAILED" &&
        video.status !== "UPLOADING_FAILED" &&
        (isComputerVisionRunsIncomplete || !video.computerVisionRuns)
      ) {
        unvalidatedVideoIds.push(video.id);
      }
    });
    return unvalidatedVideoIds;
  }

  @action
  fetchVideoTotalFrames(videoID: number): number {
    const startFrameFromVP = this.videos.get(videoID)?.startFrame;
    const endFrameFromVP = this.videos.get(videoID)?.endFrame;
    if (startFrameFromVP && endFrameFromVP) {
      return endFrameFromVP - startFrameFromVP;
    } else {
      return 0;
    }
  }

  @action
  async setRetainVideo(videoId: number, shouldRetain: boolean) {
    const video = this.videos.get(videoId)!;
    const response = await this.rootStore.apiStore.authenticatedFetch(
      getApiEndpoint(ApiEndpointPath.SET_RETAIN_VIDEO) +
        "?" +
        new URLSearchParams({
          video_id: String(videoId),
          should_retain: String(shouldRetain),
        }),
      {
        method: "POST",
      }
    );
    if (response.status === 200) {
      this.setVideo(video.id, { ...video, shouldRetain });
    } else {
      throw new Error("non-200 response received: " + response.toString());
    }
  }
}
