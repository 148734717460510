/* eslint-disable */
// source: vivacity/config_v2/vision_program.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.VisionProgram', null, global);
goog.exportSymbol('proto.vivacity.config_v2.VisionProgram.DistortionType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.VisionProgram.TransformationType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.VisionProgram.VisionProgramStatus', null, global);
goog.exportSymbol('proto.vivacity.config_v2.VisionProgramToClass', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.VisionProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.VisionProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.VisionProgram.displayName = 'proto.vivacity.config_v2.VisionProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.VisionProgramToClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.VisionProgramToClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.VisionProgramToClass.displayName = 'proto.vivacity.config_v2.VisionProgramToClass';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.VisionProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.VisionProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.VisionProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.VisionProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    extraValues: (f = msg.getExtraValues()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    hardwareId: (f = msg.getHardwareId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    transformationType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    distortionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pointsToTransform: (f = msg.getPointsToTransform()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    pointsImageSpace: (f = msg.getPointsImageSpace()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    pointsTransformedSpace: (f = msg.getPointsTransformedSpace()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    supermarioHttpPort: (f = msg.getSupermarioHttpPort()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    supermarioImage: (f = msg.getSupermarioImage()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    visionProgramImage: (f = msg.getVisionProgramImage()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    cameraNumber: (f = msg.getCameraNumber()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    kafkaProducerEnable: (f = msg.getKafkaProducerEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    crossingsEnable: (f = msg.getCrossingsEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalSpeedInstantaneousEnable: (f = msg.getZonalSpeedInstantaneousEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalOccupancyInstantaneousEnable: (f = msg.getZonalOccupancyInstantaneousEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    protoclearEnable: (f = msg.getProtoclearEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    protoclearFieldList: (f = msg.getProtoclearFieldList()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    udpEnable: (f = msg.getUdpEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    udpProtodumpEnable: (f = msg.getUdpProtodumpEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    udpForceRedialEnable: (f = msg.getUdpForceRedialEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    udpForceRedialInterval: (f = msg.getUdpForceRedialInterval()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    udpDestinations: (f = msg.getUdpDestinations()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    automaticityConfigs: (f = msg.getAutomaticityConfigs()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    detectionConfidenceThreshold: (f = msg.getDetectionConfidenceThreshold()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    classtimatorAlpha: (f = msg.getClasstimatorAlpha()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    classtimatorIncludeStopped: (f = msg.getClasstimatorIncludeStopped()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    kalmanTrackerJson: (f = msg.getKalmanTrackerJson()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    nvTrackerJson: (f = msg.getNvTrackerJson()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    enableZonalAlerts: (f = msg.getEnableZonalAlerts()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableTurningMovement: (f = msg.getEnableTurningMovement()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableAnpr: (f = msg.getEnableAnpr()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enablePose3d: (f = msg.getEnablePose3d()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableTraffic32: (f = msg.getEnableTraffic32()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableHalfRes: (f = msg.getEnableHalfRes()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalFeaturesIncludeMissingClasses: (f = msg.getZonalFeaturesIncludeMissingClasses()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalFeaturesExcludeExtraClasses: (f = msg.getZonalFeaturesExcludeExtraClasses()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalFeaturesExcludeExtraZones: (f = msg.getZonalFeaturesExcludeExtraZones()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalFeaturesDoNotIncludeMissingZones: (f = msg.getZonalFeaturesDoNotIncludeMissingZones()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalFeaturesDoNotIgnorePredictedTracks: (f = msg.getZonalFeaturesDoNotIgnorePredictedTracks()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableDrawingTrackHeads: (f = msg.getEnableDrawingTrackHeads()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    enableDrawingTrackTails: (f = msg.getEnableDrawingTrackTails()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    disableDrawingMasks: (f = msg.getDisableDrawingMasks()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    disableDrawingZones: (f = msg.getDisableDrawingZones()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    disableDrawingCountlines: (f = msg.getDisableDrawingCountlines()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    notes: (f = msg.getNotes()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    doNotMonitor: (f = msg.getDoNotMonitor()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    doNotMonitorReason: (f = msg.getDoNotMonitorReason()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    alertIfUp: (f = msg.getAlertIfUp()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    alertIfUpReason: (f = msg.getAlertIfUpReason()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    vpWindowedFeaturesEnable: (f = msg.getVpWindowedFeaturesEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    vpWindowedFeaturesTickIntervalMilliseconds: (f = msg.getVpWindowedFeaturesTickIntervalMilliseconds()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    vpWindowedFeaturesProtoclearFieldList: (f = msg.getVpWindowedFeaturesProtoclearFieldList()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    udpDtfDirectionalOccupancySwap: (f = msg.getUdpDtfDirectionalOccupancySwap()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    primaryModelId: (f = msg.getPrimaryModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    primaryPlateModelId: (f = msg.getPrimaryPlateModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    secondaryOcrDetectorModelId: (f = msg.getSecondaryOcrDetectorModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    secondaryClassifierHumanoidModelId: (f = msg.getSecondaryClassifierHumanoidModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    secondary3dPoseModelId: (f = msg.getSecondary3dPoseModelId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    primaryModelResolution: (f = msg.getPrimaryModelResolution()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    doNotCollapse: (f = msg.getDoNotCollapse()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    deprecatedEscootersEnabled: (f = msg.getDeprecatedEscootersEnabled()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    presetId: (f = msg.getPresetId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    presetVersion: (f = msg.getPresetVersion()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    enableTesting: (f = msg.getEnableTesting()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    s4tcDynniqControllerPort: (f = msg.getS4tcDynniqControllerPort()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    hardwarePositionId: (f = msg.getHardwarePositionId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 69, 0),
    enableGpsToCartesianTransform: (f = msg.getEnableGpsToCartesianTransform()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    gpsToCartesianPointsToTransform: (f = msg.getGpsToCartesianPointsToTransform()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    nvtrackerV6: (f = msg.getNvtrackerV6()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    zonalOcclusionDetectionEnable: (f = msg.getZonalOcclusionDetectionEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    glareDetectionEnable: (f = msg.getGlareDetectionEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    zonalImageDegradationResponseEnable: (f = msg.getZonalImageDegradationResponseEnable()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    occlusionPctThreshold: (f = msg.getOcclusionPctThreshold()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    glareIntensityThreshold: (f = msg.getGlareIntensityThreshold()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    cargoBikesEnabled: (f = msg.getCargoBikesEnabled()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    clear3dPosePoints: (f = msg.getClear3dPosePoints()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.VisionProgram}
 */
proto.vivacity.config_v2.VisionProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.VisionProgram;
  return proto.vivacity.config_v2.VisionProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.VisionProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.VisionProgram}
 */
proto.vivacity.config_v2.VisionProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setExtraValues(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setHardwareId(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config_v2.VisionProgram.TransformationType} */ (reader.readEnum());
      msg.setTransformationType(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.config_v2.VisionProgram.DistortionType} */ (reader.readEnum());
      msg.setDistortionType(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setPointsToTransform(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setPointsImageSpace(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setPointsTransformedSpace(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setSupermarioHttpPort(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setSupermarioImage(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setVisionProgramImage(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setCameraNumber(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setKafkaProducerEnable(value);
      break;
    case 14:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setCrossingsEnable(value);
      break;
    case 15:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalSpeedInstantaneousEnable(value);
      break;
    case 16:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalOccupancyInstantaneousEnable(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setProtoclearEnable(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setProtoclearFieldList(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setUdpEnable(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setUdpProtodumpEnable(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setUdpForceRedialEnable(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUdpForceRedialInterval(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setUdpDestinations(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setAutomaticityConfigs(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDetectionConfidenceThreshold(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setClasstimatorAlpha(value);
      break;
    case 27:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setClasstimatorIncludeStopped(value);
      break;
    case 28:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setKalmanTrackerJson(value);
      break;
    case 29:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setNvTrackerJson(value);
      break;
    case 30:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableZonalAlerts(value);
      break;
    case 31:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableTurningMovement(value);
      break;
    case 32:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableAnpr(value);
      break;
    case 33:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnablePose3d(value);
      break;
    case 34:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableTraffic32(value);
      break;
    case 35:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableHalfRes(value);
      break;
    case 36:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalFeaturesIncludeMissingClasses(value);
      break;
    case 37:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalFeaturesExcludeExtraClasses(value);
      break;
    case 38:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalFeaturesExcludeExtraZones(value);
      break;
    case 39:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalFeaturesDoNotIncludeMissingZones(value);
      break;
    case 40:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalFeaturesDoNotIgnorePredictedTracks(value);
      break;
    case 41:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableDrawingTrackHeads(value);
      break;
    case 42:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableDrawingTrackTails(value);
      break;
    case 43:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDisableDrawingMasks(value);
      break;
    case 44:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDisableDrawingZones(value);
      break;
    case 45:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDisableDrawingCountlines(value);
      break;
    case 46:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 47:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setNotes(value);
      break;
    case 48:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDoNotMonitor(value);
      break;
    case 49:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDoNotMonitorReason(value);
      break;
    case 50:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setAlertIfUp(value);
      break;
    case 51:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setAlertIfUpReason(value);
      break;
    case 52:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesEnable(value);
      break;
    case 53:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesTickIntervalMilliseconds(value);
      break;
    case 54:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesProtoclearFieldList(value);
      break;
    case 55:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setUdpDtfDirectionalOccupancySwap(value);
      break;
    case 56:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setPrimaryModelId(value);
      break;
    case 57:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setPrimaryPlateModelId(value);
      break;
    case 58:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setSecondaryOcrDetectorModelId(value);
      break;
    case 59:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setSecondaryClassifierHumanoidModelId(value);
      break;
    case 60:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setSecondary3dPoseModelId(value);
      break;
    case 61:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryModelResolution(value);
      break;
    case 62:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDoNotCollapse(value);
      break;
    case 63:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDeprecatedEscootersEnabled(value);
      break;
    case 64:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setPresetId(value);
      break;
    case 65:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setPresetVersion(value);
      break;
    case 66:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableTesting(value);
      break;
    case 67:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setS4tcDynniqControllerPort(value);
      break;
    case 68:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setHardwarePositionId(value);
      break;
    case 69:
      var value = /** @type {!proto.vivacity.config_v2.VisionProgram.VisionProgramStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 70:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setEnableGpsToCartesianTransform(value);
      break;
    case 71:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setGpsToCartesianPointsToTransform(value);
      break;
    case 72:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setNvtrackerV6(value);
      break;
    case 73:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalOcclusionDetectionEnable(value);
      break;
    case 74:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setGlareDetectionEnable(value);
      break;
    case 75:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setZonalImageDegradationResponseEnable(value);
      break;
    case 76:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setOcclusionPctThreshold(value);
      break;
    case 77:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setGlareIntensityThreshold(value);
      break;
    case 78:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setCargoBikesEnabled(value);
      break;
    case 79:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setClear3dPosePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.VisionProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.VisionProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.VisionProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.VisionProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getExtraValues();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getHardwareId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.VisionProgram.TransformationType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.VisionProgram.DistortionType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPointsToTransform();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getPointsImageSpace();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getPointsTransformedSpace();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getSupermarioHttpPort();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getSupermarioImage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramImage();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCameraNumber();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getKafkaProducerEnable();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCrossingsEnable();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalSpeedInstantaneousEnable();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalOccupancyInstantaneousEnable();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getProtoclearEnable();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getProtoclearFieldList();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getUdpEnable();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getUdpProtodumpEnable();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getUdpForceRedialEnable();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getUdpForceRedialInterval();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUdpDestinations();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getAutomaticityConfigs();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getDetectionConfidenceThreshold();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getClasstimatorAlpha();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getClasstimatorIncludeStopped();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getKalmanTrackerJson();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getNvTrackerJson();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableZonalAlerts();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableTurningMovement();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableAnpr();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnablePose3d();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableTraffic32();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableHalfRes();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalFeaturesIncludeMissingClasses();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalFeaturesExcludeExtraClasses();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalFeaturesExcludeExtraZones();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalFeaturesDoNotIncludeMissingZones();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalFeaturesDoNotIgnorePredictedTracks();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableDrawingTrackHeads();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableDrawingTrackTails();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDisableDrawingMasks();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDisableDrawingZones();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDisableDrawingCountlines();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDoNotMonitor();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDoNotMonitorReason();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAlertIfUp();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAlertIfUpReason();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesEnable();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesTickIntervalMilliseconds();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesProtoclearFieldList();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getUdpDtfDirectionalOccupancySwap();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryModelId();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryPlateModelId();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryOcrDetectorModelId();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryClassifierHumanoidModelId();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getSecondary3dPoseModelId();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryModelResolution();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDoNotCollapse();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDeprecatedEscootersEnabled();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPresetId();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getPresetVersion();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getEnableTesting();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getS4tcDynniqControllerPort();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionId();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.VisionProgram.VisionProgramStatus} */ (jspb.Message.getField(message, 69));
  if (f != null) {
    writer.writeEnum(
      69,
      f
    );
  }
  f = message.getEnableGpsToCartesianTransform();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getGpsToCartesianPointsToTransform();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getNvtrackerV6();
  if (f != null) {
    writer.writeMessage(
      72,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalOcclusionDetectionEnable();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getGlareDetectionEnable();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getZonalImageDegradationResponseEnable();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOcclusionPctThreshold();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getGlareIntensityThreshold();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getCargoBikesEnabled();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getClear3dPosePoints();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.VisionProgram.TransformationType = {
  UNKNOWN_TRANSFORMATION: 0,
  GPS: 1,
  CARTESIAN: 2
};

/**
 * @enum {number}
 */
proto.vivacity.config_v2.VisionProgram.DistortionType = {
  UNKNOWN_DISTORTION: 0,
  DISTORTED: 1,
  UNDISTORTED: 2
};

/**
 * @enum {number}
 */
proto.vivacity.config_v2.VisionProgram.VisionProgramStatus = {
  UNKNOWN: 0,
  QC_COMPLETED: 1,
  CONFIGURED_UNVALIDATED: 2,
  CONFIGURED_VALIDATED: 3,
  STOPPED: 4
};

/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue extra_values = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getExtraValues = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setExtraValues = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearExtraValues = function() {
  return this.setExtraValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasExtraValues = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id hardware_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getHardwareId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setHardwareId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearHardwareId = function() {
  return this.setHardwareId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasHardwareId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TransformationType transformation_type = 4;
 * @return {!proto.vivacity.config_v2.VisionProgram.TransformationType}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getTransformationType = function() {
  return /** @type {!proto.vivacity.config_v2.VisionProgram.TransformationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config_v2.VisionProgram.TransformationType} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.setTransformationType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearTransformationType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasTransformationType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DistortionType distortion_type = 5;
 * @return {!proto.vivacity.config_v2.VisionProgram.DistortionType}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDistortionType = function() {
  return /** @type {!proto.vivacity.config_v2.VisionProgram.DistortionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.config_v2.VisionProgram.DistortionType} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.setDistortionType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDistortionType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDistortionType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.StringArray points_to_transform = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPointsToTransform = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPointsToTransform = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPointsToTransform = function() {
  return this.setPointsToTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPointsToTransform = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Path points_image_space = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPointsImageSpace = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPointsImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPointsImageSpace = function() {
  return this.setPointsImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPointsImageSpace = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Path points_transformed_space = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPointsTransformedSpace = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPointsTransformedSpace = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPointsTransformedSpace = function() {
  return this.setPointsTransformedSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPointsTransformedSpace = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value supermario_http_port = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getSupermarioHttpPort = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearSupermarioHttpPort = function() {
  return this.setSupermarioHttpPort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasSupermarioHttpPort = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.StringValue supermario_image = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getSupermarioImage = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setSupermarioImage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearSupermarioImage = function() {
  return this.setSupermarioImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasSupermarioImage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.StringValue vision_program_image = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getVisionProgramImage = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setVisionProgramImage = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearVisionProgramImage = function() {
  return this.setVisionProgramImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasVisionProgramImage = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value camera_number = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getCameraNumber = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setCameraNumber = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearCameraNumber = function() {
  return this.setCameraNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasCameraNumber = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue kafka_producer_enable = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getKafkaProducerEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setKafkaProducerEnable = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearKafkaProducerEnable = function() {
  return this.setKafkaProducerEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasKafkaProducerEnable = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue crossings_enable = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getCrossingsEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setCrossingsEnable = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearCrossingsEnable = function() {
  return this.setCrossingsEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasCrossingsEnable = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_speed_instantaneous_enable = 15;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalSpeedInstantaneousEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 15));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalSpeedInstantaneousEnable = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalSpeedInstantaneousEnable = function() {
  return this.setZonalSpeedInstantaneousEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalSpeedInstantaneousEnable = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_occupancy_instantaneous_enable = 16;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalOccupancyInstantaneousEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 16));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalOccupancyInstantaneousEnable = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalOccupancyInstantaneousEnable = function() {
  return this.setZonalOccupancyInstantaneousEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalOccupancyInstantaneousEnable = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue protoclear_enable = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getProtoclearEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setProtoclearEnable = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearProtoclearEnable = function() {
  return this.setProtoclearEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasProtoclearEnable = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.StringArray protoclear_field_list = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getProtoclearFieldList = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setProtoclearFieldList = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearProtoclearFieldList = function() {
  return this.setProtoclearFieldList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasProtoclearFieldList = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue udp_enable = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpEnable = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpEnable = function() {
  return this.setUdpEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpEnable = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue udp_protodump_enable = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpProtodumpEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpProtodumpEnable = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpProtodumpEnable = function() {
  return this.setUdpProtodumpEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpProtodumpEnable = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue udp_force_redial_enable = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpForceRedialEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpForceRedialEnable = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpForceRedialEnable = function() {
  return this.setUdpForceRedialEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpForceRedialEnable = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value udp_force_redial_interval = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpForceRedialInterval = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpForceRedialInterval = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpForceRedialInterval = function() {
  return this.setUdpForceRedialInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpForceRedialInterval = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.StringArray udp_destinations = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpDestinations = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpDestinations = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpDestinations = function() {
  return this.setUdpDestinations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpDestinations = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue automaticity_configs = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getAutomaticityConfigs = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setAutomaticityConfigs = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearAutomaticityConfigs = function() {
  return this.setAutomaticityConfigs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasAutomaticityConfigs = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue detection_confidence_threshold = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDetectionConfidenceThreshold = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDetectionConfidenceThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDetectionConfidenceThreshold = function() {
  return this.setDetectionConfidenceThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDetectionConfidenceThreshold = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue classtimator_alpha = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getClasstimatorAlpha = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setClasstimatorAlpha = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearClasstimatorAlpha = function() {
  return this.setClasstimatorAlpha(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasClasstimatorAlpha = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue classtimator_include_stopped = 27;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getClasstimatorIncludeStopped = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 27));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setClasstimatorIncludeStopped = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearClasstimatorIncludeStopped = function() {
  return this.setClasstimatorIncludeStopped(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasClasstimatorIncludeStopped = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue kalman_tracker_json = 28;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getKalmanTrackerJson = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 28));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setKalmanTrackerJson = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearKalmanTrackerJson = function() {
  return this.setKalmanTrackerJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasKalmanTrackerJson = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue nv_tracker_json = 29;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getNvTrackerJson = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 29));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setNvTrackerJson = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearNvTrackerJson = function() {
  return this.setNvTrackerJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasNvTrackerJson = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_zonal_alerts = 30;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableZonalAlerts = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 30));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableZonalAlerts = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableZonalAlerts = function() {
  return this.setEnableZonalAlerts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableZonalAlerts = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_turning_movement = 31;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableTurningMovement = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 31));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableTurningMovement = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableTurningMovement = function() {
  return this.setEnableTurningMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableTurningMovement = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_anpr = 32;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableAnpr = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 32));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableAnpr = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableAnpr = function() {
  return this.setEnableAnpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableAnpr = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_pose_3d = 33;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnablePose3d = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 33));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnablePose3d = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnablePose3d = function() {
  return this.setEnablePose3d(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnablePose3d = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_traffic32 = 34;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableTraffic32 = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 34));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableTraffic32 = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableTraffic32 = function() {
  return this.setEnableTraffic32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableTraffic32 = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_half_res = 35;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableHalfRes = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 35));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableHalfRes = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableHalfRes = function() {
  return this.setEnableHalfRes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableHalfRes = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_features_include_missing_classes = 36;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalFeaturesIncludeMissingClasses = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 36));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalFeaturesIncludeMissingClasses = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalFeaturesIncludeMissingClasses = function() {
  return this.setZonalFeaturesIncludeMissingClasses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalFeaturesIncludeMissingClasses = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_features_exclude_extra_classes = 37;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalFeaturesExcludeExtraClasses = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 37));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalFeaturesExcludeExtraClasses = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalFeaturesExcludeExtraClasses = function() {
  return this.setZonalFeaturesExcludeExtraClasses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalFeaturesExcludeExtraClasses = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_features_exclude_extra_zones = 38;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalFeaturesExcludeExtraZones = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 38));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalFeaturesExcludeExtraZones = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalFeaturesExcludeExtraZones = function() {
  return this.setZonalFeaturesExcludeExtraZones(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalFeaturesExcludeExtraZones = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_features_do_not_include_missing_zones = 39;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalFeaturesDoNotIncludeMissingZones = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 39));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalFeaturesDoNotIncludeMissingZones = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalFeaturesDoNotIncludeMissingZones = function() {
  return this.setZonalFeaturesDoNotIncludeMissingZones(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalFeaturesDoNotIncludeMissingZones = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_features_do_not_ignore_predicted_tracks = 40;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalFeaturesDoNotIgnorePredictedTracks = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 40));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalFeaturesDoNotIgnorePredictedTracks = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalFeaturesDoNotIgnorePredictedTracks = function() {
  return this.setZonalFeaturesDoNotIgnorePredictedTracks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalFeaturesDoNotIgnorePredictedTracks = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_drawing_track_heads = 41;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableDrawingTrackHeads = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 41));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableDrawingTrackHeads = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableDrawingTrackHeads = function() {
  return this.setEnableDrawingTrackHeads(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableDrawingTrackHeads = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_drawing_track_tails = 42;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableDrawingTrackTails = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 42));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableDrawingTrackTails = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableDrawingTrackTails = function() {
  return this.setEnableDrawingTrackTails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableDrawingTrackTails = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue disable_drawing_masks = 43;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDisableDrawingMasks = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 43));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDisableDrawingMasks = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDisableDrawingMasks = function() {
  return this.setDisableDrawingMasks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDisableDrawingMasks = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue disable_drawing_zones = 44;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDisableDrawingZones = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 44));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDisableDrawingZones = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDisableDrawingZones = function() {
  return this.setDisableDrawingZones(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDisableDrawingZones = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue disable_drawing_countlines = 45;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDisableDrawingCountlines = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 45));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDisableDrawingCountlines = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDisableDrawingCountlines = function() {
  return this.setDisableDrawingCountlines(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDisableDrawingCountlines = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional PrimitiveWrapper.StringValue description = 46;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDescription = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 46));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional PrimitiveWrapper.StringValue notes = 47;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getNotes = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 47));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue do_not_monitor = 48;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDoNotMonitor = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 48));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDoNotMonitor = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDoNotMonitor = function() {
  return this.setDoNotMonitor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDoNotMonitor = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional PrimitiveWrapper.StringValue do_not_monitor_reason = 49;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDoNotMonitorReason = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 49));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDoNotMonitorReason = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDoNotMonitorReason = function() {
  return this.setDoNotMonitorReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDoNotMonitorReason = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue alert_if_up = 50;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getAlertIfUp = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 50));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setAlertIfUp = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearAlertIfUp = function() {
  return this.setAlertIfUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasAlertIfUp = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional PrimitiveWrapper.StringValue alert_if_up_reason = 51;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getAlertIfUpReason = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 51));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setAlertIfUpReason = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearAlertIfUpReason = function() {
  return this.setAlertIfUpReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasAlertIfUpReason = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue vp_windowed_features_enable = 52;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getVpWindowedFeaturesEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 52));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setVpWindowedFeaturesEnable = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearVpWindowedFeaturesEnable = function() {
  return this.setVpWindowedFeaturesEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasVpWindowedFeaturesEnable = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value vp_windowed_features_tick_interval_milliseconds = 53;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getVpWindowedFeaturesTickIntervalMilliseconds = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 53));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setVpWindowedFeaturesTickIntervalMilliseconds = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearVpWindowedFeaturesTickIntervalMilliseconds = function() {
  return this.setVpWindowedFeaturesTickIntervalMilliseconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasVpWindowedFeaturesTickIntervalMilliseconds = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional PrimitiveWrapper.StringArray vp_windowed_features_protoclear_field_list = 54;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getVpWindowedFeaturesProtoclearFieldList = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 54));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setVpWindowedFeaturesProtoclearFieldList = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearVpWindowedFeaturesProtoclearFieldList = function() {
  return this.setVpWindowedFeaturesProtoclearFieldList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasVpWindowedFeaturesProtoclearFieldList = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue udp_dtf_directional_occupancy_swap = 55;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getUdpDtfDirectionalOccupancySwap = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 55));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setUdpDtfDirectionalOccupancySwap = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearUdpDtfDirectionalOccupancySwap = function() {
  return this.setUdpDtfDirectionalOccupancySwap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasUdpDtfDirectionalOccupancySwap = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id primary_model_id = 56;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPrimaryModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 56));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPrimaryModelId = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPrimaryModelId = function() {
  return this.setPrimaryModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPrimaryModelId = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id primary_plate_model_id = 57;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPrimaryPlateModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 57));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPrimaryPlateModelId = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPrimaryPlateModelId = function() {
  return this.setPrimaryPlateModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPrimaryPlateModelId = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id secondary_ocr_detector_model_id = 58;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getSecondaryOcrDetectorModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 58));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setSecondaryOcrDetectorModelId = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearSecondaryOcrDetectorModelId = function() {
  return this.setSecondaryOcrDetectorModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasSecondaryOcrDetectorModelId = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id secondary_classifier_humanoid_model_id = 59;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getSecondaryClassifierHumanoidModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 59));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setSecondaryClassifierHumanoidModelId = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearSecondaryClassifierHumanoidModelId = function() {
  return this.setSecondaryClassifierHumanoidModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasSecondaryClassifierHumanoidModelId = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id secondary_3d_pose_model_id = 60;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getSecondary3dPoseModelId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 60));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setSecondary3dPoseModelId = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearSecondary3dPoseModelId = function() {
  return this.setSecondary3dPoseModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasSecondary3dPoseModelId = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional PrimitiveWrapper.StringValue primary_model_resolution = 61;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPrimaryModelResolution = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 61));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPrimaryModelResolution = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPrimaryModelResolution = function() {
  return this.setPrimaryModelResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPrimaryModelResolution = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue do_not_collapse = 62;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDoNotCollapse = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 62));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDoNotCollapse = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDoNotCollapse = function() {
  return this.setDoNotCollapse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDoNotCollapse = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue deprecated_escooters_enabled = 63;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getDeprecatedEscootersEnabled = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 63));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setDeprecatedEscootersEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearDeprecatedEscootersEnabled = function() {
  return this.setDeprecatedEscootersEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasDeprecatedEscootersEnabled = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id preset_id = 64;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPresetId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 64));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPresetId = function(value) {
  return jspb.Message.setWrapperField(this, 64, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPresetId = function() {
  return this.setPresetId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPresetId = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value preset_version = 65;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getPresetVersion = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 65));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setPresetVersion = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearPresetVersion = function() {
  return this.setPresetVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasPresetVersion = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_testing = 66;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableTesting = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 66));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableTesting = function(value) {
  return jspb.Message.setWrapperField(this, 66, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableTesting = function() {
  return this.setEnableTesting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableTesting = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value s4tc_dynniq_controller_port = 67;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getS4tcDynniqControllerPort = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 67));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setS4tcDynniqControllerPort = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearS4tcDynniqControllerPort = function() {
  return this.setS4tcDynniqControllerPort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasS4tcDynniqControllerPort = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id hardware_position_id = 68;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getHardwarePositionId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 68));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setHardwarePositionId = function(value) {
  return jspb.Message.setWrapperField(this, 68, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearHardwarePositionId = function() {
  return this.setHardwarePositionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasHardwarePositionId = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional VisionProgramStatus status = 69;
 * @return {!proto.vivacity.config_v2.VisionProgram.VisionProgramStatus}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config_v2.VisionProgram.VisionProgramStatus} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {!proto.vivacity.config_v2.VisionProgram.VisionProgramStatus} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 69, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 69, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue enable_gps_to_cartesian_transform = 70;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getEnableGpsToCartesianTransform = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 70));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setEnableGpsToCartesianTransform = function(value) {
  return jspb.Message.setWrapperField(this, 70, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearEnableGpsToCartesianTransform = function() {
  return this.setEnableGpsToCartesianTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasEnableGpsToCartesianTransform = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional PrimitiveWrapper.StringArray gps_to_cartesian_points_to_transform = 71;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getGpsToCartesianPointsToTransform = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 71));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setGpsToCartesianPointsToTransform = function(value) {
  return jspb.Message.setWrapperField(this, 71, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearGpsToCartesianPointsToTransform = function() {
  return this.setGpsToCartesianPointsToTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasGpsToCartesianPointsToTransform = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue nvtracker_v6 = 72;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getNvtrackerV6 = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 72));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setNvtrackerV6 = function(value) {
  return jspb.Message.setWrapperField(this, 72, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearNvtrackerV6 = function() {
  return this.setNvtrackerV6(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasNvtrackerV6 = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_occlusion_detection_enable = 73;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalOcclusionDetectionEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 73));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalOcclusionDetectionEnable = function(value) {
  return jspb.Message.setWrapperField(this, 73, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalOcclusionDetectionEnable = function() {
  return this.setZonalOcclusionDetectionEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalOcclusionDetectionEnable = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue glare_detection_enable = 74;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getGlareDetectionEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 74));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setGlareDetectionEnable = function(value) {
  return jspb.Message.setWrapperField(this, 74, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearGlareDetectionEnable = function() {
  return this.setGlareDetectionEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasGlareDetectionEnable = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue zonal_image_degradation_response_enable = 75;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getZonalImageDegradationResponseEnable = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 75));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setZonalImageDegradationResponseEnable = function(value) {
  return jspb.Message.setWrapperField(this, 75, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearZonalImageDegradationResponseEnable = function() {
  return this.setZonalImageDegradationResponseEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasZonalImageDegradationResponseEnable = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value occlusion_pct_threshold = 76;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getOcclusionPctThreshold = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 76));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setOcclusionPctThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 76, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearOcclusionPctThreshold = function() {
  return this.setOcclusionPctThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasOcclusionPctThreshold = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value glare_intensity_threshold = 77;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getGlareIntensityThreshold = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 77));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setGlareIntensityThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearGlareIntensityThreshold = function() {
  return this.setGlareIntensityThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasGlareIntensityThreshold = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue cargo_bikes_enabled = 78;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getCargoBikesEnabled = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 78));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setCargoBikesEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearCargoBikesEnabled = function() {
  return this.setCargoBikesEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasCargoBikesEnabled = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue clear_3d_pose_points = 79;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.VisionProgram.prototype.getClear3dPosePoints = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 79));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
*/
proto.vivacity.config_v2.VisionProgram.prototype.setClear3dPosePoints = function(value) {
  return jspb.Message.setWrapperField(this, 79, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgram} returns this
 */
proto.vivacity.config_v2.VisionProgram.prototype.clearClear3dPosePoints = function() {
  return this.setClear3dPosePoints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgram.prototype.hasClear3dPosePoints = function() {
  return jspb.Message.getField(this, 79) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.VisionProgramToClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.VisionProgramToClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.VisionProgramToClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: (f = msg.getVisionProgramId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    classId: (f = msg.getClassId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    collapseTargetClassId: (f = msg.getCollapseTargetClassId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    detectionConfidenceThreshold: (f = msg.getDetectionConfidenceThreshold()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    confidenceBoost: (f = msg.getConfidenceBoost()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass}
 */
proto.vivacity.config_v2.VisionProgramToClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.VisionProgramToClass;
  return proto.vivacity.config_v2.VisionProgramToClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.VisionProgramToClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass}
 */
proto.vivacity.config_v2.VisionProgramToClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setClassId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setCollapseTargetClassId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDetectionConfidenceThreshold(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setConfidenceBoost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.VisionProgramToClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.VisionProgramToClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.VisionProgramToClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getClassId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getCollapseTargetClassId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getDetectionConfidenceThreshold();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getConfidenceBoost();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id vision_program_id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.getVisionProgramId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
*/
proto.vivacity.config_v2.VisionProgramToClass.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.clearVisionProgramId = function() {
  return this.setVisionProgramId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.hasVisionProgramId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id class_id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.getClassId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
*/
proto.vivacity.config_v2.VisionProgramToClass.prototype.setClassId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.clearClassId = function() {
  return this.setClassId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.hasClassId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id collapse_target_class_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.getCollapseTargetClassId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
*/
proto.vivacity.config_v2.VisionProgramToClass.prototype.setCollapseTargetClassId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.clearCollapseTargetClassId = function() {
  return this.setCollapseTargetClassId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.hasCollapseTargetClassId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue detection_confidence_threshold = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.getDetectionConfidenceThreshold = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
*/
proto.vivacity.config_v2.VisionProgramToClass.prototype.setDetectionConfidenceThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.clearDetectionConfidenceThreshold = function() {
  return this.setDetectionConfidenceThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.hasDetectionConfidenceThreshold = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue confidence_boost = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.getConfidenceBoost = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
*/
proto.vivacity.config_v2.VisionProgramToClass.prototype.setConfidenceBoost = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.VisionProgramToClass} returns this
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.clearConfidenceBoost = function() {
  return this.setConfidenceBoost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.VisionProgramToClass.prototype.hasConfidenceBoost = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
