import React, { FC, Fragment } from "react";
import { observer } from "mobx-react";
import { PlayerUIStore } from "../../../stores/playerUI.store";
import { UrlStore } from "../../../stores/url.store";
import PolyLine from "./PolyLine/PolyLine";
import _ from "lodash";
import { action } from "mobx";
import * as PIXI from "pixi.js";
import { Container, Sprite } from "react-pixi-fiber/index";

export const Countlines: FC<{ playerUIStore: PlayerUIStore; urlStore: UrlStore }> = observer(props => {
  const { playerUIStore, urlStore } = props;

  if (!playerUIStore.showCountlines) {
    return null;
  }

  // Use this dummy observation to force a re-render every time we play/pause to fix weird non-interactivity issue
  // If we don't do this, countlines are non-interactive
  const dummy = playerUIStore.throttledCurrentFrameNumber;

  const countlines: JSX.Element[] = [];
  playerUIStore.countlineGeometries.forEach((geometry, countlineID) => {
    const active = props.urlStore.selectedCountline === countlineID;

    const onClickCountline: (e: PIXI.InteractionEvent) => void = action("select/deselect countline ID", e => {
      if (props.urlStore.selectedCountline === countlineID) {
        urlStore.setParams({ selectedCountline: undefined }, "replaceIn");
      } else {
        urlStore.setParams({ selectedCountline: countlineID }, "replaceIn");
      }
      playerUIStore.setMouseOverCountline(countlineID, false);
    });

    let index = 0;
    countlines.push(
      ..._.chunk(geometry, 4).map(geom => {
        index += 1;
        return (
          <PolyLine
            key={`countline-${countlineID}-segment-${index}`}
            id={countlineID}
            xy={_.flatten(geom)}
            lineColor={active ? 0xff0000 : 0x3f3f3f}
            lineWidth={active || playerUIStore.mouseOverByCountlineID.get(countlineID) ? 7 : 5}
            alpha={active ? 0.8 : 0.6}
            interactive={true}
            interactiveChildren={true}
            cursor={"pointer"}
            hitAreaWidth={20}
            pointerover={e => {
              playerUIStore.setMouseOverCountline(countlineID, true);
            }}
            pointerout={e => {
              playerUIStore.setMouseOverCountline(countlineID, false);
            }}
            pointerup={onClickCountline}
          />
        );
      })
    );
  });

  return <>{countlines}</>;
});
