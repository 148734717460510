import React from "react";
import { observer } from "mobx-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { VPID, VPIDInfo } from "../../domain";
import { toJS } from "mobx";
import { Link } from "react-router-dom";
import { RoutePath, ValidationMode } from "../../enums";
import { FreneticityButton } from "../styledElements";
import { Grid } from "@mui/material";
import { URL_QUERY_PARAMS } from "../../stores/url.store";
import { useStores } from "../../hooks/useStores.hook";

const idColumn: GridColDef<TableVPIDInfo, VPID> = {
  field: "id",
  headerName: "VPID",
  flex: 0.1,
  valueFormatter: v => v.value,
};

const projectColumn: GridColDef<TableVPIDInfo, string> = {
  field: "project",
  headerName: "Project",
  flex: 0.6,
  valueFormatter: v => v.value,
};

const hardwareColumn: GridColDef<TableVPIDInfo, number> = {
  field: "hardwareID",
  headerName: "Hardware ID",
  flex: 0.1,
  valueFormatter: v => v.value,
};

const sensorNumberColumn: GridColDef<TableVPIDInfo, number> = {
  field: "sensorNumber",
  headerName: "Sensor Number",
  flex: 0.1,
  valueFormatter: v => v.value,
};

const actionsColumn: GridColDef<TableVPIDInfo> = {
  field: "actions",
  headerName: "Actions",
  sortable: false,
  filterable: false,
  flex: 1,
  renderCell: ({ row }) => {
    const params = new URLSearchParams(location.search);
    params.set(URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM, row.id.toString(10));
    params.set(URL_QUERY_PARAMS.SEARCH, "");

    return (
      <Grid container spacing={1} alignItems={"center"} style={{ minHeight: 67 }}>
        <Grid item>
          <Link
            to={{
              pathname: RoutePath.Videos,
              search: params.toString(),
            }}
            style={{ textDecoration: "none" }}
          >
            <FreneticityButton variant="outlined">Videos</FreneticityButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to={{
              pathname: RoutePath.ComputerVisionRuns,
              search: params.toString(),
            }}
            style={{ textDecoration: "none" }}
          >
            <FreneticityButton variant="outlined">CV Runs</FreneticityButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to={{
              pathname: RoutePath.ValidationRuns,
              search: params.toString(),
            }}
            style={{ textDecoration: "none" }}
          >
            <FreneticityButton variant="outlined">Validation Runs</FreneticityButton>
          </Link>
        </Grid>
        <Grid item>
          <a
            href={`https://centricity.vivacitylabs.com/visionProgram/${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FreneticityButton variant="outlined">CM</FreneticityButton>
          </a>
        </Grid>
      </Grid>
    );
  },
};

interface VPIDInfoChildCounts {
  childVideoCount: number;
  childCVRunCount: number;
  childValidationRunCount: number;
  VPIsHydrated: boolean;
}

type TableVPIDInfo = VPIDInfo & VPIDInfoChildCounts;

export const VisionProgramTable = observer(() => {
  const { entitiesStore, urlStore } = useStores();
  const { selectedValidationMode } = urlStore;

  const isNearMissMode = selectedValidationMode === ValidationMode.NearMissValidation;

  const tableInfo: TableVPIDInfo[] = entitiesStore.filteredVisionPrograms
    .filter(vp => (isNearMissMode ? vp.isNearMissConfigured : true))
    .map(vp => {
      const VPIsHydrated = entitiesStore.hydratedVPIDs.get(vp.id) || false;
      const childVideoCount = entitiesStore.videosByVPID.get(vp.id)?.size || 0;
      const childCVRunCount = entitiesStore.getComputerVisionRunsForVPID(vp.id).length;
      const childValidationRunCount = entitiesStore.getValidationRunsForVPID(vp.id).length;
      return {
        ...vp,
        childVideoCount,
        childCVRunCount,
        childValidationRunCount,
        VPIsHydrated,
      };
    });

  return (
    <DataGrid
      rows={toJS(tableInfo)}
      columns={[idColumn, projectColumn, hardwareColumn, sensorNumberColumn, actionsColumn]}
      getRowHeight={() => "auto"}
    />
  );
});
