import React, { FC, useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useStores } from "../hooks/useStores.hook";
import { observer } from "mobx-react-lite";
import { ApiEndpointPath, getApiEndpoint } from "../stores/apiStore";

const useLocalStorage = (storageKey, fallbackState) => {
  const initialState = localStorage.getItem(storageKey)
    ? JSON.parse(localStorage.getItem(storageKey)!) ?? fallbackState
    : fallbackState;
  const [value, setValue] = React.useState(initialState);

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

export const JwtProvider: FC<{}> = observer(({ children }) => {
  const { apiStore } = useStores();
  const [hasPerformedInitialCheck, setHasPerformedInitialCheck] = useState(false);
  const [jwt, setJwt] = useLocalStorage("JWT_KEY", null);
  const [error, setError] = useState<string>("");

  const submitJwt = async () => {
    const response = await apiStore.authenticatedFetch(getApiEndpoint(ApiEndpointPath.JWT_AUTHENTICATOR), {}, jwt);
    if (response.status !== 200) {
      setError(response.statusText);
    } else {
      apiStore.setJwt(jwt);
    }
  };

  useEffect(() => {
    const performInitialCheck = async () => {
      await submitJwt();
      setHasPerformedInitialCheck(true);
    };

    performInitialCheck();
  }, []);

  if (!hasPerformedInitialCheck) {
    return <></>;
  }
  if (apiStore.jwt) {
    return <>{children}</>;
  } else {
    return (
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField placeholder="Input JWT here" rows={4} onChange={event => setJwt(event.target.value)} />
        <Button variant="outlined" onClick={submitJwt}>
          {" "}
          Check JWT{" "}
        </Button>
        {error ? <h5>{error}</h5> : ""}
      </Box>
    );
  }
});
