export const trackHeadTextFuncWithClassConfidenceMap =
  '`${trackHead.trackNumber} ${classLookup[trackHead.trackClass]} \n\
${_.join(\n\
_.take(\n\
  trackHead.cumulativeClassConfidenceList.map(\n\
    conf => classLookup[conf.classType] + " " + conf.classConfidence.toFixed(2)\n\
  ),\n\
  5\n\
),\n\
"\\n"\n\
)}`';
