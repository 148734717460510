import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Select,
  Slide,
  Slider,
} from "@mui/material";

import { useStores } from "../../hooks/useStores.hook";

import { FreneticityButton } from "../styledElements";
import { TransitionProps } from "@mui/material/transitions";
import { ValidationRunStore } from "../../stores/validationRun.store";
import _ from "lodash";
import { Sprite, Stage, usePixiApp, Container, Text } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";

import curlingStonePng from "./curling_stone.png";
import broomPng from "./broom.png";
import Circle from "../PlayerUI/PlayerDrawing/Circle";
import Line from "../PlayerUI/PlayerDrawing/Line";

const curlingStone = PIXI.Texture.from(curlingStonePng, {
  scaleMode: PIXI.SCALE_MODES.LINEAR,
  multisample: PIXI.MSAA_QUALITY.HIGH,
});

const broom = PIXI.Texture.from(broomPng, {
  scaleMode: PIXI.SCALE_MODES.LINEAR,
  multisample: PIXI.MSAA_QUALITY.HIGH,
});

const SliderConfirmation: FC<{ validationRunStore: ValidationRunStore; onClose: () => void }> = ({
  validationRunStore,
  onClose,
}) => {
  const [sliderCertainty, setSliderCertainty] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const nextValueFunc = useMemo(() => {
    const a = Math.floor(Math.random() * 40) / 5;
    const b = Math.floor(Math.random() * Math.floor(a)) / 5;
    let x = 0;
    const nextValue = (): number => {
      x += 1;
      return a * x - b;
    };
    return nextValue;
  }, []);

  const handleConfirm = () => {
    if (sliderCertainty < 100) {
      setErrorMessage("You have to be 100% sure that you want to delete this validation run");
      return;
    }
    if (sliderCertainty > 100) {
      setErrorMessage(
        `You can't be more than 100% sure that you want to delete the validation run, that is overly confident.`
      );
      return;
    }
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Fragment>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        How sure are you that you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>?
        <br />
        <br />
        This operation is final and cannot be undone.
        <Slider
          min={0.0}
          max={150}
          step={0.2}
          style={{ paddingTop: "50px", minWidth: "700px" }}
          value={sliderCertainty}
          defaultValue={0}
          aria-label="Certainty"
          valueLabelDisplay="on"
          valueLabelFormat={value => {
            return value.toFixed(2) + "%";
          }}
          onChange={(e, newValue) => {
            setSliderCertainty(newValue as number);
          }}
          onKeyDown={e => e.preventDefault()}
        />
        {errorMessage !== "" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          color="error"
          onClick={handleConfirm}
          onMouseOver={() => setSliderCertainty(prev => prev - nextValueFunc())}
        >
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Fragment>
  );
};

const TextConfirmation: FC<{ validationRunStore: ValidationRunStore; onClose: () => void }> = ({
  validationRunStore,
  onClose,
}) => {
  const [confirmationText, setConfirmationText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleConfirm = () => {
    if (confirmationText !== "DELETE") {
      setErrorMessage("You have to type 'DELETE' to confirm deletion");
      return;
    }
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Fragment>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>? <br />
        <br />
        This operation is final and cannot be undone.
        <br />
        Please type DELETE to confirm:
        <Input
          sx={{ p: 1 }}
          value={confirmationText}
          onChange={e => {
            let value = e.target.value;
            if (value.endsWith("E") && Math.random() > 0.05) {
              value = _.trimEnd(value, "E");
            }
            if (Math.random() > 0.85) {
              value += "E".repeat(Math.round(Math.random() * 6));
            }
            setConfirmationText(value);
          }}
        />
        {errorMessage !== "" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          color="error"
          onClick={handleConfirm}
          onMouseOver={() => setConfirmationText(prev => prev + "!")}
        >
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Fragment>
  );
};

const DropdownConfirmation: FC<{ validationRunStore: ValidationRunStore; onClose: () => void }> = ({
  validationRunStore,
  onClose,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [choice, listOfCountries] = useMemo(() => {
    const list = [
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas (the)",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia (Plurinational State of)",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory (the)",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cayman Islands (the)",
      "Central African Republic (the)",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands (the)",
      "Colombia",
      "Comoros (the)",
      "Congo (the Democratic Republic of the)",
      "Congo (the)",
      "Cook Islands (the)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czechia",
      "Côte d'Ivoire",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic (the)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Falkland Islands (the) [Malvinas]",
      "Faroe Islands (the)",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories (the)",
      "Gabon",
      "Gambia (the)",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (the)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea (the Democratic People's Republic of)",
      "Korea (the Republic of)",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic (the)",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands (the)",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia (Federated States of)",
      "Moldova (the Republic of)",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands (the)",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger (the)",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands (the)",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine, State of",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines (the)",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of North Macedonia",
      "Romania",
      "Russian Federation (the)",
      "Rwanda",
      "Réunion",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan (the)",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands (the)",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (the)",
      "United Kingdom of Great Britain and Northern Ireland (the)",
      "United States Minor Outlying Islands (the)",
      "United States of America (the)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      "Åland Islands",
    ];

    const possibleChoices = [
      "I am sure I want to delete this validation",
      "Please delete this validation",
      "Yes I want to delete this validation",
      `Validation ${validationRunStore.proposedDeletionValidationRunID} must be deleted!`,
    ];

    const theChoice = possibleChoices[Math.floor(Math.random() * possibleChoices.length)];
    list.push(theChoice);
    list.sort();
    return [theChoice, list];
  }, []);

  const [selected, setSelected] = useState(listOfCountries[0]);

  const handleConfirm = () => {
    if (selected !== choice) {
      setErrorMessage("Please select from the list");
      return;
    }
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Fragment>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>? <br />
        <br />
        This operation is final and cannot be undone.
        <br />
        <br />
        Please select your choice from the list below:
        <br />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          label="Selection"
          onChange={e => {
            setSelected(e.target.value as string);
          }}
        >
          {listOfCountries.map(country => {
            return (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            );
          })}
        </Select>
        {errorMessage !== "" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton color="error" onClick={handleConfirm}>
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Fragment>
  );
};

const M6Confirmation: FC<{ validationRunStore: ValidationRunStore; onClose: () => void }> = ({
  validationRunStore,
  onClose,
}) => {
  const [chosenJunction, setChosenJunction] = useState("");
  const [attempts, setAttempts] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const junctions = [
    "J45",
    "J44",
    "J43",
    "J42",
    "J41",
    "J40",
    "J39",
    "J38",
    "J37",
    "J36",
    "J35",
    "J34",
    "J33",
    "J32",
    "J31A",
    "J31",
    "J30",
    "J29",
    "J28",
    "J27",
    "J26",
    "J25",
    "J24",
    "J23",
    "J22",
    "J21A",
    "J21",
    "J20",
    "J19",
    "J18",
    "J17",
    "J16",
    "J15",
    "J14",
    "J13",
    "J12",
    "J11|11A",
    "J10A",
    "J10",
    "J9",
    "J8",
    "J7",
    "J6",
    "J5",
    "J4A",
    "J3A|4",
    "J3",
    "J2",
    "J1",
    "M1 J19",
  ];

  const correctChoice = useMemo(() => {
    return junctions[Math.floor(Math.random() * junctions.length)];
  }, []);

  const handleConfirm = () => {
    if (chosenJunction !== correctChoice) {
      setAttempts(prev => prev + 1);
      if (attempts > 5) {
        setErrorMessage(
          "Incorrect, please try again. Some examples are: " + _.take(_.shuffle(junctions), 20).join(", ")
        );
        return;
      }
      setErrorMessage("Incorrect, please try again");
      return;
    }
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Fragment>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>? <br />
        <br />
        This operation is final and cannot be undone.
        <br />
        Please name a junction on the M6 to confirm:
        <Input
          value={chosenJunction}
          sx={{ p: 1 }}
          onChange={e => {
            setChosenJunction(e.target.value);
          }}
        />
        {errorMessage !== "" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton color="error" onClick={handleConfirm}>
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Fragment>
  );
};

const CurlingStage: FC<{ setIsInZone: (val: boolean) => void }> = ({ setIsInZone }) => {
  const app = usePixiApp();

  const [coefficientOfFriction, setCoefficientOfFriction] = useState(0.01);
  const [sweepingIncreasesFriction, setSweepingIncreasesFriction] = useState(false);
  const [speedX, setSpeedX] = useState(0);
  const [speedY, setSpeedY] = useState(0);
  const [positionX, setPositionX] = useState(50);
  const [positionY, setPositionY] = useState(400);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [hasReleased, setHasReleased] = useState(false);
  const [success, setSuccess] = useState(false);
  const lastMouse = useRef<PIXI.Point>(new PIXI.Point(0, 0));
  const lastMouse2 = useRef<PIXI.Point>(new PIXI.Point(0, 0));

  const reset = () => {
    setCoefficientOfFriction(0.01);
    setSpeedX(0);
    setSpeedY(0);
    setPositionX(50);
    setPositionY(400);
    setIsGrabbing(false);
    setIsScrubbing(false);
    setHasReleased(false);
    setSuccess(false);
    lastMouse.current.x = 0;
    lastMouse.current.y = 0;
    lastMouse2.current.x = 0;
    lastMouse2.current.y = 0;
  };

  useEffect(() => {
    const windowPointerUp = (e: MouseEvent) => {
      if (isGrabbing) {
        setIsGrabbing(false);
        setHasReleased(true);
      }
      if (hasReleased) {
        setIsScrubbing(false);
        setCoefficientOfFriction(0.01);
      }
    };
    window.addEventListener("pointerup", windowPointerUp);
    return function cleanup() {
      window.removeEventListener("pointerup", windowPointerUp);
    };
  }, [isGrabbing, hasReleased, coefficientOfFriction, isScrubbing]);

  const animate = useCallback(
    delta => {
      if (!isGrabbing) {
        setPositionX(posX => posX + speedX * delta - 0.5 * coefficientOfFriction * speedX * delta ** 2);
        setPositionY(posY => posY + speedY * delta - 0.5 * coefficientOfFriction * speedY * delta ** 2);
        setSpeedX(vX => vX - coefficientOfFriction * vX * delta);
        setSpeedY(vY => vY - coefficientOfFriction * vY * delta);
      }
    },
    [speedX, speedY, coefficientOfFriction, isGrabbing, hasReleased]
  );

  useEffect(() => {
    app?.ticker?.add(animate);

    return () => {
      app?.ticker?.remove(animate);
    };
  }, [app, app.ticker, animate]);

  const gameOver = hasReleased && speedX < 0.1 && speedX > -0.1;

  const targetX = 3600;
  const targetY = 400;
  const targetRadius = 80;

  const targetDistanceX = 3600 - positionX;
  const targetDistanceY = 400 - positionY;
  const isInRange = Math.sqrt(targetDistanceX * targetDistanceX + targetDistanceY * targetDistanceY) <= targetRadius;
  if (gameOver) {
    if (isInRange) {
      if (!success) {
        setIsInZone(true);
        setSuccess(true);
      }
    }
  }

  const rightScrollX = hasReleased ? -positionX + 800 : 0;

  const onPointerMoveContainer = (e: PIXI.InteractionEvent) => {
    const deltaY = Math.abs(e.data.global.y - lastMouse.current.y);
    lastMouse2.current.x = lastMouse.current.x;
    lastMouse2.current.y = lastMouse.current.y;
    lastMouse.current.x = e.data.global.x;
    lastMouse.current.y = e.data.global.y;
    if (isScrubbing) {
      setCoefficientOfFriction(prev =>
        Math.max(0, Math.min(1, prev + (sweepingIncreasesFriction ? 0.00001 : -0.000001) * deltaY))
      );
    }
  };

  const onPointerDownContainer = (e: PIXI.InteractionEvent) => {
    if (hasReleased) {
      setIsScrubbing(true);
    }
  };

  const onPointerUpContainer = (e: PIXI.InteractionEvent) => {
    if (isGrabbing) {
      setIsGrabbing(false);
    }
    if (!hasReleased && isGrabbing) {
      setHasReleased(true);
    }
    if (hasReleased) {
      setIsScrubbing(false);
      setCoefficientOfFriction(0.01);
    }
  };

  return (
    <Container
      interactive={true}
      interactiveChildren={true}
      pointermove={onPointerMoveContainer}
      pointerdown={onPointerDownContainer}
      pointerup={onPointerUpContainer}
    >
      <Container x={rightScrollX}>
        {_.range(400, targetX, 400).map(x => (
          <Fragment key={x}>
            <Text
              text={`${(x / 80).toFixed(0)}m`}
              x={x}
              y={80}
              anchor={"0.5,0.5"}
              style={new PIXI.TextStyle({ fill: 0x999999, fontSize: 20, align: "center" })}
            />
            <Line x1={x} x2={x} y1={100} y2={700} lineColor={0xcccccc} lineWidth={5} />
          </Fragment>
        ))}
        <Circle x={targetX} y={targetY} radius={240} lineColor={0x0000dd} lineWidth={40} />
        <Circle
          x={targetX}
          y={targetY}
          radius={targetRadius}
          lineColor={isInRange ? 0xff0000 : 0xdd0000}
          lineWidth={40}
        />
        <Text
          text={"Confirmation\nzone"}
          x={targetX}
          y={targetY}
          anchor={"0.5,0.5"}
          style={new PIXI.TextStyle({ fill: 0x0000dd, fontSize: 20, align: "center" })}
        />
        <Sprite
          texture={curlingStone}
          cursor={hasReleased ? "not-allowed" : isGrabbing ? "grabbing" : "grab"}
          interactive={true}
          pointerdown={e => {
            if (!hasReleased) {
              setIsGrabbing(true);
            }
            // e.stopPropagation();
          }}
          pointerup={e => {
            setIsGrabbing(false);
            setHasReleased(true);
          }}
          pointermove={(e: PIXI.InteractionEvent) => {
            if (isGrabbing) {
              setPositionX(e.data.global.x);
              setPositionY(e.data.global.y);
              setSpeedX(prev => prev + (e.data.global.x - lastMouse2.current.x) / 20);
              setSpeedY(prev => prev + (e.data.global.y - lastMouse2.current.y) / 80);
            }
          }}
          x={positionX}
          y={positionY}
          anchor="0.5,0.5"
          scale={new PIXI.Point(-0.3, 0.3)}
        />
        <Sprite
          // Interaction target
          interactive={false}
          texture={PIXI.Texture.EMPTY}
          x={rightScrollX}
          y={-800}
          width={10000}
          height={1600}
        />
      </Container>
      <Sprite
        rotation={90}
        scale={new PIXI.Point(0.2, 0.2)}
        anchor="0.5, 1"
        texture={broom}
        tint={sweepingIncreasesFriction ? 0x0000ff : 0xffff00}
        alpha={hasReleased ? 1 : 0}
        x={lastMouse.current.x}
        y={lastMouse.current.y}
      />
      {gameOver ? (
        <Text
          text={success ? "Confirmed" : "Confirmation failed, try again next time"}
          x={800}
          y={50}
          interactive={true}
          pointerup={() => {
            if (!success) {
              reset();
            }
          }}
          cursor={"pointer"}
          anchor={"0.5,0.5"}
          style={new PIXI.TextStyle({ fill: success ? 0x0000dd : 0xff0000, fontSize: 60, align: "center" })}
        />
      ) : (
        <Fragment>
          <Text
            text={"Friction: " + (coefficientOfFriction * 1000).toFixed(2)}
            x={800}
            y={50}
            interactive={true}
            pointerup={() => {
              setSweepingIncreasesFriction(prev => !prev);
            }}
            cursor={"pointer"}
            anchor={"0.5,0.5"}
            style={
              new PIXI.TextStyle({
                fill: sweepingIncreasesFriction ? 0x0000dd : 0xdd0000,
                fontSize: 24,
                align: "center",
              })
            }
          />
        </Fragment>
      )}
    </Container>
  );
};

const CurlingConfirmation: FC<{ validationRunStore: ValidationRunStore; onClose: () => void }> = ({
  validationRunStore,
  onClose,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isInZone, setIsInZone] = useState(false);

  const handleConfirm = () => {
    if (!isInZone) {
      setErrorMessage("Curling stone not in the confirmation zone, please try again");
      return;
    }
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Fragment>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>? <br />
        <br />
        This operation is final and cannot be undone.
        <br />
        <br />
        Please slide the curling stone into the confirmation zone to confirm:
        <Stage
          style={{ width: "800px", height: "400px", cursor: "none" }}
          options={{
            antialias: true,
            backgroundColor: 0xeeeeee,
            width: 1600,
            height: 800,
          }}
        >
          <CurlingStage setIsInZone={setIsInZone} />
        </Stage>
        {errorMessage !== "" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton disabled={!isInZone} onClick={handleConfirm}>
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Fragment>
  );
};

export const DeleteValidationRunConfirmationModal: FC = observer(() => {
  const { validationRunStore } = useStores();

  const onClose = () => {
    validationRunStore.setProposedDeletionValidationRunID(undefined);
  };

  const possibleComponents: JSX.Element[] = [
    <SliderConfirmation key={"slider"} onClose={onClose} validationRunStore={validationRunStore} />,
    <TextConfirmation key={"text"} onClose={onClose} validationRunStore={validationRunStore} />,
    <DropdownConfirmation key={"dropdown"} onClose={onClose} validationRunStore={validationRunStore} />,
    <M6Confirmation key={"m6"} onClose={onClose} validationRunStore={validationRunStore} />,
    <CurlingConfirmation key={"curling"} validationRunStore={validationRunStore} onClose={onClose} />,
  ];

  return (
    <Dialog
      open={!!validationRunStore.proposedDeletionValidationRunID && !validationRunStore.easyDeletionMode}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      {possibleComponents[Math.floor(Math.random() * possibleComponents.length)]}
    </Dialog>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
