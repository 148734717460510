import * as PopperJS from "@popperjs/core";
import React, { FC } from "react";
import { useStores } from "../../../hooks/useStores.hook";
import { observer } from "mobx-react";
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { classLookup } from "../../../workers/utils";
import { getIconForClass } from "./iconMap";
import { getColourFromClass } from "../../../workers/colourMap";

const ValidationRunCrossingPopper: FC<{}> = observer(() => {
  const { playerUIStore } = useStores();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const valRunCrossing = playerUIStore.lastClickedValidationRunCrossingForPopper;

  if (!valRunCrossing) {
    return null;
  }
  let left: number;
  let top: number;
  if (playerUIStore.lastClickedValidationRunCrossingForPopperIsZoomed) {
    left =
      1.5 +
      (playerUIStore.timelineWidth *
        (valRunCrossing.frameNumber / playerUIStore.totalFrames - playerUIStore.timelineLowerBound)) /
        (playerUIStore.timelineUpperBound - playerUIStore.timelineLowerBound);
    top = 80;
  } else {
    left = 1.5 + playerUIStore.timelineWidth * (valRunCrossing.frameNumber / playerUIStore.totalFrames);
    top = 64;
  }

  const virtualReference: PopperJS.VirtualElement = {
    getBoundingClientRect() {
      return {
        top: top,
        left: left,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        toJSON(): string {
          return JSON.stringify(this);
        },
      };
    },
  };
  return (
    <Popper
      open={true}
      anchorEl={virtualReference}
      placement={"bottom"}
      transition
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => {
        if (!valRunCrossing) {
          return null;
        }
        return (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ backgroundColor: "#fcf9ec", p: 1 }}>
              <Box
                sx={{
                  "&::before": {
                    content: '""',
                    pointerEvents: "none",
                    position: "relative",
                    top: "-44px",
                    borderTop: "13px solid transparent",
                    borderBottom: "56px solid #fcf9ec",
                    borderRight: "13px solid transparent",
                    borderLeft: "13px solid transparent",
                  },
                }}
                ref={setArrowRef}
              />
              <Grid container>
                <Grid item>
                  {getIconForClass(valRunCrossing.detectionClassV2Id, {
                    fontSize: "large",
                    sx: { color: `#${getColourFromClass(valRunCrossing.detectionClassV2Id).toString(16)}` },
                  })}
                  {valRunCrossing.anprVehicleClass
                    ? getIconForClass(valRunCrossing.anprVehicleClass, {
                        fontSize: "large",
                        sx: { color: `#${getColourFromClass(valRunCrossing.anprVehicleClass).toString(16)}` },
                      })
                    : null}
                  <Typography sx={{ p: 1, whiteSpace: "pre-wrap" }} variant={"h6"}>
                    {classLookup[valRunCrossing.detectionClassV2Id]} (Human)
                  </Typography>
                  <Table>
                    <TableBody>
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Direction
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valRunCrossing.clockwise ? "Clockwise" : "Anti-clockwise"}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Frame number
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valRunCrossing.frameNumber}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Created by
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valRunCrossing.createdByUserId ?? "You"}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Saved
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valRunCrossing.saved ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                      {valRunCrossing.plate ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                            Plate Read
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {valRunCrossing.plate}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {valRunCrossing.anprVehicleClass ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                            Plate Class
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {classLookup[valRunCrossing.anprVehicleClass]}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item sx={{ padding: "5px" }}>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => playerUIStore.deleteLastClickedValidationRunCrossing()}
                  >
                    <DeleteForeverIcon fontSize="medium" />
                  </IconButton>
                </Grid>
                <Grid item sx={{ padding: "5px" }}>
                  <IconButton
                    size="medium"
                    aria-label="close"
                    color="inherit"
                    onClick={() => playerUIStore.clearLastClickedValidationRunCrossingForPopper()}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        );
      }}
    </Popper>
  );
});

export default ValidationRunCrossingPopper;
