import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Breadcrumbs, Chip, emphasize, Grid, IconButton, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../enums";
import { useStores } from "../../hooks/useStores.hook";
import { URL_QUERY_PARAMS } from "../../stores/url.store";

interface TableBreadcrumbsProps {
  depth: number;
}

interface PathElement {
  label: string;
  pathname?: RoutePath;
  clearParams?: URL_QUERY_PARAMS[];
}

export const TableBreadcrumbs: FC<TableBreadcrumbsProps> = observer(({ depth }) => {
  const {
    urlStore: { selectedVisionProgram, selectedVideo },
  } = useStores();
  const { urlStore } = useStores();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isRootVisionPrograms = urlStore.breadcrumbsRoot === RoutePath.Root;

  const pathElements: PathElement[] = [
    {
      label: "Vision Programs",
      pathname: RoutePath.Root,
      clearParams: [
        URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM,
        URL_QUERY_PARAMS.SELECTED_VIDEO,
        URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN,
        URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN,
      ],
    },
  ];
  if (selectedVisionProgram) {
    pathElements.push({
      label: String(selectedVisionProgram),
    });
  }

  if (depth > 0 && isRootVisionPrograms) {
    pathElements.push({
      label: "Videos",
      pathname: RoutePath.Videos,
      clearParams: [
        URL_QUERY_PARAMS.SELECTED_VIDEO,
        URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN,
        URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN,
      ],
    });

    if (selectedVideo) {
      pathElements.push({
        label: String(selectedVideo),
      });
    }
  }
  if (depth > 1 && isRootVisionPrograms) {
    pathElements.push({
      label: "Computer Vision Runs",
      pathname: RoutePath.ComputerVisionRuns,
      clearParams: [URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN, URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN],
    });
  }
  if (depth > 2) {
    pathElements.push({
      label: "Validation Runs",
      pathname: RoutePath.ValidationRuns,
      clearParams: [URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN],
    });
  }

  const expandedPathElement: PathElement = {
    label: "Vision Programs",
    pathname: RoutePath.Root,
    clearParams: [
      URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM,
      URL_QUERY_PARAMS.SELECTED_VIDEO,
      URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN,
      URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN,
    ],
  };

  const paramsForPath = (pathElement: PathElement) => {
    const params = new URLSearchParams(location.search);
    if (pathElement.clearParams) {
      pathElement.clearParams.forEach(p => params.delete(p));
    }
    return params.toString();
  };

  return (
    <Container>
      <Grid container>
        <Grid item container>
          <StyledIconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <ExpandMore /> : <ChevronRight />}
          </StyledIconButton>

          <Breadcrumbs separator=">">
            {pathElements.map(pathElement =>
              pathElement.pathname ? (
                <Link
                  key={pathElement.label}
                  to={{
                    pathname: pathElement.pathname,
                    search: paramsForPath(pathElement),
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <StyledBreadcrumb label={pathElement.label} clickable />
                </Link>
              ) : (
                <StyledBreadcrumb label={pathElement.label} />
              )
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Container>
  );
});

const Container = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const NestedBreadcrumb = styled(StyledBreadcrumb)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(0.5),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: theme.spacing(3),
  width: theme.spacing(3),
  "&:hover, &:focus": {
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
  },
}));
