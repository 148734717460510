import React from "react";
import { Container, Sprite } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import { PlayerUIStore } from "../../../stores/playerUI.store";
import { observer } from "mobx-react";

const filter = new PIXI.filters.ColorMatrixFilter();
filter.brightness(1.1, true);

export const ThumbnailsBackground: React.FunctionComponent<{ playerUIStore: PlayerUIStore }> = observer(props => {
  const thumbs = props.playerUIStore.thumbailsInOrder.map((thumb, index) => {
    return (
      <Sprite
        key={index}
        texture={PIXI.Texture.from(thumb)}
        filters={[filter]}
        width={70}
        height={40}
        x={index * 70}
        alpha={0.5}
        interactive
      />
    );
  });
  return <Container>{thumbs}</Container>;
});
