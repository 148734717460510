import { CountlinesConfig, ZonesDrawnConfig } from "./hydration";
import {
  CountlineID,
  CVRunID,
  FrameNumber,
  TrackID,
  TurningMovementEndZone,
  TurningMovementStartZone,
  ValidationRunID,
  VideoID,
  VPID,
} from "../domain";
import { CVRunStatus } from "../enums";
import { CVRunCountlineCrossing } from "./cvRunCountlineCrossing.interface";
import { ExtraThumbnail } from "./video.interface";
import { CVRunTurningMovement } from "./cvRunTurningMovement.interface";

export interface ComputerVisionRun {
  id: CVRunID;
  vpid: VPID;
  videoID: VideoID;
  status: CVRunStatus;
  processingPercent: number;
  outputDrawingVideo: boolean;
  supermarioImage: string;
  processedVideoPath: string;
  dtfsUrl: string;
  imageSpaceCountlines: CountlinesConfig | undefined;
  imageSpaceMasks: ZonesDrawnConfig | undefined;
  imageSpaceTurningZones: ZonesDrawnConfig | undefined;
  validationRuns: ValidationRunID[];
  priority: number;
  countlineCrossings: ComputerVisionRunCountlineCrossings;
  turningMovements: ComputerVisionRunTurningMovements | undefined;
  thumbnail: string | undefined;
  videoDownloadURL: string | undefined;
  extraThumbnails: ExtraThumbnail[];
  skipValidation: boolean;
  processorID: string | undefined;
  supermarioValues: string | undefined;
  lastReportedError: string | undefined;
  lastRunStdout: string | undefined;
  lastRunStderr: string | undefined;
  isNearMiss: boolean;
  createdBy: string | undefined;
  createdAt: string | undefined;
  rawDtfsFromSensor: boolean;
}

export type ComputerVisionRunCountlineCrossings = Map<CountlineID, Map<FrameNumber, CVRunCountlineCrossing>>;

export type ComputerVisionRunTurningMovements = Map<
  TurningMovementStartZone,
  Map<TurningMovementEndZone, CVRunTurningMovement[]>
>;

export const ComputerVisionRunSearchableFields: { [fieldName in keyof ComputerVisionRun]: boolean } = {
  countlineCrossings: false,
  turningMovements: false,
  imageSpaceCountlines: false,
  imageSpaceMasks: false,
  imageSpaceTurningZones: false,
  thumbnail: false,
  validationRuns: false,
  id: true,
  vpid: true,
  videoID: true,
  status: true,
  processingPercent: false,
  outputDrawingVideo: false,
  supermarioImage: false,
  processedVideoPath: false,
  dtfsUrl: false,
  priority: false,
  extraThumbnails: false,
  videoDownloadURL: false,
  skipValidation: true,
  processorID: false,
  supermarioValues: false,
  lastReportedError: false,
  lastRunStdout: false,
  lastRunStderr: false,
  isNearMiss: false,
  createdBy: false,
  createdAt: false,
  rawDtfsFromSensor: false,
};
