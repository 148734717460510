import React, { FC } from "react";

import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStores } from "../../hooks/useStores.hook";
import { observer } from "mobx-react";

export const LinearProgressWithLabel: FC<
  LinearProgressProps & { value: number; text: string; minwidth: number }
> = props => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", minHeight: "30px" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: props.minwidth }}>
        <Typography variant="body2" color="text.secondary">
          {props.text + `${props.value.toFixed(1)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const playbackProgressStyles = { paddingBottom: "4px" };

export const PlaybackProgress = observer(() => {
  const { playerUIStore } = useStores();
  return (
    <LinearProgress
      variant="buffer"
      style={playbackProgressStyles}
      value={(100 * playerUIStore.videoCurrentTimeSeconds) / playerUIStore.videoDurationSeconds}
      valueBuffer={(100 * playerUIStore.videoLoadedTimeSeconds) / playerUIStore.videoDurationSeconds}
    />
  );
});
