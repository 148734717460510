import { action, makeObservable, observable } from "mobx";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { ComputerVisionRun } from "../interfaces";

export const NEW_VALIDATION_RUN_MODAL_EVENT = "NEW_VALIDATION_RUN_MODAL_EVENT";
export const CLONE_VALIDATION_RUN_MODAL_EVENT = "CLONE_VALIDATION_RUN_MODAL_EVENT";

export interface NewValidationRunModalEvent {
  isOpen: boolean;
  computerVisionRun?: ComputerVisionRun;
  countlinesIDs?: number[];
}

export interface CloneValidationRunModalEvent {
  isOpen: boolean;
  targetComputerVisionRun?: ComputerVisionRun;
}

export class ValidationCreationUIStore extends ChildStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable countlineSelectionModalIsOpen = false;
  @observable computerVisionRun?: ComputerVisionRun = undefined;
  @observable countlineIDs: number[] = [];

  @observable cloneModalIsOpen = false;
  @observable targetComputerVisionRun?: ComputerVisionRun = undefined;

  @action
  init() {
    window.addEventListener(NEW_VALIDATION_RUN_MODAL_EVENT, ((e: CustomEvent<NewValidationRunModalEvent>) => {
      const { isOpen, computerVisionRun, countlinesIDs } = e.detail;
      this.setCountlineSelectionModalIsOpen(isOpen);
      if (computerVisionRun) {
        this.setComputerVisionRun(computerVisionRun);
      }
      if (countlinesIDs) {
        this.setCountlineIDs(countlinesIDs);
      }
    }) as EventListener);

    window.addEventListener(CLONE_VALIDATION_RUN_MODAL_EVENT, ((e: CustomEvent<CloneValidationRunModalEvent>) => {
      const { isOpen, targetComputerVisionRun } = e.detail;
      this.setCloneModalIsOpen(isOpen);
      if (targetComputerVisionRun) {
        this.setTargetComputerVisionRun(targetComputerVisionRun);
      }
    }) as EventListener);
  }

  @action
  setCloneModalIsOpen(isOpen: boolean) {
    this.cloneModalIsOpen = isOpen;
  }

  @action
  setCountlineSelectionModalIsOpen(isOpen: boolean) {
    this.countlineSelectionModalIsOpen = isOpen;
  }

  @action
  setComputerVisionRun(cvRun: ComputerVisionRun) {
    this.computerVisionRun = cvRun;
  }

  @action
  setCountlineIDs(IDs: number[]) {
    this.countlineIDs = IDs;
  }

  @action
  setTargetComputerVisionRun(cvRun: ComputerVisionRun) {
    this.targetComputerVisionRun = cvRun;
  }
}
