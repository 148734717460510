import React, { FC, PropsWithChildren, useEffect } from "react";
import { Sprite, Stage } from "react-pixi-fiber/index";
import { observer } from "mobx-react";
import * as PIXI from "pixi.js";
import Rectangle from "../PlayerDrawing/Rectangle";
import { useStores } from "../../../hooks/useStores.hook";
import _ from "lodash";
import VideoThumbnail from "../PlayerDrawing/VideoThumbnail";
import { TimeScrubber, ZoomedTimeScrubber } from "./TimeScrubber";
import { CVCrossings, ZoomedCVCrossings } from "./CVCrossings";
import { ThumbnailsBackground } from "./ThumbnailsBackground";
import { ValidationRunCrossings, ZoomedValidationRunCrossings } from "./ValidationRunCrossings";
import { StoresContext } from "../../../contexts/stores.context";
import CVCrossingPopper from "./CVCrossingPopper";
import ValidationRunCrossingPopper from "./ValidationRunCrossingPopper";
import { NearMissIncidents, ZoomedNearMissIncidents } from "./NearMissIncident";
import { ValidationMode } from "../../../enums";

type TimelineProps = {
  height: number;
};

const ZoomBox: FC<{ storesContext: StoresContext; height: number; pointerUp: (e: PIXI.InteractionEvent) => void }> =
  observer(props => {
    const { playerUIStore } = props.storesContext;
    return (
      <Rectangle
        x={playerUIStore.timelineZoomBoxXLeft}
        y={0}
        width={playerUIStore.timelineZoomBoxWidth}
        height={props.height - 20}
        fillColor={0xffffff}
        lineWidth={1.5}
        lineColor={0x666666}
        fillAlpha={0.4}
        lineAlpha={1}
        cursor={"crosshair"}
        interactive={true}
        pointerup={props.pointerUp}
      />
    );
  });

const Timeline: React.FunctionComponent<PropsWithChildren<TimelineProps>> = observer(
  (props: PropsWithChildren<TimelineProps>) => {
    const storesContext = useStores();
    const { playerUIStore, urlStore } = storesContext;

    useEffect(() => {
      const windowPointerUp = (e: MouseEvent) => {
        if (playerUIStore.timelineIsDragging) {
          playerUIStore.setTimelineIsDragging(false);

          // Make the video update to the current frame by playing for 60ms
          playerUIStore.play1Frame(1 / 16, 60);
        }
      };
      window.addEventListener("pointerup", windowPointerUp);
      return function cleanup() {
        window.removeEventListener("pointerup", windowPointerUp);
      };
    }, [playerUIStore.playing, playerUIStore, playerUIStore.timelineIsDragging]);

    if (playerUIStore.videoURL === "") {
      // Parent component needs to redirect somewhere to hydrate and select a video
      console.log("no selected video");
      return null;
    }

    const pointerMove = (e: PIXI.InteractionEvent) => {
      if (playerUIStore.timelineIsDragging) {
        e.stopPropagation();
        playerUIStore.seekToFraction(e.data.global.x / playerUIStore.timelineWidth);
      }
    };

    const onWheel = (e: React.WheelEvent) => {
      e.persist();
      const scalingFactor = 1 / 2 ** (-e.deltaY / 500);
      playerUIStore.setTimelineZoomLevel(zoomLevel => Math.min(zoomLevel * scalingFactor, 1.0));
    };

    const interactionTarget = (
      <Sprite
        width={playerUIStore.timelineWidth}
        height={40}
        interactive={true}
        cursor={"crosshair"}
        pointerdown={playerUIStore.handleTimelineClick.bind(playerUIStore)}
      />
    );

    const interactionTargetZoomed = (
      <Sprite
        width={playerUIStore.timelineWidth}
        y={40}
        height={20}
        interactive={true}
        cursor={"crosshair"}
        pointerdown={playerUIStore.handleTimelineClickZoomed.bind(playerUIStore)}
      />
    );

    const duration = playerUIStore.videoDurationSeconds;

    const thumbnailStep = duration / 28; // 1920/70
    const thumbs = _.range(thumbnailStep, duration + thumbnailStep, thumbnailStep).map(timeLocation => {
      return (
        <VideoThumbnail
          key={timeLocation}
          height={40}
          width={70}
          videoUrl={playerUIStore.videoURL}
          thumbnailHandler={(thumbnail: string) => playerUIStore.addThumbnail(timeLocation, thumbnail)}
          snapshotAtTime={timeLocation}
        />
      );
    });

    return (
      <div
        onWheel={onWheel}
        onMouseOver={() => {
          document.body.style.overflow = "hidden";
        }}
        onMouseOut={() => {
          document.body.style.overflow = "auto";
        }}
        style={{ width: "fit-content", height: props.height }}
      >
        {thumbs}
        <Stage
          style={{
            padding: 0,
            display: "block",
          }}
          options={{
            antialias: true,
            backgroundColor: 0xcccccc,
            width: playerUIStore.timelineWidth,
            height: props.height,
            backgroundAlpha: 0.9,
          }}
        >
          <ThumbnailsBackground playerUIStore={playerUIStore} />
          {interactionTarget}
          {interactionTargetZoomed}
          <ZoomBox
            storesContext={storesContext}
            height={props.height}
            pointerUp={playerUIStore.handleTimelineClick.bind(playerUIStore)}
          />
          <CVCrossings
            width={playerUIStore.timelineWidth}
            storesContext={storesContext}
            alpha={0.9}
            pointerUp={playerUIStore.handleCVCrossingClick.bind(playerUIStore)}
            pointerDown={playerUIStore.handleCVCrossingClick.bind(playerUIStore)}
          />
          {urlStore.selectedValidationMode === ValidationMode.NearMissValidation && (
            <NearMissIncidents storesContext={storesContext} />
          )}
          <ValidationRunCrossings
            width={playerUIStore.timelineWidth}
            storesContext={storesContext}
            alpha={0.9}
            pointerUp={playerUIStore.handleValidationCrossingClick.bind(playerUIStore)}
            pointerDown={playerUIStore.handleValidationCrossingClick.bind(playerUIStore)}
          />
          <TimeScrubber
            pointerDown={(e: PIXI.InteractionEvent) => {
              e.stopPropagation();
              playerUIStore.setTimelineIsDragging(true);
            }}
            pointerUp={playerUIStore.handleTimelineClick.bind(playerUIStore)}
            pointerMove={pointerMove}
            storesContext={storesContext}
            height={props.height}
          />
          <ZoomedTimeScrubber storesContext={storesContext} height={props.height} />
          <ZoomedCVCrossings
            alpha={0.9}
            storesContext={storesContext}
            pointerUp={playerUIStore.handleZoomedCVCrossingClick.bind(playerUIStore)}
            pointerDown={playerUIStore.handleZoomedCVCrossingClick.bind(playerUIStore)}
          />
          {urlStore.selectedValidationMode === ValidationMode.NearMissValidation && (
            <ZoomedNearMissIncidents storesContext={storesContext} />
          )}
          <ZoomedValidationRunCrossings
            alpha={0.9}
            storesContext={storesContext}
            pointerUp={playerUIStore.handleZoomedValidationCrossingClick.bind(playerUIStore)}
            pointerDown={playerUIStore.handleZoomedValidationCrossingClick.bind(playerUIStore)}
          />
        </Stage>
        <CVCrossingPopper />
        <ValidationRunCrossingPopper />
      </div>
    );
  }
);

export default Timeline;
