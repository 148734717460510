import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider, styled, Box } from "@mui/material";
import React, { FC, Fragment } from "react";
import { FreneticityButton } from "../styledElements";
import { useStores } from "../../hooks/useStores.hook";
import { observer } from "mobx-react";
import { MonacoDiffEditor } from "react-monaco-editor";
import MonacoEditor from "react-monaco-editor/lib/editor";
import MuiGrid from "@mui/material/Grid";
import { CVRunID } from "../../domain";

interface CVRunDetailsModalsProps {
  isOpen: boolean;
  onClose: () => void;
  currentCVRun: CVRunID;
}

export const CVRunDetailsModal: FC<CVRunDetailsModalsProps> = observer(({ isOpen, onClose, currentCVRun }) => {
  const { cvRunStore, entitiesStore } = useStores();

  const cvRun = cvRunStore.computerVisionRuns.get(currentCVRun);
  if (!cvRun) {
    return null;
  }

  const allCVRuns = entitiesStore.getComputerVisionRunsForVideoID(cvRun.videoID);
  allCVRuns.sort((a, b) => a.id - b.id);
  const comparisonCVRun = allCVRuns[allCVRuns.indexOf(cvRun) - 1];
  if (comparisonCVRun && !comparisonCVRun.supermarioValues) {
    cvRunStore.fetchCvRunDetails(comparisonCVRun.id);
  }

  if (comparisonCVRun) {
    return (
      <Dialog open={isOpen && currentCVRun !== undefined} maxWidth={"xl"} fullWidth={true} onClose={onClose}>
        <Grid container>
          <Grid item xs>
            <DialogTitle>
              <h4>Previous CV Run {comparisonCVRun?.id} Details</h4>
            </DialogTitle>
            <DialogContent>
              <Typography>
                Requested by <b>{comparisonCVRun.createdBy}</b> (
                {new Date(Date.parse(comparisonCVRun.createdAt ?? "")).toLocaleString()})
              </Typography>
              <Typography>
                Executed on <b>{comparisonCVRun.processorID}</b>
              </Typography>
            </DialogContent>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs>
            <DialogTitle>
              <Box display="flex" justifyContent="flex-end">
                <h4>Current CV Run {currentCVRun} Details</h4>
              </Box>
            </DialogTitle>
            <Box display="flex" justifyContent="flex-end">
              <Typography>
                Requested by <b>{cvRun.createdBy}</b> ({new Date(Date.parse(cvRun.createdAt ?? "")).toLocaleString()})
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography>
                Executed on <b>{cvRun.processorID}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <DialogContent>
          <Fragment>
            <MonacoDiffEditor
              width="100%"
              height={600}
              language="yaml"
              theme="vs-dark"
              original={comparisonCVRun?.supermarioValues}
              value={cvRun.supermarioValues}
            />
            {cvRun.lastRunStdout ? (
              <Fragment>
                <Typography>Stdout</Typography>
                <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStdout} />
              </Fragment>
            ) : null}
            {cvRun.lastRunStderr ? (
              <Fragment>
                <Typography>Stderr</Typography>
                <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStderr} />
              </Fragment>
            ) : null}
          </Fragment>
        </DialogContent>
        <DialogActions>
          <FreneticityButton onClick={onClose}>Close</FreneticityButton>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog open={isOpen && currentCVRun !== undefined} maxWidth={"xl"} fullWidth={true} onClose={onClose}>
      <DialogTitle>
        <h4>CV Run {currentCVRun} Details</h4>
      </DialogTitle>
      <DialogContent>
        <Fragment>
          <Typography>
            Requested by <b>{cvRun.createdBy}</b> ({new Date(Date.parse(cvRun.createdAt ?? "")).toLocaleString()})
          </Typography>
          <Typography>
            Executed on <b>{cvRun.processorID}</b>
          </Typography>
          <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.supermarioValues} />
          {cvRun.lastRunStdout ? (
            <Fragment>
              <Typography>Stdout</Typography>
              <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStdout} />
            </Fragment>
          ) : null}
          {cvRun.lastRunStderr ? (
            <Fragment>
              <Typography>Stderr</Typography>
              <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStderr} />
            </Fragment>
          ) : null}
        </Fragment>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Close</FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "97%",
  ...theme.typography.h4,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));
