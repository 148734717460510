import { CustomPIXIComponent, applyDisplayObjectProps } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import CSS from "csstype";
import { IHitArea } from "@pixi/interaction";

export interface PolylineProperties extends Partial<PIXI.Graphics> {
  xy: number[];
  id: number;
  lineColor: number;
  lineWidth: number;
  alpha: number;
  cursor?: CSS.Property.Cursor;
  hitAreaWidth?: number;
}

const distanceBetweenPointAndLine: (
  pointX: number,
  pointY: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => number = (pointX, pointY, x1, y1, x2, y2) => {
  const A = pointX - x1;
  const B = pointY - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) {
    // in case of 0 length line
    param = dot / len_sq;
  }

  let xx = 0;
  let yy = 0;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = pointX - xx;
  const dy = pointY - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

class ThickPolyLineHitArea implements IHitArea {
  private lines: number[][];
  private readonly maxDistance: number;

  constructor(xy: number[], thickness: number) {
    this.maxDistance = thickness / 2;
    this.lines = [];
    let i = 0;
    while (i <= xy.length - 4) {
      this.lines.push([xy[i], xy[i + 1], xy[i + 2], xy[i + 3]]);
      i = i + 2;
    }
  }

  public contains(x: number, y: number): boolean {
    let distance: number | undefined = undefined;
    this.lines.forEach(line => {
      const d = distanceBetweenPointAndLine(x, y, line[0], line[1], line[2], line[3]);
      if (distance === undefined) {
        distance = d;
      } else if (d < distance) {
        distance = d;
      }
    });
    if (distance === undefined) {
      console.error(`distance undefined. polyline contains ${this.lines.length} lines`);
      return false;
    } else {
      return distance <= this.maxDistance;
    }
  }
}

const TYPE = "PolyLine";
const behavior = {
  customDisplayObject: (props: PolylineProperties) => new PIXI.Graphics(),
  customApplyProps: function (
    instance: PIXI.Graphics,
    oldProps: PolylineProperties | undefined,
    newProps: PolylineProperties
  ) {
    instance.interactive = true;
    if (oldProps !== undefined) {
      instance.clear();
    }
    const { xy, id, lineColor, lineWidth, alpha, cursor, hitAreaWidth, ...newPropsRest } = newProps;

    instance.lineStyle(lineWidth, lineColor, alpha);
    instance.moveTo(xy[0], xy[1]);
    if (hitAreaWidth) {
      instance.hitArea = new ThickPolyLineHitArea(xy, hitAreaWidth);
    }
    for (let i = 2; i < xy.length; i += 2) {
      instance.lineTo(xy[i], xy[i + 1]);
    }
    if (cursor) {
      instance.cursor = cursor;
    } else {
      instance.cursor = "pointer";
    }

    if (oldProps) {
      const {
        xy: oldPoints,
        id: oldId,
        lineColor: oldLineColour,
        lineWidth: oldLineWidth,
        alpha: oldAlpha,
        cursor: oldCursor,
        hitAreaWidth: oldHitAreaWidth,
        ...oldPropsRest
      } = oldProps;

      applyDisplayObjectProps(instance.name, instance, oldPropsRest, newPropsRest);
    }
  },
};
const PolyLine = CustomPIXIComponent(behavior, TYPE);
export default PolyLine;
