/* eslint-disable */
// source: vivacity/config_v2/shared_metadata.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_v2_hardware_pb = require('../../vivacity/config_v2/hardware_pb.js');
goog.object.extend(proto, vivacity_config_v2_hardware_pb);
var vivacity_config_v2_vision_program_pb = require('../../vivacity/config_v2/vision_program_pb.js');
goog.object.extend(proto, vivacity_config_v2_vision_program_pb);
var vivacity_config_v2_smart_junctions_pb = require('../../vivacity/config_v2/smart_junctions_pb.js');
goog.object.extend(proto, vivacity_config_v2_smart_junctions_pb);
var vivacity_config_v2_test_entity_pb = require('../../vivacity/config_v2/test_entity_pb.js');
goog.object.extend(proto, vivacity_config_v2_test_entity_pb);
var vivacity_config_v2_zones_image_space_pb = require('../../vivacity/config_v2/zones_image_space_pb.js');
goog.object.extend(proto, vivacity_config_v2_zones_image_space_pb);
var vivacity_config_v2_shared_metadata_class_configs_pb = require('../../vivacity/config_v2/shared_metadata_class_configs_pb.js');
goog.object.extend(proto, vivacity_config_v2_shared_metadata_class_configs_pb);
var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.SharedMetadata', null, global);
goog.exportSymbol('proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.SharedMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.SharedMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.SharedMetadata.displayName = 'proto.vivacity.config_v2.SharedMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.displayName = 'proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.SharedMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.SharedMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.SharedMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.toObject(includeInstance, f),
    correlationId: (f = msg.getCorrelationId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    modifiedBy: (f = msg.getModifiedBy()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    modifiedAt: (f = msg.getModifiedAt()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    deletedBy: (f = msg.getDeletedBy()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    restoredBy: (f = msg.getRestoredBy()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    restoredAt: (f = msg.getRestoredAt()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.SharedMetadata}
 */
proto.vivacity.config_v2.SharedMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.SharedMetadata;
  return proto.vivacity.config_v2.SharedMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.SharedMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.SharedMetadata}
 */
proto.vivacity.config_v2.SharedMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject;
      reader.readMessage(value,proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setCorrelationId(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setModifiedBy(value);
      break;
    case 14:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setModifiedAt(value);
      break;
    case 15:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setDeletedBy(value);
      break;
    case 16:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setRestoredBy(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setRestoredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.SharedMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.SharedMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.SharedMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.serializeBinaryToWriter
    );
  }
  f = message.getCorrelationId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getModifiedBy();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getDeletedBy();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getRestoredBy();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getRestoredAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    classType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    testParentEntity: (f = msg.getTestParentEntity()) && vivacity_config_v2_test_entity_pb.TestParentEntity.toObject(includeInstance, f),
    testChildEntity: (f = msg.getTestChildEntity()) && vivacity_config_v2_test_entity_pb.TestChildEntity.toObject(includeInstance, f),
    testReferenceEntity: (f = msg.getTestReferenceEntity()) && vivacity_config_v2_test_entity_pb.TestReferenceEntity.toObject(includeInstance, f),
    testParentEntityToTestReferenceEntity: (f = msg.getTestParentEntityToTestReferenceEntity()) && vivacity_config_v2_test_entity_pb.TestParentEntityToTestReferenceEntity.toObject(includeInstance, f),
    hardware: (f = msg.getHardware()) && vivacity_config_v2_hardware_pb.Hardware.toObject(includeInstance, f),
    visionProgram: (f = msg.getVisionProgram()) && vivacity_config_v2_vision_program_pb.VisionProgram.toObject(includeInstance, f),
    visionProgramToClass: (f = msg.getVisionProgramToClass()) && vivacity_config_v2_vision_program_pb.VisionProgramToClass.toObject(includeInstance, f),
    country: (f = msg.getCountry()) && vivacity_config_v2_smart_junctions_pb.Country.toObject(includeInstance, f),
    region: (f = msg.getRegion()) && vivacity_config_v2_smart_junctions_pb.Region.toObject(includeInstance, f),
    controller: (f = msg.getController()) && vivacity_config_v2_smart_junctions_pb.Controller.toObject(includeInstance, f),
    controllerStream: (f = msg.getControllerStream()) && vivacity_config_v2_smart_junctions_pb.ControllerStream.toObject(includeInstance, f),
    stage: (f = msg.getStage()) && vivacity_config_v2_smart_junctions_pb.Stage.toObject(includeInstance, f),
    phase: (f = msg.getPhase()) && vivacity_config_v2_smart_junctions_pb.Phase.toObject(includeInstance, f),
    stageToPhase: (f = msg.getStageToPhase()) && vivacity_config_v2_smart_junctions_pb.StageToPhase.toObject(includeInstance, f),
    zoneImageSpaceToClass: (f = msg.getZoneImageSpaceToClass()) && vivacity_config_v2_zones_image_space_pb.ZoneImageSpaceToClass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject;
  return proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (reader.readEnum());
      msg.setClassType(value);
      break;
    case 10:
      var value = new vivacity_config_v2_test_entity_pb.TestParentEntity;
      reader.readMessage(value,vivacity_config_v2_test_entity_pb.TestParentEntity.deserializeBinaryFromReader);
      msg.setTestParentEntity(value);
      break;
    case 11:
      var value = new vivacity_config_v2_test_entity_pb.TestChildEntity;
      reader.readMessage(value,vivacity_config_v2_test_entity_pb.TestChildEntity.deserializeBinaryFromReader);
      msg.setTestChildEntity(value);
      break;
    case 12:
      var value = new vivacity_config_v2_test_entity_pb.TestReferenceEntity;
      reader.readMessage(value,vivacity_config_v2_test_entity_pb.TestReferenceEntity.deserializeBinaryFromReader);
      msg.setTestReferenceEntity(value);
      break;
    case 13:
      var value = new vivacity_config_v2_test_entity_pb.TestParentEntityToTestReferenceEntity;
      reader.readMessage(value,vivacity_config_v2_test_entity_pb.TestParentEntityToTestReferenceEntity.deserializeBinaryFromReader);
      msg.setTestParentEntityToTestReferenceEntity(value);
      break;
    case 1000:
      var value = new vivacity_config_v2_hardware_pb.Hardware;
      reader.readMessage(value,vivacity_config_v2_hardware_pb.Hardware.deserializeBinaryFromReader);
      msg.setHardware(value);
      break;
    case 1001:
      var value = new vivacity_config_v2_vision_program_pb.VisionProgram;
      reader.readMessage(value,vivacity_config_v2_vision_program_pb.VisionProgram.deserializeBinaryFromReader);
      msg.setVisionProgram(value);
      break;
    case 1003:
      var value = new vivacity_config_v2_vision_program_pb.VisionProgramToClass;
      reader.readMessage(value,vivacity_config_v2_vision_program_pb.VisionProgramToClass.deserializeBinaryFromReader);
      msg.setVisionProgramToClass(value);
      break;
    case 2000:
      var value = new vivacity_config_v2_smart_junctions_pb.Country;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    case 2001:
      var value = new vivacity_config_v2_smart_junctions_pb.Region;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 2002:
      var value = new vivacity_config_v2_smart_junctions_pb.Controller;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.Controller.deserializeBinaryFromReader);
      msg.setController(value);
      break;
    case 2003:
      var value = new vivacity_config_v2_smart_junctions_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStream(value);
      break;
    case 2004:
      var value = new vivacity_config_v2_smart_junctions_pb.Stage;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.Stage.deserializeBinaryFromReader);
      msg.setStage(value);
      break;
    case 2005:
      var value = new vivacity_config_v2_smart_junctions_pb.Phase;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.Phase.deserializeBinaryFromReader);
      msg.setPhase(value);
      break;
    case 2006:
      var value = new vivacity_config_v2_smart_junctions_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_v2_smart_junctions_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setStageToPhase(value);
      break;
    case 2007:
      var value = new vivacity_config_v2_zones_image_space_pb.ZoneImageSpaceToClass;
      reader.readMessage(value,vivacity_config_v2_zones_image_space_pb.ZoneImageSpaceToClass.deserializeBinaryFromReader);
      msg.setZoneImageSpaceToClass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTestParentEntity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_test_entity_pb.TestParentEntity.serializeBinaryToWriter
    );
  }
  f = message.getTestChildEntity();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_test_entity_pb.TestChildEntity.serializeBinaryToWriter
    );
  }
  f = message.getTestReferenceEntity();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_test_entity_pb.TestReferenceEntity.serializeBinaryToWriter
    );
  }
  f = message.getTestParentEntityToTestReferenceEntity();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_test_entity_pb.TestParentEntityToTestReferenceEntity.serializeBinaryToWriter
    );
  }
  f = message.getHardware();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      vivacity_config_v2_hardware_pb.Hardware.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgram();
  if (f != null) {
    writer.writeMessage(
      1001,
      f,
      vivacity_config_v2_vision_program_pb.VisionProgram.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToClass();
  if (f != null) {
    writer.writeMessage(
      1003,
      f,
      vivacity_config_v2_vision_program_pb.VisionProgramToClass.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      2000,
      f,
      vivacity_config_v2_smart_junctions_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      2001,
      f,
      vivacity_config_v2_smart_junctions_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getController();
  if (f != null) {
    writer.writeMessage(
      2002,
      f,
      vivacity_config_v2_smart_junctions_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerStream();
  if (f != null) {
    writer.writeMessage(
      2003,
      f,
      vivacity_config_v2_smart_junctions_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getStage();
  if (f != null) {
    writer.writeMessage(
      2004,
      f,
      vivacity_config_v2_smart_junctions_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getPhase();
  if (f != null) {
    writer.writeMessage(
      2005,
      f,
      vivacity_config_v2_smart_junctions_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getStageToPhase();
  if (f != null) {
    writer.writeMessage(
      2006,
      f,
      vivacity_config_v2_smart_junctions_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getZoneImageSpaceToClass();
  if (f != null) {
    writer.writeMessage(
      2007,
      f,
      vivacity_config_v2_zones_image_space_pb.ZoneImageSpaceToClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional SharedMetadataClassConfig.ClassType class_type = 1;
 * @return {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getClassType = function() {
  return /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setClassType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TestParentEntity test_parent_entity = 10;
 * @return {?proto.vivacity.config_v2.TestParentEntity}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getTestParentEntity = function() {
  return /** @type{?proto.vivacity.config_v2.TestParentEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_test_entity_pb.TestParentEntity, 10));
};


/**
 * @param {?proto.vivacity.config_v2.TestParentEntity|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setTestParentEntity = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearTestParentEntity = function() {
  return this.setTestParentEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasTestParentEntity = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional TestChildEntity test_child_entity = 11;
 * @return {?proto.vivacity.config_v2.TestChildEntity}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getTestChildEntity = function() {
  return /** @type{?proto.vivacity.config_v2.TestChildEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_test_entity_pb.TestChildEntity, 11));
};


/**
 * @param {?proto.vivacity.config_v2.TestChildEntity|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setTestChildEntity = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearTestChildEntity = function() {
  return this.setTestChildEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasTestChildEntity = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TestReferenceEntity test_reference_entity = 12;
 * @return {?proto.vivacity.config_v2.TestReferenceEntity}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getTestReferenceEntity = function() {
  return /** @type{?proto.vivacity.config_v2.TestReferenceEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_test_entity_pb.TestReferenceEntity, 12));
};


/**
 * @param {?proto.vivacity.config_v2.TestReferenceEntity|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setTestReferenceEntity = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearTestReferenceEntity = function() {
  return this.setTestReferenceEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasTestReferenceEntity = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TestParentEntityToTestReferenceEntity test_parent_entity_to_test_reference_entity = 13;
 * @return {?proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getTestParentEntityToTestReferenceEntity = function() {
  return /** @type{?proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_test_entity_pb.TestParentEntityToTestReferenceEntity, 13));
};


/**
 * @param {?proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setTestParentEntityToTestReferenceEntity = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearTestParentEntityToTestReferenceEntity = function() {
  return this.setTestParentEntityToTestReferenceEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasTestParentEntityToTestReferenceEntity = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Hardware hardware = 1000;
 * @return {?proto.vivacity.config_v2.Hardware}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getHardware = function() {
  return /** @type{?proto.vivacity.config_v2.Hardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_hardware_pb.Hardware, 1000));
};


/**
 * @param {?proto.vivacity.config_v2.Hardware|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setHardware = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearHardware = function() {
  return this.setHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasHardware = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * optional VisionProgram vision_program = 1001;
 * @return {?proto.vivacity.config_v2.VisionProgram}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getVisionProgram = function() {
  return /** @type{?proto.vivacity.config_v2.VisionProgram} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_vision_program_pb.VisionProgram, 1001));
};


/**
 * @param {?proto.vivacity.config_v2.VisionProgram|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setVisionProgram = function(value) {
  return jspb.Message.setWrapperField(this, 1001, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearVisionProgram = function() {
  return this.setVisionProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasVisionProgram = function() {
  return jspb.Message.getField(this, 1001) != null;
};


/**
 * optional VisionProgramToClass vision_program_to_class = 1003;
 * @return {?proto.vivacity.config_v2.VisionProgramToClass}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getVisionProgramToClass = function() {
  return /** @type{?proto.vivacity.config_v2.VisionProgramToClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_vision_program_pb.VisionProgramToClass, 1003));
};


/**
 * @param {?proto.vivacity.config_v2.VisionProgramToClass|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setVisionProgramToClass = function(value) {
  return jspb.Message.setWrapperField(this, 1003, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearVisionProgramToClass = function() {
  return this.setVisionProgramToClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasVisionProgramToClass = function() {
  return jspb.Message.getField(this, 1003) != null;
};


/**
 * optional Country country = 2000;
 * @return {?proto.vivacity.config_v2.Country}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getCountry = function() {
  return /** @type{?proto.vivacity.config_v2.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.Country, 2000));
};


/**
 * @param {?proto.vivacity.config_v2.Country|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 2000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 2000) != null;
};


/**
 * optional Region region = 2001;
 * @return {?proto.vivacity.config_v2.Region}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getRegion = function() {
  return /** @type{?proto.vivacity.config_v2.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.Region, 2001));
};


/**
 * @param {?proto.vivacity.config_v2.Region|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 2001, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 2001) != null;
};


/**
 * optional Controller controller = 2002;
 * @return {?proto.vivacity.config_v2.Controller}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getController = function() {
  return /** @type{?proto.vivacity.config_v2.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.Controller, 2002));
};


/**
 * @param {?proto.vivacity.config_v2.Controller|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setController = function(value) {
  return jspb.Message.setWrapperField(this, 2002, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearController = function() {
  return this.setController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasController = function() {
  return jspb.Message.getField(this, 2002) != null;
};


/**
 * optional ControllerStream controller_stream = 2003;
 * @return {?proto.vivacity.config_v2.ControllerStream}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getControllerStream = function() {
  return /** @type{?proto.vivacity.config_v2.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.ControllerStream, 2003));
};


/**
 * @param {?proto.vivacity.config_v2.ControllerStream|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 2003, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearControllerStream = function() {
  return this.setControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasControllerStream = function() {
  return jspb.Message.getField(this, 2003) != null;
};


/**
 * optional Stage stage = 2004;
 * @return {?proto.vivacity.config_v2.Stage}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getStage = function() {
  return /** @type{?proto.vivacity.config_v2.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.Stage, 2004));
};


/**
 * @param {?proto.vivacity.config_v2.Stage|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setStage = function(value) {
  return jspb.Message.setWrapperField(this, 2004, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearStage = function() {
  return this.setStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasStage = function() {
  return jspb.Message.getField(this, 2004) != null;
};


/**
 * optional Phase phase = 2005;
 * @return {?proto.vivacity.config_v2.Phase}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getPhase = function() {
  return /** @type{?proto.vivacity.config_v2.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.Phase, 2005));
};


/**
 * @param {?proto.vivacity.config_v2.Phase|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setPhase = function(value) {
  return jspb.Message.setWrapperField(this, 2005, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearPhase = function() {
  return this.setPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasPhase = function() {
  return jspb.Message.getField(this, 2005) != null;
};


/**
 * optional StageToPhase stage_to_phase = 2006;
 * @return {?proto.vivacity.config_v2.StageToPhase}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getStageToPhase = function() {
  return /** @type{?proto.vivacity.config_v2.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_smart_junctions_pb.StageToPhase, 2006));
};


/**
 * @param {?proto.vivacity.config_v2.StageToPhase|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setStageToPhase = function(value) {
  return jspb.Message.setWrapperField(this, 2006, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearStageToPhase = function() {
  return this.setStageToPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasStageToPhase = function() {
  return jspb.Message.getField(this, 2006) != null;
};


/**
 * optional ZoneImageSpaceToClass zone_image_space_to_class = 2007;
 * @return {?proto.vivacity.config_v2.ZoneImageSpaceToClass}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.getZoneImageSpaceToClass = function() {
  return /** @type{?proto.vivacity.config_v2.ZoneImageSpaceToClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_zones_image_space_pb.ZoneImageSpaceToClass, 2007));
};


/**
 * @param {?proto.vivacity.config_v2.ZoneImageSpaceToClass|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
*/
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.setZoneImageSpaceToClass = function(value) {
  return jspb.Message.setWrapperField(this, 2007, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} returns this
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.clearZoneImageSpaceToClass = function() {
  return this.setZoneImageSpaceToClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject.prototype.hasZoneImageSpaceToClass = function() {
  return jspb.Message.getField(this, 2007) != null;
};


/**
 * optional SharedMetadataClassObject object = 1;
 * @return {?proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getObject = function() {
  return /** @type{?proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject, 1));
};


/**
 * @param {?proto.vivacity.config_v2.SharedMetadata.SharedMetadataClassObject|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue correlation_id = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getCorrelationId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setCorrelationId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearCorrelationId = function() {
  return this.setCorrelationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasCorrelationId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue created_by = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getCreatedBy = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue created_at = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getCreatedAt = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue modified_by = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getModifiedBy = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setModifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearModifiedBy = function() {
  return this.setModifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasModifiedBy = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue modified_at = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getModifiedAt = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearModifiedAt = function() {
  return this.setModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasModifiedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue deleted_by = 15;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getDeletedBy = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 15));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setDeletedBy = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearDeletedBy = function() {
  return this.setDeletedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasDeletedBy = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue deleted_at = 16;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getDeletedAt = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 16));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue restored_by = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getRestoredBy = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setRestoredBy = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearRestoredBy = function() {
  return this.setRestoredBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasRestoredBy = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue restored_at = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.getRestoredAt = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
*/
proto.vivacity.config_v2.SharedMetadata.prototype.setRestoredAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.SharedMetadata} returns this
 */
proto.vivacity.config_v2.SharedMetadata.prototype.clearRestoredAt = function() {
  return this.setRestoredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.SharedMetadata.prototype.hasRestoredAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
