/* eslint-disable */
// source: vivacity/core/http_server_event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var vivacity_core_authentication_pb = require('../../vivacity/core/authentication_pb.js');
goog.object.extend(proto, vivacity_core_authentication_pb);
var vivacity_core_authorization_pb = require('../../vivacity/core/authorization_pb.js');
goog.object.extend(proto, vivacity_core_authorization_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
goog.exportSymbol('proto.vivacity.core.HttpServerEvent', null, global);
goog.exportSymbol('proto.vivacity.core.HttpServerEvent.ParameterValue', null, global);
goog.exportSymbol('proto.vivacity.core.HttpServerEvent.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.HttpServerEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.HttpServerEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.HttpServerEvent.displayName = 'proto.vivacity.core.HttpServerEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.HttpServerEvent.ParameterValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.HttpServerEvent.ParameterValue.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.HttpServerEvent.ParameterValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.HttpServerEvent.ParameterValue.displayName = 'proto.vivacity.core.HttpServerEvent.ParameterValue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.HttpServerEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.HttpServerEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.HttpServerEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HttpServerEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    host: jspb.Message.getFieldWithDefault(msg, 10, ""),
    endpointName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    endpointMatchPath: jspb.Message.getFieldWithDefault(msg, 12, ""),
    endpointMatchPathPrefix: jspb.Message.getFieldWithDefault(msg, 13, ""),
    method: jspb.Message.getFieldWithDefault(msg, 14, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    remoteAddress: jspb.Message.getFieldWithDefault(msg, 21, ""),
    path: jspb.Message.getFieldWithDefault(msg, 22, ""),
    queryParametersMap: (f = msg.getQueryParametersMap()) ? f.toObject(includeInstance, proto.vivacity.core.HttpServerEvent.ParameterValue.toObject) : [],
    truncatedRequestBody: jspb.Message.getFieldWithDefault(msg, 24, ""),
    requestBodyLength: jspb.Message.getFieldWithDefault(msg, 25, 0),
    requestContentType: jspb.Message.getFieldWithDefault(msg, 26, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    statusCode: jspb.Message.getFieldWithDefault(msg, 30, 0),
    truncatedResponseBody: jspb.Message.getFieldWithDefault(msg, 31, ""),
    responseBodyLength: jspb.Message.getFieldWithDefault(msg, 32, 0),
    responseContentType: jspb.Message.getFieldWithDefault(msg, 33, ""),
    authMethod: jspb.Message.getFieldWithDefault(msg, 34, 0),
    authenticationIdentifier: jspb.Message.getFieldWithDefault(msg, 35, ""),
    authorizationResponse: (f = msg.getAuthorizationResponse()) && vivacity_core_authorization_pb.AuthorizationResponse.toObject(includeInstance, f),
    requestTimestamp: (f = msg.getRequestTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    authenticationResponseTimestamp: (f = msg.getAuthenticationResponseTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    authorizationResponseTimestamp: (f = msg.getAuthorizationResponseTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    responseTimestamp: (f = msg.getResponseTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    errors: jspb.Message.getFieldWithDefault(msg, 50, ""),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.HttpServerEvent}
 */
proto.vivacity.core.HttpServerEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.HttpServerEvent;
  return proto.vivacity.core.HttpServerEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.HttpServerEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.HttpServerEvent}
 */
proto.vivacity.core.HttpServerEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.HttpServerEvent.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpointName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpointMatchPath(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpointMatchPathPrefix(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteAddress(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 23:
      var value = msg.getQueryParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.HttpServerEvent.ParameterValue.deserializeBinaryFromReader, "", new proto.vivacity.core.HttpServerEvent.ParameterValue());
         });
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruncatedRequestBody(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestBodyLength(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestContentType(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatusCode(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruncatedResponseBody(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResponseBodyLength(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseContentType(value);
      break;
    case 34:
      var value = /** @type {!proto.vivacity.core.AuthMethod} */ (reader.readEnum());
      msg.setAuthMethod(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthenticationIdentifier(value);
      break;
    case 36:
      var value = new vivacity_core_authorization_pb.AuthorizationResponse;
      reader.readMessage(value,vivacity_core_authorization_pb.AuthorizationResponse.deserializeBinaryFromReader);
      msg.setAuthorizationResponse(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRequestTimestamp(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAuthenticationResponseTimestamp(value);
      break;
    case 42:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAuthorizationResponseTimestamp(value);
      break;
    case 43:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResponseTimestamp(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrors(value);
      break;
    case 110:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.HttpServerEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.HttpServerEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.HttpServerEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HttpServerEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEndpointName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEndpointMatchPath();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEndpointMatchPathPrefix();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRemoteAddress();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getQueryParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(23, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.HttpServerEvent.ParameterValue.serializeBinaryToWriter);
  }
  f = message.getTruncatedRequestBody();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getRequestBodyLength();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getRequestContentType();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getTruncatedResponseBody();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getResponseBodyLength();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getResponseContentType();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getAuthMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getAuthenticationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getAuthorizationResponse();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      vivacity_core_authorization_pb.AuthorizationResponse.serializeBinaryToWriter
    );
  }
  f = message.getRequestTimestamp();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuthenticationResponseTimestamp();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizationResponseTimestamp();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResponseTimestamp();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getErrors();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.HttpServerEvent.State = {
  UNKNOWN: 0,
  REQUEST: 1,
  AUTHENTICATION_RESPONSE: 2,
  AUTHORIZATION_RESPONSE: 3,
  RESPONSE: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.HttpServerEvent.ParameterValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.HttpServerEvent.ParameterValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.HttpServerEvent.ParameterValue}
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.HttpServerEvent.ParameterValue;
  return proto.vivacity.core.HttpServerEvent.ParameterValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.HttpServerEvent.ParameterValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.HttpServerEvent.ParameterValue}
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.HttpServerEvent.ParameterValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.HttpServerEvent.ParameterValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.core.HttpServerEvent.ParameterValue} returns this
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.HttpServerEvent.ParameterValue} returns this
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.HttpServerEvent.ParameterValue} returns this
 */
proto.vivacity.core.HttpServerEvent.ParameterValue.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional State state = 1;
 * @return {!proto.vivacity.core.HttpServerEvent.State}
 */
proto.vivacity.core.HttpServerEvent.prototype.getState = function() {
  return /** @type {!proto.vivacity.core.HttpServerEvent.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.HttpServerEvent.State} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 request_id = 2;
 * @return {number}
 */
proto.vivacity.core.HttpServerEvent.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string host = 10;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string endpoint_name = 11;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getEndpointName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setEndpointName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string endpoint_match_path = 12;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getEndpointMatchPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setEndpointMatchPath = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string endpoint_match_path_prefix = 13;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getEndpointMatchPathPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setEndpointMatchPathPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string method = 14;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string user_id = 20;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string remote_address = 21;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getRemoteAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setRemoteAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string path = 22;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * map<string, ParameterValue> query_parameters = 23;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.HttpServerEvent.ParameterValue>}
 */
proto.vivacity.core.HttpServerEvent.prototype.getQueryParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.HttpServerEvent.ParameterValue>} */ (
      jspb.Message.getMapField(this, 23, opt_noLazyCreate,
      proto.vivacity.core.HttpServerEvent.ParameterValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearQueryParametersMap = function() {
  this.getQueryParametersMap().clear();
  return this;
};


/**
 * optional string truncated_request_body = 24;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getTruncatedRequestBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setTruncatedRequestBody = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 request_body_length = 25;
 * @return {number}
 */
proto.vivacity.core.HttpServerEvent.prototype.getRequestBodyLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setRequestBodyLength = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string request_content_type = 26;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getRequestContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setRequestContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string user_name = 27;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional uint32 status_code = 30;
 * @return {number}
 */
proto.vivacity.core.HttpServerEvent.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional string truncated_response_body = 31;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getTruncatedResponseBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setTruncatedResponseBody = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional int32 response_body_length = 32;
 * @return {number}
 */
proto.vivacity.core.HttpServerEvent.prototype.getResponseBodyLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setResponseBodyLength = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional string response_content_type = 33;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getResponseContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setResponseContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional AuthMethod auth_method = 34;
 * @return {!proto.vivacity.core.AuthMethod}
 */
proto.vivacity.core.HttpServerEvent.prototype.getAuthMethod = function() {
  return /** @type {!proto.vivacity.core.AuthMethod} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.vivacity.core.AuthMethod} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setAuthMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional string authentication_identifier = 35;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getAuthenticationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setAuthenticationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional AuthorizationResponse authorization_response = 36;
 * @return {?proto.vivacity.core.AuthorizationResponse}
 */
proto.vivacity.core.HttpServerEvent.prototype.getAuthorizationResponse = function() {
  return /** @type{?proto.vivacity.core.AuthorizationResponse} */ (
    jspb.Message.getWrapperField(this, vivacity_core_authorization_pb.AuthorizationResponse, 36));
};


/**
 * @param {?proto.vivacity.core.AuthorizationResponse|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setAuthorizationResponse = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearAuthorizationResponse = function() {
  return this.setAuthorizationResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasAuthorizationResponse = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.protobuf.Timestamp request_timestamp = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.core.HttpServerEvent.prototype.getRequestTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setRequestTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearRequestTimestamp = function() {
  return this.setRequestTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasRequestTimestamp = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp authentication_response_timestamp = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.core.HttpServerEvent.prototype.getAuthenticationResponseTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setAuthenticationResponseTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearAuthenticationResponseTimestamp = function() {
  return this.setAuthenticationResponseTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasAuthenticationResponseTimestamp = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.Timestamp authorization_response_timestamp = 42;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.core.HttpServerEvent.prototype.getAuthorizationResponseTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 42));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setAuthorizationResponseTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearAuthorizationResponseTimestamp = function() {
  return this.setAuthorizationResponseTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasAuthorizationResponseTimestamp = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.protobuf.Timestamp response_timestamp = 43;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.core.HttpServerEvent.prototype.getResponseTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 43));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setResponseTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearResponseTimestamp = function() {
  return this.setResponseTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasResponseTimestamp = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional string errors = 50;
 * @return {string}
 */
proto.vivacity.core.HttpServerEvent.prototype.getErrors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.setErrors = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional TracingContext tracing_ctx = 110;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.HttpServerEvent.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 110));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
*/
proto.vivacity.core.HttpServerEvent.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.HttpServerEvent} returns this
 */
proto.vivacity.core.HttpServerEvent.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.HttpServerEvent.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 110) != null;
};


goog.object.extend(exports, proto.vivacity.core);
