import React, { FC, useEffect, useState } from "react";
import { CopyAll } from "@mui/icons-material";
import { Button } from "@mui/material";
import { RoutePath } from "../../enums";
import { Video } from "../../interfaces";
import { URL_QUERY_PARAMS } from "../../stores/url.store";

interface CopyVideoLinksButtonProps {
  filteredVideos: Video[];
  search?: string;
}

export const CopyVideoLinksButton: FC<CopyVideoLinksButtonProps> = ({ filteredVideos, search }) => {
  const [copyVideoLinksStatus, setCopyVideoLinksStatus] = useState<number>();

  useEffect(() => {
    setCopyVideoLinksStatus(undefined);
  }, [search]);

  const handleCopyVideoLinks = async () => {
    const params = new URLSearchParams(location.search);

    const videoLinks = filteredVideos
      .map(v => {
        const videoParams = params;
        videoParams.set(URL_QUERY_PARAMS.SELECTED_VIDEO, v.id.toString(10));

        // use latest cvRun
        const cvRunId = v.computerVisionRuns ? v.computerVisionRuns[v.computerVisionRuns.length - 1] : null;
        if (!cvRunId) {
          return "";
        }
        videoParams.set(URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN, cvRunId.toString(10));

        const protocol = window.location.host.includes("localhost") ? "http" : "https";
        const videoUrl = `${protocol}://${window.location.host}${RoutePath.Player}?${videoParams.toString()}`;
        return videoUrl;
      })
      .filter(v => v && v.length)
      .join("\n");
    try {
      await navigator.clipboard.writeText(videoLinks);
      setCopyVideoLinksStatus(videoLinks.split("\n").length);
    } catch {
      setCopyVideoLinksStatus(-1);
    }
  };

  return (
    <div>
      <Button
        style={{ width: "25%", margin: 6 }}
        variant="outlined"
        startIcon={<CopyAll />}
        onClick={handleCopyVideoLinks}
        disabled={filteredVideos.length === 0}
      >
        Copy video links
      </Button>
      {copyVideoLinksStatus && copyVideoLinksStatus === -1 ? (
        <span style={{ fontStyle: "italic", color: "red" }}>Error copying video links, please try again...</span>
      ) : copyVideoLinksStatus && copyVideoLinksStatus > 0 ? (
        <span style={{ fontStyle: "italic", color: "green" }}>
          Successfully copied {copyVideoLinksStatus} video links
        </span>
      ) : null}
    </div>
  );
};
