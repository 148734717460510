/* eslint-disable */
// source: vivacity/config/entity_groups.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.config.EntityGroup', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupGenealogy', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupGenealogy.Edge', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupGenealogy.Edge.Direction', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupGenealogy.EdgeNode', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupGenealogy.Node', null, global);
goog.exportSymbol('proto.vivacity.config.EntityGroupLinkage', null, global);
goog.exportSymbol('proto.vivacity.config.EntityNodeType', null, global);
goog.exportSymbol('proto.vivacity.config.ImageSpaceCountlineGroupingMetadata', null, global);
goog.exportSymbol('proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroupGenealogy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.EntityGroupGenealogy.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.EntityGroupGenealogy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroupGenealogy.displayName = 'proto.vivacity.config.EntityGroupGenealogy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroupGenealogy.Edge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityGroupGenealogy.Edge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroupGenealogy.Edge.displayName = 'proto.vivacity.config.EntityGroupGenealogy.Edge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroupGenealogy.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityGroupGenealogy.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroupGenealogy.Node.displayName = 'proto.vivacity.config.EntityGroupGenealogy.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityGroupGenealogy.EdgeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroupGenealogy.EdgeNode.displayName = 'proto.vivacity.config.EntityGroupGenealogy.EdgeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.EntityGroup.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.EntityGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroup.displayName = 'proto.vivacity.config.EntityGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityGroupLinkage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityGroupLinkage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityGroupLinkage.displayName = 'proto.vivacity.config.EntityGroupLinkage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ImageSpaceCountlineGroupingMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.displayName = 'proto.vivacity.config.ImageSpaceCountlineGroupingMetadata';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.EntityGroupGenealogy.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroupGenealogy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroupGenealogy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    edgeToNodePairsList: jspb.Message.toObjectList(msg.getEdgeToNodePairsList(),
    proto.vivacity.config.EntityGroupGenealogy.EdgeNode.toObject, includeInstance),
    whereClause: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.EntityGroupGenealogy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroupGenealogy;
  return proto.vivacity.config.EntityGroupGenealogy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroupGenealogy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.EntityGroupGenealogy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.vivacity.config.EntityGroupGenealogy.EdgeNode;
      reader.readMessage(value,proto.vivacity.config.EntityGroupGenealogy.EdgeNode.deserializeBinaryFromReader);
      msg.addEdgeToNodePairs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhereClause(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroupGenealogy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroupGenealogy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEdgeToNodePairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.EntityGroupGenealogy.EdgeNode.serializeBinaryToWriter
    );
  }
  f = message.getWhereClause();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroupGenealogy.Edge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Edge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cypherConstraints: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroupGenealogy.Edge;
  return proto.vivacity.config.EntityGroupGenealogy.Edge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Edge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.EntityGroupGenealogy.Edge.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCypherConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroupGenealogy.Edge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Edge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCypherConstraints();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.Direction = {
  UNKNOWN: 0,
  LEFT: 1,
  RIGHT: 2
};

/**
 * optional string type = 1;
 * @return {string}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Direction direction = 2;
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge.Direction}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.getDirection = function() {
  return /** @type {!proto.vivacity.config.EntityGroupGenealogy.Edge.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Edge.Direction} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string cypher_constraints = 3;
 * @return {string}
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.getCypherConstraints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Edge} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.Edge.prototype.setCypherConstraints = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroupGenealogy.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cypherConstraints: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Node}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroupGenealogy.Node;
  return proto.vivacity.config.EntityGroupGenealogy.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Node}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.EntityNodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCypherConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroupGenealogy.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCypherConstraints();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EntityNodeType type = 1;
 * @return {!proto.vivacity.config.EntityNodeType}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.getType = function() {
  return /** @type {!proto.vivacity.config.EntityNodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.EntityNodeType} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Node} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string cypher_constraints = 2;
 * @return {string}
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.getCypherConstraints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.Node} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.Node.prototype.setCypherConstraints = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroupGenealogy.EdgeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    edge: (f = msg.getEdge()) && proto.vivacity.config.EntityGroupGenealogy.Edge.toObject(includeInstance, f),
    node: (f = msg.getNode()) && proto.vivacity.config.EntityGroupGenealogy.Node.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroupGenealogy.EdgeNode;
  return proto.vivacity.config.EntityGroupGenealogy.EdgeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.EntityGroupGenealogy.Edge;
      reader.readMessage(value,proto.vivacity.config.EntityGroupGenealogy.Edge.deserializeBinaryFromReader);
      msg.setEdge(value);
      break;
    case 2:
      var value = new proto.vivacity.config.EntityGroupGenealogy.Node;
      reader.readMessage(value,proto.vivacity.config.EntityGroupGenealogy.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroupGenealogy.EdgeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.EntityGroupGenealogy.Edge.serializeBinaryToWriter
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.config.EntityGroupGenealogy.Node.serializeBinaryToWriter
    );
  }
};


/**
 * optional Edge edge = 1;
 * @return {?proto.vivacity.config.EntityGroupGenealogy.Edge}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.getEdge = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy.Edge} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.EntityGroupGenealogy.Edge, 1));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy.Edge|undefined} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} returns this
*/
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.setEdge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.clearEdge = function() {
  return this.setEdge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.hasEdge = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Node node = 2;
 * @return {?proto.vivacity.config.EntityGroupGenealogy.Node}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.getNode = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy.Node} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.EntityGroupGenealogy.Node, 2));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy.Node|undefined} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} returns this
*/
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.EntityGroupGenealogy.EdgeNode.prototype.hasNode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated EdgeNode edge_to_node_pairs = 2;
 * @return {!Array<!proto.vivacity.config.EntityGroupGenealogy.EdgeNode>}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.getEdgeToNodePairsList = function() {
  return /** @type{!Array<!proto.vivacity.config.EntityGroupGenealogy.EdgeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.EntityGroupGenealogy.EdgeNode, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.EntityGroupGenealogy.EdgeNode>} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy} returns this
*/
proto.vivacity.config.EntityGroupGenealogy.prototype.setEdgeToNodePairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.EntityGroupGenealogy.EdgeNode}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.addEdgeToNodePairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.EntityGroupGenealogy.EdgeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.EntityGroupGenealogy} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.clearEdgeToNodePairsList = function() {
  return this.setEdgeToNodePairsList([]);
};


/**
 * optional string where_clause = 3;
 * @return {string}
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.getWhereClause = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroupGenealogy} returns this
 */
proto.vivacity.config.EntityGroupGenealogy.prototype.setWhereClause = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.EntityGroup.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstNeighbourType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    genealogyIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.EntityGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroup;
  return proto.vivacity.config.EntityGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.EntityGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.EntityNodeType} */ (reader.readEnum());
      msg.setFirstNeighbourType(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGenealogyId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstNeighbourType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGenealogyIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.EntityGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.EntityGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vivacity.config.EntityGroup.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EntityNodeType first_neighbour_type = 4;
 * @return {!proto.vivacity.config.EntityNodeType}
 */
proto.vivacity.config.EntityGroup.prototype.getFirstNeighbourType = function() {
  return /** @type {!proto.vivacity.config.EntityNodeType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.EntityNodeType} value
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.setFirstNeighbourType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated uint32 genealogy_id = 5;
 * @return {!Array<number>}
 */
proto.vivacity.config.EntityGroup.prototype.getGenealogyIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.setGenealogyIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.addGenealogyId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.EntityGroup} returns this
 */
proto.vivacity.config.EntityGroup.prototype.clearGenealogyIdList = function() {
  return this.setGenealogyIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityGroupLinkage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityGroupLinkage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupLinkage.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    imageSpaceCountlineInfo: (f = msg.getImageSpaceCountlineInfo()) && proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.EntityGroupLinkage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityGroupLinkage;
  return proto.vivacity.config.EntityGroupLinkage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityGroupLinkage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.EntityGroupLinkage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.EntityNodeType} */ (reader.readEnum());
      msg.setEntityType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupId(value);
      break;
    case 4:
      var value = new proto.vivacity.config.ImageSpaceCountlineGroupingMetadata;
      reader.readMessage(value,proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.deserializeBinaryFromReader);
      msg.setImageSpaceCountlineInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityGroupLinkage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityGroupLinkage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityGroupLinkage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getImageSpaceCountlineInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional EntityNodeType entity_type = 1;
 * @return {!proto.vivacity.config.EntityNodeType}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.getEntityType = function() {
  return /** @type {!proto.vivacity.config.EntityNodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.EntityNodeType} value
 * @return {!proto.vivacity.config.EntityGroupLinkage} returns this
 */
proto.vivacity.config.EntityGroupLinkage.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 entity_id = 2;
 * @return {number}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityGroupLinkage} returns this
 */
proto.vivacity.config.EntityGroupLinkage.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 group_id = 3;
 * @return {number}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityGroupLinkage} returns this
 */
proto.vivacity.config.EntityGroupLinkage.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ImageSpaceCountlineGroupingMetadata image_space_countline_info = 4;
 * @return {?proto.vivacity.config.ImageSpaceCountlineGroupingMetadata}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.getImageSpaceCountlineInfo = function() {
  return /** @type{?proto.vivacity.config.ImageSpaceCountlineGroupingMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.ImageSpaceCountlineGroupingMetadata, 4));
};


/**
 * @param {?proto.vivacity.config.ImageSpaceCountlineGroupingMetadata|undefined} value
 * @return {!proto.vivacity.config.EntityGroupLinkage} returns this
*/
proto.vivacity.config.EntityGroupLinkage.prototype.setImageSpaceCountlineInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.EntityGroupLinkage} returns this
 */
proto.vivacity.config.EntityGroupLinkage.prototype.clearImageSpaceCountlineInfo = function() {
  return this.setImageSpaceCountlineInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.EntityGroupLinkage.prototype.hasImageSpaceCountlineInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    direction: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ImageSpaceCountlineGroupingMetadata;
  return proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction = {
  UNKNOWN: 0,
  CLOCKWISE: 1,
  ANTICLOCKWISE: 2
};

/**
 * optional Direction direction = 1;
 * @return {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction}
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.prototype.getDirection = function() {
  return /** @type {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.Direction} value
 * @return {!proto.vivacity.config.ImageSpaceCountlineGroupingMetadata} returns this
 */
proto.vivacity.config.ImageSpaceCountlineGroupingMetadata.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.vivacity.config.EntityNodeType = {
  UNKNOWN_ENTITY_NODE_TYPE: 0,
  GROUP: 1,
  HARDWARE: 2,
  VISION_PROGRAM: 3,
  IMAGE_SPACE_COUNTLINE: 4,
  REAL_WORLD_SPACE_COUNTLINE: 5,
  IMAGE_SPACE_ZONE: 6,
  REAL_WORLD_SPACE_ZONE: 7
};

goog.object.extend(exports, proto.vivacity.config);
