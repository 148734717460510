import React, { FC, Fragment } from "react";
import { observer } from "mobx-react";
import * as PIXI from "pixi.js";
import PolyLine from "./PolyLine/PolyLine";
import { PlayerUIStore } from "../../../stores/playerUI.store";

export const HIGHLIGHTED_COLOUR = 0xf699cd;

export const TrackTails: FC<{ playerUIStore: PlayerUIStore; isDTFsInitialised: boolean }> = observer(props => {
  const { playerUIStore, isDTFsInitialised } = props;
  if (!playerUIStore.showTrackTails) {
    return null;
  }

  const tracks: JSX.Element[] = [];

  if (isDTFsInitialised) {
    const activeTracks = playerUIStore.activeTracksByFrame.get(playerUIStore.currentFrameNumber);
    activeTracks?.forEach(trackNumber => {
      const trackStartFrame = playerUIStore.tailStartFrames.get(trackNumber);
      const trackGeometry = playerUIStore.tailGeometries.get(trackNumber);
      let trackColour = playerUIStore.tailColours.get(trackNumber);
      if (trackStartFrame === undefined || trackGeometry === undefined) {
        console.error(`u made tracks wrongly, start frame and/or geometry missing`);
        return;
      }
      const sliceIndex = 2 * (playerUIStore.currentFrameNumber - trackStartFrame);
      const tailGeometry = trackGeometry.slice(0, sliceIndex + 2);
      const ghostOfXmasFuture = trackGeometry.slice(sliceIndex);
      if (!trackColour) {
        console.error(`no track colour for track ${trackNumber}`);
      } else {
        let lineWidth = 3;
        let futureLineWidth = 1;
        let futureAlpha = 0.3;
        if (playerUIStore.mouseOverByTrack.get(trackNumber)) {
          trackColour = HIGHLIGHTED_COLOUR;
          lineWidth = 6;
          futureLineWidth = 6;
          futureAlpha = 1;
        }
        const onClickTrack: (e: PIXI.InteractionEvent) => void = e => {
          playerUIStore.setLastClickedTrackHead(trackNumber);
          const tailStartFrame = playerUIStore.tailStartFrames.get(trackNumber);
          if (tailStartFrame !== undefined) {
            playerUIStore.seekToFrame(tailStartFrame);
          }
          playerUIStore.setMouseOverTrack(trackNumber, false);
        };
        if (tailGeometry.length === 0) {
          console.error(`tail geometry has length 0 for track ${trackNumber}`);
        }
        if (ghostOfXmasFuture.length === 0) {
          console.error(`future tail geometry has length 0 for track ${trackNumber}`);
        }
        if (tailGeometry.length >= 4) {
          tracks.push(
            <PolyLine
              xy={tailGeometry}
              id={trackNumber}
              lineColor={trackColour}
              lineWidth={lineWidth}
              alpha={1}
              key={`${trackNumber}-past`}
              hitAreaWidth={10}
              pointerover={e => {
                playerUIStore.setMouseOverTrack(trackNumber, true);
              }}
              pointerout={e => {
                playerUIStore.setMouseOverTrack(trackNumber, false);
              }}
              pointerup={onClickTrack}
            />
          );
        }
        if (ghostOfXmasFuture.length >= 4) {
          tracks.push(
            <PolyLine
              xy={ghostOfXmasFuture}
              id={trackNumber}
              lineColor={trackColour}
              lineWidth={futureLineWidth}
              alpha={futureAlpha}
              hitAreaWidth={5}
              pointerover={e => {
                playerUIStore.setMouseOverTrack(trackNumber, true);
              }}
              pointerout={e => {
                playerUIStore.setMouseOverTrack(trackNumber, false);
              }}
              pointerup={onClickTrack}
              key={`${trackNumber}-future`}
            />
          );
        }
      }
    });
  }
  return <Fragment>{tracks}</Fragment>;
});
