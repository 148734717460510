import React, { PropsWithChildren } from "react";
import * as PIXI from "pixi.js";
import { Sprite, Container } from "react-pixi-fiber/index";
import { observer } from "mobx-react";
import { StoresContext } from "../../../contexts/stores.context";
import { FrameInterval } from "../../../domain";

const INCIDENT_COLOURS = [
  0xffc8cf, 0xa02a4d, 0x51000e, 0x61cff4, 0x007cb2, 0x003357, 0x00005d, 0xda6d8e, 0xb6c2ce, 0x004448, 0x0c171f,
];

type NearMissIncidentsProps = {
  storesContext: StoresContext;
};

export const NearMissIncidents: React.FunctionComponent<PropsWithChildren<NearMissIncidentsProps>> = observer(props => {
  const { playerUIStore } = props.storesContext;
  const { nearMissIncidents, seekToFrame } = playerUIStore;
  const totalFrames = playerUIStore.totalFrames;

  return (
    <Container>
      {Array.from(nearMissIncidents).map(([key, nearMissIncident], index) => {
        return (
          <NearMissIncident
            startPositionX={(playerUIStore.timelineWidth * nearMissIncident.start) / totalFrames}
            endPositionX={(playerUIStore.timelineWidth * nearMissIncident.end) / totalFrames}
            frameInverval={nearMissIncident}
            index={index}
            seekToFrame={seekToFrame}
            key={"near-miss-incident-" + key}
          />
        );
      })}
    </Container>
  );
});

type NearMissIncidentProps = {
  frameInverval: FrameInterval;
  startPositionX: number;
  endPositionX: number;
  index: number;
  seekToFrame: (frameNumber: number) => void;
};

const NearMissIncident: React.FunctionComponent<PropsWithChildren<NearMissIncidentProps>> = (
  props: PropsWithChildren<NearMissIncidentProps>
) => {
  const { startPositionX, endPositionX, index, frameInverval, seekToFrame } = props;
  const width = endPositionX - startPositionX;
  const height = 8;
  return (
    <Sprite
      cursor={"pointer"}
      texture={PIXI.Texture.WHITE}
      tint={INCIDENT_COLOURS[index % INCIDENT_COLOURS.length]}
      x={startPositionX}
      y={(index % 5) * height}
      width={width}
      height={height}
      alpha={0.9}
      interactive={true}
      pointerup={() => seekToFrame(frameInverval.start)}
      pointerdown={() => seekToFrame(frameInverval.start)}
    />
  );
};

type ZoomedNearMissIncidentsProps = {
  storesContext: StoresContext;
};

export const ZoomedNearMissIncidents: React.FunctionComponent<PropsWithChildren<ZoomedNearMissIncidentsProps>> =
  observer(props => {
    const { playerUIStore } = props.storesContext;
    const { nearMissIncidents, seekToFrame } = playerUIStore;

    const filteredNearMissIncidents = Array.from(nearMissIncidents)
      .map(([key, nearMissIncident], index) => {
        return { ...nearMissIncident, index, key };
      })
      .filter(nearMissIncident => {
        const startFraction = nearMissIncident.start / playerUIStore.totalFrames;
        const endFraction = nearMissIncident.end / playerUIStore.totalFrames;
        return endFraction >= playerUIStore.timelineLowerBound && startFraction <= playerUIStore.timelineUpperBound;
      });

    return (
      <Container>
        {filteredNearMissIncidents.map(nearMissIncident => {
          return (
            <ZoomedNearMissIncident
              key={"zoomed-near-miss-incident-" + nearMissIncident.key}
              frameInverval={nearMissIncident}
              seekToFrame={seekToFrame}
              index={nearMissIncident.index}
              totalFrames={playerUIStore.totalFrames}
              lowerBound={playerUIStore.timelineLowerBound}
              upperBound={playerUIStore.timelineUpperBound}
              alpha={0.9}
              timelineWidth={playerUIStore.timelineWidth}
            />
          );
        })}
      </Container>
    );
  });

export type ZoomedNearMissIncidentProps = {
  frameInverval: FrameInterval;
  totalFrames: number;
  lowerBound: number;
  upperBound: number;
  index: number;
  seekToFrame: (frameNumber: number) => void;
  alpha: number;
  timelineWidth: number;
};

export const ZoomedNearMissIncident: React.FunctionComponent<PropsWithChildren<ZoomedNearMissIncidentProps>> = (
  props: PropsWithChildren<ZoomedNearMissIncidentProps>
) => {
  const { index, frameInverval, seekToFrame, timelineWidth, totalFrames, lowerBound, upperBound } = props;
  const height = 4;
  const startFraction = frameInverval.start / totalFrames;
  const endFraction = frameInverval.end / totalFrames;

  const xStartPos = (timelineWidth * (startFraction - lowerBound)) / (upperBound - lowerBound);
  const xEndPos = (timelineWidth * (endFraction - lowerBound)) / (upperBound - lowerBound);
  const width = xEndPos - xStartPos;
  return (
    <Sprite
      cursor={"pointer"}
      texture={PIXI.Texture.WHITE}
      tint={INCIDENT_COLOURS[index % INCIDENT_COLOURS.length]}
      x={xStartPos}
      y={(index % 5) * height + 40}
      width={width}
      height={height}
      alpha={0.9}
      interactive={true}
      pointerup={() => seekToFrame(frameInverval.start)}
      pointerdown={() => seekToFrame(frameInverval.start)}
    />
  );
};
