/* eslint-disable */
// source: vivacity/core/track_head.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var vivacity_core_anpr_confidence_pb = require('../../vivacity/core/anpr_confidence_pb.js');
goog.object.extend(proto, vivacity_core_anpr_confidence_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_countline_crossing_pb = require('../../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
var vivacity_core_detection_box_pb = require('../../vivacity/core/detection_box_pb.js');
goog.object.extend(proto, vivacity_core_detection_box_pb);
var vivacity_core_movement_pb = require('../../vivacity/core/movement_pb.js');
goog.object.extend(proto, vivacity_core_movement_pb);
var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_vector_pb = require('../../vivacity/core/vector_pb.js');
goog.object.extend(proto, vivacity_core_vector_pb);
goog.exportSymbol('proto.vivacity.core.DebugMatchScore', null, global);
goog.exportSymbol('proto.vivacity.core.DebugMatchScore.GatingProgress', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissCategoryConfidenceMapEntry', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEvent', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventCategory', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.EventData', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.Metadata', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.PetEvent', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.ProximalEvent', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.TrackPair', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissEventV2.TtcEvent', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissPair', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatus', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatus.EventType', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatus.NearMissMetric', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.EventState', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.Metadata', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.PetStatus', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.ProximalStatus', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.StatusData', null, global);
goog.exportSymbol('proto.vivacity.core.NearMissStatusV2.TtcStatus', null, global);
goog.exportSymbol('proto.vivacity.core.TrackHead', null, global);
goog.exportSymbol('proto.vivacity.core.TrackHeadConfidence', null, global);
goog.exportSymbol('proto.vivacity.core.TrackMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.TrackMetadata.TrackTerminationReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DebugMatchScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.DebugMatchScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DebugMatchScore.displayName = 'proto.vivacity.core.DebugMatchScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TrackMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TrackMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackMetadata.displayName = 'proto.vivacity.core.TrackMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackHeadConfidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.TrackHeadConfidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackHeadConfidence.displayName = 'proto.vivacity.core.TrackHeadConfidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackHead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TrackHead.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TrackHead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackHead.displayName = 'proto.vivacity.core.TrackHead';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissCategoryConfidenceMapEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissCategoryConfidenceMapEntry.displayName = 'proto.vivacity.core.NearMissCategoryConfidenceMapEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.NearMissStatusV2.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.displayName = 'proto.vivacity.core.NearMissStatusV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.NearMissStatusV2.Metadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.Metadata.displayName = 'proto.vivacity.core.NearMissStatusV2.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.displayName = 'proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.displayName = 'proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.displayName = 'proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.displayName = 'proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.StatusData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.StatusData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.StatusData.displayName = 'proto.vivacity.core.NearMissStatusV2.StatusData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.PetStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.PetStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.PetStatus.displayName = 'proto.vivacity.core.NearMissStatusV2.PetStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.ProximalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.ProximalStatus.displayName = 'proto.vivacity.core.NearMissStatusV2.ProximalStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatusV2.TtcStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatusV2.TtcStatus.displayName = 'proto.vivacity.core.NearMissStatusV2.TtcStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.NearMissEventV2.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.displayName = 'proto.vivacity.core.NearMissEventV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.TrackPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.TrackPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.TrackPair.displayName = 'proto.vivacity.core.NearMissEventV2.TrackPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.Metadata.displayName = 'proto.vivacity.core.NearMissEventV2.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.EventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.EventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.EventData.displayName = 'proto.vivacity.core.NearMissEventV2.EventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.PetEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.PetEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.PetEvent.displayName = 'proto.vivacity.core.NearMissEventV2.PetEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.ProximalEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.ProximalEvent.displayName = 'proto.vivacity.core.NearMissEventV2.ProximalEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEventV2.TtcEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEventV2.TtcEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEventV2.TtcEvent.displayName = 'proto.vivacity.core.NearMissEventV2.TtcEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatus.displayName = 'proto.vivacity.core.NearMissStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.displayName = 'proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NearMissEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NearMissEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NearMissEvent.displayName = 'proto.vivacity.core.NearMissEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DebugMatchScore.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DebugMatchScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DebugMatchScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DebugMatchScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    iouScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    labScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lbpSimpleScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lbpOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lbpColourOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lbpFullnessOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lbpStrengthOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    hogScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    yoloEmbedding1024Score: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    overallWeightedFeatureScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    localVsGlobalMotionAngleScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    classTransitionScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    gatingProgress: jspb.Message.getFieldWithDefault(msg, 13, 0),
    candidateBox: (f = msg.getCandidateBox()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.DebugMatchScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DebugMatchScore;
  return proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DebugMatchScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIouScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLabScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpSimpleScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpOrientedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpColourOrientedScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpFullnessOrientedScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpStrengthOrientedScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHogScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYoloEmbedding1024Score(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverallWeightedFeatureScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLocalVsGlobalMotionAngleScore(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassTransitionScore(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.DebugMatchScore.GatingProgress} */ (reader.readEnum());
      msg.setGatingProgress(value);
      break;
    case 14:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setCandidateBox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DebugMatchScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DebugMatchScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIouScore();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLabScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLbpSimpleScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLbpOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLbpColourOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLbpFullnessOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLbpStrengthOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getHogScore();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getYoloEmbedding1024Score();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getOverallWeightedFeatureScore();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getLocalVsGlobalMotionAngleScore();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getClassTransitionScore();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getGatingProgress();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCandidateBox();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.DebugMatchScore.GatingProgress = {
  NOT_GATED: 0,
  IOU: 1,
  IOU_LOOSENED_NOT_YET_VALID: 2,
  FEATURE_HISTOGRAM: 3,
  LOCAL_VS_GLOBAL_MOTION_ANGLE: 4
};

/**
 * optional float iou_score = 1;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getIouScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setIouScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lab_score = 2;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLabScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLabScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float lbp_simple_score = 3;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpSimpleScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpSimpleScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float lbp_oriented_score = 4;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float lbp_colour_oriented_score = 5;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpColourOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpColourOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float lbp_fullness_oriented_score = 6;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpFullnessOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpFullnessOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float lbp_strength_oriented_score = 7;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpStrengthOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpStrengthOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float hog_score = 8;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getHogScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setHogScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float yolo_embedding_1024_score = 9;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getYoloEmbedding1024Score = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setYoloEmbedding1024Score = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float overall_weighted_feature_score = 10;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getOverallWeightedFeatureScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setOverallWeightedFeatureScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float local_vs_global_motion_angle_score = 11;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLocalVsGlobalMotionAngleScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLocalVsGlobalMotionAngleScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float class_transition_score = 12;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getClassTransitionScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setClassTransitionScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional GatingProgress gating_progress = 13;
 * @return {!proto.vivacity.core.DebugMatchScore.GatingProgress}
 */
proto.vivacity.core.DebugMatchScore.prototype.getGatingProgress = function() {
  return /** @type {!proto.vivacity.core.DebugMatchScore.GatingProgress} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.DebugMatchScore.GatingProgress} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setGatingProgress = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional DetectionBox candidate_box = 14;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.DebugMatchScore.prototype.getCandidateBox = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 14));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
*/
proto.vivacity.core.DebugMatchScore.prototype.setCandidateBox = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.clearCandidateBox = function() {
  return this.setCandidateBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DebugMatchScore.prototype.hasCandidateBox = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TrackMetadata.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchedScore: (f = msg.getMatchedScore()) && proto.vivacity.core.DebugMatchScore.toObject(includeInstance, f),
    unmatchedScoresList: jspb.Message.toObjectList(msg.getUnmatchedScoresList(),
    proto.vivacity.core.DebugMatchScore.toObject, includeInstance),
    globalMotionDirection: (f = msg.getGlobalMotionDirection()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    localMotionDirection: (f = msg.getLocalMotionDirection()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    terminationReason: jspb.Message.getFieldWithDefault(msg, 13, 0),
    pose3dKalmanPrediction: (f = msg.getPose3dKalmanPrediction()) && vivacity_core_detection_box_pb.PoseEstimate3d.toObject(includeInstance, f),
    pose3dModelOutput: (f = msg.getPose3dModelOutput()) && vivacity_core_detection_box_pb.PoseEstimate3d.toObject(includeInstance, f),
    nearMissesMap: (f = msg.getNearMissesMap()) ? f.toObject(includeInstance, proto.vivacity.core.NearMissStatusV2.toObject) : [],
    confidence: (f = msg.getConfidence()) && proto.vivacity.core.TrackHeadConfidence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackMetadata;
  return proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.DebugMatchScore;
      reader.readMessage(value,proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader);
      msg.setMatchedScore(value);
      break;
    case 2:
      var value = new proto.vivacity.core.DebugMatchScore;
      reader.readMessage(value,proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader);
      msg.addUnmatchedScores(value);
      break;
    case 11:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setGlobalMotionDirection(value);
      break;
    case 12:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setLocalMotionDirection(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} */ (reader.readEnum());
      msg.setTerminationReason(value);
      break;
    case 14:
      var value = new vivacity_core_detection_box_pb.PoseEstimate3d;
      reader.readMessage(value,vivacity_core_detection_box_pb.PoseEstimate3d.deserializeBinaryFromReader);
      msg.setPose3dKalmanPrediction(value);
      break;
    case 15:
      var value = new vivacity_core_detection_box_pb.PoseEstimate3d;
      reader.readMessage(value,vivacity_core_detection_box_pb.PoseEstimate3d.deserializeBinaryFromReader);
      msg.setPose3dModelOutput(value);
      break;
    case 16:
      var value = msg.getNearMissesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.NearMissStatusV2.deserializeBinaryFromReader, 0, new proto.vivacity.core.NearMissStatusV2());
         });
      break;
    case 17:
      var value = new proto.vivacity.core.TrackHeadConfidence;
      reader.readMessage(value,proto.vivacity.core.TrackHeadConfidence.deserializeBinaryFromReader);
      msg.setConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchedScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter
    );
  }
  f = message.getUnmatchedScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter
    );
  }
  f = message.getGlobalMotionDirection();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getLocalMotionDirection();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getTerminationReason();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getPose3dKalmanPrediction();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_core_detection_box_pb.PoseEstimate3d.serializeBinaryToWriter
    );
  }
  f = message.getPose3dModelOutput();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_core_detection_box_pb.PoseEstimate3d.serializeBinaryToWriter
    );
  }
  f = message.getNearMissesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.NearMissStatusV2.serializeBinaryToWriter);
  }
  f = message.getConfidence();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.vivacity.core.TrackHeadConfidence.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.TrackMetadata.TrackTerminationReason = {
  NOT_TERMINATED_YET: 0,
  NOT_YET_VALID: 1,
  PREDICTION_WIDTH_OUT_OF_BOUNDS: 2,
  PREDICTION_HEIGHT_OUT_OF_BOUNDS: 3,
  PREDICTION_X_OUTSIDE_FRAME: 4,
  PREDICTION_Y_OUTSIDE_FRAME: 5,
  NOT_MATCHED_WITHIN_FRAME_LOOKBACK_WINDOW: 6,
  LETHAL_MASK: 7,
  NOT_MATCHED_WITHIN_FRAME_OCCLUDED_LOOKBACK_WINDOW: 8
};

/**
 * optional DebugMatchScore matched_score = 1;
 * @return {?proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.TrackMetadata.prototype.getMatchedScore = function() {
  return /** @type{?proto.vivacity.core.DebugMatchScore} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DebugMatchScore, 1));
};


/**
 * @param {?proto.vivacity.core.DebugMatchScore|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setMatchedScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearMatchedScore = function() {
  return this.setMatchedScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasMatchedScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DebugMatchScore unmatched_scores = 2;
 * @return {!Array<!proto.vivacity.core.DebugMatchScore>}
 */
proto.vivacity.core.TrackMetadata.prototype.getUnmatchedScoresList = function() {
  return /** @type{!Array<!proto.vivacity.core.DebugMatchScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.DebugMatchScore, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.DebugMatchScore>} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setUnmatchedScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.DebugMatchScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.TrackMetadata.prototype.addUnmatchedScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.DebugMatchScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearUnmatchedScoresList = function() {
  return this.setUnmatchedScoresList([]);
};


/**
 * optional VectorF global_motion_direction = 11;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.TrackMetadata.prototype.getGlobalMotionDirection = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 11));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setGlobalMotionDirection = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearGlobalMotionDirection = function() {
  return this.setGlobalMotionDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasGlobalMotionDirection = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional VectorF local_motion_direction = 12;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.TrackMetadata.prototype.getLocalMotionDirection = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 12));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setLocalMotionDirection = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearLocalMotionDirection = function() {
  return this.setLocalMotionDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasLocalMotionDirection = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TrackTerminationReason termination_reason = 13;
 * @return {!proto.vivacity.core.TrackMetadata.TrackTerminationReason}
 */
proto.vivacity.core.TrackMetadata.prototype.getTerminationReason = function() {
  return /** @type {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.setTerminationReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional PoseEstimate3d pose_3d_kalman_prediction = 14;
 * @return {?proto.vivacity.core.PoseEstimate3d}
 */
proto.vivacity.core.TrackMetadata.prototype.getPose3dKalmanPrediction = function() {
  return /** @type{?proto.vivacity.core.PoseEstimate3d} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.PoseEstimate3d, 14));
};


/**
 * @param {?proto.vivacity.core.PoseEstimate3d|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setPose3dKalmanPrediction = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearPose3dKalmanPrediction = function() {
  return this.setPose3dKalmanPrediction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasPose3dKalmanPrediction = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PoseEstimate3d pose_3d_model_output = 15;
 * @return {?proto.vivacity.core.PoseEstimate3d}
 */
proto.vivacity.core.TrackMetadata.prototype.getPose3dModelOutput = function() {
  return /** @type{?proto.vivacity.core.PoseEstimate3d} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.PoseEstimate3d, 15));
};


/**
 * @param {?proto.vivacity.core.PoseEstimate3d|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setPose3dModelOutput = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearPose3dModelOutput = function() {
  return this.setPose3dModelOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasPose3dModelOutput = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * map<uint32, NearMissStatusV2> near_misses = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.NearMissStatusV2>}
 */
proto.vivacity.core.TrackMetadata.prototype.getNearMissesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.NearMissStatusV2>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      proto.vivacity.core.NearMissStatusV2));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearNearMissesMap = function() {
  this.getNearMissesMap().clear();
  return this;
};


/**
 * optional TrackHeadConfidence confidence = 17;
 * @return {?proto.vivacity.core.TrackHeadConfidence}
 */
proto.vivacity.core.TrackMetadata.prototype.getConfidence = function() {
  return /** @type{?proto.vivacity.core.TrackHeadConfidence} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackHeadConfidence, 17));
};


/**
 * @param {?proto.vivacity.core.TrackHeadConfidence|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setConfidence = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearConfidence = function() {
  return this.setConfidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasConfidence = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackHeadConfidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackHeadConfidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHeadConfidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    isThereGlareOnTrack: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isDetectionBoxAreaSmall: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isDetectionBoxAreaLarge: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isFootprintRectangular: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isPoseAlignedWithShortTermAvg: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isPoseAlignedWithLongTermAvg: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isFootprintTooShort: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isFootprintTooLong: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isFootprintTooNarrow: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isFootprintTooWide: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isDetectionBoxTouchingFrameEdge: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackHeadConfidence}
 */
proto.vivacity.core.TrackHeadConfidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackHeadConfidence;
  return proto.vivacity.core.TrackHeadConfidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackHeadConfidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackHeadConfidence}
 */
proto.vivacity.core.TrackHeadConfidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsThereGlareOnTrack(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetectionBoxAreaSmall(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetectionBoxAreaLarge(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFootprintRectangular(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPoseAlignedWithShortTermAvg(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPoseAlignedWithLongTermAvg(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFootprintTooShort(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFootprintTooLong(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFootprintTooNarrow(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFootprintTooWide(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetectionBoxTouchingFrameEdge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackHeadConfidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackHeadConfidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHeadConfidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsThereGlareOnTrack();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsDetectionBoxAreaSmall();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsDetectionBoxAreaLarge();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsFootprintRectangular();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsPoseAlignedWithShortTermAvg();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsPoseAlignedWithLongTermAvg();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsFootprintTooShort();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsFootprintTooLong();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsFootprintTooNarrow();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsFootprintTooWide();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsDetectionBoxTouchingFrameEdge();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional bool is_there_glare_on_track = 1;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsThereGlareOnTrack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsThereGlareOnTrack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_detection_box_area_small = 2;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsDetectionBoxAreaSmall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsDetectionBoxAreaSmall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_detection_box_area_large = 3;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsDetectionBoxAreaLarge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsDetectionBoxAreaLarge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_footprint_rectangular = 4;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsFootprintRectangular = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsFootprintRectangular = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_pose_aligned_with_short_term_avg = 5;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsPoseAlignedWithShortTermAvg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsPoseAlignedWithShortTermAvg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_pose_aligned_with_long_term_avg = 6;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsPoseAlignedWithLongTermAvg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsPoseAlignedWithLongTermAvg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_footprint_too_short = 7;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsFootprintTooShort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsFootprintTooShort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_footprint_too_long = 8;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsFootprintTooLong = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsFootprintTooLong = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_footprint_too_narrow = 9;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsFootprintTooNarrow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsFootprintTooNarrow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_footprint_too_wide = 10;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsFootprintTooWide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsFootprintTooWide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_detection_box_touching_frame_edge = 11;
 * @return {boolean}
 */
proto.vivacity.core.TrackHeadConfidence.prototype.getIsDetectionBoxTouchingFrameEdge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHeadConfidence} returns this
 */
proto.vivacity.core.TrackHeadConfidence.prototype.setIsDetectionBoxTouchingFrameEdge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TrackHead.repeatedFields_ = [5,6,18,21,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackHead.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackHead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackHead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHead.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionBox: (f = msg.getDetectionBox()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isPredicted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isTracked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lastDetectedTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    occupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    countlineCrossingsList: jspb.Message.toObjectList(msg.getCountlineCrossingsList(),
    vivacity_core_countline_crossing_pb.CountlineCrossing.toObject, includeInstance),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    displacement: (f = msg.getDisplacement()) && vivacity_core_vector_pb.Vector.toObject(includeInstance, f),
    isStopped: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isStoppedLongTerm: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    isOccluded: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    movement: (f = msg.getMovement()) && vivacity_core_movement_pb.Movement.toObject(includeInstance, f),
    trackClass: jspb.Message.getFieldWithDefault(msg, 11, 0),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.TrackMetadata.toObject(includeInstance, f),
    cumulativeStoppedFrames: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cumulativeStoppedFramesByZoneIdMap: (f = msg.getCumulativeStoppedFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeStoppedDirectionalFramesByZoneIdMap: (f = msg.getCumulativeStoppedDirectionalFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeStoppedContraDirectionalFramesByZoneIdMap: (f = msg.getCumulativeStoppedContraDirectionalFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeStoppedMicroseconds: jspb.Message.getFieldWithDefault(msg, 28, 0),
    cumulativeStoppedMicrosecondsByZoneIdMap: (f = msg.getCumulativeStoppedMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeStoppedDirectionalMicrosecondsByZoneIdMap: (f = msg.getCumulativeStoppedDirectionalMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap: (f = msg.getCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeTotalFrames: jspb.Message.getFieldWithDefault(msg, 16, 0),
    cumulativeTotalFramesByZoneIdMap: (f = msg.getCumulativeTotalFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeClassConfidenceList: jspb.Message.toObjectList(msg.getCumulativeClassConfidenceList(),
    vivacity_core_classifying_detector_class_types_pb.ClassConfidence.toObject, includeInstance),
    trackPlate: jspb.Message.getFieldWithDefault(msg, 19, ""),
    trackHashedPlate: jspb.Message.getFieldWithDefault(msg, 20, ""),
    cumulativeAnprConfidenceList: jspb.Message.toObjectList(msg.getCumulativeAnprConfidenceList(),
    vivacity_core_anpr_confidence_pb.AnprConfidence.toObject, includeInstance),
    anprVehicleClass: jspb.Message.getFieldWithDefault(msg, 22, 0),
    anprAssociatedTrackNumber: jspb.Message.getFieldWithDefault(msg, 23, 0),
    directionalOccupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    contraDirectionalOccupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    firstTimestampMicrosecondsEnteredZoneByZoneIdMap: (f = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    lastTimestampMicrosecondsLeftZoneByZoneIdMap: (f = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    nearMissesMap: (f = msg.getNearMissesMap()) ? f.toObject(includeInstance, proto.vivacity.core.NearMissStatus.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackHead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackHead;
  return proto.vivacity.core.TrackHead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackHead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackHead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setDetectionBox(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPredicted(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTracked(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastDetectedTimestampMicroseconds(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOccupancyZoneId(values[i]);
      }
      break;
    case 6:
      var value = new vivacity_core_countline_crossing_pb.CountlineCrossing;
      reader.readMessage(value,vivacity_core_countline_crossing_pb.CountlineCrossing.deserializeBinaryFromReader);
      msg.addCountlineCrossings(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 8:
      var value = new vivacity_core_vector_pb.Vector;
      reader.readMessage(value,vivacity_core_vector_pb.Vector.deserializeBinaryFromReader);
      msg.setDisplacement(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStopped(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStoppedLongTerm(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOccluded(value);
      break;
    case 10:
      var value = new vivacity_core_movement_pb.Movement;
      reader.readMessage(value,vivacity_core_movement_pb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 11:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass(value);
      break;
    case 12:
      var value = new proto.vivacity.core.TrackMetadata;
      reader.readMessage(value,proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCumulativeStoppedFrames(value);
      break;
    case 15:
      var value = msg.getCumulativeStoppedFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 30:
      var value = msg.getCumulativeStoppedDirectionalFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 31:
      var value = msg.getCumulativeStoppedContraDirectionalFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCumulativeStoppedMicroseconds(value);
      break;
    case 29:
      var value = msg.getCumulativeStoppedMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 32:
      var value = msg.getCumulativeStoppedDirectionalMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 33:
      var value = msg.getCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCumulativeTotalFrames(value);
      break;
    case 17:
      var value = msg.getCumulativeTotalFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 18:
      var value = new vivacity_core_classifying_detector_class_types_pb.ClassConfidence;
      reader.readMessage(value,vivacity_core_classifying_detector_class_types_pb.ClassConfidence.deserializeBinaryFromReader);
      msg.addCumulativeClassConfidence(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackPlate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackHashedPlate(value);
      break;
    case 21:
      var value = new vivacity_core_anpr_confidence_pb.AnprConfidence;
      reader.readMessage(value,vivacity_core_anpr_confidence_pb.AnprConfidence.deserializeBinaryFromReader);
      msg.addCumulativeAnprConfidence(value);
      break;
    case 22:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setAnprVehicleClass(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAnprAssociatedTrackNumber(value);
      break;
    case 24:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDirectionalOccupancyZoneId(values[i]);
      }
      break;
    case 25:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContraDirectionalOccupancyZoneId(values[i]);
      }
      break;
    case 26:
      var value = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 27:
      var value = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 34:
      var value = msg.getNearMissesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.NearMissStatus.deserializeBinaryFromReader, 0, new proto.vivacity.core.NearMissStatus());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackHead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackHead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackHead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionBox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsPredicted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsTracked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLastDetectedTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
  f = message.getCountlineCrossingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      vivacity_core_countline_crossing_pb.CountlineCrossing.serializeBinaryToWriter
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDisplacement();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_vector_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getIsStopped();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsStoppedLongTerm();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getIsOccluded();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_movement_pb.Movement.serializeBinaryToWriter
    );
  }
  f = message.getTrackClass();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.core.TrackMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeStoppedFrames();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCumulativeStoppedFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeStoppedDirectionalFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(30, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeStoppedContraDirectionalFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(31, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeStoppedMicroseconds();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getCumulativeStoppedMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(29, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeStoppedDirectionalMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(32, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(33, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeTotalFrames();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getCumulativeTotalFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeClassConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      vivacity_core_classifying_detector_class_types_pb.ClassConfidence.serializeBinaryToWriter
    );
  }
  f = message.getTrackPlate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTrackHashedPlate();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCumulativeAnprConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      vivacity_core_anpr_confidence_pb.AnprConfidence.serializeBinaryToWriter
    );
  }
  f = message.getAnprVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getAnprAssociatedTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getDirectionalOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      24,
      f
    );
  }
  f = message.getContraDirectionalOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      25,
      f
    );
  }
  f = message.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(26, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getLastTimestampMicrosecondsLeftZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(27, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getNearMissesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(34, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.NearMissStatus.serializeBinaryToWriter);
  }
};


/**
 * optional DetectionBox detection_box = 1;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.TrackHead.prototype.getDetectionBox = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 1));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setDetectionBox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDetectionBox = function() {
  return this.setDetectionBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasDetectionBox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 track_number = 2;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_predicted = 3;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsPredicted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsPredicted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_tracked = 13;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsTracked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint64 last_detected_timestamp_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getLastDetectedTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setLastDetectedTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 occupancy_zone_id = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearOccupancyZoneIdList = function() {
  return this.setOccupancyZoneIdList([]);
};


/**
 * repeated CountlineCrossing countline_crossings = 6;
 * @return {!Array<!proto.vivacity.core.CountlineCrossing>}
 */
proto.vivacity.core.TrackHead.prototype.getCountlineCrossingsList = function() {
  return /** @type{!Array<!proto.vivacity.core.CountlineCrossing>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_countline_crossing_pb.CountlineCrossing, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.CountlineCrossing>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCountlineCrossingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.core.CountlineCrossing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.core.TrackHead.prototype.addCountlineCrossings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.core.CountlineCrossing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCountlineCrossingsList = function() {
  return this.setCountlineCrossingsList([]);
};


/**
 * optional uint64 frame_time_microseconds = 7;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Vector displacement = 8;
 * @return {?proto.vivacity.core.Vector}
 */
proto.vivacity.core.TrackHead.prototype.getDisplacement = function() {
  return /** @type{?proto.vivacity.core.Vector} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.Vector, 8));
};


/**
 * @param {?proto.vivacity.core.Vector|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setDisplacement = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDisplacement = function() {
  return this.setDisplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasDisplacement = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_stopped = 9;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_stopped_long_term = 35;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsStoppedLongTerm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsStoppedLongTerm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool is_occluded = 36;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsOccluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsOccluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional Movement movement = 10;
 * @return {?proto.vivacity.core.Movement}
 */
proto.vivacity.core.TrackHead.prototype.getMovement = function() {
  return /** @type{?proto.vivacity.core.Movement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_movement_pb.Movement, 10));
};


/**
 * @param {?proto.vivacity.core.Movement|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ClassifyingDetectorClassTypes track_class = 11;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TrackHead.prototype.getTrackClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional TrackMetadata metadata = 12;
 * @return {?proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackHead.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.TrackMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackMetadata, 12));
};


/**
 * @param {?proto.vivacity.core.TrackMetadata|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 cumulative_stopped_frames = 14;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setCumulativeStoppedFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * map<uint32, uint32> cumulative_stopped_frames_by_zone_id = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedFramesByZoneIdMap = function() {
  this.getCumulativeStoppedFramesByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint32> cumulative_stopped_directional_frames_by_zone_id = 30;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedDirectionalFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 30, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedDirectionalFramesByZoneIdMap = function() {
  this.getCumulativeStoppedDirectionalFramesByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint32> cumulative_stopped_contra_directional_frames_by_zone_id = 31;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedContraDirectionalFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 31, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedContraDirectionalFramesByZoneIdMap = function() {
  this.getCumulativeStoppedContraDirectionalFramesByZoneIdMap().clear();
  return this;
};


/**
 * optional uint32 cumulative_stopped_microseconds = 28;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setCumulativeStoppedMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * map<uint32, uint32> cumulative_stopped_microseconds_by_zone_id = 29;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 29, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedMicrosecondsByZoneIdMap = function() {
  this.getCumulativeStoppedMicrosecondsByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint32> cumulative_stopped_directional_microseconds_by_zone_id = 32;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedDirectionalMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 32, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedDirectionalMicrosecondsByZoneIdMap = function() {
  this.getCumulativeStoppedDirectionalMicrosecondsByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint32> cumulative_stopped_contra_directional_microseconds_by_zone_id = 33;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 33, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap = function() {
  this.getCumulativeStoppedContraDirectionalMicrosecondsByZoneIdMap().clear();
  return this;
};


/**
 * optional uint32 cumulative_total_frames = 16;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeTotalFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setCumulativeTotalFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * map<uint32, uint32> cumulative_total_frames_by_zone_id = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeTotalFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeTotalFramesByZoneIdMap = function() {
  this.getCumulativeTotalFramesByZoneIdMap().clear();
  return this;
};


/**
 * repeated ClassConfidence cumulative_class_confidence = 18;
 * @return {!Array<!proto.vivacity.core.ClassConfidence>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeClassConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_classifying_detector_class_types_pb.ClassConfidence, 18));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassConfidence>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCumulativeClassConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.vivacity.core.ClassConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassConfidence}
 */
proto.vivacity.core.TrackHead.prototype.addCumulativeClassConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.vivacity.core.ClassConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeClassConfidenceList = function() {
  return this.setCumulativeClassConfidenceList([]);
};


/**
 * optional string track_plate = 19;
 * @return {string}
 */
proto.vivacity.core.TrackHead.prototype.getTrackPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string track_hashed_plate = 20;
 * @return {string}
 */
proto.vivacity.core.TrackHead.prototype.getTrackHashedPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackHashedPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated AnprConfidence cumulative_anpr_confidence = 21;
 * @return {!Array<!proto.vivacity.core.AnprConfidence>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeAnprConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.AnprConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_anpr_confidence_pb.AnprConfidence, 21));
};


/**
 * @param {!Array<!proto.vivacity.core.AnprConfidence>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCumulativeAnprConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.vivacity.core.AnprConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AnprConfidence}
 */
proto.vivacity.core.TrackHead.prototype.addCumulativeAnprConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.vivacity.core.AnprConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeAnprConfidenceList = function() {
  return this.setCumulativeAnprConfidenceList([]);
};


/**
 * optional ClassifyingDetectorClassTypes anpr_vehicle_class = 22;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TrackHead.prototype.getAnprVehicleClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setAnprVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional uint32 anpr_associated_track_number = 23;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getAnprAssociatedTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setAnprAssociatedTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * repeated uint32 directional_occupancy_zone_id = 24;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getDirectionalOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setDirectionalOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addDirectionalOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDirectionalOccupancyZoneIdList = function() {
  return this.setDirectionalOccupancyZoneIdList([]);
};


/**
 * repeated uint32 contra_directional_occupancy_zone_id = 25;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getContraDirectionalOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setContraDirectionalOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addContraDirectionalOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearContraDirectionalOccupancyZoneIdList = function() {
  return this.setContraDirectionalOccupancyZoneIdList([]);
};


/**
 * map<uint32, uint64> first_timestamp_microseconds_entered_zone_by_zone_id = 26;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 26, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function() {
  this.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint64> last_timestamp_microseconds_left_zone_by_zone_id = 27;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getLastTimestampMicrosecondsLeftZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 27, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearLastTimestampMicrosecondsLeftZoneByZoneIdMap = function() {
  this.getLastTimestampMicrosecondsLeftZoneByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, NearMissStatus> near_misses = 34;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.NearMissStatus>}
 */
proto.vivacity.core.TrackHead.prototype.getNearMissesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.NearMissStatus>} */ (
      jspb.Message.getMapField(this, 34, opt_noLazyCreate,
      proto.vivacity.core.NearMissStatus));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearNearMissesMap = function() {
  this.getNearMissesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissCategoryConfidenceMapEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissCategoryConfidenceMapEntry;
  return proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.NearMissEventCategory} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCategoryConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissCategoryConfidenceMapEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCategoryConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional NearMissEventCategory category_type = 1;
 * @return {!proto.vivacity.core.NearMissEventCategory}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.getCategoryType = function() {
  return /** @type {!proto.vivacity.core.NearMissEventCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissEventCategory} value
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry} returns this
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float category_confidence = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.getCategoryConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry} returns this
 */
proto.vivacity.core.NearMissCategoryConfidenceMapEntry.prototype.setCategoryConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.NearMissStatusV2.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.NearMissStatusV2.Metadata.toObject(includeInstance, f),
    mostCriticalTrackHeadPair: (f = msg.getMostCriticalTrackHeadPair()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject(includeInstance, f),
    globalStatus: (f = msg.getGlobalStatus()) && proto.vivacity.core.NearMissStatusV2.StatusData.toObject(includeInstance, f),
    petStatus: (f = msg.getPetStatus()) && proto.vivacity.core.NearMissStatusV2.PetStatus.toObject(includeInstance, f),
    proximalStatus: (f = msg.getProximalStatus()) && proto.vivacity.core.NearMissStatusV2.ProximalStatus.toObject(includeInstance, f),
    ttcStatus: (f = msg.getTtcStatus()) && proto.vivacity.core.NearMissStatusV2.TtcStatus.toObject(includeInstance, f),
    cumulativeCategorisationConfidenceMappingList: jspb.Message.toObjectList(msg.getCumulativeCategorisationConfidenceMappingList(),
    proto.vivacity.core.NearMissCategoryConfidenceMapEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2}
 */
proto.vivacity.core.NearMissStatusV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2;
  return proto.vivacity.core.NearMissStatusV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2}
 */
proto.vivacity.core.NearMissStatusV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = new proto.vivacity.core.NearMissStatusV2.Metadata;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader);
      msg.setMostCriticalTrackHeadPair(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.StatusData;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader);
      msg.setGlobalStatus(value);
      break;
    case 3:
      var value = new proto.vivacity.core.NearMissStatusV2.PetStatus;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.PetStatus.deserializeBinaryFromReader);
      msg.setPetStatus(value);
      break;
    case 4:
      var value = new proto.vivacity.core.NearMissStatusV2.ProximalStatus;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.ProximalStatus.deserializeBinaryFromReader);
      msg.setProximalStatus(value);
      break;
    case 5:
      var value = new proto.vivacity.core.NearMissStatusV2.TtcStatus;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.TtcStatus.deserializeBinaryFromReader);
      msg.setTtcStatus(value);
      break;
    case 7:
      var value = new proto.vivacity.core.NearMissCategoryConfidenceMapEntry;
      reader.readMessage(value,proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinaryFromReader);
      msg.addCumulativeCategorisationConfidenceMapping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.NearMissStatusV2.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalTrackHeadPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter
    );
  }
  f = message.getGlobalStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter
    );
  }
  f = message.getPetStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.NearMissStatusV2.PetStatus.serializeBinaryToWriter
    );
  }
  f = message.getProximalStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.NearMissStatusV2.ProximalStatus.serializeBinaryToWriter
    );
  }
  f = message.getTtcStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.core.NearMissStatusV2.TtcStatus.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeCategorisationConfidenceMappingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.core.NearMissCategoryConfidenceMapEntry.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.NearMissStatusV2.EventState = {
  UNKNOWN_EVENT_STATE: 0,
  CANDIDATE: 1,
  CANDIDACY_FAILED: 2,
  STARTED: 3,
  ONGOING: 4,
  ENDED: 5
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.NearMissStatusV2.Metadata.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    categorisationMetrics: (f = msg.getCategorisationMetrics()) && proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.toObject(includeInstance, f),
    categorisationConfidenceMappingList: jspb.Message.toObjectList(msg.getCategorisationConfidenceMappingList(),
    proto.vivacity.core.NearMissCategoryConfidenceMapEntry.toObject, includeInstance),
    unsafeJson: (f = msg.getUnsafeJson()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    isValidated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pair: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.Metadata;
  return proto.vivacity.core.NearMissStatusV2.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.deserializeBinaryFromReader);
      msg.setCategorisationMetrics(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissCategoryConfidenceMapEntry;
      reader.readMessage(value,proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinaryFromReader);
      msg.addCategorisationConfidenceMapping(value);
      break;
    case 3:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setUnsafeJson(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValidated(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.core.NearMissPair} */ (reader.readEnum());
      msg.setPair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategorisationMetrics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.serializeBinaryToWriter
    );
  }
  f = message.getCategorisationConfidenceMappingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.NearMissCategoryConfidenceMapEntry.serializeBinaryToWriter
    );
  }
  f = message.getUnsafeJson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getIsValidated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPair();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    track1Metrics: (f = msg.getTrack1Metrics()) && proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.toObject(includeInstance, f),
    track2Metrics: (f = msg.getTrack2Metrics()) && proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.toObject(includeInstance, f),
    angleDelta: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics;
  return proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.deserializeBinaryFromReader);
      msg.setTrack1Metrics(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.deserializeBinaryFromReader);
      msg.setTrack2Metrics(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngleDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrack1Metrics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.serializeBinaryToWriter
    );
  }
  f = message.getTrack2Metrics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.serializeBinaryToWriter
    );
  }
  f = message.getAngleDelta();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics;
  return proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float speed = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional TrackMetrics track_1_metrics = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.getTrack1Metrics = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} returns this
*/
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.setTrack1Metrics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.clearTrack1Metrics = function() {
  return this.setTrack1Metrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.hasTrack1Metrics = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrackMetrics track_2_metrics = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.getTrack2Metrics = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.TrackMetrics|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} returns this
*/
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.setTrack2Metrics = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.clearTrack2Metrics = function() {
  return this.setTrack2Metrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.hasTrack2Metrics = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float angle_delta = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.getAngleDelta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics.prototype.setAngleDelta = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional CategorisationMetrics categorisation_metrics = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.getCategorisationMetrics = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.Metadata.CategorisationMetrics|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
*/
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.setCategorisationMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.clearCategorisationMetrics = function() {
  return this.setCategorisationMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.hasCategorisationMetrics = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated NearMissCategoryConfidenceMapEntry categorisation_confidence_mapping = 2;
 * @return {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.getCategorisationConfidenceMappingList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
*/
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.setCategorisationConfidenceMappingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.addCategorisationConfidenceMapping = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.clearCategorisationConfidenceMappingList = function() {
  return this.setCategorisationConfidenceMappingList([]);
};


/**
 * optional google.protobuf.Struct unsafe_json = 3;
 * @return {?proto.google.protobuf.Struct}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.getUnsafeJson = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 3));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
*/
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.setUnsafeJson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.clearUnsafeJson = function() {
  return this.setUnsafeJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.hasUnsafeJson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_validated = 4;
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.getIsValidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.setIsValidated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional NearMissPair pair = 5;
 * @return {!proto.vivacity.core.NearMissPair}
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.getPair = function() {
  return /** @type {!proto.vivacity.core.NearMissPair} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissPair} value
 * @return {!proto.vivacity.core.NearMissStatusV2.Metadata} returns this
 */
proto.vivacity.core.NearMissStatusV2.Metadata.prototype.setPair = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    track1: (f = msg.getTrack1()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.toObject(includeInstance, f),
    track2: (f = msg.getTrack2()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair;
  return proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.deserializeBinaryFromReader);
      msg.setTrack1(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.deserializeBinaryFromReader);
      msg.setTrack2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrack1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrack2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionBox: (f = msg.getDetectionBox()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    movement: (f = msg.getMovement()) && vivacity_core_movement_pb.Movement.toObject(includeInstance, f),
    trackClass: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead;
  return proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setDetectionBox(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 4:
      var value = new vivacity_core_movement_pb.Movement;
      reader.readMessage(value,vivacity_core_movement_pb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionBox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_movement_pb.Movement.serializeBinaryToWriter
    );
  }
  f = message.getTrackClass();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional DetectionBox detection_box = 1;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.getDetectionBox = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 1));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
*/
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.setDetectionBox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.clearDetectionBox = function() {
  return this.setDetectionBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.hasDetectionBox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 track_number = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 frame_time_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Movement movement = 4;
 * @return {?proto.vivacity.core.Movement}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.getMovement = function() {
  return /** @type{?proto.vivacity.core.Movement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_movement_pb.Movement, 4));
};


/**
 * @param {?proto.vivacity.core.Movement|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
*/
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClassifyingDetectorClassTypes track_class = 5;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.getTrackClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead.prototype.setTrackClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TrackHead track_1 = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.getTrack1 = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
*/
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.setTrack1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.clearTrack1 = function() {
  return this.setTrack1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.hasTrack1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrackHead track_2 = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.getTrack2 = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.TrackHead|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
*/
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.setTrack2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.clearTrack2 = function() {
  return this.setTrack2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.hasTrack2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 zone_id = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.StatusData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.StatusData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.StatusData.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startFrameNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.StatusData;
  return proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.StatusData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimestampMicroseconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartFrameNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.StatusData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getStartFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCurrentScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional uint64 start_timestamp_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.getStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.StatusData} returns this
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.setStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 start_frame_number = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.getStartFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.StatusData} returns this
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.setStartFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float current_score = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.getCurrentScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.StatusData} returns this
 */
proto.vivacity.core.NearMissStatusV2.StatusData.prototype.setCurrentScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.PetStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.PetStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostCriticalTrackHeadPair: (f = msg.getMostCriticalTrackHeadPair()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.vivacity.core.NearMissStatusV2.StatusData.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currentPetMicroseconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mostCriticalPetMicroseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isFirstTrackLeading: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isNearlyMissing: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.PetStatus;
  return proto.vivacity.core.NearMissStatusV2.PetStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.PetStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader);
      msg.setMostCriticalTrackHeadPair(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.StatusData;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentPetMicroseconds(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalPetMicroseconds(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstTrackLeading(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNearlyMissing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.PetStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.PetStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostCriticalTrackHeadPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCurrentPetMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getMostCriticalPetMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getIsFirstTrackLeading();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsNearlyMissing();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional MostCriticalTrackHeadPair most_critical_track_head_pair = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getMostCriticalTrackHeadPair = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setMostCriticalTrackHeadPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.clearMostCriticalTrackHeadPair = function() {
  return this.setMostCriticalTrackHeadPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.hasMostCriticalTrackHeadPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusData data = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.StatusData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.StatusData, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.StatusData|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventState state = 3;
 * @return {!proto.vivacity.core.NearMissStatusV2.EventState}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getState = function() {
  return /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatusV2.EventState} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float proportion_predicted_detections = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint64 current_pet_microseconds = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getCurrentPetMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setCurrentPetMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 most_critical_pet_microseconds = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getMostCriticalPetMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setMostCriticalPetMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_first_track_leading = 7;
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getIsFirstTrackLeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setIsFirstTrackLeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_nearly_missing = 8;
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.getIsNearlyMissing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissStatusV2.PetStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.PetStatus.prototype.setIsNearlyMissing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.ProximalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostCriticalTrackHeadPair: (f = msg.getMostCriticalTrackHeadPair()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.vivacity.core.NearMissStatusV2.StatusData.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currentDistanceMetres: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    mostCriticalDistanceMetres: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    isNearlyMissing: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.ProximalStatus;
  return proto.vivacity.core.NearMissStatusV2.ProximalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader);
      msg.setMostCriticalTrackHeadPair(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.StatusData;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentDistanceMetres(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalDistanceMetres(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNearlyMissing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.ProximalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostCriticalTrackHeadPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCurrentDistanceMetres();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMostCriticalDistanceMetres();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getIsNearlyMissing();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional MostCriticalTrackHeadPair most_critical_track_head_pair = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getMostCriticalTrackHeadPair = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setMostCriticalTrackHeadPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.clearMostCriticalTrackHeadPair = function() {
  return this.setMostCriticalTrackHeadPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.hasMostCriticalTrackHeadPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusData data = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.StatusData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.StatusData, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.StatusData|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventState state = 3;
 * @return {!proto.vivacity.core.NearMissStatusV2.EventState}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getState = function() {
  return /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatusV2.EventState} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float proportion_predicted_detections = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float current_distance_metres = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getCurrentDistanceMetres = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setCurrentDistanceMetres = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float most_critical_distance_metres = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getMostCriticalDistanceMetres = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setMostCriticalDistanceMetres = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool is_nearly_missing = 7;
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.getIsNearlyMissing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissStatusV2.ProximalStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.ProximalStatus.prototype.setIsNearlyMissing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatusV2.TtcStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatusV2.TtcStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostCriticalTrackHeadPair: (f = msg.getMostCriticalTrackHeadPair()) && proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.vivacity.core.NearMissStatusV2.StatusData.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currentTtcMicroseconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mostCriticalTtcMicroseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isNearlyMissing: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatusV2.TtcStatus;
  return proto.vivacity.core.NearMissStatusV2.TtcStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatusV2.TtcStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.deserializeBinaryFromReader);
      msg.setMostCriticalTrackHeadPair(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissStatusV2.StatusData;
      reader.readMessage(value,proto.vivacity.core.NearMissStatusV2.StatusData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentTtcMicroseconds(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalTtcMicroseconds(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNearlyMissing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatusV2.TtcStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatusV2.TtcStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostCriticalTrackHeadPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissStatusV2.StatusData.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCurrentTtcMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getMostCriticalTtcMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getIsNearlyMissing();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional MostCriticalTrackHeadPair most_critical_track_head_pair = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getMostCriticalTrackHeadPair = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setMostCriticalTrackHeadPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.clearMostCriticalTrackHeadPair = function() {
  return this.setMostCriticalTrackHeadPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.hasMostCriticalTrackHeadPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusData data = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.StatusData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.StatusData, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.StatusData|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
*/
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventState state = 3;
 * @return {!proto.vivacity.core.NearMissStatusV2.EventState}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getState = function() {
  return /** @type {!proto.vivacity.core.NearMissStatusV2.EventState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatusV2.EventState} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float proportion_predicted_detections = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint64 current_ttc_microseconds = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getCurrentTtcMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setCurrentTtcMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 most_critical_ttc_microseconds = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getMostCriticalTtcMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setMostCriticalTtcMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_nearly_missing = 7;
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.getIsNearlyMissing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissStatusV2.TtcStatus} returns this
 */
proto.vivacity.core.NearMissStatusV2.TtcStatus.prototype.setIsNearlyMissing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Metadata metadata = 6;
 * @return {?proto.vivacity.core.NearMissStatusV2.Metadata}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.Metadata, 6));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.Metadata|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MostCriticalTrackHeadPair most_critical_track_head_pair = 1;
 * @return {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getMostCriticalTrackHeadPair = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.MostCriticalTrackHeadPair|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setMostCriticalTrackHeadPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearMostCriticalTrackHeadPair = function() {
  return this.setMostCriticalTrackHeadPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasMostCriticalTrackHeadPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusData global_status = 2;
 * @return {?proto.vivacity.core.NearMissStatusV2.StatusData}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getGlobalStatus = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.StatusData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.StatusData, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.StatusData|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setGlobalStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearGlobalStatus = function() {
  return this.setGlobalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasGlobalStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PetStatus pet_status = 3;
 * @return {?proto.vivacity.core.NearMissStatusV2.PetStatus}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getPetStatus = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.PetStatus} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.PetStatus, 3));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.PetStatus|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setPetStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearPetStatus = function() {
  return this.setPetStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasPetStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProximalStatus proximal_status = 4;
 * @return {?proto.vivacity.core.NearMissStatusV2.ProximalStatus}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getProximalStatus = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.ProximalStatus} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.ProximalStatus, 4));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.ProximalStatus|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setProximalStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearProximalStatus = function() {
  return this.setProximalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasProximalStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TtcStatus ttc_status = 5;
 * @return {?proto.vivacity.core.NearMissStatusV2.TtcStatus}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getTtcStatus = function() {
  return /** @type{?proto.vivacity.core.NearMissStatusV2.TtcStatus} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatusV2.TtcStatus, 5));
};


/**
 * @param {?proto.vivacity.core.NearMissStatusV2.TtcStatus|undefined} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setTtcStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearTtcStatus = function() {
  return this.setTtcStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatusV2.prototype.hasTtcStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated NearMissCategoryConfidenceMapEntry cumulative_categorisation_confidence_mapping = 7;
 * @return {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>}
 */
proto.vivacity.core.NearMissStatusV2.prototype.getCumulativeCategorisationConfidenceMappingList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} value
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
*/
proto.vivacity.core.NearMissStatusV2.prototype.setCumulativeCategorisationConfidenceMappingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry}
 */
proto.vivacity.core.NearMissStatusV2.prototype.addCumulativeCategorisationConfidenceMapping = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissStatusV2} returns this
 */
proto.vivacity.core.NearMissStatusV2.prototype.clearCumulativeCategorisationConfidenceMappingList = function() {
  return this.setCumulativeCategorisationConfidenceMappingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.NearMissEventV2.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackPair: (f = msg.getTrackPair()) && proto.vivacity.core.NearMissEventV2.TrackPair.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.NearMissEventV2.Metadata.toObject(includeInstance, f),
    global: (f = msg.getGlobal()) && proto.vivacity.core.NearMissEventV2.EventData.toObject(includeInstance, f),
    petEventsList: jspb.Message.toObjectList(msg.getPetEventsList(),
    proto.vivacity.core.NearMissEventV2.PetEvent.toObject, includeInstance),
    proximalEventsList: jspb.Message.toObjectList(msg.getProximalEventsList(),
    proto.vivacity.core.NearMissEventV2.ProximalEvent.toObject, includeInstance),
    ttcEventsList: jspb.Message.toObjectList(msg.getTtcEventsList(),
    proto.vivacity.core.NearMissEventV2.TtcEvent.toObject, includeInstance),
    cumulativeCategorisationConfidenceMappingList: jspb.Message.toObjectList(msg.getCumulativeCategorisationConfidenceMappingList(),
    proto.vivacity.core.NearMissCategoryConfidenceMapEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2}
 */
proto.vivacity.core.NearMissEventV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2;
  return proto.vivacity.core.NearMissEventV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2}
 */
proto.vivacity.core.NearMissEventV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissEventV2.TrackPair;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.TrackPair.deserializeBinaryFromReader);
      msg.setTrackPair(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NearMissEventV2.Metadata;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = new proto.vivacity.core.NearMissEventV2.EventData;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader);
      msg.setGlobal(value);
      break;
    case 4:
      var value = new proto.vivacity.core.NearMissEventV2.PetEvent;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.PetEvent.deserializeBinaryFromReader);
      msg.addPetEvents(value);
      break;
    case 5:
      var value = new proto.vivacity.core.NearMissEventV2.ProximalEvent;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.ProximalEvent.deserializeBinaryFromReader);
      msg.addProximalEvents(value);
      break;
    case 6:
      var value = new proto.vivacity.core.NearMissEventV2.TtcEvent;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.TtcEvent.deserializeBinaryFromReader);
      msg.addTtcEvents(value);
      break;
    case 7:
      var value = new proto.vivacity.core.NearMissCategoryConfidenceMapEntry;
      reader.readMessage(value,proto.vivacity.core.NearMissCategoryConfidenceMapEntry.deserializeBinaryFromReader);
      msg.addCumulativeCategorisationConfidenceMapping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissEventV2.TrackPair.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.NearMissEventV2.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getGlobal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter
    );
  }
  f = message.getPetEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.core.NearMissEventV2.PetEvent.serializeBinaryToWriter
    );
  }
  f = message.getProximalEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vivacity.core.NearMissEventV2.ProximalEvent.serializeBinaryToWriter
    );
  }
  f = message.getTtcEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vivacity.core.NearMissEventV2.TtcEvent.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeCategorisationConfidenceMappingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.core.NearMissCategoryConfidenceMapEntry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.TrackPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.TrackPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.TrackPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackNumber1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trackNumber2: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trackClass1: jspb.Message.getFieldWithDefault(msg, 3, 0),
    trackClass2: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pair: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.TrackPair;
  return proto.vivacity.core.NearMissEventV2.TrackPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.TrackPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber2(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass1(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass2(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.core.NearMissPair} */ (reader.readEnum());
      msg.setPair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.TrackPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.TrackPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.TrackPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackNumber1();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTrackNumber2();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTrackClass1();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTrackClass2();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPair();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional uint32 track_number_1 = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.getTrackNumber1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair} returns this
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.setTrackNumber1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 track_number_2 = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.getTrackNumber2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair} returns this
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.setTrackNumber2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ClassifyingDetectorClassTypes track_class_1 = 3;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.getTrackClass1 = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair} returns this
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.setTrackClass1 = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ClassifyingDetectorClassTypes track_class_2 = 4;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.getTrackClass2 = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair} returns this
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.setTrackClass2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NearMissPair pair = 5;
 * @return {!proto.vivacity.core.NearMissPair}
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.getPair = function() {
  return /** @type {!proto.vivacity.core.NearMissPair} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissPair} value
 * @return {!proto.vivacity.core.NearMissEventV2.TrackPair} returns this
 */
proto.vivacity.core.NearMissEventV2.TrackPair.prototype.setPair = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nearMissVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    emittedFrameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unsafeJson: (f = msg.getUnsafeJson()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    isValidated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata}
 */
proto.vivacity.core.NearMissEventV2.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.Metadata;
  return proto.vivacity.core.NearMissEventV2.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata}
 */
proto.vivacity.core.NearMissEventV2.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNearMissVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEmittedFrameTimeMicroseconds(value);
      break;
    case 5:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setUnsafeJson(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValidated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNearMissVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEmittedFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getUnsafeJson();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getIsValidated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 restart_number = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 near_miss_version = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getNearMissVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setNearMissVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 emitted_frame_time_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getEmittedFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setEmittedFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Struct unsafe_json = 5;
 * @return {?proto.google.protobuf.Struct}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getUnsafeJson = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 5));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
*/
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setUnsafeJson = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.clearUnsafeJson = function() {
  return this.setUnsafeJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.hasUnsafeJson = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_validated = 6;
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.getIsValidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissEventV2.Metadata} returns this
 */
proto.vivacity.core.NearMissEventV2.Metadata.prototype.setIsValidated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.EventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.EventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.EventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startFrameNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endFrameNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mostCriticalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    mostCriticalTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mostCriticalTrackDetectionBox1: (f = msg.getMostCriticalTrackDetectionBox1()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    mostCriticalTrackDetectionBox2: (f = msg.getMostCriticalTrackDetectionBox2()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    mostCriticalTrackSpeedMetresPerSecond1: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    mostCriticalTrackSpeedMetresPerSecond2: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    mostCriticalPoint: (f = msg.getMostCriticalPoint()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    mostCriticalZoneId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.EventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.EventData;
  return proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.EventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimestampMicroseconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartFrameNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndTimestampMicroseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndFrameNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalTimestamp(value);
      break;
    case 7:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setMostCriticalTrackDetectionBox1(value);
      break;
    case 8:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setMostCriticalTrackDetectionBox2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalTrackSpeedMetresPerSecond1(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalTrackSpeedMetresPerSecond2(value);
      break;
    case 11:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setMostCriticalPoint(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMostCriticalZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.EventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getStartFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEndTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEndFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMostCriticalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMostCriticalTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getMostCriticalTrackDetectionBox1();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalTrackDetectionBox2();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalTrackSpeedMetresPerSecond1();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getMostCriticalTrackSpeedMetresPerSecond2();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMostCriticalPoint();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalZoneId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional uint64 start_timestamp_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 start_frame_number = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getStartFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setStartFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 end_timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getEndTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setEndTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 end_frame_number = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getEndFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setEndFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float most_critical_score = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint64 most_critical_timestamp = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DetectionBox most_critical_track_detection_box_1 = 7;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalTrackDetectionBox1 = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 7));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
*/
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalTrackDetectionBox1 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.clearMostCriticalTrackDetectionBox1 = function() {
  return this.setMostCriticalTrackDetectionBox1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.hasMostCriticalTrackDetectionBox1 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DetectionBox most_critical_track_detection_box_2 = 8;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalTrackDetectionBox2 = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 8));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
*/
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalTrackDetectionBox2 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.clearMostCriticalTrackDetectionBox2 = function() {
  return this.setMostCriticalTrackDetectionBox2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.hasMostCriticalTrackDetectionBox2 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float most_critical_track_speed_metres_per_second_1 = 9;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalTrackSpeedMetresPerSecond1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalTrackSpeedMetresPerSecond1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float most_critical_track_speed_metres_per_second_2 = 10;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalTrackSpeedMetresPerSecond2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalTrackSpeedMetresPerSecond2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional Point most_critical_point = 11;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalPoint = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 11));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
*/
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalPoint = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.clearMostCriticalPoint = function() {
  return this.setMostCriticalPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.hasMostCriticalPoint = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional uint32 most_critical_zone_id = 12;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.getMostCriticalZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.EventData} returns this
 */
proto.vivacity.core.NearMissEventV2.EventData.prototype.setMostCriticalZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.PetEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.PetEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.PetEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.vivacity.core.NearMissEventV2.EventData.toObject(includeInstance, f),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    mostCriticalPetMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isFirstTrackLeading: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.PetEvent;
  return proto.vivacity.core.NearMissEventV2.PetEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.PetEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissEventV2.EventData;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalPetMicroseconds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstTrackLeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.PetEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.PetEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.PetEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMostCriticalPetMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getIsFirstTrackLeading();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional EventData data = 1;
 * @return {?proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.EventData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.EventData, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.EventData|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent} returns this
*/
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float proportion_predicted_detections = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint64 most_critical_pet_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.getMostCriticalPetMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.setMostCriticalPetMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_first_track_leading = 4;
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.getIsFirstTrackLeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.PetEvent.prototype.setIsFirstTrackLeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.ProximalEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.ProximalEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.vivacity.core.NearMissEventV2.EventData.toObject(includeInstance, f),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    mostCriticalDistanceMetres: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.ProximalEvent;
  return proto.vivacity.core.NearMissEventV2.ProximalEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.ProximalEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissEventV2.EventData;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalDistanceMetres(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.ProximalEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.ProximalEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMostCriticalDistanceMetres();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional EventData data = 1;
 * @return {?proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.EventData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.EventData, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.EventData|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent} returns this
*/
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float proportion_predicted_detections = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float most_critical_distance_metres = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.getMostCriticalDistanceMetres = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.ProximalEvent.prototype.setMostCriticalDistanceMetres = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEventV2.TtcEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEventV2.TtcEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.vivacity.core.NearMissEventV2.EventData.toObject(includeInstance, f),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    mostCriticalTtcMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEventV2.TtcEvent;
  return proto.vivacity.core.NearMissEventV2.TtcEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEventV2.TtcEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NearMissEventV2.EventData;
      reader.readMessage(value,proto.vivacity.core.NearMissEventV2.EventData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalTtcMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEventV2.TtcEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEventV2.TtcEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.NearMissEventV2.EventData.serializeBinaryToWriter
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMostCriticalTtcMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional EventData data = 1;
 * @return {?proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.getData = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.EventData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.EventData, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.EventData|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent} returns this
*/
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float proportion_predicted_detections = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint64 most_critical_ttc_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.getMostCriticalTtcMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent} returns this
 */
proto.vivacity.core.NearMissEventV2.TtcEvent.prototype.setMostCriticalTtcMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TrackPair track_pair = 1;
 * @return {?proto.vivacity.core.NearMissEventV2.TrackPair}
 */
proto.vivacity.core.NearMissEventV2.prototype.getTrackPair = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.TrackPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.TrackPair, 1));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.TrackPair|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setTrackPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearTrackPair = function() {
  return this.setTrackPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.prototype.hasTrackPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Metadata metadata = 2;
 * @return {?proto.vivacity.core.NearMissEventV2.Metadata}
 */
proto.vivacity.core.NearMissEventV2.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.Metadata, 2));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.Metadata|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventData global = 3;
 * @return {?proto.vivacity.core.NearMissEventV2.EventData}
 */
proto.vivacity.core.NearMissEventV2.prototype.getGlobal = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2.EventData} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissEventV2.EventData, 3));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2.EventData|undefined} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setGlobal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearGlobal = function() {
  return this.setGlobal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEventV2.prototype.hasGlobal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PetEvent pet_events = 4;
 * @return {!Array<!proto.vivacity.core.NearMissEventV2.PetEvent>}
 */
proto.vivacity.core.NearMissEventV2.prototype.getPetEventsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissEventV2.PetEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissEventV2.PetEvent, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissEventV2.PetEvent>} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setPetEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.NearMissEventV2.PetEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissEventV2.PetEvent}
 */
proto.vivacity.core.NearMissEventV2.prototype.addPetEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.NearMissEventV2.PetEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearPetEventsList = function() {
  return this.setPetEventsList([]);
};


/**
 * repeated ProximalEvent proximal_events = 5;
 * @return {!Array<!proto.vivacity.core.NearMissEventV2.ProximalEvent>}
 */
proto.vivacity.core.NearMissEventV2.prototype.getProximalEventsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissEventV2.ProximalEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissEventV2.ProximalEvent, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissEventV2.ProximalEvent>} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setProximalEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.NearMissEventV2.ProximalEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissEventV2.ProximalEvent}
 */
proto.vivacity.core.NearMissEventV2.prototype.addProximalEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.NearMissEventV2.ProximalEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearProximalEventsList = function() {
  return this.setProximalEventsList([]);
};


/**
 * repeated TtcEvent ttc_events = 6;
 * @return {!Array<!proto.vivacity.core.NearMissEventV2.TtcEvent>}
 */
proto.vivacity.core.NearMissEventV2.prototype.getTtcEventsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissEventV2.TtcEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissEventV2.TtcEvent, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissEventV2.TtcEvent>} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setTtcEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.core.NearMissEventV2.TtcEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissEventV2.TtcEvent}
 */
proto.vivacity.core.NearMissEventV2.prototype.addTtcEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.core.NearMissEventV2.TtcEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearTtcEventsList = function() {
  return this.setTtcEventsList([]);
};


/**
 * repeated NearMissCategoryConfidenceMapEntry cumulative_categorisation_confidence_mapping = 7;
 * @return {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>}
 */
proto.vivacity.core.NearMissEventV2.prototype.getCumulativeCategorisationConfidenceMappingList = function() {
  return /** @type{!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.NearMissCategoryConfidenceMapEntry>} value
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
*/
proto.vivacity.core.NearMissEventV2.prototype.setCumulativeCategorisationConfidenceMappingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NearMissCategoryConfidenceMapEntry}
 */
proto.vivacity.core.NearMissEventV2.prototype.addCumulativeCategorisationConfidenceMapping = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.NearMissCategoryConfidenceMapEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NearMissEventV2} returns this
 */
proto.vivacity.core.NearMissEventV2.prototype.clearCumulativeCategorisationConfidenceMappingList = function() {
  return this.setCumulativeCategorisationConfidenceMappingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventStartTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventStartFrameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metricType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mostCriticalTrackHeadPair: (f = msg.getMostCriticalTrackHeadPair()) && proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.toObject(includeInstance, f),
    proportionPredictedDetections: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatus}
 */
proto.vivacity.core.NearMissStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatus;
  return proto.vivacity.core.NearMissStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatus}
 */
proto.vivacity.core.NearMissStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEventStartTimestampMicroseconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventStartFrameNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.NearMissStatus.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.NearMissStatus.NearMissMetric} */ (reader.readEnum());
      msg.setMetricType(value);
      break;
    case 4:
      var value = new proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair;
      reader.readMessage(value,proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.deserializeBinaryFromReader);
      msg.setMostCriticalTrackHeadPair(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProportionPredictedDetections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getEventStartFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMetricType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMostCriticalTrackHeadPair();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.serializeBinaryToWriter
    );
  }
  f = message.getProportionPredictedDetections();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.NearMissStatus.NearMissMetric = {
  UNKNOWN_NEAR_MISS_METRIC: 0,
  PROXIMAL: 1,
  PET: 2,
  TTC: 3
};

/**
 * @enum {number}
 */
proto.vivacity.core.NearMissStatus.EventType = {
  UNKNOWN_EVENT_TYPE: 0,
  CANDIDATE: 1,
  CANDIDACY_FAILED: 2,
  STARTED: 3,
  ONGOING: 4,
  ENDED: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackHead1: (f = msg.getTrackHead1()) && proto.vivacity.core.TrackHead.toObject(includeInstance, f),
    trackHead2: (f = msg.getTrackHead2()) && proto.vivacity.core.TrackHead.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    petValue: jspb.Message.getFieldWithDefault(msg, 4, 0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ttcValue: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair;
  return proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHead1(value);
      break;
    case 2:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHead2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPetValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTtcValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackHead1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrackHead2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPetValue();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTtcValue();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional TrackHead track_head1 = 1;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getTrackHead1 = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackHead, 1));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
*/
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setTrackHead1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.clearTrackHead1 = function() {
  return this.setTrackHead1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.hasTrackHead1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrackHead track_head2 = 2;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getTrackHead2 = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackHead, 2));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
*/
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setTrackHead2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.clearTrackHead2 = function() {
  return this.setTrackHead2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.hasTrackHead2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint64 pet_value = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getPetValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setPetValue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float distance = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint64 ttc_value = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.getTtcValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} returns this
 */
proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair.prototype.setTtcValue = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 event_start_timestamp_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.prototype.getEventStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.setEventStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_start_frame_number = 5;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.prototype.getEventStartFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.setEventStartFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional EventType event_type = 2;
 * @return {!proto.vivacity.core.NearMissStatus.EventType}
 */
proto.vivacity.core.NearMissStatus.prototype.getEventType = function() {
  return /** @type {!proto.vivacity.core.NearMissStatus.EventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatus.EventType} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional NearMissMetric metric_type = 3;
 * @return {!proto.vivacity.core.NearMissStatus.NearMissMetric}
 */
proto.vivacity.core.NearMissStatus.prototype.getMetricType = function() {
  return /** @type {!proto.vivacity.core.NearMissStatus.NearMissMetric} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatus.NearMissMetric} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional MostCriticalTrackHeadPair most_critical_track_head_pair = 4;
 * @return {?proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair}
 */
proto.vivacity.core.NearMissStatus.prototype.getMostCriticalTrackHeadPair = function() {
  return /** @type{?proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair, 4));
};


/**
 * @param {?proto.vivacity.core.NearMissStatus.MostCriticalTrackHeadPair|undefined} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
*/
proto.vivacity.core.NearMissStatus.prototype.setMostCriticalTrackHeadPair = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.clearMostCriticalTrackHeadPair = function() {
  return this.setMostCriticalTrackHeadPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissStatus.prototype.hasMostCriticalTrackHeadPair = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float proportion_predicted_detections = 6;
 * @return {number}
 */
proto.vivacity.core.NearMissStatus.prototype.getProportionPredictedDetections = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissStatus} returns this
 */
proto.vivacity.core.NearMissStatus.prototype.setProportionPredictedDetections = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NearMissEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NearMissEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NearMissEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trackNumber1: jspb.Message.getFieldWithDefault(msg, 3, 0),
    trackNumber2: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trackClass1: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trackClass2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mostCriticalTrackDetectionBox1: (f = msg.getMostCriticalTrackDetectionBox1()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    mostCriticalTrackDetectionBox2: (f = msg.getMostCriticalTrackDetectionBox2()) && vivacity_core_detection_box_pb.DetectionBox.toObject(includeInstance, f),
    mostCriticalTrackSpeedMetresPerSecond1: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    mostCriticalTrackSpeedMetresPerSecond2: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    mostCriticalTimestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
    startTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 12, 0),
    startFrameNumber: jspb.Message.getFieldWithDefault(msg, 19, 0),
    endTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 13, 0),
    endFrameNumber: jspb.Message.getFieldWithDefault(msg, 20, 0),
    eventScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    metricType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    mostCriticalPoint: (f = msg.getMostCriticalPoint()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    mostCriticalPetMicroseconds: jspb.Message.getFieldWithDefault(msg, 17, 0),
    mostCriticalDistanceMetres: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    mostCriticalTtcMicroseconds: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NearMissEvent}
 */
proto.vivacity.core.NearMissEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NearMissEvent;
  return proto.vivacity.core.NearMissEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NearMissEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NearMissEvent}
 */
proto.vivacity.core.NearMissEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber1(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber2(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass1(value);
      break;
    case 6:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass2(value);
      break;
    case 7:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setMostCriticalTrackDetectionBox1(value);
      break;
    case 8:
      var value = new vivacity_core_detection_box_pb.DetectionBox;
      reader.readMessage(value,vivacity_core_detection_box_pb.DetectionBox.deserializeBinaryFromReader);
      msg.setMostCriticalTrackDetectionBox2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalTrackSpeedMetresPerSecond1(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalTrackSpeedMetresPerSecond2(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalTimestamp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimestampMicroseconds(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartFrameNumber(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndTimestampMicroseconds(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndFrameNumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEventScore(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.core.NearMissStatus.NearMissMetric} */ (reader.readEnum());
      msg.setMetricType(value);
      break;
    case 16:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setMostCriticalPoint(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalPetMicroseconds(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMostCriticalDistanceMetres(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMostCriticalTtcMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NearMissEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NearMissEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NearMissEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NearMissEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTrackNumber1();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTrackNumber2();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTrackClass1();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTrackClass2();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMostCriticalTrackDetectionBox1();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalTrackDetectionBox2();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_detection_box_pb.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalTrackSpeedMetresPerSecond1();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getMostCriticalTrackSpeedMetresPerSecond2();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMostCriticalTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getStartFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getEndTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getEndFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getEventScore();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getMetricType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getMostCriticalPoint();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getMostCriticalPetMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getMostCriticalDistanceMetres();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getMostCriticalTtcMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 restart_number = 2;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 track_number_1 = 3;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getTrackNumber1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setTrackNumber1 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 track_number_2 = 4;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getTrackNumber2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setTrackNumber2 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ClassifyingDetectorClassTypes track_class_1 = 5;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.NearMissEvent.prototype.getTrackClass1 = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setTrackClass1 = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ClassifyingDetectorClassTypes track_class_2 = 6;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.NearMissEvent.prototype.getTrackClass2 = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setTrackClass2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional DetectionBox most_critical_track_detection_box_1 = 7;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTrackDetectionBox1 = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 7));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
*/
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTrackDetectionBox1 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.clearMostCriticalTrackDetectionBox1 = function() {
  return this.setMostCriticalTrackDetectionBox1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEvent.prototype.hasMostCriticalTrackDetectionBox1 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DetectionBox most_critical_track_detection_box_2 = 8;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTrackDetectionBox2 = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_box_pb.DetectionBox, 8));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
*/
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTrackDetectionBox2 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.clearMostCriticalTrackDetectionBox2 = function() {
  return this.setMostCriticalTrackDetectionBox2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEvent.prototype.hasMostCriticalTrackDetectionBox2 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float most_critical_track_speed_metres_per_second_1 = 9;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTrackSpeedMetresPerSecond1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTrackSpeedMetresPerSecond1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float most_critical_track_speed_metres_per_second_2 = 10;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTrackSpeedMetresPerSecond2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTrackSpeedMetresPerSecond2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional uint64 most_critical_timestamp = 11;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 start_timestamp_microseconds = 12;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 start_frame_number = 19;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getStartFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setStartFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 end_timestamp_microseconds = 13;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getEndTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setEndTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 end_frame_number = 20;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getEndFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setEndFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional float event_score = 14;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getEventScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setEventScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional NearMissStatus.NearMissMetric metric_type = 15;
 * @return {!proto.vivacity.core.NearMissStatus.NearMissMetric}
 */
proto.vivacity.core.NearMissEvent.prototype.getMetricType = function() {
  return /** @type {!proto.vivacity.core.NearMissStatus.NearMissMetric} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.core.NearMissStatus.NearMissMetric} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional Point most_critical_point = 16;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalPoint = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 16));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
*/
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalPoint = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.clearMostCriticalPoint = function() {
  return this.setMostCriticalPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NearMissEvent.prototype.hasMostCriticalPoint = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional uint64 most_critical_pet_microseconds = 17;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalPetMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalPetMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional float most_critical_distance_metres = 18;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalDistanceMetres = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalDistanceMetres = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional uint64 most_critical_ttc_microseconds = 21;
 * @return {number}
 */
proto.vivacity.core.NearMissEvent.prototype.getMostCriticalTtcMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NearMissEvent} returns this
 */
proto.vivacity.core.NearMissEvent.prototype.setMostCriticalTtcMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * @enum {number}
 */
proto.vivacity.core.NearMissEventCategory = {
  UNKNOWN_NEAR_MISS_CATEGORY: 0,
  CYCLIST_WAY_BLOCKED: 1,
  CYCLIST_PROBLEMATIC_PASS: 2,
  CYCLIST_VEHICLE_PULLING_OUT: 3,
  CYCLIST_DRIVEN_AT: 4,
  CYCLIST_NEAR_LEFT_RIGHT_HOOK: 5,
  CYCLIST_CYCLIST_TAILGATING: 6,
  CYCLIST_VEHICLE_TAILGATING: 7,
  CYCLIST_PROBLEMATIC_TURN: 8
};

/**
 * @enum {number}
 */
proto.vivacity.core.NearMissPair = {
  UNKNOWN_NEAR_MISS_PAIR: 0,
  CYCLIST_VEHICLE: 1,
  PEDESTRIAN_VEHICLE: 2,
  VEHICLE_VEHICLE: 3,
  PEDESTRIAN_CYCLIST: 4
};

goog.object.extend(exports, proto.vivacity.core);
