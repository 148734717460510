import Line from "../PlayerDrawing/Line";
import * as PIXI from "pixi.js";
import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { StoresContext } from "../../../contexts/stores.context";

type TimeScrubberProps = {
  height: number;
  pointerDown: (e: PIXI.InteractionEvent) => void;
  pointerUp: (e: PIXI.InteractionEvent) => void;
  pointerMove: (e: PIXI.InteractionEvent) => void;
  storesContext: StoresContext;
};

export const TimeScrubber: React.FunctionComponent<PropsWithChildren<TimeScrubberProps>> = observer(props => {
  const { playerUIStore } = props.storesContext;
  return (
    <Line
      hitArea={new PIXI.Rectangle(playerUIStore.timelineLinePosX - 10, 0, 20, props.height - 20)}
      lineWidth={2}
      lineColor={0x000044}
      x1={playerUIStore.timelineLinePosX}
      y1={0}
      x2={playerUIStore.timelineLinePosX}
      y2={props.height - 20}
      cursor={"ew-resize"}
      interactive={true}
      pointerdown={props.pointerDown}
      pointerup={props.pointerUp}
      pointermove={props.pointerMove}
    />
  );
});

type ZoomedTimeScrubberProps = {
  height: number;
  storesContext: StoresContext;
};

export const ZoomedTimeScrubber: React.FunctionComponent<PropsWithChildren<ZoomedTimeScrubberProps>> = observer(
  props => {
    const { playerUIStore } = props.storesContext;

    return (
      <Line
        lineWidth={2}
        lineColor={0x000044}
        x1={playerUIStore.timelineZoomedTimeCursorX}
        y1={40}
        cursor={"crosshair"}
        interactive={false}
        x2={playerUIStore.timelineZoomedTimeCursorX}
        y2={props.height}
      />
    );
  }
);
