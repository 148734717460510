import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";

import { FreneticityButton } from "../styledElements";
import { TransitionProps } from "@mui/material/transitions";

interface SaveConfirmationModalProps {
  isOpen: boolean;
  onClose: (confirmed: boolean) => void;
  proposedValidationResult?: boolean;
}

export const SaveConfirmationModal: FC<SaveConfirmationModalProps> = observer(
  ({ isOpen, onClose, proposedValidationResult }) => {
    return (
      <Dialog open={isOpen} onClose={() => onClose(false)} maxWidth="sm" fullWidth TransitionComponent={Transition}>
        <DialogTitle>Save Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to save the Validation Run as <b>{proposedValidationResult ? "PASSED" : "FAILED"}</b>?
          <br />
          <br />
          This operation is final and cannot be undone.
        </DialogContent>
        <DialogActions>
          <FreneticityButton onClick={() => onClose(false)}>Cancel</FreneticityButton>
          <FreneticityButton color="success" onClick={() => onClose(true)}>
            Confirm
          </FreneticityButton>
        </DialogActions>
      </Dialog>
    );
  }
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
