/* eslint-disable */
// source: vivacity/core/gpu_histogram.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.core.GpuHistogram', null, global);
goog.exportSymbol('proto.vivacity.core.GpuHistogram.ColourFormat', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GpuHistogram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.GpuHistogram.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.GpuHistogram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GpuHistogram.displayName = 'proto.vivacity.core.GpuHistogram';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.GpuHistogram.repeatedFields_ = [5,10,15,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GpuHistogram.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GpuHistogram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GpuHistogram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GpuHistogram.toObject = function(includeInstance, msg) {
  var f, obj = {
    colourFormat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    horizontalSubdivIdx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    verticalSubdivIdx: jspb.Message.getFieldWithDefault(msg, 3, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    roiTopLeftX: jspb.Message.getFieldWithDefault(msg, 25, 0),
    roiTopLeftY: jspb.Message.getFieldWithDefault(msg, 26, 0),
    roiWidth: jspb.Message.getFieldWithDefault(msg, 27, 0),
    roiHeight: jspb.Message.getFieldWithDefault(msg, 28, 0),
    channel0List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    channel0MinBin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    channel0MaxBin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    channel0PeakIndex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    channel0PeakValue: jspb.Message.getFieldWithDefault(msg, 9, 0),
    channel1List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    channel1MinBin: jspb.Message.getFieldWithDefault(msg, 11, 0),
    channel1MaxBin: jspb.Message.getFieldWithDefault(msg, 12, 0),
    channel1PeakIndex: jspb.Message.getFieldWithDefault(msg, 13, 0),
    channel1PeakValue: jspb.Message.getFieldWithDefault(msg, 14, 0),
    channel2List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f,
    channel2MinBin: jspb.Message.getFieldWithDefault(msg, 16, 0),
    channel2MaxBin: jspb.Message.getFieldWithDefault(msg, 17, 0),
    channel2PeakIndex: jspb.Message.getFieldWithDefault(msg, 18, 0),
    channel2PeakValue: jspb.Message.getFieldWithDefault(msg, 19, 0),
    channel3List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 20)) == null ? undefined : f,
    channel3MinBin: jspb.Message.getFieldWithDefault(msg, 21, 0),
    channel3MaxBin: jspb.Message.getFieldWithDefault(msg, 22, 0),
    channel3PeakIndex: jspb.Message.getFieldWithDefault(msg, 23, 0),
    channel3PeakValue: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GpuHistogram}
 */
proto.vivacity.core.GpuHistogram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GpuHistogram;
  return proto.vivacity.core.GpuHistogram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GpuHistogram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GpuHistogram}
 */
proto.vivacity.core.GpuHistogram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.GpuHistogram.ColourFormat} */ (reader.readEnum());
      msg.setColourFormat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHorizontalSubdivIdx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVerticalSubdivIdx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoiTopLeftX(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoiTopLeftY(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoiWidth(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoiHeight(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannel0(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel0MinBin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel0MaxBin(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel0PeakIndex(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel0PeakValue(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannel1(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel1MinBin(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel1MaxBin(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel1PeakIndex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel1PeakValue(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannel2(values[i]);
      }
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel2MinBin(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel2MaxBin(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel2PeakIndex(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel2PeakValue(value);
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannel3(values[i]);
      }
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel3MinBin(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel3MaxBin(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel3PeakIndex(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel3PeakValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GpuHistogram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GpuHistogram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GpuHistogram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GpuHistogram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColourFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHorizontalSubdivIdx();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getVerticalSubdivIdx();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRoiTopLeftX();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getRoiTopLeftY();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getRoiWidth();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getRoiHeight();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getChannel0List();
  if (f.length > 0) {
    writer.writePackedFloat(
      5,
      f
    );
  }
  f = message.getChannel0MinBin();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getChannel0MaxBin();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getChannel0PeakIndex();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getChannel0PeakValue();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getChannel1List();
  if (f.length > 0) {
    writer.writePackedFloat(
      10,
      f
    );
  }
  f = message.getChannel1MinBin();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getChannel1MaxBin();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getChannel1PeakIndex();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getChannel1PeakValue();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getChannel2List();
  if (f.length > 0) {
    writer.writePackedFloat(
      15,
      f
    );
  }
  f = message.getChannel2MinBin();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getChannel2MaxBin();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getChannel2PeakIndex();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getChannel2PeakValue();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getChannel3List();
  if (f.length > 0) {
    writer.writePackedFloat(
      20,
      f
    );
  }
  f = message.getChannel3MinBin();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getChannel3MaxBin();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getChannel3PeakIndex();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getChannel3PeakValue();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GpuHistogram.ColourFormat = {
  UNKNOWN_COLOUR_FORMAT: 0,
  NV12: 1,
  BGR: 2,
  BGRA: 3,
  RGB: 4,
  RGBA: 5
};

/**
 * optional ColourFormat colour_format = 1;
 * @return {!proto.vivacity.core.GpuHistogram.ColourFormat}
 */
proto.vivacity.core.GpuHistogram.prototype.getColourFormat = function() {
  return /** @type {!proto.vivacity.core.GpuHistogram.ColourFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.GpuHistogram.ColourFormat} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setColourFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 horizontal_subdiv_idx = 2;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getHorizontalSubdivIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setHorizontalSubdivIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 vertical_subdiv_idx = 3;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getVerticalSubdivIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setVerticalSubdivIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 zone_id = 4;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 roi_top_left_x = 25;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getRoiTopLeftX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setRoiTopLeftX = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint32 roi_top_left_y = 26;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getRoiTopLeftY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setRoiTopLeftY = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint32 roi_width = 27;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getRoiWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setRoiWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional uint32 roi_height = 28;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getRoiHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setRoiHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * repeated float channel_0 = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel0List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel0List = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.addChannel0 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.clearChannel0List = function() {
  return this.setChannel0List([]);
};


/**
 * optional uint32 channel_0_min_bin = 6;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel0MinBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel0MinBin = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 channel_0_max_bin = 7;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel0MaxBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel0MaxBin = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 channel_0_peak_index = 8;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel0PeakIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel0PeakIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 channel_0_peak_value = 9;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel0PeakValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel0PeakValue = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated float channel_1 = 10;
 * @return {!Array<number>}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel1List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel1List = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.addChannel1 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.clearChannel1List = function() {
  return this.setChannel1List([]);
};


/**
 * optional uint32 channel_1_min_bin = 11;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel1MinBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel1MinBin = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 channel_1_max_bin = 12;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel1MaxBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel1MaxBin = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 channel_1_peak_index = 13;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel1PeakIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel1PeakIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 channel_1_peak_value = 14;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel1PeakValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel1PeakValue = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated float channel_2 = 15;
 * @return {!Array<number>}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel2List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel2List = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.addChannel2 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.clearChannel2List = function() {
  return this.setChannel2List([]);
};


/**
 * optional uint32 channel_2_min_bin = 16;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel2MinBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel2MinBin = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 channel_2_max_bin = 17;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel2MaxBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel2MaxBin = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 channel_2_peak_index = 18;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel2PeakIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel2PeakIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint32 channel_2_peak_value = 19;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel2PeakValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel2PeakValue = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * repeated float channel_3 = 20;
 * @return {!Array<number>}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel3List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel3List = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.addChannel3 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.clearChannel3List = function() {
  return this.setChannel3List([]);
};


/**
 * optional uint32 channel_3_min_bin = 21;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel3MinBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel3MinBin = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint32 channel_3_max_bin = 22;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel3MaxBin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel3MaxBin = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint32 channel_3_peak_index = 23;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel3PeakIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel3PeakIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint32 channel_3_peak_value = 24;
 * @return {number}
 */
proto.vivacity.core.GpuHistogram.prototype.getChannel3PeakValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GpuHistogram} returns this
 */
proto.vivacity.core.GpuHistogram.prototype.setChannel3PeakValue = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


goog.object.extend(exports, proto.vivacity.core);
