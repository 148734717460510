import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { FC, Fragment } from "react";
import { FreneticityButton } from "../styledElements";
import { useStores } from "../../hooks/useStores.hook";
import { observer } from "mobx-react";
import MonacoEditor from "react-monaco-editor/lib/editor";
import { CVRunID } from "../../domain";

interface CVRunErrorsModalsProps {
  isOpen: boolean;
  onClose: () => void;
  currentCVRun: CVRunID;
}

export const CVRunErrorsModal: FC<CVRunErrorsModalsProps> = observer(({ isOpen, onClose, currentCVRun }) => {
  const { cvRunStore } = useStores();

  const cvRun = cvRunStore.computerVisionRuns.get(currentCVRun);
  if (!cvRun) {
    return null;
  }

  return (
    <Dialog open={isOpen && currentCVRun !== undefined} maxWidth={"xl"} fullWidth={true} onClose={onClose}>
      <DialogTitle>
        <h4>CV Run {currentCVRun} Errors</h4>
      </DialogTitle>
      <DialogContent>
        <Fragment>
          <Typography>
            Requested by <b>{cvRun.createdBy}</b> ({new Date(Date.parse(cvRun.createdAt ?? "")).toLocaleString()})
          </Typography>
          <Typography>
            Executed on <b>{cvRun.processorID}</b>
          </Typography>
          <MonacoEditor
            width="100%"
            height={600}
            language="text"
            options={{ wordWrap: "on" }}
            theme="vs-dark"
            value={cvRun.lastReportedError}
          />
          {cvRun.lastRunStdout ? (
            <Fragment>
              <Typography>Stdout</Typography>
              <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStdout} />
            </Fragment>
          ) : null}
          {cvRun.lastRunStderr ? (
            <Fragment>
              <Typography>Stderr</Typography>
              <MonacoEditor width="100%" height={600} language="yaml" theme="vs-dark" value={cvRun.lastRunStderr} />
            </Fragment>
          ) : null}
        </Fragment>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Close</FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});
