import React, { PropsWithChildren, RefObject, useMemo } from "react";
import { Container, Sprite } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import ReactPlayer from "react-player";
import { PlayerUIStore } from "../../../../stores/playerUI.store";

type ContainerProps = {
  videoRef?: RefObject<ReactPlayer>;
  playerUIStore: PlayerUIStore;
  height: number;
  width: number;
  blur?: number;
};

const ContainerWithBackground: React.FunctionComponent<PropsWithChildren<ContainerProps>> = (
  props: PropsWithChildren<ContainerProps>
) => {
  const maybeVideo = props.videoRef?.current?.getInternalPlayer();
  const maybeSprite = useMemo(() => {
    if (maybeVideo) {
      const filters: PIXI.Filter[] = [];
      if (props.blur) {
        filters.push(new PIXI.filters.BlurFilter(props.blur));
      }
      const texture = PIXI.Texture.from(maybeVideo as HTMLVideoElement, { scaleMode: PIXI.SCALE_MODES.LINEAR }, false);
      props.playerUIStore.setPixiVideoTexture(texture);
      return (
        <Sprite
          texture={texture}
          filters={filters}
          width={props.width}
          height={props.height}
          interactive={true}
          interactiveChildren={true}
        />
      );
    } else {
      return null;
    }
  }, [maybeVideo, props.blur, props.height, props.width]);

  return (
    <Container interactive={true} interactiveChildren={true}>
      {maybeSprite}
      {props.children}
    </Container>
  );
};

export default ContainerWithBackground;
