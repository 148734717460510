/* eslint-disable */
// source: vivacity/core/zonal_windowed_features.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_detection_quality_pb = require('../../vivacity/core/detection_quality_pb.js');
goog.object.extend(proto, vivacity_core_detection_quality_pb);
goog.exportSymbol('proto.vivacity.core.ZonalWindowedClassFeatures', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalWindowedFeatures', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalWindowedClassFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ZonalWindowedClassFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalWindowedClassFeatures.displayName = 'proto.vivacity.core.ZonalWindowedClassFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalWindowedFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.ZonalWindowedFeatures.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.ZonalWindowedFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalWindowedFeatures.displayName = 'proto.vivacity.core.ZonalWindowedFeatures';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalWindowedClassFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedClassFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    classId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meanNumberObjects: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    meanNumberStoppedObjects: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    meanRealWorldSpaceSpeedMetersPerSecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    meanImageSpaceSpeedPointsPerSecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    meanCumulativeStoppedSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxNumberObjects: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalWindowedClassFeatures;
  return proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanNumberObjects(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanNumberStoppedObjects(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanRealWorldSpaceSpeedMetersPerSecond(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanImageSpaceSpeedPointsPerSecond(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanCumulativeStoppedSeconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxNumberObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMeanNumberObjects();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMeanNumberStoppedObjects();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMeanRealWorldSpaceSpeedMetersPerSecond();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMeanImageSpaceSpeedPointsPerSecond();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMeanCumulativeStoppedSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxNumberObjects();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class_id = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getClassId = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setClassId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float mean_number_objects = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMeanNumberObjects = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMeanNumberObjects = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float mean_number_stopped_objects = 3;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMeanNumberStoppedObjects = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMeanNumberStoppedObjects = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float mean_real_world_space_speed_meters_per_second = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMeanRealWorldSpaceSpeedMetersPerSecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMeanRealWorldSpaceSpeedMetersPerSecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float mean_image_space_speed_points_per_second = 5;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMeanImageSpaceSpeedPointsPerSecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMeanImageSpaceSpeedPointsPerSecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float mean_cumulative_stopped_seconds = 6;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMeanCumulativeStoppedSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMeanCumulativeStoppedSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional uint32 max_number_objects = 7;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.getMaxNumberObjects = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedClassFeatures.prototype.setMaxNumberObjects = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.ZonalWindowedFeatures.repeatedFields_ = [5,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalWindowedFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalWindowedFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    windowStartTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    windowLengthMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classFeaturesList: jspb.Message.toObjectList(msg.getClassFeaturesList(),
    proto.vivacity.core.ZonalWindowedClassFeatures.toObject, includeInstance),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    directionalClassFeaturesList: jspb.Message.toObjectList(msg.getDirectionalClassFeaturesList(),
    proto.vivacity.core.ZonalWindowedClassFeatures.toObject, includeInstance),
    contraDirectionalClassFeaturesList: jspb.Message.toObjectList(msg.getContraDirectionalClassFeaturesList(),
    proto.vivacity.core.ZonalWindowedClassFeatures.toObject, includeInstance),
    aggregatedFeatures: (f = msg.getAggregatedFeatures()) && proto.vivacity.core.ZonalWindowedClassFeatures.toObject(includeInstance, f),
    aggregatedDirectionalFeatures: (f = msg.getAggregatedDirectionalFeatures()) && proto.vivacity.core.ZonalWindowedClassFeatures.toObject(includeInstance, f),
    aggregatedContraDirectionalFeatures: (f = msg.getAggregatedContraDirectionalFeatures()) && proto.vivacity.core.ZonalWindowedClassFeatures.toObject(includeInstance, f),
    aggregatedCrossingsClockwise: jspb.Message.getFieldWithDefault(msg, 12, 0),
    aggregatedCrossingsAnticlockwise: jspb.Message.getFieldWithDefault(msg, 13, 0),
    detectionQuality: (f = msg.getDetectionQuality()) && vivacity_core_detection_quality_pb.DetectionQuality.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalWindowedFeatures;
  return proto.vivacity.core.ZonalWindowedFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalWindowedFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWindowStartTimestampMicroseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowLengthMicroseconds(value);
      break;
    case 5:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.addClassFeatures(value);
      break;
    case 6:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 7:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.addDirectionalClassFeatures(value);
      break;
    case 8:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.addContraDirectionalClassFeatures(value);
      break;
    case 9:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.setAggregatedFeatures(value);
      break;
    case 10:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.setAggregatedDirectionalFeatures(value);
      break;
    case 11:
      var value = new proto.vivacity.core.ZonalWindowedClassFeatures;
      reader.readMessage(value,proto.vivacity.core.ZonalWindowedClassFeatures.deserializeBinaryFromReader);
      msg.setAggregatedContraDirectionalFeatures(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAggregatedCrossingsClockwise(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAggregatedCrossingsAnticlockwise(value);
      break;
    case 14:
      var value = new vivacity_core_detection_quality_pb.DetectionQuality;
      reader.readMessage(value,vivacity_core_detection_quality_pb.DetectionQuality.deserializeBinaryFromReader);
      msg.setDetectionQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalWindowedFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalWindowedFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWindowStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getWindowLengthMicroseconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getClassFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getDirectionalClassFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getContraDirectionalClassFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getAggregatedFeatures();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getAggregatedDirectionalFeatures();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getAggregatedContraDirectionalFeatures();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vivacity.core.ZonalWindowedClassFeatures.serializeBinaryToWriter
    );
  }
  f = message.getAggregatedCrossingsClockwise();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getAggregatedCrossingsAnticlockwise();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getDetectionQuality();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_core_detection_quality_pb.DetectionQuality.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 zone_id = 1;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 window_start_timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getWindowStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setWindowStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 window_length_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getWindowLengthMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setWindowLengthMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ZonalWindowedClassFeatures class_features = 5;
 * @return {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getClassFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setClassFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.addClassFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.ZonalWindowedClassFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearClassFeaturesList = function() {
  return this.setClassFeaturesList([]);
};


/**
 * optional TracingContext tracing_ctx = 6;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 6));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ZonalWindowedClassFeatures directional_class_features = 7;
 * @return {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getDirectionalClassFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setDirectionalClassFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.addDirectionalClassFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.ZonalWindowedClassFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearDirectionalClassFeaturesList = function() {
  return this.setDirectionalClassFeaturesList([]);
};


/**
 * repeated ZonalWindowedClassFeatures contra_directional_class_features = 8;
 * @return {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getContraDirectionalClassFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 8));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalWindowedClassFeatures>} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setContraDirectionalClassFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vivacity.core.ZonalWindowedClassFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.addContraDirectionalClassFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vivacity.core.ZonalWindowedClassFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearContraDirectionalClassFeaturesList = function() {
  return this.setContraDirectionalClassFeaturesList([]);
};


/**
 * optional ZonalWindowedClassFeatures aggregated_features = 9;
 * @return {?proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getAggregatedFeatures = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedClassFeatures} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 9));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedClassFeatures|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setAggregatedFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearAggregatedFeatures = function() {
  return this.setAggregatedFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.hasAggregatedFeatures = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ZonalWindowedClassFeatures aggregated_directional_features = 10;
 * @return {?proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getAggregatedDirectionalFeatures = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedClassFeatures} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 10));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedClassFeatures|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setAggregatedDirectionalFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearAggregatedDirectionalFeatures = function() {
  return this.setAggregatedDirectionalFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.hasAggregatedDirectionalFeatures = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ZonalWindowedClassFeatures aggregated_contra_directional_features = 11;
 * @return {?proto.vivacity.core.ZonalWindowedClassFeatures}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getAggregatedContraDirectionalFeatures = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedClassFeatures} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalWindowedClassFeatures, 11));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedClassFeatures|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setAggregatedContraDirectionalFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearAggregatedContraDirectionalFeatures = function() {
  return this.setAggregatedContraDirectionalFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.hasAggregatedContraDirectionalFeatures = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional uint32 aggregated_crossings_clockwise = 12;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getAggregatedCrossingsClockwise = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setAggregatedCrossingsClockwise = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 aggregated_crossings_anticlockwise = 13;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getAggregatedCrossingsAnticlockwise = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.setAggregatedCrossingsAnticlockwise = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional DetectionQuality detection_quality = 14;
 * @return {?proto.vivacity.core.DetectionQuality}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.getDetectionQuality = function() {
  return /** @type{?proto.vivacity.core.DetectionQuality} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_quality_pb.DetectionQuality, 14));
};


/**
 * @param {?proto.vivacity.core.DetectionQuality|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
*/
proto.vivacity.core.ZonalWindowedFeatures.prototype.setDetectionQuality = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedFeatures} returns this
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.clearDetectionQuality = function() {
  return this.setDetectionQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedFeatures.prototype.hasDetectionQuality = function() {
  return jspb.Message.getField(this, 14) != null;
};


goog.object.extend(exports, proto.vivacity.core);
