/* eslint-disable */
// source: vivacity/core/controller_state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.core.Control', null, global);
goog.exportSymbol('proto.vivacity.core.Control.UsingMapsOrBitMasks', null, global);
goog.exportSymbol('proto.vivacity.core.ControllerState', null, global);
goog.exportSymbol('proto.vivacity.core.ControllerState.MessageVersion', null, global);
goog.exportSymbol('proto.vivacity.core.Reply', null, global);
goog.exportSymbol('proto.vivacity.core.Reply.UsingMapsOrBitMasks', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Control = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Control, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Control.displayName = 'proto.vivacity.core.Control';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Reply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Reply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Reply.displayName = 'proto.vivacity.core.Reply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ControllerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ControllerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ControllerState.displayName = 'proto.vivacity.core.ControllerState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Control.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Control.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Control} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Control.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeStamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dx: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    dn: msg.getDn_asB64(),
    fn: msg.getFn_asB64(),
    sfn: msg.getSfn_asB64(),
    pv: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    px: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    so: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    sg: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    lo: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    ll: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    ts: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    fm: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    to: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    hi: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    cp: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    ep: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    go: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    ff: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    mo: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    usingMapsOrBitmasks: jspb.Message.getFieldWithDefault(msg, 30, 0),
    dnAsMapMap: (f = msg.getDnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    fnAsMapMap: (f = msg.getFnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    sfAsMapMap: (f = msg.getSfAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    specialBitsMap: (f = msg.getSpecialBitsMap()) ? f.toObject(includeInstance, undefined) : [],
    controllerId: jspb.Message.getFieldWithDefault(msg, 100, 0),
    streamIndex: jspb.Message.getFieldWithDefault(msg, 101, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Control}
 */
proto.vivacity.core.Control.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Control;
  return proto.vivacity.core.Control.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Control} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Control}
 */
proto.vivacity.core.Control.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeStamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDx(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDn(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFn(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSfn(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPv(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPx(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSo(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSg(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLo(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTs(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFm(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTo(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHi(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCp(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEp(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGo(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFf(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMo(value);
      break;
    case 30:
      var value = /** @type {!proto.vivacity.core.Control.UsingMapsOrBitMasks} */ (reader.readEnum());
      msg.setUsingMapsOrBitmasks(value);
      break;
    case 31:
      var value = msg.getDnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 32:
      var value = msg.getFnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 33:
      var value = msg.getSfAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 50:
      var value = msg.getSpecialBitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStreamIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Control.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Control.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Control} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Control.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeStamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDx();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getFn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getSfn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getPv();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPx();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSo();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSg();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLo();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getLl();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getTs();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getFm();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getTo();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHi();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCp();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getEp();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getGo();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getFf();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getMo();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUsingMapsOrBitmasks();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getDnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(31, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getFnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(32, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getSfAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(33, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getSpecialBitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(50, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      100,
      f
    );
  }
  f = message.getStreamIndex();
  if (f !== 0) {
    writer.writeUint32(
      101,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.Control.UsingMapsOrBitMasks = {
  UNKNOWN: 0,
  MAPS: 1,
  BITMASKS: 2
};

/**
 * optional uint64 time_stamp = 1;
 * @return {number}
 */
proto.vivacity.core.Control.prototype.getTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vivacity.core.Control.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool DX = 3;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getDx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setDx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bytes Dn = 4;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Control.prototype.getDn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes Dn = 4;
 * This is a type-conversion wrapper around `getDn()`
 * @return {string}
 */
proto.vivacity.core.Control.prototype.getDn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDn()));
};


/**
 * optional bytes Dn = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Control.prototype.getDn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setDn = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes Fn = 5;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Control.prototype.getFn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes Fn = 5;
 * This is a type-conversion wrapper around `getFn()`
 * @return {string}
 */
proto.vivacity.core.Control.prototype.getFn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFn()));
};


/**
 * optional bytes Fn = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Control.prototype.getFn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setFn = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional bytes SFn = 6;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Control.prototype.getSfn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes SFn = 6;
 * This is a type-conversion wrapper around `getSfn()`
 * @return {string}
 */
proto.vivacity.core.Control.prototype.getSfn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSfn()));
};


/**
 * optional bytes SFn = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSfn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Control.prototype.getSfn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSfn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setSfn = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional bool PV = 7;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getPv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setPv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool PX = 8;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getPx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setPx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool SO = 9;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getSo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setSo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool SG = 10;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getSg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setSg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool LO = 11;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getLo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setLo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool LL = 12;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getLl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setLl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool TS = 13;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getTs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setTs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool FM = 14;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getFm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setFm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool TO = 15;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getTo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setTo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool HI = 16;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getHi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setHi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool CP = 17;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getCp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setCp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool EP = 18;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getEp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setEp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool GO = 19;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getGo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setGo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool FF = 20;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getFf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setFf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool MO = 21;
 * @return {boolean}
 */
proto.vivacity.core.Control.prototype.getMo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setMo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional UsingMapsOrBitMasks using_maps_or_bitmasks = 30;
 * @return {!proto.vivacity.core.Control.UsingMapsOrBitMasks}
 */
proto.vivacity.core.Control.prototype.getUsingMapsOrBitmasks = function() {
  return /** @type {!proto.vivacity.core.Control.UsingMapsOrBitMasks} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.vivacity.core.Control.UsingMapsOrBitMasks} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setUsingMapsOrBitmasks = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * map<uint32, bool> Dn_as_map = 31;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Control.prototype.getDnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 31, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.clearDnAsMapMap = function() {
  this.getDnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> Fn_as_map = 32;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Control.prototype.getFnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 32, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.clearFnAsMapMap = function() {
  this.getFnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> SF_as_map = 33;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Control.prototype.getSfAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 33, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.clearSfAsMapMap = function() {
  this.getSfAsMapMap().clear();
  return this;
};


/**
 * map<string, bool> special_bits = 50;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.vivacity.core.Control.prototype.getSpecialBitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 50, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.clearSpecialBitsMap = function() {
  this.getSpecialBitsMap().clear();
  return this;
};


/**
 * optional uint32 controller_id = 100;
 * @return {number}
 */
proto.vivacity.core.Control.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional uint32 stream_index = 101;
 * @return {number}
 */
proto.vivacity.core.Control.prototype.getStreamIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Control} returns this
 */
proto.vivacity.core.Control.prototype.setStreamIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Reply.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Reply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Reply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Reply.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeStamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gn: msg.getGn_asB64(),
    gx: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    df: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fc: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    scn: msg.getScn_asB64(),
    hc: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    wi: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    pc: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    pr: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    cg: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    gr1: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    sdn: msg.getSdn_asB64(),
    mc: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    cf: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    le: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    rr: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    lfn: msg.getLfn_asB64(),
    rf1: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    rf2: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    ev: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    vc: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    vo: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    gpn: msg.getGpn_asB64(),
    vq: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    ca: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    cr: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    cl: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    csn: msg.getCsn_asB64(),
    tf: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    vsn: msg.getVsn_asB64(),
    co: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    ec: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    cs: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    fr: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    bdn: msg.getBdn_asB64(),
    tpn: msg.getTpn_asB64(),
    sb: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    lc: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    mr: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    mf: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    ml: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    usingMapsOrBitmasks: jspb.Message.getFieldWithDefault(msg, 50, 0),
    gnAsMapMap: (f = msg.getGnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    scnAsMapMap: (f = msg.getScnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    sdnAsMapMap: (f = msg.getSdnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    lfnAsMapMap: (f = msg.getLfnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    gpnAsMapMap: (f = msg.getGpnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    csnAsMapMap: (f = msg.getCsnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    vsnAsMapMap: (f = msg.getVsnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    bdnAsMapMap: (f = msg.getBdnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    tpnAsMapMap: (f = msg.getTpnAsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    specialBitsMap: (f = msg.getSpecialBitsMap()) ? f.toObject(includeInstance, undefined) : [],
    controllerId: jspb.Message.getFieldWithDefault(msg, 100, 0),
    streamIndex: jspb.Message.getFieldWithDefault(msg, 101, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Reply}
 */
proto.vivacity.core.Reply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Reply;
  return proto.vivacity.core.Reply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Reply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Reply}
 */
proto.vivacity.core.Reply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeStamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGx(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDf(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFc(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScn(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHc(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWi(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPc(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPr(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCg(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGr1(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdn(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMc(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCf(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLe(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRr(value);
      break;
    case 19:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLfn(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRf1(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRf2(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEv(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVc(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVo(value);
      break;
    case 25:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGpn(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVq(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCa(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCr(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCl(value);
      break;
    case 30:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCsn(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTf(value);
      break;
    case 32:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVsn(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCo(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEc(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCs(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFr(value);
      break;
    case 37:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBdn(value);
      break;
    case 38:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTpn(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSb(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLc(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMr(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMf(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMl(value);
      break;
    case 50:
      var value = /** @type {!proto.vivacity.core.Reply.UsingMapsOrBitMasks} */ (reader.readEnum());
      msg.setUsingMapsOrBitmasks(value);
      break;
    case 51:
      var value = msg.getGnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 52:
      var value = msg.getScnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 53:
      var value = msg.getSdnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 54:
      var value = msg.getLfnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 55:
      var value = msg.getGpnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 56:
      var value = msg.getCsnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 57:
      var value = msg.getVsnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 58:
      var value = msg.getBdnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 59:
      var value = msg.getTpnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    case 80:
      var value = msg.getSpecialBitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStreamIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Reply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Reply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Reply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeStamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getGx();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDf();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFc();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getScn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getHc();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getWi();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPc();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPr();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCg();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getGr1();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSdn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getMc();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCf();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getLe();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getRr();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getLfn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      19,
      f
    );
  }
  f = message.getRf1();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getRf2();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getEv();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getVc();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getVo();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getGpn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      25,
      f
    );
  }
  f = message.getVq();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getCa();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getCr();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getCl();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getCsn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      30,
      f
    );
  }
  f = message.getTf();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getVsn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      32,
      f
    );
  }
  f = message.getCo();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getEc();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCs();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getFr();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getBdn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      37,
      f
    );
  }
  f = message.getTpn_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      38,
      f
    );
  }
  f = message.getSb();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getLc();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getMr();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getMf();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getMl();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getUsingMapsOrBitmasks();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getGnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(51, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getScnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(52, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getSdnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(53, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getLfnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(54, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getGpnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(55, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getCsnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(56, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getVsnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(57, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getBdnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(58, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getTpnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(59, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getSpecialBitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(80, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      100,
      f
    );
  }
  f = message.getStreamIndex();
  if (f !== 0) {
    writer.writeUint32(
      101,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.Reply.UsingMapsOrBitMasks = {
  UNKNOWN: 0,
  MAPS: 1,
  BITMASKS: 2
};

/**
 * optional uint64 time_stamp = 1;
 * @return {number}
 */
proto.vivacity.core.Reply.prototype.getTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes Gn = 3;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getGn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes Gn = 3;
 * This is a type-conversion wrapper around `getGn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getGn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGn()));
};


/**
 * optional bytes Gn = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getGn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setGn = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bool GX = 4;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getGx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setGx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool DF = 5;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getDf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setDf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool FC = 6;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getFc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setFc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bytes SCn = 7;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getScn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes SCn = 7;
 * This is a type-conversion wrapper around `getScn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getScn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScn()));
};


/**
 * optional bytes SCn = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getScn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setScn = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional bool HC = 8;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getHc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setHc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool WI = 9;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getWi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setWi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool PC = 10;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getPc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setPc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool PR = 11;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getPr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setPr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool CG = 12;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool GR1 = 13;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getGr1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setGr1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bytes SDn = 14;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getSdn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes SDn = 14;
 * This is a type-conversion wrapper around `getSdn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getSdn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdn()));
};


/**
 * optional bytes SDn = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getSdn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setSdn = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional bool MC = 15;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getMc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setMc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool CF = 16;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool LE = 17;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getLe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setLe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool RR = 18;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getRr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setRr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bytes LFn = 19;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getLfn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * optional bytes LFn = 19;
 * This is a type-conversion wrapper around `getLfn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getLfn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLfn()));
};


/**
 * optional bytes LFn = 19;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLfn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getLfn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLfn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setLfn = function(value) {
  return jspb.Message.setProto3BytesField(this, 19, value);
};


/**
 * optional bool RF1 = 20;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getRf1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setRf1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool RF2 = 21;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getRf2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setRf2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool EV = 22;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getEv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setEv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool VC = 23;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getVc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setVc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool VO = 24;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getVo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setVo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bytes GPn = 25;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getGpn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * optional bytes GPn = 25;
 * This is a type-conversion wrapper around `getGpn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getGpn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGpn()));
};


/**
 * optional bytes GPn = 25;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGpn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getGpn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGpn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setGpn = function(value) {
  return jspb.Message.setProto3BytesField(this, 25, value);
};


/**
 * optional bool VQ = 26;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getVq = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setVq = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool CA = 27;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool CR = 28;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool CL = 29;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bytes CSn = 30;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getCsn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * optional bytes CSn = 30;
 * This is a type-conversion wrapper around `getCsn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getCsn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCsn()));
};


/**
 * optional bytes CSn = 30;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCsn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getCsn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCsn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCsn = function(value) {
  return jspb.Message.setProto3BytesField(this, 30, value);
};


/**
 * optional bool TF = 31;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getTf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setTf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bytes VSn = 32;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getVsn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * optional bytes VSn = 32;
 * This is a type-conversion wrapper around `getVsn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getVsn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVsn()));
};


/**
 * optional bytes VSn = 32;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVsn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getVsn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVsn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setVsn = function(value) {
  return jspb.Message.setProto3BytesField(this, 32, value);
};


/**
 * optional bool CO = 33;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool EC = 34;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getEc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setEc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool CS = 35;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getCs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setCs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool FR = 36;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getFr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setFr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bytes BDn = 37;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getBdn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * optional bytes BDn = 37;
 * This is a type-conversion wrapper around `getBdn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getBdn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBdn()));
};


/**
 * optional bytes BDn = 37;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBdn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getBdn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBdn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setBdn = function(value) {
  return jspb.Message.setProto3BytesField(this, 37, value);
};


/**
 * optional bytes TPn = 38;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Reply.prototype.getTpn = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * optional bytes TPn = 38;
 * This is a type-conversion wrapper around `getTpn()`
 * @return {string}
 */
proto.vivacity.core.Reply.prototype.getTpn_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTpn()));
};


/**
 * optional bytes TPn = 38;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTpn()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Reply.prototype.getTpn_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTpn()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setTpn = function(value) {
  return jspb.Message.setProto3BytesField(this, 38, value);
};


/**
 * optional bool SB = 39;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getSb = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setSb = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool LC = 40;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getLc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setLc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool MR = 41;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getMr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setMr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool MF = 42;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getMf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setMf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool ML = 43;
 * @return {boolean}
 */
proto.vivacity.core.Reply.prototype.getMl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setMl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional UsingMapsOrBitMasks using_maps_or_bitmasks = 50;
 * @return {!proto.vivacity.core.Reply.UsingMapsOrBitMasks}
 */
proto.vivacity.core.Reply.prototype.getUsingMapsOrBitmasks = function() {
  return /** @type {!proto.vivacity.core.Reply.UsingMapsOrBitMasks} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.vivacity.core.Reply.UsingMapsOrBitMasks} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setUsingMapsOrBitmasks = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * map<uint32, bool> Gn_as_map = 51;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getGnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 51, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearGnAsMapMap = function() {
  this.getGnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> SCn_as_map = 52;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getScnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 52, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearScnAsMapMap = function() {
  this.getScnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> SDn_as_map = 53;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getSdnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 53, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearSdnAsMapMap = function() {
  this.getSdnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> LFn_as_map = 54;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getLfnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 54, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearLfnAsMapMap = function() {
  this.getLfnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> GPn_as_map = 55;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getGpnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 55, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearGpnAsMapMap = function() {
  this.getGpnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> CSn_as_map = 56;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getCsnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 56, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearCsnAsMapMap = function() {
  this.getCsnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> VSn_as_map = 57;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getVsnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 57, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearVsnAsMapMap = function() {
  this.getVsnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> BDn_as_map = 58;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getBdnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 58, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearBdnAsMapMap = function() {
  this.getBdnAsMapMap().clear();
  return this;
};


/**
 * map<uint32, bool> TPn_as_map = 59;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.Reply.prototype.getTpnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 59, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearTpnAsMapMap = function() {
  this.getTpnAsMapMap().clear();
  return this;
};


/**
 * map<string, bool> special_bits = 80;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.vivacity.core.Reply.prototype.getSpecialBitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 80, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.clearSpecialBitsMap = function() {
  this.getSpecialBitsMap().clear();
  return this;
};


/**
 * optional uint32 controller_id = 100;
 * @return {number}
 */
proto.vivacity.core.Reply.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional uint32 stream_index = 101;
 * @return {number}
 */
proto.vivacity.core.Reply.prototype.getStreamIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Reply} returns this
 */
proto.vivacity.core.Reply.prototype.setStreamIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ControllerState.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ControllerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ControllerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ControllerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    control: (f = msg.getControl()) && proto.vivacity.core.Control.toObject(includeInstance, f),
    reply: (f = msg.getReply()) && proto.vivacity.core.Reply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ControllerState}
 */
proto.vivacity.core.ControllerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ControllerState;
  return proto.vivacity.core.ControllerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ControllerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ControllerState}
 */
proto.vivacity.core.ControllerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ControllerState.MessageVersion} */ (reader.readEnum());
      msg.setMessageVersion(value);
      break;
    case 2:
      var value = new proto.vivacity.core.Control;
      reader.readMessage(value,proto.vivacity.core.Control.deserializeBinaryFromReader);
      msg.setControl(value);
      break;
    case 3:
      var value = new proto.vivacity.core.Reply;
      reader.readMessage(value,proto.vivacity.core.Reply.deserializeBinaryFromReader);
      msg.setReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ControllerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ControllerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ControllerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ControllerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getControl();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.Control.serializeBinaryToWriter
    );
  }
  f = message.getReply();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.Reply.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ControllerState.MessageVersion = {
  UNKNOWN_MESSAGE_VERSION: 0,
  V1_0_0: 1
};

/**
 * optional MessageVersion message_version = 1;
 * @return {!proto.vivacity.core.ControllerState.MessageVersion}
 */
proto.vivacity.core.ControllerState.prototype.getMessageVersion = function() {
  return /** @type {!proto.vivacity.core.ControllerState.MessageVersion} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ControllerState.MessageVersion} value
 * @return {!proto.vivacity.core.ControllerState} returns this
 */
proto.vivacity.core.ControllerState.prototype.setMessageVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Control control = 2;
 * @return {?proto.vivacity.core.Control}
 */
proto.vivacity.core.ControllerState.prototype.getControl = function() {
  return /** @type{?proto.vivacity.core.Control} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Control, 2));
};


/**
 * @param {?proto.vivacity.core.Control|undefined} value
 * @return {!proto.vivacity.core.ControllerState} returns this
*/
proto.vivacity.core.ControllerState.prototype.setControl = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ControllerState} returns this
 */
proto.vivacity.core.ControllerState.prototype.clearControl = function() {
  return this.setControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ControllerState.prototype.hasControl = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Reply reply = 3;
 * @return {?proto.vivacity.core.Reply}
 */
proto.vivacity.core.ControllerState.prototype.getReply = function() {
  return /** @type{?proto.vivacity.core.Reply} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Reply, 3));
};


/**
 * @param {?proto.vivacity.core.Reply|undefined} value
 * @return {!proto.vivacity.core.ControllerState} returns this
*/
proto.vivacity.core.ControllerState.prototype.setReply = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ControllerState} returns this
 */
proto.vivacity.core.ControllerState.prototype.clearReply = function() {
  return this.setReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ControllerState.prototype.hasReply = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.vivacity.core);
