import { action, computed, makeObservable, observable } from "mobx";
import { ValidationRunID, researchGrade, CountlineID, FrameNumber } from "../domain";
import { ValidationRun } from "../interfaces";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { getApiEndpoint, ApiEndpointPath } from "./apiStore";
import { handleNon200 } from "./utils/promiseHandlers";
import { errString } from "./utils/errorString";
import { ClassifyingDetectorClassTypeNumber } from "../workers/utils";

export const DELETE_VALIDATION_RUN_EVENT = "DELETE_VALIDATION_RUN_EVENT";

export interface DeleteValidationRunEvent {
  validationRunID: ValidationRunID;
  easyDeletionMode: boolean;
}

export class ValidationRunStore extends ChildStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable
  validationRuns = new Map<ValidationRunID, ValidationRun>();
  @observable
  proposedDeletionValidationRunID: ValidationRunID | undefined = undefined;
  @observable
  easyDeletionMode = false;

  init() {
    window.addEventListener(DELETE_VALIDATION_RUN_EVENT, ((e: CustomEvent<DeleteValidationRunEvent>) => {
      const { validationRunID, easyDeletionMode } = e.detail;
      this.setEasyDeletionMode(easyDeletionMode);
      this.setProposedDeletionValidationRunID(validationRunID);
    }) as EventListener);
  }

  @action
  setProposedDeletionValidationRunID(id: ValidationRunID | undefined) {
    this.proposedDeletionValidationRunID = id;
  }

  @action
  setEasyDeletionMode(easyDeletionMode: boolean) {
    this.easyDeletionMode = easyDeletionMode;
  }

  @action
  deleteProposedValidationRun() {
    if (!this.proposedDeletionValidationRunID) {
      console.error("proposedDeletionValidationRunID was undefined when attempting to delete");
      return;
    }
    const validationRun = this.validationRuns.get(this.proposedDeletionValidationRunID);
    if (!validationRun) {
      console.error(`unable to lookup validationRun ${this.proposedDeletionValidationRunID} when attempting to delete`);
      return;
    }

    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.DELETE_VALIDATION_RUN) +
          "?" +
          new URLSearchParams({
            validation_run_id: this.proposedDeletionValidationRunID!.toString(10),
          }).toString(),
        { method: "DELETE" }
      )
      .then(handleNon200)
      .then(() => {
        validationRun.deletedAt = new Date();
        this.setProposedDeletionValidationRunID(undefined);
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not deleteProposedValidationRun(): " + errString(err))
        )
      );
  }

  @action
  setValidationRun(id: ValidationRunID, run: ValidationRun) {
    const thumbnail = this.validationRuns.get(id)?.thumbnail;
    const videoDownloadURL = this.validationRuns.get(id)?.videoDownloadURL;
    if (thumbnail) {
      if (videoDownloadURL) {
        this.validationRuns.set(id, {
          ...run,
          thumbnail,
          videoDownloadURL,
        });
      } else {
        this.validationRuns.set(id, {
          ...run,
          thumbnail,
        });
      }
    } else {
      if (videoDownloadURL) {
        this.validationRuns.set(id, {
          ...run,
          videoDownloadURL,
        });
      } else {
        this.validationRuns.set(id, run);
      }
    }
  }

  @action
  async updateValidationRunStatus(
    validationRunID: ValidationRunID,
    passed: boolean,
    customValidatedClasses: string | null
  ) {
    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.COMPLETE_VALIDATION) +
          "?" +
          new URLSearchParams({
            validation_run_id: `${validationRunID}`,
            passed: `${passed}`,
            custom_validated_classes: `${customValidatedClasses}`,
          }),
        {
          method: "POST",
        }
      )
      .then(
        action(response => {
          if (response.status === 200) {
            const validationRun = this.validationRuns.get(validationRunID);
            if (validationRun) {
              validationRun.passed = passed;
              validationRun.status = "COMPLETED";
            }
          }
        })
      )
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors(
            "Could not deleteCountlineValidationCrossing(): " + errString(err)
          )
        )
      );
  }

  async updateValidationRunResearchGrade(validationRunIDs: ValidationRunID[], grade: researchGrade) {
    const params = new URLSearchParams(validationRunIDs.map(id => ["validation_run_ids", `${id}`]));
    params.append("research_validation_grade", grade);
    return this.rootStore.apiStore
      .authenticatedFetch(getApiEndpoint(ApiEndpointPath.UPDATE_RESEARCH_GRADE) + "?" + params, {
        method: "POST",
      })
      .then(async res => res.text())
      .catch(e => {
        console.log("unexpected error: ", e);
        return undefined;
      });
  }

  @computed
  get incompleteValidationRuns() {
    return Array.from(this.validationRuns.values())
      .filter(vr => vr.deletedAt === undefined)
      .filter(vr => vr.status === "IN_PROGRESS")
      .map(vr => vr.id);
  }

  @action
  public async ValidationRunCrossingsRunningTotals(
    validationRunId?: number
  ): Promise<Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>> {
    const validationRunTotals: Map<
      FrameNumber,
      Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
    > = new Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>();

    let selectedValRun: number | undefined;
    if (!validationRunId) {
      selectedValRun = this.rootStore.urlStore.selectedValidationRun;
    } else {
      selectedValRun = validationRunId;
    }

    if (!selectedValRun) {
      return validationRunTotals;
    }

    const valRun = this.validationRuns.get(selectedValRun);
    if (!valRun) {
      return validationRunTotals;
    }

    const seenClassesMap: Map<ClassifyingDetectorClassTypeNumber, boolean> = new Map();

    const arrayOfCountlinesValidated = this.validationRuns.get(selectedValRun!)?.countlineValidationRuns;

    if (arrayOfCountlinesValidated) {
      for (const countlineID of arrayOfCountlinesValidated) {
        await this.rootStore.countlineValidationRunStore.fetchCountlineValidationCrossings(countlineID);
        this.rootStore.countlineValidationRunStore.countlineValidationRuns
          .get(countlineID)
          ?.validationRunCountlineCrossings.forEach(crossing => {
            seenClassesMap.set(crossing.detectionClassV2Id, true);
          });
      }
    }

    // Get all classes seen by all countline validation runs
    valRun.countlineValidationRuns?.forEach(clValRunID => {
      const clValRun = this.rootStore.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
      if (clValRun) {
        clValRun.validationRunCountlineCrossings.forEach(crossing => {
          seenClassesMap.set(crossing.detectionClassV2Id, true);
        });
      }
    });

    const accumulator: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();

    valRun.countlineValidationRuns?.forEach(clValRunID => {
      const countlineID = parseInt(clValRunID.split("-")[1], 10);
      const accClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accAntiClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
      seenClassesMap.forEach((b, classNumber) => {
        accAntiClockwise.set(classNumber, 0);
        accClockwise.set(classNumber, 0);
      });
      accByDirection.set(true, accClockwise);
      accByDirection.set(false, accAntiClockwise);
      accumulator.set(countlineID, accByDirection);
    });

    const videoID = this.rootStore.urlStore.selectedVideo;

    if (!videoID) {
      return validationRunTotals;
    }

    const relativeEndFrame = this.rootStore.videoStore.fetchVideoTotalFrames(videoID);

    for (let frameNumber = 0; frameNumber <= relativeEndFrame; frameNumber++) {
      const clCrossings: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();
      validationRunTotals.set(frameNumber, clCrossings);

      valRun.countlineValidationRuns?.forEach(clValRunID => {
        // TODO - I hate this
        const countlineID = parseInt(clValRunID.split("-")[1], 10);
        const clValRun = this.rootStore.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
        if (clValRun) {
          const clCrossingsByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
          clCrossings.set(countlineID, clCrossingsByDirection);

          const clAccumulator = accumulator.get(countlineID);
          const crossingsByFrameNumber = clValRun.validationRunCountlineCrossings;
          if (crossingsByFrameNumber && clAccumulator) {
            const clAccumulatorClockwise = clAccumulator.get(true);
            const clAccumulatorAntiClockwise = clAccumulator.get(false);

            if (clAccumulatorClockwise && clAccumulatorAntiClockwise) {
              const crossing = crossingsByFrameNumber.get(frameNumber);
              if (crossing) {
                const maybeCurrentCount = crossing.clockwise
                  ? clAccumulatorClockwise.get(crossing.detectionClassV2Id)
                  : clAccumulatorAntiClockwise.get(crossing.detectionClassV2Id);
                const currentCount = maybeCurrentCount !== undefined ? maybeCurrentCount : 0;
                if (crossing.clockwise) {
                  clAccumulatorClockwise.set(crossing.detectionClassV2Id, currentCount + 1);
                } else {
                  clAccumulatorAntiClockwise.set(crossing.detectionClassV2Id, currentCount + 1);
                }
              }
              const clCrossingsByClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
                clAccumulatorClockwise
              );
              const clCrossingsByAnticlockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
                clAccumulatorAntiClockwise
              );

              clCrossingsByDirection.set(true, clCrossingsByClockwise);
              clCrossingsByDirection.set(false, clCrossingsByAnticlockwise);
            }
          }
        }
      });
    }
    return validationRunTotals;
  }
}
