import {
  CustomPIXIComponent,
  CustomPIXIComponentBehaviorDefinition,
  applyDisplayObjectProps,
} from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import CSS from "csstype";

export interface LineProperties extends Partial<PIXI.Graphics> {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  lineColor: number;
  lineWidth: number;
  cursor?: CSS.Property.Cursor;
}

const TYPE = "Line";

const behavior: CustomPIXIComponentBehaviorDefinition<PIXI.Graphics, LineProperties> = {
  customDisplayObject: () => new PIXI.Graphics(),
  customApplyProps: function (instance: PIXI.Graphics, oldProps: LineProperties | undefined, newProps: LineProperties) {
    instance.interactive = true;

    if (oldProps !== undefined) {
      instance.clear();
    }

    const { x1, y1, x2, y2, cursor, lineColor, lineWidth, ...newPropsRest } = newProps;

    if (cursor) {
      instance.cursor = cursor;
    } else {
      instance.cursor = "pointer";
    }
    instance.lineStyle(lineWidth, lineColor, 1);
    instance.moveTo(x1, y1);
    instance.lineTo(x2, y2);
    if (oldProps) {
      const {
        x1: oldX1,
        y1: oldY1,
        x2: oldX2,
        y2: oldY2,
        cursor: oldCursor,
        lineColor: oldLineColour,
        lineWidth: oldLineWidth,
        ...oldPropsRest
      } = oldProps;

      applyDisplayObjectProps(instance.name, instance, oldPropsRest, newPropsRest);
    }
  },
};

export default CustomPIXIComponent(behavior, TYPE);
