import React, { FC, useEffect, useRef, useState } from "react";
import { DecodedValueMap, useQueryParams } from "use-query-params";

import { storesContext } from "../contexts/stores.context";

import { RootStore } from "../stores/root.store";
import { URL_QUERY_PARAMS, UrlStore } from "../stores/url.store";
import { QueryParams } from "../stores/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../enums";
import { autorun } from "mobx";
import { UrlQueryParams } from "../interfaces";

export const StoresProvider: FC<{}> = ({ children }) => {
  const [rootStore, setRootStore] = useState<RootStore | null>(null);
  const [queryParams, setQueryParams] = useQueryParams(UrlStore.queryParamDefinition());
  const location = useLocation();
  const navigationRef = useRef(useNavigate());

  useEffect(
    () =>
      autorun(() => {
        const initStore = async (params: DecodedValueMap<QueryParams>) => {
          const newRootStore = await RootStore.instantiate(navigationRef);
          // This should not init stores that hydrate as we need the jwt before hand (i.e. the entitiesStore)
          // These can instead be added to the hydration provider
          newRootStore.urlStore.init(params, setQueryParams);
          newRootStore.videoStore.init();
          newRootStore.cvRunStore.init();
          newRootStore.validationRunStore.init();
          newRootStore.countlineValidationRunStore.init();
          newRootStore.playerUIStore.init();
          newRootStore.apiStore.init();
          newRootStore.validationCreationUIStore.init();
          setRootStore(newRootStore);
        };

        if (!rootStore) {
          initStore(queryParams);
        }
      }),
    [rootStore, queryParams, setQueryParams]
  );

  useEffect(
    () =>
      autorun(() => {
        const params = new URLSearchParams(location.search);
        if (!rootStore || !rootStore.urlStore) {
          return;
        }
        const newParams: Partial<UrlQueryParams> = {};
        Object.keys(UrlStore.queryParamDefinition()).forEach(k => {
          const v = params.get(k);
          if (v) {
            const niceV = [URL_QUERY_PARAMS.SEARCH, URL_QUERY_PARAMS.SELECTED_VALIDATION_MODE].includes(
              k as URL_QUERY_PARAMS
            )
              ? v
              : parseInt(v, 10);
            newParams[k] = niceV;
          }
        });
        rootStore.urlStore.setParams(newParams, "replace");

        const hydrateIfNecessary = async () => {
          const selectedVisionProgram = rootStore.urlStore.selectedVisionProgram;
          if (selectedVisionProgram === undefined) {
            if (!RoutePath.Root.includes(location.pathname as RoutePath)) {
              const navigate = navigationRef.current;
              // TODO - sort this out?
              alert("No VPID selected, redirecting...");
              navigate(RoutePath.Root);
            }
            return;
          }
          const isHydrated = rootStore.entitiesStore.hydratedVPIDs.get(selectedVisionProgram);
          if (!isHydrated) {
            try {
              await rootStore.entitiesStore.fetchMetadataForVPID(selectedVisionProgram);
            } catch (err) {
              console.error(err);
              throw err;
            }
          }
        };

        if (rootStore.apiStore.jwt) {
          hydrateIfNecessary().catch(e => console.error(e));
        }
      }),
    [location, rootStore, rootStore?.apiStore.jwt]
  );

  // useEffect(() => {
  //   console.log({ location });
  //   const params = new URLSearchParams(location.search);
  //   if (!rootStore || !rootStore.urlStore) {
  //     return;
  //   }
  //   console.log({ again: location });
  //
  //   Object.keys(UrlStore.queryParamDefinition()).forEach(k => {
  //     const v = params.get(k);
  //     if (v) {
  //       rootStore.urlStore.setParams({ [k]: v }, "replace");
  //     }
  //   });
  //
  //   const hydrateIfNecessary = async () => {
  //     const selectedVisionProgram = rootStore.urlStore.selectedVisionProgram;
  //     if (selectedVisionProgram === undefined) {
  //       const navigate = navigationRef.current;
  //       // TODO - sort this out?
  //       alert("No VPID selected, redirecting...");
  //       navigate("/");
  //       return;
  //     }
  //     const isHydrated = rootStore.entitiesStore.hydratedVPIDs.get(selectedVisionProgram);
  //     if (!isHydrated) {
  //       try {
  //         await rootStore.entitiesStore.fetchMetadataForVPID(selectedVisionProgram);
  //       } catch (err) {
  //         console.error(err);
  //         throw err;
  //       }
  //     }
  //   };
  //
  //   hydrateIfNecessary().catch(e => console.error(e));
  // }, [location]);

  if (!rootStore) {
    return null;
  }

  // For console debugging
  (window as any).store = rootStore;

  return <storesContext.Provider value={rootStore}>{children}</storesContext.Provider>;
};
