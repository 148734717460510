/* eslint-disable */
// source: vivacity/command/file.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.command.FileProperties', null, global);
goog.exportSymbol('proto.vivacity.command.FileResponse', null, global);
goog.exportSymbol('proto.vivacity.command.FileResponse.ResponseType', null, global);
goog.exportSymbol('proto.vivacity.command.ManagedFileCommand', null, global);
goog.exportSymbol('proto.vivacity.command.ManagedFileCommand.CommandType', null, global);
goog.exportSymbol('proto.vivacity.command.UnmanagedFileCommand', null, global);
goog.exportSymbol('proto.vivacity.command.UnmanagedFileCommand.CommandType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.UnmanagedFileCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.UnmanagedFileCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.UnmanagedFileCommand.displayName = 'proto.vivacity.command.UnmanagedFileCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.ManagedFileCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.ManagedFileCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.ManagedFileCommand.displayName = 'proto.vivacity.command.ManagedFileCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.FileProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.FileProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.FileProperties.displayName = 'proto.vivacity.command.FileProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.FileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.FileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.FileResponse.displayName = 'proto.vivacity.command.FileResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.UnmanagedFileCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.UnmanagedFileCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.UnmanagedFileCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unmanagedFile: (f = msg.getUnmanagedFile()) && proto.vivacity.command.FileProperties.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && proto.vivacity.command.FileResponse.toObject(includeInstance, f),
    sedScript: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.UnmanagedFileCommand}
 */
proto.vivacity.command.UnmanagedFileCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.UnmanagedFileCommand;
  return proto.vivacity.command.UnmanagedFileCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.UnmanagedFileCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.UnmanagedFileCommand}
 */
proto.vivacity.command.UnmanagedFileCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.command.UnmanagedFileCommand.CommandType} */ (reader.readEnum());
      msg.setCommandType(value);
      break;
    case 2:
      var value = new proto.vivacity.command.FileProperties;
      reader.readMessage(value,proto.vivacity.command.FileProperties.deserializeBinaryFromReader);
      msg.setUnmanagedFile(value);
      break;
    case 7:
      var value = new proto.vivacity.command.FileResponse;
      reader.readMessage(value,proto.vivacity.command.FileResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSedScript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.UnmanagedFileCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.UnmanagedFileCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.UnmanagedFileCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUnmanagedFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.command.FileProperties.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.command.FileResponse.serializeBinaryToWriter
    );
  }
  f = message.getSedScript();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.command.UnmanagedFileCommand.CommandType = {
  UNKNOWN: 0,
  CREATE: 1,
  DELETE: 2,
  MOVE: 3,
  COPY: 4,
  EDIT: 5
};

/**
 * optional CommandType command_type = 1;
 * @return {!proto.vivacity.command.UnmanagedFileCommand.CommandType}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.getCommandType = function() {
  return /** @type {!proto.vivacity.command.UnmanagedFileCommand.CommandType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.command.UnmanagedFileCommand.CommandType} value
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional FileProperties unmanaged_file = 2;
 * @return {?proto.vivacity.command.FileProperties}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.getUnmanagedFile = function() {
  return /** @type{?proto.vivacity.command.FileProperties} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.FileProperties, 2));
};


/**
 * @param {?proto.vivacity.command.FileProperties|undefined} value
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
*/
proto.vivacity.command.UnmanagedFileCommand.prototype.setUnmanagedFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.clearUnmanagedFile = function() {
  return this.setUnmanagedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.hasUnmanagedFile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FileResponse response = 7;
 * @return {?proto.vivacity.command.FileResponse}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.getResponse = function() {
  return /** @type{?proto.vivacity.command.FileResponse} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.FileResponse, 7));
};


/**
 * @param {?proto.vivacity.command.FileResponse|undefined} value
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
*/
proto.vivacity.command.UnmanagedFileCommand.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string sed_script = 3;
 * @return {string}
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.getSedScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.UnmanagedFileCommand} returns this
 */
proto.vivacity.command.UnmanagedFileCommand.prototype.setSedScript = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.ManagedFileCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.ManagedFileCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.ManagedFileCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.ManagedFileCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    managedFile: (f = msg.getManagedFile()) && proto.vivacity.command.FileProperties.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && proto.vivacity.command.FileResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.ManagedFileCommand}
 */
proto.vivacity.command.ManagedFileCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.ManagedFileCommand;
  return proto.vivacity.command.ManagedFileCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.ManagedFileCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.ManagedFileCommand}
 */
proto.vivacity.command.ManagedFileCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.command.ManagedFileCommand.CommandType} */ (reader.readEnum());
      msg.setCommandType(value);
      break;
    case 2:
      var value = new proto.vivacity.command.FileProperties;
      reader.readMessage(value,proto.vivacity.command.FileProperties.deserializeBinaryFromReader);
      msg.setManagedFile(value);
      break;
    case 3:
      var value = new proto.vivacity.command.FileResponse;
      reader.readMessage(value,proto.vivacity.command.FileResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.ManagedFileCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.ManagedFileCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.ManagedFileCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.ManagedFileCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getManagedFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.command.FileProperties.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.command.FileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.command.ManagedFileCommand.CommandType = {
  UNKNOWN: 0,
  WRITE: 1,
  DELETE: 2,
  MOVE: 3
};

/**
 * optional CommandType command_type = 1;
 * @return {!proto.vivacity.command.ManagedFileCommand.CommandType}
 */
proto.vivacity.command.ManagedFileCommand.prototype.getCommandType = function() {
  return /** @type {!proto.vivacity.command.ManagedFileCommand.CommandType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.command.ManagedFileCommand.CommandType} value
 * @return {!proto.vivacity.command.ManagedFileCommand} returns this
 */
proto.vivacity.command.ManagedFileCommand.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional FileProperties managed_file = 2;
 * @return {?proto.vivacity.command.FileProperties}
 */
proto.vivacity.command.ManagedFileCommand.prototype.getManagedFile = function() {
  return /** @type{?proto.vivacity.command.FileProperties} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.FileProperties, 2));
};


/**
 * @param {?proto.vivacity.command.FileProperties|undefined} value
 * @return {!proto.vivacity.command.ManagedFileCommand} returns this
*/
proto.vivacity.command.ManagedFileCommand.prototype.setManagedFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.ManagedFileCommand} returns this
 */
proto.vivacity.command.ManagedFileCommand.prototype.clearManagedFile = function() {
  return this.setManagedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.ManagedFileCommand.prototype.hasManagedFile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FileResponse response = 3;
 * @return {?proto.vivacity.command.FileResponse}
 */
proto.vivacity.command.ManagedFileCommand.prototype.getResponse = function() {
  return /** @type{?proto.vivacity.command.FileResponse} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.FileResponse, 3));
};


/**
 * @param {?proto.vivacity.command.FileResponse|undefined} value
 * @return {!proto.vivacity.command.ManagedFileCommand} returns this
*/
proto.vivacity.command.ManagedFileCommand.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.ManagedFileCommand} returns this
 */
proto.vivacity.command.ManagedFileCommand.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.ManagedFileCommand.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.FileProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.FileProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.FileProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.FileProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileContent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oldFilePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fsUser: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fsGroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fsPermissions: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.FileProperties}
 */
proto.vivacity.command.FileProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.FileProperties;
  return proto.vivacity.command.FileProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.FileProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.FileProperties}
 */
proto.vivacity.command.FileProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldFilePath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsUser(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsGroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.FileProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.FileProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.FileProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.FileProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOldFilePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFsUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFsGroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFsPermissions();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string file_content = 1;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getFileContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setFileContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_path = 2;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string old_file_path = 3;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getOldFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setOldFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fs_user = 4;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getFsUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setFsUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fs_group = 5;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getFsGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setFsGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fs_permissions = 6;
 * @return {string}
 */
proto.vivacity.command.FileProperties.prototype.getFsPermissions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileProperties} returns this
 */
proto.vivacity.command.FileProperties.prototype.setFsPermissions = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.FileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.FileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.FileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.FileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.FileResponse}
 */
proto.vivacity.command.FileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.FileResponse;
  return proto.vivacity.command.FileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.FileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.FileResponse}
 */
proto.vivacity.command.FileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.command.FileResponse.ResponseType} */ (reader.readEnum());
      msg.setResponseType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.FileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.FileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.FileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.FileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.command.FileResponse.ResponseType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  ERROR_EMPTY_UNMANAGED_FILE_COMMAND_MESSAGE: 2,
  ERROR_UNEXPECTED_UNMANAGED_FILE_COMMAND_TYPE: 3,
  ERROR_EMPTY_FILE_PATH: 4,
  ERROR_CREATING_FILE: 5,
  ERROR_COPYING_FILE: 6,
  ERROR_DELETING_FILE: 7,
  ERROR_DELETING_FILE_CANNOT_STAT: 8,
  ERROR_EMPTY_OLD_FILE_PATH: 9,
  ERROR_STATTING_OLD_FILE: 10,
  ERROR_CONVERTING_FILE_PERMISSIONS: 11,
  ERROR_VALIDATING_FS_USER: 12,
  ERROR_VALIDATING_FS_GROUP: 13,
  ERROR_CREATING_NEW_DIR: 14,
  ERROR_RENAMING: 15,
  ERROR_GETTING_FILE_CONTENTS: 16,
  ERROR_EMPTY_MANAGED_FILE_COMMAND_MESSAGE: 102,
  ERROR_UNEXPECTED_MANAGED_FILE_COMMAND_TYPE: 103
};

/**
 * optional ResponseType response_type = 1;
 * @return {!proto.vivacity.command.FileResponse.ResponseType}
 */
proto.vivacity.command.FileResponse.prototype.getResponseType = function() {
  return /** @type {!proto.vivacity.command.FileResponse.ResponseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.command.FileResponse.ResponseType} value
 * @return {!proto.vivacity.command.FileResponse} returns this
 */
proto.vivacity.command.FileResponse.prototype.setResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.vivacity.command.FileResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.FileResponse} returns this
 */
proto.vivacity.command.FileResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.command);
