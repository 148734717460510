import React from "react";
import { renderToString } from "react-dom/server";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import RouteIcon from "@mui/icons-material/Route";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PolylineIcon from "@mui/icons-material/Polyline";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataObjectIcon from "@mui/icons-material/DataObject";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AutofpsSelectIcon from "@mui/icons-material/AutofpsSelect";

export const speedUpButton = renderToString(<ArrowCircleUpIcon />);
export const slowDownButton = renderToString(<ArrowCircleDownIcon />);
export const upButton = renderToString(<ArrowDropUp />);
export const downButton = renderToString(<ArrowDropDown />);
export const leftButton = renderToString(<ArrowLeftIcon />);
export const rightButton = renderToString(<ArrowRightIcon />);
export const cameraButton = renderToString(<CameraAltIcon />);
export const autoFpsSelectButton = renderToString(<AutofpsSelectIcon />);
export const backspaceButton = renderToString(<BackspaceIcon />);
export const returnButton = renderToString(<KeyboardReturnIcon />);
export const zoomInButton = renderToString(<ZoomInIcon />);
export const keyboardButton = renderToString(<KeyboardIcon />);
export const routeButton = renderToString(<RouteIcon />);
export const checkBoxOutlineBlankButton = renderToString(<CheckBoxOutlineBlankIcon />);
export const polylineButton = renderToString(<PolylineIcon />);
export const noteAddButton = renderToString(<NoteAddIcon />);
export const fileDownloadButton = renderToString(<FileDownloadIcon />);
export const dataObjectButton = renderToString(<DataObjectIcon />);
export const minorCrashButton = renderToString(<MinorCrashIcon />);
export const saveIconButton = renderToString(<SaveIcon />);
export const closeButton = renderToString(<CloseIcon />);
