import {
  Box,
  Button,
  Chip,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StopIcon from "@mui/icons-material/Stop";
import Dialog from "@mui/material/Dialog";
import { observer } from "mobx-react";
import React, { FC, Fragment, useState } from "react";
import { useStores } from "../../hooks/useStores.hook";
import _ from "lodash";
import { FreneticityButton } from "../styledElements";
import { assertExtendedKeyMapOptions, ExtendedKeyMapOptionsWithName } from "../../interfaces/hydration/type-assertions";
import { action } from "mobx";
import { PlayerUIStore } from "../../stores/playerUI.store";
import { MouseTrapToIconDictionary } from "../PlayerUI/KeyboardPopover/reactToMousetrapNames";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";

interface EditKeyMapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const prettifyHandlerPreservingClass = (handlerName: string) => {
  let newName = handlerName;

  const classNameToSubstitute = Object.keys(ClassifyingDetectorClassTypes).find(className => {
    switch (handlerName) {
      case `DELETE_LAST_${className}_COUNT_CLOCKWISE`:
      case `DELETE_LAST_${className}_COUNT_ANTICLOCKWISE`:
      case `ADD_${className}_COUNT_ANTICLOCKWISE`:
      case `ADD_${className}_COUNT_CLOCKWISE`:
        newName = newName.replace(className, "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
        return true;
      default:
        return false;
    }
  });

  newName = _.upperFirst(_.lowerCase(newName));

  if (classNameToSubstitute) {
    newName = newName.replace("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", classNameToSubstitute);
  }
  return newName;
};

const HandlerGroupRow = observer(
  (props: { keyMaps: ExtendedKeyMapOptionsWithName[]; groupName: string; playerUIStore: PlayerUIStore }) => {
    const { keyMaps, groupName, playerUIStore } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={"group"}>
          <TableCell component="th" scope="row">
            <Typography variant="h6" gutterBottom component="div" align={"left"}>
              {_.upperFirst(groupName)}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row" width={10}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow key={"details"}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="Key map" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Sequences</TableCell>
                      <TableCell align="right">Record</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {keyMaps.map(keyMap => (
                      <TableRow key={keyMap.handlerName}>
                        <TableCell component="th" scope="row">
                          {prettifyHandlerPreservingClass(keyMap.handlerName)}
                        </TableCell>
                        <TableCell>{keyMap.description}</TableCell>
                        <TableCell align={"right"}>
                          {keyMap.sequences.map(sequence => {
                            const withSpacesAndPluses = (sequence as string).split(" ").map(keySequences => {
                              return keySequences.split("+").map(keyVal => {
                                let displayKey = keyVal;
                                Object.keys(MouseTrapToIconDictionary).forEach(subst => {
                                  displayKey = displayKey.replace(subst, MouseTrapToIconDictionary[subst]);
                                });
                                return displayKey;
                              });
                            });
                            const keys = withSpacesAndPluses
                              .map(withPluses => {
                                return withPluses
                                  .map(s => {
                                    return <Chip key={s} size={"small"} label={s} color={"info"} />;
                                  })
                                  .reduce((prev, curr) => (
                                    <>
                                      {prev} <span>+</span> {curr}
                                    </>
                                  ));
                              })
                              .reduce((prev, curr) => (
                                <>
                                  {prev} <span> </span> {curr}
                                </>
                              ));
                            return (
                              <Fragment key={keyMap.name + "-" + (sequence as string)}>
                                <Chip
                                  label={keys}
                                  key={keyMap.name + "-" + (sequence as string)}
                                  onDelete={action(e => {
                                    playerUIStore.deleteSequenceFromKeyMap(keyMap.handlerName, sequence as string);
                                  })}
                                />
                                <br />
                              </Fragment>
                            );
                          })}
                        </TableCell>
                        <TableCell align={"right"}>
                          <Button
                            onClick={action(() =>
                              playerUIStore.toggleRecordingKeyMap(keyMap.handlerName, keyCombo =>
                                console.log("Recorded key combination for ", keyMap.handlerName, ": ", keyCombo)
                              )
                            )}
                          >
                            {playerUIStore.isRecordingKeymap &&
                            playerUIStore.recordingHandlerName === keyMap.handlerName ? (
                              <StopIcon color={"action"} />
                            ) : (
                              <FiberManualRecordIcon color={"error"} />
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
);

export const EditKeyMapModal: FC<EditKeyMapModalProps> = observer(({ isOpen, onClose }) => {
  const { playerUIStore } = useStores();

  const keyGroups: { [key: string]: ExtendedKeyMapOptionsWithName[] } = _.groupBy(
    Object.keys(playerUIStore.currentKeyMap).map(handlerName => {
      return {
        handlerName,
        ...assertExtendedKeyMapOptions(playerUIStore.currentKeyMap[handlerName]),
      };
    }),
    "group"
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle variant={"h6"}>Edit Key Map ({playerUIStore.currentKeyMapName})</DialogTitle>
      <DialogContent>
        <StyledFormControl>
          <Table stickyHeader>
            <TableBody>
              {Object.keys(keyGroups).map(group => {
                if (group === "DUMMY") {
                  return null;
                }
                return (
                  <HandlerGroupRow
                    key={group}
                    groupName={group}
                    keyMaps={keyGroups[group]}
                    playerUIStore={playerUIStore}
                  />
                );
              })}
            </TableBody>
          </Table>
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <FreneticityButton color={"info"} onClick={onClose}>
          Close
        </FreneticityButton>
        <FreneticityButton color={"primary"} onClick={async () => playerUIStore.pasteKeyMap()}>
          Paste Keymaps from Clipboard
        </FreneticityButton>
        <FreneticityButton color={"primary"} onClick={async () => playerUIStore.copyKeyMap()}>
          Copy Keymaps to Clipboard
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
});
