import React, { createRef, CSSProperties, FC, Fragment, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { configure, GlobalHotKeys } from "react-hotkeys";
import { observer } from "mobx-react";
import { action, autorun } from "mobx";
import { Slider } from "@mui/material";
import { Stage } from "react-pixi-fiber";

import ZoomableStage from "./PlayerDrawing/ZoomableStage";
import ContainerWithBackground from "./PlayerDrawing/ContainerWithBackground";
import Timeline from "./../../components/PlayerUI/Timeline";
import { useStores } from "../../hooks/useStores.hook";
import { LinearProgressWithLabel, PlaybackProgress } from "./Progress";
import useWindowDimensions from "./../../hooks/useWindowDimensions.hook";
import { KeyboardPopover } from "./KeyboardPopover";
import { Boxes } from "./PlayerDrawing/Boxes";
import { DrawnZone } from "./PlayerDrawing/DrawnZone";
import { TrackTails } from "./PlayerDrawing/TrackTails";
import { Countlines } from "./PlayerDrawing/Countlines";
import { FormatResultsModal, NotesModal, SaveModal } from "../modals";
import { EditTrackHeadDisplayFuncsModal } from "../modals/EditTrackHeadDisplayFuncsModal";
import { InsertCrossingModal } from "../modals/InsertCrossingModal";
import { PlayerSpeedDial } from "./PlayerSpeedDial";
import { ErrorSnackbar, SuccessSnackbar } from "./Snackbars";
import { RunningTotals } from "./PlayerDrawing/RunningTotals";
import { CornerText } from "./PlayerDrawing/CornerText";
import { CloneCVCrossingPlateModal } from "../modals/CloneCVCrossingPlateModal";
import { InsertCrossingWithPlateModal } from "../modals/InsertCrossingWithPlateModal";
import { TogglePlayUntilNextCVCrossingClassesModal } from "../modals/TogglePlayUntilNextCVCrossingClassesModal";
import { NearMissZones } from "./PlayerDrawing/NearMissZones";
import { ValidationMode } from "../../enums";
import { TurningMovementTotals } from "./PlayerDrawing/TurningMovementTotals";
import { DTFWorkerResponsePayload, TurningMovementStartZone, WorkerResponseType } from "../../domain";
import { NewCVRunModal } from "../modals/NewCVRunModal";
import { ComputerVisionRun, Video } from "../../interfaces";

const playbackSpeedSliderStyle: CSSProperties = {
  position: "absolute",
  right: "0",
  marginTop: "65px",
  height: "50%",
};

const PlayerUI: FC<{}> = observer(() => {
  configure({
    ignoreTags: ["input", "select", "textarea"],
    // logLevel: "debug",
    customLocationPrefixes: {
      0: "",
      1: "L",
      2: "R",
      3: "Numpad",
    },
    ignoreRepeatedEventsWhenKeyHeldDown: false,
    ignoreKeymapAndHandlerChangesByDefault: false,
  });

  const playerRef = useRef<ReactPlayer>(null);
  const stageRef = createRef<Stage>();

  const windowDimensions = useWindowDimensions();

  const storesContext = useStores();
  const { playerUIStore, cvRunStore, videoStore, urlStore, zoneStore, apiStore } = storesContext;

  const [dtfDownloadURL, setDtfDownloadURL] = useState<string>("");
  const [downloadingDtfs, setDownloadingDtfs] = useState<boolean>(false);
  const [dtfDownloadFraction, setDtfDownloadFraction] = useState<number>(0);
  const [dtfLoadingFraction, setDtfLoadingFraction] = useState<number>(0);
  const [isDTFsInitialised, setIsDTFsInitialised] = useState<boolean>(false);
  const [dtfBuffer, setDTFBuffer] = useState<Uint8Array>();
  const [DTFParsingWorker, setDTFParsingWorker] = useState<Worker>();
  const [selectedTurningStartZone, setSelectedTurningStartZone] = useState<TurningMovementStartZone>();

  useEffect(() => {
    playerUIStore.setReactPlayerRef(playerRef);
  }, [playerRef, playerUIStore]);

  useEffect(() => {
    playerUIStore.setStageRef(stageRef);
  }, [stageRef, playerUIStore]);

  const handleDTFParsingUpdate = (message: MessageEvent) => {
    if (message) {
      const payload: DTFWorkerResponsePayload = message.data;
      if (payload.type === WorkerResponseType.progressUpdate) {
        if (payload.progressUpdate === undefined) {
          console.error("got a worker update message without a loadFraction. shit the bed");
          return;
        }
        setDtfLoadingFraction(payload.progressUpdate.loadFraction);
      } else if (payload.type === WorkerResponseType.error && payload.error) {
        console.error("got an error while parsing DTFs:", payload.error);
        playerUIStore.setError(payload.error);
      } else if (payload.type === WorkerResponseType.fullPayload) {
        if (payload.fullPayload === undefined) {
          console.error("got a worker full payload message without a full payload. shit the bed");
          return;
        }
        setDtfLoadingFraction(1);
        playerUIStore.setTailGeometries(payload.fullPayload.tailGeometries);
        playerUIStore.setTailColours(payload.fullPayload.tailColours);
        playerUIStore.setTailStartFrames(payload.fullPayload.tailStartFrames);
        playerUIStore.setActiveTracksByFrame(payload.fullPayload.activeTracksByFrame);
        playerUIStore.setUnsnappedUnixTimestampsByFrame(payload.fullPayload.unsnappedUnixTimestampsByFrame);
        playerUIStore.setBoundingBoxesByFrame(payload.fullPayload.boundingBoxesByFrame);
        playerUIStore.setActualTotalFrames(payload.fullPayload.totalFrames);
        playerUIStore.setFrameNumberOfInterestEnd(payload.fullPayload.totalFrames);
        playerUIStore.setFramesByVideoTimestampMicroseconds(payload.fullPayload.framesByVideoTimestamp);
        playerUIStore.setVideoTimestampMicrosecondsByFrame(payload.fullPayload.videoTimestampByFrame);
        playerUIStore.setFrameAvgIntervalMicroseconds(payload.fullPayload.trueAvgIntervalMicroseconds);
        playerUIStore.setDTFBufferOffsetsByFrameNumber(payload.fullPayload.dtfBufferOffsetsByFrameNumber);
        playerUIStore.setDTFBuffer(new Uint8Array(payload.fullPayload.dtfBuffer));
        playerUIStore.setNearMissIncidents(payload.fullPayload.nearMissIncidents);
        setIsDTFsInitialised(true);
      }
    }
  };

  const parseDTFs = (width: number, height: number) => {
    if (dtfBuffer) {
      setDtfLoadingFraction(0);
      setIsDTFsInitialised(false);
      setDTFParsingWorker(() => {
        const worker = new Worker(new URL("../../workers/DTFParsing.worker.ts", import.meta.url));
        worker.onmessage = handleDTFParsingUpdate;
        worker.postMessage(
          [
            dtfBuffer.buffer,
            width,
            height,
            JSON.parse(JSON.stringify(playerUIStore.trackHeadDisplayFuncs)),
            playerUIStore.usePoseGroundCentre,
          ],
          []
        );

        return worker;
      });
    }
  };

  useEffect(() => {
    if (dtfBuffer) {
      const video = videoStore.videos.get(urlStore.selectedVideo!)!;
      parseDTFs(playerUIStore.use1920x1080 ? 1920 : video.width, playerUIStore.use1920x1080 ? 1080 : video.height);
    }
  }, [dtfBuffer]);

  const fetchDTFs = () => {
    if (downloadingDtfs) {
      // Don't do anything
      return;
    }
    setDownloadingDtfs(true);
    // Reset the state
    setDtfDownloadFraction(0);
    setDtfLoadingFraction(0);
    setIsDTFsInitialised(false);
    if (dtfDownloadURL) {
      apiStore
        .authenticatedFetch(dtfDownloadURL + "?alt=media", {
          method: "GET",
        })
        .then(
          action(resp => {
            // at this point we haven't actually downloaded the dtfs, we have a reader which lets us download from a readable stream
            const reader = resp.body?.getReader();
            if (!resp.ok || resp.body === undefined || !reader) {
              playerUIStore.setError("Error retrieving DTFs");
              console.error("got error retrieving DTFs:", resp.body);
              return;
            }

            const contentLengthStr = resp.headers.get("Content-Length");
            if (contentLengthStr === null) {
              playerUIStore.setError("Content-Length not set when retrieving DTFs");
              console.error(playerUIStore.errors);
              return;
            }

            const contentLength = parseInt(contentLengthStr, 10);

            const chunksOfDownloadedRawDTFData: Uint8Array[] = [];
            let receivedLength = 0; // received that many bytes at the moment

            // Recursively pump the reader, filling up
            const pump: () => void = action(async () => {
              return reader.read().then(
                action(({ done, value }) => {
                  if (done) {
                    // the download is complete, so send assemble the chunks and offload the raw DTFs to a worker for processing
                    const byteArray = new Uint8Array(receivedLength); // (4.1)
                    let position = 0;
                    for (const chunk of chunksOfDownloadedRawDTFData) {
                      byteArray.set(chunk, position); // (4.2)
                      position += chunk.length;
                    }
                    setDTFBuffer(byteArray);
                    return;
                  } else {
                    if (value === undefined) {
                      playerUIStore.setError("Pumped a reader with an undefined value - shit the bed");
                      console.error(playerUIStore.errors);
                      return;
                    }
                    receivedLength += value.length;
                    chunksOfDownloadedRawDTFData.push(value);
                    setDtfDownloadFraction(receivedLength / contentLength);
                    pump();
                  }
                })
              );
            });
            pump();
          })
        )
        .catch(
          action(err => {
            playerUIStore.setError("Exception during DTF download: " + err);
            console.error(playerUIStore.errors);
          })
        );
    }
  };

  const setZoneCountlineGeometry = async (cvRun: ComputerVisionRun, video: Video) => {
    await cvRunStore.hydrateCvRun(cvRun.id);
    if (!dtfDownloadURL) {
      setDtfDownloadURL(cvRun.dtfsUrl);
    }
    if (cvRun.imageSpaceCountlines) {
      cvRun.imageSpaceCountlines.countlines.forEach(countline => {
        playerUIStore.setCountlineGeometryFromConfig(
          countline,
          playerUIStore.use1920x1080 ? 1920 : video.width,
          playerUIStore.use1920x1080 ? 1080 : video.height
        );
      });
      if (cvRun.imageSpaceMasks) {
        cvRun.imageSpaceMasks.zones?.forEach(zone => {
          playerUIStore.setImageSpaceMasksFromConfig(
            zone,
            playerUIStore.use1920x1080 ? 1920 : video.width,
            playerUIStore.use1920x1080 ? 1080 : video.height
          );
        });
      }
      if (cvRun.imageSpaceTurningZones) {
        cvRun.imageSpaceTurningZones.zones?.forEach(zone => {
          playerUIStore.setImageSpaceTurningZonesFromConfig(
            zone,
            playerUIStore.use1920x1080 ? 1920 : video.width,
            playerUIStore.use1920x1080 ? 1080 : video.height
          );
        });
      }
    }
    if (urlStore.selectedValidationRun !== undefined) {
      storesContext.countlineValidationRunStore.getValidationRunCountlineCrossings(urlStore.selectedValidationRun);
      cvRun.imageSpaceCountlines?.countlines.forEach(countlineConfig => {
        const clValRunID = `${urlStore.selectedValidationRun}-${countlineConfig.countline_id}`;
        const clValRun = storesContext.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
        if (clValRun) {
          if (clValRun.status === "OMITTED") {
            playerUIStore.clearCountlineGeometry(countlineConfig.countline_id);
          }
        }
      });
    }
  };

  useEffect(() => {
    return autorun(async () => {
      if (urlStore.selectedVideo !== undefined) {
        const video = videoStore.videos.get(urlStore.selectedVideo);
        if (video) {
          if (!video.downloadUrl) {
            await videoStore.storeSignedURL(video.id);
          }
          if (video.downloadUrl !== undefined && video.downloadUrl !== "") {
            playerUIStore.setVideo(video);
          }
        }
        if (video && urlStore.selectedComputerVisionRun !== undefined) {
          const cvRun = cvRunStore.computerVisionRuns.get(urlStore.selectedComputerVisionRun);
          if (cvRun !== undefined) {
            await setZoneCountlineGeometry(cvRun, video);
          }
        }
      }
    });
  }, []);

  if (dtfDownloadURL && !downloadingDtfs) {
    fetchDTFs();
  }

  if (playerUIStore.videoURL === "") {
    // Something earlier (hydration hook or selectedVideo check) needs to redirect somewhere to hydrate and select a video
    return null;
  }

  const height = playerUIStore.use1920x1080 ? 1080 : playerUIStore.videoHeight;
  const width = playerUIStore.use1920x1080 ? 1920 : playerUIStore.videoWidth;

  const stageWidth = windowDimensions.width;
  const stageHeight = windowDimensions.width * (playerUIStore.videoHeight / playerUIStore.videoWidth);

  if (playerUIStore.timelineWidth !== stageWidth) {
    playerUIStore.setTimelineWidth(stageWidth);
  }

  let dtfProgress: JSX.Element | null = null;
  if (dtfDownloadURL !== "" && (dtfDownloadFraction !== 1 || dtfLoadingFraction !== 1)) {
    dtfProgress = (
      <Fragment>
        <LinearProgressWithLabel value={dtfDownloadFraction * 100} text={"DTF download "} minwidth={140} />
        <LinearProgressWithLabel value={dtfLoadingFraction * 100} text={"DTF loading "} minwidth={140} />
      </Fragment>
    );
  }

  playerUIStore.setLastRenderPerformanceTime();
  return (
    <>
      <GlobalHotKeys keyMap={playerUIStore.currentKeyMap} handlers={playerUIStore.keyboardHandlers} allowChanges={true}>
        {dtfProgress}
        <PlaybackProgress />
        <Timeline height={60} />
        <ZoomableStage
          stageRef={stageRef}
          videoRef={playerRef}
          style={{ width: stageWidth + "px", height: stageHeight + "px" }}
          zoomEnabled={playerUIStore.zoomEnabled}
          options={{
            antialias: true,
            backgroundColor: 0xeeeeee,
            width: playerUIStore.use1920x1080 ? 1920 : width,
            height: playerUIStore.use1920x1080 ? 1080 : height,
          }}
        >
          <ContainerWithBackground
            width={playerUIStore.use1920x1080 ? 1920 : width}
            height={playerUIStore.use1920x1080 ? 1080 : height}
            blur={playerUIStore.videoBlur}
            playerUIStore={playerUIStore}
          >
            <CornerText playerUIStore={playerUIStore} />
            {!playerUIStore.showTurningZones && (
              <RunningTotals storesContext={storesContext} width={playerUIStore.use1920x1080 ? 1920 : width} />
            )}
            {playerUIStore.showTurningZones && (
              <DrawnZone
                imageSpaceZones={playerUIStore.imageSpaceTurningZones}
                videoHeight={playerUIStore.use1920x1080 ? 1080 : playerUIStore.videoHeight}
                videoWidth={playerUIStore.use1920x1080 ? 1920 : playerUIStore.videoWidth}
                zoneType={"turning"}
                onClick={id => setSelectedTurningStartZone(id as TurningMovementStartZone)}
              />
            )}
            {playerUIStore.showTurningZones && playerUIStore.getCVRunTurningMovements() && selectedTurningStartZone && (
              <TurningMovementTotals
                startZoneId={selectedTurningStartZone}
                turningMovementsPerStartZone={playerUIStore.getCVRunTurningMovements()!.get(selectedTurningStartZone)!}
              />
            )}
            <TrackTails playerUIStore={playerUIStore} isDTFsInitialised={isDTFsInitialised} />
            <Boxes playerUIStore={playerUIStore} />
            {playerUIStore.showMasks && (
              <DrawnZone
                imageSpaceZones={playerUIStore.imageSpaceMasks}
                videoHeight={playerUIStore.use1920x1080 ? 1080 : playerUIStore.videoHeight}
                videoWidth={playerUIStore.use1920x1080 ? 1920 : playerUIStore.videoWidth}
                zoneType={"mask"}
              />
            )}

            {playerUIStore.showNearMissZones &&
              urlStore.selectedValidationMode === ValidationMode.NearMissValidation && (
                <NearMissZones
                  vpZones={zoneStore.zones}
                  videoHeight={playerUIStore.use1920x1080 ? 1080 : playerUIStore.videoHeight}
                  videoWidth={playerUIStore.use1920x1080 ? 1920 : playerUIStore.videoWidth}
                />
              )}
            <Countlines playerUIStore={playerUIStore} urlStore={urlStore} />
          </ContainerWithBackground>
        </ZoomableStage>
        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: "slider-vertical",
            },
          }}
          orientation="vertical"
          min={0.0625}
          max={16}
          step={0.02}
          value={playerUIStore.playbackRateVisual}
          defaultValue={1}
          aria-label="Playback speed"
          valueLabelDisplay="on"
          style={playbackSpeedSliderStyle}
          valueLabelFormat={value => {
            return value.toFixed(2) + "x";
          }}
          onChange={(e, newValue) => {
            playerUIStore.setPlaybackRate(newValue as number);
          }}
          onKeyDown={e => e.preventDefault()}
        />
        <ReactPlayer
          style={{ display: "none" }}
          onProgress={action("ReactPlayer.onProgress -> setCurrentTime", progress => {
            playerUIStore.setCurrentTime(progress.playedSeconds, progress.loadedSeconds);
          })}
          onDuration={action(durationActual => {
            playerUIStore.setVideoDuration(durationActual, isDTFsInitialised, dtfLoadingFraction);
          })}
          progressInterval={10} // TODO - make this configurable
          playbackRate={playerUIStore.playbackRate}
          controls={false}
          loop={false}
          playsinline={true}
          ref={playerRef}
          playing={playerUIStore.playing}
          onReady={action("ReactPlayer.onReady", player => {
            const video = player.getInternalPlayer() as HTMLVideoElement;
            if (video) {
              playerUIStore.setVideoDimensions(video.videoWidth, video.videoHeight);
            }
          })}
          onStart={() => {
            if (!playerUIStore.playing) {
              // Video sometimes autoplays even when it shouldn't??
              playerRef.current?.getInternalPlayer().pause();
              console.log("video autoplayed for some reason... pausing");
            }
          }}
          width={playerUIStore.videoWidth}
          height={playerUIStore.videoHeight}
          url={playerUIStore.videoURL}
          config={{
            file: {
              forceVideo: true,
              attributes: {
                crossOrigin: "true",
                preload: "auto",
                autoPlay: false,
              },
            },
          }}
        />
        <SuccessSnackbar />
        <ErrorSnackbar />
        <PlayerSpeedDial height={windowDimensions.height} />
        <KeyboardPopover />
        <FormatResultsModal
          isOpen={playerUIStore.isResultsModalOpen}
          onClose={() => playerUIStore.setIsResultsModalOpen(false)}
          snackbarText={text => playerUIStore.setSnackbarText(text)}
        />
        <EditTrackHeadDisplayFuncsModal
          isOpen={playerUIStore.isEditTrackHeadDisplayFuncsModalOpen}
          onClose={() => playerUIStore.setIsEditTrackHeadDisplayFuncsModalOpen(false)}
          parseDTFs={parseDTFs}
          setZoneCountlineGeometry={setZoneCountlineGeometry}
          cvRun={
            urlStore.selectedComputerVisionRun
              ? cvRunStore.computerVisionRuns.get(urlStore.selectedComputerVisionRun)
              : undefined
          }
          video={urlStore.selectedVideo ? videoStore.videos.get(urlStore.selectedVideo) : undefined}
        />
        <InsertCrossingModal
          isOpen={playerUIStore.isInsertCrossingModalOpen}
          onClose={() => playerUIStore.setIsInsertCrossingModalOpen(false)}
        />
        <InsertCrossingWithPlateModal
          isOpen={playerUIStore.isInsertCrossingWithPlateModalOpen}
          onClose={() => playerUIStore.setIsInsertCrossingWithPlateModalOpen(false)}
        />
        <CloneCVCrossingPlateModal
          isOpen={playerUIStore.isCloneCVCrossingWithPlateModalOpen}
          onClose={() => playerUIStore.setIsCloneCVCrossingWithPlateModalOpen(false)}
        />
        <TogglePlayUntilNextCVCrossingClassesModal
          isOpen={playerUIStore.isPlayUntilNextCVCrossingClassesModalOpen}
          onClose={() => playerUIStore.setIsPlayUntilNextCVCrossingClassesModalOpen(false)}
        />
        <NotesModal isOpen={playerUIStore.isNotesModalOpen} onClose={() => playerUIStore.setIsNotesModalOpen(false)} />
        <SaveModal isOpen={playerUIStore.isSaveModalOpen} onClose={() => playerUIStore.setIsSaveModalOpen(false)} />
        <NewCVRunModal
          isOpen={playerUIStore.isNewCVRunModalOpen}
          onClose={() => playerUIStore.setIsNewCVRunModalOpen(false)}
          videoId={urlStore.selectedVideo!}
        />
      </GlobalHotKeys>
    </>
  );
});

export default PlayerUI;
