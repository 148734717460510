import React, { FC, useState } from "react";
import { useStores } from "../../hooks/useStores.hook";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { FreneticityButton } from "../styledElements";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";
import { ClassifyingDetectorClassTypeNumber, classLookup } from "../../workers/utils";

interface InsertCrossingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InsertCrossingModal: FC<InsertCrossingModalProps> = ({ isOpen, onClose }) => {
  const { playerUIStore } = useStores();

  const [classType, setClassType] = useState<ClassifyingDetectorClassTypeNumber>(1);
  const [clockwise, setClockwise] = useState<boolean>(true);

  const classes = Object.keys(ClassifyingDetectorClassTypes).map(className => {
    const classNumber = ClassifyingDetectorClassTypes[className] as ClassifyingDetectorClassTypeNumber;

    return (
      <MenuItem key={classNumber} value={classNumber}>
        {classLookup[classNumber]}
      </MenuItem>
    );
  });
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>Insert crossing</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={classType}
              label="Class Type"
              onChange={e => {
                setClassType(e.target.value as ClassifyingDetectorClassTypeNumber);
              }}
            >
              {classes}
            </Select>
            <Typography variant="body2">Clockwise</Typography>
            <Switch
              checked={clockwise}
              onChange={(e, val) => {
                e.currentTarget.blur();
                setClockwise(val);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </FormLabel>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          type={"submit"}
          autoFocus={true}
          color={classType !== 0 ? "primary" : "warning"}
          onClick={e => {
            if (classType !== 0) {
              e.currentTarget.blur();
              playerUIStore.addCrossing(classType, clockwise);
              onClose();
            }
          }}
        >
          {classType !== 0 ? "Insert" : "Pick a class"}
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
};
