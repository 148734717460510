/* eslint-disable */
import { CustomPIXIComponent, applyDisplayObjectProps } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";

export interface CircleProperties extends Partial<PIXI.Graphics> {
  x: number;
  y: number;
  radius: number;
  fillColor?: number;
  lineColor: number;
  lineWidth: number;
}

export default CustomPIXIComponent(
  {
    customDisplayObject: (props: CircleProperties | undefined) => new PIXI.Graphics(),
    customApplyProps: function (
      instance: PIXI.Graphics,
      oldProps: CircleProperties | undefined,
      newProps: CircleProperties
    ) {
      if (oldProps !== undefined) {
        instance.clear();
      }

      const { x, y, radius, fillColor, lineColor, lineWidth, ...newPropsRest } = newProps;

      if (fillColor) {
        instance.beginFill(fillColor);
      }
      instance.lineStyle(lineWidth, lineColor);
      instance.drawCircle(x, y, radius);
      if (fillColor) {
        instance.endFill();
      }

      if (oldProps) {
        const {
          x: oldX,
          y: oldY,
          radius: oldRadius,
          fillColor: oldFill,
          lineColor: oldColor,
          lineWidth: oldWidth,
          ...oldPropsRest
        } = oldProps;
        applyDisplayObjectProps(instance.name, instance, oldPropsRest, newPropsRest);
      }
    },
  },
  "Circle"
);
