import React, { FC } from "react";
import { observer } from "mobx-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toJS } from "mobx";
import { URL_QUERY_PARAMS } from "../../stores/url.store";
import { Box, Grid, Tooltip } from "@mui/material";
import { ValidationRun } from "../../interfaces";
import { Link } from "react-router-dom";
import { RoutePath } from "../../enums";
import { useStores } from "../../hooks/useStores.hook";
import { FreneticityButton } from "../styledElements";
import { DELETE_VALIDATION_RUN_EVENT, DeleteValidationRunEvent } from "../../stores/validationRun.store";
import { DeleteValidationRunConfirmationModal, EasyDeleteValidationRunConfirmationModal } from "../modals";
import _ from "lodash";
import { ThumbnailButton } from "./VideoTable";

const idColumn: GridColDef<ValidationRun, number> = {
  field: "id",
  headerName: "Validation ID",
  flex: 0.1,
  valueFormatter: v => v.value,
};

const vpidColumn: GridColDef<ValidationRun> = {
  field: "vpid",
  headerName: "VPID",
  flex: 0.1,
  renderCell: ({ row }) => (row.vpid ? row.vpid : ""),
};

const videoColumn: GridColDef<ValidationRun> = {
  field: "videoID",
  headerName: "Video ID",
  flex: 0.1,
  renderCell: ({ row }) => (row.videoID ? row.videoID : ""),
};

const cvRunColumn: GridColDef<ValidationRun> = {
  field: "cvRunID",
  headerName: "CVRun ID",
  flex: 0.1,
  renderCell: ({ row }) => (row.cvRunID ? row.cvRunID : ""),
};

const statusColumn: GridColDef<ValidationRun> = {
  field: "status",
  headerName: "Status",
  flex: 0.3,
  renderCell: ({ row }) => {
    return row.status === "COMPLETED" ? (row.passed ? "Passed" : "Failed") : _.upperFirst(_.lowerCase(row.status));
  },
};

const actionsColumn: GridColDef<ValidationRun> = {
  field: "actions",
  headerName: "Actions",
  sortable: false,
  filterable: false,
  flex: 1,
  renderCell: ({ row }) => {
    const params = new URLSearchParams(location.search);
    params.set(URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN, row.id.toString(10));
    if (row.vpid) {
      params.set(URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM, row.vpid.toString(10));
    }
    if (row.videoID) {
      params.set(URL_QUERY_PARAMS.SELECTED_VIDEO, row.videoID.toString(10));
    }
    if (row.cvRunID) {
      params.set(URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN, row.cvRunID.toString(10));
    }

    const handleDelete = (easyDeletionMode: boolean) => () => {
      const cloneValidationModalEvent: DeleteValidationRunEvent = {
        validationRunID: row.id,
        easyDeletionMode,
      };
      const event = new CustomEvent(DELETE_VALIDATION_RUN_EVENT, { detail: cloneValidationModalEvent });
      window.dispatchEvent(event);
    };

    return (
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item>
          <Tooltip enterDelay={2000} title="Watch or edit Validation" arrow>
            {row.videoDownloadURL ? (
              <Link
                to={{
                  pathname: RoutePath.Player,
                  search: params.toString(),
                }}
                style={{ textDecoration: "none" }}
              >
                <ThumbnailButton row={row} />
              </Link>
            ) : (
              <ThumbnailButton row={row} />
            )}
          </Tooltip>
        </Grid>
        <Grid item>
          <a
            href={`https://centricity.vivacitylabs.com/visionProgram/${row.vpid}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FreneticityButton variant="outlined">CM</FreneticityButton>
          </a>
        </Grid>
        <Grid item>
          <FreneticityButton onClick={handleDelete(false)} color={"error"}>
            Delete
          </FreneticityButton>
        </Grid>
        <Grid item>
          <FreneticityButton onClick={handleDelete(true)} color={"error"}>
            Easy Delete
          </FreneticityButton>
        </Grid>
      </Grid>
    );
  },
};

export const ValidationRunTable = observer(() => {
  const { entitiesStore } = useStores();

  // Force a re-render of the whole table when thumbnails update, because cell renderers aren't observers
  entitiesStore.validationRuns.forEach(valRun => {
    const dummyThumbnailRead = valRun.thumbnail;
    const blah = valRun.extraThumbnails.map(thumb => thumb.thumbnail);
  });
  return (
    <>
      <DataGrid
        rows={toJS(entitiesStore.filteredValidationRuns)}
        columns={[idColumn, vpidColumn, videoColumn, cvRunColumn, statusColumn, actionsColumn]}
        getRowHeight={() => "auto"}
      />
      <DeleteValidationRunConfirmationModal />
      <EasyDeleteValidationRunConfirmationModal />
    </>
  );
});
