import Rectangle from "./Rectangle";
import { Text } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import React, { FC, Fragment } from "react";
import { observer } from "mobx-react";
import { HIGHLIGHTED_COLOUR } from "./TrackTails";
import { PlayerUIStore } from "../../../stores/playerUI.store";
import Polygon from "./Polygon";

export const Boxes: FC<{ playerUIStore: PlayerUIStore }> = observer(props => {
  const { playerUIStore } = props;

  if (!playerUIStore.showTrackBoxes) {
    return null;
  }

  const boxes: JSX.Element[] = [];
  const footprints: JSX.Element[] = [];
  const texts: JSX.Element[] = [];

  playerUIStore.boundingBoxesByFrame?.get(playerUIStore.currentFrameNumber)?.forEach((box, i) => {
    if (box.footprint && box.footprint.shouldDisplayFootprint && playerUIStore.showTrackFootprints) {
      footprints.push(
        <Polygon
          key={"footprint-" + box.trackID}
          points={[
            box.footprint.frontLeftX,
            box.footprint.frontLeftY,
            box.footprint.frontRightX,
            box.footprint.frontRightY,
            box.footprint.backRightX,
            box.footprint.backRightY,
            box.footprint.backLeftX,
            box.footprint.backLeftY,
          ]}
          fillColor={box.footprint.fillColor}
          fillAlpha={box.footprint.fillAlpha}
          lineColor={box.footprint.lineColor}
          lineAlpha={box.footprint.lineAlpha}
          lineWidth={box.footprint.lineWidth}
        />
      );
    }
    if (!box.shouldDisplay) {
      return;
    }
    let lineWidth = box.lineWidth;
    let fillAlpha = box.fillAlpha;
    let lineColor = box.lineColor;
    let fillColor = box.fillColor;
    const lineAlpha = 1;
    if (playerUIStore.mouseOverByTrack.get(box.trackID)) {
      lineWidth = 3;
      fillAlpha = 0.4;
      lineColor = HIGHLIGHTED_COLOUR;
      fillColor = HIGHLIGHTED_COLOUR;
    }
    boxes.push(
      <Rectangle
        x={box.x}
        y={box.y}
        width={box.width}
        height={box.height}
        fillColor={fillColor}
        fillAlpha={fillAlpha}
        lineColor={lineColor}
        lineWidth={lineWidth}
        lineAlpha={lineAlpha}
        key={`${playerUIStore.currentFrameNumber}-${i}`}
        pointerover={() => {
          playerUIStore.setMouseOverTrack(box.trackID, true);
        }}
        pointerout={() => {
          playerUIStore.setMouseOverTrack(box.trackID, false);
        }}
        pointerup={() => {
          playerUIStore.setLastClickedTrackHead(box.trackID);
          const tailStartFrame = playerUIStore.tailStartFrames.get(box.trackID);
          if (tailStartFrame !== undefined) {
            playerUIStore.seekToFrame(tailStartFrame);
          }
          playerUIStore.setMouseOverTrack(box.trackID, false);
        }}
      />
    );
    texts.push(
      <Text
        key={`${playerUIStore.currentFrameNumber}-${i}-text`}
        text={box.text}
        x={box.x}
        y={box.y + box.height * box.textPosition}
        alpha={box.textAlpha}
        style={new PIXI.TextStyle({ fill: box.textColour, fontSize: box.textSize })}
      />
    );
  });
  return (
    <Fragment>
      {footprints}
      {boxes}
      {texts}
    </Fragment>
  );
});
