import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { ValidationMode } from "../../enums";
import { useStores } from "../../hooks/useStores.hook";

export const ValidationModeToggle: FC = observer(() => {
  const { urlStore } = useStores();

  const { selectedValidationMode } = urlStore;

  const handleChangeValidationMode = (event: React.MouseEvent<HTMLElement>, newValidationMode: ValidationMode) => {
    if (newValidationMode !== null) {
      urlStore.setParams({ selectedValidationMode: newValidationMode }, "pushIn");
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
      <span>Validation mode:</span>
      <ToggleButtonGroup
        value={selectedValidationMode}
        exclusive
        onChange={handleChangeValidationMode}
        aria-label="text alignment"
        style={{ height: "70%" }}
      >
        <ToggleButton value={ValidationMode.CountlineValidation} aria-label="countline validation">
          Countlines
        </ToggleButton>
        <ToggleButton value={ValidationMode.NearMissValidation} aria-label="near miss validation">
          Near Miss
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
});
