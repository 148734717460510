import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../enums";
import { useStores } from "../../hooks/useStores.hook";
import { FreneticityButton } from "../styledElements";
import { CLONE_VALIDATION_RUN_MODAL_EVENT } from "../../stores/validationCreationUI.store";

export const CloneModal: FC<{}> = observer(() => {
  const { urlStore, entitiesStore, validationCreationUIStore } = useStores();

  const [selectedValidationRunIndex, setSelectedValidationRunIndex] = useState<number>(0);
  const [selectedCountlineIDs, setSelectedCountlineIDs] = useState<number[]>([]);
  const navigate = useNavigate();

  const validationRuns = (
    validationCreationUIStore.targetComputerVisionRun
      ? entitiesStore.getValidationRunsForVideoID(validationCreationUIStore.targetComputerVisionRun.videoID)
      : []
  )
    .filter(r => r.cvRunID !== validationCreationUIStore.targetComputerVisionRun?.id)
    .sort((a, b) => b.id - a.id);

  const validationRunCountlineIDs = (
    (validationRuns[selectedValidationRunIndex] &&
      validationRuns[selectedValidationRunIndex].countlineValidationRuns) ||
    []
  ).map(r => {
    // TODO: Should filter out omitted?
    return parseInt(r.split("-")[1], 10);
  });

  const onSubmit = async () => {
    if (!validationCreationUIStore.targetComputerVisionRun) {
      return;
    }

    const newValidationID = await entitiesStore.cloneValidation(
      validationCreationUIStore.targetComputerVisionRun.id,
      validationRuns[selectedValidationRunIndex].id,
      selectedCountlineIDs
    );
    const newParams = {
      selectedValidationRun: newValidationID,
      selectedComputerVisionRun: validationCreationUIStore.targetComputerVisionRun.id,
      selectedVideo: validationCreationUIStore.targetComputerVisionRun.videoID,
      selectedVisionProgram: validationCreationUIStore.targetComputerVisionRun.vpid,
    };
    urlStore.setParams(newParams, "replaceIn");
    navigate(
      `${RoutePath.Player}?${new URLSearchParams(
        Object.entries(newParams).reduce((agg, [k, v]) => {
          agg[k] = v?.toString(10) ?? "";
          return agg;
        }, {})
      )}`
    );

    validationCreationUIStore.setCloneModalIsOpen(false);
  };

  const onClose = () => {
    const event = new CustomEvent(CLONE_VALIDATION_RUN_MODAL_EVENT, { detail: { isOpen: false } });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    setSelectedCountlineIDs([]);
  }, [selectedValidationRunIndex]);

  const onChangeSelectedCountlines = (newCountlines: number[]) => {
    setSelectedCountlineIDs(newCountlines);
  };

  return (
    <Dialog open={validationCreationUIStore.cloneModalIsOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>Clone validation</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>
            <Typography variant="body2">Validation to clone</Typography>
          </FormLabel>
          {validationRuns.map(({ id, startedAt, startedByUserEmail, cvRunID }, i) => (
            <FormControlLabel
              key={id}
              control={<Radio checked={selectedValidationRunIndex === i} size="small" />}
              onChange={() => setSelectedValidationRunIndex(i)}
              label={
                <Typography variant="body2">{`${id}, CVRun ${cvRunID}, (${startedAt?.toLocaleString()}), by ${startedByUserEmail}`}</Typography>
              }
            />
          ))}
        </FormControl>
        <FormControl>
          <FormLabel>
            <Typography variant="body2">Countlines to clone</Typography>
          </FormLabel>
          <Select
            multiple
            value={selectedCountlineIDs}
            renderValue={value => value.join(" | ")}
            size={"small"}
            onChange={e => onChangeSelectedCountlines(e.target.value as number[])}
          >
            {validationRunCountlineIDs.map(countlineID => {
              return (
                <MenuItem key={countlineID} value={countlineID}>
                  <ListItemText primary={countlineID} />
                  <Checkbox checked={selectedCountlineIDs.includes(countlineID)} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton onClick={onSubmit} type={"submit"} autoFocus={true}>
          Clone existing validation
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});
