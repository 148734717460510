/* eslint-disable */
// source: vivacity/config/countline_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_countline_crossing_pb = require('../../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
goog.exportSymbol('proto.vivacity.config.CountlineImageSpaceToRealWorld', null, global);
goog.exportSymbol('proto.vivacity.config.CountlineRealWorldConfig', null, global);
goog.exportSymbol('proto.vivacity.config.CountlineWindowedClassConfig', null, global);
goog.exportSymbol('proto.vivacity.config.CountlinesConfigChange', null, global);
goog.exportSymbol('proto.vivacity.config.CountlinesConfigChange.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramCountlinesConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CountlinesConfigChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CountlinesConfigChange.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CountlinesConfigChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CountlinesConfigChange.displayName = 'proto.vivacity.config.CountlinesConfigChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramCountlinesConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.VisionProgramCountlinesConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.VisionProgramCountlinesConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramCountlinesConfig.displayName = 'proto.vivacity.config.VisionProgramCountlinesConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CountlineRealWorldConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CountlineRealWorldConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CountlineRealWorldConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CountlineRealWorldConfig.displayName = 'proto.vivacity.config.CountlineRealWorldConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CountlineImageSpaceToRealWorld, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CountlineImageSpaceToRealWorld.displayName = 'proto.vivacity.config.CountlineImageSpaceToRealWorld';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CountlineWindowedClassConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CountlineWindowedClassConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CountlineWindowedClassConfig.displayName = 'proto.vivacity.config.CountlineWindowedClassConfig';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CountlinesConfigChange.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CountlinesConfigChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CountlinesConfigChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlinesConfigChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countlinesConfigsList: jspb.Message.toObjectList(msg.getCountlinesConfigsList(),
    proto.vivacity.config.VisionProgramCountlinesConfig.toObject, includeInstance),
    countlineRealWorldConfigsList: jspb.Message.toObjectList(msg.getCountlineRealWorldConfigsList(),
    proto.vivacity.config.CountlineRealWorldConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.CountlinesConfigChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CountlinesConfigChange;
  return proto.vivacity.config.CountlinesConfigChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CountlinesConfigChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.CountlinesConfigChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.CountlinesConfigChange.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = new proto.vivacity.config.VisionProgramCountlinesConfig;
      reader.readMessage(value,proto.vivacity.config.VisionProgramCountlinesConfig.deserializeBinaryFromReader);
      msg.addCountlinesConfigs(value);
      break;
    case 3:
      var value = new proto.vivacity.config.CountlineRealWorldConfig;
      reader.readMessage(value,proto.vivacity.config.CountlineRealWorldConfig.deserializeBinaryFromReader);
      msg.addCountlineRealWorldConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CountlinesConfigChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CountlinesConfigChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlinesConfigChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCountlinesConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.VisionProgramCountlinesConfig.serializeBinaryToWriter
    );
  }
  f = message.getCountlineRealWorldConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config.CountlineRealWorldConfig.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.CountlinesConfigChange.OperationType = {
  UNKNOWN: 0,
  UPDATE: 1,
  DELETE: 2,
  SET: 3,
  CREATE: 4
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.CountlinesConfigChange.OperationType}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.CountlinesConfigChange.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.CountlinesConfigChange.OperationType} value
 * @return {!proto.vivacity.config.CountlinesConfigChange} returns this
 */
proto.vivacity.config.CountlinesConfigChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated VisionProgramCountlinesConfig countlines_configs = 2;
 * @return {!Array<!proto.vivacity.config.VisionProgramCountlinesConfig>}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.getCountlinesConfigsList = function() {
  return /** @type{!Array<!proto.vivacity.config.VisionProgramCountlinesConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.VisionProgramCountlinesConfig, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.VisionProgramCountlinesConfig>} value
 * @return {!proto.vivacity.config.CountlinesConfigChange} returns this
*/
proto.vivacity.config.CountlinesConfigChange.prototype.setCountlinesConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.VisionProgramCountlinesConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.addCountlinesConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.VisionProgramCountlinesConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CountlinesConfigChange} returns this
 */
proto.vivacity.config.CountlinesConfigChange.prototype.clearCountlinesConfigsList = function() {
  return this.setCountlinesConfigsList([]);
};


/**
 * repeated CountlineRealWorldConfig countline_real_world_configs = 3;
 * @return {!Array<!proto.vivacity.config.CountlineRealWorldConfig>}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.getCountlineRealWorldConfigsList = function() {
  return /** @type{!Array<!proto.vivacity.config.CountlineRealWorldConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CountlineRealWorldConfig, 3));
};


/**
 * @param {!Array<!proto.vivacity.config.CountlineRealWorldConfig>} value
 * @return {!proto.vivacity.config.CountlinesConfigChange} returns this
*/
proto.vivacity.config.CountlinesConfigChange.prototype.setCountlineRealWorldConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config.CountlineRealWorldConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CountlineRealWorldConfig}
 */
proto.vivacity.config.CountlinesConfigChange.prototype.addCountlineRealWorldConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config.CountlineRealWorldConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CountlinesConfigChange} returns this
 */
proto.vivacity.config.CountlinesConfigChange.prototype.clearCountlineRealWorldConfigsList = function() {
  return this.setCountlineRealWorldConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.VisionProgramCountlinesConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramCountlinesConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramCountlinesConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramCountlinesConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countlinesList: jspb.Message.toObjectList(msg.getCountlinesList(),
    vivacity_core_countline_crossing_pb.Countline.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramCountlinesConfig;
  return proto.vivacity.config.VisionProgramCountlinesConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramCountlinesConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = new vivacity_core_countline_crossing_pb.Countline;
      reader.readMessage(value,vivacity_core_countline_crossing_pb.Countline.deserializeBinaryFromReader);
      msg.addCountlines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramCountlinesConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramCountlinesConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramCountlinesConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCountlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      vivacity_core_countline_crossing_pb.Countline.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig} returns this
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated vivacity.core.Countline countlines = 2;
 * @return {!Array<!proto.vivacity.core.Countline>}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.getCountlinesList = function() {
  return /** @type{!Array<!proto.vivacity.core.Countline>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_countline_crossing_pb.Countline, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.Countline>} value
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig} returns this
*/
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.setCountlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.Countline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Countline}
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.addCountlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.Countline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramCountlinesConfig} returns this
 */
proto.vivacity.config.VisionProgramCountlinesConfig.prototype.clearCountlinesList = function() {
  return this.setCountlinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CountlineRealWorldConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CountlineRealWorldConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CountlineRealWorldConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineRealWorldConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countlineImageSpaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gpsCoordinatesList: jspb.Message.toObjectList(msg.getGpsCoordinatesList(),
    vivacity_core_point_pb.PointF.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CountlineRealWorldConfig}
 */
proto.vivacity.config.CountlineRealWorldConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CountlineRealWorldConfig;
  return proto.vivacity.config.CountlineRealWorldConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CountlineRealWorldConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CountlineRealWorldConfig}
 */
proto.vivacity.config.CountlineRealWorldConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCountlineImageSpaceId(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.PointF;
      reader.readMessage(value,vivacity_core_point_pb.PointF.deserializeBinaryFromReader);
      msg.addGpsCoordinates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CountlineRealWorldConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CountlineRealWorldConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineRealWorldConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCountlineImageSpaceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGpsCoordinatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_point_pb.PointF.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 countline_image_space_id = 2;
 * @return {number}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.getCountlineImageSpaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.setCountlineImageSpaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated vivacity.core.PointF gps_coordinates = 3;
 * @return {!Array<!proto.vivacity.core.PointF>}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.getGpsCoordinatesList = function() {
  return /** @type{!Array<!proto.vivacity.core.PointF>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.PointF, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.PointF>} value
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
*/
proto.vivacity.config.CountlineRealWorldConfig.prototype.setGpsCoordinatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.PointF=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.PointF}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.addGpsCoordinates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.PointF, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.clearGpsCoordinatesList = function() {
  return this.setGpsCoordinatesList([]);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CountlineRealWorldConfig} returns this
 */
proto.vivacity.config.CountlineRealWorldConfig.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CountlineImageSpaceToRealWorld.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CountlineImageSpaceToRealWorld} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.toObject = function(includeInstance, msg) {
  var f, obj = {
    countlineRealWorldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countlineImageSpaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CountlineImageSpaceToRealWorld;
  return proto.vivacity.config.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CountlineImageSpaceToRealWorld} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCountlineRealWorldId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCountlineImageSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CountlineImageSpaceToRealWorld.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CountlineImageSpaceToRealWorld} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountlineRealWorldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCountlineImageSpaceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 countline_real_world_id = 1;
 * @return {number}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.getCountlineRealWorldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineImageSpaceToRealWorld} returns this
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.setCountlineRealWorldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 countline_image_space_id = 2;
 * @return {number}
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.getCountlineImageSpaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineImageSpaceToRealWorld} returns this
 */
proto.vivacity.config.CountlineImageSpaceToRealWorld.prototype.setCountlineImageSpaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CountlineWindowedClassConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CountlineWindowedClassConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineWindowedClassConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageSpaceCountlineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_class: jspb.Message.getFieldWithDefault(msg, 2, 0),
    classScalingFactor: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.CountlineWindowedClassConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CountlineWindowedClassConfig;
  return proto.vivacity.config.CountlineWindowedClassConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CountlineWindowedClassConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.CountlineWindowedClassConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageSpaceCountlineId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClassScalingFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CountlineWindowedClassConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CountlineWindowedClassConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CountlineWindowedClassConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageSpaceCountlineId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClassScalingFactor();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 image_space_countline_id = 1;
 * @return {number}
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.getImageSpaceCountlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineWindowedClassConfig} returns this
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.setImageSpaceCountlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes class = 2;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.CountlineWindowedClassConfig} returns this
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 class_scaling_factor = 3;
 * @return {number}
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.getClassScalingFactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CountlineWindowedClassConfig} returns this
 */
proto.vivacity.config.CountlineWindowedClassConfig.prototype.setClassScalingFactor = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.vivacity.config);
