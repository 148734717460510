import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

type VideoThumbnailProps = {
  videoUrl: string;
  snapshotAtTime: number;
  thumbnailHandler?: (thumbnail: string) => void;
  height: number;
  width: number;
};

const style = {
  display: "none",
};

const VideoThumbnail: React.FunctionComponent<PropsWithChildren<VideoThumbnailProps>> = (
  props: PropsWithChildren<VideoThumbnailProps>
) => {
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [seeked, setSeeked] = useState(false);
  const [snapshot, setSnapshot] = useState("");

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRefScaled = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const getSnapshot = () => {
    const canvas = canvasRef.current;
    const canvasScaled = canvasRefScaled.current;
    const video = videoRef.current;
    if (canvas !== null && video !== null && canvasScaled !== null) {
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvasScaled.height = props.height;
      canvasScaled.width = props.width;
      const ctx = canvas.getContext("2d");

      const steps = canvas.width / canvasScaled.width / 4;
      const ctxScaled = canvasScaled.getContext("2d");

      if (ctxScaled && ctx) {
        // Blur the larger image with a low pass filter so that the bilinear scaling algo doesn't get tangled in high frequencies
        ctx.filter = `blur(${steps}px)`;
        ctx.drawImage(video, 0, 0);
        ctxScaled.imageSmoothingQuality = "high";
        ctxScaled.imageSmoothingEnabled = true;
        ctxScaled.drawImage(
          ctx.canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvasScaled.width,
          canvasScaled.height
        );
      }

      const thumbnail = canvasScaled.toDataURL("image/jpeg");
      video.src = "";
      video.remove();
      canvas.remove();
      canvasScaled.remove();
      setSnapshot(thumbnail);
      if (props.thumbnailHandler) {
        props.thumbnailHandler(thumbnail);
      }
    }
  };
  const video = videoRef.current;

  useEffect(() => {
    if (metadataLoaded && dataLoaded && suspended) {
      if (!video?.currentTime || video?.currentTime < props.snapshotAtTime) {
        if (video) {
          video.currentTime = props.snapshotAtTime;
          if (seeked && snapshot === "") {
            getSnapshot();
          }
        }
      }
    }
  }, [metadataLoaded, dataLoaded, suspended, video, seeked]);

  if (snapshot === "") {
    return ReactDOM.createPortal(
      <div style={style} key={props.snapshotAtTime}>
        <canvas ref={canvasRef}></canvas>
        <canvas ref={canvasRefScaled} key={"scaled"}></canvas>
        <video
          muted
          crossOrigin={"anonymous"}
          ref={videoRef}
          src={props.videoUrl}
          autoPlay={true}
          onLoadedMetadata={() => setMetadataLoaded(true)}
          onLoadedData={() => setDataLoaded(true)}
          onSuspend={() => setSuspended(true)}
          onSeeked={() => setSeeked(true)}
        ></video>
      </div>,
      document.body
    );
  } else {
    return null;
  }
};

export default VideoThumbnail;
