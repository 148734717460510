import * as PopperJS from "@popperjs/core";
import React, { FC } from "react";
import { useStores } from "../../../hooks/useStores.hook";
import { observer } from "mobx-react";
import {
  Box,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { classLookup } from "../../../workers/utils";
import { getColourFromClass } from "../../../workers/colourMap";
import { getIconForClass } from "./iconMap";
import { VideoCrop } from "./VideoCrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import _ from "lodash";
import { action } from "mobx";
const CVCrossingPopper: FC<{}> = observer(() => {
  const { playerUIStore } = useStores();
  const cvCrossing = playerUIStore.lastClickedCVCrossingForPopper;
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [showCrop, setShowCrop] = React.useState(true);

  if (!cvCrossing) {
    return null;
  }
  let left: number;
  let top: number;
  if (playerUIStore.lastClickedCVCrossingForPopperIsZoomed) {
    left =
      1.5 +
      (playerUIStore.timelineWidth *
        (cvCrossing.frameNumber / playerUIStore.totalFrames - playerUIStore.timelineLowerBound)) /
        (playerUIStore.timelineUpperBound - playerUIStore.timelineLowerBound);
    top = 90;
  } else {
    left = 1.5 + playerUIStore.timelineWidth * (cvCrossing.frameNumber / playerUIStore.totalFrames);
    top = 64;
  }

  const virtualReference: PopperJS.VirtualElement = {
    getBoundingClientRect() {
      return {
        top: top,
        left: left,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        toJSON(): string {
          return JSON.stringify(this);
        },
      };
    },
  };

  return (
    <Popper
      open={true}
      anchorEl={virtualReference}
      placement={"bottom"}
      transition
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => {
        if (!cvCrossing) {
          return null;
        }
        return (
          <Fade {...TransitionProps} timeout={200}>
            <Paper sx={{ backgroundColor: "#F4F7FF", p: 1 }}>
              <Box
                sx={{
                  "&::before": {
                    content: '""',
                    pointerEvents: "none",
                    position: "relative",
                    top: "-54px",
                    borderTop: "8px solid transparent",
                    borderBottom: "30px solid #F4F7FF",
                    borderRight: "8px solid transparent",
                    borderLeft: "8px solid transparent",
                  },
                }}
                ref={setArrowRef}
              />
              <Grid container>
                <Grid item>
                  {getIconForClass(cvCrossing.trackClass, {
                    fontSize: "large",
                    sx: { color: `#${getColourFromClass(cvCrossing.trackClass).toString(16)}` },
                  })}
                  {cvCrossing.anprVehicleClass
                    ? getIconForClass(cvCrossing.anprVehicleClass, {
                        fontSize: "large",
                        sx: { color: `#${getColourFromClass(cvCrossing.anprVehicleClass).toString(16)}` },
                      })
                    : null}
                  <Typography sx={{ p: 2, whiteSpace: "pre-wrap" }} variant={"h6"}>
                    {classLookup[cvCrossing.trackClass]}{" "}
                    {cvCrossing.anprVehicleClass ? `(${classLookup[cvCrossing.anprVehicleClass]})` : null} (CV)
                  </Typography>
                  <Grid item>{showCrop ? <VideoCrop playerUIStore={playerUIStore} /> : null}</Grid>

                  <Grid item>
                    <IconButton
                      sx={{ p: 2 }}
                      title={`${showCrop ? "Hide" : "Show"} crop`}
                      size="small"
                      color="inherit"
                      onClick={() => setShowCrop(prev => !prev)}
                    >
                      <VisibilityIcon fontSize="medium" />
                    </IconButton>
                    <FormControlLabel
                      sx={{ fontSize: "10px" }}
                      control={
                        <Switch
                          size={"small"}
                          value={playerUIStore.keepLastClickedCVCrossingPopperOpen}
                          onChange={(e, val) => playerUIStore.setKeepLastClickedCVCrossingPopperOpen(val)}
                        />
                      }
                      label="Keep Open"
                    />
                    <IconButton
                      sx={{ p: 2 }}
                      title={`Clone CV Crossing`}
                      size="small"
                      color="inherit"
                      onClick={action(() => {
                        playerUIStore.cloneCVCrossing(cvCrossing);
                      })}
                    >
                      <ContentCopyIcon fontSize="medium" />
                    </IconButton>
                  </Grid>
                  <Table aria-label="simple table">
                    <TableBody>
                      {cvCrossing.topRankedPlate ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                            Primary Plate Read
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {cvCrossing.topRankedPlate} ({cvCrossing.topRankedConfidence ?? "unknown confidence"})
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {cvCrossing.otherPlates.length > 0 ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                            Other Plate Reads
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {_.take(cvCrossing.otherPlates, 5).map((plate, index) => {
                              return (
                                <span key={plate}>
                                  {plate} ({cvCrossing?.otherConfidences[index] ?? "unknown confidence"}) <br />
                                </span>
                              );
                            })}
                            {cvCrossing.otherPlates.length > 5 ? (
                              <Tooltip
                                title={cvCrossing.otherPlates.slice(5).map((plate, index) => (
                                  <span key={plate}>
                                    {plate} ({cvCrossing?.otherConfidences[index + 5] ?? "unknown confidence"}) <br />
                                  </span>
                                ))}
                              >
                                <span>{cvCrossing.otherPlates.length - 5} more plates...</span>
                              </Tooltip>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {cvCrossing.anprVehicleClass ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                            Plate Class
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {classLookup[cvCrossing.anprVehicleClass]}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Direction
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cvCrossing.clockwise ? "Clockwise" : "Anti-clockwise"}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Frame number
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cvCrossing.frameNumber}
                          {cvCrossing.actualFrameNumber !== cvCrossing.frameNumber
                            ? ` (Actually ${cvCrossing.actualFrameNumber})`
                            : ""}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Track ID
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cvCrossing.trackNumber}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">
                          Crossing time (real)
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cvCrossing.crossingTime.toISOString()}
                        </TableCell>
                      </TableRow>
                      {/*  TODO - add other features here */}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item sx={{ padding: "5px" }}>
                  <IconButton
                    size="medium"
                    aria-label="close"
                    color="inherit"
                    onClick={() => playerUIStore.clearLastClickedCVCrossingForPopper()}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        );
      }}
    </Popper>
  );
});

export default CVCrossingPopper;
