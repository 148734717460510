import _ from "lodash";

export const ReactKeyNamesToMousetrapDictionary = _.invert({
  /**
   * Generic
   */
  tab: "Tab",

  capslock: "CapsLock",

  shift: "Shift",
  alt: "Alt",
  ctrl: "Control",

  space: " ",

  esc: "Escape",

  left: "ArrowLeft",
  right: "ArrowRight",
  up: "ArrowUp",
  down: "ArrowDown",

  /**
   * Mac
   */
  return: "Enter",
  del: "Delete",
  command: "Meta",

  /**
   * Windows
   */
  enter: "Enter",
  backspace: "Backspace",
  ins: "Insert",
  pageup: "PageUp",
  pagedown: "PageDown",
  end: "End",
  home: "Home",
  contextmenu: "ContextMenu",
  numlock: "Clear",
});

export const MouseTrapToIconDictionary = {
  left: "←",
  right: "→",
  up: "↑",
  down: "↓",
  "page↑": "pageup",
  "page↓": "pagedown",
  shift: "shift ⇧",
  LShift: "left ⇧",
  RShift: "right ⇧",
  tab: "⇥",
  backspace: "⌫",
  RMeta: "right ⌘",
  LMeta: "left ⌘",
  RControl: navigator.userAgent.includes("Mac") ? "right ⌃" : "right ctrl",
  LControl: navigator.userAgent.includes("Mac") ? "left ⌃" : "left ctrl",
  RAltGraph: "Alt Gr",
  RAlt: navigator.userAgent.includes("Mac") ? "Right ⌥" : "right alt",
  LAlt: navigator.userAgent.includes("Mac") ? "Left ⌥" : "left alt",
};
