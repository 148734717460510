import { RootStore } from "./root.store";
import { ChildStore } from "./child.store";
import { CountlineValidationRun, ValidationRunCountlineCrossing } from "../interfaces";
import { CountlineID, CountlineValidationRunID, FrameNumber, ValidationRunID } from "../domain";
import { action, computed, makeObservable, observable } from "mobx";
import { CountlineValidationRunStatus } from "../enums";
import { handleNon200 } from "./utils/promiseHandlers";
import { ApiEndpointPath, getApiEndpoint } from "./apiStore";
import { errString } from "./utils/errorString";
import {
  assertCountlineValidationNotesHydrationHydration,
  assertValidationCrossingsHydration,
} from "../interfaces/hydration/type-assertions";
import { ClassifyingDetectorClassTypeNumber } from "../workers/utils";
import { validationCrossingHydrationtoValidationCrossings } from "../interfaces/hydration/utils";

export class CountlineValidationRunStore extends ChildStore {
  @observable
  countlineValidationRuns: Map<CountlineValidationRunID, CountlineValidationRun> = new Map<
    CountlineValidationRunID,
    CountlineValidationRun
  >();
  @observable
  hydratedClValidationRunCrossings = new Map<CountlineValidationRunID, boolean>();

  @observable
  failedToSaveMessage = "";

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action
  init() {}

  getCountlineValidationRun(valID: ValidationRunID, countlineID: CountlineID) {
    return this.countlineValidationRuns.get(`${valID}-${countlineID}`);
  }

  @action
  getValidationRunCountlineCrossings(valID: ValidationRunID) {
    const validationRun = this.rootStore.validationRunStore.validationRuns.get(valID);
    if (validationRun) {
      validationRun.countlineValidationRuns?.forEach(async id => this.fetchCountlineValidationCrossings(id));
    }
  }

  @action
  updateStatus(countlineValidationId: CountlineValidationRunID, status: CountlineValidationRunStatus) {
    const countlineValidationRun = this.countlineValidationRuns.get(countlineValidationId);

    if (countlineValidationRun) {
      countlineValidationRun.status = status;
    }
  }

  @action
  setCountlineValidationRun(id: CountlineValidationRunID, run: CountlineValidationRun) {
    const validationRunCountlineCrossings = this.countlineValidationRuns.get(id)?.validationRunCountlineCrossings;
    if (validationRunCountlineCrossings) {
      this.countlineValidationRuns.set(id, { ...run, validationRunCountlineCrossings });
    } else {
      this.countlineValidationRuns.set(id, run);
    }
  }

  @action
  setValidationRunCountlineCrossing(
    id: CountlineValidationRunID,
    frameNumber: FrameNumber,
    countlineCrossing: ValidationRunCountlineCrossing
  ) {
    const clValRun = this.countlineValidationRuns.get(id);
    if (!clValRun) {
      console.error(`FECK! Got no countline val run for id ${id}`);
      return;
    }
    clValRun.validationRunCountlineCrossings.set(frameNumber, countlineCrossing);
  }

  @action
  deleteValidationRunCountlineCrossing(id: CountlineValidationRunID, frameNumber: FrameNumber) {
    this.countlineValidationRuns.get(id)?.validationRunCountlineCrossings.delete(frameNumber);
  }

  @action
  async fetchCountlineValidationCrossings(countlineValidationRunID: CountlineValidationRunID) {
    const hydratedClValRunCrossings = this.hydratedClValidationRunCrossings.get(countlineValidationRunID);
    if (hydratedClValRunCrossings) {
      return;
    }
    this.hydratedClValidationRunCrossings.set(countlineValidationRunID, true);

    await this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.FETCH_VALIDATION_RUN_CROSSINGS) +
          "?" +
          new URLSearchParams({
            validation_run_id: countlineValidationRunID.split("-")[0],
            image_space_countline_id: countlineValidationRunID.split("-")[1],
          })
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(
        action(data => {
          const hydationCrossings = assertValidationCrossingsHydration(data);
          const crossings = validationCrossingHydrationtoValidationCrossings(hydationCrossings);
          crossings.forEach((crossing, frameNumber) => {
            this.setValidationRunCountlineCrossing(countlineValidationRunID, Number(frameNumber), crossing);
          });
        })
      )
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors(
            "Could not fetchCountlineValidationCrossings(): " + errString(err)
          )
        )
      );
  }

  @action
  async addCountlineValidationCrossing(
    countlineValidationRunID: CountlineValidationRunID,
    frameNumber: FrameNumber,
    microsecondOffsetFromStart: number,
    classId: ClassifyingDetectorClassTypeNumber,
    clockwise: boolean,
    plate: string,
    anprVehicleClass?: ClassifyingDetectorClassTypeNumber
  ) {
    let targetFrameNumber = frameNumber;
    while (
      this.countlineValidationRuns
        .get(countlineValidationRunID)
        ?.validationRunCountlineCrossings?.has(targetFrameNumber)
    ) {
      targetFrameNumber += 1;
    }
    // first saves the crossing with saved as undefined
    const crossing: ValidationRunCountlineCrossing = {
      actualFrameNumber: frameNumber,
      frameNumber: targetFrameNumber,
      crossingTime: microsecondOffsetFromStart,
      detectionClassV2Id: classId,
      clockwise,
      plate,
      anprVehicleClass,
      saved: undefined,
      createdByUserEmail: undefined,
      createdByUserId: undefined,
    };
    this.setValidationRunCountlineCrossing(countlineValidationRunID, targetFrameNumber, crossing);
    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.EDIT_VALIDATION_RUN_CROSSING) +
          "?" +
          new URLSearchParams({
            validation_run_id: countlineValidationRunID.split("-")[0],
            image_space_countline_id: countlineValidationRunID.split("-")[1],
            frame_number: targetFrameNumber.toString(10),
            microsecond_offset_from_start: microsecondOffsetFromStart.toString(10),
            detection_class_v2_id: classId.toString(10),
            clockwise: String(clockwise),
            plate: crossing.plate,
            anpr_vehicle_class: anprVehicleClass?.toString(10) ?? "",
          }),
        {
          method: "POST",
        }
      )
      .then(
        action(response => {
          if (response.status === 200) {
            this.setValidationRunCountlineCrossing(countlineValidationRunID, targetFrameNumber, {
              ...crossing,
              saved: true,
            });
          } else {
            this.setValidationRunCountlineCrossing(countlineValidationRunID, targetFrameNumber, {
              ...crossing,
              saved: false,
            });
          }
        })
      )
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors(
            "Could not addCountlineValidationCrossing(): " + errString(err)
          )
        )
      );
  }

  @action
  async deleteCountlineValidationCrossing(
    countlineValidationRunID: CountlineValidationRunID,
    frameNumber: FrameNumber
  ) {
    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.EDIT_VALIDATION_RUN_CROSSING) +
          "?" +
          new URLSearchParams({
            validation_run_id: countlineValidationRunID.split("-")[0],
            image_space_countline_id: countlineValidationRunID.split("-")[1],
            frame_number: frameNumber.toString(10),
          }),
        {
          method: "DELETE",
        }
      )
      .then(
        action(response => {
          if (response.status === 200) {
            this.deleteValidationRunCountlineCrossing(countlineValidationRunID, frameNumber);
          }
        })
      )
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors(
            "Could not deleteCountlineValidationCrossing(): " + errString(err)
          )
        )
      );
  }

  async updateValidationRunNote(id: CountlineValidationRunID, notesText: string) {
    const countlineValidationRun = this.countlineValidationRuns.get(id);
    if (!countlineValidationRun) {
      return;
    }
    const [validationRunID, countlineId] = id.split("-");
    await this.rootStore.apiStore
      .authenticatedFetch(
        `${getApiEndpoint(ApiEndpointPath.NOTES)}?${new URLSearchParams({
          validation_run_id: validationRunID,
          image_space_countline_id: countlineId,
        })}`,
        {
          method: "POST",
          body: notesText,
        }
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(data => {
        const newNotes = assertCountlineValidationNotesHydrationHydration(data);
        action(() => {
          countlineValidationRun.notes = newNotes;
          this.failedToSaveMessage = "";
        })();
      })
      .catch(
        action((err: Error) => {
          this.failedToSaveMessage = errString(err);
        })
      );
  }

  @computed
  get incompleteCountlineValidationRuns() {
    const incompleteCountlineValidationRunIds: string[] = [];
    this.countlineValidationRuns.forEach(countlineValidationRun => {
      if (countlineValidationRun.status === "IN_PROGRESS" || countlineValidationRun.status === "PENDING") {
        incompleteCountlineValidationRunIds.push(countlineValidationRun.id);
      }
    });
    return incompleteCountlineValidationRunIds;
  }

  @action
  async updateCountlineValidationRunStatus(
    validationRunID: ValidationRunID,
    countlineID: CountlineID,
    passed: boolean
  ) {
    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.COMPLETE_COUNTLINE_VALIDATION) +
          "?" +
          new URLSearchParams({
            validation_run_id: `${validationRunID}`,
            image_space_countline_id: `${countlineID}`,
            passed: `${passed}`,
          }),
        {
          method: "POST",
        }
      )
      .then(
        action(response => {
          if (response.status === 200) {
            const countlineValidationRun = this.countlineValidationRuns.get(`${validationRunID}-${countlineID}`);
            if (countlineValidationRun) {
              countlineValidationRun.passed = passed;
              countlineValidationRun.status = "COMPLETED";
            }
          }
        })
      )
      .catch(e => console.log(e)); // TODO: what's meant to be going on with errors?
  }

  @action
  async reopenCountlineValidationRun(validationRunID: ValidationRunID, countlineID: CountlineID) {
    return this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.REOPEN_COUNTLINE_VALIDATION) +
          "?" +
          new URLSearchParams({
            validation_run_id: `${validationRunID}`,
            image_space_countline_id: `${countlineID}`,
          }),
        {
          method: "PUT",
        }
      )
      .then(
        action(response => {
          if (response.status === 200) {
            const countlineValidationRun = this.countlineValidationRuns.get(`${validationRunID}-${countlineID}`);
            if (countlineValidationRun) {
              countlineValidationRun.status = "IN_PROGRESS";
            }
          }
        })
      )
      .catch(e => {
        console.log(e); // TODO: what's meant to be going on with errors?
      });
  }
}
