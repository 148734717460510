import { createEnumParam, NumberParam, SetQuery, StringParam, withDefault } from "use-query-params";
import { ValidationMode } from "../../enums";

export const ValidationModeParam = withDefault(
  createEnumParam(Object.values(ValidationMode)),
  ValidationMode.CountlineValidation
);

export type QueryParams = {
  selectedVisionProgram: typeof NumberParam;
  selectedVideo: typeof NumberParam;
  selectedComputerVisionRun: typeof NumberParam;
  selectedValidationRun: typeof NumberParam;
  selectedCountline: typeof NumberParam;
  selectedValidationMode: typeof ValidationModeParam;
  search: typeof StringParam;
};

export type SetQueryParams = SetQuery<QueryParams>;
