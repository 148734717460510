import React, { FC, useCallback, useState } from "react";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { VisionProgramTable } from "../components/tables/VisionProgramTable";
import { FilterSearchBar } from "../controllers/FilterSearchBar";
import { useStores } from "../hooks/useStores.hook";
import { Alert, AlertTitle } from "@mui/material";
import { PageHeader } from "../components/pageHeader/PageHeader";
import { useLocation } from "react-router-dom";
import { URL_QUERY_PARAMS } from "../stores/url.store";

export const VisionProgramsLayout: FC<{}> = observer(() => {
  const { entitiesStore } = useStores();
  const { vpidList } = entitiesStore;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get(URL_QUERY_PARAMS.SEARCH);
  const [searchString, setSearchString] = useState<string>(search ?? "");

  const debouncedSetFilter = debounce(setSearchString, 300);
  const onSearchStringChange = useCallback(
    (newSearchString: string): void => {
      debouncedSetFilter(newSearchString);
    },
    [debouncedSetFilter]
  );

  if (!vpidList) {
    return <></>;
  }

  return (
    <>
      {entitiesStore.hydrationErrors !== "" ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {entitiesStore.hydrationErrors}
        </Alert>
      ) : null}
      <PageHeader />
      <div style={{ display: "flex", alignItems: "center" }}>
        <FilterSearchBar
          filter={searchString}
          onFilterChange={onSearchStringChange}
          placeholderText={"Search"}
          defaultFilter=""
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <VisionProgramTable />
      </div>
    </>
  );
});
