import {
  ClassifyingDetectorClassTypes,
  ClassifyingDetectorClassTypesMap,
} from "../vivacity/core/classifying_detector_class_types_pb";
import { BoxDetails, Footprint, FootprintGeometry, FrameInterval, FrameNumber, TrackPair } from "../domain";
import { crc24 } from "crc";
import _, { LoDashStatic } from "lodash";
import { Point } from "../vivacity/core/point_pb";
import { DetectorTrackerFrame } from "../vivacity/core/detector_tracker_frame_pb";
import { TrackHead } from "../vivacity/core/track_head_pb";
import { colourMap } from "./colourMap";
import { errString } from "../stores/utils/errorString";
import { CRCModule } from "crc/mjs/types";
import varint from "varint";
import { transposeMap, transposerFunc } from "../enums/proto.enum";

export type ClassifyingDetectorClassTypeName = keyof ClassifyingDetectorClassTypesMap;
export type ClassifyingDetectorClassTypeNumber = ClassifyingDetectorClassTypesMap[ClassifyingDetectorClassTypeName];
export type ClassNumberToStringLookup = { [key: number]: ClassifyingDetectorClassTypeName };

export const classLookup: ClassNumberToStringLookup = Object.fromEntries(
  Object.entries(ClassifyingDetectorClassTypes).map(([className, enumValue]) => [
    enumValue,
    _.startCase(className.toLowerCase()),
  ])
);

export function normalisePointInPlace(point: Point, frameWidth: number, frameHeight: number): Point {
  point.setX((point.getX() * frameWidth) / 16384);
  point.setY((point.getY() * frameHeight) / 16384);
  return point;
}

export type DisplayFuncs = {
  trackHeadTextFuncParsed: TrackTextFunc;
  trackHeadShouldDisplayFuncParsed: TrackBoolFunc;
  trackHeadShouldDisplayFootprintFuncParsed: TrackBoolFunc;
  trackHeadLineWidthFuncParsed: TrackNumberFunc;
  trackHeadLineColourFuncParsed: TrackNumberFunc;
  trackHeadLineAlphaFuncParsed: TrackNumberFunc;
  trackHeadFillColourFuncParsed: TrackNumberFunc;
  trackHeadFillAlphaFuncParsed: TrackNumberFunc;
  trackHeadFootprintLineWidthFuncParsed: TrackNumberFunc;
  trackHeadFootprintLineColourFuncParsed: TrackNumberFunc;
  trackHeadFootprintLineAlphaFuncParsed: TrackNumberFunc;
  trackHeadFootprintFillColourFuncParsed: TrackNumberFunc;
  trackHeadFootprintFillAlphaFuncParsed: TrackNumberFunc;
  trackHeadTextColourFuncParsed: TrackNumberFunc;
  trackHeadTextAlphaFuncParsed: TrackNumberFunc;
  trackHeadTextSizeFuncParsed: TrackNumberFunc;
  trackHeadTextPositionFuncParsed: TrackNumberFunc;
};

export type DisplayFuncsText = {
  trackHeadTextFunc: string;
  trackHeadShouldDisplayFunc: string;
  trackHeadShouldDisplayFootprintFunc: string;
  trackHeadLineWidthFunc: string;
  trackHeadLineColourFunc: string;
  trackHeadLineAlphaFunc: string;
  trackHeadFillColourFunc: string;
  trackHeadFillAlphaFunc: string;
  trackHeadFootprintLineWidthFunc: string;
  trackHeadFootprintLineColourFunc: string;
  trackHeadFootprintLineAlphaFunc: string;
  trackHeadFootprintFillColourFunc: string;
  trackHeadFootprintFillAlphaFunc: string;
  trackHeadTextColourFunc: string;
  trackHeadTextAlphaFunc: string;
  trackHeadTextSizeFunc: string;
  trackHeadTextPositionFunc: string;
  trackHeadTextExpressionOnly?: boolean;
  trackHeadShouldDisplayExpressionOnly?: boolean;
  trackHeadShouldDisplayFootprintExpressionOnly?: boolean;
  trackHeadLineWidthExpressionOnly?: boolean;
  trackHeadLineColourExpressionOnly?: boolean;
  trackHeadLineAlphaExpressionOnly?: boolean;
  trackHeadFillColourExpressionOnly?: boolean;
  trackHeadFillAlphaExpressionOnly?: boolean;
  trackHeadFootprintLineWidthExpressionOnly?: boolean;
  trackHeadFootprintLineColourExpressionOnly?: boolean;
  trackHeadFootprintLineAlphaExpressionOnly?: boolean;
  trackHeadFootprintFillColourExpressionOnly?: boolean;
  trackHeadFootprintFillAlphaExpressionOnly?: boolean;
  trackHeadTextColourExpressionOnly?: boolean;
  trackHeadTextAlphaExpressionOnly?: boolean;
  trackHeadTextSizeExpressionOnly?: boolean;
  trackHeadTextPositionExpressionOnly?: boolean;
};

export type TrackTextFunc = (
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject,
  classLookup: ClassNumberToStringLookup,
  transposeMap: transposerFunc,
  proto: object,
  _: LoDashStatic
) => string;

export type TrackNumberFunc = (
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject,
  classLookup: ClassNumberToStringLookup,
  transposeMap: transposerFunc,
  proto: object,
  _: LoDashStatic,
  crc24: CRCModule,
  colorMap: { [key: number]: number }
) => number;

export type TrackBoolFunc = (
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject,
  classLookup: ClassNumberToStringLookup,
  transposeMap: transposerFunc,
  proto: object,
  _: LoDashStatic
) => boolean;

export function buildTrackTextFunc(functionText: string, expressionOnly = true): TrackTextFunc {
  let funcText = '"use strict";' + functionText + "";
  if (expressionOnly) {
    funcText = '"use strict";return (' + functionText + ")";
  }
  return Function("trackHead", "dtf", "classLookup", "transposeMap", "proto", "_", funcText) as TrackTextFunc;
}

export function buildTrackNumberFunc(functionText: string, expressionOnly = true): TrackNumberFunc {
  let funcText = '"use strict";' + functionText + "";
  if (expressionOnly) {
    funcText = '"use strict";return (' + functionText + ")";
  }
  return Function(
    "trackHead",
    "dtf",
    "classLookup",
    "transposeMap",
    "proto",
    "_",
    "crc24",
    "colourMap",
    funcText
  ) as TrackNumberFunc;
}

export function buildTrackBoolFunc(functionText: string, expressionOnly = true): TrackBoolFunc {
  let funcText = '"use strict";' + functionText + "";
  if (expressionOnly) {
    funcText = '"use strict";return (' + functionText + ")";
  }
  return Function("trackHead", "dtf", "classLookup", "transposeMap", "proto", "_", funcText) as TrackBoolFunc;
}

export function getTrackNumber(
  func: TrackNumberFunc,
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject
): number {
  try {
    const num = func(trackHead, dtf, classLookup, transposeMap, self.proto, _, crc24, colourMap);
    if (typeof num === "number") {
      return num;
    } else {
      return 0;
    }
  } catch (e) {
    console.error("failed to extract number from trackhead: ", e);
    return 0;
  }
}

export function getTrackBool(
  func: TrackBoolFunc,
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject
): boolean {
  try {
    const bool = func(trackHead, dtf, classLookup, transposeMap, self.proto, _);
    if (typeof bool === "boolean") {
      return bool;
    } else {
      return true;
    }
  } catch (e) {
    console.error("failed to extract boolean from trackhead: ", e);
    return true;
  }
}

export function getTrackText(
  func: TrackTextFunc,
  trackHead: TrackHead.AsObject,
  dtf: DetectorTrackerFrame.AsObject
): string {
  try {
    const text = func(trackHead, dtf, classLookup, transposeMap, self.proto, _);
    if (typeof text === "string") {
      return text;
    } else {
      return JSON.stringify(text);
    }
  } catch (e) {
    return "ERROR: " + errString(e);
  }
}

export function getNextDTF(byteArray: Uint8Array, o: number): [DetectorTrackerFrame, number] {
  const dtfLength = varint.decode(byteArray, o);
  const delimiterLength = varint.decode.bytes;
  let offset = o + delimiterLength;
  const dtf: DetectorTrackerFrame = DetectorTrackerFrame.deserializeBinary(
    byteArray.slice(offset, offset + dtfLength)
  ) as DetectorTrackerFrame;
  offset += dtfLength;
  return [dtf, offset];
}

export function getBoxDetails(
  topLeft: Point,
  bottomRight: Point,
  trackHead: TrackHead,
  dtf: DetectorTrackerFrame,
  displayFuncs: DisplayFuncs,
  footprintGeom?: FootprintGeometry
): BoxDetails {
  let x = 0;
  let y = 0;
  let width = 0;
  let height = 0;

  if (topLeft && bottomRight) {
    x = topLeft.getX();
    y = topLeft.getY();
    width = bottomRight.getX() - x;
    height = bottomRight.getY() - y;
  }

  const trackHeadObject = trackHead.toObject();
  const dtfObject = dtf.toObject();

  let footprint: Footprint | undefined;
  if (footprintGeom) {
    footprint = {
      lineColor: getTrackNumber(displayFuncs.trackHeadFootprintLineColourFuncParsed, trackHeadObject, dtfObject),
      fillColor: getTrackNumber(displayFuncs.trackHeadFootprintFillColourFuncParsed, trackHeadObject, dtfObject),
      fillAlpha: getTrackNumber(displayFuncs.trackHeadFootprintFillAlphaFuncParsed, trackHeadObject, dtfObject),
      lineAlpha: getTrackNumber(displayFuncs.trackHeadFootprintLineAlphaFuncParsed, trackHeadObject, dtfObject),
      lineWidth: getTrackNumber(displayFuncs.trackHeadFootprintLineWidthFuncParsed, trackHeadObject, dtfObject),
      shouldDisplayFootprint: getTrackBool(
        displayFuncs.trackHeadShouldDisplayFootprintFuncParsed,
        trackHeadObject,
        dtfObject
      ),
      ...footprintGeom,
    };
  }
  return {
    shouldDisplay: getTrackBool(displayFuncs.trackHeadShouldDisplayFuncParsed, trackHeadObject, dtfObject),
    x: x,
    y: y,
    width: width,
    height: height,
    text: getTrackText(displayFuncs.trackHeadTextFuncParsed, trackHeadObject, dtfObject),
    lineColor: getTrackNumber(displayFuncs.trackHeadLineColourFuncParsed, trackHeadObject, dtfObject),
    fillColor: getTrackNumber(displayFuncs.trackHeadFillColourFuncParsed, trackHeadObject, dtfObject),
    fillAlpha: getTrackNumber(displayFuncs.trackHeadFillAlphaFuncParsed, trackHeadObject, dtfObject),
    lineAlpha: getTrackNumber(displayFuncs.trackHeadLineAlphaFuncParsed, trackHeadObject, dtfObject),
    lineWidth: getTrackNumber(displayFuncs.trackHeadLineWidthFuncParsed, trackHeadObject, dtfObject),
    textAlpha: getTrackNumber(displayFuncs.trackHeadTextAlphaFuncParsed, trackHeadObject, dtfObject),
    textColour: getTrackNumber(displayFuncs.trackHeadTextColourFuncParsed, trackHeadObject, dtfObject),
    textPosition: getTrackNumber(displayFuncs.trackHeadTextPositionFuncParsed, trackHeadObject, dtfObject),
    textSize: getTrackNumber(displayFuncs.trackHeadTextSizeFuncParsed, trackHeadObject, dtfObject),
    trackID: trackHead.getTrackNumber(),
    footprint: footprint,
  };
}

export const updateNearMissIncidentFrames = (
  trackHead: TrackHead,
  frameNumber: FrameNumber,
  nearMissIncidents: Map<TrackPair, FrameInterval>
) => {
  const metadata = trackHead.getMetadata();
  const nearMisses = metadata?.getNearMissesMap();
  if (!nearMisses) {
    return;
  }
  nearMisses.forEach(nearMiss => {
    const mostCriticalTrackHeadPair = nearMiss.getMostCriticalTrackHeadPair();
    const track1 = mostCriticalTrackHeadPair?.getTrack1()?.getTrackNumber();
    const track2 = mostCriticalTrackHeadPair?.getTrack2()?.getTrackNumber();
    if (!track1 || !track2) {
      return;
    }
    const incidentTrackPair = `${track1}-${track2}`;
    const nearMissIncident = nearMissIncidents.get(incidentTrackPair);
    if (!nearMissIncident) {
      nearMissIncidents.set(incidentTrackPair, {
        start: frameNumber,
        end: frameNumber,
      });
    } else {
      if (nearMissIncident.end < frameNumber) {
        nearMissIncidents.set(incidentTrackPair, { ...nearMissIncident, end: frameNumber });
      }
      if (nearMissIncident.start > frameNumber) {
        nearMissIncidents.set(incidentTrackPair, { ...nearMissIncident, start: frameNumber });
      }
    }
  });
};
