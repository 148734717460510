import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  AlertTitle,
  Alert,
  Switch,
  Slider,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";

import { useStores } from "../../hooks/useStores.hook";
import { TrackHeadDisplayFuncEnum } from "../../domain";

import { FreneticityButton } from "../styledElements";
import MonacoEditor, { EditorWillMount } from "react-monaco-editor";
import { EditorWillUnmount } from "react-monaco-editor/src/types";
import { IDisposable } from "monaco-editor";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import LODASH_index from "!raw-loader!@types/lodash/index.d.ts";
// @ts-ignore
import LODASH_common from "!raw-loader!@types/lodash/common/common.d.ts";
// @ts-ignore
import LODASH_array from "!raw-loader!@types/lodash/common/array.d.ts";
// @ts-ignore
import LODASH_collection from "!raw-loader!@types/lodash/common/collection.d.ts";
// @ts-ignore
import LODASH_date from "!raw-loader!@types/lodash/common/date.d.ts";
// @ts-ignore
import LODASH_function from "!raw-loader!@types/lodash/common/function.d.ts";
// @ts-ignore
import LODASH_lang from "!raw-loader!@types/lodash/common/lang.d.ts";
// @ts-ignore
import LODASH_math from "!raw-loader!@types/lodash/common/math.d.ts";
// @ts-ignore
import LODASH_number from "!raw-loader!@types/lodash/common/number.d.ts";
// @ts-ignore
import LODASH_object from "!raw-loader!@types/lodash/common/object.d.ts";
// @ts-ignore
import LODASH_seq from "!raw-loader!@types/lodash/common/seq.d.ts";
// @ts-ignore
import LODASH_string from "!raw-loader!@types/lodash/common/string.d.ts";
// @ts-ignore
import LODASH_util from "!raw-loader!@types/lodash/common/util.d.ts";
import { classLookup } from "../../workers/utils";
import { colourMap } from "../../workers/colourMap";
import {
  defaultTrackHeadTextFunc,
  trackHeadTextFuncWithClassConfidenceMap,
  nearMissTrackHeadTextFunc,
  dwellTimesTrackHeadTextFunc,
  trackHeadTextFuncWithSpeedFunc,
} from "../../defaultDisplayTextFuncs";
import { snakeCase, startCase } from "lodash";
import { ComputerVisionRun, Video } from "../../interfaces";

interface EditTrackHeadDisplayFuncsProps {
  isOpen: boolean;
  onClose: () => void;
  parseDTFs: (videoWidth: number, videoHeight: number) => void;
  setZoneCountlineGeometry: (cvRun: ComputerVisionRun, video: Video) => void;
  cvRun?: ComputerVisionRun;
  video?: Video;
}

interface Choice {
  editorText: string;
  editorChangeFunc: (val: string) => void;
  saveFunc: () => boolean;
  testFunc: () => [number | boolean | string, string];
  expressionOnly: boolean;
  toggleExpressionOnlyFunc: (val: boolean) => void;
}

export const EditTrackHeadDisplayFuncsModal: FC<EditTrackHeadDisplayFuncsProps> = observer(
  ({ isOpen, onClose, parseDTFs, setZoneCountlineGeometry, cvRun, video }) => {
    const [result, setResult] = useState<number | string | boolean>("");
    const [resultErr, setResultErr] = useState<string>("");
    const [selected, setSelected] = useState<TrackHeadDisplayFuncEnum>(TrackHeadDisplayFuncEnum.TrackText);
    const [trackHeadTextFunc, setTrackHeadTextFunc] = useState<string>(defaultTrackHeadTextFunc);
    const [trackHeadShouldDisplayFunc, setTrackHeadShouldDisplayFunc] = useState<string>("true");
    const [trackHeadShouldDisplayFootprintFunc, setTrackHeadShouldDisplayFootprintFunc] = useState<string>("true");
    const [trackHeadLineWidthFunc, setTrackHeadLineWidthFunc] = useState<string>("2");
    const [trackHeadLineColourFunc, setTrackHeadLineColourFunc] = useState<string>(
      "trackHead.isPredicted ? 0xffffff : trackHead.isStopped ? 0xcc0000 : (colourMap[trackHead.trackClass] ?? crc24(trackHead.trackClass.toString()))"
    );
    const [trackHeadLineAlphaFunc, setTrackHeadLineAlphaFunc] = useState<string>("1");
    const [trackHeadFillColourFunc, setTrackHeadFillColourFunc] = useState<string>(
      "trackHead.isStopped ? 0xcc0000 : (colourMap[trackHead.trackClass] ?? crc24(trackHead.trackClass.toString()))"
    );
    const [trackHeadFillAlphaFunc, setTrackHeadFillAlphaFunc] = useState<string>("0.2");
    const [trackHeadFootprintLineWidthFunc, setTrackHeadFootprintLineWidthFunc] = useState<string>("2");
    const [trackHeadFootprintLineColourFunc, setTrackHeadFootprintLineColourFunc] = useState<string>(
      "trackHead.isPredicted ? 0xffffff : trackHead.isStopped ? 0xcc0000 : (colourMap[trackHead.trackClass] ?? crc24(trackHead.trackClass.toString()))"
    );
    const [trackHeadFootprintLineAlphaFunc, setTrackHeadFootprintLineAlphaFunc] = useState<string>("1");
    const [trackHeadFootprintFillColourFunc, setTrackHeadFootprintFillColourFunc] = useState<string>(
      "trackHeadFootprint.isStopped ? 0xcc0000 : (colourMap[trackHeadFootprint.trackClass] ?? crc24(trackHead.trackClass.toString()))"
    );
    const [trackHeadFootprintFillAlphaFunc, setTrackHeadFootprintFillAlphaFunc] = useState<string>("0.2");
    const [trackHeadTextColourFunc, setTrackHeadTextColourFunc] = useState<string>("0xFFFFFF");
    const [trackHeadTextAlphaFunc, setTrackHeadTextAlphaFunc] = useState<string>("1.0");
    const [trackHeadTextSizeFunc, setTrackHeadTextSizeFunc] = useState<string>("20");
    const [trackHeadTextPositionFunc, setTrackHeadTextPositionFunc] = useState<string>("0");
    const [disposable, setDisposable] = useState<IDisposable>({ dispose: () => {} });
    const { playerUIStore } = useStores();

    useEffect(() => {
      if (playerUIStore.trackHeadDisplayFuncs.trackHeadTextFunc) {
        setTrackHeadTextFunc(playerUIStore.trackHeadDisplayFuncs.trackHeadTextFunc);
      }
    }, [
      playerUIStore.trackHeadDisplayFuncs.trackHeadTextFunc,
      playerUIStore.trackHeadDisplayFuncs.trackHeadTextExpressionOnly,
    ]);

    useEffect(() => {
      if (playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFunc) {
        setTrackHeadShouldDisplayFunc(playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFunc);
      }
    }, [playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFunc]);

    useEffect(() => {
      if (playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintFunc) {
        setTrackHeadShouldDisplayFunc(playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintFunc);
      }
    }, [playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintFunc]);

    const handleSelectTrackHeadTextFunc: (event: SelectChangeEvent<string>, selectedOption: any) => void = (
      _,
      selectedOption
    ) => {
      const selectedFunc = trackHeadTextFuncOptions.find(
        ({ functionName }) => selectedOption.props.value === functionName
      );
      if (!selectedFunc) {
        return;
      }
      setTrackHeadTextFunc(selectedFunc.functionText);
    };

    const editorWillMount: EditorWillMount = ({ editor, languages }) => {
      languages.typescript.typescriptDefaults.setCompilerOptions({
        target: languages.typescript.ScriptTarget.ES2016,
        allowNonTsExtensions: true,
        moduleResolution: languages.typescript.ModuleResolutionKind.NodeJs,
        module: languages.typescript.ModuleKind.CommonJS,
        noEmit: true,
        typeRoots: ["src"],
      });

      const disposables: IDisposable[] = [];

      languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
      });

      disposables.push(languages.typescript.javascriptDefaults.addExtraLib(LODASH_index, "@types/lodash/index.d.ts"));
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_common, "@types/lodash/common/common.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_array, "@types/lodash/common/array.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_collection, "@types/lodash/common/collection.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_date, "@types/lodash/common/date.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_function, "@types/lodash/common/function.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_lang, "@types/lodash/common/lang.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_math, "@types/lodash/common/math.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_number, "@types/lodash/common/number.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_object, "@types/lodash/common/object.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_seq, "@types/lodash/common/seq.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_string, "@types/lodash/common/string.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(LODASH_util, "@types/lodash/common/util.d.ts")
      );
      disposables.push(
        languages.typescript.javascriptDefaults.addExtraLib(
          `
          declare const trackHead = ${JSON.stringify(playerUIStore.lastClickedTrackHead)};
          declare const dtf = ${JSON.stringify(playerUIStore.lastClickedDTF)};
          declare const proto = ${JSON.stringify(window.proto, (key, val) => {
            if (typeof val === "function") {
              return Object.keys(val)
                .filter(k => typeof val[k] === "object")
                .reduce((prev, curr) => {
                  return { ...prev, [curr]: val[curr] };
                }, {});
            }
            return val;
          })};
          declare const classLookup = ${JSON.stringify(classLookup)};
          declare const colourMap = ${JSON.stringify(colourMap)};
          `,
          "file:///src/workers/exampleTrackHead.json"
        )
      );

      setDisposable({
        dispose: () => {
          disposables.map(d => d.dispose());
        },
      });
    };

    const editorWillUnmount: EditorWillUnmount = (editor, monaco) => {
      disposable.dispose();
    };

    const choices: { [key: number]: Choice } = {
      [TrackHeadDisplayFuncEnum.TrackText]: {
        editorText: trackHeadTextFunc,
        editorChangeFunc: setTrackHeadTextFunc,
        saveFunc: () => playerUIStore.setTrackHeadTextFunc(trackHeadTextFunc),
        testFunc: () =>
          playerUIStore.testTextFunc(
            trackHeadTextFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadTextExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadTextExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadTextExpressionOnly(trackHeadTextFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.ShouldDisplay]: {
        editorText: trackHeadShouldDisplayFunc,
        editorChangeFunc: setTrackHeadShouldDisplayFunc,
        saveFunc: () => playerUIStore.setTrackHeadShouldDisplayFunc(trackHeadShouldDisplayFunc),
        testFunc: () =>
          playerUIStore.testBoolFunc(
            trackHeadShouldDisplayFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadShouldDisplayExpressionOnly(trackHeadShouldDisplayFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.LineWidth]: {
        editorText: trackHeadLineWidthFunc,
        editorChangeFunc: setTrackHeadLineWidthFunc,
        saveFunc: () => playerUIStore.setTrackHeadLineWidthFunc(trackHeadLineWidthFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadLineWidthFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadLineWidthExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadLineWidthExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadLineWidthExpressionOnly(trackHeadLineWidthFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.LineColour]: {
        editorText: trackHeadLineColourFunc,
        editorChangeFunc: setTrackHeadLineColourFunc,
        saveFunc: () => playerUIStore.setTrackHeadLineColourFunc(trackHeadLineColourFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadLineColourFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadLineColourExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadLineColourExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadLineColourExpressionOnly(trackHeadLineColourFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.LineAlpha]: {
        editorText: trackHeadLineAlphaFunc,
        editorChangeFunc: setTrackHeadLineAlphaFunc,
        saveFunc: () => playerUIStore.setTrackHeadLineAlphaFunc(trackHeadLineAlphaFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadLineAlphaFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadLineAlphaExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadLineAlphaExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadLineAlphaExpressionOnly(trackHeadLineAlphaFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.FillColour]: {
        editorText: trackHeadFillColourFunc,
        editorChangeFunc: setTrackHeadFillColourFunc,
        saveFunc: () => playerUIStore.setTrackHeadFillColourFunc(trackHeadFillColourFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFillColourFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFillColourExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFillColourExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFillColourExpressionOnly(trackHeadFillColourFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.FillAlpha]: {
        editorText: trackHeadFillAlphaFunc,
        editorChangeFunc: setTrackHeadFillAlphaFunc,
        saveFunc: () => playerUIStore.setTrackHeadFillAlphaFunc(trackHeadFillAlphaFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFillAlphaFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFillAlphaExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFillAlphaExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFillAlphaExpressionOnly(trackHeadFillAlphaFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.ShouldDisplayFootprint]: {
        editorText: trackHeadShouldDisplayFootprintFunc,
        editorChangeFunc: setTrackHeadShouldDisplayFootprintFunc,
        saveFunc: () => playerUIStore.setTrackHeadShouldDisplayFootprintFunc(trackHeadShouldDisplayFootprintFunc),
        testFunc: () =>
          playerUIStore.testBoolFunc(
            trackHeadShouldDisplayFootprintFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadShouldDisplayFootprintExpressionOnly(
            trackHeadShouldDisplayFootprintFunc,
            isExpressionOnly
          ),
      },
      [TrackHeadDisplayFuncEnum.FootprintLineWidth]: {
        editorText: trackHeadFootprintLineWidthFunc,
        editorChangeFunc: setTrackHeadFootprintLineWidthFunc,
        saveFunc: () => playerUIStore.setTrackHeadFootprintLineWidthFunc(trackHeadFootprintLineWidthFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFootprintLineWidthFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineWidthExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineWidthExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFootprintLineWidthExpressionOnly(trackHeadFootprintLineWidthFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.FootprintLineColour]: {
        editorText: trackHeadFootprintLineColourFunc,
        editorChangeFunc: setTrackHeadFootprintLineColourFunc,
        saveFunc: () => playerUIStore.setTrackHeadFootprintLineColourFunc(trackHeadFootprintLineColourFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFootprintLineColourFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineColourExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineColourExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFootprintLineColourExpressionOnly(
            trackHeadFootprintLineColourFunc,
            isExpressionOnly
          ),
      },
      [TrackHeadDisplayFuncEnum.FootprintLineAlpha]: {
        editorText: trackHeadFootprintLineAlphaFunc,
        editorChangeFunc: setTrackHeadFootprintLineAlphaFunc,
        saveFunc: () => playerUIStore.setTrackHeadFootprintLineAlphaFunc(trackHeadFootprintLineAlphaFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFootprintLineAlphaFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineAlphaExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintLineAlphaExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFootprintLineAlphaExpressionOnly(trackHeadFootprintLineAlphaFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.FootprintFillColour]: {
        editorText: trackHeadFootprintFillColourFunc,
        editorChangeFunc: setTrackHeadFootprintFillColourFunc,
        saveFunc: () => playerUIStore.setTrackHeadFootprintFillColourFunc(trackHeadFootprintFillColourFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFootprintFillColourFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintFillColourExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintFillColourExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFootprintFillColourExpressionOnly(
            trackHeadFootprintFillColourFunc,
            isExpressionOnly
          ),
      },
      [TrackHeadDisplayFuncEnum.FootprintFillAlpha]: {
        editorText: trackHeadFootprintFillAlphaFunc,
        editorChangeFunc: setTrackHeadFootprintFillAlphaFunc,
        saveFunc: () => playerUIStore.setTrackHeadFootprintFillAlphaFunc(trackHeadFootprintFillAlphaFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadFootprintFillAlphaFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintFillAlphaExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadFootprintFillAlphaExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadFootprintFillAlphaExpressionOnly(trackHeadFootprintFillAlphaFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.TextColour]: {
        editorText: trackHeadTextColourFunc,
        editorChangeFunc: setTrackHeadTextColourFunc,
        saveFunc: () => playerUIStore.setTrackHeadTextColourFunc(trackHeadTextColourFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadTextColourFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadTextColourExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadTextColourExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadTextColourExpressionOnly(trackHeadTextColourFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.TextAlpha]: {
        editorText: trackHeadTextAlphaFunc,
        editorChangeFunc: setTrackHeadTextAlphaFunc,
        saveFunc: () => playerUIStore.setTrackHeadTextAlphaFunc(trackHeadTextAlphaFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadTextAlphaFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadTextAlphaExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadTextAlphaExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadTextAlphaExpressionOnly(trackHeadTextAlphaFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.TextSize]: {
        editorText: trackHeadTextSizeFunc,
        editorChangeFunc: setTrackHeadTextSizeFunc,
        saveFunc: () => playerUIStore.setTrackHeadTextSizeFunc(trackHeadTextSizeFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadTextSizeFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadTextSizeExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadTextSizeExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadTextSizeExpressionOnly(trackHeadTextSizeFunc, isExpressionOnly),
      },
      [TrackHeadDisplayFuncEnum.TextPosition]: {
        editorText: trackHeadTextPositionFunc,
        editorChangeFunc: setTrackHeadTextPositionFunc,
        saveFunc: () => playerUIStore.setTrackHeadTextPositionFunc(trackHeadTextPositionFunc),
        testFunc: () =>
          playerUIStore.testNumberFunc(
            trackHeadTextPositionFunc,
            playerUIStore.trackHeadDisplayFuncs.trackHeadTextPositionExpressionOnly ?? false
          ),
        expressionOnly: playerUIStore.trackHeadDisplayFuncs.trackHeadTextPositionExpressionOnly ?? false,
        toggleExpressionOnlyFunc: isExpressionOnly =>
          playerUIStore.setTrackHeadTextPositionExpressionOnly(trackHeadTextPositionFunc, isExpressionOnly),
      },
    };

    const trackHeadTextFuncOptions = [
      { functionName: "class_only", functionText: defaultTrackHeadTextFunc, evaluateAsExpression: true },
      {
        functionName: "class_and_class_confidence_map",
        functionText: trackHeadTextFuncWithClassConfidenceMap,
        evaluateAsExpression: true,
      },
      {
        functionName: "near_miss_incidents_only",
        functionText: nearMissTrackHeadTextFunc,
        evaluateAsExpression: false,
      },
      { functionName: "dwell_times", functionText: dwellTimesTrackHeadTextFunc, evaluateAsExpression: false },
      {
        functionName: "class_and_vehicle_speed",
        functionText: trackHeadTextFuncWithSpeedFunc,
        evaluateAsExpression: true,
      },
    ];

    const selectedTrackHeadTextFuncOption =
      trackHeadTextFuncOptions.find(({ functionText }) => functionText === trackHeadTextFunc)?.functionName || "";

    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle variant={"h6"}>Edit Track Head Display Parameters</DialogTitle>

        <DialogContent>
          <StyledFormControl>
            <Typography variant="body2" color="text.secondary" style={{ paddingBottom: "10px" }}>
              Video Blur
            </Typography>
            <Slider
              min={0.0}
              max={20}
              step={0.02}
              style={{ paddingTop: "50px" }}
              value={playerUIStore.videoBlur}
              defaultValue={0}
              aria-label="Video Blur"
              valueLabelDisplay="on"
              valueLabelFormat={value => {
                return value.toFixed(2) + "x";
              }}
              onChange={(e, newValue) => {
                playerUIStore.setVideoBlur(newValue as number);
              }}
              onKeyDown={e => e.preventDefault()}
            />
            <Typography variant="body2" color="text.secondary" style={{ paddingBottom: "10px" }}>
              Running Totals Text Alpha
            </Typography>
            <Slider
              min={0.0}
              max={1}
              step={0.005}
              style={{ paddingTop: "20px" }}
              value={playerUIStore.runningTotalsTextAlpha}
              defaultValue={0.75}
              aria-label="Running Totals Text Alpha"
              valueLabelDisplay="on"
              valueLabelFormat={value => {
                return (value * 100).toFixed(0) + "%";
              }}
              onChange={(e, newValue) => {
                playerUIStore.setRunningTotalsTextAlpha(newValue as number);
              }}
              onKeyDown={e => e.preventDefault()}
            />
            Use 3d Pose Ground Centre for track tails
            <Switch
              checked={playerUIStore.usePoseGroundCentre}
              onChange={(e, val) => playerUIStore.setUsePoseGroundCentre(val)}
              inputProps={{ "aria-label": "controlled" }}
            />
            Force 1920x1080 drawing resolution
            <Switch
              checked={playerUIStore.use1920x1080}
              onChange={(e, val) => playerUIStore.setUse1920x1080(val)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <ToggleButtonGroup
              style={{ flexWrap: "wrap", justifyContent: "center", scale: 0.8 }}
              value={selected}
              size={"small"}
              color="primary"
              exclusive
              onChange={(e, v) => (v ? setSelected(v) : setSelected(TrackHeadDisplayFuncEnum.TrackText))}
            >
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.TrackText}>
                Track Text
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.ShouldDisplay}>
                Should Display Box/Text?
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.LineWidth}>
                Line Width
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.LineColour}>
                Line Colour
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.LineAlpha}>
                Line Alpha
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FillColour}>
                Fill Colour
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FillAlpha}>
                Fill Alpha
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.ShouldDisplayFootprint}>
                Should Display Footprint?
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FootprintLineWidth}>
                Footprint Line Width
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FootprintLineColour}>
                Footprint Line Colour
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FootprintLineAlpha}>
                Footprint Line Alpha
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FootprintFillColour}>
                Footprint Fill Colour
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.FootprintFillAlpha}>
                Footprint Fill Alpha
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.TextColour}>
                Text Colour
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.TextAlpha}>
                Text Alpha
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.TextSize}>
                Text Size
              </ToggleButton>
              <ToggleButton style={{ maxWidth: "90px" }} value={TrackHeadDisplayFuncEnum.TextPosition}>
                Text Position
              </ToggleButton>
            </ToggleButtonGroup>
            {selected === TrackHeadDisplayFuncEnum.TrackText && (
              <FormControl style={{ width: "40%", marginTop: 12, marginBottom: 6 }}>
                <InputLabel id="input-label" style={{ background: "white" }}>
                  Select from common functions
                </InputLabel>
                <Select
                  labelId="input-label"
                  value={selectedTrackHeadTextFuncOption}
                  onChange={handleSelectTrackHeadTextFunc}
                >
                  {trackHeadTextFuncOptions.map(({ functionName }) => (
                    <MenuItem key={functionName} value={functionName}>
                      {startCase(functionName)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {playerUIStore.getTrackHeadDisplayFuncError(selected) !== "" ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {playerUIStore.getTrackHeadDisplayFuncError(selected)}
              </Alert>
            ) : null}
            <MonacoEditor
              width="100%"
              height={50 + choices[selected].editorText.split(/\n/).length * 20}
              language="javascript"
              theme="vs-dark"
              value={choices[selected].editorText}
              editorWillMount={editorWillMount}
              editorWillUnmount={editorWillUnmount}
              onChange={choices[selected].editorChangeFunc}
            />
            Evaluate as expression (whether the code is wrapped in return( ... ))
            <Switch
              checked={choices[selected].expressionOnly}
              onChange={(e, val) => choices[selected].toggleExpressionOnlyFunc(val)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <FreneticityButton
              color={"info"}
              onClick={() => {
                const [val, err] = choices[selected].testFunc();
                setResult(val);
                setResultErr(err);
              }}
            >
              Test on last clicked box
            </FreneticityButton>
            {resultErr !== "" ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {resultErr}
              </Alert>
            ) : (
              <>
                <Typography style={{ marginTop: "20px" }}>Result</Typography>
                <Typography variant={"body2"} style={{ whiteSpace: "pre-wrap", color: "#666666" }}>
                  {result.toString()}
                </Typography>
              </>
            )}
          </StyledFormControl>
        </DialogContent>
        <DialogActions>
          <FreneticityButton color={"info"} onClick={onClose}>
            Cancel
          </FreneticityButton>
          <FreneticityButton
            onClick={() => {
              const saved = choices[selected].saveFunc();
              if (saved && !playerUIStore.hasTrackHeadDisplayFuncError) {
                if (cvRun && video) {
                  setZoneCountlineGeometry(cvRun, video);
                }
                parseDTFs(
                  playerUIStore.use1920x1080 ? 1920 : playerUIStore.videoWidth,
                  playerUIStore.use1920x1080 ? 1080 : playerUIStore.videoHeight
                );
              }
            }}
          >
            Save
          </FreneticityButton>
        </DialogActions>
      </Dialog>
    );
  }
);

const StyledFormControl = styled(FormControl)({
  width: "100%",
});
