/* eslint-disable */
// source: vivacity/core/scene_capture_trigger_reasons.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_countline_crossing_pb = require('../../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
goog.exportSymbol('proto.vivacity.core.ClassTriggeredSceneCaptureEvent', null, global);
goog.exportSymbol('proto.vivacity.core.ClassTriggeredSceneCaptureEvents', null, global);
goog.exportSymbol('proto.vivacity.core.SceneCaptureTriggerReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ClassTriggeredSceneCaptureEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassTriggeredSceneCaptureEvent.displayName = 'proto.vivacity.core.ClassTriggeredSceneCaptureEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.ClassTriggeredSceneCaptureEvents.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.ClassTriggeredSceneCaptureEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassTriggeredSceneCaptureEvents.displayName = 'proto.vivacity.core.ClassTriggeredSceneCaptureEvents';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassTriggeredSceneCaptureEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    crossing: (f = msg.getCrossing()) && vivacity_core_countline_crossing_pb.CountlineCrossing.toObject(includeInstance, f),
    zoneId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trackId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    imageWidth: jspb.Message.getFieldWithDefault(msg, 6, 0),
    imageHeight: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassTriggeredSceneCaptureEvent;
  return proto.vivacity.core.ClassTriggeredSceneCaptureEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 3:
      var value = new vivacity_core_countline_crossing_pb.CountlineCrossing;
      reader.readMessage(value,vivacity_core_countline_crossing_pb.CountlineCrossing.deserializeBinaryFromReader);
      msg.setCrossing(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageWidth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassTriggeredSceneCaptureEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCrossing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_countline_crossing_pb.CountlineCrossing.serializeBinaryToWriter
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTrackId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getImageWidth();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getImageHeight();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SceneCaptureTriggerReason reason = 2;
 * @return {!proto.vivacity.core.SceneCaptureTriggerReason}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getReason = function() {
  return /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.SceneCaptureTriggerReason} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional CountlineCrossing crossing = 3;
 * @return {?proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getCrossing = function() {
  return /** @type{?proto.vivacity.core.CountlineCrossing} */ (
    jspb.Message.getWrapperField(this, vivacity_core_countline_crossing_pb.CountlineCrossing, 3));
};


/**
 * @param {?proto.vivacity.core.CountlineCrossing|undefined} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
*/
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setCrossing = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.clearCrossing = function() {
  return this.setCrossing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.hasCrossing = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 zone_id = 4;
 * @return {number}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 track_id = 5;
 * @return {number}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getTrackId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 image_width = 6;
 * @return {number}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getImageWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setImageWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 image_height = 7;
 * @return {number}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.getImageHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvent.prototype.setImageHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassTriggeredSceneCaptureEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonsList: jspb.Message.toObjectList(msg.getReasonsList(),
    proto.vivacity.core.ClassTriggeredSceneCaptureEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassTriggeredSceneCaptureEvents;
  return proto.vivacity.core.ClassTriggeredSceneCaptureEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.ClassTriggeredSceneCaptureEvent;
      reader.readMessage(value,proto.vivacity.core.ClassTriggeredSceneCaptureEvent.deserializeBinaryFromReader);
      msg.addReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassTriggeredSceneCaptureEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.ClassTriggeredSceneCaptureEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClassTriggeredSceneCaptureEvent reasons = 1;
 * @return {!Array<!proto.vivacity.core.ClassTriggeredSceneCaptureEvent>}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.getReasonsList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassTriggeredSceneCaptureEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ClassTriggeredSceneCaptureEvent, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassTriggeredSceneCaptureEvent>} value
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents} returns this
*/
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.setReasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvent}
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.addReasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.ClassTriggeredSceneCaptureEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ClassTriggeredSceneCaptureEvents} returns this
 */
proto.vivacity.core.ClassTriggeredSceneCaptureEvents.prototype.clearReasonsList = function() {
  return this.setReasonsList([]);
};


/**
 * @enum {number}
 */
proto.vivacity.core.SceneCaptureTriggerReason = {
  UNKNOWN_TRIGGER_REASON: 0,
  MANUAL_TRIGGER_DELIVERY_VALIDATION: 1,
  MANUAL_TRIGGER_LABELLING: 2,
  MANUAL_TRIGGER_RESEARCH_MISC: 3,
  NEAR_MISS: 4,
  TARGET_CLASS_IN_FRAME: 5,
  TARGET_CLASS_IN_ZONE: 6,
  TARGET_CLASS_CROSSING: 7,
  TRACK_CLASS_FRAME_CLASS_MISMATCH: 8,
  OBJECT_TRACKED_BUT_NOT_DETECTED: 9,
  NEAR_MISS_VALIDATION: 10,
  IMAGE_DEGRADATION_GLARE: 11,
  IMAGE_DEGRADATION_SENSOR_SLIP: 12,
  NEAR_MISS_LOW_RES: 13
};

goog.object.extend(exports, proto.vivacity.core);
