/* eslint-disable */
import React, { CSSProperties, RefObject } from "react";
import * as PIXI from "pixi.js";
import { Container, Stage, StagePropsWithOptions } from "react-pixi-fiber/index";
import Hammer from "react-hammerjs";
import update from "immutability-helper";
import ReactPlayer from "react-player";

type ZoomableStageProps = StagePropsWithOptions & {
  initialScale?: number;
  onMouseDown?: (point: PIXI.Point) => void;
  onMouseUp?: (point: PIXI.Point) => void;
  videoRef?: RefObject<ReactPlayer>;
  stageRef?: RefObject<Stage>;
  style: CSSProperties;
  zoomEnabled?: boolean;
};

type ZoomableStageState = {
  pivot: PIXI.Point;
  scale: number;
  x: number;
  y: number;
  lastMouse: PIXI.Point;
};

class ZoomableStage extends React.Component<ZoomableStageProps, ZoomableStageState> {
  private lastPanDelta = { x: 0, y: 0 };
  private lastScale = 1;
  private containerRef = React.createRef<PIXI.Container & PIXI.DisplayObject>();

  static getDerivedStateFromProps(props: ZoomableStageProps, currentState: ZoomableStageState) {
    if (!props.zoomEnabled) {
      return {
        pivot: new PIXI.Point(0, 0),
        scale: props.initialScale ? props.initialScale : 1,
        x: 0,
        y: 0,
        lastMouse: new PIXI.Point(0, 0),
      };
    }
    return null;
  }

  constructor(props: ZoomableStageProps) {
    super(props);
    this.state = {
      pivot: new PIXI.Point(0, 0),
      scale: this.props.initialScale ? this.props.initialScale : 1,
      x: 0,
      y: 0,
      lastMouse: new PIXI.Point(0, 0),
    };
  }

  handlePinch(e: HammerInput) {
    if (e.type === "pinchstart") {
      this.lastScale = e.scale;
      this.lastPanDelta = { x: e.deltaX, y: e.deltaY };
      return;
    }

    const { x: lastPanX, y: lastPanY } = this.lastPanDelta;
    this.setState(oldState => {
      return update(oldState, {
        $merge: {
          x: oldState.x + e.deltaX - lastPanX,
          y: oldState.y + e.deltaY - lastPanY,
          scale: oldState.scale * (e.scale / this.lastScale),
        },
      });
    });
    this.lastScale = e.scale;
    this.lastPanDelta = { x: e.deltaX, y: e.deltaY };
  }

  handlePan(e: HammerInput) {
    if (!this.props.zoomEnabled) {
      return;
    }
    if (e.type === "panstart") {
      this.lastPanDelta = { x: 0, y: 0 };
      if (this.containerRef.current !== null) {
        const pivot = this.containerRef.current.toLocal(e.center as PIXI.Point);
        const offset = this.containerRef.current.toGlobal(this.state.pivot);
        this.setState(oldState => {
          return update(oldState, {
            $merge: {
              x: oldState.x - (offset.x - e.center.x),
              y: oldState.y - (offset.y - e.center.y),
              pivot: pivot as PIXI.Point,
            },
          });
        });
      }
    }
    const { x: lastPanX, y: lastPanY } = this.lastPanDelta;
    this.setState(oldState => {
      return update(oldState, {
        $merge: {
          x: oldState.x + e.deltaX - lastPanX,
          y: oldState.y + e.deltaY - lastPanY,
        },
      });
    });
    this.lastPanDelta = { x: e.deltaX, y: e.deltaY };
  }

  handleWheel(e: React.WheelEvent) {
    if (!this.props.zoomEnabled) {
      return;
    }
    e.persist();
    if (this.containerRef.current !== null) {
      const offset = this.containerRef.current.toGlobal(this.state.pivot);
      this.setState(oldState => {
        if (this.containerRef.current !== null) {
          return update(oldState, {
            $merge: {
              x: oldState.x - (offset.x - oldState.lastMouse.x),
              y: oldState.y - (offset.y - oldState.lastMouse.y),
              scale: oldState.scale * (1 - e.deltaY / 1000),
              pivot: this.containerRef.current.toLocal(oldState.lastMouse) as PIXI.Point,
            },
          });
        } else {
          return update(oldState, { $merge: {} });
        }
      });
    }
  }

  handleMouseMove(e: PIXI.InteractionEvent) {
    this.setState(
      update(this.state, {
        $merge: {
          lastMouse: e.data.global,
        },
      })
    );
  }

  handleMouseDown(e: PIXI.InteractionEvent) {
    if (!this.props.zoomEnabled) {
      return;
    }
    if (this.props.onMouseDown) {
      if (this.containerRef.current !== null) {
        const point = this.containerRef.current.toLocal(new PIXI.Point(e.data.global.x, e.data.global.y));
        this.props.onMouseDown(point as PIXI.Point);
      }
    }
    e.stopPropagation();
  }

  handleMouseUp(e: PIXI.InteractionEvent) {
    if (!this.props.zoomEnabled) {
      return;
    }
    if (this.props.onMouseUp) {
      if (this.containerRef.current !== null) {
        const point = this.containerRef.current.toLocal(new PIXI.Point(e.data.global.x, e.data.global.y));
        this.props.onMouseUp(point as PIXI.Point);
      }
    }
    e.stopPropagation();
  }

  render() {
    const { initialScale, onMouseDown, onMouseUp, videoRef, zoomEnabled, stageRef, ...stageProps } = this.props;

    let children = React.Children.toArray(this.props.children).map(child => {
      return React.cloneElement(child as any, { videoRef });
    });

    return (
      <Hammer
        onPinch={this.handlePinch.bind(this)}
        onPinchStart={this.handlePinch.bind(this)}
        onPan={this.handlePan.bind(this)}
        onPanStart={this.handlePan.bind(this)}
        onPanEnd={this.handlePan.bind(this)}
        options={{
          recognizers: {
            pinch: { enable: true },
            rotate: { enable: true },
          },
        }}
      >
        <div
          onWheel={this.handleWheel.bind(this)}
          onMouseOver={() => {
            if (!!this.props.zoomEnabled) {
              // This prevents the page from scrolling while the mouse is inside this div (to allow us to capture scroll for zooming)
              document.body.style.overflow = "hidden";
            }
          }}
          onMouseOut={() => {
            if (!!this.props.zoomEnabled) {
              document.body.style.overflow = "auto";
            }
          }}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          <Stage {...stageProps} ref={stageRef}>
            <Container
              x={this.state.x}
              y={this.state.y}
              scale={new PIXI.Point(this.state.scale, this.state.scale)}
              ref={this.containerRef}
              pivot={this.state.pivot}
              interactive
              mousemove={this.handleMouseMove.bind(this)}
              mousedown={this.handleMouseDown.bind(this)}
              mouseup={this.handleMouseUp.bind(this)}
            >
              {children}
            </Container>
          </Stage>
        </div>
      </Hammer>
    );
  }
}

export default ZoomableStage;
