import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertTitle,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";

import { FreneticityButton } from "../styledElements";
import { NEW_CV_RUN_EVENT, NewCVRunEvent } from "../../stores/cvRun.store";
import MonacoEditor from "react-monaco-editor/lib/editor";
import { useStores } from "../../hooks/useStores.hook";
import { EntitiesStore } from "../../stores/entities.store";

interface NewCVRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoId: number | undefined;
}

const handleNewCVRun = (
  entitiesStore: EntitiesStore,
  isCustomConfig: boolean,
  { videoId, supermarioImage, supermarioValuesTemplatedYaml }: NewCVRunEvent
) => {
  const videoDetails = entitiesStore.videos.find(video => {
    return video.id === videoId;
  });

  if (!videoDetails) {
    console.error(`Error: Could not find Video ID ${videoId}`);
    return;
  }

  if (videoDetails.status !== "UPLOADING_COMPLETE") {
    console.error(`Error: Unable to start new CV run for ${videoDetails.id} with status: ${videoDetails.status}`);
    return;
  }

  const newValidationModalEvent: NewCVRunEvent = {
    videoId: videoId,
    supermarioValuesTemplatedYaml: isCustomConfig ? supermarioValuesTemplatedYaml : undefined,
    supermarioImage: isCustomConfig ? supermarioImage : undefined,
  };
  const event = new CustomEvent(NEW_CV_RUN_EVENT, { detail: newValidationModalEvent });
  window.dispatchEvent(event);
};

export const NewCVRunModal: FC<NewCVRunModalProps> = observer(({ isOpen, onClose, videoId }) => {
  const { entitiesStore } = useStores();
  const [isCustomConfig, setIsCustomConfig] = useState(false);
  const [customConfig, setCustomConfig] = useState("");
  const [customSupermarioImage, setCustomSupermarioImage] = useState("");

  const clearCustomFields = () => {
    setCustomConfig("");
    setCustomSupermarioImage("");
  };

  const clearOnClose = () => {
    clearCustomFields();
    setIsCustomConfig(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      {videoId ? (
        <>
          <DialogTitle>Video {videoId}: New CV Run</DialogTitle>
          <DialogContent>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCustomConfig}
                    onChange={() => {
                      setIsCustomConfig(!isCustomConfig);
                      clearCustomFields();
                    }}
                  />
                }
                label="Use custom Supermario Image & Values"
              />
            </Stack>
            {isCustomConfig ? (
              <Stack direction={"column"} justifyContent="left" style={{ marginTop: 32 }}>
                <TextField
                  label="Supermario Image"
                  multiline
                  value={customSupermarioImage}
                  fullWidth
                  onChange={e => setCustomSupermarioImage(e.target.value)}
                />
                <Stack direction={"column"} justifyContent="left" style={{ marginTop: 32 }}>
                  Supermario Values:
                  <MonacoEditor
                    width="100%"
                    height={600}
                    language="yaml"
                    theme="vs-dark"
                    value={customConfig}
                    onChange={value => setCustomConfig(value)}
                  />
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <FreneticityButton
              disabled={isCustomConfig && (customConfig === "" || customSupermarioImage === "")}
              onClick={() => {
                handleNewCVRun(entitiesStore, isCustomConfig, {
                  videoId,
                  supermarioImage: customSupermarioImage,
                  supermarioValuesTemplatedYaml: customConfig,
                });
                clearOnClose();
              }}
            >
              New CV Run
            </FreneticityButton>
            <FreneticityButton onClick={clearOnClose}>Close</FreneticityButton>
          </DialogActions>
        </>
      ) : (
        <>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Selected Video is Undefined
          </Alert>
          <DialogTitle>Error: selectedVideo not defined</DialogTitle>
        </>
      )}
    </Dialog>
  );
});
