/* eslint-disable */
// source: vivacity/config/config_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_zone_config_pb = require('../../vivacity/config/zone_config_pb.js');
goog.object.extend(proto, vivacity_config_zone_config_pb);
var vivacity_config_countline_config_pb = require('../../vivacity/config/countline_config_pb.js');
goog.object.extend(proto, vivacity_config_countline_config_pb);
var vivacity_config_hardware_pb = require('../../vivacity/config/hardware_pb.js');
goog.object.extend(proto, vivacity_config_hardware_pb);
var vivacity_config_vault_pb = require('../../vivacity/config/vault_pb.js');
goog.object.extend(proto, vivacity_config_vault_pb);
var vivacity_config_vision_program_modification_pb = require('../../vivacity/config/vision_program_modification_pb.js');
goog.object.extend(proto, vivacity_config_vision_program_modification_pb);
var vivacity_config_perspective_transform_pb = require('../../vivacity/config/perspective_transform_pb.js');
goog.object.extend(proto, vivacity_config_perspective_transform_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_config_file_templates_pb = require('../../vivacity/config/file_templates_pb.js');
goog.object.extend(proto, vivacity_config_file_templates_pb);
var vivacity_config_alerts_config_pb = require('../../vivacity/config/alerts_config_pb.js');
goog.object.extend(proto, vivacity_config_alerts_config_pb);
var vivacity_config_agent_config_pb = require('../../vivacity/config/agent_config_pb.js');
goog.object.extend(proto, vivacity_config_agent_config_pb);
var vivacity_config_controller_config_pb = require('../../vivacity/config/controller_config_pb.js');
goog.object.extend(proto, vivacity_config_controller_config_pb);
var vivacity_config_region_config_pb = require('../../vivacity/config/region_config_pb.js');
goog.object.extend(proto, vivacity_config_region_config_pb);
var vivacity_config_instation_config_pb = require('../../vivacity/config/instation_config_pb.js');
goog.object.extend(proto, vivacity_config_instation_config_pb);
var vivacity_config_cv_model_config_pb = require('../../vivacity/config/cv_model_config_pb.js');
goog.object.extend(proto, vivacity_config_cv_model_config_pb);
var vivacity_config_viu_config_pb = require('../../vivacity/config/viu_config_pb.js');
goog.object.extend(proto, vivacity_config_viu_config_pb);
var vivacity_config_cronjob_pb = require('../../vivacity/config/cronjob_pb.js');
goog.object.extend(proto, vivacity_config_cronjob_pb);
var vivacity_config_entity_groups_pb = require('../../vivacity/config/entity_groups_pb.js');
goog.object.extend(proto, vivacity_config_entity_groups_pb);
var vivacity_config_dynniq_message_s4tc_pb = require('../../vivacity/config/dynniq_message_s4tc_pb.js');
goog.object.extend(proto, vivacity_config_dynniq_message_s4tc_pb);
var vivacity_config_entity_templates_pb = require('../../vivacity/config/entity_templates_pb.js');
goog.object.extend(proto, vivacity_config_entity_templates_pb);
var vivacity_config_contracted_hardware_pb = require('../../vivacity/config/contracted_hardware_pb.js');
goog.object.extend(proto, vivacity_config_contracted_hardware_pb);
var vivacity_config_hardware_position_pb = require('../../vivacity/config/hardware_position_pb.js');
goog.object.extend(proto, vivacity_config_hardware_position_pb);
var vivacity_config_hardware_position_drawing_pb = require('../../vivacity/config/hardware_position_drawing_pb.js');
goog.object.extend(proto, vivacity_config_hardware_position_drawing_pb);
var vivacity_config_v2_crud_operation_message_pb = require('../../vivacity/config_v2/crud_operation_message_pb.js');
goog.object.extend(proto, vivacity_config_v2_crud_operation_message_pb);
var vivacity_benchmarking_cv_model_pb = require('../../vivacity/benchmarking/cv_model_pb.js');
goog.object.extend(proto, vivacity_benchmarking_cv_model_pb);
var vivacity_benchmarking_benchmark_run_pb = require('../../vivacity/benchmarking/benchmark_run_pb.js');
goog.object.extend(proto, vivacity_benchmarking_benchmark_run_pb);
var vivacity_command_docker_pb = require('../../vivacity/command/docker_pb.js');
goog.object.extend(proto, vivacity_command_docker_pb);
var vivacity_command_service_pb = require('../../vivacity/command/service_pb.js');
goog.object.extend(proto, vivacity_command_service_pb);
var vivacity_command_shell_pb = require('../../vivacity/command/shell_pb.js');
goog.object.extend(proto, vivacity_command_shell_pb);
var vivacity_command_file_pb = require('../../vivacity/command/file_pb.js');
goog.object.extend(proto, vivacity_command_file_pb);
var vivacity_command_hwman_pb = require('../../vivacity/command/hwman_pb.js');
goog.object.extend(proto, vivacity_command_hwman_pb);
var vivacity_command_at_command_pb = require('../../vivacity/command/at_command_pb.js');
goog.object.extend(proto, vivacity_command_at_command_pb);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest.CommandState', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest.ErrorResponseType', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupTemplateMessage', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupTemplateQuery', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.CommandState', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.CommandType', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.ErrorResponseType', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigError', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigError.ErrorType', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigMessage', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigMessage.EventMessageType', null, global);
goog.exportSymbol('proto.vivacity.config.HybridConfigOperation', null, global);
goog.exportSymbol('proto.vivacity.config.HybridConfigOperation.ConfigOperationType', null, global);
goog.exportSymbol('proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference', null, global);
goog.exportSymbol('proto.vivacity.config.HybridTransactionalMessage', null, global);
goog.exportSymbol('proto.vivacity.config.OriginatingSystem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ConfigError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ConfigError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ConfigError.displayName = 'proto.vivacity.config.ConfigError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ConfigMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ConfigMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ConfigMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ConfigMessage.displayName = 'proto.vivacity.config.ConfigMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupTemplateMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupTemplateMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupTemplateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupTemplateMessage.displayName = 'proto.vivacity.config.CommandGroupTemplateMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupTemplateQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupTemplateQuery.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupTemplateQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupTemplateQuery.displayName = 'proto.vivacity.config.CommandGroupTemplateQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupRequest.displayName = 'proto.vivacity.config.CommandGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandRequest.displayName = 'proto.vivacity.config.CommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HybridTransactionalMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.HybridTransactionalMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.HybridTransactionalMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HybridTransactionalMessage.displayName = 'proto.vivacity.config.HybridTransactionalMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HybridConfigOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HybridConfigOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HybridConfigOperation.displayName = 'proto.vivacity.config.HybridConfigOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.displayName = 'proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ConfigError.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ConfigError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ConfigError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigError.toObject = function(includeInstance, msg) {
  var f, obj = {
    originatingMessage: (f = msg.getOriginatingMessage()) && proto.vivacity.config.ConfigMessage.toObject(includeInstance, f),
    errorType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ConfigError}
 */
proto.vivacity.config.ConfigError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ConfigError;
  return proto.vivacity.config.ConfigError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ConfigError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ConfigError}
 */
proto.vivacity.config.ConfigError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.setOriginatingMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.ConfigError.ErrorType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ConfigError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ConfigError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ConfigError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginatingMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ConfigError.ErrorType = {
  UNKNOWN_ERROR_TYPE: 0,
  CLIENT_ERROR: 400,
  INTERNAL_SERVER_ERROR: 500
};

/**
 * optional ConfigMessage originating_message = 1;
 * @return {?proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigError.prototype.getOriginatingMessage = function() {
  return /** @type{?proto.vivacity.config.ConfigMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.ConfigMessage, 1));
};


/**
 * @param {?proto.vivacity.config.ConfigMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigError} returns this
*/
proto.vivacity.config.ConfigError.prototype.setOriginatingMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.clearOriginatingMessage = function() {
  return this.setOriginatingMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigError.prototype.hasOriginatingMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorType error_type = 2;
 * @return {!proto.vivacity.config.ConfigError.ErrorType}
 */
proto.vivacity.config.ConfigError.prototype.getErrorType = function() {
  return /** @type {!proto.vivacity.config.ConfigError.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.ConfigError.ErrorType} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.vivacity.config.ConfigError.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OriginatingSystem originating_system = 4;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.ConfigError.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ConfigMessage.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ConfigMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ConfigMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ConfigMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventMessageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    batchedConfigMessagesList: jspb.Message.toObjectList(msg.getBatchedConfigMessagesList(),
    proto.vivacity.config.ConfigMessage.toObject, includeInstance),
    reducedBatchedConfigMessagesList: jspb.Message.toObjectList(msg.getReducedBatchedConfigMessagesList(),
    proto.vivacity.config.ConfigMessage.toObject, includeInstance),
    changeZoneImageSpace: (f = msg.getChangeZoneImageSpace()) && vivacity_config_zone_config_pb.ZonesConfigChange.toObject(includeInstance, f),
    zoneImageSpaceChanged: (f = msg.getZoneImageSpaceChanged()) && vivacity_config_zone_config_pb.ZonesConfigChange.toObject(includeInstance, f),
    changeCountlineImageSpace: (f = msg.getChangeCountlineImageSpace()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    countlineImageSpaceChanged: (f = msg.getCountlineImageSpaceChanged()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    changePerspectiveTransform: (f = msg.getChangePerspectiveTransform()) && vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.toObject(includeInstance, f),
    perspectiveTransformChanged: (f = msg.getPerspectiveTransformChanged()) && vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.toObject(includeInstance, f),
    changeCountlineRealWorldSpace: (f = msg.getChangeCountlineRealWorldSpace()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    countlineRealWorldSpaceChanged: (f = msg.getCountlineRealWorldSpaceChanged()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    changeZonalAlert: (f = msg.getChangeZonalAlert()) && vivacity_config_alerts_config_pb.ZonalAlertConfigChange.toObject(includeInstance, f),
    zonalAlertChanged: (f = msg.getZonalAlertChanged()) && vivacity_config_alerts_config_pb.ZonalAlertConfigChange.toObject(includeInstance, f),
    getHardwareId: (f = msg.getGetHardwareId()) && vivacity_config_hardware_pb.GetOrCreateHardwareId.toObject(includeInstance, f),
    createHardwareId: (f = msg.getCreateHardwareId()) && vivacity_config_hardware_pb.GetOrCreateHardwareId.toObject(includeInstance, f),
    hardwareIdTokenResponse: (f = msg.getHardwareIdTokenResponse()) && vivacity_config_hardware_pb.HardwareIdTokenResponse.toObject(includeInstance, f),
    hardwareIdCreated: (f = msg.getHardwareIdCreated()) && vivacity_config_hardware_pb.HardwareIdCreated.toObject(includeInstance, f),
    vaultResourceCreated: (f = msg.getVaultResourceCreated()) && vivacity_config_vault_pb.VaultResourceCreated.toObject(includeInstance, f),
    changeHardwareFields: (f = msg.getChangeHardwareFields()) && vivacity_config_hardware_pb.HardwareFields.toObject(includeInstance, f),
    hardwareFieldsChanged: (f = msg.getHardwareFieldsChanged()) && vivacity_config_hardware_pb.HardwareFields.toObject(includeInstance, f),
    createVisionProgram: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    visionProgramCreated: jspb.Message.getFieldWithDefault(msg, 32, 0),
    changeVisionProgramFields: (f = msg.getChangeVisionProgramFields()) && vivacity_config_vision_program_modification_pb.VisionProgramFields.toObject(includeInstance, f),
    visionProgramFieldsChanged: (f = msg.getVisionProgramFieldsChanged()) && vivacity_config_vision_program_modification_pb.VisionProgramFields.toObject(includeInstance, f),
    linkCountlineImageSpaceToRealWorld: (f = msg.getLinkCountlineImageSpaceToRealWorld()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    countlineImageSpaceToRealWorldLinked: (f = msg.getCountlineImageSpaceToRealWorldLinked()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    unlinkCountlineImageSpaceToRealWorld: (f = msg.getUnlinkCountlineImageSpaceToRealWorld()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    countlineImageSpaceToRealWorldUnlinked: (f = msg.getCountlineImageSpaceToRealWorldUnlinked()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    createFileTemplate: (f = msg.getCreateFileTemplate()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateCreated: (f = msg.getFileTemplateCreated()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    changeFileTemplateFields: (f = msg.getChangeFileTemplateFields()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateFieldsChanged: (f = msg.getFileTemplateFieldsChanged()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    deleteFileTemplate: (f = msg.getDeleteFileTemplate()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateDeleted: (f = msg.getFileTemplateDeleted()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    createRenderedFileEntry: (f = msg.getCreateRenderedFileEntry()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderedFileEntryCreated: (f = msg.getRenderedFileEntryCreated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    deleteRenderedFile: (f = msg.getDeleteRenderedFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderedFileDeleted: (f = msg.getRenderedFileDeleted()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderFile: (f = msg.getRenderFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    fileRendered: (f = msg.getFileRendered()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    updateFileOnHardware: (f = msg.getUpdateFileOnHardware()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    fileOnHardwareUpdated: (f = msg.getFileOnHardwareUpdated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    remoteFileStateOnDbUpdated: (f = msg.getRemoteFileStateOnDbUpdated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    retrieveRenderedFiles: (f = msg.getRetrieveRenderedFiles()) && vivacity_config_file_templates_pb.RetrieveFile.toObject(includeInstance, f),
    linkVisionProgramToHardware: (f = msg.getLinkVisionProgramToHardware()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    visionProgramToHardwareLinked: (f = msg.getVisionProgramToHardwareLinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    unlinkVisionProgramToHardware: (f = msg.getUnlinkVisionProgramToHardware()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    visionProgramToHardwareUnlinked: (f = msg.getVisionProgramToHardwareUnlinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    changeLinkageCronjobToHardware: (f = msg.getChangeLinkageCronjobToHardware()) && vivacity_config_cronjob_pb.CronjobToHardwareLinkage.toObject(includeInstance, f),
    cronjobToHardwareLinkageChanged: (f = msg.getCronjobToHardwareLinkageChanged()) && vivacity_config_cronjob_pb.CronjobToHardwareLinkage.toObject(includeInstance, f),
    changeCronjob: (f = msg.getChangeCronjob()) && vivacity_config_cronjob_pb.Cronjob.toObject(includeInstance, f),
    cronjobChanged: (f = msg.getCronjobChanged()) && vivacity_config_cronjob_pb.Cronjob.toObject(includeInstance, f),
    createBenchmarkRun: (f = msg.getCreateBenchmarkRun()) && vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.toObject(includeInstance, f),
    createCommandGroupTemplate: (f = msg.getCreateCommandGroupTemplate()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    commandGroupTemplateCreated: (f = msg.getCommandGroupTemplateCreated()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    createAgent: (f = msg.getCreateAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentCreated: (f = msg.getAgentCreated()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    changeAgent: (f = msg.getChangeAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentChanged: (f = msg.getAgentChanged()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    deleteAgent: (f = msg.getDeleteAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentDeleted: (f = msg.getAgentDeleted()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    createAgentStream: (f = msg.getCreateAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamCreated: (f = msg.getAgentStreamCreated()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    changeAgentStream: (f = msg.getChangeAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamChanged: (f = msg.getAgentStreamChanged()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    deleteAgentStream: (f = msg.getDeleteAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamDeleted: (f = msg.getAgentStreamDeleted()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    createCountry: (f = msg.getCreateCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryCreated: (f = msg.getCountryCreated()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    changeCountry: (f = msg.getChangeCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryChanged: (f = msg.getCountryChanged()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    deleteCountry: (f = msg.getDeleteCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryDeleted: (f = msg.getCountryDeleted()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    createRegion: (f = msg.getCreateRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionCreated: (f = msg.getRegionCreated()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    changeRegion: (f = msg.getChangeRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionChanged: (f = msg.getRegionChanged()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    deleteRegion: (f = msg.getDeleteRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionDeleted: (f = msg.getRegionDeleted()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    createController: (f = msg.getCreateController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerCreated: (f = msg.getControllerCreated()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    changeController: (f = msg.getChangeController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerChanged: (f = msg.getControllerChanged()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    deleteController: (f = msg.getDeleteController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerDeleted: (f = msg.getControllerDeleted()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    createControllerStream: (f = msg.getCreateControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamCreated: (f = msg.getControllerStreamCreated()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    changeControllerStream: (f = msg.getChangeControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamChanged: (f = msg.getControllerStreamChanged()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    deleteControllerStream: (f = msg.getDeleteControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamDeleted: (f = msg.getControllerStreamDeleted()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    createVelocity: (f = msg.getCreateVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityCreated: (f = msg.getVelocityCreated()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    changeVelocity: (f = msg.getChangeVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityChanged: (f = msg.getVelocityChanged()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    deleteVelocity: (f = msg.getDeleteVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityDeleted: (f = msg.getVelocityDeleted()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    createAgentConfig: (f = msg.getCreateAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigCreated: (f = msg.getAgentConfigCreated()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    changeAgentConfig: (f = msg.getChangeAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigChanged: (f = msg.getAgentConfigChanged()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    deleteAgentConfig: (f = msg.getDeleteAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigDeleted: (f = msg.getAgentConfigDeleted()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    createControlReplyBit: (f = msg.getCreateControlReplyBit()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    controlReplyBitCreated: (f = msg.getControlReplyBitCreated()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    changeControlReplyBit: (f = msg.getChangeControlReplyBit()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    controlReplyBitChanged: (f = msg.getControlReplyBitChanged()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    deleteControlReplyBit: (f = msg.getDeleteControlReplyBit()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    controlReplyBitDeleted: (f = msg.getControlReplyBitDeleted()) && vivacity_config_controller_config_pb.ControlReplyBit.toObject(includeInstance, f),
    createStage: (f = msg.getCreateStage()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    stageCreated: (f = msg.getStageCreated()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    changeStage: (f = msg.getChangeStage()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    stageChanged: (f = msg.getStageChanged()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    deleteStage: (f = msg.getDeleteStage()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    stageDeleted: (f = msg.getStageDeleted()) && vivacity_config_controller_config_pb.Stage.toObject(includeInstance, f),
    createPhase: (f = msg.getCreatePhase()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    phaseCreated: (f = msg.getPhaseCreated()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    changePhase: (f = msg.getChangePhase()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    phaseChanged: (f = msg.getPhaseChanged()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    deletePhase: (f = msg.getDeletePhase()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    phaseDeleted: (f = msg.getPhaseDeleted()) && vivacity_config_controller_config_pb.Phase.toObject(includeInstance, f),
    createViu: (f = msg.getCreateViu()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    viuCreated: (f = msg.getViuCreated()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    changeViu: (f = msg.getChangeViu()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    viuChanged: (f = msg.getViuChanged()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    deleteViu: (f = msg.getDeleteViu()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    viuDeleted: (f = msg.getViuDeleted()) && vivacity_config_viu_config_pb.Viu.toObject(includeInstance, f),
    createDetoxicity: (f = msg.getCreateDetoxicity()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    detoxicityCreated: (f = msg.getDetoxicityCreated()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    changeDetoxicity: (f = msg.getChangeDetoxicity()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    detoxicityChanged: (f = msg.getDetoxicityChanged()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    deleteDetoxicity: (f = msg.getDeleteDetoxicity()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    detoxicityDeleted: (f = msg.getDetoxicityDeleted()) && vivacity_config_instation_config_pb.Detoxicity.toObject(includeInstance, f),
    createStageToPhase: (f = msg.getCreateStageToPhase()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    stageToPhaseCreated: (f = msg.getStageToPhaseCreated()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    changeStageToPhase: (f = msg.getChangeStageToPhase()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    stageToPhaseChanged: (f = msg.getStageToPhaseChanged()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    deleteStageToPhase: (f = msg.getDeleteStageToPhase()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    stageToPhaseDeleted: (f = msg.getStageToPhaseDeleted()) && vivacity_config_controller_config_pb.StageToPhase.toObject(includeInstance, f),
    createVisionProgramToControllerStream: (f = msg.getCreateVisionProgramToControllerStream()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    visionProgramToControllerStreamCreated: (f = msg.getVisionProgramToControllerStreamCreated()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    changeVisionProgramToControllerStream: (f = msg.getChangeVisionProgramToControllerStream()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    visionProgramToControllerStreamChanged: (f = msg.getVisionProgramToControllerStreamChanged()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    deleteVisionProgramToControllerStream: (f = msg.getDeleteVisionProgramToControllerStream()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    visionProgramToControllerStreamDeleted: (f = msg.getVisionProgramToControllerStreamDeleted()) && vivacity_config_controller_config_pb.VisionProgramToControllerStream.toObject(includeInstance, f),
    createPhaseToImageSpaceZone: (f = msg.getCreatePhaseToImageSpaceZone()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    phaseToImageSpaceZoneCreated: (f = msg.getPhaseToImageSpaceZoneCreated()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    changePhaseToImageSpaceZone: (f = msg.getChangePhaseToImageSpaceZone()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    phaseToImageSpaceZoneChanged: (f = msg.getPhaseToImageSpaceZoneChanged()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    deletePhaseToImageSpaceZone: (f = msg.getDeletePhaseToImageSpaceZone()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    phaseToImageSpaceZoneDeleted: (f = msg.getPhaseToImageSpaceZoneDeleted()) && vivacity_config_controller_config_pb.PhaseToImageSpaceZone.toObject(includeInstance, f),
    createPhaseToImageSpaceCountline: (f = msg.getCreatePhaseToImageSpaceCountline()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    phaseToImageSpaceCountlineCreated: (f = msg.getPhaseToImageSpaceCountlineCreated()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    changePhaseToImageSpaceCountline: (f = msg.getChangePhaseToImageSpaceCountline()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    phaseToImageSpaceCountlineChanged: (f = msg.getPhaseToImageSpaceCountlineChanged()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    deletePhaseToImageSpaceCountline: (f = msg.getDeletePhaseToImageSpaceCountline()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    phaseToImageSpaceCountlineDeleted: (f = msg.getPhaseToImageSpaceCountlineDeleted()) && vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.toObject(includeInstance, f),
    createAgentType: (f = msg.getCreateAgentType()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    agentTypeCreated: (f = msg.getAgentTypeCreated()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    changeAgentType: (f = msg.getChangeAgentType()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    agentTypeChanged: (f = msg.getAgentTypeChanged()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    deleteAgentType: (f = msg.getDeleteAgentType()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    agentTypeDeleted: (f = msg.getAgentTypeDeleted()) && vivacity_config_agent_config_pb.AgentType.toObject(includeInstance, f),
    createEntityGroupGenealogy: (f = msg.getCreateEntityGroupGenealogy()) && vivacity_config_entity_groups_pb.EntityGroupGenealogy.toObject(includeInstance, f),
    entityGroupGenealogyCreated: (f = msg.getEntityGroupGenealogyCreated()) && vivacity_config_entity_groups_pb.EntityGroupGenealogy.toObject(includeInstance, f),
    deleteEntityGroupGenealogy: (f = msg.getDeleteEntityGroupGenealogy()) && vivacity_config_entity_groups_pb.EntityGroupGenealogy.toObject(includeInstance, f),
    entityGroupGenealogyDeleted: (f = msg.getEntityGroupGenealogyDeleted()) && vivacity_config_entity_groups_pb.EntityGroupGenealogy.toObject(includeInstance, f),
    createEntityGroup: (f = msg.getCreateEntityGroup()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    entityGroupCreated: (f = msg.getEntityGroupCreated()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    changeEntityGroupFields: (f = msg.getChangeEntityGroupFields()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    entityGroupFieldsChanged: (f = msg.getEntityGroupFieldsChanged()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    deleteEntityGroup: (f = msg.getDeleteEntityGroup()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    entityGroupDeleted: (f = msg.getEntityGroupDeleted()) && vivacity_config_entity_groups_pb.EntityGroup.toObject(includeInstance, f),
    addGroupLinkage: (f = msg.getAddGroupLinkage()) && vivacity_config_entity_groups_pb.EntityGroupLinkage.toObject(includeInstance, f),
    groupLinkageAdded: (f = msg.getGroupLinkageAdded()) && vivacity_config_entity_groups_pb.EntityGroupLinkage.toObject(includeInstance, f),
    deleteGroupLinkage: (f = msg.getDeleteGroupLinkage()) && vivacity_config_entity_groups_pb.EntityGroupLinkage.toObject(includeInstance, f),
    groupLinkageDeleted: (f = msg.getGroupLinkageDeleted()) && vivacity_config_entity_groups_pb.EntityGroupLinkage.toObject(includeInstance, f),
    setCommandGroupTemplate: (f = msg.getSetCommandGroupTemplate()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    commandGroupTemplateSetted: (f = msg.getCommandGroupTemplateSetted()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    createZonalWindowedClassConfig: (f = msg.getCreateZonalWindowedClassConfig()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    zonalWindowedClassConfigCreated: (f = msg.getZonalWindowedClassConfigCreated()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    changeZonalWindowedClassConfig: (f = msg.getChangeZonalWindowedClassConfig()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    zonalWindowedClassConfigChanged: (f = msg.getZonalWindowedClassConfigChanged()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    deleteZonalWindowedClassConfig: (f = msg.getDeleteZonalWindowedClassConfig()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    zonalWindowedClassConfigDeleted: (f = msg.getZonalWindowedClassConfigDeleted()) && vivacity_config_zone_config_pb.ZonalWindowedClassConfig.toObject(includeInstance, f),
    createControllerStreamControlSchedule: (f = msg.getCreateControllerStreamControlSchedule()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    controllerStreamControlScheduleCreated: (f = msg.getControllerStreamControlScheduleCreated()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    changeControllerStreamControlSchedule: (f = msg.getChangeControllerStreamControlSchedule()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    controllerStreamControlScheduleChanged: (f = msg.getControllerStreamControlScheduleChanged()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    deleteControllerStreamControlSchedule: (f = msg.getDeleteControllerStreamControlSchedule()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    controllerStreamControlScheduleDeleted: (f = msg.getControllerStreamControlScheduleDeleted()) && vivacity_config_instation_config_pb.ControllerStreamControlSchedule.toObject(includeInstance, f),
    createStageTransition: (f = msg.getCreateStageTransition()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    stageTransitionCreated: (f = msg.getStageTransitionCreated()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    changeStageTransition: (f = msg.getChangeStageTransition()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    stageTransitionChanged: (f = msg.getStageTransitionChanged()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    deleteStageTransition: (f = msg.getDeleteStageTransition()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    stageTransitionDeleted: (f = msg.getStageTransitionDeleted()) && vivacity_config_controller_config_pb.StageTransition.toObject(includeInstance, f),
    createVpToVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getCreateVpToVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpToVpWindowedFeaturesWebsocketConnectionConfigCreated: (f = msg.getVpToVpWindowedFeaturesWebsocketConnectionConfigCreated()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    changeVpToVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getChangeVpToVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpToVpWindowedFeaturesWebsocketConnectionConfigChanged: (f = msg.getVpToVpWindowedFeaturesWebsocketConnectionConfigChanged()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    deleteVpToVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpToVpWindowedFeaturesWebsocketConnectionConfigDeleted: (f = msg.getVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted()) && vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    createVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getCreateVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpWindowedFeaturesWebsocketConnectionConfigCreated: (f = msg.getVpWindowedFeaturesWebsocketConnectionConfigCreated()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    changeVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getChangeVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpWindowedFeaturesWebsocketConnectionConfigChanged: (f = msg.getVpWindowedFeaturesWebsocketConnectionConfigChanged()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    deleteVpWindowedFeaturesWebsocketConnectionConfig: (f = msg.getDeleteVpWindowedFeaturesWebsocketConnectionConfig()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    vpWindowedFeaturesWebsocketConnectionConfigDeleted: (f = msg.getVpWindowedFeaturesWebsocketConnectionConfigDeleted()) && vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.toObject(includeInstance, f),
    createCvModel: (f = msg.getCreateCvModel()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    cvModelCreated: (f = msg.getCvModelCreated()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    changeCvModel: (f = msg.getChangeCvModel()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    cvModelChanged: (f = msg.getCvModelChanged()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    deleteCvModel: (f = msg.getDeleteCvModel()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    cvModelDeleted: (f = msg.getCvModelDeleted()) && vivacity_config_cv_model_config_pb.CVModel.toObject(includeInstance, f),
    createCvModelType: (f = msg.getCreateCvModelType()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    cvModelTypeCreated: (f = msg.getCvModelTypeCreated()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    changeCvModelType: (f = msg.getChangeCvModelType()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    cvModelTypeChanged: (f = msg.getCvModelTypeChanged()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    deleteCvModelType: (f = msg.getDeleteCvModelType()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    cvModelTypeDeleted: (f = msg.getCvModelTypeDeleted()) && vivacity_config_cv_model_config_pb.CVModelType.toObject(includeInstance, f),
    createCvModelClass: (f = msg.getCreateCvModelClass()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    cvModelClassCreated: (f = msg.getCvModelClassCreated()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    changeCvModelClass: (f = msg.getChangeCvModelClass()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    cvModelClassChanged: (f = msg.getCvModelClassChanged()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    deleteCvModelClass: (f = msg.getDeleteCvModelClass()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    cvModelClassDeleted: (f = msg.getCvModelClassDeleted()) && vivacity_config_cv_model_config_pb.CVModelClass.toObject(includeInstance, f),
    createVisionProgramPreset: (f = msg.getCreateVisionProgramPreset()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    visionProgramPresetCreated: (f = msg.getVisionProgramPresetCreated()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    changeVisionProgramPreset: (f = msg.getChangeVisionProgramPreset()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    visionProgramPresetChanged: (f = msg.getVisionProgramPresetChanged()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    deleteVisionProgramPreset: (f = msg.getDeleteVisionProgramPreset()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    visionProgramPresetDeleted: (f = msg.getVisionProgramPresetDeleted()) && vivacity_config_cv_model_config_pb.VisionProgramPreset.toObject(includeInstance, f),
    createEntityTemplateType: (f = msg.getCreateEntityTemplateType()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    entityTemplateTypeCreated: (f = msg.getEntityTemplateTypeCreated()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    changeEntityTemplateType: (f = msg.getChangeEntityTemplateType()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    entityTemplateTypeChanged: (f = msg.getEntityTemplateTypeChanged()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    deleteEntityTemplateType: (f = msg.getDeleteEntityTemplateType()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    entityTemplateTypeDeleted: (f = msg.getEntityTemplateTypeDeleted()) && vivacity_config_entity_templates_pb.EntityTemplateType.toObject(includeInstance, f),
    createEntityTemplate: (f = msg.getCreateEntityTemplate()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    entityTemplateCreated: (f = msg.getEntityTemplateCreated()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    changeEntityTemplate: (f = msg.getChangeEntityTemplate()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    entityTemplateChanged: (f = msg.getEntityTemplateChanged()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    deleteEntityTemplate: (f = msg.getDeleteEntityTemplate()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    entityTemplateDeleted: (f = msg.getEntityTemplateDeleted()) && vivacity_config_entity_templates_pb.EntityTemplate.toObject(includeInstance, f),
    createRenderedEntityTemplate: (f = msg.getCreateRenderedEntityTemplate()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    renderedEntityTemplateCreated: (f = msg.getRenderedEntityTemplateCreated()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    changeRenderedEntityTemplate: (f = msg.getChangeRenderedEntityTemplate()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    renderedEntityTemplateChanged: (f = msg.getRenderedEntityTemplateChanged()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    deleteRenderedEntityTemplate: (f = msg.getDeleteRenderedEntityTemplate()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    renderedEntityTemplateDeleted: (f = msg.getRenderedEntityTemplateDeleted()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    renderEntityTemplate: (f = msg.getRenderEntityTemplate()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    entityTemplateRendered: (f = msg.getEntityTemplateRendered()) && vivacity_config_entity_templates_pb.RenderedEntityTemplate.toObject(includeInstance, f),
    createDynniqMessageClassGrouping: (f = msg.getCreateDynniqMessageClassGrouping()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    dynniqMessageClassGroupingCreated: (f = msg.getDynniqMessageClassGroupingCreated()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    changeDynniqMessageClassGrouping: (f = msg.getChangeDynniqMessageClassGrouping()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    dynniqMessageClassGroupingChanged: (f = msg.getDynniqMessageClassGroupingChanged()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    deleteDynniqMessageClassGrouping: (f = msg.getDeleteDynniqMessageClassGrouping()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    dynniqMessageClassGroupingDeleted: (f = msg.getDynniqMessageClassGroupingDeleted()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.toObject(includeInstance, f),
    createDynniqMessageZone: (f = msg.getCreateDynniqMessageZone()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    dynniqMessageZoneCreated: (f = msg.getDynniqMessageZoneCreated()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    changeDynniqMessageZone: (f = msg.getChangeDynniqMessageZone()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    dynniqMessageZoneChanged: (f = msg.getDynniqMessageZoneChanged()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    deleteDynniqMessageZone: (f = msg.getDeleteDynniqMessageZone()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    dynniqMessageZoneDeleted: (f = msg.getDynniqMessageZoneDeleted()) && vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.toObject(includeInstance, f),
    createControllerHardwareModels: (f = msg.getCreateControllerHardwareModels()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    controllerHardwareModelsCreated: (f = msg.getControllerHardwareModelsCreated()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    changeControllerHardwareModels: (f = msg.getChangeControllerHardwareModels()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    controllerHardwareModelsChanged: (f = msg.getControllerHardwareModelsChanged()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    deleteControllerHardwareModels: (f = msg.getDeleteControllerHardwareModels()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    controllerHardwareModelsDeleted: (f = msg.getControllerHardwareModelsDeleted()) && vivacity_config_controller_config_pb.ControllerHardwareModels.toObject(includeInstance, f),
    createHardwareManufacturers: (f = msg.getCreateHardwareManufacturers()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    hardwareManufacturersCreated: (f = msg.getHardwareManufacturersCreated()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    changeHardwareManufacturers: (f = msg.getChangeHardwareManufacturers()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    hardwareManufacturersChanged: (f = msg.getHardwareManufacturersChanged()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    deleteHardwareManufacturers: (f = msg.getDeleteHardwareManufacturers()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    hardwareManufacturersDeleted: (f = msg.getHardwareManufacturersDeleted()) && vivacity_config_hardware_pb.HardwareManufacturers.toObject(includeInstance, f),
    createCountlineWindowedClassConfig: (f = msg.getCreateCountlineWindowedClassConfig()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    countlineWindowedClassConfigCreated: (f = msg.getCountlineWindowedClassConfigCreated()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    changeCountlineWindowedClassConfig: (f = msg.getChangeCountlineWindowedClassConfig()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    countlineWindowedClassConfigChanged: (f = msg.getCountlineWindowedClassConfigChanged()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    deleteCountlineWindowedClassConfig: (f = msg.getDeleteCountlineWindowedClassConfig()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    countlineWindowedClassConfigDeleted: (f = msg.getCountlineWindowedClassConfigDeleted()) && vivacity_config_countline_config_pb.CountlineWindowedClassConfig.toObject(includeInstance, f),
    createEntityTemplateTypeToEntity: (f = msg.getCreateEntityTemplateTypeToEntity()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    entityTemplateTypeToEntityCreated: (f = msg.getEntityTemplateTypeToEntityCreated()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    changeEntityTemplateTypeToEntity: (f = msg.getChangeEntityTemplateTypeToEntity()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    entityTemplateTypeToEntityChanged: (f = msg.getEntityTemplateTypeToEntityChanged()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    deleteEntityTemplateTypeToEntity: (f = msg.getDeleteEntityTemplateTypeToEntity()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    entityTemplateTypeToEntityDeleted: (f = msg.getEntityTemplateTypeToEntityDeleted()) && vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.toObject(includeInstance, f),
    createHardwarePosition: (f = msg.getCreateHardwarePosition()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    hardwarePositionCreated: (f = msg.getHardwarePositionCreated()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    changeHardwarePosition: (f = msg.getChangeHardwarePosition()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    hardwarePositionChanged: (f = msg.getHardwarePositionChanged()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    deleteHardwarePosition: (f = msg.getDeleteHardwarePosition()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    hardwarePositionDeleted: (f = msg.getHardwarePositionDeleted()) && vivacity_config_hardware_position_pb.HardwarePosition.toObject(includeInstance, f),
    createContractedHardware: (f = msg.getCreateContractedHardware()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    contractedHardwareCreated: (f = msg.getContractedHardwareCreated()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    changeContractedHardware: (f = msg.getChangeContractedHardware()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    contractedHardwareChanged: (f = msg.getContractedHardwareChanged()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    deleteContractedHardware: (f = msg.getDeleteContractedHardware()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    contractedHardwareDeleted: (f = msg.getContractedHardwareDeleted()) && vivacity_config_contracted_hardware_pb.ContractedHardware.toObject(includeInstance, f),
    createCustomer: (f = msg.getCreateCustomer()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    customerCreated: (f = msg.getCustomerCreated()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    changeCustomer: (f = msg.getChangeCustomer()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    customerChanged: (f = msg.getCustomerChanged()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    deleteCustomer: (f = msg.getDeleteCustomer()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    customerDeleted: (f = msg.getCustomerDeleted()) && vivacity_config_contracted_hardware_pb.Customers.toObject(includeInstance, f),
    linkContractToContractedHardware: (f = msg.getLinkContractToContractedHardware()) && vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.toObject(includeInstance, f),
    contractToContractedHardwareLinked: (f = msg.getContractToContractedHardwareLinked()) && vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.toObject(includeInstance, f),
    unlinkContractToContractedHardware: (f = msg.getUnlinkContractToContractedHardware()) && vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.toObject(includeInstance, f),
    contractToContractedHardwareUnlinked: (f = msg.getContractToContractedHardwareUnlinked()) && vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.toObject(includeInstance, f),
    linkHardwarePositionToHardware: (f = msg.getLinkHardwarePositionToHardware()) && vivacity_config_hardware_position_pb.HardwarePositionToHardware.toObject(includeInstance, f),
    hardwarePositionToHardwareLinked: (f = msg.getHardwarePositionToHardwareLinked()) && vivacity_config_hardware_position_pb.HardwarePositionToHardware.toObject(includeInstance, f),
    unlinkHardwarePositionToHardware: (f = msg.getUnlinkHardwarePositionToHardware()) && vivacity_config_hardware_position_pb.HardwarePositionToHardware.toObject(includeInstance, f),
    hardwarePositionToHardwareUnlinked: (f = msg.getHardwarePositionToHardwareUnlinked()) && vivacity_config_hardware_position_pb.HardwarePositionToHardware.toObject(includeInstance, f),
    linkHardwarePositionToContractedHardware: (f = msg.getLinkHardwarePositionToContractedHardware()) && vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.toObject(includeInstance, f),
    hardwarePositionToContractedHardwareLinked: (f = msg.getHardwarePositionToContractedHardwareLinked()) && vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.toObject(includeInstance, f),
    unlinkHardwarePositionToContractedHardware: (f = msg.getUnlinkHardwarePositionToContractedHardware()) && vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.toObject(includeInstance, f),
    hardwarePositionToContractedHardwareUnlinked: (f = msg.getHardwarePositionToContractedHardwareUnlinked()) && vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.toObject(includeInstance, f),
    linkVisionProgramToHardwarePosition: (f = msg.getLinkVisionProgramToHardwarePosition()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.toObject(includeInstance, f),
    visionProgramToHardwarePositionLinked: (f = msg.getVisionProgramToHardwarePositionLinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.toObject(includeInstance, f),
    unlinkVisionProgramToHardwarePosition: (f = msg.getUnlinkVisionProgramToHardwarePosition()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.toObject(includeInstance, f),
    visionProgramToHardwarePositionUnlinked: (f = msg.getVisionProgramToHardwarePositionUnlinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.toObject(includeInstance, f),
    createContract: (f = msg.getCreateContract()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    contractCreated: (f = msg.getContractCreated()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    changeContract: (f = msg.getChangeContract()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    contractChanged: (f = msg.getContractChanged()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    deleteContract: (f = msg.getDeleteContract()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    contractDeleted: (f = msg.getContractDeleted()) && vivacity_config_contracted_hardware_pb.Contracts.toObject(includeInstance, f),
    createHardwarePositionDrawing: (f = msg.getCreateHardwarePositionDrawing()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    hardwarePositionDrawingCreated: (f = msg.getHardwarePositionDrawingCreated()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    changeHardwarePositionDrawing: (f = msg.getChangeHardwarePositionDrawing()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    hardwarePositionDrawingChanged: (f = msg.getHardwarePositionDrawingChanged()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    deleteHardwarePositionDrawing: (f = msg.getDeleteHardwarePositionDrawing()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    hardwarePositionDrawingDeleted: (f = msg.getHardwarePositionDrawingDeleted()) && vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.toObject(includeInstance, f),
    setControlControllerStream: (f = msg.getSetControlControllerStream()) && vivacity_config_controller_config_pb.SetControlControllerStream.toObject(includeInstance, f),
    controllerStreamControlSet: (f = msg.getControllerStreamControlSet()) && vivacity_config_controller_config_pb.SetControlControllerStream.toObject(includeInstance, f),
    createZoneRealWorldSpace: (f = msg.getCreateZoneRealWorldSpace()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    zoneRealWorldSpaceCreated: (f = msg.getZoneRealWorldSpaceCreated()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    changeZoneRealWorldSpace: (f = msg.getChangeZoneRealWorldSpace()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    zoneRealWorldSpaceChanged: (f = msg.getZoneRealWorldSpaceChanged()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    deleteZoneRealWorldSpace: (f = msg.getDeleteZoneRealWorldSpace()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    zoneRealWorldSpaceDeleted: (f = msg.getZoneRealWorldSpaceDeleted()) && vivacity_config_zone_config_pb.ZoneRealWorldSpace.toObject(includeInstance, f),
    hybridMessageRequest: (f = msg.getHybridMessageRequest()) && proto.vivacity.config.HybridTransactionalMessage.toObject(includeInstance, f),
    hybridMessageResponse: (f = msg.getHybridMessageResponse()) && proto.vivacity.config.HybridTransactionalMessage.toObject(includeInstance, f),
    changeHardwarePositionStatus: (f = msg.getChangeHardwarePositionStatus()) && vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.toObject(includeInstance, f),
    hardwarePositionStatusChanged: (f = msg.getHardwarePositionStatusChanged()) && vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ConfigMessage;
  return proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ConfigMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.ConfigMessage.EventMessageType} */ (reader.readEnum());
      msg.setEventMessageType(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 6:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 7:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.addBatchedConfigMessages(value);
      break;
    case 8:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.addReducedBatchedConfigMessages(value);
      break;
    case 11:
      var value = new vivacity_config_zone_config_pb.ZonesConfigChange;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonesConfigChange.deserializeBinaryFromReader);
      msg.setChangeZoneImageSpace(value);
      break;
    case 12:
      var value = new vivacity_config_zone_config_pb.ZonesConfigChange;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonesConfigChange.deserializeBinaryFromReader);
      msg.setZoneImageSpaceChanged(value);
      break;
    case 13:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setChangeCountlineImageSpace(value);
      break;
    case 14:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceChanged(value);
      break;
    case 15:
      var value = new vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram;
      reader.readMessage(value,vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.deserializeBinaryFromReader);
      msg.setChangePerspectiveTransform(value);
      break;
    case 16:
      var value = new vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram;
      reader.readMessage(value,vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.deserializeBinaryFromReader);
      msg.setPerspectiveTransformChanged(value);
      break;
    case 17:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setChangeCountlineRealWorldSpace(value);
      break;
    case 18:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setCountlineRealWorldSpaceChanged(value);
      break;
    case 19:
      var value = new vivacity_config_alerts_config_pb.ZonalAlertConfigChange;
      reader.readMessage(value,vivacity_config_alerts_config_pb.ZonalAlertConfigChange.deserializeBinaryFromReader);
      msg.setChangeZonalAlert(value);
      break;
    case 20:
      var value = new vivacity_config_alerts_config_pb.ZonalAlertConfigChange;
      reader.readMessage(value,vivacity_config_alerts_config_pb.ZonalAlertConfigChange.deserializeBinaryFromReader);
      msg.setZonalAlertChanged(value);
      break;
    case 21:
      var value = new vivacity_config_hardware_pb.GetOrCreateHardwareId;
      reader.readMessage(value,vivacity_config_hardware_pb.GetOrCreateHardwareId.deserializeBinaryFromReader);
      msg.setGetHardwareId(value);
      break;
    case 22:
      var value = new vivacity_config_hardware_pb.GetOrCreateHardwareId;
      reader.readMessage(value,vivacity_config_hardware_pb.GetOrCreateHardwareId.deserializeBinaryFromReader);
      msg.setCreateHardwareId(value);
      break;
    case 23:
      var value = new vivacity_config_hardware_pb.HardwareIdTokenResponse;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareIdTokenResponse.deserializeBinaryFromReader);
      msg.setHardwareIdTokenResponse(value);
      break;
    case 24:
      var value = new vivacity_config_hardware_pb.HardwareIdCreated;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareIdCreated.deserializeBinaryFromReader);
      msg.setHardwareIdCreated(value);
      break;
    case 25:
      var value = new vivacity_config_vault_pb.VaultResourceCreated;
      reader.readMessage(value,vivacity_config_vault_pb.VaultResourceCreated.deserializeBinaryFromReader);
      msg.setVaultResourceCreated(value);
      break;
    case 26:
      var value = new vivacity_config_hardware_pb.HardwareFields;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareFields.deserializeBinaryFromReader);
      msg.setChangeHardwareFields(value);
      break;
    case 27:
      var value = new vivacity_config_hardware_pb.HardwareFields;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareFields.deserializeBinaryFromReader);
      msg.setHardwareFieldsChanged(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateVisionProgram(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramCreated(value);
      break;
    case 33:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramFields;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramFields.deserializeBinaryFromReader);
      msg.setChangeVisionProgramFields(value);
      break;
    case 34:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramFields;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramFields.deserializeBinaryFromReader);
      msg.setVisionProgramFieldsChanged(value);
      break;
    case 35:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setLinkCountlineImageSpaceToRealWorld(value);
      break;
    case 36:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceToRealWorldLinked(value);
      break;
    case 37:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setUnlinkCountlineImageSpaceToRealWorld(value);
      break;
    case 38:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceToRealWorldUnlinked(value);
      break;
    case 40:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setCreateFileTemplate(value);
      break;
    case 41:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateCreated(value);
      break;
    case 42:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setChangeFileTemplateFields(value);
      break;
    case 43:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateFieldsChanged(value);
      break;
    case 44:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setDeleteFileTemplate(value);
      break;
    case 45:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateDeleted(value);
      break;
    case 46:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setCreateRenderedFileEntry(value);
      break;
    case 47:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderedFileEntryCreated(value);
      break;
    case 48:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setDeleteRenderedFile(value);
      break;
    case 49:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderedFileDeleted(value);
      break;
    case 50:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderFile(value);
      break;
    case 51:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setFileRendered(value);
      break;
    case 52:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setUpdateFileOnHardware(value);
      break;
    case 53:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setFileOnHardwareUpdated(value);
      break;
    case 54:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRemoteFileStateOnDbUpdated(value);
      break;
    case 55:
      var value = new vivacity_config_file_templates_pb.RetrieveFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RetrieveFile.deserializeBinaryFromReader);
      msg.setRetrieveRenderedFiles(value);
      break;
    case 60:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setLinkVisionProgramToHardware(value);
      break;
    case 61:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwareLinked(value);
      break;
    case 62:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setUnlinkVisionProgramToHardware(value);
      break;
    case 63:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwareUnlinked(value);
      break;
    case 64:
      var value = new vivacity_config_cronjob_pb.CronjobToHardwareLinkage;
      reader.readMessage(value,vivacity_config_cronjob_pb.CronjobToHardwareLinkage.deserializeBinaryFromReader);
      msg.setChangeLinkageCronjobToHardware(value);
      break;
    case 65:
      var value = new vivacity_config_cronjob_pb.CronjobToHardwareLinkage;
      reader.readMessage(value,vivacity_config_cronjob_pb.CronjobToHardwareLinkage.deserializeBinaryFromReader);
      msg.setCronjobToHardwareLinkageChanged(value);
      break;
    case 66:
      var value = new vivacity_config_cronjob_pb.Cronjob;
      reader.readMessage(value,vivacity_config_cronjob_pb.Cronjob.deserializeBinaryFromReader);
      msg.setChangeCronjob(value);
      break;
    case 67:
      var value = new vivacity_config_cronjob_pb.Cronjob;
      reader.readMessage(value,vivacity_config_cronjob_pb.Cronjob.deserializeBinaryFromReader);
      msg.setCronjobChanged(value);
      break;
    case 69:
      var value = new vivacity_benchmarking_benchmark_run_pb.BenchmarkRun;
      reader.readMessage(value,vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.deserializeBinaryFromReader);
      msg.setCreateBenchmarkRun(value);
      break;
    case 70:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setCreateCommandGroupTemplate(value);
      break;
    case 71:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setCommandGroupTemplateCreated(value);
      break;
    case 72:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setCreateAgent(value);
      break;
    case 73:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentCreated(value);
      break;
    case 74:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setChangeAgent(value);
      break;
    case 75:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentChanged(value);
      break;
    case 76:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setDeleteAgent(value);
      break;
    case 77:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentDeleted(value);
      break;
    case 78:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setCreateAgentStream(value);
      break;
    case 79:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamCreated(value);
      break;
    case 80:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setChangeAgentStream(value);
      break;
    case 81:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamChanged(value);
      break;
    case 82:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setDeleteAgentStream(value);
      break;
    case 83:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamDeleted(value);
      break;
    case 84:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCreateCountry(value);
      break;
    case 85:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryCreated(value);
      break;
    case 86:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setChangeCountry(value);
      break;
    case 87:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryChanged(value);
      break;
    case 88:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setDeleteCountry(value);
      break;
    case 89:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryDeleted(value);
      break;
    case 90:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setCreateRegion(value);
      break;
    case 91:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionCreated(value);
      break;
    case 92:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setChangeRegion(value);
      break;
    case 93:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionChanged(value);
      break;
    case 94:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setDeleteRegion(value);
      break;
    case 95:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionDeleted(value);
      break;
    case 96:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setCreateController(value);
      break;
    case 97:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerCreated(value);
      break;
    case 98:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setChangeController(value);
      break;
    case 99:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerChanged(value);
      break;
    case 100:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setDeleteController(value);
      break;
    case 101:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerDeleted(value);
      break;
    case 102:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setCreateControllerStream(value);
      break;
    case 103:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamCreated(value);
      break;
    case 104:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setChangeControllerStream(value);
      break;
    case 105:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamChanged(value);
      break;
    case 106:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setDeleteControllerStream(value);
      break;
    case 107:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamDeleted(value);
      break;
    case 108:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setCreateVelocity(value);
      break;
    case 109:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityCreated(value);
      break;
    case 110:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setChangeVelocity(value);
      break;
    case 111:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityChanged(value);
      break;
    case 112:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setDeleteVelocity(value);
      break;
    case 113:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityDeleted(value);
      break;
    case 114:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setCreateAgentConfig(value);
      break;
    case 115:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigCreated(value);
      break;
    case 116:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setChangeAgentConfig(value);
      break;
    case 117:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigChanged(value);
      break;
    case 118:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setDeleteAgentConfig(value);
      break;
    case 119:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigDeleted(value);
      break;
    case 120:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setCreateControlReplyBit(value);
      break;
    case 121:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setControlReplyBitCreated(value);
      break;
    case 122:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setChangeControlReplyBit(value);
      break;
    case 123:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setControlReplyBitChanged(value);
      break;
    case 124:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setDeleteControlReplyBit(value);
      break;
    case 125:
      var value = new vivacity_config_controller_config_pb.ControlReplyBit;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControlReplyBit.deserializeBinaryFromReader);
      msg.setControlReplyBitDeleted(value);
      break;
    case 126:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setCreateStage(value);
      break;
    case 127:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setStageCreated(value);
      break;
    case 128:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setChangeStage(value);
      break;
    case 129:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setStageChanged(value);
      break;
    case 130:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setDeleteStage(value);
      break;
    case 131:
      var value = new vivacity_config_controller_config_pb.Stage;
      reader.readMessage(value,vivacity_config_controller_config_pb.Stage.deserializeBinaryFromReader);
      msg.setStageDeleted(value);
      break;
    case 132:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setCreatePhase(value);
      break;
    case 133:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setPhaseCreated(value);
      break;
    case 134:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setChangePhase(value);
      break;
    case 135:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setPhaseChanged(value);
      break;
    case 136:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setDeletePhase(value);
      break;
    case 137:
      var value = new vivacity_config_controller_config_pb.Phase;
      reader.readMessage(value,vivacity_config_controller_config_pb.Phase.deserializeBinaryFromReader);
      msg.setPhaseDeleted(value);
      break;
    case 138:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setCreateViu(value);
      break;
    case 139:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setViuCreated(value);
      break;
    case 140:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setChangeViu(value);
      break;
    case 141:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setViuChanged(value);
      break;
    case 142:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setDeleteViu(value);
      break;
    case 143:
      var value = new vivacity_config_viu_config_pb.Viu;
      reader.readMessage(value,vivacity_config_viu_config_pb.Viu.deserializeBinaryFromReader);
      msg.setViuDeleted(value);
      break;
    case 144:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setCreateDetoxicity(value);
      break;
    case 145:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setDetoxicityCreated(value);
      break;
    case 146:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setChangeDetoxicity(value);
      break;
    case 147:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setDetoxicityChanged(value);
      break;
    case 148:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setDeleteDetoxicity(value);
      break;
    case 149:
      var value = new vivacity_config_instation_config_pb.Detoxicity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Detoxicity.deserializeBinaryFromReader);
      msg.setDetoxicityDeleted(value);
      break;
    case 150:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setCreateStageToPhase(value);
      break;
    case 151:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setStageToPhaseCreated(value);
      break;
    case 152:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setChangeStageToPhase(value);
      break;
    case 153:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setStageToPhaseChanged(value);
      break;
    case 154:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setDeleteStageToPhase(value);
      break;
    case 155:
      var value = new vivacity_config_controller_config_pb.StageToPhase;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageToPhase.deserializeBinaryFromReader);
      msg.setStageToPhaseDeleted(value);
      break;
    case 156:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setCreateVisionProgramToControllerStream(value);
      break;
    case 157:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setVisionProgramToControllerStreamCreated(value);
      break;
    case 158:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setChangeVisionProgramToControllerStream(value);
      break;
    case 159:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setVisionProgramToControllerStreamChanged(value);
      break;
    case 160:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setDeleteVisionProgramToControllerStream(value);
      break;
    case 161:
      var value = new vivacity_config_controller_config_pb.VisionProgramToControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.VisionProgramToControllerStream.deserializeBinaryFromReader);
      msg.setVisionProgramToControllerStreamDeleted(value);
      break;
    case 162:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setCreatePhaseToImageSpaceZone(value);
      break;
    case 163:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceZoneCreated(value);
      break;
    case 164:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setChangePhaseToImageSpaceZone(value);
      break;
    case 165:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceZoneChanged(value);
      break;
    case 166:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setDeletePhaseToImageSpaceZone(value);
      break;
    case 167:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceZone;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceZone.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceZoneDeleted(value);
      break;
    case 170:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setCreatePhaseToImageSpaceCountline(value);
      break;
    case 171:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceCountlineCreated(value);
      break;
    case 172:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setChangePhaseToImageSpaceCountline(value);
      break;
    case 173:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceCountlineChanged(value);
      break;
    case 174:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setDeletePhaseToImageSpaceCountline(value);
      break;
    case 175:
      var value = new vivacity_config_controller_config_pb.PhaseToImageSpaceCountline;
      reader.readMessage(value,vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.deserializeBinaryFromReader);
      msg.setPhaseToImageSpaceCountlineDeleted(value);
      break;
    case 176:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setCreateAgentType(value);
      break;
    case 177:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setAgentTypeCreated(value);
      break;
    case 178:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setChangeAgentType(value);
      break;
    case 179:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setAgentTypeChanged(value);
      break;
    case 180:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setDeleteAgentType(value);
      break;
    case 181:
      var value = new vivacity_config_agent_config_pb.AgentType;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentType.deserializeBinaryFromReader);
      msg.setAgentTypeDeleted(value);
      break;
    case 182:
      var value = new vivacity_config_entity_groups_pb.EntityGroupGenealogy;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupGenealogy.deserializeBinaryFromReader);
      msg.setCreateEntityGroupGenealogy(value);
      break;
    case 183:
      var value = new vivacity_config_entity_groups_pb.EntityGroupGenealogy;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupGenealogy.deserializeBinaryFromReader);
      msg.setEntityGroupGenealogyCreated(value);
      break;
    case 184:
      var value = new vivacity_config_entity_groups_pb.EntityGroupGenealogy;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupGenealogy.deserializeBinaryFromReader);
      msg.setDeleteEntityGroupGenealogy(value);
      break;
    case 185:
      var value = new vivacity_config_entity_groups_pb.EntityGroupGenealogy;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupGenealogy.deserializeBinaryFromReader);
      msg.setEntityGroupGenealogyDeleted(value);
      break;
    case 186:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setCreateEntityGroup(value);
      break;
    case 187:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setEntityGroupCreated(value);
      break;
    case 188:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setChangeEntityGroupFields(value);
      break;
    case 189:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setEntityGroupFieldsChanged(value);
      break;
    case 190:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setDeleteEntityGroup(value);
      break;
    case 191:
      var value = new vivacity_config_entity_groups_pb.EntityGroup;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroup.deserializeBinaryFromReader);
      msg.setEntityGroupDeleted(value);
      break;
    case 192:
      var value = new vivacity_config_entity_groups_pb.EntityGroupLinkage;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupLinkage.deserializeBinaryFromReader);
      msg.setAddGroupLinkage(value);
      break;
    case 193:
      var value = new vivacity_config_entity_groups_pb.EntityGroupLinkage;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupLinkage.deserializeBinaryFromReader);
      msg.setGroupLinkageAdded(value);
      break;
    case 194:
      var value = new vivacity_config_entity_groups_pb.EntityGroupLinkage;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupLinkage.deserializeBinaryFromReader);
      msg.setDeleteGroupLinkage(value);
      break;
    case 195:
      var value = new vivacity_config_entity_groups_pb.EntityGroupLinkage;
      reader.readMessage(value,vivacity_config_entity_groups_pb.EntityGroupLinkage.deserializeBinaryFromReader);
      msg.setGroupLinkageDeleted(value);
      break;
    case 196:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setSetCommandGroupTemplate(value);
      break;
    case 197:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setCommandGroupTemplateSetted(value);
      break;
    case 198:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setCreateZonalWindowedClassConfig(value);
      break;
    case 199:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setZonalWindowedClassConfigCreated(value);
      break;
    case 200:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setChangeZonalWindowedClassConfig(value);
      break;
    case 201:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setZonalWindowedClassConfigChanged(value);
      break;
    case 202:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setDeleteZonalWindowedClassConfig(value);
      break;
    case 203:
      var value = new vivacity_config_zone_config_pb.ZonalWindowedClassConfig;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonalWindowedClassConfig.deserializeBinaryFromReader);
      msg.setZonalWindowedClassConfigDeleted(value);
      break;
    case 204:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setCreateControllerStreamControlSchedule(value);
      break;
    case 205:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setControllerStreamControlScheduleCreated(value);
      break;
    case 206:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setChangeControllerStreamControlSchedule(value);
      break;
    case 207:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setControllerStreamControlScheduleChanged(value);
      break;
    case 208:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setDeleteControllerStreamControlSchedule(value);
      break;
    case 209:
      var value = new vivacity_config_instation_config_pb.ControllerStreamControlSchedule;
      reader.readMessage(value,vivacity_config_instation_config_pb.ControllerStreamControlSchedule.deserializeBinaryFromReader);
      msg.setControllerStreamControlScheduleDeleted(value);
      break;
    case 210:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setCreateStageTransition(value);
      break;
    case 211:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setStageTransitionCreated(value);
      break;
    case 212:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setChangeStageTransition(value);
      break;
    case 213:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setStageTransitionChanged(value);
      break;
    case 214:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setDeleteStageTransition(value);
      break;
    case 215:
      var value = new vivacity_config_controller_config_pb.StageTransition;
      reader.readMessage(value,vivacity_config_controller_config_pb.StageTransition.deserializeBinaryFromReader);
      msg.setStageTransitionDeleted(value);
      break;
    case 216:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setCreateVpToVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 217:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpToVpWindowedFeaturesWebsocketConnectionConfigCreated(value);
      break;
    case 218:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setChangeVpToVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 219:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpToVpWindowedFeaturesWebsocketConnectionConfigChanged(value);
      break;
    case 220:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 221:
      var value = new vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted(value);
      break;
    case 222:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setCreateVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 223:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesWebsocketConnectionConfigCreated(value);
      break;
    case 224:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setChangeVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 225:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesWebsocketConnectionConfigChanged(value);
      break;
    case 226:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setDeleteVpWindowedFeaturesWebsocketConnectionConfig(value);
      break;
    case 227:
      var value = new vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader);
      msg.setVpWindowedFeaturesWebsocketConnectionConfigDeleted(value);
      break;
    case 228:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setCreateCvModel(value);
      break;
    case 229:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setCvModelCreated(value);
      break;
    case 230:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setChangeCvModel(value);
      break;
    case 231:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setCvModelChanged(value);
      break;
    case 232:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setDeleteCvModel(value);
      break;
    case 233:
      var value = new vivacity_config_cv_model_config_pb.CVModel;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModel.deserializeBinaryFromReader);
      msg.setCvModelDeleted(value);
      break;
    case 234:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setCreateCvModelType(value);
      break;
    case 235:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setCvModelTypeCreated(value);
      break;
    case 236:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setChangeCvModelType(value);
      break;
    case 237:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setCvModelTypeChanged(value);
      break;
    case 238:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setDeleteCvModelType(value);
      break;
    case 239:
      var value = new vivacity_config_cv_model_config_pb.CVModelType;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelType.deserializeBinaryFromReader);
      msg.setCvModelTypeDeleted(value);
      break;
    case 240:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setCreateCvModelClass(value);
      break;
    case 241:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setCvModelClassCreated(value);
      break;
    case 242:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setChangeCvModelClass(value);
      break;
    case 243:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setCvModelClassChanged(value);
      break;
    case 244:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setDeleteCvModelClass(value);
      break;
    case 245:
      var value = new vivacity_config_cv_model_config_pb.CVModelClass;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.CVModelClass.deserializeBinaryFromReader);
      msg.setCvModelClassDeleted(value);
      break;
    case 246:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setCreateVisionProgramPreset(value);
      break;
    case 247:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setVisionProgramPresetCreated(value);
      break;
    case 248:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setChangeVisionProgramPreset(value);
      break;
    case 249:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setVisionProgramPresetChanged(value);
      break;
    case 250:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setDeleteVisionProgramPreset(value);
      break;
    case 251:
      var value = new vivacity_config_cv_model_config_pb.VisionProgramPreset;
      reader.readMessage(value,vivacity_config_cv_model_config_pb.VisionProgramPreset.deserializeBinaryFromReader);
      msg.setVisionProgramPresetDeleted(value);
      break;
    case 258:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setCreateEntityTemplateType(value);
      break;
    case 259:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeCreated(value);
      break;
    case 260:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setChangeEntityTemplateType(value);
      break;
    case 261:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeChanged(value);
      break;
    case 262:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setDeleteEntityTemplateType(value);
      break;
    case 263:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateType;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateType.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeDeleted(value);
      break;
    case 264:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setCreateEntityTemplate(value);
      break;
    case 265:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setEntityTemplateCreated(value);
      break;
    case 266:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setChangeEntityTemplate(value);
      break;
    case 267:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setEntityTemplateChanged(value);
      break;
    case 268:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setDeleteEntityTemplate(value);
      break;
    case 269:
      var value = new vivacity_config_entity_templates_pb.EntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplate.deserializeBinaryFromReader);
      msg.setEntityTemplateDeleted(value);
      break;
    case 270:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setCreateRenderedEntityTemplate(value);
      break;
    case 271:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setRenderedEntityTemplateCreated(value);
      break;
    case 272:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setChangeRenderedEntityTemplate(value);
      break;
    case 273:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setRenderedEntityTemplateChanged(value);
      break;
    case 274:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setDeleteRenderedEntityTemplate(value);
      break;
    case 275:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setRenderedEntityTemplateDeleted(value);
      break;
    case 276:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setRenderEntityTemplate(value);
      break;
    case 277:
      var value = new vivacity_config_entity_templates_pb.RenderedEntityTemplate;
      reader.readMessage(value,vivacity_config_entity_templates_pb.RenderedEntityTemplate.deserializeBinaryFromReader);
      msg.setEntityTemplateRendered(value);
      break;
    case 278:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setCreateDynniqMessageClassGrouping(value);
      break;
    case 279:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setDynniqMessageClassGroupingCreated(value);
      break;
    case 280:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setChangeDynniqMessageClassGrouping(value);
      break;
    case 281:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setDynniqMessageClassGroupingChanged(value);
      break;
    case 282:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setDeleteDynniqMessageClassGrouping(value);
      break;
    case 283:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.deserializeBinaryFromReader);
      msg.setDynniqMessageClassGroupingDeleted(value);
      break;
    case 284:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setCreateDynniqMessageZone(value);
      break;
    case 285:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setDynniqMessageZoneCreated(value);
      break;
    case 286:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setChangeDynniqMessageZone(value);
      break;
    case 287:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setDynniqMessageZoneChanged(value);
      break;
    case 288:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setDeleteDynniqMessageZone(value);
      break;
    case 289:
      var value = new vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone;
      reader.readMessage(value,vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.deserializeBinaryFromReader);
      msg.setDynniqMessageZoneDeleted(value);
      break;
    case 290:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setCreateControllerHardwareModels(value);
      break;
    case 291:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setControllerHardwareModelsCreated(value);
      break;
    case 292:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setChangeControllerHardwareModels(value);
      break;
    case 293:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setControllerHardwareModelsChanged(value);
      break;
    case 294:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setDeleteControllerHardwareModels(value);
      break;
    case 295:
      var value = new vivacity_config_controller_config_pb.ControllerHardwareModels;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerHardwareModels.deserializeBinaryFromReader);
      msg.setControllerHardwareModelsDeleted(value);
      break;
    case 296:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setCreateHardwareManufacturers(value);
      break;
    case 297:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setHardwareManufacturersCreated(value);
      break;
    case 298:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setChangeHardwareManufacturers(value);
      break;
    case 299:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setHardwareManufacturersChanged(value);
      break;
    case 300:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setDeleteHardwareManufacturers(value);
      break;
    case 301:
      var value = new vivacity_config_hardware_pb.HardwareManufacturers;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareManufacturers.deserializeBinaryFromReader);
      msg.setHardwareManufacturersDeleted(value);
      break;
    case 302:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setCreateCountlineWindowedClassConfig(value);
      break;
    case 303:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setCountlineWindowedClassConfigCreated(value);
      break;
    case 304:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setChangeCountlineWindowedClassConfig(value);
      break;
    case 305:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setCountlineWindowedClassConfigChanged(value);
      break;
    case 306:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setDeleteCountlineWindowedClassConfig(value);
      break;
    case 307:
      var value = new vivacity_config_countline_config_pb.CountlineWindowedClassConfig;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineWindowedClassConfig.deserializeBinaryFromReader);
      msg.setCountlineWindowedClassConfigDeleted(value);
      break;
    case 308:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setCreateEntityTemplateTypeToEntity(value);
      break;
    case 309:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeToEntityCreated(value);
      break;
    case 310:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setChangeEntityTemplateTypeToEntity(value);
      break;
    case 311:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeToEntityChanged(value);
      break;
    case 312:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setDeleteEntityTemplateTypeToEntity(value);
      break;
    case 313:
      var value = new vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity;
      reader.readMessage(value,vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.deserializeBinaryFromReader);
      msg.setEntityTemplateTypeToEntityDeleted(value);
      break;
    case 314:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setCreateHardwarePosition(value);
      break;
    case 315:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setHardwarePositionCreated(value);
      break;
    case 316:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setChangeHardwarePosition(value);
      break;
    case 317:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setHardwarePositionChanged(value);
      break;
    case 318:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setDeleteHardwarePosition(value);
      break;
    case 319:
      var value = new vivacity_config_hardware_position_pb.HardwarePosition;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePosition.deserializeBinaryFromReader);
      msg.setHardwarePositionDeleted(value);
      break;
    case 320:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setCreateContractedHardware(value);
      break;
    case 321:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setContractedHardwareCreated(value);
      break;
    case 322:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setChangeContractedHardware(value);
      break;
    case 323:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setContractedHardwareChanged(value);
      break;
    case 324:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setDeleteContractedHardware(value);
      break;
    case 325:
      var value = new vivacity_config_contracted_hardware_pb.ContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractedHardware.deserializeBinaryFromReader);
      msg.setContractedHardwareDeleted(value);
      break;
    case 326:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setCreateCustomer(value);
      break;
    case 327:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setCustomerCreated(value);
      break;
    case 328:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setChangeCustomer(value);
      break;
    case 329:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setCustomerChanged(value);
      break;
    case 330:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setDeleteCustomer(value);
      break;
    case 331:
      var value = new vivacity_config_contracted_hardware_pb.Customers;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Customers.deserializeBinaryFromReader);
      msg.setCustomerDeleted(value);
      break;
    case 332:
      var value = new vivacity_config_contracted_hardware_pb.ContractsToContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.deserializeBinaryFromReader);
      msg.setLinkContractToContractedHardware(value);
      break;
    case 333:
      var value = new vivacity_config_contracted_hardware_pb.ContractsToContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.deserializeBinaryFromReader);
      msg.setContractToContractedHardwareLinked(value);
      break;
    case 334:
      var value = new vivacity_config_contracted_hardware_pb.ContractsToContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.deserializeBinaryFromReader);
      msg.setUnlinkContractToContractedHardware(value);
      break;
    case 335:
      var value = new vivacity_config_contracted_hardware_pb.ContractsToContractedHardware;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.deserializeBinaryFromReader);
      msg.setContractToContractedHardwareUnlinked(value);
      break;
    case 336:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToHardware.deserializeBinaryFromReader);
      msg.setLinkHardwarePositionToHardware(value);
      break;
    case 337:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToHardware.deserializeBinaryFromReader);
      msg.setHardwarePositionToHardwareLinked(value);
      break;
    case 338:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToHardware.deserializeBinaryFromReader);
      msg.setUnlinkHardwarePositionToHardware(value);
      break;
    case 339:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToHardware.deserializeBinaryFromReader);
      msg.setHardwarePositionToHardwareUnlinked(value);
      break;
    case 340:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.deserializeBinaryFromReader);
      msg.setLinkHardwarePositionToContractedHardware(value);
      break;
    case 341:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.deserializeBinaryFromReader);
      msg.setHardwarePositionToContractedHardwareLinked(value);
      break;
    case 342:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.deserializeBinaryFromReader);
      msg.setUnlinkHardwarePositionToContractedHardware(value);
      break;
    case 343:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.deserializeBinaryFromReader);
      msg.setHardwarePositionToContractedHardwareUnlinked(value);
      break;
    case 344:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.deserializeBinaryFromReader);
      msg.setLinkVisionProgramToHardwarePosition(value);
      break;
    case 345:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwarePositionLinked(value);
      break;
    case 346:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.deserializeBinaryFromReader);
      msg.setUnlinkVisionProgramToHardwarePosition(value);
      break;
    case 347:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwarePositionUnlinked(value);
      break;
    case 348:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setCreateContract(value);
      break;
    case 349:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setContractCreated(value);
      break;
    case 350:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setChangeContract(value);
      break;
    case 351:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setContractChanged(value);
      break;
    case 352:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setDeleteContract(value);
      break;
    case 353:
      var value = new vivacity_config_contracted_hardware_pb.Contracts;
      reader.readMessage(value,vivacity_config_contracted_hardware_pb.Contracts.deserializeBinaryFromReader);
      msg.setContractDeleted(value);
      break;
    case 354:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setCreateHardwarePositionDrawing(value);
      break;
    case 355:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setHardwarePositionDrawingCreated(value);
      break;
    case 356:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setChangeHardwarePositionDrawing(value);
      break;
    case 357:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setHardwarePositionDrawingChanged(value);
      break;
    case 358:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setDeleteHardwarePositionDrawing(value);
      break;
    case 359:
      var value = new vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing;
      reader.readMessage(value,vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.deserializeBinaryFromReader);
      msg.setHardwarePositionDrawingDeleted(value);
      break;
    case 360:
      var value = new vivacity_config_controller_config_pb.SetControlControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.SetControlControllerStream.deserializeBinaryFromReader);
      msg.setSetControlControllerStream(value);
      break;
    case 361:
      var value = new vivacity_config_controller_config_pb.SetControlControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.SetControlControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamControlSet(value);
      break;
    case 362:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setCreateZoneRealWorldSpace(value);
      break;
    case 363:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setZoneRealWorldSpaceCreated(value);
      break;
    case 364:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setChangeZoneRealWorldSpace(value);
      break;
    case 365:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setZoneRealWorldSpaceChanged(value);
      break;
    case 366:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setDeleteZoneRealWorldSpace(value);
      break;
    case 367:
      var value = new vivacity_config_zone_config_pb.ZoneRealWorldSpace;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZoneRealWorldSpace.deserializeBinaryFromReader);
      msg.setZoneRealWorldSpaceDeleted(value);
      break;
    case 368:
      var value = new proto.vivacity.config.HybridTransactionalMessage;
      reader.readMessage(value,proto.vivacity.config.HybridTransactionalMessage.deserializeBinaryFromReader);
      msg.setHybridMessageRequest(value);
      break;
    case 369:
      var value = new proto.vivacity.config.HybridTransactionalMessage;
      reader.readMessage(value,proto.vivacity.config.HybridTransactionalMessage.deserializeBinaryFromReader);
      msg.setHybridMessageResponse(value);
      break;
    case 370:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionStatusOnly;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.deserializeBinaryFromReader);
      msg.setChangeHardwarePositionStatus(value);
      break;
    case 371:
      var value = new vivacity_config_hardware_position_pb.HardwarePositionStatusOnly;
      reader.readMessage(value,vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.deserializeBinaryFromReader);
      msg.setHardwarePositionStatusChanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ConfigMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ConfigMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ConfigMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getBatchedConfigMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getReducedBatchedConfigMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getChangeZoneImageSpace();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_zone_config_pb.ZonesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getZoneImageSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_zone_config_pb.ZonesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountlineImageSpace();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangePerspectiveTransform();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.serializeBinaryToWriter
    );
  }
  f = message.getPerspectiveTransformChanged();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountlineRealWorldSpace();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getCountlineRealWorldSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangeZonalAlert();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_alerts_config_pb.ZonalAlertConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getZonalAlertChanged();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_alerts_config_pb.ZonalAlertConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getGetHardwareId();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_hardware_pb.GetOrCreateHardwareId.serializeBinaryToWriter
    );
  }
  f = message.getCreateHardwareId();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_hardware_pb.GetOrCreateHardwareId.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdTokenResponse();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_hardware_pb.HardwareIdTokenResponse.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdCreated();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_hardware_pb.HardwareIdCreated.serializeBinaryToWriter
    );
  }
  f = message.getVaultResourceCreated();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_vault_pb.VaultResourceCreated.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwareFields();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_hardware_pb.HardwareFields.serializeBinaryToWriter
    );
  }
  f = message.getHardwareFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_config_hardware_pb.HardwareFields.serializeBinaryToWriter
    );
  }
  f = message.getCreateVisionProgram();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getVisionProgramCreated();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getChangeVisionProgramFields();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramFields.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramFields.serializeBinaryToWriter
    );
  }
  f = message.getLinkCountlineImageSpaceToRealWorld();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceToRealWorldLinked();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkCountlineImageSpaceToRealWorld();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceToRealWorldUnlinked();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCreateFileTemplate();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getChangeFileTemplateFields();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getDeleteFileTemplate();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateDeleted();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getCreateRenderedFileEntry();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderedFileEntryCreated();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getDeleteRenderedFile();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderedFileDeleted();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderFile();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getFileRendered();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getUpdateFileOnHardware();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getFileOnHardwareUpdated();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRemoteFileStateOnDbUpdated();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRetrieveRenderedFiles();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      vivacity_config_file_templates_pb.RetrieveFile.serializeBinaryToWriter
    );
  }
  f = message.getLinkVisionProgramToHardware();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwareLinked();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkVisionProgramToHardware();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwareUnlinked();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getChangeLinkageCronjobToHardware();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      vivacity_config_cronjob_pb.CronjobToHardwareLinkage.serializeBinaryToWriter
    );
  }
  f = message.getCronjobToHardwareLinkageChanged();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      vivacity_config_cronjob_pb.CronjobToHardwareLinkage.serializeBinaryToWriter
    );
  }
  f = message.getChangeCronjob();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      vivacity_config_cronjob_pb.Cronjob.serializeBinaryToWriter
    );
  }
  f = message.getCronjobChanged();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      vivacity_config_cronjob_pb.Cronjob.serializeBinaryToWriter
    );
  }
  f = message.getCreateBenchmarkRun();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.serializeBinaryToWriter
    );
  }
  f = message.getCreateCommandGroupTemplate();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCommandGroupTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgent();
  if (f != null) {
    writer.writeMessage(
      72,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentCreated();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgent();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentChanged();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgent();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentDeleted();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgentStream();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamCreated();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgentStream();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamChanged();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgentStream();
  if (f != null) {
    writer.writeMessage(
      82,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamDeleted();
  if (f != null) {
    writer.writeMessage(
      83,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getCreateCountry();
  if (f != null) {
    writer.writeMessage(
      84,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryCreated();
  if (f != null) {
    writer.writeMessage(
      85,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountry();
  if (f != null) {
    writer.writeMessage(
      86,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryChanged();
  if (f != null) {
    writer.writeMessage(
      87,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCountry();
  if (f != null) {
    writer.writeMessage(
      88,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryDeleted();
  if (f != null) {
    writer.writeMessage(
      89,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCreateRegion();
  if (f != null) {
    writer.writeMessage(
      90,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionCreated();
  if (f != null) {
    writer.writeMessage(
      91,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getChangeRegion();
  if (f != null) {
    writer.writeMessage(
      92,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionChanged();
  if (f != null) {
    writer.writeMessage(
      93,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getDeleteRegion();
  if (f != null) {
    writer.writeMessage(
      94,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionDeleted();
  if (f != null) {
    writer.writeMessage(
      95,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getCreateController();
  if (f != null) {
    writer.writeMessage(
      96,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerCreated();
  if (f != null) {
    writer.writeMessage(
      97,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getChangeController();
  if (f != null) {
    writer.writeMessage(
      98,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerChanged();
  if (f != null) {
    writer.writeMessage(
      99,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getDeleteController();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerDeleted();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getCreateControllerStream();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamCreated();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getChangeControllerStream();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamChanged();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getDeleteControllerStream();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamDeleted();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getCreateVelocity();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityCreated();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getChangeVelocity();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityChanged();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVelocity();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityDeleted();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgentConfig();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigCreated();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgentConfig();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigChanged();
  if (f != null) {
    writer.writeMessage(
      117,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgentConfig();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreateControlReplyBit();
  if (f != null) {
    writer.writeMessage(
      120,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getControlReplyBitCreated();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getChangeControlReplyBit();
  if (f != null) {
    writer.writeMessage(
      122,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getControlReplyBitChanged();
  if (f != null) {
    writer.writeMessage(
      123,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getDeleteControlReplyBit();
  if (f != null) {
    writer.writeMessage(
      124,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getControlReplyBitDeleted();
  if (f != null) {
    writer.writeMessage(
      125,
      f,
      vivacity_config_controller_config_pb.ControlReplyBit.serializeBinaryToWriter
    );
  }
  f = message.getCreateStage();
  if (f != null) {
    writer.writeMessage(
      126,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getStageCreated();
  if (f != null) {
    writer.writeMessage(
      127,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getChangeStage();
  if (f != null) {
    writer.writeMessage(
      128,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getStageChanged();
  if (f != null) {
    writer.writeMessage(
      129,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getDeleteStage();
  if (f != null) {
    writer.writeMessage(
      130,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getStageDeleted();
  if (f != null) {
    writer.writeMessage(
      131,
      f,
      vivacity_config_controller_config_pb.Stage.serializeBinaryToWriter
    );
  }
  f = message.getCreatePhase();
  if (f != null) {
    writer.writeMessage(
      132,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getPhaseCreated();
  if (f != null) {
    writer.writeMessage(
      133,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getChangePhase();
  if (f != null) {
    writer.writeMessage(
      134,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getPhaseChanged();
  if (f != null) {
    writer.writeMessage(
      135,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getDeletePhase();
  if (f != null) {
    writer.writeMessage(
      136,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getPhaseDeleted();
  if (f != null) {
    writer.writeMessage(
      137,
      f,
      vivacity_config_controller_config_pb.Phase.serializeBinaryToWriter
    );
  }
  f = message.getCreateViu();
  if (f != null) {
    writer.writeMessage(
      138,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getViuCreated();
  if (f != null) {
    writer.writeMessage(
      139,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getChangeViu();
  if (f != null) {
    writer.writeMessage(
      140,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getViuChanged();
  if (f != null) {
    writer.writeMessage(
      141,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getDeleteViu();
  if (f != null) {
    writer.writeMessage(
      142,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getViuDeleted();
  if (f != null) {
    writer.writeMessage(
      143,
      f,
      vivacity_config_viu_config_pb.Viu.serializeBinaryToWriter
    );
  }
  f = message.getCreateDetoxicity();
  if (f != null) {
    writer.writeMessage(
      144,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getDetoxicityCreated();
  if (f != null) {
    writer.writeMessage(
      145,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getChangeDetoxicity();
  if (f != null) {
    writer.writeMessage(
      146,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getDetoxicityChanged();
  if (f != null) {
    writer.writeMessage(
      147,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getDeleteDetoxicity();
  if (f != null) {
    writer.writeMessage(
      148,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getDetoxicityDeleted();
  if (f != null) {
    writer.writeMessage(
      149,
      f,
      vivacity_config_instation_config_pb.Detoxicity.serializeBinaryToWriter
    );
  }
  f = message.getCreateStageToPhase();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getStageToPhaseCreated();
  if (f != null) {
    writer.writeMessage(
      151,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getChangeStageToPhase();
  if (f != null) {
    writer.writeMessage(
      152,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getStageToPhaseChanged();
  if (f != null) {
    writer.writeMessage(
      153,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getDeleteStageToPhase();
  if (f != null) {
    writer.writeMessage(
      154,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getStageToPhaseDeleted();
  if (f != null) {
    writer.writeMessage(
      155,
      f,
      vivacity_config_controller_config_pb.StageToPhase.serializeBinaryToWriter
    );
  }
  f = message.getCreateVisionProgramToControllerStream();
  if (f != null) {
    writer.writeMessage(
      156,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToControllerStreamCreated();
  if (f != null) {
    writer.writeMessage(
      157,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getChangeVisionProgramToControllerStream();
  if (f != null) {
    writer.writeMessage(
      158,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToControllerStreamChanged();
  if (f != null) {
    writer.writeMessage(
      159,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVisionProgramToControllerStream();
  if (f != null) {
    writer.writeMessage(
      160,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToControllerStreamDeleted();
  if (f != null) {
    writer.writeMessage(
      161,
      f,
      vivacity_config_controller_config_pb.VisionProgramToControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getCreatePhaseToImageSpaceZone();
  if (f != null) {
    writer.writeMessage(
      162,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceZoneCreated();
  if (f != null) {
    writer.writeMessage(
      163,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getChangePhaseToImageSpaceZone();
  if (f != null) {
    writer.writeMessage(
      164,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceZoneChanged();
  if (f != null) {
    writer.writeMessage(
      165,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getDeletePhaseToImageSpaceZone();
  if (f != null) {
    writer.writeMessage(
      166,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceZoneDeleted();
  if (f != null) {
    writer.writeMessage(
      167,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceZone.serializeBinaryToWriter
    );
  }
  f = message.getCreatePhaseToImageSpaceCountline();
  if (f != null) {
    writer.writeMessage(
      170,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceCountlineCreated();
  if (f != null) {
    writer.writeMessage(
      171,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getChangePhaseToImageSpaceCountline();
  if (f != null) {
    writer.writeMessage(
      172,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceCountlineChanged();
  if (f != null) {
    writer.writeMessage(
      173,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getDeletePhaseToImageSpaceCountline();
  if (f != null) {
    writer.writeMessage(
      174,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getPhaseToImageSpaceCountlineDeleted();
  if (f != null) {
    writer.writeMessage(
      175,
      f,
      vivacity_config_controller_config_pb.PhaseToImageSpaceCountline.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgentType();
  if (f != null) {
    writer.writeMessage(
      176,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getAgentTypeCreated();
  if (f != null) {
    writer.writeMessage(
      177,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgentType();
  if (f != null) {
    writer.writeMessage(
      178,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getAgentTypeChanged();
  if (f != null) {
    writer.writeMessage(
      179,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgentType();
  if (f != null) {
    writer.writeMessage(
      180,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getAgentTypeDeleted();
  if (f != null) {
    writer.writeMessage(
      181,
      f,
      vivacity_config_agent_config_pb.AgentType.serializeBinaryToWriter
    );
  }
  f = message.getCreateEntityGroupGenealogy();
  if (f != null) {
    writer.writeMessage(
      182,
      f,
      vivacity_config_entity_groups_pb.EntityGroupGenealogy.serializeBinaryToWriter
    );
  }
  f = message.getEntityGroupGenealogyCreated();
  if (f != null) {
    writer.writeMessage(
      183,
      f,
      vivacity_config_entity_groups_pb.EntityGroupGenealogy.serializeBinaryToWriter
    );
  }
  f = message.getDeleteEntityGroupGenealogy();
  if (f != null) {
    writer.writeMessage(
      184,
      f,
      vivacity_config_entity_groups_pb.EntityGroupGenealogy.serializeBinaryToWriter
    );
  }
  f = message.getEntityGroupGenealogyDeleted();
  if (f != null) {
    writer.writeMessage(
      185,
      f,
      vivacity_config_entity_groups_pb.EntityGroupGenealogy.serializeBinaryToWriter
    );
  }
  f = message.getCreateEntityGroup();
  if (f != null) {
    writer.writeMessage(
      186,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getEntityGroupCreated();
  if (f != null) {
    writer.writeMessage(
      187,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getChangeEntityGroupFields();
  if (f != null) {
    writer.writeMessage(
      188,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getEntityGroupFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      189,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getDeleteEntityGroup();
  if (f != null) {
    writer.writeMessage(
      190,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getEntityGroupDeleted();
  if (f != null) {
    writer.writeMessage(
      191,
      f,
      vivacity_config_entity_groups_pb.EntityGroup.serializeBinaryToWriter
    );
  }
  f = message.getAddGroupLinkage();
  if (f != null) {
    writer.writeMessage(
      192,
      f,
      vivacity_config_entity_groups_pb.EntityGroupLinkage.serializeBinaryToWriter
    );
  }
  f = message.getGroupLinkageAdded();
  if (f != null) {
    writer.writeMessage(
      193,
      f,
      vivacity_config_entity_groups_pb.EntityGroupLinkage.serializeBinaryToWriter
    );
  }
  f = message.getDeleteGroupLinkage();
  if (f != null) {
    writer.writeMessage(
      194,
      f,
      vivacity_config_entity_groups_pb.EntityGroupLinkage.serializeBinaryToWriter
    );
  }
  f = message.getGroupLinkageDeleted();
  if (f != null) {
    writer.writeMessage(
      195,
      f,
      vivacity_config_entity_groups_pb.EntityGroupLinkage.serializeBinaryToWriter
    );
  }
  f = message.getSetCommandGroupTemplate();
  if (f != null) {
    writer.writeMessage(
      196,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCommandGroupTemplateSetted();
  if (f != null) {
    writer.writeMessage(
      197,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCreateZonalWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      198,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedClassConfigCreated();
  if (f != null) {
    writer.writeMessage(
      199,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeZonalWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      200,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedClassConfigChanged();
  if (f != null) {
    writer.writeMessage(
      201,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteZonalWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      202,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedClassConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      203,
      f,
      vivacity_config_zone_config_pb.ZonalWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreateControllerStreamControlSchedule();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamControlScheduleCreated();
  if (f != null) {
    writer.writeMessage(
      205,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getChangeControllerStreamControlSchedule();
  if (f != null) {
    writer.writeMessage(
      206,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamControlScheduleChanged();
  if (f != null) {
    writer.writeMessage(
      207,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getDeleteControllerStreamControlSchedule();
  if (f != null) {
    writer.writeMessage(
      208,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamControlScheduleDeleted();
  if (f != null) {
    writer.writeMessage(
      209,
      f,
      vivacity_config_instation_config_pb.ControllerStreamControlSchedule.serializeBinaryToWriter
    );
  }
  f = message.getCreateStageTransition();
  if (f != null) {
    writer.writeMessage(
      210,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getStageTransitionCreated();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getChangeStageTransition();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getStageTransitionChanged();
  if (f != null) {
    writer.writeMessage(
      213,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getDeleteStageTransition();
  if (f != null) {
    writer.writeMessage(
      214,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getStageTransitionDeleted();
  if (f != null) {
    writer.writeMessage(
      215,
      f,
      vivacity_config_controller_config_pb.StageTransition.serializeBinaryToWriter
    );
  }
  f = message.getCreateVpToVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      216,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpToVpWindowedFeaturesWebsocketConnectionConfigCreated();
  if (f != null) {
    writer.writeMessage(
      217,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeVpToVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      218,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpToVpWindowedFeaturesWebsocketConnectionConfigChanged();
  if (f != null) {
    writer.writeMessage(
      219,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      220,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      221,
      f,
      vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreateVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      222,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesWebsocketConnectionConfigCreated();
  if (f != null) {
    writer.writeMessage(
      223,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      224,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesWebsocketConnectionConfigChanged();
  if (f != null) {
    writer.writeMessage(
      225,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVpWindowedFeaturesWebsocketConnectionConfig();
  if (f != null) {
    writer.writeMessage(
      226,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesWebsocketConnectionConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      227,
      f,
      vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreateCvModel();
  if (f != null) {
    writer.writeMessage(
      228,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getCvModelCreated();
  if (f != null) {
    writer.writeMessage(
      229,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getChangeCvModel();
  if (f != null) {
    writer.writeMessage(
      230,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getCvModelChanged();
  if (f != null) {
    writer.writeMessage(
      231,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCvModel();
  if (f != null) {
    writer.writeMessage(
      232,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getCvModelDeleted();
  if (f != null) {
    writer.writeMessage(
      233,
      f,
      vivacity_config_cv_model_config_pb.CVModel.serializeBinaryToWriter
    );
  }
  f = message.getCreateCvModelType();
  if (f != null) {
    writer.writeMessage(
      234,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getCvModelTypeCreated();
  if (f != null) {
    writer.writeMessage(
      235,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getChangeCvModelType();
  if (f != null) {
    writer.writeMessage(
      236,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getCvModelTypeChanged();
  if (f != null) {
    writer.writeMessage(
      237,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCvModelType();
  if (f != null) {
    writer.writeMessage(
      238,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getCvModelTypeDeleted();
  if (f != null) {
    writer.writeMessage(
      239,
      f,
      vivacity_config_cv_model_config_pb.CVModelType.serializeBinaryToWriter
    );
  }
  f = message.getCreateCvModelClass();
  if (f != null) {
    writer.writeMessage(
      240,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getCvModelClassCreated();
  if (f != null) {
    writer.writeMessage(
      241,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getChangeCvModelClass();
  if (f != null) {
    writer.writeMessage(
      242,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getCvModelClassChanged();
  if (f != null) {
    writer.writeMessage(
      243,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCvModelClass();
  if (f != null) {
    writer.writeMessage(
      244,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getCvModelClassDeleted();
  if (f != null) {
    writer.writeMessage(
      245,
      f,
      vivacity_config_cv_model_config_pb.CVModelClass.serializeBinaryToWriter
    );
  }
  f = message.getCreateVisionProgramPreset();
  if (f != null) {
    writer.writeMessage(
      246,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramPresetCreated();
  if (f != null) {
    writer.writeMessage(
      247,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getChangeVisionProgramPreset();
  if (f != null) {
    writer.writeMessage(
      248,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramPresetChanged();
  if (f != null) {
    writer.writeMessage(
      249,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVisionProgramPreset();
  if (f != null) {
    writer.writeMessage(
      250,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramPresetDeleted();
  if (f != null) {
    writer.writeMessage(
      251,
      f,
      vivacity_config_cv_model_config_pb.VisionProgramPreset.serializeBinaryToWriter
    );
  }
  f = message.getCreateEntityTemplateType();
  if (f != null) {
    writer.writeMessage(
      258,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeCreated();
  if (f != null) {
    writer.writeMessage(
      259,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getChangeEntityTemplateType();
  if (f != null) {
    writer.writeMessage(
      260,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeChanged();
  if (f != null) {
    writer.writeMessage(
      261,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getDeleteEntityTemplateType();
  if (f != null) {
    writer.writeMessage(
      262,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeDeleted();
  if (f != null) {
    writer.writeMessage(
      263,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateType.serializeBinaryToWriter
    );
  }
  f = message.getCreateEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      264,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      265,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getChangeEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      266,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateChanged();
  if (f != null) {
    writer.writeMessage(
      267,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getDeleteEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      268,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateDeleted();
  if (f != null) {
    writer.writeMessage(
      269,
      f,
      vivacity_config_entity_templates_pb.EntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getCreateRenderedEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      270,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getRenderedEntityTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      271,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getChangeRenderedEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      272,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getRenderedEntityTemplateChanged();
  if (f != null) {
    writer.writeMessage(
      273,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getDeleteRenderedEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      274,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getRenderedEntityTemplateDeleted();
  if (f != null) {
    writer.writeMessage(
      275,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getRenderEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      276,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateRendered();
  if (f != null) {
    writer.writeMessage(
      277,
      f,
      vivacity_config_entity_templates_pb.RenderedEntityTemplate.serializeBinaryToWriter
    );
  }
  f = message.getCreateDynniqMessageClassGrouping();
  if (f != null) {
    writer.writeMessage(
      278,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageClassGroupingCreated();
  if (f != null) {
    writer.writeMessage(
      279,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getChangeDynniqMessageClassGrouping();
  if (f != null) {
    writer.writeMessage(
      280,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageClassGroupingChanged();
  if (f != null) {
    writer.writeMessage(
      281,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getDeleteDynniqMessageClassGrouping();
  if (f != null) {
    writer.writeMessage(
      282,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageClassGroupingDeleted();
  if (f != null) {
    writer.writeMessage(
      283,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping.serializeBinaryToWriter
    );
  }
  f = message.getCreateDynniqMessageZone();
  if (f != null) {
    writer.writeMessage(
      284,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageZoneCreated();
  if (f != null) {
    writer.writeMessage(
      285,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getChangeDynniqMessageZone();
  if (f != null) {
    writer.writeMessage(
      286,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageZoneChanged();
  if (f != null) {
    writer.writeMessage(
      287,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getDeleteDynniqMessageZone();
  if (f != null) {
    writer.writeMessage(
      288,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getDynniqMessageZoneDeleted();
  if (f != null) {
    writer.writeMessage(
      289,
      f,
      vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone.serializeBinaryToWriter
    );
  }
  f = message.getCreateControllerHardwareModels();
  if (f != null) {
    writer.writeMessage(
      290,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getControllerHardwareModelsCreated();
  if (f != null) {
    writer.writeMessage(
      291,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getChangeControllerHardwareModels();
  if (f != null) {
    writer.writeMessage(
      292,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getControllerHardwareModelsChanged();
  if (f != null) {
    writer.writeMessage(
      293,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getDeleteControllerHardwareModels();
  if (f != null) {
    writer.writeMessage(
      294,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getControllerHardwareModelsDeleted();
  if (f != null) {
    writer.writeMessage(
      295,
      f,
      vivacity_config_controller_config_pb.ControllerHardwareModels.serializeBinaryToWriter
    );
  }
  f = message.getCreateHardwareManufacturers();
  if (f != null) {
    writer.writeMessage(
      296,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getHardwareManufacturersCreated();
  if (f != null) {
    writer.writeMessage(
      297,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwareManufacturers();
  if (f != null) {
    writer.writeMessage(
      298,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getHardwareManufacturersChanged();
  if (f != null) {
    writer.writeMessage(
      299,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getDeleteHardwareManufacturers();
  if (f != null) {
    writer.writeMessage(
      300,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getHardwareManufacturersDeleted();
  if (f != null) {
    writer.writeMessage(
      301,
      f,
      vivacity_config_hardware_pb.HardwareManufacturers.serializeBinaryToWriter
    );
  }
  f = message.getCreateCountlineWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      302,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getCountlineWindowedClassConfigCreated();
  if (f != null) {
    writer.writeMessage(
      303,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountlineWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      304,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getCountlineWindowedClassConfigChanged();
  if (f != null) {
    writer.writeMessage(
      305,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCountlineWindowedClassConfig();
  if (f != null) {
    writer.writeMessage(
      306,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getCountlineWindowedClassConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      307,
      f,
      vivacity_config_countline_config_pb.CountlineWindowedClassConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreateEntityTemplateTypeToEntity();
  if (f != null) {
    writer.writeMessage(
      308,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeToEntityCreated();
  if (f != null) {
    writer.writeMessage(
      309,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getChangeEntityTemplateTypeToEntity();
  if (f != null) {
    writer.writeMessage(
      310,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeToEntityChanged();
  if (f != null) {
    writer.writeMessage(
      311,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getDeleteEntityTemplateTypeToEntity();
  if (f != null) {
    writer.writeMessage(
      312,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getEntityTemplateTypeToEntityDeleted();
  if (f != null) {
    writer.writeMessage(
      313,
      f,
      vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity.serializeBinaryToWriter
    );
  }
  f = message.getCreateHardwarePosition();
  if (f != null) {
    writer.writeMessage(
      314,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionCreated();
  if (f != null) {
    writer.writeMessage(
      315,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwarePosition();
  if (f != null) {
    writer.writeMessage(
      316,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionChanged();
  if (f != null) {
    writer.writeMessage(
      317,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getDeleteHardwarePosition();
  if (f != null) {
    writer.writeMessage(
      318,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionDeleted();
  if (f != null) {
    writer.writeMessage(
      319,
      f,
      vivacity_config_hardware_position_pb.HardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getCreateContractedHardware();
  if (f != null) {
    writer.writeMessage(
      320,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getContractedHardwareCreated();
  if (f != null) {
    writer.writeMessage(
      321,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getChangeContractedHardware();
  if (f != null) {
    writer.writeMessage(
      322,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getContractedHardwareChanged();
  if (f != null) {
    writer.writeMessage(
      323,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getDeleteContractedHardware();
  if (f != null) {
    writer.writeMessage(
      324,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getContractedHardwareDeleted();
  if (f != null) {
    writer.writeMessage(
      325,
      f,
      vivacity_config_contracted_hardware_pb.ContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getCreateCustomer();
  if (f != null) {
    writer.writeMessage(
      326,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getCustomerCreated();
  if (f != null) {
    writer.writeMessage(
      327,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getChangeCustomer();
  if (f != null) {
    writer.writeMessage(
      328,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getCustomerChanged();
  if (f != null) {
    writer.writeMessage(
      329,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCustomer();
  if (f != null) {
    writer.writeMessage(
      330,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getCustomerDeleted();
  if (f != null) {
    writer.writeMessage(
      331,
      f,
      vivacity_config_contracted_hardware_pb.Customers.serializeBinaryToWriter
    );
  }
  f = message.getLinkContractToContractedHardware();
  if (f != null) {
    writer.writeMessage(
      332,
      f,
      vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getContractToContractedHardwareLinked();
  if (f != null) {
    writer.writeMessage(
      333,
      f,
      vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkContractToContractedHardware();
  if (f != null) {
    writer.writeMessage(
      334,
      f,
      vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getContractToContractedHardwareUnlinked();
  if (f != null) {
    writer.writeMessage(
      335,
      f,
      vivacity_config_contracted_hardware_pb.ContractsToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getLinkHardwarePositionToHardware();
  if (f != null) {
    writer.writeMessage(
      336,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToHardware.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionToHardwareLinked();
  if (f != null) {
    writer.writeMessage(
      337,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToHardware.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkHardwarePositionToHardware();
  if (f != null) {
    writer.writeMessage(
      338,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToHardware.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionToHardwareUnlinked();
  if (f != null) {
    writer.writeMessage(
      339,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToHardware.serializeBinaryToWriter
    );
  }
  f = message.getLinkHardwarePositionToContractedHardware();
  if (f != null) {
    writer.writeMessage(
      340,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionToContractedHardwareLinked();
  if (f != null) {
    writer.writeMessage(
      341,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkHardwarePositionToContractedHardware();
  if (f != null) {
    writer.writeMessage(
      342,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionToContractedHardwareUnlinked();
  if (f != null) {
    writer.writeMessage(
      343,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware.serializeBinaryToWriter
    );
  }
  f = message.getLinkVisionProgramToHardwarePosition();
  if (f != null) {
    writer.writeMessage(
      344,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwarePositionLinked();
  if (f != null) {
    writer.writeMessage(
      345,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkVisionProgramToHardwarePosition();
  if (f != null) {
    writer.writeMessage(
      346,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwarePositionUnlinked();
  if (f != null) {
    writer.writeMessage(
      347,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition.serializeBinaryToWriter
    );
  }
  f = message.getCreateContract();
  if (f != null) {
    writer.writeMessage(
      348,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getContractCreated();
  if (f != null) {
    writer.writeMessage(
      349,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getChangeContract();
  if (f != null) {
    writer.writeMessage(
      350,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getContractChanged();
  if (f != null) {
    writer.writeMessage(
      351,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getDeleteContract();
  if (f != null) {
    writer.writeMessage(
      352,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getContractDeleted();
  if (f != null) {
    writer.writeMessage(
      353,
      f,
      vivacity_config_contracted_hardware_pb.Contracts.serializeBinaryToWriter
    );
  }
  f = message.getCreateHardwarePositionDrawing();
  if (f != null) {
    writer.writeMessage(
      354,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionDrawingCreated();
  if (f != null) {
    writer.writeMessage(
      355,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwarePositionDrawing();
  if (f != null) {
    writer.writeMessage(
      356,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionDrawingChanged();
  if (f != null) {
    writer.writeMessage(
      357,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getDeleteHardwarePositionDrawing();
  if (f != null) {
    writer.writeMessage(
      358,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionDrawingDeleted();
  if (f != null) {
    writer.writeMessage(
      359,
      f,
      vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing.serializeBinaryToWriter
    );
  }
  f = message.getSetControlControllerStream();
  if (f != null) {
    writer.writeMessage(
      360,
      f,
      vivacity_config_controller_config_pb.SetControlControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamControlSet();
  if (f != null) {
    writer.writeMessage(
      361,
      f,
      vivacity_config_controller_config_pb.SetControlControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getCreateZoneRealWorldSpace();
  if (f != null) {
    writer.writeMessage(
      362,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getZoneRealWorldSpaceCreated();
  if (f != null) {
    writer.writeMessage(
      363,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getChangeZoneRealWorldSpace();
  if (f != null) {
    writer.writeMessage(
      364,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getZoneRealWorldSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      365,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getDeleteZoneRealWorldSpace();
  if (f != null) {
    writer.writeMessage(
      366,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getZoneRealWorldSpaceDeleted();
  if (f != null) {
    writer.writeMessage(
      367,
      f,
      vivacity_config_zone_config_pb.ZoneRealWorldSpace.serializeBinaryToWriter
    );
  }
  f = message.getHybridMessageRequest();
  if (f != null) {
    writer.writeMessage(
      368,
      f,
      proto.vivacity.config.HybridTransactionalMessage.serializeBinaryToWriter
    );
  }
  f = message.getHybridMessageResponse();
  if (f != null) {
    writer.writeMessage(
      369,
      f,
      proto.vivacity.config.HybridTransactionalMessage.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwarePositionStatus();
  if (f != null) {
    writer.writeMessage(
      370,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.serializeBinaryToWriter
    );
  }
  f = message.getHardwarePositionStatusChanged();
  if (f != null) {
    writer.writeMessage(
      371,
      f,
      vivacity_config_hardware_position_pb.HardwarePositionStatusOnly.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ConfigMessage.EventMessageType = {
  UNKNOWN_EVENT_MESSAGE_TYPE: 0,
  BATCHED_CONFIG_MESSAGES: 7,
  REDUCED_BATCHED_CONFIG_MESSAGES: 8,
  CHANGE_ZONE_IMAGE_SPACE: 11,
  ZONE_IMAGE_SPACE_CHANGED: 12,
  CHANGE_COUNTLINE_IMAGE_SPACE: 13,
  COUNTLINE_IMAGE_SPACE_CHANGED: 14,
  CHANGE_PERSPECTIVE_TRANSFORM: 15,
  PERSPECTIVE_TRANSFORM_CHANGED: 16,
  CHANGE_COUNTLINE_REAL_WORLD_SPACE: 17,
  COUNTLINE_REAL_WORLD_SPACE_CHANGED: 18,
  CHANGE_ZONAL_ALERT: 19,
  ZONAL_ALERT_CHANGED: 20,
  GET_HARDWARE_ID: 21,
  CREATE_HARDWARE_ID: 22,
  HARDWARE_ID_TOKEN_RESPONSE: 23,
  HARDWARE_ID_CREATED: 24,
  VAULT_RESOURCE_CREATED: 25,
  CHANGE_HARDWARE_FIELDS: 26,
  HARDWARE_FIELDS_CHANGED: 27,
  CREATE_VISION_PROGRAM: 31,
  VISION_PROGRAM_CREATED: 32,
  CHANGE_VISION_PROGRAM_FIELDS: 33,
  VISION_PROGRAM_FIELDS_CHANGED: 34,
  LINK_COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD: 35,
  COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD_LINKED: 36,
  UNLINK_COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD: 37,
  COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD_UNLINKED: 38,
  CREATE_FILE_TEMPLATE: 40,
  FILE_TEMPLATE_CREATED: 41,
  CHANGE_FILE_TEMPLATE_FIELDS: 42,
  FILE_TEMPLATE_FIELDS_CHANGED: 43,
  DELETE_FILE_TEMPLATE: 44,
  FILE_TEMPLATE_DELETED: 45,
  CREATE_RENDERED_FILE_ENTRY: 46,
  RENDERED_FILE_ENTRY_CREATED: 47,
  DELETE_RENDERED_FILE: 48,
  RENDERED_FILE_DELETED: 49,
  RENDER_FILE: 50,
  FILE_RENDERED: 51,
  UPDATE_FILE_ON_HARDWARE: 52,
  FILE_ON_HARDWARE_UPDATED: 53,
  REMOTE_FILE_STATE_ON_DB_UPDATED: 54,
  RETRIEVE_RENDERED_FILES: 55,
  LINK_VISION_PROGRAM_TO_HARDWARE: 60,
  VISION_PROGRAM_TO_HARDWARE_LINKED: 61,
  UNLINK_VISION_PROGRAM_TO_HARDWARE: 62,
  VISION_PROGRAM_TO_HARDWARE_UNLINKED: 63,
  CHANGE_LINKAGE_CRONJOB_TO_HARDWARE: 64,
  CRONJOB_TO_HARDWARE_LINKAGE_CHANGED: 65,
  CHANGE_CRONJOB: 66,
  CRONJOB_CHANGED: 67,
  CREATE_BENCHMARK_RUN: 70,
  BENCHMARK_RUN_CREATED: 71,
  CREATE_COMMAND_GROUP_TEMPLATE: 72,
  COMMAND_GROUP_TEMPLATE_CREATED: 73,
  CREATE_AGENT: 74,
  AGENT_CREATED: 75,
  CHANGE_AGENT: 76,
  AGENT_CHANGED: 77,
  DELETE_AGENT: 78,
  AGENT_DELETED: 79,
  CREATE_AGENT_STREAM: 80,
  AGENT_STREAM_CREATED: 81,
  CHANGE_AGENT_STREAM: 82,
  AGENT_STREAM_CHANGED: 83,
  DELETE_AGENT_STREAM: 84,
  AGENT_STREAM_DELETED: 85,
  CREATE_CONTROLLER: 86,
  CONTROLLER_CREATED: 87,
  CHANGE_CONTROLLER: 88,
  CONTROLLER_CHANGED: 89,
  DELETE_CONTROLLER: 90,
  CONTROLLER_DELETED: 91,
  CREATE_CONTROLLER_STREAM: 92,
  CONTROLLER_STREAM_CREATED: 93,
  CHANGE_CONTROLLER_STREAM: 94,
  CONTROLLER_STREAM_CHANGED: 95,
  DELETE_CONTROLLER_STREAM: 96,
  CONTROLLER_STREAM_DELETED: 97,
  CREATE_COUNTRY: 98,
  COUNTRY_CREATED: 99,
  CHANGE_COUNTRY: 100,
  COUNTRY_CHANGED: 101,
  DELETE_COUNTRY: 102,
  COUNTRY_DELETED: 103,
  CREATE_REGION: 104,
  REGION_CREATED: 105,
  CHANGE_REGION: 106,
  REGION_CHANGED: 107,
  DELETE_REGION: 108,
  REGION_DELETED: 109,
  CREATE_VELOCITY: 110,
  VELOCITY_CREATED: 111,
  CHANGE_VELOCITY: 112,
  VELOCITY_CHANGED: 113,
  DELETE_VELOCITY: 114,
  VELOCITY_DELETED: 115,
  CREATE_AGENT_CONFIG: 116,
  AGENT_CONFIG_CREATED: 117,
  CHANGE_AGENT_CONFIG: 118,
  AGENT_CONFIG_CHANGED: 119,
  DELETE_AGENT_CONFIG: 120,
  AGENT_CONFIG_DELETED: 121,
  CREATE_CONTROL_REPLY_BIT: 122,
  CONTROL_REPLY_BIT_CREATED: 123,
  CHANGE_CONTROL_REPLY_BIT: 124,
  CONTROL_REPLY_BIT_CHANGED: 125,
  DELETE_CONTROL_REPLY_BIT: 126,
  CONTROL_REPLY_BIT_DELETED: 127,
  CREATE_STAGE: 128,
  STAGE_CREATED: 129,
  CHANGE_STAGE: 130,
  STAGE_CHANGED: 131,
  DELETE_STAGE: 132,
  STAGE_DELETED: 133,
  CREATE_PHASE: 134,
  PHASE_CREATED: 135,
  CHANGE_PHASE: 136,
  PHASE_CHANGED: 137,
  DELETE_PHASE: 138,
  PHASE_DELETED: 139,
  CREATE_VIU: 140,
  VIU_CREATED: 141,
  CHANGE_VIU: 142,
  VIU_CHANGED: 143,
  DELETE_VIU: 144,
  VIU_DELETED: 145,
  CREATE_DETOXICITY: 146,
  DETOXICITY_CREATED: 147,
  CHANGE_DETOXICITY: 148,
  DETOXICITY_CHANGED: 149,
  DELETE_DETOXICITY: 150,
  DETOXICITY_DELETED: 151,
  CREATE_STAGE_TO_PHASE: 152,
  STAGE_TO_PHASE_CREATED: 153,
  CHANGE_STAGE_TO_PHASE: 154,
  STAGE_TO_PHASE_CHANGED: 155,
  DELETE_STAGE_TO_PHASE: 156,
  STAGE_TO_PHASE_DELETED: 157,
  CREATE_VISION_PROGRAM_TO_CONTROLLER_STREAM: 158,
  VISION_PROGRAM_TO_CONTROLLER_STREAM_CREATED: 159,
  CHANGE_VISION_PROGRAM_TO_CONTROLLER_STREAM: 160,
  VISION_PROGRAM_TO_CONTROLLER_STREAM_CHANGED: 161,
  DELETE_VISION_PROGRAM_TO_CONTROLLER_STREAM: 162,
  VISION_PROGRAM_TO_CONTROLLER_STREAM_DELETED: 163,
  CREATE_PHASE_TO_IMAGE_SPACE_ZONE: 164,
  PHASE_TO_IMAGE_SPACE_ZONE_CREATED: 165,
  CHANGE_PHASE_TO_IMAGE_SPACE_ZONE: 166,
  PHASE_TO_IMAGE_SPACE_ZONE_CHANGED: 167,
  DELETE_PHASE_TO_IMAGE_SPACE_ZONE: 168,
  PHASE_TO_IMAGE_SPACE_ZONE_DELETED: 169,
  CREATE_PHASE_TO_IMAGE_SPACE_COUNTLINE: 170,
  PHASE_TO_IMAGE_SPACE_COUNTLINE_CREATED: 171,
  CHANGE_PHASE_TO_IMAGE_SPACE_COUNTLINE: 172,
  PHASE_TO_IMAGE_SPACE_COUNTLINE_CHANGED: 173,
  DELETE_PHASE_TO_IMAGE_SPACE_COUNTLINE: 174,
  PHASE_TO_IMAGE_SPACE_COUNTLINE_DELETED: 175,
  CREATE_AGENT_TYPE: 176,
  AGENT_TYPE_CREATED: 177,
  CHANGE_AGENT_TYPE: 178,
  AGENT_TYPE_CHANGED: 179,
  DELETE_AGENT_TYPE: 180,
  AGENT_TYPE_DELETED: 181,
  CHANGE_GENEALOGY: 182,
  GENEALOGY_CHANGED: 183,
  CREATE_ENTITY_GROUP: 184,
  ENTITY_GROUP_CREATED: 185,
  CHANGE_ENTITY_GROUP: 186,
  ENTITY_GROUP_CHANGED: 187,
  DELETE_ENTITY_GROUP: 188,
  ENTITY_GROUP_DELETED: 189,
  ADD_GROUP_LINKAGE: 190,
  GROUP_LINKAGE_ADDED: 191,
  DELETE_GROUP_LINKAGE: 192,
  GROUP_LINKAGE_DELETED: 193,
  SET_COMMAND_GROUP_TEMPLATE: 194,
  COMMAND_GROUP_TEMPLATE_SETTED: 195,
  CREATE_ZONAL_WINDOWED_CLASS_CONFIG: 196,
  ZONAL_WINDOWED_CLASS_CONFIG_CREATED: 197,
  CHANGE_ZONAL_WINDOWED_CLASS_CONFIG: 198,
  ZONAL_WINDOWED_CLASS_CONFIG_CHANGED: 199,
  DELETE_ZONAL_WINDOWED_CLASS_CONFIG: 200,
  ZONAL_WINDOWED_CLASS_CONFIG_DELETED: 201,
  CREATE_CONTROLLER_STREAM_CONTROL_SCHEDULE: 202,
  CONTROLLER_STREAM_CONTROL_SCHEDULE_CREATED: 203,
  CHANGE_CONTROLLER_STREAM_CONTROL_SCHEDULE: 204,
  CONTROLLER_STREAM_CONTROL_SCHEDULE_CHANGED: 205,
  DELETE_CONTROLLER_STREAM_CONTROL_SCHEDULE: 206,
  CONTROLLER_STREAM_CONTROL_SCHEDULE_DELETED: 207,
  CREATE_STAGE_TRANSITION: 208,
  STAGE_TRANSITION_CREATED: 209,
  CHANGE_STAGE_TRANSITION: 210,
  STAGE_TRANSITION_CHANGED: 211,
  DELETE_STAGE_TRANSITION: 212,
  STAGE_TRANSITION_DELETED: 213,
  CREATE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 214,
  VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_CREATED: 215,
  CHANGE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 216,
  VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_CHANGED: 217,
  DELETE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 218,
  VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_DELETED: 219,
  CREATE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 220,
  VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_CREATED: 221,
  CHANGE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 222,
  VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_CHANGED: 223,
  DELETE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG: 224,
  VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_DELETED: 225,
  CREATE_CV_MODEL: 226,
  CV_MODEL_CREATED: 227,
  CHANGE_CV_MODEL: 228,
  CV_MODEL_CHANGED: 229,
  DELETE_CV_MODEL: 230,
  CV_MODEL_DELETED: 231,
  CREATE_CV_MODEL_TYPE: 232,
  CV_MODEL_TYPE_CREATED: 233,
  CHANGE_CV_MODEL_TYPE: 234,
  CV_MODEL_TYPE_CHANGED: 235,
  DELETE_CV_MODEL_TYPE: 236,
  CV_MODEL_TYPE_DELETED: 237,
  CREATE_CV_MODEL_CLASS: 238,
  CV_MODEL_CLASS_CREATED: 239,
  CHANGE_CV_MODEL_CLASS: 240,
  CV_MODEL_CLASS_CHANGED: 241,
  DELETE_CV_MODEL_CLASS: 242,
  CV_MODEL_CLASS_DELETED: 243,
  CREATE_VISION_PROGRAM_PRESET: 244,
  VISION_PROGRAM_PRESET_CREATED: 245,
  CHANGE_VISION_PROGRAM_PRESET: 251,
  VISION_PROGRAM_PRESET_CHANGED: 252,
  DELETE_VISION_PROGRAM_PRESET: 253,
  VISION_PROGRAM_PRESET_DELETED: 254,
  RENDER_ENTITY_TEMPLATE: 255,
  ENTITY_TEMPLATE_RENDERED: 256,
  CREATE_ENTITY_TEMPLATE_TYPE: 257,
  ENTITY_TEMPLATE_TYPE_CREATED: 258,
  CHANGE_ENTITY_TEMPLATE_TYPE: 259,
  ENTITY_TEMPLATE_TYPE_CHANGED: 260,
  DELETE_ENTITY_TEMPLATE_TYPE: 261,
  ENTITY_TEMPLATE_TYPE_DELETED: 262,
  CREATE_ENTITY_TEMPLATE: 263,
  ENTITY_TEMPLATE_CREATED: 264,
  CHANGE_ENTITY_TEMPLATE: 265,
  ENTITY_TEMPLATE_CHANGED: 266,
  DELETE_ENTITY_TEMPLATE: 267,
  ENTITY_TEMPLATE_DELETED: 268,
  CREATE_RENDERED_ENTITY_TEMPLATE: 269,
  RENDERED_ENTITY_TEMPLATE_CREATED: 270,
  CHANGE_RENDERED_ENTITY_TEMPLATE: 271,
  RENDERED_ENTITY_TEMPLATE_CHANGED: 272,
  DELETE_RENDERED_ENTITY_TEMPLATE: 273,
  RENDERED_ENTITY_TEMPLATE_DELETED: 274,
  CREATE_DYNNIQ_MSG_CLASS_GROUPING: 275,
  DYNNIQ_MSG_CLASS_GROUPING_CREATED: 276,
  CHANGE_DYNNIQ_MSG_CLASS_GROUPING: 277,
  DYNNIQ_MSG_CLASS_GROUPING_CHANGED: 278,
  DELETE_DYNNIQ_MSG_CLASS_GROUPING: 279,
  DYNNIQ_MSG_CLASS_GROUPING_DELETED: 280,
  CREATE_DYNNIQ_MSG_ZONE: 281,
  DYNNIQ_MSG_ZONE_CREATED: 282,
  CHANGE_DYNNIQ_MSG_ZONE: 283,
  DYNNIQ_MSG_ZONE_CHANGED: 284,
  DELETE_DYNNIQ_MSG_ZONE: 285,
  DYNNIQ_MSG_ZONE_DELETED: 286,
  CREATE_CONTROLLER_HARDWARE_MODELS: 287,
  CONTROLLER_HARDWARE_MODELS_CREATED: 288,
  CHANGE_CONTROLLER_HARDWARE_MODELS: 289,
  CONTROLLER_HARDWARE_MODELS_CHANGED: 290,
  DELETE_CONTROLLER_HARDWARE_MODELS: 291,
  CONTROLLER_HARDWARE_MODELS_DELETED: 292,
  CREATE_HARDWARE_MANUFACTURERS: 293,
  HARDWARE_MANUFACTURERS_CREATED: 294,
  CHANGE_HARDWARE_MANUFACTURERS: 295,
  HARDWARE_MANUFACTURERS_CHANGED: 296,
  DELETE_HARDWARE_MANUFACTURERS: 297,
  HARDWARE_MANUFACTURERS_DELETED: 298,
  CREATE_COUNTLINE_WINDOWED_CLASS_CONFIG: 299,
  COUNTLINE_WINDOWED_CLASS_CONFIG_CREATED: 300,
  CHANGE_COUNTLINE_WINDOWED_CLASS_CONFIG: 301,
  COUNTLINE_WINDOWED_CLASS_CONFIG_CHANGED: 302,
  DELETE_COUNTLINE_WINDOWED_CLASS_CONFIG: 303,
  COUNTLINE_WINDOWED_CLASS_CONFIG_DELETED: 304,
  CREATE_ENTITY_TEMPLATE_TYPE_TO_ENTITY: 305,
  ENTITY_TEMPLATE_TYPE_TO_ENTITY_CREATED: 306,
  CHANGE_ENTITY_TEMPLATE_TYPE_TO_ENTITY: 307,
  ENTITY_TEMPLATE_TYPE_TO_ENTITY_CHANGED: 308,
  DELETE_ENTITY_TEMPLATE_TYPE_TO_ENTITY: 309,
  ENTITY_TEMPLATE_TYPE_TO_ENTITY_DELETED: 310,
  CREATE_HARDWARE_POSITION: 311,
  HARDWARE_POSITION_CREATED: 312,
  CHANGE_HARDWARE_POSITION: 313,
  HARDWARE_POSITION_CHANGED: 314,
  DELETE_HARDWARE_POSITION: 315,
  HARDWARE_POSITION_DELETED: 316,
  CREATE_CONTRACTED_HARDWARE: 317,
  CONTRACTED_HARDWARE_CREATED: 318,
  CHANGE_CONTRACTED_HARDWARE: 319,
  CONTRACTED_HARDWARE_CHANGED: 320,
  DELETE_CONTRACTED_HARDWARE: 321,
  CONTRACTED_HARDWARE_DELETED: 322,
  CREATE_CUSTOMER: 323,
  CUSTOMER_CREATED: 324,
  CHANGE_CUSTOMER: 325,
  CUSTOMER_CHANGED: 326,
  DELETE_CUSTOMER: 327,
  CUSTOMER_DELETED: 328,
  CREATE_CONTRACT: 329,
  CONTRACT_CREATED: 330,
  CHANGE_CONTRACT: 331,
  CONTRACT_CHANGED: 332,
  DELETE_CONTRACT: 333,
  CONTRACT_DELETED: 334,
  LINK_VISION_PROGRAM_TO_HARDWARE_POSITION: 335,
  VISION_PROGRAM_TO_HARDWARE_POSITION_LINKED: 336,
  UNLINK_VISION_PROGRAM_TO_HARDWARE_POSITION: 337,
  VISION_PROGRAM_TO_HARDWARE_POSITION_UNLINKED: 338,
  LINK_HARDWARE_POSITION_TO_HARDWARE: 339,
  HARDWARE_POSITION_TO_HARDWARE_LINKED: 340,
  UNLINK_HARDWARE_POSITION_TO_HARDWARE: 341,
  HARDWARE_POSITION_TO_HARDWARE_UNLINKED: 342,
  LINK_CONTRACT_TO_CONTRACTED_HARDWARE: 343,
  CONTRACT_TO_CONTRACTED_HARDWARE_LINKED: 344,
  UNLINK_CONTRACT_TO_CONTRACTED_HARDWARE: 345,
  CONTRACT_TO_CONTRACTED_HARDWARE_UNLINKED: 346,
  LINK_HARDWARE_POSITION_TO_CONTRACTED_HARDWARE: 347,
  HARDWARE_POSITION_TO_CONTRACTED_HARDWARE_LINKED: 348,
  UNLINK_HARDWARE_POSITION_TO_CONTRACTED_HARDWARE: 349,
  HARDWARE_POSITION_TO_CONTRACTED_HARDWARE_UNLINKED: 350,
  CREATE_HARDWARE_POSITION_DRAWING: 351,
  HARDWARE_POSITION_DRAWING_CREATED: 352,
  CHANGE_HARDWARE_POSITION_DRAWING: 353,
  HARDWARE_POSITION_DRAWING_CHANGED: 354,
  DELETE_HARDWARE_POSITION_DRAWING: 355,
  HARDWARE_POSITION_DRAWING_DELETED: 356,
  SET_CONTROL_CONTROLLER_STREAM: 357,
  CONTROLLER_STREAM_CONTROL_SET: 358,
  CREATE_ZONE_REAL_WORLD_SPACE: 359,
  ZONE_REAL_WORLD_SPACE_CREATED: 360,
  CHANGE_ZONE_REAL_WORLD_SPACE: 361,
  ZONE_REAL_WORLD_SPACE_CHANGED: 362,
  DELETE_ZONE_REAL_WORLD_SPACE: 363,
  ZONE_REAL_WORLD_SPACE_DELETED: 364,
  HYBRID_MESSAGE_REQUEST: 365,
  HYBRID_MESSAGE_RESPONSE: 366,
  CHANGE_HARDWARE_POSITION_STATUS: 367,
  HARDWARE_POSITION_STATUS_CHANGED: 368
};

/**
 * optional EventMessageType event_message_type = 1;
 * @return {!proto.vivacity.config.ConfigMessage.EventMessageType}
 */
proto.vivacity.config.ConfigMessage.prototype.getEventMessageType = function() {
  return /** @type {!proto.vivacity.config.ConfigMessage.EventMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.ConfigMessage.EventMessageType} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setEventMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional OriginatingSystem originating_system = 2;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.ConfigMessage.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 user_id = 3;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 timestamp_microseconds = 4;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string correlation_id = 5;
 * @return {string}
 */
proto.vivacity.config.ConfigMessage.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional vivacity.core.TracingContext tracing_ctx = 6;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.config.ConfigMessage.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 6));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ConfigMessage batched_config_messages = 7;
 * @return {!Array<!proto.vivacity.config.ConfigMessage>}
 */
proto.vivacity.config.ConfigMessage.prototype.getBatchedConfigMessagesList = function() {
  return /** @type{!Array<!proto.vivacity.config.ConfigMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ConfigMessage, 7));
};


/**
 * @param {!Array<!proto.vivacity.config.ConfigMessage>} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setBatchedConfigMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.config.ConfigMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.addBatchedConfigMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.config.ConfigMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearBatchedConfigMessagesList = function() {
  return this.setBatchedConfigMessagesList([]);
};


/**
 * repeated ConfigMessage reduced_batched_config_messages = 8;
 * @return {!Array<!proto.vivacity.config.ConfigMessage>}
 */
proto.vivacity.config.ConfigMessage.prototype.getReducedBatchedConfigMessagesList = function() {
  return /** @type{!Array<!proto.vivacity.config.ConfigMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ConfigMessage, 8));
};


/**
 * @param {!Array<!proto.vivacity.config.ConfigMessage>} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setReducedBatchedConfigMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vivacity.config.ConfigMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.addReducedBatchedConfigMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vivacity.config.ConfigMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearReducedBatchedConfigMessagesList = function() {
  return this.setReducedBatchedConfigMessagesList([]);
};


/**
 * optional ZonesConfigChange change_zone_image_space = 11;
 * @return {?proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZoneImageSpace = function() {
  return /** @type{?proto.vivacity.config.ZonesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonesConfigChange, 11));
};


/**
 * @param {?proto.vivacity.config.ZonesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZoneImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZoneImageSpace = function() {
  return this.setChangeZoneImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZoneImageSpace = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ZonesConfigChange zone_image_space_changed = 12;
 * @return {?proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getZoneImageSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.ZonesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonesConfigChange, 12));
};


/**
 * @param {?proto.vivacity.config.ZonesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZoneImageSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZoneImageSpaceChanged = function() {
  return this.setZoneImageSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZoneImageSpaceChanged = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CountlinesConfigChange change_countline_image_space = 13;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountlineImageSpace = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 13));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountlineImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountlineImageSpace = function() {
  return this.setChangeCountlineImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountlineImageSpace = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional CountlinesConfigChange countline_image_space_changed = 14;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 14));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceChanged = function() {
  return this.setCountlineImageSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceChanged = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PerspectiveTransformConfigByVisionProgram change_perspective_transform = 15;
 * @return {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangePerspectiveTransform = function() {
  return /** @type{?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram} */ (
    jspb.Message.getWrapperField(this, vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram, 15));
};


/**
 * @param {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangePerspectiveTransform = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangePerspectiveTransform = function() {
  return this.setChangePerspectiveTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangePerspectiveTransform = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PerspectiveTransformConfigByVisionProgram perspective_transform_changed = 16;
 * @return {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram}
 */
proto.vivacity.config.ConfigMessage.prototype.getPerspectiveTransformChanged = function() {
  return /** @type{?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram} */ (
    jspb.Message.getWrapperField(this, vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram, 16));
};


/**
 * @param {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPerspectiveTransformChanged = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPerspectiveTransformChanged = function() {
  return this.setPerspectiveTransformChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPerspectiveTransformChanged = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional CountlinesConfigChange change_countline_real_world_space = 17;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountlineRealWorldSpace = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 17));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountlineRealWorldSpace = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountlineRealWorldSpace = function() {
  return this.setChangeCountlineRealWorldSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountlineRealWorldSpace = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional CountlinesConfigChange countline_real_world_space_changed = 18;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineRealWorldSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 18));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineRealWorldSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineRealWorldSpaceChanged = function() {
  return this.setCountlineRealWorldSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineRealWorldSpaceChanged = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ZonalAlertConfigChange change_zonal_alert = 19;
 * @return {?proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZonalAlert = function() {
  return /** @type{?proto.vivacity.config.ZonalAlertConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_alerts_config_pb.ZonalAlertConfigChange, 19));
};


/**
 * @param {?proto.vivacity.config.ZonalAlertConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZonalAlert = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZonalAlert = function() {
  return this.setChangeZonalAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZonalAlert = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ZonalAlertConfigChange zonal_alert_changed = 20;
 * @return {?proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getZonalAlertChanged = function() {
  return /** @type{?proto.vivacity.config.ZonalAlertConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_alerts_config_pb.ZonalAlertConfigChange, 20));
};


/**
 * @param {?proto.vivacity.config.ZonalAlertConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZonalAlertChanged = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZonalAlertChanged = function() {
  return this.setZonalAlertChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZonalAlertChanged = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional GetOrCreateHardwareId get_hardware_id = 21;
 * @return {?proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.ConfigMessage.prototype.getGetHardwareId = function() {
  return /** @type{?proto.vivacity.config.GetOrCreateHardwareId} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.GetOrCreateHardwareId, 21));
};


/**
 * @param {?proto.vivacity.config.GetOrCreateHardwareId|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setGetHardwareId = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearGetHardwareId = function() {
  return this.setGetHardwareId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasGetHardwareId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional GetOrCreateHardwareId create_hardware_id = 22;
 * @return {?proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateHardwareId = function() {
  return /** @type{?proto.vivacity.config.GetOrCreateHardwareId} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.GetOrCreateHardwareId, 22));
};


/**
 * @param {?proto.vivacity.config.GetOrCreateHardwareId|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateHardwareId = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateHardwareId = function() {
  return this.setCreateHardwareId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateHardwareId = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional HardwareIdTokenResponse hardware_id_token_response = 23;
 * @return {?proto.vivacity.config.HardwareIdTokenResponse}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareIdTokenResponse = function() {
  return /** @type{?proto.vivacity.config.HardwareIdTokenResponse} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareIdTokenResponse, 23));
};


/**
 * @param {?proto.vivacity.config.HardwareIdTokenResponse|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareIdTokenResponse = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareIdTokenResponse = function() {
  return this.setHardwareIdTokenResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareIdTokenResponse = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional HardwareIdCreated hardware_id_created = 24;
 * @return {?proto.vivacity.config.HardwareIdCreated}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareIdCreated = function() {
  return /** @type{?proto.vivacity.config.HardwareIdCreated} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareIdCreated, 24));
};


/**
 * @param {?proto.vivacity.config.HardwareIdCreated|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareIdCreated = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareIdCreated = function() {
  return this.setHardwareIdCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareIdCreated = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional VaultResourceCreated vault_resource_created = 25;
 * @return {?proto.vivacity.config.VaultResourceCreated}
 */
proto.vivacity.config.ConfigMessage.prototype.getVaultResourceCreated = function() {
  return /** @type{?proto.vivacity.config.VaultResourceCreated} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vault_pb.VaultResourceCreated, 25));
};


/**
 * @param {?proto.vivacity.config.VaultResourceCreated|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVaultResourceCreated = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVaultResourceCreated = function() {
  return this.setVaultResourceCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVaultResourceCreated = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional HardwareFields change_hardware_fields = 26;
 * @return {?proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwareFields = function() {
  return /** @type{?proto.vivacity.config.HardwareFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareFields, 26));
};


/**
 * @param {?proto.vivacity.config.HardwareFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwareFields = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwareFields = function() {
  return this.setChangeHardwareFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwareFields = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional HardwareFields hardware_fields_changed = 27;
 * @return {?proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.HardwareFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareFields, 27));
};


/**
 * @param {?proto.vivacity.config.HardwareFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareFieldsChanged = function() {
  return this.setHardwareFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareFieldsChanged = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool create_vision_program = 31;
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVisionProgram = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setCreateVisionProgram = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional uint32 vision_program_created = 32;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional VisionProgramFields change_vision_program_fields = 33;
 * @return {?proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVisionProgramFields = function() {
  return /** @type{?proto.vivacity.config.VisionProgramFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramFields, 33));
};


/**
 * @param {?proto.vivacity.config.VisionProgramFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVisionProgramFields = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVisionProgramFields = function() {
  return this.setChangeVisionProgramFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVisionProgramFields = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional VisionProgramFields vision_program_fields_changed = 34;
 * @return {?proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.VisionProgramFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramFields, 34));
};


/**
 * @param {?proto.vivacity.config.VisionProgramFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramFieldsChanged = function() {
  return this.setVisionProgramFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramFieldsChanged = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld link_countline_image_space_to_real_world = 35;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkCountlineImageSpaceToRealWorld = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 35));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkCountlineImageSpaceToRealWorld = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkCountlineImageSpaceToRealWorld = function() {
  return this.setLinkCountlineImageSpaceToRealWorld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkCountlineImageSpaceToRealWorld = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld countline_image_space_to_real_world_linked = 36;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceToRealWorldLinked = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 36));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceToRealWorldLinked = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceToRealWorldLinked = function() {
  return this.setCountlineImageSpaceToRealWorldLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceToRealWorldLinked = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld unlink_countline_image_space_to_real_world = 37;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkCountlineImageSpaceToRealWorld = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 37));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkCountlineImageSpaceToRealWorld = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkCountlineImageSpaceToRealWorld = function() {
  return this.setUnlinkCountlineImageSpaceToRealWorld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkCountlineImageSpaceToRealWorld = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld countline_image_space_to_real_world_unlinked = 38;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceToRealWorldUnlinked = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 38));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceToRealWorldUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceToRealWorldUnlinked = function() {
  return this.setCountlineImageSpaceToRealWorldUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceToRealWorldUnlinked = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional FileTemplate create_file_template = 40;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateFileTemplate = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 40));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateFileTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateFileTemplate = function() {
  return this.setCreateFileTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateFileTemplate = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional FileTemplate file_template_created = 41;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 41));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateCreated = function() {
  return this.setFileTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateCreated = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional FileTemplate change_file_template_fields = 42;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeFileTemplateFields = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 42));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeFileTemplateFields = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeFileTemplateFields = function() {
  return this.setChangeFileTemplateFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeFileTemplateFields = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional FileTemplate file_template_fields_changed = 43;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 43));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateFieldsChanged = function() {
  return this.setFileTemplateFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateFieldsChanged = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional FileTemplate delete_file_template = 44;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteFileTemplate = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 44));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteFileTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteFileTemplate = function() {
  return this.setDeleteFileTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteFileTemplate = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional FileTemplate file_template_deleted = 45;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateDeleted = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 45));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateDeleted = function() {
  return this.setFileTemplateDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateDeleted = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional RenderedFile create_rendered_file_entry = 46;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateRenderedFileEntry = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 46));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateRenderedFileEntry = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateRenderedFileEntry = function() {
  return this.setCreateRenderedFileEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateRenderedFileEntry = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional RenderedFile rendered_file_entry_created = 47;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedFileEntryCreated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 47));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedFileEntryCreated = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedFileEntryCreated = function() {
  return this.setRenderedFileEntryCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedFileEntryCreated = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional RenderedFile delete_rendered_file = 48;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteRenderedFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 48));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteRenderedFile = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteRenderedFile = function() {
  return this.setDeleteRenderedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteRenderedFile = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional RenderedFile rendered_file_deleted = 49;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedFileDeleted = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 49));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedFileDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedFileDeleted = function() {
  return this.setRenderedFileDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedFileDeleted = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional RenderedFile render_file = 50;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 50));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderFile = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderFile = function() {
  return this.setRenderFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderFile = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional RenderedFile file_rendered = 51;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileRendered = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 51));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileRendered = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileRendered = function() {
  return this.setFileRendered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileRendered = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional RenderedFile update_file_on_hardware = 52;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getUpdateFileOnHardware = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 52));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUpdateFileOnHardware = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUpdateFileOnHardware = function() {
  return this.setUpdateFileOnHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUpdateFileOnHardware = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional RenderedFile file_on_hardware_updated = 53;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileOnHardwareUpdated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 53));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileOnHardwareUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileOnHardwareUpdated = function() {
  return this.setFileOnHardwareUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileOnHardwareUpdated = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional RenderedFile remote_file_state_on_db_updated = 54;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRemoteFileStateOnDbUpdated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 54));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRemoteFileStateOnDbUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRemoteFileStateOnDbUpdated = function() {
  return this.setRemoteFileStateOnDbUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRemoteFileStateOnDbUpdated = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional RetrieveFile retrieve_rendered_files = 55;
 * @return {?proto.vivacity.config.RetrieveFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRetrieveRenderedFiles = function() {
  return /** @type{?proto.vivacity.config.RetrieveFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RetrieveFile, 55));
};


/**
 * @param {?proto.vivacity.config.RetrieveFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRetrieveRenderedFiles = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRetrieveRenderedFiles = function() {
  return this.setRetrieveRenderedFiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRetrieveRenderedFiles = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional VisionProgramToHardware link_vision_program_to_hardware = 60;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkVisionProgramToHardware = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 60));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkVisionProgramToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkVisionProgramToHardware = function() {
  return this.setLinkVisionProgramToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkVisionProgramToHardware = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional VisionProgramToHardware vision_program_to_hardware_linked = 61;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwareLinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 61));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwareLinked = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwareLinked = function() {
  return this.setVisionProgramToHardwareLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwareLinked = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional VisionProgramToHardware unlink_vision_program_to_hardware = 62;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkVisionProgramToHardware = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 62));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkVisionProgramToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkVisionProgramToHardware = function() {
  return this.setUnlinkVisionProgramToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkVisionProgramToHardware = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional VisionProgramToHardware vision_program_to_hardware_unlinked = 63;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwareUnlinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 63));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwareUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwareUnlinked = function() {
  return this.setVisionProgramToHardwareUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwareUnlinked = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional CronjobToHardwareLinkage change_linkage_cronjob_to_hardware = 64;
 * @return {?proto.vivacity.config.CronjobToHardwareLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeLinkageCronjobToHardware = function() {
  return /** @type{?proto.vivacity.config.CronjobToHardwareLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.CronjobToHardwareLinkage, 64));
};


/**
 * @param {?proto.vivacity.config.CronjobToHardwareLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeLinkageCronjobToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 64, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeLinkageCronjobToHardware = function() {
  return this.setChangeLinkageCronjobToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeLinkageCronjobToHardware = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional CronjobToHardwareLinkage cronjob_to_hardware_linkage_changed = 65;
 * @return {?proto.vivacity.config.CronjobToHardwareLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCronjobToHardwareLinkageChanged = function() {
  return /** @type{?proto.vivacity.config.CronjobToHardwareLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.CronjobToHardwareLinkage, 65));
};


/**
 * @param {?proto.vivacity.config.CronjobToHardwareLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCronjobToHardwareLinkageChanged = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCronjobToHardwareLinkageChanged = function() {
  return this.setCronjobToHardwareLinkageChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCronjobToHardwareLinkageChanged = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional Cronjob change_cronjob = 66;
 * @return {?proto.vivacity.config.Cronjob}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCronjob = function() {
  return /** @type{?proto.vivacity.config.Cronjob} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.Cronjob, 66));
};


/**
 * @param {?proto.vivacity.config.Cronjob|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCronjob = function(value) {
  return jspb.Message.setWrapperField(this, 66, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCronjob = function() {
  return this.setChangeCronjob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCronjob = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional Cronjob cronjob_changed = 67;
 * @return {?proto.vivacity.config.Cronjob}
 */
proto.vivacity.config.ConfigMessage.prototype.getCronjobChanged = function() {
  return /** @type{?proto.vivacity.config.Cronjob} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.Cronjob, 67));
};


/**
 * @param {?proto.vivacity.config.Cronjob|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCronjobChanged = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCronjobChanged = function() {
  return this.setCronjobChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCronjobChanged = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional vivacity.benchmarking.BenchmarkRun create_benchmark_run = 69;
 * @return {?proto.vivacity.benchmarking.BenchmarkRun}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateBenchmarkRun = function() {
  return /** @type{?proto.vivacity.benchmarking.BenchmarkRun} */ (
    jspb.Message.getWrapperField(this, vivacity_benchmarking_benchmark_run_pb.BenchmarkRun, 69));
};


/**
 * @param {?proto.vivacity.benchmarking.BenchmarkRun|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateBenchmarkRun = function(value) {
  return jspb.Message.setWrapperField(this, 69, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateBenchmarkRun = function() {
  return this.setCreateBenchmarkRun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateBenchmarkRun = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional CommandGroupTemplateMessage create_command_group_template = 70;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCommandGroupTemplate = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 70));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCommandGroupTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 70, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCommandGroupTemplate = function() {
  return this.setCreateCommandGroupTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCommandGroupTemplate = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional CommandGroupTemplateMessage command_group_template_created = 71;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCommandGroupTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 71));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCommandGroupTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 71, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCommandGroupTemplateCreated = function() {
  return this.setCommandGroupTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCommandGroupTemplateCreated = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional Agent create_agent = 72;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 72));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgent = function(value) {
  return jspb.Message.setWrapperField(this, 72, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgent = function() {
  return this.setCreateAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgent = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional Agent agent_created = 73;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentCreated = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 73));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentCreated = function(value) {
  return jspb.Message.setWrapperField(this, 73, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentCreated = function() {
  return this.setAgentCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentCreated = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional Agent change_agent = 74;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 74));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgent = function(value) {
  return jspb.Message.setWrapperField(this, 74, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgent = function() {
  return this.setChangeAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgent = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional Agent agent_changed = 75;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentChanged = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 75));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentChanged = function(value) {
  return jspb.Message.setWrapperField(this, 75, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentChanged = function() {
  return this.setAgentChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentChanged = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional Agent delete_agent = 76;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 76));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgent = function(value) {
  return jspb.Message.setWrapperField(this, 76, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgent = function() {
  return this.setDeleteAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgent = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional Agent agent_deleted = 77;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentDeleted = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 77));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentDeleted = function() {
  return this.setAgentDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentDeleted = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional AgentStream create_agent_stream = 78;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 78));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgentStream = function() {
  return this.setCreateAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgentStream = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional AgentStream agent_stream_created = 79;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamCreated = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 79));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamCreated = function(value) {
  return jspb.Message.setWrapperField(this, 79, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamCreated = function() {
  return this.setAgentStreamCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamCreated = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional AgentStream change_agent_stream = 80;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 80));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgentStream = function() {
  return this.setChangeAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgentStream = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional AgentStream agent_stream_changed = 81;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamChanged = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 81));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamChanged = function(value) {
  return jspb.Message.setWrapperField(this, 81, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamChanged = function() {
  return this.setAgentStreamChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamChanged = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional AgentStream delete_agent_stream = 82;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 82));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 82, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgentStream = function() {
  return this.setDeleteAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgentStream = function() {
  return jspb.Message.getField(this, 82) != null;
};


/**
 * optional AgentStream agent_stream_deleted = 83;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamDeleted = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 83));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 83, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamDeleted = function() {
  return this.setAgentStreamDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamDeleted = function() {
  return jspb.Message.getField(this, 83) != null;
};


/**
 * optional Country create_country = 84;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 84));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCountry = function(value) {
  return jspb.Message.setWrapperField(this, 84, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCountry = function() {
  return this.setCreateCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCountry = function() {
  return jspb.Message.getField(this, 84) != null;
};


/**
 * optional Country country_created = 85;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryCreated = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 85));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryCreated = function(value) {
  return jspb.Message.setWrapperField(this, 85, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryCreated = function() {
  return this.setCountryCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryCreated = function() {
  return jspb.Message.getField(this, 85) != null;
};


/**
 * optional Country change_country = 86;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 86));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountry = function(value) {
  return jspb.Message.setWrapperField(this, 86, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountry = function() {
  return this.setChangeCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountry = function() {
  return jspb.Message.getField(this, 86) != null;
};


/**
 * optional Country country_changed = 87;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryChanged = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 87));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryChanged = function(value) {
  return jspb.Message.setWrapperField(this, 87, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryChanged = function() {
  return this.setCountryChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryChanged = function() {
  return jspb.Message.getField(this, 87) != null;
};


/**
 * optional Country delete_country = 88;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 88));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCountry = function(value) {
  return jspb.Message.setWrapperField(this, 88, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCountry = function() {
  return this.setDeleteCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCountry = function() {
  return jspb.Message.getField(this, 88) != null;
};


/**
 * optional Country country_deleted = 89;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryDeleted = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 89));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 89, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryDeleted = function() {
  return this.setCountryDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryDeleted = function() {
  return jspb.Message.getField(this, 89) != null;
};


/**
 * optional Region create_region = 90;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 90));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateRegion = function(value) {
  return jspb.Message.setWrapperField(this, 90, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateRegion = function() {
  return this.setCreateRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateRegion = function() {
  return jspb.Message.getField(this, 90) != null;
};


/**
 * optional Region region_created = 91;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionCreated = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 91));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionCreated = function(value) {
  return jspb.Message.setWrapperField(this, 91, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionCreated = function() {
  return this.setRegionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionCreated = function() {
  return jspb.Message.getField(this, 91) != null;
};


/**
 * optional Region change_region = 92;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 92));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeRegion = function(value) {
  return jspb.Message.setWrapperField(this, 92, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeRegion = function() {
  return this.setChangeRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeRegion = function() {
  return jspb.Message.getField(this, 92) != null;
};


/**
 * optional Region region_changed = 93;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionChanged = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 93));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionChanged = function(value) {
  return jspb.Message.setWrapperField(this, 93, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionChanged = function() {
  return this.setRegionChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionChanged = function() {
  return jspb.Message.getField(this, 93) != null;
};


/**
 * optional Region delete_region = 94;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 94));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteRegion = function(value) {
  return jspb.Message.setWrapperField(this, 94, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteRegion = function() {
  return this.setDeleteRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteRegion = function() {
  return jspb.Message.getField(this, 94) != null;
};


/**
 * optional Region region_deleted = 95;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionDeleted = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 95));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 95, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionDeleted = function() {
  return this.setRegionDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionDeleted = function() {
  return jspb.Message.getField(this, 95) != null;
};


/**
 * optional Controller create_controller = 96;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 96));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateController = function(value) {
  return jspb.Message.setWrapperField(this, 96, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateController = function() {
  return this.setCreateController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateController = function() {
  return jspb.Message.getField(this, 96) != null;
};


/**
 * optional Controller controller_created = 97;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerCreated = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 97));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerCreated = function(value) {
  return jspb.Message.setWrapperField(this, 97, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerCreated = function() {
  return this.setControllerCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerCreated = function() {
  return jspb.Message.getField(this, 97) != null;
};


/**
 * optional Controller change_controller = 98;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 98));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeController = function(value) {
  return jspb.Message.setWrapperField(this, 98, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeController = function() {
  return this.setChangeController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeController = function() {
  return jspb.Message.getField(this, 98) != null;
};


/**
 * optional Controller controller_changed = 99;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerChanged = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 99));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerChanged = function(value) {
  return jspb.Message.setWrapperField(this, 99, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerChanged = function() {
  return this.setControllerChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerChanged = function() {
  return jspb.Message.getField(this, 99) != null;
};


/**
 * optional Controller delete_controller = 100;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 100));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteController = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteController = function() {
  return this.setDeleteController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteController = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional Controller controller_deleted = 101;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerDeleted = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 101));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerDeleted = function() {
  return this.setControllerDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerDeleted = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional ControllerStream create_controller_stream = 102;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 102));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 102, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateControllerStream = function() {
  return this.setCreateControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateControllerStream = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional ControllerStream controller_stream_created = 103;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamCreated = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 103));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamCreated = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamCreated = function() {
  return this.setControllerStreamCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamCreated = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional ControllerStream change_controller_stream = 104;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 104));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeControllerStream = function() {
  return this.setChangeControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeControllerStream = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional ControllerStream controller_stream_changed = 105;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamChanged = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 105));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamChanged = function(value) {
  return jspb.Message.setWrapperField(this, 105, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamChanged = function() {
  return this.setControllerStreamChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamChanged = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional ControllerStream delete_controller_stream = 106;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 106));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteControllerStream = function() {
  return this.setDeleteControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteControllerStream = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional ControllerStream controller_stream_deleted = 107;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamDeleted = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 107));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamDeleted = function() {
  return this.setControllerStreamDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamDeleted = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional Velocity create_velocity = 108;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 108));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVelocity = function() {
  return this.setCreateVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVelocity = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional Velocity velocity_created = 109;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityCreated = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 109));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityCreated = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityCreated = function() {
  return this.setVelocityCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityCreated = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional Velocity change_velocity = 110;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 110));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVelocity = function() {
  return this.setChangeVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVelocity = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional Velocity velocity_changed = 111;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityChanged = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 111));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityChanged = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityChanged = function() {
  return this.setVelocityChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityChanged = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional Velocity delete_velocity = 112;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 112));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVelocity = function() {
  return this.setDeleteVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVelocity = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional Velocity velocity_deleted = 113;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityDeleted = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 113));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityDeleted = function() {
  return this.setVelocityDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityDeleted = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional AgentConfig create_agent_config = 114;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 114));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgentConfig = function() {
  return this.setCreateAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgentConfig = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional AgentConfig agent_config_created = 115;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigCreated = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 115));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigCreated = function() {
  return this.setAgentConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigCreated = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional AgentConfig change_agent_config = 116;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 116));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgentConfig = function() {
  return this.setChangeAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgentConfig = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional AgentConfig agent_config_changed = 117;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigChanged = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 117));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 117, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigChanged = function() {
  return this.setAgentConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigChanged = function() {
  return jspb.Message.getField(this, 117) != null;
};


/**
 * optional AgentConfig delete_agent_config = 118;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 118));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgentConfig = function() {
  return this.setDeleteAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgentConfig = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional AgentConfig agent_config_deleted = 119;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 119));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigDeleted = function() {
  return this.setAgentConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigDeleted = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional ControlReplyBit create_control_reply_bit = 120;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateControlReplyBit = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 120));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateControlReplyBit = function(value) {
  return jspb.Message.setWrapperField(this, 120, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateControlReplyBit = function() {
  return this.setCreateControlReplyBit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateControlReplyBit = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional ControlReplyBit control_reply_bit_created = 121;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getControlReplyBitCreated = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 121));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControlReplyBitCreated = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControlReplyBitCreated = function() {
  return this.setControlReplyBitCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControlReplyBitCreated = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional ControlReplyBit change_control_reply_bit = 122;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeControlReplyBit = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 122));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeControlReplyBit = function(value) {
  return jspb.Message.setWrapperField(this, 122, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeControlReplyBit = function() {
  return this.setChangeControlReplyBit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeControlReplyBit = function() {
  return jspb.Message.getField(this, 122) != null;
};


/**
 * optional ControlReplyBit control_reply_bit_changed = 123;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getControlReplyBitChanged = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 123));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControlReplyBitChanged = function(value) {
  return jspb.Message.setWrapperField(this, 123, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControlReplyBitChanged = function() {
  return this.setControlReplyBitChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControlReplyBitChanged = function() {
  return jspb.Message.getField(this, 123) != null;
};


/**
 * optional ControlReplyBit delete_control_reply_bit = 124;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteControlReplyBit = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 124));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteControlReplyBit = function(value) {
  return jspb.Message.setWrapperField(this, 124, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteControlReplyBit = function() {
  return this.setDeleteControlReplyBit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteControlReplyBit = function() {
  return jspb.Message.getField(this, 124) != null;
};


/**
 * optional ControlReplyBit control_reply_bit_deleted = 125;
 * @return {?proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ConfigMessage.prototype.getControlReplyBitDeleted = function() {
  return /** @type{?proto.vivacity.config.ControlReplyBit} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControlReplyBit, 125));
};


/**
 * @param {?proto.vivacity.config.ControlReplyBit|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControlReplyBitDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 125, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControlReplyBitDeleted = function() {
  return this.setControlReplyBitDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControlReplyBitDeleted = function() {
  return jspb.Message.getField(this, 125) != null;
};


/**
 * optional Stage create_stage = 126;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateStage = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 126));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateStage = function(value) {
  return jspb.Message.setWrapperField(this, 126, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateStage = function() {
  return this.setCreateStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateStage = function() {
  return jspb.Message.getField(this, 126) != null;
};


/**
 * optional Stage stage_created = 127;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageCreated = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 127));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageCreated = function(value) {
  return jspb.Message.setWrapperField(this, 127, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageCreated = function() {
  return this.setStageCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageCreated = function() {
  return jspb.Message.getField(this, 127) != null;
};


/**
 * optional Stage change_stage = 128;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeStage = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 128));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeStage = function(value) {
  return jspb.Message.setWrapperField(this, 128, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeStage = function() {
  return this.setChangeStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeStage = function() {
  return jspb.Message.getField(this, 128) != null;
};


/**
 * optional Stage stage_changed = 129;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageChanged = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 129));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageChanged = function(value) {
  return jspb.Message.setWrapperField(this, 129, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageChanged = function() {
  return this.setStageChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageChanged = function() {
  return jspb.Message.getField(this, 129) != null;
};


/**
 * optional Stage delete_stage = 130;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteStage = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 130));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteStage = function(value) {
  return jspb.Message.setWrapperField(this, 130, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteStage = function() {
  return this.setDeleteStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteStage = function() {
  return jspb.Message.getField(this, 130) != null;
};


/**
 * optional Stage stage_deleted = 131;
 * @return {?proto.vivacity.config.Stage}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageDeleted = function() {
  return /** @type{?proto.vivacity.config.Stage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Stage, 131));
};


/**
 * @param {?proto.vivacity.config.Stage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 131, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageDeleted = function() {
  return this.setStageDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageDeleted = function() {
  return jspb.Message.getField(this, 131) != null;
};


/**
 * optional Phase create_phase = 132;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreatePhase = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 132));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreatePhase = function(value) {
  return jspb.Message.setWrapperField(this, 132, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreatePhase = function() {
  return this.setCreatePhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreatePhase = function() {
  return jspb.Message.getField(this, 132) != null;
};


/**
 * optional Phase phase_created = 133;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseCreated = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 133));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseCreated = function(value) {
  return jspb.Message.setWrapperField(this, 133, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseCreated = function() {
  return this.setPhaseCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseCreated = function() {
  return jspb.Message.getField(this, 133) != null;
};


/**
 * optional Phase change_phase = 134;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangePhase = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 134));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangePhase = function(value) {
  return jspb.Message.setWrapperField(this, 134, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangePhase = function() {
  return this.setChangePhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangePhase = function() {
  return jspb.Message.getField(this, 134) != null;
};


/**
 * optional Phase phase_changed = 135;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseChanged = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 135));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseChanged = function(value) {
  return jspb.Message.setWrapperField(this, 135, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseChanged = function() {
  return this.setPhaseChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseChanged = function() {
  return jspb.Message.getField(this, 135) != null;
};


/**
 * optional Phase delete_phase = 136;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeletePhase = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 136));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeletePhase = function(value) {
  return jspb.Message.setWrapperField(this, 136, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeletePhase = function() {
  return this.setDeletePhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeletePhase = function() {
  return jspb.Message.getField(this, 136) != null;
};


/**
 * optional Phase phase_deleted = 137;
 * @return {?proto.vivacity.config.Phase}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseDeleted = function() {
  return /** @type{?proto.vivacity.config.Phase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Phase, 137));
};


/**
 * @param {?proto.vivacity.config.Phase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 137, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseDeleted = function() {
  return this.setPhaseDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseDeleted = function() {
  return jspb.Message.getField(this, 137) != null;
};


/**
 * optional Viu create_viu = 138;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateViu = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 138));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateViu = function(value) {
  return jspb.Message.setWrapperField(this, 138, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateViu = function() {
  return this.setCreateViu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateViu = function() {
  return jspb.Message.getField(this, 138) != null;
};


/**
 * optional Viu viu_created = 139;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getViuCreated = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 139));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setViuCreated = function(value) {
  return jspb.Message.setWrapperField(this, 139, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearViuCreated = function() {
  return this.setViuCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasViuCreated = function() {
  return jspb.Message.getField(this, 139) != null;
};


/**
 * optional Viu change_viu = 140;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeViu = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 140));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeViu = function(value) {
  return jspb.Message.setWrapperField(this, 140, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeViu = function() {
  return this.setChangeViu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeViu = function() {
  return jspb.Message.getField(this, 140) != null;
};


/**
 * optional Viu viu_changed = 141;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getViuChanged = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 141));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setViuChanged = function(value) {
  return jspb.Message.setWrapperField(this, 141, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearViuChanged = function() {
  return this.setViuChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasViuChanged = function() {
  return jspb.Message.getField(this, 141) != null;
};


/**
 * optional Viu delete_viu = 142;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteViu = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 142));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteViu = function(value) {
  return jspb.Message.setWrapperField(this, 142, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteViu = function() {
  return this.setDeleteViu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteViu = function() {
  return jspb.Message.getField(this, 142) != null;
};


/**
 * optional Viu viu_deleted = 143;
 * @return {?proto.vivacity.config.Viu}
 */
proto.vivacity.config.ConfigMessage.prototype.getViuDeleted = function() {
  return /** @type{?proto.vivacity.config.Viu} */ (
    jspb.Message.getWrapperField(this, vivacity_config_viu_config_pb.Viu, 143));
};


/**
 * @param {?proto.vivacity.config.Viu|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setViuDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 143, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearViuDeleted = function() {
  return this.setViuDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasViuDeleted = function() {
  return jspb.Message.getField(this, 143) != null;
};


/**
 * optional Detoxicity create_detoxicity = 144;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateDetoxicity = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 144));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateDetoxicity = function(value) {
  return jspb.Message.setWrapperField(this, 144, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateDetoxicity = function() {
  return this.setCreateDetoxicity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateDetoxicity = function() {
  return jspb.Message.getField(this, 144) != null;
};


/**
 * optional Detoxicity detoxicity_created = 145;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDetoxicityCreated = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 145));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDetoxicityCreated = function(value) {
  return jspb.Message.setWrapperField(this, 145, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDetoxicityCreated = function() {
  return this.setDetoxicityCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDetoxicityCreated = function() {
  return jspb.Message.getField(this, 145) != null;
};


/**
 * optional Detoxicity change_detoxicity = 146;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeDetoxicity = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 146));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeDetoxicity = function(value) {
  return jspb.Message.setWrapperField(this, 146, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeDetoxicity = function() {
  return this.setChangeDetoxicity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeDetoxicity = function() {
  return jspb.Message.getField(this, 146) != null;
};


/**
 * optional Detoxicity detoxicity_changed = 147;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDetoxicityChanged = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 147));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDetoxicityChanged = function(value) {
  return jspb.Message.setWrapperField(this, 147, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDetoxicityChanged = function() {
  return this.setDetoxicityChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDetoxicityChanged = function() {
  return jspb.Message.getField(this, 147) != null;
};


/**
 * optional Detoxicity delete_detoxicity = 148;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteDetoxicity = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 148));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteDetoxicity = function(value) {
  return jspb.Message.setWrapperField(this, 148, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteDetoxicity = function() {
  return this.setDeleteDetoxicity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteDetoxicity = function() {
  return jspb.Message.getField(this, 148) != null;
};


/**
 * optional Detoxicity detoxicity_deleted = 149;
 * @return {?proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDetoxicityDeleted = function() {
  return /** @type{?proto.vivacity.config.Detoxicity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Detoxicity, 149));
};


/**
 * @param {?proto.vivacity.config.Detoxicity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDetoxicityDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 149, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDetoxicityDeleted = function() {
  return this.setDetoxicityDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDetoxicityDeleted = function() {
  return jspb.Message.getField(this, 149) != null;
};


/**
 * optional StageToPhase create_stage_to_phase = 150;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateStageToPhase = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 150));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateStageToPhase = function(value) {
  return jspb.Message.setWrapperField(this, 150, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateStageToPhase = function() {
  return this.setCreateStageToPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateStageToPhase = function() {
  return jspb.Message.getField(this, 150) != null;
};


/**
 * optional StageToPhase stage_to_phase_created = 151;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageToPhaseCreated = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 151));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageToPhaseCreated = function(value) {
  return jspb.Message.setWrapperField(this, 151, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageToPhaseCreated = function() {
  return this.setStageToPhaseCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageToPhaseCreated = function() {
  return jspb.Message.getField(this, 151) != null;
};


/**
 * optional StageToPhase change_stage_to_phase = 152;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeStageToPhase = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 152));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeStageToPhase = function(value) {
  return jspb.Message.setWrapperField(this, 152, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeStageToPhase = function() {
  return this.setChangeStageToPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeStageToPhase = function() {
  return jspb.Message.getField(this, 152) != null;
};


/**
 * optional StageToPhase stage_to_phase_changed = 153;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageToPhaseChanged = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 153));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageToPhaseChanged = function(value) {
  return jspb.Message.setWrapperField(this, 153, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageToPhaseChanged = function() {
  return this.setStageToPhaseChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageToPhaseChanged = function() {
  return jspb.Message.getField(this, 153) != null;
};


/**
 * optional StageToPhase delete_stage_to_phase = 154;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteStageToPhase = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 154));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteStageToPhase = function(value) {
  return jspb.Message.setWrapperField(this, 154, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteStageToPhase = function() {
  return this.setDeleteStageToPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteStageToPhase = function() {
  return jspb.Message.getField(this, 154) != null;
};


/**
 * optional StageToPhase stage_to_phase_deleted = 155;
 * @return {?proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageToPhaseDeleted = function() {
  return /** @type{?proto.vivacity.config.StageToPhase} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageToPhase, 155));
};


/**
 * @param {?proto.vivacity.config.StageToPhase|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageToPhaseDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 155, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageToPhaseDeleted = function() {
  return this.setStageToPhaseDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageToPhaseDeleted = function() {
  return jspb.Message.getField(this, 155) != null;
};


/**
 * optional VisionProgramToControllerStream create_vision_program_to_controller_stream = 156;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVisionProgramToControllerStream = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 156));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVisionProgramToControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 156, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVisionProgramToControllerStream = function() {
  return this.setCreateVisionProgramToControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVisionProgramToControllerStream = function() {
  return jspb.Message.getField(this, 156) != null;
};


/**
 * optional VisionProgramToControllerStream vision_program_to_controller_stream_created = 157;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToControllerStreamCreated = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 157));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToControllerStreamCreated = function(value) {
  return jspb.Message.setWrapperField(this, 157, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToControllerStreamCreated = function() {
  return this.setVisionProgramToControllerStreamCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToControllerStreamCreated = function() {
  return jspb.Message.getField(this, 157) != null;
};


/**
 * optional VisionProgramToControllerStream change_vision_program_to_controller_stream = 158;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVisionProgramToControllerStream = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 158));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVisionProgramToControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 158, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVisionProgramToControllerStream = function() {
  return this.setChangeVisionProgramToControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVisionProgramToControllerStream = function() {
  return jspb.Message.getField(this, 158) != null;
};


/**
 * optional VisionProgramToControllerStream vision_program_to_controller_stream_changed = 159;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToControllerStreamChanged = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 159));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToControllerStreamChanged = function(value) {
  return jspb.Message.setWrapperField(this, 159, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToControllerStreamChanged = function() {
  return this.setVisionProgramToControllerStreamChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToControllerStreamChanged = function() {
  return jspb.Message.getField(this, 159) != null;
};


/**
 * optional VisionProgramToControllerStream delete_vision_program_to_controller_stream = 160;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVisionProgramToControllerStream = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 160));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVisionProgramToControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 160, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVisionProgramToControllerStream = function() {
  return this.setDeleteVisionProgramToControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVisionProgramToControllerStream = function() {
  return jspb.Message.getField(this, 160) != null;
};


/**
 * optional VisionProgramToControllerStream vision_program_to_controller_stream_deleted = 161;
 * @return {?proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToControllerStreamDeleted = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.VisionProgramToControllerStream, 161));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToControllerStreamDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 161, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToControllerStreamDeleted = function() {
  return this.setVisionProgramToControllerStreamDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToControllerStreamDeleted = function() {
  return jspb.Message.getField(this, 161) != null;
};


/**
 * optional PhaseToImageSpaceZone create_phase_to_image_space_zone = 162;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreatePhaseToImageSpaceZone = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 162));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreatePhaseToImageSpaceZone = function(value) {
  return jspb.Message.setWrapperField(this, 162, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreatePhaseToImageSpaceZone = function() {
  return this.setCreatePhaseToImageSpaceZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreatePhaseToImageSpaceZone = function() {
  return jspb.Message.getField(this, 162) != null;
};


/**
 * optional PhaseToImageSpaceZone phase_to_image_space_zone_created = 163;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceZoneCreated = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 163));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceZoneCreated = function(value) {
  return jspb.Message.setWrapperField(this, 163, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceZoneCreated = function() {
  return this.setPhaseToImageSpaceZoneCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceZoneCreated = function() {
  return jspb.Message.getField(this, 163) != null;
};


/**
 * optional PhaseToImageSpaceZone change_phase_to_image_space_zone = 164;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangePhaseToImageSpaceZone = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 164));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangePhaseToImageSpaceZone = function(value) {
  return jspb.Message.setWrapperField(this, 164, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangePhaseToImageSpaceZone = function() {
  return this.setChangePhaseToImageSpaceZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangePhaseToImageSpaceZone = function() {
  return jspb.Message.getField(this, 164) != null;
};


/**
 * optional PhaseToImageSpaceZone phase_to_image_space_zone_changed = 165;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceZoneChanged = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 165));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceZoneChanged = function(value) {
  return jspb.Message.setWrapperField(this, 165, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceZoneChanged = function() {
  return this.setPhaseToImageSpaceZoneChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceZoneChanged = function() {
  return jspb.Message.getField(this, 165) != null;
};


/**
 * optional PhaseToImageSpaceZone delete_phase_to_image_space_zone = 166;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeletePhaseToImageSpaceZone = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 166));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeletePhaseToImageSpaceZone = function(value) {
  return jspb.Message.setWrapperField(this, 166, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeletePhaseToImageSpaceZone = function() {
  return this.setDeletePhaseToImageSpaceZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeletePhaseToImageSpaceZone = function() {
  return jspb.Message.getField(this, 166) != null;
};


/**
 * optional PhaseToImageSpaceZone phase_to_image_space_zone_deleted = 167;
 * @return {?proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceZoneDeleted = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceZone, 167));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceZoneDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 167, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceZoneDeleted = function() {
  return this.setPhaseToImageSpaceZoneDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceZoneDeleted = function() {
  return jspb.Message.getField(this, 167) != null;
};


/**
 * optional PhaseToImageSpaceCountline create_phase_to_image_space_countline = 170;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreatePhaseToImageSpaceCountline = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 170));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreatePhaseToImageSpaceCountline = function(value) {
  return jspb.Message.setWrapperField(this, 170, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreatePhaseToImageSpaceCountline = function() {
  return this.setCreatePhaseToImageSpaceCountline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreatePhaseToImageSpaceCountline = function() {
  return jspb.Message.getField(this, 170) != null;
};


/**
 * optional PhaseToImageSpaceCountline phase_to_image_space_countline_created = 171;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceCountlineCreated = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 171));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceCountlineCreated = function(value) {
  return jspb.Message.setWrapperField(this, 171, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceCountlineCreated = function() {
  return this.setPhaseToImageSpaceCountlineCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceCountlineCreated = function() {
  return jspb.Message.getField(this, 171) != null;
};


/**
 * optional PhaseToImageSpaceCountline change_phase_to_image_space_countline = 172;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangePhaseToImageSpaceCountline = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 172));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangePhaseToImageSpaceCountline = function(value) {
  return jspb.Message.setWrapperField(this, 172, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangePhaseToImageSpaceCountline = function() {
  return this.setChangePhaseToImageSpaceCountline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangePhaseToImageSpaceCountline = function() {
  return jspb.Message.getField(this, 172) != null;
};


/**
 * optional PhaseToImageSpaceCountline phase_to_image_space_countline_changed = 173;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceCountlineChanged = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 173));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceCountlineChanged = function(value) {
  return jspb.Message.setWrapperField(this, 173, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceCountlineChanged = function() {
  return this.setPhaseToImageSpaceCountlineChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceCountlineChanged = function() {
  return jspb.Message.getField(this, 173) != null;
};


/**
 * optional PhaseToImageSpaceCountline delete_phase_to_image_space_countline = 174;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeletePhaseToImageSpaceCountline = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 174));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeletePhaseToImageSpaceCountline = function(value) {
  return jspb.Message.setWrapperField(this, 174, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeletePhaseToImageSpaceCountline = function() {
  return this.setDeletePhaseToImageSpaceCountline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeletePhaseToImageSpaceCountline = function() {
  return jspb.Message.getField(this, 174) != null;
};


/**
 * optional PhaseToImageSpaceCountline phase_to_image_space_countline_deleted = 175;
 * @return {?proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.ConfigMessage.prototype.getPhaseToImageSpaceCountlineDeleted = function() {
  return /** @type{?proto.vivacity.config.PhaseToImageSpaceCountline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.PhaseToImageSpaceCountline, 175));
};


/**
 * @param {?proto.vivacity.config.PhaseToImageSpaceCountline|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPhaseToImageSpaceCountlineDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 175, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPhaseToImageSpaceCountlineDeleted = function() {
  return this.setPhaseToImageSpaceCountlineDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPhaseToImageSpaceCountlineDeleted = function() {
  return jspb.Message.getField(this, 175) != null;
};


/**
 * optional AgentType create_agent_type = 176;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgentType = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 176));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgentType = function(value) {
  return jspb.Message.setWrapperField(this, 176, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgentType = function() {
  return this.setCreateAgentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgentType = function() {
  return jspb.Message.getField(this, 176) != null;
};


/**
 * optional AgentType agent_type_created = 177;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentTypeCreated = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 177));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentTypeCreated = function(value) {
  return jspb.Message.setWrapperField(this, 177, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentTypeCreated = function() {
  return this.setAgentTypeCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentTypeCreated = function() {
  return jspb.Message.getField(this, 177) != null;
};


/**
 * optional AgentType change_agent_type = 178;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgentType = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 178));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgentType = function(value) {
  return jspb.Message.setWrapperField(this, 178, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgentType = function() {
  return this.setChangeAgentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgentType = function() {
  return jspb.Message.getField(this, 178) != null;
};


/**
 * optional AgentType agent_type_changed = 179;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentTypeChanged = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 179));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentTypeChanged = function(value) {
  return jspb.Message.setWrapperField(this, 179, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentTypeChanged = function() {
  return this.setAgentTypeChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentTypeChanged = function() {
  return jspb.Message.getField(this, 179) != null;
};


/**
 * optional AgentType delete_agent_type = 180;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgentType = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 180));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgentType = function(value) {
  return jspb.Message.setWrapperField(this, 180, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgentType = function() {
  return this.setDeleteAgentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgentType = function() {
  return jspb.Message.getField(this, 180) != null;
};


/**
 * optional AgentType agent_type_deleted = 181;
 * @return {?proto.vivacity.config.AgentType}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentTypeDeleted = function() {
  return /** @type{?proto.vivacity.config.AgentType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentType, 181));
};


/**
 * @param {?proto.vivacity.config.AgentType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentTypeDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 181, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentTypeDeleted = function() {
  return this.setAgentTypeDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentTypeDeleted = function() {
  return jspb.Message.getField(this, 181) != null;
};


/**
 * optional EntityGroupGenealogy create_entity_group_genealogy = 182;
 * @return {?proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateEntityGroupGenealogy = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupGenealogy, 182));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateEntityGroupGenealogy = function(value) {
  return jspb.Message.setWrapperField(this, 182, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateEntityGroupGenealogy = function() {
  return this.setCreateEntityGroupGenealogy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateEntityGroupGenealogy = function() {
  return jspb.Message.getField(this, 182) != null;
};


/**
 * optional EntityGroupGenealogy entity_group_genealogy_created = 183;
 * @return {?proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityGroupGenealogyCreated = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupGenealogy, 183));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityGroupGenealogyCreated = function(value) {
  return jspb.Message.setWrapperField(this, 183, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityGroupGenealogyCreated = function() {
  return this.setEntityGroupGenealogyCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityGroupGenealogyCreated = function() {
  return jspb.Message.getField(this, 183) != null;
};


/**
 * optional EntityGroupGenealogy delete_entity_group_genealogy = 184;
 * @return {?proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteEntityGroupGenealogy = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupGenealogy, 184));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteEntityGroupGenealogy = function(value) {
  return jspb.Message.setWrapperField(this, 184, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteEntityGroupGenealogy = function() {
  return this.setDeleteEntityGroupGenealogy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteEntityGroupGenealogy = function() {
  return jspb.Message.getField(this, 184) != null;
};


/**
 * optional EntityGroupGenealogy entity_group_genealogy_deleted = 185;
 * @return {?proto.vivacity.config.EntityGroupGenealogy}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityGroupGenealogyDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityGroupGenealogy} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupGenealogy, 185));
};


/**
 * @param {?proto.vivacity.config.EntityGroupGenealogy|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityGroupGenealogyDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 185, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityGroupGenealogyDeleted = function() {
  return this.setEntityGroupGenealogyDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityGroupGenealogyDeleted = function() {
  return jspb.Message.getField(this, 185) != null;
};


/**
 * optional EntityGroup create_entity_group = 186;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateEntityGroup = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 186));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateEntityGroup = function(value) {
  return jspb.Message.setWrapperField(this, 186, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateEntityGroup = function() {
  return this.setCreateEntityGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateEntityGroup = function() {
  return jspb.Message.getField(this, 186) != null;
};


/**
 * optional EntityGroup entity_group_created = 187;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityGroupCreated = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 187));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityGroupCreated = function(value) {
  return jspb.Message.setWrapperField(this, 187, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityGroupCreated = function() {
  return this.setEntityGroupCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityGroupCreated = function() {
  return jspb.Message.getField(this, 187) != null;
};


/**
 * optional EntityGroup change_entity_group_fields = 188;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeEntityGroupFields = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 188));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeEntityGroupFields = function(value) {
  return jspb.Message.setWrapperField(this, 188, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeEntityGroupFields = function() {
  return this.setChangeEntityGroupFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeEntityGroupFields = function() {
  return jspb.Message.getField(this, 188) != null;
};


/**
 * optional EntityGroup entity_group_fields_changed = 189;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityGroupFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 189));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityGroupFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 189, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityGroupFieldsChanged = function() {
  return this.setEntityGroupFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityGroupFieldsChanged = function() {
  return jspb.Message.getField(this, 189) != null;
};


/**
 * optional EntityGroup delete_entity_group = 190;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteEntityGroup = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 190));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteEntityGroup = function(value) {
  return jspb.Message.setWrapperField(this, 190, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteEntityGroup = function() {
  return this.setDeleteEntityGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteEntityGroup = function() {
  return jspb.Message.getField(this, 190) != null;
};


/**
 * optional EntityGroup entity_group_deleted = 191;
 * @return {?proto.vivacity.config.EntityGroup}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityGroupDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityGroup} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroup, 191));
};


/**
 * @param {?proto.vivacity.config.EntityGroup|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityGroupDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 191, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityGroupDeleted = function() {
  return this.setEntityGroupDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityGroupDeleted = function() {
  return jspb.Message.getField(this, 191) != null;
};


/**
 * optional EntityGroupLinkage add_group_linkage = 192;
 * @return {?proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getAddGroupLinkage = function() {
  return /** @type{?proto.vivacity.config.EntityGroupLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupLinkage, 192));
};


/**
 * @param {?proto.vivacity.config.EntityGroupLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAddGroupLinkage = function(value) {
  return jspb.Message.setWrapperField(this, 192, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAddGroupLinkage = function() {
  return this.setAddGroupLinkage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAddGroupLinkage = function() {
  return jspb.Message.getField(this, 192) != null;
};


/**
 * optional EntityGroupLinkage group_linkage_added = 193;
 * @return {?proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getGroupLinkageAdded = function() {
  return /** @type{?proto.vivacity.config.EntityGroupLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupLinkage, 193));
};


/**
 * @param {?proto.vivacity.config.EntityGroupLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setGroupLinkageAdded = function(value) {
  return jspb.Message.setWrapperField(this, 193, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearGroupLinkageAdded = function() {
  return this.setGroupLinkageAdded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasGroupLinkageAdded = function() {
  return jspb.Message.getField(this, 193) != null;
};


/**
 * optional EntityGroupLinkage delete_group_linkage = 194;
 * @return {?proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteGroupLinkage = function() {
  return /** @type{?proto.vivacity.config.EntityGroupLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupLinkage, 194));
};


/**
 * @param {?proto.vivacity.config.EntityGroupLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteGroupLinkage = function(value) {
  return jspb.Message.setWrapperField(this, 194, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteGroupLinkage = function() {
  return this.setDeleteGroupLinkage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteGroupLinkage = function() {
  return jspb.Message.getField(this, 194) != null;
};


/**
 * optional EntityGroupLinkage group_linkage_deleted = 195;
 * @return {?proto.vivacity.config.EntityGroupLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getGroupLinkageDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityGroupLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_groups_pb.EntityGroupLinkage, 195));
};


/**
 * @param {?proto.vivacity.config.EntityGroupLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setGroupLinkageDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 195, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearGroupLinkageDeleted = function() {
  return this.setGroupLinkageDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasGroupLinkageDeleted = function() {
  return jspb.Message.getField(this, 195) != null;
};


/**
 * optional CommandGroupTemplateMessage set_command_group_template = 196;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getSetCommandGroupTemplate = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 196));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setSetCommandGroupTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 196, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearSetCommandGroupTemplate = function() {
  return this.setSetCommandGroupTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasSetCommandGroupTemplate = function() {
  return jspb.Message.getField(this, 196) != null;
};


/**
 * optional CommandGroupTemplateMessage command_group_template_setted = 197;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCommandGroupTemplateSetted = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 197));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCommandGroupTemplateSetted = function(value) {
  return jspb.Message.setWrapperField(this, 197, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCommandGroupTemplateSetted = function() {
  return this.setCommandGroupTemplateSetted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCommandGroupTemplateSetted = function() {
  return jspb.Message.getField(this, 197) != null;
};


/**
 * optional ZonalWindowedClassConfig create_zonal_windowed_class_config = 198;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateZonalWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 198));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateZonalWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 198, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateZonalWindowedClassConfig = function() {
  return this.setCreateZonalWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateZonalWindowedClassConfig = function() {
  return jspb.Message.getField(this, 198) != null;
};


/**
 * optional ZonalWindowedClassConfig zonal_windowed_class_config_created = 199;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getZonalWindowedClassConfigCreated = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 199));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZonalWindowedClassConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 199, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZonalWindowedClassConfigCreated = function() {
  return this.setZonalWindowedClassConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZonalWindowedClassConfigCreated = function() {
  return jspb.Message.getField(this, 199) != null;
};


/**
 * optional ZonalWindowedClassConfig change_zonal_windowed_class_config = 200;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZonalWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 200));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZonalWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 200, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZonalWindowedClassConfig = function() {
  return this.setChangeZonalWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZonalWindowedClassConfig = function() {
  return jspb.Message.getField(this, 200) != null;
};


/**
 * optional ZonalWindowedClassConfig zonal_windowed_class_config_changed = 201;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getZonalWindowedClassConfigChanged = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 201));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZonalWindowedClassConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 201, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZonalWindowedClassConfigChanged = function() {
  return this.setZonalWindowedClassConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZonalWindowedClassConfigChanged = function() {
  return jspb.Message.getField(this, 201) != null;
};


/**
 * optional ZonalWindowedClassConfig delete_zonal_windowed_class_config = 202;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteZonalWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 202));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteZonalWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 202, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteZonalWindowedClassConfig = function() {
  return this.setDeleteZonalWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteZonalWindowedClassConfig = function() {
  return jspb.Message.getField(this, 202) != null;
};


/**
 * optional ZonalWindowedClassConfig zonal_windowed_class_config_deleted = 203;
 * @return {?proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getZonalWindowedClassConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.ZonalWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonalWindowedClassConfig, 203));
};


/**
 * @param {?proto.vivacity.config.ZonalWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZonalWindowedClassConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 203, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZonalWindowedClassConfigDeleted = function() {
  return this.setZonalWindowedClassConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZonalWindowedClassConfigDeleted = function() {
  return jspb.Message.getField(this, 203) != null;
};


/**
 * optional ControllerStreamControlSchedule create_controller_stream_control_schedule = 204;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateControllerStreamControlSchedule = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 204));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateControllerStreamControlSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 204, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateControllerStreamControlSchedule = function() {
  return this.setCreateControllerStreamControlSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateControllerStreamControlSchedule = function() {
  return jspb.Message.getField(this, 204) != null;
};


/**
 * optional ControllerStreamControlSchedule controller_stream_control_schedule_created = 205;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamControlScheduleCreated = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 205));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamControlScheduleCreated = function(value) {
  return jspb.Message.setWrapperField(this, 205, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamControlScheduleCreated = function() {
  return this.setControllerStreamControlScheduleCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamControlScheduleCreated = function() {
  return jspb.Message.getField(this, 205) != null;
};


/**
 * optional ControllerStreamControlSchedule change_controller_stream_control_schedule = 206;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeControllerStreamControlSchedule = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 206));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeControllerStreamControlSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 206, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeControllerStreamControlSchedule = function() {
  return this.setChangeControllerStreamControlSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeControllerStreamControlSchedule = function() {
  return jspb.Message.getField(this, 206) != null;
};


/**
 * optional ControllerStreamControlSchedule controller_stream_control_schedule_changed = 207;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamControlScheduleChanged = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 207));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamControlScheduleChanged = function(value) {
  return jspb.Message.setWrapperField(this, 207, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamControlScheduleChanged = function() {
  return this.setControllerStreamControlScheduleChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamControlScheduleChanged = function() {
  return jspb.Message.getField(this, 207) != null;
};


/**
 * optional ControllerStreamControlSchedule delete_controller_stream_control_schedule = 208;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteControllerStreamControlSchedule = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 208));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteControllerStreamControlSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 208, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteControllerStreamControlSchedule = function() {
  return this.setDeleteControllerStreamControlSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteControllerStreamControlSchedule = function() {
  return jspb.Message.getField(this, 208) != null;
};


/**
 * optional ControllerStreamControlSchedule controller_stream_control_schedule_deleted = 209;
 * @return {?proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamControlScheduleDeleted = function() {
  return /** @type{?proto.vivacity.config.ControllerStreamControlSchedule} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.ControllerStreamControlSchedule, 209));
};


/**
 * @param {?proto.vivacity.config.ControllerStreamControlSchedule|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamControlScheduleDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 209, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamControlScheduleDeleted = function() {
  return this.setControllerStreamControlScheduleDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamControlScheduleDeleted = function() {
  return jspb.Message.getField(this, 209) != null;
};


/**
 * optional StageTransition create_stage_transition = 210;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateStageTransition = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 210));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateStageTransition = function(value) {
  return jspb.Message.setWrapperField(this, 210, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateStageTransition = function() {
  return this.setCreateStageTransition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateStageTransition = function() {
  return jspb.Message.getField(this, 210) != null;
};


/**
 * optional StageTransition stage_transition_created = 211;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageTransitionCreated = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 211));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageTransitionCreated = function(value) {
  return jspb.Message.setWrapperField(this, 211, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageTransitionCreated = function() {
  return this.setStageTransitionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageTransitionCreated = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional StageTransition change_stage_transition = 212;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeStageTransition = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 212));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeStageTransition = function(value) {
  return jspb.Message.setWrapperField(this, 212, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeStageTransition = function() {
  return this.setChangeStageTransition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeStageTransition = function() {
  return jspb.Message.getField(this, 212) != null;
};


/**
 * optional StageTransition stage_transition_changed = 213;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageTransitionChanged = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 213));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageTransitionChanged = function(value) {
  return jspb.Message.setWrapperField(this, 213, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageTransitionChanged = function() {
  return this.setStageTransitionChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageTransitionChanged = function() {
  return jspb.Message.getField(this, 213) != null;
};


/**
 * optional StageTransition delete_stage_transition = 214;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteStageTransition = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 214));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteStageTransition = function(value) {
  return jspb.Message.setWrapperField(this, 214, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteStageTransition = function() {
  return this.setDeleteStageTransition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteStageTransition = function() {
  return jspb.Message.getField(this, 214) != null;
};


/**
 * optional StageTransition stage_transition_deleted = 215;
 * @return {?proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.ConfigMessage.prototype.getStageTransitionDeleted = function() {
  return /** @type{?proto.vivacity.config.StageTransition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.StageTransition, 215));
};


/**
 * @param {?proto.vivacity.config.StageTransition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setStageTransitionDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 215, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearStageTransitionDeleted = function() {
  return this.setStageTransitionDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasStageTransitionDeleted = function() {
  return jspb.Message.getField(this, 215) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig create_vp_to_vp_windowed_features_websocket_connection_config = 216;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 216));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVpToVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 216, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setCreateVpToVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 216) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig vp_to_vp_windowed_features_websocket_connection_config_created = 217;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpToVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 217));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpToVpWindowedFeaturesWebsocketConnectionConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 217, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpToVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return this.setVpToVpWindowedFeaturesWebsocketConnectionConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpToVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return jspb.Message.getField(this, 217) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig change_vp_to_vp_windowed_features_websocket_connection_config = 218;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 218));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVpToVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 218, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setChangeVpToVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 218) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig vp_to_vp_windowed_features_websocket_connection_config_changed = 219;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpToVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 219));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpToVpWindowedFeaturesWebsocketConnectionConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 219, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpToVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return this.setVpToVpWindowedFeaturesWebsocketConnectionConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpToVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return jspb.Message.getField(this, 219) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig delete_vp_to_vp_windowed_features_websocket_connection_config = 220;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 220));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 220, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVpToVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 220) != null;
};


/**
 * optional VPToVPWindowedFeaturesWebsocketConnectionConfig vp_to_vp_windowed_features_websocket_connection_config_deleted = 221;
 * @return {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPToVPWindowedFeaturesWebsocketConnectionConfig, 221));
};


/**
 * @param {?proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 221, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return this.setVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpToVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return jspb.Message.getField(this, 221) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig create_vp_windowed_features_websocket_connection_config = 222;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 222));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 222, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setCreateVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 222) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig vp_windowed_features_websocket_connection_config_created = 223;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 223));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpWindowedFeaturesWebsocketConnectionConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 223, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return this.setVpWindowedFeaturesWebsocketConnectionConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpWindowedFeaturesWebsocketConnectionConfigCreated = function() {
  return jspb.Message.getField(this, 223) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig change_vp_windowed_features_websocket_connection_config = 224;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 224));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 224, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setChangeVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 224) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig vp_windowed_features_websocket_connection_config_changed = 225;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 225));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpWindowedFeaturesWebsocketConnectionConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 225, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return this.setVpWindowedFeaturesWebsocketConnectionConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpWindowedFeaturesWebsocketConnectionConfigChanged = function() {
  return jspb.Message.getField(this, 225) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig delete_vp_windowed_features_websocket_connection_config = 226;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 226));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVpWindowedFeaturesWebsocketConnectionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 226, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return this.setDeleteVpWindowedFeaturesWebsocketConnectionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVpWindowedFeaturesWebsocketConnectionConfig = function() {
  return jspb.Message.getField(this, 226) != null;
};


/**
 * optional VPWindowedFeaturesWebsocketConnectionConfig vp_windowed_features_websocket_connection_config_deleted = 227;
 * @return {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VPWindowedFeaturesWebsocketConnectionConfig, 227));
};


/**
 * @param {?proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVpWindowedFeaturesWebsocketConnectionConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 227, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return this.setVpWindowedFeaturesWebsocketConnectionConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVpWindowedFeaturesWebsocketConnectionConfigDeleted = function() {
  return jspb.Message.getField(this, 227) != null;
};


/**
 * optional CVModel create_cv_model = 228;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCvModel = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 228));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCvModel = function(value) {
  return jspb.Message.setWrapperField(this, 228, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCvModel = function() {
  return this.setCreateCvModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCvModel = function() {
  return jspb.Message.getField(this, 228) != null;
};


/**
 * optional CVModel cv_model_created = 229;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelCreated = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 229));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelCreated = function(value) {
  return jspb.Message.setWrapperField(this, 229, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelCreated = function() {
  return this.setCvModelCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelCreated = function() {
  return jspb.Message.getField(this, 229) != null;
};


/**
 * optional CVModel change_cv_model = 230;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCvModel = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 230));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCvModel = function(value) {
  return jspb.Message.setWrapperField(this, 230, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCvModel = function() {
  return this.setChangeCvModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCvModel = function() {
  return jspb.Message.getField(this, 230) != null;
};


/**
 * optional CVModel cv_model_changed = 231;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelChanged = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 231));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelChanged = function(value) {
  return jspb.Message.setWrapperField(this, 231, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelChanged = function() {
  return this.setCvModelChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelChanged = function() {
  return jspb.Message.getField(this, 231) != null;
};


/**
 * optional CVModel delete_cv_model = 232;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCvModel = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 232));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCvModel = function(value) {
  return jspb.Message.setWrapperField(this, 232, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCvModel = function() {
  return this.setDeleteCvModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCvModel = function() {
  return jspb.Message.getField(this, 232) != null;
};


/**
 * optional CVModel cv_model_deleted = 233;
 * @return {?proto.vivacity.config.CVModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelDeleted = function() {
  return /** @type{?proto.vivacity.config.CVModel} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModel, 233));
};


/**
 * @param {?proto.vivacity.config.CVModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 233, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelDeleted = function() {
  return this.setCvModelDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelDeleted = function() {
  return jspb.Message.getField(this, 233) != null;
};


/**
 * optional CVModelType create_cv_model_type = 234;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCvModelType = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 234));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCvModelType = function(value) {
  return jspb.Message.setWrapperField(this, 234, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCvModelType = function() {
  return this.setCreateCvModelType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCvModelType = function() {
  return jspb.Message.getField(this, 234) != null;
};


/**
 * optional CVModelType cv_model_type_created = 235;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelTypeCreated = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 235));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelTypeCreated = function(value) {
  return jspb.Message.setWrapperField(this, 235, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelTypeCreated = function() {
  return this.setCvModelTypeCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelTypeCreated = function() {
  return jspb.Message.getField(this, 235) != null;
};


/**
 * optional CVModelType change_cv_model_type = 236;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCvModelType = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 236));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCvModelType = function(value) {
  return jspb.Message.setWrapperField(this, 236, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCvModelType = function() {
  return this.setChangeCvModelType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCvModelType = function() {
  return jspb.Message.getField(this, 236) != null;
};


/**
 * optional CVModelType cv_model_type_changed = 237;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelTypeChanged = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 237));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelTypeChanged = function(value) {
  return jspb.Message.setWrapperField(this, 237, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelTypeChanged = function() {
  return this.setCvModelTypeChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelTypeChanged = function() {
  return jspb.Message.getField(this, 237) != null;
};


/**
 * optional CVModelType delete_cv_model_type = 238;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCvModelType = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 238));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCvModelType = function(value) {
  return jspb.Message.setWrapperField(this, 238, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCvModelType = function() {
  return this.setDeleteCvModelType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCvModelType = function() {
  return jspb.Message.getField(this, 238) != null;
};


/**
 * optional CVModelType cv_model_type_deleted = 239;
 * @return {?proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelTypeDeleted = function() {
  return /** @type{?proto.vivacity.config.CVModelType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelType, 239));
};


/**
 * @param {?proto.vivacity.config.CVModelType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelTypeDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 239, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelTypeDeleted = function() {
  return this.setCvModelTypeDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelTypeDeleted = function() {
  return jspb.Message.getField(this, 239) != null;
};


/**
 * optional CVModelClass create_cv_model_class = 240;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCvModelClass = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 240));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCvModelClass = function(value) {
  return jspb.Message.setWrapperField(this, 240, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCvModelClass = function() {
  return this.setCreateCvModelClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCvModelClass = function() {
  return jspb.Message.getField(this, 240) != null;
};


/**
 * optional CVModelClass cv_model_class_created = 241;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelClassCreated = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 241));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelClassCreated = function(value) {
  return jspb.Message.setWrapperField(this, 241, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelClassCreated = function() {
  return this.setCvModelClassCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelClassCreated = function() {
  return jspb.Message.getField(this, 241) != null;
};


/**
 * optional CVModelClass change_cv_model_class = 242;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCvModelClass = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 242));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCvModelClass = function(value) {
  return jspb.Message.setWrapperField(this, 242, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCvModelClass = function() {
  return this.setChangeCvModelClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCvModelClass = function() {
  return jspb.Message.getField(this, 242) != null;
};


/**
 * optional CVModelClass cv_model_class_changed = 243;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelClassChanged = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 243));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelClassChanged = function(value) {
  return jspb.Message.setWrapperField(this, 243, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelClassChanged = function() {
  return this.setCvModelClassChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelClassChanged = function() {
  return jspb.Message.getField(this, 243) != null;
};


/**
 * optional CVModelClass delete_cv_model_class = 244;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCvModelClass = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 244));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCvModelClass = function(value) {
  return jspb.Message.setWrapperField(this, 244, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCvModelClass = function() {
  return this.setDeleteCvModelClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCvModelClass = function() {
  return jspb.Message.getField(this, 244) != null;
};


/**
 * optional CVModelClass cv_model_class_deleted = 245;
 * @return {?proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.ConfigMessage.prototype.getCvModelClassDeleted = function() {
  return /** @type{?proto.vivacity.config.CVModelClass} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.CVModelClass, 245));
};


/**
 * @param {?proto.vivacity.config.CVModelClass|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCvModelClassDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 245, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCvModelClassDeleted = function() {
  return this.setCvModelClassDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCvModelClassDeleted = function() {
  return jspb.Message.getField(this, 245) != null;
};


/**
 * optional VisionProgramPreset create_vision_program_preset = 246;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVisionProgramPreset = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 246));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVisionProgramPreset = function(value) {
  return jspb.Message.setWrapperField(this, 246, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVisionProgramPreset = function() {
  return this.setCreateVisionProgramPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVisionProgramPreset = function() {
  return jspb.Message.getField(this, 246) != null;
};


/**
 * optional VisionProgramPreset vision_program_preset_created = 247;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramPresetCreated = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 247));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramPresetCreated = function(value) {
  return jspb.Message.setWrapperField(this, 247, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramPresetCreated = function() {
  return this.setVisionProgramPresetCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramPresetCreated = function() {
  return jspb.Message.getField(this, 247) != null;
};


/**
 * optional VisionProgramPreset change_vision_program_preset = 248;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVisionProgramPreset = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 248));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVisionProgramPreset = function(value) {
  return jspb.Message.setWrapperField(this, 248, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVisionProgramPreset = function() {
  return this.setChangeVisionProgramPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVisionProgramPreset = function() {
  return jspb.Message.getField(this, 248) != null;
};


/**
 * optional VisionProgramPreset vision_program_preset_changed = 249;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramPresetChanged = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 249));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramPresetChanged = function(value) {
  return jspb.Message.setWrapperField(this, 249, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramPresetChanged = function() {
  return this.setVisionProgramPresetChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramPresetChanged = function() {
  return jspb.Message.getField(this, 249) != null;
};


/**
 * optional VisionProgramPreset delete_vision_program_preset = 250;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVisionProgramPreset = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 250));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVisionProgramPreset = function(value) {
  return jspb.Message.setWrapperField(this, 250, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVisionProgramPreset = function() {
  return this.setDeleteVisionProgramPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVisionProgramPreset = function() {
  return jspb.Message.getField(this, 250) != null;
};


/**
 * optional VisionProgramPreset vision_program_preset_deleted = 251;
 * @return {?proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramPresetDeleted = function() {
  return /** @type{?proto.vivacity.config.VisionProgramPreset} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cv_model_config_pb.VisionProgramPreset, 251));
};


/**
 * @param {?proto.vivacity.config.VisionProgramPreset|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramPresetDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 251, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramPresetDeleted = function() {
  return this.setVisionProgramPresetDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramPresetDeleted = function() {
  return jspb.Message.getField(this, 251) != null;
};


/**
 * optional EntityTemplateType create_entity_template_type = 258;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateEntityTemplateType = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 258));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateEntityTemplateType = function(value) {
  return jspb.Message.setWrapperField(this, 258, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateEntityTemplateType = function() {
  return this.setCreateEntityTemplateType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateEntityTemplateType = function() {
  return jspb.Message.getField(this, 258) != null;
};


/**
 * optional EntityTemplateType entity_template_type_created = 259;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeCreated = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 259));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeCreated = function(value) {
  return jspb.Message.setWrapperField(this, 259, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeCreated = function() {
  return this.setEntityTemplateTypeCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeCreated = function() {
  return jspb.Message.getField(this, 259) != null;
};


/**
 * optional EntityTemplateType change_entity_template_type = 260;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeEntityTemplateType = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 260));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeEntityTemplateType = function(value) {
  return jspb.Message.setWrapperField(this, 260, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeEntityTemplateType = function() {
  return this.setChangeEntityTemplateType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeEntityTemplateType = function() {
  return jspb.Message.getField(this, 260) != null;
};


/**
 * optional EntityTemplateType entity_template_type_changed = 261;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeChanged = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 261));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeChanged = function(value) {
  return jspb.Message.setWrapperField(this, 261, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeChanged = function() {
  return this.setEntityTemplateTypeChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeChanged = function() {
  return jspb.Message.getField(this, 261) != null;
};


/**
 * optional EntityTemplateType delete_entity_template_type = 262;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteEntityTemplateType = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 262));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteEntityTemplateType = function(value) {
  return jspb.Message.setWrapperField(this, 262, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteEntityTemplateType = function() {
  return this.setDeleteEntityTemplateType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteEntityTemplateType = function() {
  return jspb.Message.getField(this, 262) != null;
};


/**
 * optional EntityTemplateType entity_template_type_deleted = 263;
 * @return {?proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateType} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateType, 263));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateType|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 263, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeDeleted = function() {
  return this.setEntityTemplateTypeDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeDeleted = function() {
  return jspb.Message.getField(this, 263) != null;
};


/**
 * optional EntityTemplate create_entity_template = 264;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 264));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 264, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateEntityTemplate = function() {
  return this.setCreateEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateEntityTemplate = function() {
  return jspb.Message.getField(this, 264) != null;
};


/**
 * optional EntityTemplate entity_template_created = 265;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 265));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 265, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateCreated = function() {
  return this.setEntityTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateCreated = function() {
  return jspb.Message.getField(this, 265) != null;
};


/**
 * optional EntityTemplate change_entity_template = 266;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 266));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 266, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeEntityTemplate = function() {
  return this.setChangeEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeEntityTemplate = function() {
  return jspb.Message.getField(this, 266) != null;
};


/**
 * optional EntityTemplate entity_template_changed = 267;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateChanged = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 267));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateChanged = function(value) {
  return jspb.Message.setWrapperField(this, 267, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateChanged = function() {
  return this.setEntityTemplateChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateChanged = function() {
  return jspb.Message.getField(this, 267) != null;
};


/**
 * optional EntityTemplate delete_entity_template = 268;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 268));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 268, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteEntityTemplate = function() {
  return this.setDeleteEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteEntityTemplate = function() {
  return jspb.Message.getField(this, 268) != null;
};


/**
 * optional EntityTemplate entity_template_deleted = 269;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplate, 269));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 269, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateDeleted = function() {
  return this.setEntityTemplateDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateDeleted = function() {
  return jspb.Message.getField(this, 269) != null;
};


/**
 * optional RenderedEntityTemplate create_rendered_entity_template = 270;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateRenderedEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 270));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateRenderedEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 270, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateRenderedEntityTemplate = function() {
  return this.setCreateRenderedEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateRenderedEntityTemplate = function() {
  return jspb.Message.getField(this, 270) != null;
};


/**
 * optional RenderedEntityTemplate rendered_entity_template_created = 271;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedEntityTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 271));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedEntityTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 271, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedEntityTemplateCreated = function() {
  return this.setRenderedEntityTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedEntityTemplateCreated = function() {
  return jspb.Message.getField(this, 271) != null;
};


/**
 * optional RenderedEntityTemplate change_rendered_entity_template = 272;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeRenderedEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 272));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeRenderedEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 272, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeRenderedEntityTemplate = function() {
  return this.setChangeRenderedEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeRenderedEntityTemplate = function() {
  return jspb.Message.getField(this, 272) != null;
};


/**
 * optional RenderedEntityTemplate rendered_entity_template_changed = 273;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedEntityTemplateChanged = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 273));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedEntityTemplateChanged = function(value) {
  return jspb.Message.setWrapperField(this, 273, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedEntityTemplateChanged = function() {
  return this.setRenderedEntityTemplateChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedEntityTemplateChanged = function() {
  return jspb.Message.getField(this, 273) != null;
};


/**
 * optional RenderedEntityTemplate delete_rendered_entity_template = 274;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteRenderedEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 274));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteRenderedEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 274, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteRenderedEntityTemplate = function() {
  return this.setDeleteRenderedEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteRenderedEntityTemplate = function() {
  return jspb.Message.getField(this, 274) != null;
};


/**
 * optional RenderedEntityTemplate rendered_entity_template_deleted = 275;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedEntityTemplateDeleted = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 275));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedEntityTemplateDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 275, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedEntityTemplateDeleted = function() {
  return this.setRenderedEntityTemplateDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedEntityTemplateDeleted = function() {
  return jspb.Message.getField(this, 275) != null;
};


/**
 * optional RenderedEntityTemplate render_entity_template = 276;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 276));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 276, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderEntityTemplate = function() {
  return this.setRenderEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderEntityTemplate = function() {
  return jspb.Message.getField(this, 276) != null;
};


/**
 * optional RenderedEntityTemplate entity_template_rendered = 277;
 * @return {?proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateRendered = function() {
  return /** @type{?proto.vivacity.config.RenderedEntityTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.RenderedEntityTemplate, 277));
};


/**
 * @param {?proto.vivacity.config.RenderedEntityTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateRendered = function(value) {
  return jspb.Message.setWrapperField(this, 277, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateRendered = function() {
  return this.setEntityTemplateRendered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateRendered = function() {
  return jspb.Message.getField(this, 277) != null;
};


/**
 * optional DynniqMessageClassGrouping create_dynniq_message_class_grouping = 278;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateDynniqMessageClassGrouping = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 278));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateDynniqMessageClassGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 278, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateDynniqMessageClassGrouping = function() {
  return this.setCreateDynniqMessageClassGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateDynniqMessageClassGrouping = function() {
  return jspb.Message.getField(this, 278) != null;
};


/**
 * optional DynniqMessageClassGrouping dynniq_message_class_grouping_created = 279;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageClassGroupingCreated = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 279));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageClassGroupingCreated = function(value) {
  return jspb.Message.setWrapperField(this, 279, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageClassGroupingCreated = function() {
  return this.setDynniqMessageClassGroupingCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageClassGroupingCreated = function() {
  return jspb.Message.getField(this, 279) != null;
};


/**
 * optional DynniqMessageClassGrouping change_dynniq_message_class_grouping = 280;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeDynniqMessageClassGrouping = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 280));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeDynniqMessageClassGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 280, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeDynniqMessageClassGrouping = function() {
  return this.setChangeDynniqMessageClassGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeDynniqMessageClassGrouping = function() {
  return jspb.Message.getField(this, 280) != null;
};


/**
 * optional DynniqMessageClassGrouping dynniq_message_class_grouping_changed = 281;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageClassGroupingChanged = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 281));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageClassGroupingChanged = function(value) {
  return jspb.Message.setWrapperField(this, 281, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageClassGroupingChanged = function() {
  return this.setDynniqMessageClassGroupingChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageClassGroupingChanged = function() {
  return jspb.Message.getField(this, 281) != null;
};


/**
 * optional DynniqMessageClassGrouping delete_dynniq_message_class_grouping = 282;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteDynniqMessageClassGrouping = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 282));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteDynniqMessageClassGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 282, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteDynniqMessageClassGrouping = function() {
  return this.setDeleteDynniqMessageClassGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteDynniqMessageClassGrouping = function() {
  return jspb.Message.getField(this, 282) != null;
};


/**
 * optional DynniqMessageClassGrouping dynniq_message_class_grouping_deleted = 283;
 * @return {?proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageClassGroupingDeleted = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageClassGrouping} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageClassGrouping, 283));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageClassGrouping|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageClassGroupingDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 283, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageClassGroupingDeleted = function() {
  return this.setDynniqMessageClassGroupingDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageClassGroupingDeleted = function() {
  return jspb.Message.getField(this, 283) != null;
};


/**
 * optional DynniqMessageZone create_dynniq_message_zone = 284;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateDynniqMessageZone = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 284));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateDynniqMessageZone = function(value) {
  return jspb.Message.setWrapperField(this, 284, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateDynniqMessageZone = function() {
  return this.setCreateDynniqMessageZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateDynniqMessageZone = function() {
  return jspb.Message.getField(this, 284) != null;
};


/**
 * optional DynniqMessageZone dynniq_message_zone_created = 285;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageZoneCreated = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 285));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageZoneCreated = function(value) {
  return jspb.Message.setWrapperField(this, 285, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageZoneCreated = function() {
  return this.setDynniqMessageZoneCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageZoneCreated = function() {
  return jspb.Message.getField(this, 285) != null;
};


/**
 * optional DynniqMessageZone change_dynniq_message_zone = 286;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeDynniqMessageZone = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 286));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeDynniqMessageZone = function(value) {
  return jspb.Message.setWrapperField(this, 286, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeDynniqMessageZone = function() {
  return this.setChangeDynniqMessageZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeDynniqMessageZone = function() {
  return jspb.Message.getField(this, 286) != null;
};


/**
 * optional DynniqMessageZone dynniq_message_zone_changed = 287;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageZoneChanged = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 287));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageZoneChanged = function(value) {
  return jspb.Message.setWrapperField(this, 287, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageZoneChanged = function() {
  return this.setDynniqMessageZoneChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageZoneChanged = function() {
  return jspb.Message.getField(this, 287) != null;
};


/**
 * optional DynniqMessageZone delete_dynniq_message_zone = 288;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteDynniqMessageZone = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 288));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteDynniqMessageZone = function(value) {
  return jspb.Message.setWrapperField(this, 288, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteDynniqMessageZone = function() {
  return this.setDeleteDynniqMessageZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteDynniqMessageZone = function() {
  return jspb.Message.getField(this, 288) != null;
};


/**
 * optional DynniqMessageZone dynniq_message_zone_deleted = 289;
 * @return {?proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.ConfigMessage.prototype.getDynniqMessageZoneDeleted = function() {
  return /** @type{?proto.vivacity.config.DynniqMessageZone} */ (
    jspb.Message.getWrapperField(this, vivacity_config_dynniq_message_s4tc_pb.DynniqMessageZone, 289));
};


/**
 * @param {?proto.vivacity.config.DynniqMessageZone|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDynniqMessageZoneDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 289, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDynniqMessageZoneDeleted = function() {
  return this.setDynniqMessageZoneDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDynniqMessageZoneDeleted = function() {
  return jspb.Message.getField(this, 289) != null;
};


/**
 * optional ControllerHardwareModels create_controller_hardware_models = 290;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateControllerHardwareModels = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 290));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateControllerHardwareModels = function(value) {
  return jspb.Message.setWrapperField(this, 290, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateControllerHardwareModels = function() {
  return this.setCreateControllerHardwareModels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateControllerHardwareModels = function() {
  return jspb.Message.getField(this, 290) != null;
};


/**
 * optional ControllerHardwareModels controller_hardware_models_created = 291;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerHardwareModelsCreated = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 291));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerHardwareModelsCreated = function(value) {
  return jspb.Message.setWrapperField(this, 291, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerHardwareModelsCreated = function() {
  return this.setControllerHardwareModelsCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerHardwareModelsCreated = function() {
  return jspb.Message.getField(this, 291) != null;
};


/**
 * optional ControllerHardwareModels change_controller_hardware_models = 292;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeControllerHardwareModels = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 292));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeControllerHardwareModels = function(value) {
  return jspb.Message.setWrapperField(this, 292, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeControllerHardwareModels = function() {
  return this.setChangeControllerHardwareModels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeControllerHardwareModels = function() {
  return jspb.Message.getField(this, 292) != null;
};


/**
 * optional ControllerHardwareModels controller_hardware_models_changed = 293;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerHardwareModelsChanged = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 293));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerHardwareModelsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 293, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerHardwareModelsChanged = function() {
  return this.setControllerHardwareModelsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerHardwareModelsChanged = function() {
  return jspb.Message.getField(this, 293) != null;
};


/**
 * optional ControllerHardwareModels delete_controller_hardware_models = 294;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteControllerHardwareModels = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 294));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteControllerHardwareModels = function(value) {
  return jspb.Message.setWrapperField(this, 294, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteControllerHardwareModels = function() {
  return this.setDeleteControllerHardwareModels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteControllerHardwareModels = function() {
  return jspb.Message.getField(this, 294) != null;
};


/**
 * optional ControllerHardwareModels controller_hardware_models_deleted = 295;
 * @return {?proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerHardwareModelsDeleted = function() {
  return /** @type{?proto.vivacity.config.ControllerHardwareModels} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerHardwareModels, 295));
};


/**
 * @param {?proto.vivacity.config.ControllerHardwareModels|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerHardwareModelsDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 295, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerHardwareModelsDeleted = function() {
  return this.setControllerHardwareModelsDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerHardwareModelsDeleted = function() {
  return jspb.Message.getField(this, 295) != null;
};


/**
 * optional HardwareManufacturers create_hardware_manufacturers = 296;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateHardwareManufacturers = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 296));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateHardwareManufacturers = function(value) {
  return jspb.Message.setWrapperField(this, 296, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateHardwareManufacturers = function() {
  return this.setCreateHardwareManufacturers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateHardwareManufacturers = function() {
  return jspb.Message.getField(this, 296) != null;
};


/**
 * optional HardwareManufacturers hardware_manufacturers_created = 297;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareManufacturersCreated = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 297));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareManufacturersCreated = function(value) {
  return jspb.Message.setWrapperField(this, 297, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareManufacturersCreated = function() {
  return this.setHardwareManufacturersCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareManufacturersCreated = function() {
  return jspb.Message.getField(this, 297) != null;
};


/**
 * optional HardwareManufacturers change_hardware_manufacturers = 298;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwareManufacturers = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 298));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwareManufacturers = function(value) {
  return jspb.Message.setWrapperField(this, 298, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwareManufacturers = function() {
  return this.setChangeHardwareManufacturers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwareManufacturers = function() {
  return jspb.Message.getField(this, 298) != null;
};


/**
 * optional HardwareManufacturers hardware_manufacturers_changed = 299;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareManufacturersChanged = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 299));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareManufacturersChanged = function(value) {
  return jspb.Message.setWrapperField(this, 299, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareManufacturersChanged = function() {
  return this.setHardwareManufacturersChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareManufacturersChanged = function() {
  return jspb.Message.getField(this, 299) != null;
};


/**
 * optional HardwareManufacturers delete_hardware_manufacturers = 300;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteHardwareManufacturers = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 300));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteHardwareManufacturers = function(value) {
  return jspb.Message.setWrapperField(this, 300, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteHardwareManufacturers = function() {
  return this.setDeleteHardwareManufacturers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteHardwareManufacturers = function() {
  return jspb.Message.getField(this, 300) != null;
};


/**
 * optional HardwareManufacturers hardware_manufacturers_deleted = 301;
 * @return {?proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareManufacturersDeleted = function() {
  return /** @type{?proto.vivacity.config.HardwareManufacturers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareManufacturers, 301));
};


/**
 * @param {?proto.vivacity.config.HardwareManufacturers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareManufacturersDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 301, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareManufacturersDeleted = function() {
  return this.setHardwareManufacturersDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareManufacturersDeleted = function() {
  return jspb.Message.getField(this, 301) != null;
};


/**
 * optional CountlineWindowedClassConfig create_countline_windowed_class_config = 302;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCountlineWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 302));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCountlineWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 302, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCountlineWindowedClassConfig = function() {
  return this.setCreateCountlineWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCountlineWindowedClassConfig = function() {
  return jspb.Message.getField(this, 302) != null;
};


/**
 * optional CountlineWindowedClassConfig countline_windowed_class_config_created = 303;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineWindowedClassConfigCreated = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 303));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineWindowedClassConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 303, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineWindowedClassConfigCreated = function() {
  return this.setCountlineWindowedClassConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineWindowedClassConfigCreated = function() {
  return jspb.Message.getField(this, 303) != null;
};


/**
 * optional CountlineWindowedClassConfig change_countline_windowed_class_config = 304;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountlineWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 304));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountlineWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 304, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountlineWindowedClassConfig = function() {
  return this.setChangeCountlineWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountlineWindowedClassConfig = function() {
  return jspb.Message.getField(this, 304) != null;
};


/**
 * optional CountlineWindowedClassConfig countline_windowed_class_config_changed = 305;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineWindowedClassConfigChanged = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 305));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineWindowedClassConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 305, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineWindowedClassConfigChanged = function() {
  return this.setCountlineWindowedClassConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineWindowedClassConfigChanged = function() {
  return jspb.Message.getField(this, 305) != null;
};


/**
 * optional CountlineWindowedClassConfig delete_countline_windowed_class_config = 306;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCountlineWindowedClassConfig = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 306));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCountlineWindowedClassConfig = function(value) {
  return jspb.Message.setWrapperField(this, 306, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCountlineWindowedClassConfig = function() {
  return this.setDeleteCountlineWindowedClassConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCountlineWindowedClassConfig = function() {
  return jspb.Message.getField(this, 306) != null;
};


/**
 * optional CountlineWindowedClassConfig countline_windowed_class_config_deleted = 307;
 * @return {?proto.vivacity.config.CountlineWindowedClassConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineWindowedClassConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.CountlineWindowedClassConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineWindowedClassConfig, 307));
};


/**
 * @param {?proto.vivacity.config.CountlineWindowedClassConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineWindowedClassConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 307, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineWindowedClassConfigDeleted = function() {
  return this.setCountlineWindowedClassConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineWindowedClassConfigDeleted = function() {
  return jspb.Message.getField(this, 307) != null;
};


/**
 * optional EntityTemplateTypeToEntity create_entity_template_type_to_entity = 308;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateEntityTemplateTypeToEntity = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 308));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateEntityTemplateTypeToEntity = function(value) {
  return jspb.Message.setWrapperField(this, 308, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateEntityTemplateTypeToEntity = function() {
  return this.setCreateEntityTemplateTypeToEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateEntityTemplateTypeToEntity = function() {
  return jspb.Message.getField(this, 308) != null;
};


/**
 * optional EntityTemplateTypeToEntity entity_template_type_to_entity_created = 309;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeToEntityCreated = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 309));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeToEntityCreated = function(value) {
  return jspb.Message.setWrapperField(this, 309, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeToEntityCreated = function() {
  return this.setEntityTemplateTypeToEntityCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeToEntityCreated = function() {
  return jspb.Message.getField(this, 309) != null;
};


/**
 * optional EntityTemplateTypeToEntity change_entity_template_type_to_entity = 310;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeEntityTemplateTypeToEntity = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 310));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeEntityTemplateTypeToEntity = function(value) {
  return jspb.Message.setWrapperField(this, 310, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeEntityTemplateTypeToEntity = function() {
  return this.setChangeEntityTemplateTypeToEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeEntityTemplateTypeToEntity = function() {
  return jspb.Message.getField(this, 310) != null;
};


/**
 * optional EntityTemplateTypeToEntity entity_template_type_to_entity_changed = 311;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeToEntityChanged = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 311));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeToEntityChanged = function(value) {
  return jspb.Message.setWrapperField(this, 311, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeToEntityChanged = function() {
  return this.setEntityTemplateTypeToEntityChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeToEntityChanged = function() {
  return jspb.Message.getField(this, 311) != null;
};


/**
 * optional EntityTemplateTypeToEntity delete_entity_template_type_to_entity = 312;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteEntityTemplateTypeToEntity = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 312));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteEntityTemplateTypeToEntity = function(value) {
  return jspb.Message.setWrapperField(this, 312, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteEntityTemplateTypeToEntity = function() {
  return this.setDeleteEntityTemplateTypeToEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteEntityTemplateTypeToEntity = function() {
  return jspb.Message.getField(this, 312) != null;
};


/**
 * optional EntityTemplateTypeToEntity entity_template_type_to_entity_deleted = 313;
 * @return {?proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.ConfigMessage.prototype.getEntityTemplateTypeToEntityDeleted = function() {
  return /** @type{?proto.vivacity.config.EntityTemplateTypeToEntity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_entity_templates_pb.EntityTemplateTypeToEntity, 313));
};


/**
 * @param {?proto.vivacity.config.EntityTemplateTypeToEntity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setEntityTemplateTypeToEntityDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 313, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearEntityTemplateTypeToEntityDeleted = function() {
  return this.setEntityTemplateTypeToEntityDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasEntityTemplateTypeToEntityDeleted = function() {
  return jspb.Message.getField(this, 313) != null;
};


/**
 * optional HardwarePosition create_hardware_position = 314;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateHardwarePosition = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 314));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateHardwarePosition = function(value) {
  return jspb.Message.setWrapperField(this, 314, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateHardwarePosition = function() {
  return this.setCreateHardwarePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateHardwarePosition = function() {
  return jspb.Message.getField(this, 314) != null;
};


/**
 * optional HardwarePosition hardware_position_created = 315;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionCreated = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 315));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionCreated = function(value) {
  return jspb.Message.setWrapperField(this, 315, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionCreated = function() {
  return this.setHardwarePositionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionCreated = function() {
  return jspb.Message.getField(this, 315) != null;
};


/**
 * optional HardwarePosition change_hardware_position = 316;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwarePosition = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 316));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwarePosition = function(value) {
  return jspb.Message.setWrapperField(this, 316, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwarePosition = function() {
  return this.setChangeHardwarePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwarePosition = function() {
  return jspb.Message.getField(this, 316) != null;
};


/**
 * optional HardwarePosition hardware_position_changed = 317;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionChanged = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 317));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionChanged = function(value) {
  return jspb.Message.setWrapperField(this, 317, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionChanged = function() {
  return this.setHardwarePositionChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionChanged = function() {
  return jspb.Message.getField(this, 317) != null;
};


/**
 * optional HardwarePosition delete_hardware_position = 318;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteHardwarePosition = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 318));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteHardwarePosition = function(value) {
  return jspb.Message.setWrapperField(this, 318, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteHardwarePosition = function() {
  return this.setDeleteHardwarePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteHardwarePosition = function() {
  return jspb.Message.getField(this, 318) != null;
};


/**
 * optional HardwarePosition hardware_position_deleted = 319;
 * @return {?proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionDeleted = function() {
  return /** @type{?proto.vivacity.config.HardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePosition, 319));
};


/**
 * @param {?proto.vivacity.config.HardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 319, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionDeleted = function() {
  return this.setHardwarePositionDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionDeleted = function() {
  return jspb.Message.getField(this, 319) != null;
};


/**
 * optional ContractedHardware create_contracted_hardware = 320;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateContractedHardware = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 320));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 320, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateContractedHardware = function() {
  return this.setCreateContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateContractedHardware = function() {
  return jspb.Message.getField(this, 320) != null;
};


/**
 * optional ContractedHardware contracted_hardware_created = 321;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractedHardwareCreated = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 321));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractedHardwareCreated = function(value) {
  return jspb.Message.setWrapperField(this, 321, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractedHardwareCreated = function() {
  return this.setContractedHardwareCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractedHardwareCreated = function() {
  return jspb.Message.getField(this, 321) != null;
};


/**
 * optional ContractedHardware change_contracted_hardware = 322;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeContractedHardware = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 322));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 322, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeContractedHardware = function() {
  return this.setChangeContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeContractedHardware = function() {
  return jspb.Message.getField(this, 322) != null;
};


/**
 * optional ContractedHardware contracted_hardware_changed = 323;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractedHardwareChanged = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 323));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractedHardwareChanged = function(value) {
  return jspb.Message.setWrapperField(this, 323, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractedHardwareChanged = function() {
  return this.setContractedHardwareChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractedHardwareChanged = function() {
  return jspb.Message.getField(this, 323) != null;
};


/**
 * optional ContractedHardware delete_contracted_hardware = 324;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteContractedHardware = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 324));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 324, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteContractedHardware = function() {
  return this.setDeleteContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteContractedHardware = function() {
  return jspb.Message.getField(this, 324) != null;
};


/**
 * optional ContractedHardware contracted_hardware_deleted = 325;
 * @return {?proto.vivacity.config.ContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractedHardwareDeleted = function() {
  return /** @type{?proto.vivacity.config.ContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractedHardware, 325));
};


/**
 * @param {?proto.vivacity.config.ContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractedHardwareDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 325, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractedHardwareDeleted = function() {
  return this.setContractedHardwareDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractedHardwareDeleted = function() {
  return jspb.Message.getField(this, 325) != null;
};


/**
 * optional Customers create_customer = 326;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCustomer = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 326));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 326, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCustomer = function() {
  return this.setCreateCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCustomer = function() {
  return jspb.Message.getField(this, 326) != null;
};


/**
 * optional Customers customer_created = 327;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getCustomerCreated = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 327));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCustomerCreated = function(value) {
  return jspb.Message.setWrapperField(this, 327, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCustomerCreated = function() {
  return this.setCustomerCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCustomerCreated = function() {
  return jspb.Message.getField(this, 327) != null;
};


/**
 * optional Customers change_customer = 328;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCustomer = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 328));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 328, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCustomer = function() {
  return this.setChangeCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCustomer = function() {
  return jspb.Message.getField(this, 328) != null;
};


/**
 * optional Customers customer_changed = 329;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getCustomerChanged = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 329));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCustomerChanged = function(value) {
  return jspb.Message.setWrapperField(this, 329, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCustomerChanged = function() {
  return this.setCustomerChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCustomerChanged = function() {
  return jspb.Message.getField(this, 329) != null;
};


/**
 * optional Customers delete_customer = 330;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCustomer = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 330));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 330, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCustomer = function() {
  return this.setDeleteCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCustomer = function() {
  return jspb.Message.getField(this, 330) != null;
};


/**
 * optional Customers customer_deleted = 331;
 * @return {?proto.vivacity.config.Customers}
 */
proto.vivacity.config.ConfigMessage.prototype.getCustomerDeleted = function() {
  return /** @type{?proto.vivacity.config.Customers} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Customers, 331));
};


/**
 * @param {?proto.vivacity.config.Customers|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCustomerDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 331, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCustomerDeleted = function() {
  return this.setCustomerDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCustomerDeleted = function() {
  return jspb.Message.getField(this, 331) != null;
};


/**
 * optional ContractsToContractedHardware link_contract_to_contracted_hardware = 332;
 * @return {?proto.vivacity.config.ContractsToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkContractToContractedHardware = function() {
  return /** @type{?proto.vivacity.config.ContractsToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractsToContractedHardware, 332));
};


/**
 * @param {?proto.vivacity.config.ContractsToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkContractToContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 332, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkContractToContractedHardware = function() {
  return this.setLinkContractToContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkContractToContractedHardware = function() {
  return jspb.Message.getField(this, 332) != null;
};


/**
 * optional ContractsToContractedHardware contract_to_contracted_hardware_linked = 333;
 * @return {?proto.vivacity.config.ContractsToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractToContractedHardwareLinked = function() {
  return /** @type{?proto.vivacity.config.ContractsToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractsToContractedHardware, 333));
};


/**
 * @param {?proto.vivacity.config.ContractsToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractToContractedHardwareLinked = function(value) {
  return jspb.Message.setWrapperField(this, 333, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractToContractedHardwareLinked = function() {
  return this.setContractToContractedHardwareLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractToContractedHardwareLinked = function() {
  return jspb.Message.getField(this, 333) != null;
};


/**
 * optional ContractsToContractedHardware unlink_contract_to_contracted_hardware = 334;
 * @return {?proto.vivacity.config.ContractsToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkContractToContractedHardware = function() {
  return /** @type{?proto.vivacity.config.ContractsToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractsToContractedHardware, 334));
};


/**
 * @param {?proto.vivacity.config.ContractsToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkContractToContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 334, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkContractToContractedHardware = function() {
  return this.setUnlinkContractToContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkContractToContractedHardware = function() {
  return jspb.Message.getField(this, 334) != null;
};


/**
 * optional ContractsToContractedHardware contract_to_contracted_hardware_unlinked = 335;
 * @return {?proto.vivacity.config.ContractsToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractToContractedHardwareUnlinked = function() {
  return /** @type{?proto.vivacity.config.ContractsToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.ContractsToContractedHardware, 335));
};


/**
 * @param {?proto.vivacity.config.ContractsToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractToContractedHardwareUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 335, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractToContractedHardwareUnlinked = function() {
  return this.setContractToContractedHardwareUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractToContractedHardwareUnlinked = function() {
  return jspb.Message.getField(this, 335) != null;
};


/**
 * optional HardwarePositionToHardware link_hardware_position_to_hardware = 336;
 * @return {?proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkHardwarePositionToHardware = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToHardware, 336));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkHardwarePositionToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 336, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkHardwarePositionToHardware = function() {
  return this.setLinkHardwarePositionToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkHardwarePositionToHardware = function() {
  return jspb.Message.getField(this, 336) != null;
};


/**
 * optional HardwarePositionToHardware hardware_position_to_hardware_linked = 337;
 * @return {?proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionToHardwareLinked = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToHardware, 337));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionToHardwareLinked = function(value) {
  return jspb.Message.setWrapperField(this, 337, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionToHardwareLinked = function() {
  return this.setHardwarePositionToHardwareLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionToHardwareLinked = function() {
  return jspb.Message.getField(this, 337) != null;
};


/**
 * optional HardwarePositionToHardware unlink_hardware_position_to_hardware = 338;
 * @return {?proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkHardwarePositionToHardware = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToHardware, 338));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkHardwarePositionToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 338, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkHardwarePositionToHardware = function() {
  return this.setUnlinkHardwarePositionToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkHardwarePositionToHardware = function() {
  return jspb.Message.getField(this, 338) != null;
};


/**
 * optional HardwarePositionToHardware hardware_position_to_hardware_unlinked = 339;
 * @return {?proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionToHardwareUnlinked = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToHardware, 339));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionToHardwareUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 339, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionToHardwareUnlinked = function() {
  return this.setHardwarePositionToHardwareUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionToHardwareUnlinked = function() {
  return jspb.Message.getField(this, 339) != null;
};


/**
 * optional HardwarePositionToContractedHardware link_hardware_position_to_contracted_hardware = 340;
 * @return {?proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkHardwarePositionToContractedHardware = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware, 340));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkHardwarePositionToContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 340, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkHardwarePositionToContractedHardware = function() {
  return this.setLinkHardwarePositionToContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkHardwarePositionToContractedHardware = function() {
  return jspb.Message.getField(this, 340) != null;
};


/**
 * optional HardwarePositionToContractedHardware hardware_position_to_contracted_hardware_linked = 341;
 * @return {?proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionToContractedHardwareLinked = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware, 341));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionToContractedHardwareLinked = function(value) {
  return jspb.Message.setWrapperField(this, 341, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionToContractedHardwareLinked = function() {
  return this.setHardwarePositionToContractedHardwareLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionToContractedHardwareLinked = function() {
  return jspb.Message.getField(this, 341) != null;
};


/**
 * optional HardwarePositionToContractedHardware unlink_hardware_position_to_contracted_hardware = 342;
 * @return {?proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkHardwarePositionToContractedHardware = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware, 342));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkHardwarePositionToContractedHardware = function(value) {
  return jspb.Message.setWrapperField(this, 342, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkHardwarePositionToContractedHardware = function() {
  return this.setUnlinkHardwarePositionToContractedHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkHardwarePositionToContractedHardware = function() {
  return jspb.Message.getField(this, 342) != null;
};


/**
 * optional HardwarePositionToContractedHardware hardware_position_to_contracted_hardware_unlinked = 343;
 * @return {?proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionToContractedHardwareUnlinked = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionToContractedHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionToContractedHardware, 343));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionToContractedHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionToContractedHardwareUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 343, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionToContractedHardwareUnlinked = function() {
  return this.setHardwarePositionToContractedHardwareUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionToContractedHardwareUnlinked = function() {
  return jspb.Message.getField(this, 343) != null;
};


/**
 * optional VisionProgramToHardwarePosition link_vision_program_to_hardware_position = 344;
 * @return {?proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkVisionProgramToHardwarePosition = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition, 344));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkVisionProgramToHardwarePosition = function(value) {
  return jspb.Message.setWrapperField(this, 344, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkVisionProgramToHardwarePosition = function() {
  return this.setLinkVisionProgramToHardwarePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkVisionProgramToHardwarePosition = function() {
  return jspb.Message.getField(this, 344) != null;
};


/**
 * optional VisionProgramToHardwarePosition vision_program_to_hardware_position_linked = 345;
 * @return {?proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwarePositionLinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition, 345));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwarePositionLinked = function(value) {
  return jspb.Message.setWrapperField(this, 345, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwarePositionLinked = function() {
  return this.setVisionProgramToHardwarePositionLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwarePositionLinked = function() {
  return jspb.Message.getField(this, 345) != null;
};


/**
 * optional VisionProgramToHardwarePosition unlink_vision_program_to_hardware_position = 346;
 * @return {?proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkVisionProgramToHardwarePosition = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition, 346));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkVisionProgramToHardwarePosition = function(value) {
  return jspb.Message.setWrapperField(this, 346, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkVisionProgramToHardwarePosition = function() {
  return this.setUnlinkVisionProgramToHardwarePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkVisionProgramToHardwarePosition = function() {
  return jspb.Message.getField(this, 346) != null;
};


/**
 * optional VisionProgramToHardwarePosition vision_program_to_hardware_position_unlinked = 347;
 * @return {?proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwarePositionUnlinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardwarePosition} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardwarePosition, 347));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardwarePosition|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwarePositionUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 347, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwarePositionUnlinked = function() {
  return this.setVisionProgramToHardwarePositionUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwarePositionUnlinked = function() {
  return jspb.Message.getField(this, 347) != null;
};


/**
 * optional Contracts create_contract = 348;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateContract = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 348));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateContract = function(value) {
  return jspb.Message.setWrapperField(this, 348, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateContract = function() {
  return this.setCreateContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateContract = function() {
  return jspb.Message.getField(this, 348) != null;
};


/**
 * optional Contracts contract_created = 349;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractCreated = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 349));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractCreated = function(value) {
  return jspb.Message.setWrapperField(this, 349, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractCreated = function() {
  return this.setContractCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractCreated = function() {
  return jspb.Message.getField(this, 349) != null;
};


/**
 * optional Contracts change_contract = 350;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeContract = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 350));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeContract = function(value) {
  return jspb.Message.setWrapperField(this, 350, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeContract = function() {
  return this.setChangeContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeContract = function() {
  return jspb.Message.getField(this, 350) != null;
};


/**
 * optional Contracts contract_changed = 351;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractChanged = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 351));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractChanged = function(value) {
  return jspb.Message.setWrapperField(this, 351, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractChanged = function() {
  return this.setContractChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractChanged = function() {
  return jspb.Message.getField(this, 351) != null;
};


/**
 * optional Contracts delete_contract = 352;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteContract = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 352));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteContract = function(value) {
  return jspb.Message.setWrapperField(this, 352, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteContract = function() {
  return this.setDeleteContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteContract = function() {
  return jspb.Message.getField(this, 352) != null;
};


/**
 * optional Contracts contract_deleted = 353;
 * @return {?proto.vivacity.config.Contracts}
 */
proto.vivacity.config.ConfigMessage.prototype.getContractDeleted = function() {
  return /** @type{?proto.vivacity.config.Contracts} */ (
    jspb.Message.getWrapperField(this, vivacity_config_contracted_hardware_pb.Contracts, 353));
};


/**
 * @param {?proto.vivacity.config.Contracts|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setContractDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 353, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearContractDeleted = function() {
  return this.setContractDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasContractDeleted = function() {
  return jspb.Message.getField(this, 353) != null;
};


/**
 * optional HardwarePositionDrawing create_hardware_position_drawing = 354;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateHardwarePositionDrawing = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 354));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateHardwarePositionDrawing = function(value) {
  return jspb.Message.setWrapperField(this, 354, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateHardwarePositionDrawing = function() {
  return this.setCreateHardwarePositionDrawing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateHardwarePositionDrawing = function() {
  return jspb.Message.getField(this, 354) != null;
};


/**
 * optional HardwarePositionDrawing hardware_position_drawing_created = 355;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionDrawingCreated = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 355));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionDrawingCreated = function(value) {
  return jspb.Message.setWrapperField(this, 355, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionDrawingCreated = function() {
  return this.setHardwarePositionDrawingCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionDrawingCreated = function() {
  return jspb.Message.getField(this, 355) != null;
};


/**
 * optional HardwarePositionDrawing change_hardware_position_drawing = 356;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwarePositionDrawing = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 356));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwarePositionDrawing = function(value) {
  return jspb.Message.setWrapperField(this, 356, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwarePositionDrawing = function() {
  return this.setChangeHardwarePositionDrawing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwarePositionDrawing = function() {
  return jspb.Message.getField(this, 356) != null;
};


/**
 * optional HardwarePositionDrawing hardware_position_drawing_changed = 357;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionDrawingChanged = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 357));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionDrawingChanged = function(value) {
  return jspb.Message.setWrapperField(this, 357, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionDrawingChanged = function() {
  return this.setHardwarePositionDrawingChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionDrawingChanged = function() {
  return jspb.Message.getField(this, 357) != null;
};


/**
 * optional HardwarePositionDrawing delete_hardware_position_drawing = 358;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteHardwarePositionDrawing = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 358));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteHardwarePositionDrawing = function(value) {
  return jspb.Message.setWrapperField(this, 358, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteHardwarePositionDrawing = function() {
  return this.setDeleteHardwarePositionDrawing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteHardwarePositionDrawing = function() {
  return jspb.Message.getField(this, 358) != null;
};


/**
 * optional HardwarePositionDrawing hardware_position_drawing_deleted = 359;
 * @return {?proto.vivacity.config.HardwarePositionDrawing}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionDrawingDeleted = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionDrawing} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_drawing_pb.HardwarePositionDrawing, 359));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionDrawing|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionDrawingDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 359, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionDrawingDeleted = function() {
  return this.setHardwarePositionDrawingDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionDrawingDeleted = function() {
  return jspb.Message.getField(this, 359) != null;
};


/**
 * optional SetControlControllerStream set_control_controller_stream = 360;
 * @return {?proto.vivacity.config.SetControlControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getSetControlControllerStream = function() {
  return /** @type{?proto.vivacity.config.SetControlControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.SetControlControllerStream, 360));
};


/**
 * @param {?proto.vivacity.config.SetControlControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setSetControlControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 360, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearSetControlControllerStream = function() {
  return this.setSetControlControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasSetControlControllerStream = function() {
  return jspb.Message.getField(this, 360) != null;
};


/**
 * optional SetControlControllerStream controller_stream_control_set = 361;
 * @return {?proto.vivacity.config.SetControlControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamControlSet = function() {
  return /** @type{?proto.vivacity.config.SetControlControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.SetControlControllerStream, 361));
};


/**
 * @param {?proto.vivacity.config.SetControlControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamControlSet = function(value) {
  return jspb.Message.setWrapperField(this, 361, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamControlSet = function() {
  return this.setControllerStreamControlSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamControlSet = function() {
  return jspb.Message.getField(this, 361) != null;
};


/**
 * optional ZoneRealWorldSpace create_zone_real_world_space = 362;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateZoneRealWorldSpace = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 362));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateZoneRealWorldSpace = function(value) {
  return jspb.Message.setWrapperField(this, 362, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateZoneRealWorldSpace = function() {
  return this.setCreateZoneRealWorldSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateZoneRealWorldSpace = function() {
  return jspb.Message.getField(this, 362) != null;
};


/**
 * optional ZoneRealWorldSpace zone_real_world_space_created = 363;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getZoneRealWorldSpaceCreated = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 363));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZoneRealWorldSpaceCreated = function(value) {
  return jspb.Message.setWrapperField(this, 363, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZoneRealWorldSpaceCreated = function() {
  return this.setZoneRealWorldSpaceCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZoneRealWorldSpaceCreated = function() {
  return jspb.Message.getField(this, 363) != null;
};


/**
 * optional ZoneRealWorldSpace change_zone_real_world_space = 364;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZoneRealWorldSpace = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 364));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZoneRealWorldSpace = function(value) {
  return jspb.Message.setWrapperField(this, 364, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZoneRealWorldSpace = function() {
  return this.setChangeZoneRealWorldSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZoneRealWorldSpace = function() {
  return jspb.Message.getField(this, 364) != null;
};


/**
 * optional ZoneRealWorldSpace zone_real_world_space_changed = 365;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getZoneRealWorldSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 365));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZoneRealWorldSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 365, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZoneRealWorldSpaceChanged = function() {
  return this.setZoneRealWorldSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZoneRealWorldSpaceChanged = function() {
  return jspb.Message.getField(this, 365) != null;
};


/**
 * optional ZoneRealWorldSpace delete_zone_real_world_space = 366;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteZoneRealWorldSpace = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 366));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteZoneRealWorldSpace = function(value) {
  return jspb.Message.setWrapperField(this, 366, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteZoneRealWorldSpace = function() {
  return this.setDeleteZoneRealWorldSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteZoneRealWorldSpace = function() {
  return jspb.Message.getField(this, 366) != null;
};


/**
 * optional ZoneRealWorldSpace zone_real_world_space_deleted = 367;
 * @return {?proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ConfigMessage.prototype.getZoneRealWorldSpaceDeleted = function() {
  return /** @type{?proto.vivacity.config.ZoneRealWorldSpace} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZoneRealWorldSpace, 367));
};


/**
 * @param {?proto.vivacity.config.ZoneRealWorldSpace|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZoneRealWorldSpaceDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 367, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZoneRealWorldSpaceDeleted = function() {
  return this.setZoneRealWorldSpaceDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZoneRealWorldSpaceDeleted = function() {
  return jspb.Message.getField(this, 367) != null;
};


/**
 * optional HybridTransactionalMessage hybrid_message_request = 368;
 * @return {?proto.vivacity.config.HybridTransactionalMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getHybridMessageRequest = function() {
  return /** @type{?proto.vivacity.config.HybridTransactionalMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.HybridTransactionalMessage, 368));
};


/**
 * @param {?proto.vivacity.config.HybridTransactionalMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHybridMessageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 368, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHybridMessageRequest = function() {
  return this.setHybridMessageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHybridMessageRequest = function() {
  return jspb.Message.getField(this, 368) != null;
};


/**
 * optional HybridTransactionalMessage hybrid_message_response = 369;
 * @return {?proto.vivacity.config.HybridTransactionalMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getHybridMessageResponse = function() {
  return /** @type{?proto.vivacity.config.HybridTransactionalMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.HybridTransactionalMessage, 369));
};


/**
 * @param {?proto.vivacity.config.HybridTransactionalMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHybridMessageResponse = function(value) {
  return jspb.Message.setWrapperField(this, 369, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHybridMessageResponse = function() {
  return this.setHybridMessageResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHybridMessageResponse = function() {
  return jspb.Message.getField(this, 369) != null;
};


/**
 * optional HardwarePositionStatusOnly change_hardware_position_status = 370;
 * @return {?proto.vivacity.config.HardwarePositionStatusOnly}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwarePositionStatus = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionStatusOnly} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionStatusOnly, 370));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionStatusOnly|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwarePositionStatus = function(value) {
  return jspb.Message.setWrapperField(this, 370, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwarePositionStatus = function() {
  return this.setChangeHardwarePositionStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwarePositionStatus = function() {
  return jspb.Message.getField(this, 370) != null;
};


/**
 * optional HardwarePositionStatusOnly hardware_position_status_changed = 371;
 * @return {?proto.vivacity.config.HardwarePositionStatusOnly}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwarePositionStatusChanged = function() {
  return /** @type{?proto.vivacity.config.HardwarePositionStatusOnly} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_position_pb.HardwarePositionStatusOnly, 371));
};


/**
 * @param {?proto.vivacity.config.HardwarePositionStatusOnly|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwarePositionStatusChanged = function(value) {
  return jspb.Message.setWrapperField(this, 371, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwarePositionStatusChanged = function() {
  return this.setHardwarePositionStatusChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwarePositionStatusChanged = function() {
  return jspb.Message.getField(this, 371) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupTemplateMessage.repeatedFields_ = [6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupTemplateMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.CommandGroupTemplateQuery.toObject, includeInstance),
    hardwareIdQuery: jspb.Message.getFieldWithDefault(msg, 7, ""),
    commandsRequestsList: jspb.Message.toObjectList(msg.getCommandsRequestsList(),
    proto.vivacity.config.CommandRequest.toObject, includeInstance),
    leftDelimiter: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rightDelimiter: jspb.Message.getFieldWithDefault(msg, 10, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 11, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hardwareAvailabilityTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupTemplateMessage;
  return proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 6:
      var value = new proto.vivacity.config.CommandGroupTemplateQuery;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareIdQuery(value);
      break;
    case 8:
      var value = new proto.vivacity.config.CommandRequest;
      reader.readMessage(value,proto.vivacity.config.CommandRequest.deserializeBinaryFromReader);
      msg.addCommandsRequests(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftDelimiter(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightDelimiter(value);
      break;
    case 11:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHardwareAvailabilityTimeoutMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdQuery();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCommandsRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vivacity.config.CommandRequest.serializeBinaryToWriter
    );
  }
  f = message.getLeftDelimiter();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRightDelimiter();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHardwareAvailabilityTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_type = 4;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CommandGroupTemplateQuery child_queries = 6;
 * @return {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandGroupTemplateQuery, 6));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
*/
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.config.CommandGroupTemplateQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};


/**
 * optional string hardware_id_query = 7;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getHardwareIdQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setHardwareIdQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated CommandRequest commands_requests = 8;
 * @return {!Array<!proto.vivacity.config.CommandRequest>}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getCommandsRequestsList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandRequest, 8));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandRequest>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
*/
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setCommandsRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vivacity.config.CommandRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.addCommandsRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vivacity.config.CommandRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.clearCommandsRequestsList = function() {
  return this.setCommandsRequestsList([]);
};


/**
 * optional string left_delimiter = 9;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getLeftDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setLeftDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string right_delimiter = 10;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getRightDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setRightDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional OriginatingSystem originating_system = 11;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional uint32 user_id = 12;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string correlation_id = 13;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint64 hardware_availability_timeout_microseconds = 14;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getHardwareAvailabilityTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setHardwareAvailabilityTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupTemplateQuery.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupTemplateQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.CommandGroupTemplateQuery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupTemplateQuery;
  return proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = new proto.vivacity.config.CommandGroupTemplateQuery;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CommandGroupTemplateQuery child_queries = 3;
 * @return {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandGroupTemplateQuery, 3));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
*/
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config.CommandGroupTemplateQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupRequest.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commandGroupTemplateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commandState: jspb.Message.getFieldWithDefault(msg, 3, 0),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    kafkaOffset: jspb.Message.getFieldWithDefault(msg, 9, 0),
    async: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    token: jspb.Message.getFieldWithDefault(msg, 11, ""),
    errorResponseType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    error: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hardwareAvailabilityTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    vaultSignature: jspb.Message.getFieldWithDefault(msg, 15, ""),
    customTemplateValuesMap: (f = msg.getCustomTemplateValuesMap()) ? f.toObject(includeInstance, undefined) : [],
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 17, 0),
    commandsRequestsList: jspb.Message.toObjectList(msg.getCommandsRequestsList(),
    proto.vivacity.config.CommandRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupRequest}
 */
proto.vivacity.config.CommandGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupRequest;
  return proto.vivacity.config.CommandGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupRequest}
 */
proto.vivacity.config.CommandGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandGroupTemplateId(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.config.CommandGroupRequest.CommandState} */ (reader.readEnum());
      msg.setCommandState(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKafkaOffset(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsync(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 12:
      var value = /** @type {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} */ (reader.readEnum());
      msg.setErrorResponseType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHardwareAvailabilityTimeoutMicroseconds(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVaultSignature(value);
      break;
    case 16:
      var value = msg.getCustomTemplateValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    case 20:
      var value = new proto.vivacity.config.CommandRequest;
      reader.readMessage(value,proto.vivacity.config.CommandRequest.deserializeBinaryFromReader);
      msg.addCommandsRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCommandGroupTemplateId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCommandState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getKafkaOffset();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getAsync();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getErrorResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHardwareAvailabilityTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getVaultSignature();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCustomTemplateValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getCommandsRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.vivacity.config.CommandRequest.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.CommandGroupRequest.CommandState = {
  UNKNOWN_COMMAND_STATE: 0,
  RENDER: 1,
  PENDING: 2,
  EXECUTE: 3,
  SUCCESS: 4,
  FAILED: 5,
  TIMED_OUT: 6,
  CANCEL: 7,
  CANCELLED: 8,
  TIMED_OUT_HWMAN: 9,
  ACK: 10,
  TIMED_OUT_OFFLINE: 11,
  TIMED_OUT_NO_RESPONSE: 12
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandGroupRequest.ErrorResponseType = {
  UNKNOWN_ERROR_RESPONSE: 0,
  NO_ERROR_RESPONSE: 1,
  COULD_NOT_MARSHAL_PROTOBUF: 2,
  VERIFICATION_AND_NEW_KEYS_AND_RELOGIN_FAILED: 3,
  VERIFICATION_AND_NEW_KEYS_FAILED: 4,
  NEW_KEYS_EMPTY: 5,
  VERIFICATION_FAILED: 6,
  CANCEL_ON_NON_RUNNING_COMMAND: 7
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 command_group_template_id = 2;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandGroupTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCommandGroupTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CommandState command_state = 3;
 * @return {!proto.vivacity.config.CommandGroupRequest.CommandState}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandState = function() {
  return /** @type {!proto.vivacity.config.CommandGroupRequest.CommandState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.config.CommandGroupRequest.CommandState} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCommandState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional OriginatingSystem originating_system = 4;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 user_id = 5;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string correlation_id = 6;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 entity_id = 7;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 hardware_id = 8;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 kafka_offset = 9;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getKafkaOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setKafkaOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool async = 10;
 * @return {boolean}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getAsync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setAsync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string token = 11;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ErrorResponseType error_response_type = 12;
 * @return {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getErrorResponseType = function() {
  return /** @type {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setErrorResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string error = 13;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint64 hardware_availability_timeout_microseconds = 14;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getHardwareAvailabilityTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setHardwareAvailabilityTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string vault_signature = 15;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getVaultSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setVaultSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * map<string, string> custom_template_values = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCustomTemplateValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.clearCustomTemplateValuesMap = function() {
  this.getCustomTemplateValuesMap().clear();
  return this;
};


/**
 * optional uint64 timestamp_microseconds = 17;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated CommandRequest commands_requests = 20;
 * @return {!Array<!proto.vivacity.config.CommandRequest>}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandsRequestsList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandRequest, 20));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandRequest>} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
*/
proto.vivacity.config.CommandGroupRequest.prototype.setCommandsRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.vivacity.config.CommandRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandGroupRequest.prototype.addCommandsRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.vivacity.config.CommandRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.clearCommandsRequestsList = function() {
  return this.setCommandsRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correlationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commandGroupRequestId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serialIdInCommandGroup: jspb.Message.getFieldWithDefault(msg, 4, 0),
    commandRequestExecutionSerialId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    commandType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    commandTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    retryOnFailure: jspb.Message.getFieldWithDefault(msg, 9, 0),
    continueGroupIfFailed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    startedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    id: jspb.Message.getFieldWithDefault(msg, 12, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    commandGroupTemplateId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    endedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 17, 0),
    managedFileTemplatedFileId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    managedFile: (f = msg.getManagedFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    dockerImageCommand: (f = msg.getDockerImageCommand()) && vivacity_command_docker_pb.DockerImageCommand.toObject(includeInstance, f),
    serviceCommand: (f = msg.getServiceCommand()) && vivacity_command_service_pb.ServiceCommand.toObject(includeInstance, f),
    unmanagedFileCommand: (f = msg.getUnmanagedFileCommand()) && vivacity_command_file_pb.UnmanagedFileCommand.toObject(includeInstance, f),
    shellCommand: (f = msg.getShellCommand()) && vivacity_command_shell_pb.ShellCommand.toObject(includeInstance, f),
    updateHwmanCommand: (f = msg.getUpdateHwmanCommand()) && vivacity_command_hwman_pb.UpdateHwmanCommand.toObject(includeInstance, f),
    managedFileCommand: (f = msg.getManagedFileCommand()) && vivacity_command_file_pb.ManagedFileCommand.toObject(includeInstance, f),
    atCommand: (f = msg.getAtCommand()) && vivacity_command_at_command_pb.AtCommand.toObject(includeInstance, f),
    commandExecutionState: jspb.Message.getFieldWithDefault(msg, 31, 0),
    errorResponseType: jspb.Message.getFieldWithDefault(msg, 32, 0),
    error: jspb.Message.getFieldWithDefault(msg, 33, ""),
    startDelay: jspb.Message.getFieldWithDefault(msg, 34, ""),
    skipRender: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandRequest;
  return proto.vivacity.config.CommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandGroupRequestId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialIdInCommandGroup(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandRequestExecutionSerialId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 7:
      var value = /** @type {!proto.vivacity.config.CommandRequest.CommandType} */ (reader.readEnum());
      msg.setCommandType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCommandTimeoutMicroseconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRetryOnFailure(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinueGroupIfFailed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartedAtMicroseconds(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandGroupTemplateId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndedAtMicroseconds(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagedFileTemplatedFileId(value);
      break;
    case 21:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setManagedFile(value);
      break;
    case 22:
      var value = new vivacity_command_docker_pb.DockerImageCommand;
      reader.readMessage(value,vivacity_command_docker_pb.DockerImageCommand.deserializeBinaryFromReader);
      msg.setDockerImageCommand(value);
      break;
    case 23:
      var value = new vivacity_command_service_pb.ServiceCommand;
      reader.readMessage(value,vivacity_command_service_pb.ServiceCommand.deserializeBinaryFromReader);
      msg.setServiceCommand(value);
      break;
    case 24:
      var value = new vivacity_command_file_pb.UnmanagedFileCommand;
      reader.readMessage(value,vivacity_command_file_pb.UnmanagedFileCommand.deserializeBinaryFromReader);
      msg.setUnmanagedFileCommand(value);
      break;
    case 25:
      var value = new vivacity_command_shell_pb.ShellCommand;
      reader.readMessage(value,vivacity_command_shell_pb.ShellCommand.deserializeBinaryFromReader);
      msg.setShellCommand(value);
      break;
    case 26:
      var value = new vivacity_command_hwman_pb.UpdateHwmanCommand;
      reader.readMessage(value,vivacity_command_hwman_pb.UpdateHwmanCommand.deserializeBinaryFromReader);
      msg.setUpdateHwmanCommand(value);
      break;
    case 27:
      var value = new vivacity_command_file_pb.ManagedFileCommand;
      reader.readMessage(value,vivacity_command_file_pb.ManagedFileCommand.deserializeBinaryFromReader);
      msg.setManagedFileCommand(value);
      break;
    case 28:
      var value = new vivacity_command_at_command_pb.AtCommand;
      reader.readMessage(value,vivacity_command_at_command_pb.AtCommand.deserializeBinaryFromReader);
      msg.setAtCommand(value);
      break;
    case 31:
      var value = /** @type {!proto.vivacity.config.CommandRequest.CommandState} */ (reader.readEnum());
      msg.setCommandExecutionState(value);
      break;
    case 32:
      var value = /** @type {!proto.vivacity.config.CommandRequest.ErrorResponseType} */ (reader.readEnum());
      msg.setErrorResponseType(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDelay(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkipRender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCommandGroupRequestId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSerialIdInCommandGroup();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCommandRequestExecutionSerialId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCommandType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCommandTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getRetryOnFailure();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getContinueGroupIfFailed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getStartedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getCommandGroupTemplateId();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getEndedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getManagedFileTemplatedFileId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getManagedFile();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getDockerImageCommand();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_command_docker_pb.DockerImageCommand.serializeBinaryToWriter
    );
  }
  f = message.getServiceCommand();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_command_service_pb.ServiceCommand.serializeBinaryToWriter
    );
  }
  f = message.getUnmanagedFileCommand();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_command_file_pb.UnmanagedFileCommand.serializeBinaryToWriter
    );
  }
  f = message.getShellCommand();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_command_shell_pb.ShellCommand.serializeBinaryToWriter
    );
  }
  f = message.getUpdateHwmanCommand();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_command_hwman_pb.UpdateHwmanCommand.serializeBinaryToWriter
    );
  }
  f = message.getManagedFileCommand();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_command_file_pb.ManagedFileCommand.serializeBinaryToWriter
    );
  }
  f = message.getAtCommand();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      vivacity_command_at_command_pb.AtCommand.serializeBinaryToWriter
    );
  }
  f = message.getCommandExecutionState();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getErrorResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getStartDelay();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getSkipRender();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.CommandType = {
  UNKNOWN_COMMAND_TYPE: 0,
  MANAGED_FILE: 10,
  DOCKER_IMAGE: 11,
  SERVICE: 12,
  UNMANAGED_FILE: 13,
  SHELL: 14,
  UPDATE_HWMAN: 15,
  AT_COMMAND: 16
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.CommandState = {
  UNKNOWN_COMMAND_STATE: 0,
  STARTED: 4,
  SUCCESS: 5,
  FAILED: 6,
  TIMED_OUT: 7,
  CANCEL: 8,
  CANCELLED: 9,
  FAILED_RETRYING: 10,
  TIMED_OUT_RETRYING: 11,
  INTERMEDIATE: 12,
  STARTED_WITH_DELAY: 13,
  TIMED_OUT_HWMAN: 14,
  TIMED_OUT_NO_RESPONSE: 15
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.ErrorResponseType = {
  UNKNOWN_ERROR_RESPONSE: 0,
  NO_ERROR_RESPONSE: 1,
  UNKNOWN_COMMAND_REQUEST_TYPE: 2,
  INVALID_START_DELAY: 3,
  NO_COMMAND_EXECUTION_STATE: 4
};

/**
 * optional string correlation_id = 1;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OriginatingSystem originating_system = 2;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandRequest.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 command_group_request_id = 3;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandGroupRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandGroupRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 serial_id_in_command_group = 4;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getSerialIdInCommandGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setSerialIdInCommandGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 command_request_execution_serial_id = 5;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandRequestExecutionSerialId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandRequestExecutionSerialId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 hardware_id = 6;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CommandType command_type = 7;
 * @return {!proto.vivacity.config.CommandRequest.CommandType}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandType = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.CommandType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.CommandType} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint64 command_timeout_microseconds = 8;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 retry_on_failure = 9;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getRetryOnFailure = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setRetryOnFailure = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool continue_group_if_failed = 10;
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.getContinueGroupIfFailed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setContinueGroupIfFailed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional uint64 started_at_microseconds = 11;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getStartedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setStartedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 id = 12;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 entity_id = 13;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 timestamp_microseconds = 14;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 user_id = 15;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 command_group_template_id = 16;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandGroupTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandGroupTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint64 ended_at_microseconds = 17;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getEndedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setEndedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string managed_file_templated_file_id = 20;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getManagedFileTemplatedFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setManagedFileTemplatedFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional RenderedFile managed_file = 21;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.CommandRequest.prototype.getManagedFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 21));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setManagedFile = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearManagedFile = function() {
  return this.setManagedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasManagedFile = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional vivacity.command.DockerImageCommand docker_image_command = 22;
 * @return {?proto.vivacity.command.DockerImageCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getDockerImageCommand = function() {
  return /** @type{?proto.vivacity.command.DockerImageCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_docker_pb.DockerImageCommand, 22));
};


/**
 * @param {?proto.vivacity.command.DockerImageCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setDockerImageCommand = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearDockerImageCommand = function() {
  return this.setDockerImageCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasDockerImageCommand = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional vivacity.command.ServiceCommand service_command = 23;
 * @return {?proto.vivacity.command.ServiceCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getServiceCommand = function() {
  return /** @type{?proto.vivacity.command.ServiceCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_service_pb.ServiceCommand, 23));
};


/**
 * @param {?proto.vivacity.command.ServiceCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setServiceCommand = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearServiceCommand = function() {
  return this.setServiceCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasServiceCommand = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional vivacity.command.UnmanagedFileCommand unmanaged_file_command = 24;
 * @return {?proto.vivacity.command.UnmanagedFileCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getUnmanagedFileCommand = function() {
  return /** @type{?proto.vivacity.command.UnmanagedFileCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_file_pb.UnmanagedFileCommand, 24));
};


/**
 * @param {?proto.vivacity.command.UnmanagedFileCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setUnmanagedFileCommand = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearUnmanagedFileCommand = function() {
  return this.setUnmanagedFileCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasUnmanagedFileCommand = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional vivacity.command.ShellCommand shell_command = 25;
 * @return {?proto.vivacity.command.ShellCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getShellCommand = function() {
  return /** @type{?proto.vivacity.command.ShellCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_shell_pb.ShellCommand, 25));
};


/**
 * @param {?proto.vivacity.command.ShellCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setShellCommand = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearShellCommand = function() {
  return this.setShellCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasShellCommand = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional vivacity.command.UpdateHwmanCommand update_hwman_command = 26;
 * @return {?proto.vivacity.command.UpdateHwmanCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getUpdateHwmanCommand = function() {
  return /** @type{?proto.vivacity.command.UpdateHwmanCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_hwman_pb.UpdateHwmanCommand, 26));
};


/**
 * @param {?proto.vivacity.command.UpdateHwmanCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setUpdateHwmanCommand = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearUpdateHwmanCommand = function() {
  return this.setUpdateHwmanCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasUpdateHwmanCommand = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional vivacity.command.ManagedFileCommand managed_file_command = 27;
 * @return {?proto.vivacity.command.ManagedFileCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getManagedFileCommand = function() {
  return /** @type{?proto.vivacity.command.ManagedFileCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_file_pb.ManagedFileCommand, 27));
};


/**
 * @param {?proto.vivacity.command.ManagedFileCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setManagedFileCommand = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearManagedFileCommand = function() {
  return this.setManagedFileCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasManagedFileCommand = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional vivacity.command.AtCommand at_command = 28;
 * @return {?proto.vivacity.command.AtCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getAtCommand = function() {
  return /** @type{?proto.vivacity.command.AtCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_at_command_pb.AtCommand, 28));
};


/**
 * @param {?proto.vivacity.command.AtCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setAtCommand = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearAtCommand = function() {
  return this.setAtCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasAtCommand = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional CommandState command_execution_state = 31;
 * @return {!proto.vivacity.config.CommandRequest.CommandState}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandExecutionState = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.CommandState} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.CommandState} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandExecutionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional ErrorResponseType error_response_type = 32;
 * @return {!proto.vivacity.config.CommandRequest.ErrorResponseType}
 */
proto.vivacity.config.CommandRequest.prototype.getErrorResponseType = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.ErrorResponseType} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.ErrorResponseType} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setErrorResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * optional string error = 33;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string start_delay = 34;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getStartDelay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setStartDelay = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string skip_render = 35;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getSkipRender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setSkipRender = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.HybridTransactionalMessage.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HybridTransactionalMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HybridTransactionalMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridTransactionalMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionMetadata: (f = msg.getTransactionMetadata()) && vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.TransactionMetadata.toObject(includeInstance, f),
    requestOperationsList: jspb.Message.toObjectList(msg.getRequestOperationsList(),
    proto.vivacity.config.HybridConfigOperation.toObject, includeInstance),
    operationResponsesList: jspb.Message.toObjectList(msg.getOperationResponsesList(),
    proto.vivacity.config.HybridConfigOperation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HybridTransactionalMessage}
 */
proto.vivacity.config.HybridTransactionalMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HybridTransactionalMessage;
  return proto.vivacity.config.HybridTransactionalMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HybridTransactionalMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HybridTransactionalMessage}
 */
proto.vivacity.config.HybridTransactionalMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.TransactionMetadata;
      reader.readMessage(value,vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.TransactionMetadata.deserializeBinaryFromReader);
      msg.setTransactionMetadata(value);
      break;
    case 2:
      var value = new proto.vivacity.config.HybridConfigOperation;
      reader.readMessage(value,proto.vivacity.config.HybridConfigOperation.deserializeBinaryFromReader);
      msg.addRequestOperations(value);
      break;
    case 3:
      var value = new proto.vivacity.config.HybridConfigOperation;
      reader.readMessage(value,proto.vivacity.config.HybridConfigOperation.deserializeBinaryFromReader);
      msg.addOperationResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HybridTransactionalMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HybridTransactionalMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridTransactionalMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.TransactionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getRequestOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.HybridConfigOperation.serializeBinaryToWriter
    );
  }
  f = message.getOperationResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config.HybridConfigOperation.serializeBinaryToWriter
    );
  }
};


/**
 * optional vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata transaction_metadata = 1;
 * @return {?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.getTransactionMetadata = function() {
  return /** @type{?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.TransactionMetadata, 1));
};


/**
 * @param {?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata|undefined} value
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
*/
proto.vivacity.config.HybridTransactionalMessage.prototype.setTransactionMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.clearTransactionMetadata = function() {
  return this.setTransactionMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.hasTransactionMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated HybridConfigOperation request_operations = 2;
 * @return {!Array<!proto.vivacity.config.HybridConfigOperation>}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.getRequestOperationsList = function() {
  return /** @type{!Array<!proto.vivacity.config.HybridConfigOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.HybridConfigOperation, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.HybridConfigOperation>} value
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
*/
proto.vivacity.config.HybridTransactionalMessage.prototype.setRequestOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.HybridConfigOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HybridConfigOperation}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.addRequestOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.HybridConfigOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.clearRequestOperationsList = function() {
  return this.setRequestOperationsList([]);
};


/**
 * repeated HybridConfigOperation operation_responses = 3;
 * @return {!Array<!proto.vivacity.config.HybridConfigOperation>}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.getOperationResponsesList = function() {
  return /** @type{!Array<!proto.vivacity.config.HybridConfigOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.HybridConfigOperation, 3));
};


/**
 * @param {!Array<!proto.vivacity.config.HybridConfigOperation>} value
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
*/
proto.vivacity.config.HybridTransactionalMessage.prototype.setOperationResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config.HybridConfigOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HybridConfigOperation}
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.addOperationResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config.HybridConfigOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HybridTransactionalMessage} returns this
 */
proto.vivacity.config.HybridTransactionalMessage.prototype.clearOperationResponsesList = function() {
  return this.setOperationResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HybridConfigOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HybridConfigOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HybridConfigOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridConfigOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    configOperationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    crudOperation: (f = msg.getCrudOperation()) && vivacity_config_v2_crud_operation_message_pb.CRUDOperation.toObject(includeInstance, f),
    legacyConfigMessageWithReference: (f = msg.getLegacyConfigMessageWithReference()) && proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HybridConfigOperation}
 */
proto.vivacity.config.HybridConfigOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HybridConfigOperation;
  return proto.vivacity.config.HybridConfigOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HybridConfigOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HybridConfigOperation}
 */
proto.vivacity.config.HybridConfigOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.HybridConfigOperation.ConfigOperationType} */ (reader.readEnum());
      msg.setConfigOperationType(value);
      break;
    case 2:
      var value = new vivacity_config_v2_crud_operation_message_pb.CRUDOperation;
      reader.readMessage(value,vivacity_config_v2_crud_operation_message_pb.CRUDOperation.deserializeBinaryFromReader);
      msg.setCrudOperation(value);
      break;
    case 3:
      var value = new proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference;
      reader.readMessage(value,proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.deserializeBinaryFromReader);
      msg.setLegacyConfigMessageWithReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HybridConfigOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HybridConfigOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HybridConfigOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridConfigOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCrudOperation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_crud_operation_message_pb.CRUDOperation.serializeBinaryToWriter
    );
  }
  f = message.getLegacyConfigMessageWithReference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.HybridConfigOperation.ConfigOperationType = {
  CRUD_OPERATION: 0,
  LEGACY_CONFIG_MESSAGE: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    configMessage: (f = msg.getConfigMessage()) && proto.vivacity.config.ConfigMessage.toObject(includeInstance, f),
    referenceField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    setReferenceFieldFromBatchIndexId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference;
  return proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.setConfigMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceField(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetReferenceFieldFromBatchIndexId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional ConfigMessage config_message = 1;
 * @return {?proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.getConfigMessage = function() {
  return /** @type{?proto.vivacity.config.ConfigMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.ConfigMessage, 1));
};


/**
 * @param {?proto.vivacity.config.ConfigMessage|undefined} value
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
*/
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.setConfigMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.clearConfigMessage = function() {
  return this.setConfigMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.hasConfigMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reference_field = 2;
 * @return {string}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.getReferenceField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.setReferenceField = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.clearReferenceField = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.hasReferenceField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 set_reference_field_from_batch_index_id = 3;
 * @return {number}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.getSetReferenceFieldFromBatchIndexId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.setSetReferenceFieldFromBatchIndexId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} returns this
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.clearSetReferenceFieldFromBatchIndexId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference.prototype.hasSetReferenceFieldFromBatchIndexId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ConfigOperationType config_operation_type = 1;
 * @return {!proto.vivacity.config.HybridConfigOperation.ConfigOperationType}
 */
proto.vivacity.config.HybridConfigOperation.prototype.getConfigOperationType = function() {
  return /** @type {!proto.vivacity.config.HybridConfigOperation.ConfigOperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.HybridConfigOperation.ConfigOperationType} value
 * @return {!proto.vivacity.config.HybridConfigOperation} returns this
 */
proto.vivacity.config.HybridConfigOperation.prototype.setConfigOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional vivacity.config_v2.CRUDOperation crud_operation = 2;
 * @return {?proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config.HybridConfigOperation.prototype.getCrudOperation = function() {
  return /** @type{?proto.vivacity.config_v2.CRUDOperation} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_crud_operation_message_pb.CRUDOperation, 2));
};


/**
 * @param {?proto.vivacity.config_v2.CRUDOperation|undefined} value
 * @return {!proto.vivacity.config.HybridConfigOperation} returns this
*/
proto.vivacity.config.HybridConfigOperation.prototype.setCrudOperation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HybridConfigOperation} returns this
 */
proto.vivacity.config.HybridConfigOperation.prototype.clearCrudOperation = function() {
  return this.setCrudOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridConfigOperation.prototype.hasCrudOperation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LegacyConfigMessageWithReference legacy_config_message_with_reference = 3;
 * @return {?proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference}
 */
proto.vivacity.config.HybridConfigOperation.prototype.getLegacyConfigMessageWithReference = function() {
  return /** @type{?proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference, 3));
};


/**
 * @param {?proto.vivacity.config.HybridConfigOperation.LegacyConfigMessageWithReference|undefined} value
 * @return {!proto.vivacity.config.HybridConfigOperation} returns this
*/
proto.vivacity.config.HybridConfigOperation.prototype.setLegacyConfigMessageWithReference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HybridConfigOperation} returns this
 */
proto.vivacity.config.HybridConfigOperation.prototype.clearLegacyConfigMessageWithReference = function() {
  return this.setLegacyConfigMessageWithReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HybridConfigOperation.prototype.hasLegacyConfigMessageWithReference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.vivacity.config.OriginatingSystem = {
  UNKNOWN_ORIGINATING_SYSTEM: 0,
  AUDACITY: 1,
  ZONE_IMAGE_SPACE_REDUCER: 2,
  VIVACITY_HARDWARE: 3,
  WORKSHOP_FLASHER: 4,
  INIT_HANDLER: 5,
  HARDWARE_ID_REDUCER: 6,
  POSTGRES_REDUCER: 7,
  COUNTLINE_IMAGE_SPACE_REDUCER: 8,
  VISION_PROGRAM_MODIFICATION_REDUCER: 9,
  PERSPECTIVE_TRANSFORM_REDUCER: 10,
  COUNTLINE_REAL_WORLD_SPACE_REDUCER: 11,
  BATCHED_CONFIG_MESSAGES_REDUCER: 12,
  COUNTLINE_LINK_REDUCER: 13,
  FILE_RETRIEVER_HANDLER: 14,
  CREATE_FILE_TEMPLATE_REDUCER: 15,
  CHANGE_FILE_TEMPLATE_REDUCER: 16,
  DELETE_FILE_TEMPLATE_REDUCER: 17,
  CREATE_RENDERED_FILE_ENTRY_REDUCER: 18,
  DELETE_RENDERED_FILE_REDUCER: 19,
  RENDER_FILE_REDUCER: 20,
  FILE_ON_HARDWARE_UPDATED_REDUCER: 21,
  CHANGE_HARDWARE_FIELDS_REDUCER: 22,
  HWMAN_FILE_RETRIEVER_CLIENT: 23,
  VAULT_REDUCER: 24,
  VISION_PROGRAM_AND_HARDWARE_LINK_REDUCER: 25,
  ZONAL_ALERTS_REDUCER: 26,
  DEVELOPER: 27,
  CRONJOB_HARDWARE_LINK_REDUCER: 28,
  CHANGE_CRONJOB_REDUCER: 29,
  BENCHMARK_RUN_REDUCER: 31,
  COMMAND_GROUP_TEMPLATE_REDUCER: 32,
  COMMAND_RENDERER: 33,
  HWMAN_COMMAND_MANAGEMENT: 34,
  COMMAND_HANDLER: 35,
  CREATE_AGENT_REDUCER: 36,
  CHANGE_AGENT_REDUCER: 37,
  DELETE_AGENT_REDUCER: 38,
  CREATE_AGENT_STREAM_REDUCER: 39,
  CHANGE_AGENT_STREAM_REDUCER: 40,
  DELETE_AGENT_STREAM_REDUCER: 41,
  CREATE_COUNTRY_REDUCER: 42,
  CHANGE_COUNTRY_REDUCER: 43,
  DELETE_COUNTRY_REDUCER: 44,
  CREATE_REGION_REDUCER: 45,
  CHANGE_REGION_REDUCER: 46,
  DELETE_REGION_REDUCER: 47,
  CREATE_CONTROLLER_REDUCER: 48,
  CHANGE_CONTROLLER_REDUCER: 49,
  DELETE_CONTROLLER_REDUCER: 50,
  CREATE_CONTROLLER_STREAM_REDUCER: 51,
  CHANGE_CONTROLLER_STREAM_REDUCER: 52,
  DELETE_CONTROLLER_STREAM_REDUCER: 53,
  CREATE_VELOCITY_REDUCER: 54,
  CHANGE_VELOCITY_REDUCER: 55,
  DELETE_VELOCITY_REDUCER: 56,
  CREATE_AGENT_CONFIG_REDUCER: 57,
  CHANGE_AGENT_CONFIG_REDUCER: 58,
  DELETE_AGENT_CONFIG_REDUCER: 59,
  CREATE_CONTROL_REPLY_BIT_REDUCER: 60,
  CHANGE_CONTROL_REPLY_BIT_REDUCER: 61,
  DELETE_CONTROL_REPLY_BIT_REDUCER: 62,
  CREATE_STAGE_REDUCER: 63,
  CHANGE_STAGE_REDUCER: 64,
  DELETE_STAGE_REDUCER: 65,
  CREATE_PHASE_REDUCER: 66,
  CHANGE_PHASE_REDUCER: 67,
  DELETE_PHASE_REDUCER: 68,
  CREATE_VIU_REDUCER: 69,
  CHANGE_VIU_REDUCER: 70,
  DELETE_VIU_REDUCER: 71,
  CREATE_DETOXICITY_REDUCER: 72,
  CHANGE_DETOXICITY_REDUCER: 73,
  DELETE_DETOXICITY_REDUCER: 74,
  CREATE_STAGE_TO_PHASE_REDUCER: 75,
  CHANGE_STAGE_TO_PHASE_REDUCER: 76,
  DELETE_STAGE_TO_PHASE_REDUCER: 77,
  CREATE_VISION_PROGRAM_TO_CONTROLLER_STREAM_REDUCER: 78,
  CHANGE_VISION_PROGRAM_TO_CONTROLLER_STREAM_REDUCER: 79,
  DELETE_VISION_PROGRAM_TO_CONTROLLER_STREAM_REDUCER: 80,
  CREATE_PHASE_TO_IMAGE_SPACE_ZONE_REDUCER: 81,
  CHANGE_PHASE_TO_IMAGE_SPACE_ZONE_REDUCER: 82,
  DELETE_PHASE_TO_IMAGE_SPACE_ZONE_REDUCER: 83,
  CREATE_PHASE_TO_IMAGE_SPACE_COUNTLINE_REDUCER: 84,
  CHANGE_PHASE_TO_IMAGE_SPACE_COUNTLINE_REDUCER: 85,
  DELETE_PHASE_TO_IMAGE_SPACE_COUNTLINE_REDUCER: 86,
  CREATE_AGENT_TYPE_REDUCER: 87,
  CHANGE_AGENT_TYPE_REDUCER: 88,
  DELETE_AGENT_TYPE_REDUCER: 89,
  CREATE_ENTITY_GROUP_REDUCER: 90,
  CHANGE_ENTITY_GROUP_REDUCER: 91,
  DELETE_ENTITY_GROUP_REDUCER: 92,
  ADD_ENTITY_GROUP_LINKAGE_REDUCER: 93,
  DELETE_ENTITY_GROUP_LINKAGE_REDUCER: 94,
  CENTRICITY: 95,
  CREATE_ZONAL_WINDOWED_CLASS_CONFIG_REDUCER: 96,
  CHANGE_ZONAL_WINDOWED_CLASS_CONFIG_REDUCER: 97,
  DELETE_ZONAL_WINDOWED_CLASS_CONFIG_REDUCER: 98,
  CREATE_CONTROLLER_STREAM_CONTROL_SCHEDULE_REDUCER: 99,
  CHANGE_CONTROLLER_STREAM_CONTROL_SCHEDULE_REDUCER: 100,
  DELETE_CONTROLLER_STREAM_CONTROL_SCHEDULE_REDUCER: 101,
  CREATE_STAGE_TRANSITION_REDUCER: 102,
  CHANGE_STAGE_TRANSITION_REDUCER: 103,
  DELETE_STAGE_TRANSITION_REDUCER: 104,
  CREATE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 105,
  CHANGE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 106,
  DELETE_VP_TO_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 107,
  CREATE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 108,
  CHANGE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 109,
  DELETE_VP_WINDOWED_FEATURES_WEBSOCKET_CONNECTION_CONFIG_REDUCER: 110,
  CREATE_CV_MODEL_REDUCER: 111,
  CHANGE_CV_MODEL_REDUCER: 112,
  DELETE_CV_MODEL_REDUCER: 113,
  CREATE_CV_MODEL_TYPE_REDUCER: 114,
  CHANGE_CV_MODEL_TYPE_REDUCER: 115,
  DELETE_CV_MODEL_TYPE_REDUCER: 116,
  CREATE_CV_MODEL_CLASS_REDUCER: 117,
  CHANGE_CV_MODEL_CLASS_REDUCER: 118,
  DELETE_CV_MODEL_CLASS_REDUCER: 119,
  CREATE_VISION_PROGRAM_PRESET_REDUCER: 120,
  CHANGE_VISION_PROGRAM_PRESET_REDUCER: 121,
  DELETE_VISION_PROGRAM_PRESET_REDUCER: 122,
  CREATE_ENTITY_TEMPLATE_TYPE_REDUCER: 123,
  CHANGE_ENTITY_TEMPLATE_TYPE_REDUCER: 124,
  DELETE_ENTITY_TEMPLATE_TYPE_REDUCER: 125,
  CREATE_ENTITY_TEMPLATE_REDUCER: 126,
  CHANGE_ENTITY_TEMPLATE_REDUCER: 127,
  DELETE_ENTITY_TEMPLATE_REDUCER: 128,
  CREATE_RENDERED_ENTITY_TEMPLATE_REDUCER: 129,
  CHANGE_RENDERED_ENTITY_TEMPLATE_REDUCER: 130,
  DELETE_RENDERED_ENTITY_TEMPLATE_REDUCER: 131,
  CREATE_DYNNIQ_MESSAGE_CLASS_GROUPING_REDUCER: 132,
  CHANGE_DYNNIQ_MESSAGE_CLASS_GROUPING_REDUCER: 133,
  DELETE_DYNNIQ_MESSAGE_CLASS_GROUPING_REDUCER: 134,
  CREATE_DYNNIQ_MESSAGE_ZONE_REDUCER: 135,
  CHANGE_DYNNIQ_MESSAGE_ZONE_REDUCER: 136,
  DELETE_DYNNIQ_MESSAGE_ZONE_REDUCER: 137,
  CREATE_CONTROLLER_HARDWARE_MODELS_REDUCER: 138,
  CHANGE_CONTROLLER_HARDWARE_MODELS_REDUCER: 139,
  DELETE_CONTROLLER_HARDWARE_MODELS_REDUCER: 140,
  CREATE_HARDWARE_MANUFACTURERS_REDUCER: 141,
  CHANGE_HARDWARE_MANUFACTURERS_REDUCER: 142,
  DELETE_HARDWARE_MANUFACTURERS_REDUCER: 143,
  CREATE_COUNTLINE_WINDOWED_CLASS_CONFIG_REDUCER: 144,
  CHANGE_COUNTLINE_WINDOWED_CLASS_CONFIG_REDUCER: 145,
  DELETE_COUNTLINE_WINDOWED_CLASS_CONFIG_REDUCER: 146,
  CREATE_ENTITY_TEMPLATE_TYPE_TO_ENTITY_REDUCER: 147,
  CHANGE_ENTITY_TEMPLATE_TYPE_TO_ENTITY_REDUCER: 148,
  DELETE_ENTITY_TEMPLATE_TYPE_TO_ENTITY_REDUCER: 149,
  CREATE_HARDWARE_POSITION_REDUCER: 150,
  CHANGE_HARDWARE_POSITION_REDUCER: 151,
  DELETE_HARDWARE_POSITION_REDUCER: 152,
  CREATE_CUSTOMER_REDUCER: 153,
  CHANGE_CUSTOMER_REDUCER: 154,
  DELETE_CUSTOMER_REDUCER: 155,
  CREATE_CONTRACT_REDUCER: 156,
  CHANGE_CONTRACT_REDUCER: 157,
  DELETE_CONTRACT_REDUCER: 158,
  CREATE_CONTRACTED_HARDWARE_REDUCER: 159,
  CHANGE_CONTRACTED_HARDWARE_REDUCER: 160,
  DELETE_CONTRACTED_HARDWARE_REDUCER: 161,
  HARDWARE_POSITION_TO_HARDWARE_LINKAGE_REDUCER: 162,
  HARDWARE_POSITION_TO_CONTRACTED_HARDWARE_LINKAGE_REDUCER: 163,
  VISION_PROGRAM_TO_HARDWARE_POSITION_LINKAGE_REDUCER: 164,
  CONTRACTS_TO_CONTRACTED_HARDWARE_LINKAGE_REDUCER: 165,
  CREATE_HARDWARE_POSITION_DRAWING_REDUCER: 166,
  CHANGE_HARDWARE_POSITION_DRAWING_REDUCER: 167,
  DELETE_HARDWARE_POSITION_DRAWING_REDUCER: 168,
  EARL_OF_WARNING: 169,
  SET_CONTROL_CONTROLLER_STREAM_REDUCER: 170,
  CREATE_ZONE_REAL_WORLD_SPACE_REDUCER: 171,
  CHANGE_ZONE_REAL_WORLD_SPACE_REDUCER: 172,
  DELETE_ZONE_REAL_WORLD_SPACE_REDUCER: 173,
  VIVA_PORTAL: 174,
  HYBRID_TRANSACTIONAL_MESSAGE_REDUCER: 175,
  CHANGE_HARDWARE_POSITION_STATUS_REDUCER: 176
};

goog.object.extend(exports, proto.vivacity.config);
