import React, { PropsWithChildren } from "react";
import * as PIXI from "pixi.js";
import { Sprite, Container } from "react-pixi-fiber/index";
import { getColourFromClass } from "../../../workers/colourMap";
import { CVRunCountlineCrossing } from "../../../interfaces";
import { observer } from "mobx-react";
import { StoresContext } from "../../../contexts/stores.context";

type CVCrossingsProps = {
  width: number;
  storesContext: StoresContext;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  alpha: number;
};

export const CVCrossings: React.FunctionComponent<PropsWithChildren<CVCrossingsProps>> = observer(props => {
  const { urlStore, cvRunStore, playerUIStore } = props.storesContext;

  if (urlStore.selectedComputerVisionRun === undefined || urlStore.selectedCountline === undefined) {
    return null;
  }

  const crossingsByFrame = cvRunStore.computerVisionRuns
    .get(urlStore.selectedComputerVisionRun)
    ?.countlineCrossings.get(urlStore.selectedCountline);
  const cvRunCrossings: CVRunCountlineCrossing[] = [];

  if (crossingsByFrame && playerUIStore.showCVRunCrossings) {
    crossingsByFrame.forEach(crossing => {
      cvRunCrossings.push(crossing);
    });
  }
  const totalFrames = playerUIStore.totalFrames;
  return (
    <Container>
      {cvRunCrossings.map(crossing => {
        return (
          <CVCrossing
            positionX={(props.width * crossing.frameNumber) / totalFrames}
            crossing={crossing}
            frameNumber={crossing.frameNumber}
            pointerDown={props.pointerDown}
            pointerUp={props.pointerUp}
            key={"cv-" + crossing.trackNumber.toString() + "-" + crossing.frameNumber.toString()}
            alpha={
              playerUIStore.playUntilNextCVCrossingClasses.has(crossing.trackClass) ? props.alpha : props.alpha * 0.2
            }
          />
        );
      })}
    </Container>
  );
});

type ZoomedCVCrossingsProps = {
  storesContext: StoresContext;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  alpha: number;
};

export const ZoomedCVCrossings: React.FunctionComponent<PropsWithChildren<ZoomedCVCrossingsProps>> = observer(props => {
  const { urlStore, cvRunStore, playerUIStore } = props.storesContext;

  const zoomedCVCrossings: CVRunCountlineCrossing[] = [];
  if (urlStore.selectedComputerVisionRun === undefined || urlStore.selectedCountline === undefined) {
    return null;
  }

  const crossingsByFrame = cvRunStore.computerVisionRuns
    .get(urlStore.selectedComputerVisionRun)
    ?.countlineCrossings?.get(urlStore.selectedCountline);

  if (crossingsByFrame && playerUIStore.showCVRunCrossings) {
    crossingsByFrame.forEach((crossing, frameNumber) => {
      const fraction = frameNumber / playerUIStore.totalFrames;
      // Only include crossings in the time range
      if (fraction >= playerUIStore.timelineLowerBound && fraction <= playerUIStore.timelineUpperBound) {
        zoomedCVCrossings.push(crossing);
      }
    });
  } else {
    return null;
  }
  return (
    <Container>
      {zoomedCVCrossings.map(crossing => {
        return (
          <ZoomedCVCrossing
            key={"zoomed-cv-" + crossing.trackNumber.toString() + "-" + crossing.frameNumber.toString()}
            frameNumber={crossing.frameNumber}
            crossing={crossing}
            pointerUp={props.pointerUp}
            pointerDown={props.pointerDown}
            totalFrames={playerUIStore.totalFrames}
            width={playerUIStore.timelineWidth}
            lowerBound={playerUIStore.timelineLowerBound}
            upperBound={playerUIStore.timelineUpperBound}
            alpha={
              playerUIStore.playUntilNextCVCrossingClasses.has(crossing.trackClass) ? props.alpha : props.alpha * 0.2
            }
          />
        );
      })}
    </Container>
  );
});

type CVCrossingProps = {
  frameNumber: number;
  positionX: number;
  crossing: CVRunCountlineCrossing;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  alpha: number;
};

const CVCrossing: React.FunctionComponent<PropsWithChildren<CVCrossingProps>> = (
  props: PropsWithChildren<CVCrossingProps>
) => {
  const pointerUp = (e: PIXI.InteractionEvent) => {
    if (props.pointerUp) {
      props.pointerUp(e, props.crossing);
    }
  };
  const pointerDown = (e: PIXI.InteractionEvent) => {
    props.pointerDown(e, props.crossing);
  };
  return (
    <>
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={getColourFromClass(props.crossing.trackClass)}
        x={props.positionX - 2.5}
        y={props.crossing.clockwise ? 0 : 15}
        width={8}
        height={5}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={getColourFromClass(props.crossing.trackClass)}
        x={props.positionX}
        y={0}
        width={3}
        height={20}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
    </>
  );
};

type ZoomedCVCrossingProps = {
  frameNumber: number;
  totalFrames: number;
  width: number;
  lowerBound: number;
  upperBound: number;
  crossing: CVRunCountlineCrossing;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) => void;
  alpha: number;
};

const ZoomedCVCrossing: React.FunctionComponent<PropsWithChildren<ZoomedCVCrossingProps>> = (
  props: PropsWithChildren<ZoomedCVCrossingProps>
) => {
  const fraction = props.frameNumber / props.totalFrames;
  const xPos = (props.width * (fraction - props.lowerBound)) / (props.upperBound - props.lowerBound);

  const pointerUp = (e: PIXI.InteractionEvent) => {
    if (props.pointerUp) {
      props.pointerUp(e, props.crossing);
    }
  };
  const pointerDown = (e: PIXI.InteractionEvent) => {
    props.pointerDown(e, props.crossing);
  };
  return (
    <>
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={getColourFromClass(props.crossing.trackClass)}
        x={xPos - 2.5}
        y={props.crossing.clockwise ? 40 : 45}
        width={8}
        height={5}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={getColourFromClass(props.crossing.trackClass)}
        x={xPos}
        y={40}
        width={3}
        height={10}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
    </>
  );
};
