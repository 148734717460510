import React, { FC, useCallback, useState } from "react";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { ComputerVisionRunTable } from "../components/tables/ComputerVisionRunTable";
import { FilterSearchBar } from "../controllers/FilterSearchBar";
import { useStores } from "../hooks/useStores.hook";

import { Alert, AlertTitle, FormControlLabel, Grid, Switch } from "@mui/material";
import { FreneticityButton } from "../components/styledElements";
import { PageHeader } from "../components/pageHeader/PageHeader";
import { useLocation } from "react-router-dom";
import { URL_QUERY_PARAMS } from "../stores/url.store";
import { NewCVRunModal } from "../components/modals/NewCVRunModal";

export const ComputerVisionRunsLayout: FC<{}> = observer(() => {
  const {
    entitiesStore,
    urlStore: { selectedVideo },
  } = useStores();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get(URL_QUERY_PARAMS.SEARCH);
  const [searchString, setSearchString] = useState<string>(search ?? "");
  const [displayHiddenCVRuns, setShowHiddenCVRuns] = useState(false);
  const [isNewCVRunModalOpen, setIsNewCVRunModalOpen] = useState(false);

  const debouncedSetFilter = debounce(setSearchString, 300);
  const onSearchStringChange = useCallback(
    (newSearchString: string): void => {
      debouncedSetFilter(newSearchString);
    },
    [debouncedSetFilter]
  );

  return (
    <>
      {entitiesStore.hydrationErrors !== "" ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {entitiesStore.hydrationErrors}
        </Alert>
      ) : null}
      <PageHeader breadcrumbsDepth={2} />
      <Grid container>
        <Grid item xs={8}>
          <FilterSearchBar
            filter={searchString}
            onFilterChange={onSearchStringChange}
            placeholderText={"Search"}
            defaultFilter=""
          />
        </Grid>
        <Grid container item xs={4} alignContent="center" justifyContent="flex-end" paddingRight={3}>
          <Grid item>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Display hidden CV Runs"
              value={displayHiddenCVRuns}
              onChange={() => setShowHiddenCVRuns(!displayHiddenCVRuns)}
            />
          </Grid>
          <Grid item>
            <FreneticityButton disabled={!selectedVideo} onClick={() => setIsNewCVRunModalOpen(true)}>
              New CV Run
            </FreneticityButton>
          </Grid>
        </Grid>
      </Grid>
      <ComputerVisionRunTable displayHiddenCVRuns={displayHiddenCVRuns} />
      <NewCVRunModal
        isOpen={isNewCVRunModalOpen}
        onClose={() => setIsNewCVRunModalOpen(false)}
        videoId={selectedVideo}
      />
    </>
  );
});
