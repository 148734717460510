import { action, computed, makeObservable, observable } from "mobx";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { ZoneID } from "../domain";
import { VPZone } from "../interfaces";
import _ from "lodash";

export class ZoneStore extends ChildStore {
  @observable
  zones: Map<ZoneID, VPZone> = new Map<ZoneID, VPZone>();

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action
  init() {}

  @action.bound
  setZone(id: ZoneID, zone: VPZone) {
    this.zones.set(id, zone);
  }
}
