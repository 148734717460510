import React, { FC, useEffect, useState } from "react";
import { useStores } from "../hooks/useStores.hook";

export const HydrationProvider: FC<{}> = ({ children }) => {
  const { entitiesStore } = useStores();

  useEffect(() => {
    entitiesStore.init();
  }, []);

  return <>{children}</>;
};
