/* eslint-disable */
// source: vivacity/config/controller_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_countline_crossing_pb = require('../../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
goog.exportSymbol('proto.vivacity.config.ControlReplyBit', null, global);
goog.exportSymbol('proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection', null, global);
goog.exportSymbol('proto.vivacity.config.Controller', null, global);
goog.exportSymbol('proto.vivacity.config.ControllerHardwareModels', null, global);
goog.exportSymbol('proto.vivacity.config.ControllerStream', null, global);
goog.exportSymbol('proto.vivacity.config.Phase', null, global);
goog.exportSymbol('proto.vivacity.config.Phase.AppearanceType', null, global);
goog.exportSymbol('proto.vivacity.config.Phase.TerminationType', null, global);
goog.exportSymbol('proto.vivacity.config.Phase.Type', null, global);
goog.exportSymbol('proto.vivacity.config.PhaseToImageSpaceCountline', null, global);
goog.exportSymbol('proto.vivacity.config.PhaseToImageSpaceCountline.JunctionCountlineTypes', null, global);
goog.exportSymbol('proto.vivacity.config.PhaseToImageSpaceZone', null, global);
goog.exportSymbol('proto.vivacity.config.PhaseToImageSpaceZone.JunctionZoneTypes', null, global);
goog.exportSymbol('proto.vivacity.config.SetControlControllerStream', null, global);
goog.exportSymbol('proto.vivacity.config.Stage', null, global);
goog.exportSymbol('proto.vivacity.config.StageToPhase', null, global);
goog.exportSymbol('proto.vivacity.config.StageTransition', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramToControllerStream', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ControllerHardwareModels = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ControllerHardwareModels, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ControllerHardwareModels.displayName = 'proto.vivacity.config.ControllerHardwareModels';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Controller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Controller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Controller.displayName = 'proto.vivacity.config.Controller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ControllerStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ControllerStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ControllerStream.displayName = 'proto.vivacity.config.ControllerStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Stage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Stage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Stage.displayName = 'proto.vivacity.config.Stage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Phase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Phase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Phase.displayName = 'proto.vivacity.config.Phase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ControlReplyBit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ControlReplyBit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ControlReplyBit.displayName = 'proto.vivacity.config.ControlReplyBit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.StageToPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.StageToPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.StageToPhase.displayName = 'proto.vivacity.config.StageToPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramToControllerStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramToControllerStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramToControllerStream.displayName = 'proto.vivacity.config.VisionProgramToControllerStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.PhaseToImageSpaceZone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.PhaseToImageSpaceZone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.PhaseToImageSpaceZone.displayName = 'proto.vivacity.config.PhaseToImageSpaceZone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.PhaseToImageSpaceCountline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.PhaseToImageSpaceCountline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.PhaseToImageSpaceCountline.displayName = 'proto.vivacity.config.PhaseToImageSpaceCountline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.StageTransition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.StageTransition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.StageTransition.displayName = 'proto.vivacity.config.StageTransition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.SetControlControllerStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.SetControlControllerStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.SetControlControllerStream.displayName = 'proto.vivacity.config.SetControlControllerStream';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ControllerHardwareModels.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ControllerHardwareModels.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ControllerHardwareModels} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerHardwareModels.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareManufacturerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ControllerHardwareModels.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ControllerHardwareModels;
  return proto.vivacity.config.ControllerHardwareModels.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ControllerHardwareModels} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ControllerHardwareModels}
 */
proto.vivacity.config.ControllerHardwareModels.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardwareManufacturerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ControllerHardwareModels.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ControllerHardwareModels.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ControllerHardwareModels} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerHardwareModels.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHardwareManufacturerId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ControllerHardwareModels.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerHardwareModels} returns this
 */
proto.vivacity.config.ControllerHardwareModels.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hardware_manufacturer_id = 2;
 * @return {number}
 */
proto.vivacity.config.ControllerHardwareModels.prototype.getHardwareManufacturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerHardwareModels} returns this
 */
proto.vivacity.config.ControllerHardwareModels.prototype.setHardwareManufacturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vivacity.config.ControllerHardwareModels.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControllerHardwareModels} returns this
 */
proto.vivacity.config.ControllerHardwareModels.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Controller.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Controller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Controller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Controller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controllerSiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    location: (f = msg.getLocation()) && vivacity_core_point_pb.PointF.toObject(includeInstance, f),
    viuId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isS4tc: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isSj: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 10, ""),
    controllerHardwareModelId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    issueNumber: jspb.Message.getFieldWithDefault(msg, 12, 0),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Controller}
 */
proto.vivacity.config.Controller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Controller;
  return proto.vivacity.config.Controller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Controller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Controller}
 */
proto.vivacity.config.Controller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerSiteId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegionId(value);
      break;
    case 6:
      var value = new vivacity_core_point_pb.PointF;
      reader.readMessage(value,vivacity_core_point_pb.PointF.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViuId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsS4tc(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSj(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerHardwareModelId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIssueNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Controller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Controller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Controller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Controller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControllerSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_point_pb.PointF.serializeBinaryToWriter
    );
  }
  f = message.getViuId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getIsS4tc();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsSj();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getControllerHardwareModelId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getIssueNumber();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.Controller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vivacity.config.Controller.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string controller_site_id = 4;
 * @return {string}
 */
proto.vivacity.config.Controller.prototype.getControllerSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setControllerSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 region_id = 5;
 * @return {number}
 */
proto.vivacity.config.Controller.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional vivacity.core.PointF location = 6;
 * @return {?proto.vivacity.core.PointF}
 */
proto.vivacity.config.Controller.prototype.getLocation = function() {
  return /** @type{?proto.vivacity.core.PointF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.PointF, 6));
};


/**
 * @param {?proto.vivacity.core.PointF|undefined} value
 * @return {!proto.vivacity.config.Controller} returns this
*/
proto.vivacity.config.Controller.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.Controller.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 viu_id = 7;
 * @return {number}
 */
proto.vivacity.config.Controller.prototype.getViuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setViuId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_s4tc = 8;
 * @return {boolean}
 */
proto.vivacity.config.Controller.prototype.getIsS4tc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setIsS4tc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_sj = 9;
 * @return {boolean}
 */
proto.vivacity.config.Controller.prototype.getIsSj = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setIsSj = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string ip_address = 10;
 * @return {string}
 */
proto.vivacity.config.Controller.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 controller_hardware_model_id = 11;
 * @return {number}
 */
proto.vivacity.config.Controller.prototype.getControllerHardwareModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setControllerHardwareModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 issue_number = 12;
 * @return {number}
 */
proto.vivacity.config.Controller.prototype.getIssueNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setIssueNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string extra_values_json = 13;
 * @return {string}
 */
proto.vivacity.config.Controller.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Controller} returns this
 */
proto.vivacity.config.Controller.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ControllerStream.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ControllerStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ControllerStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    streamIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    controllerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && vivacity_core_point_pb.PointF.toObject(includeInstance, f),
    takeoverBitId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    controlBitId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    velocityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isForceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isScheduleEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isDryRunning: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isCommissioning: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    multipleSensorsCriticalThreshold: jspb.Message.getFieldWithDefault(msg, 13, 0),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ControllerStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ControllerStream;
  return proto.vivacity.config.ControllerStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ControllerStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ControllerStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStreamIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    case 4:
      var value = new vivacity_core_point_pb.PointF;
      reader.readMessage(value,vivacity_core_point_pb.PointF.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTakeoverBitId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlBitId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVelocityId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsForceEnabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsScheduleEnabled(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDryRunning(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCommissioning(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultipleSensorsCriticalThreshold(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ControllerStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ControllerStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ControllerStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStreamIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_point_pb.PointF.serializeBinaryToWriter
    );
  }
  f = message.getTakeoverBitId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getControlBitId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getVelocityId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsForceEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsScheduleEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsDryRunning();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsCommissioning();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getMultipleSensorsCriticalThreshold();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 stream_index = 2;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getStreamIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setStreamIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 controller_id = 3;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional vivacity.core.PointF location = 4;
 * @return {?proto.vivacity.core.PointF}
 */
proto.vivacity.config.ControllerStream.prototype.getLocation = function() {
  return /** @type{?proto.vivacity.core.PointF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.PointF, 4));
};


/**
 * @param {?proto.vivacity.core.PointF|undefined} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
*/
proto.vivacity.config.ControllerStream.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 takeover_bit_id = 5;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getTakeoverBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setTakeoverBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 control_bit_id = 6;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getControlBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setControlBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 velocity_id = 7;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getVelocityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setVelocityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_force_enabled = 9;
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.getIsForceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setIsForceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_schedule_enabled = 10;
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.getIsScheduleEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setIsScheduleEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_dry_running = 11;
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.getIsDryRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setIsDryRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_commissioning = 12;
 * @return {boolean}
 */
proto.vivacity.config.ControllerStream.prototype.getIsCommissioning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setIsCommissioning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 multiple_sensors_critical_threshold = 13;
 * @return {number}
 */
proto.vivacity.config.ControllerStream.prototype.getMultipleSensorsCriticalThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setMultipleSensorsCriticalThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string extra_values_json = 14;
 * @return {string}
 */
proto.vivacity.config.ControllerStream.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControllerStream} returns this
 */
proto.vivacity.config.ControllerStream.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Stage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Stage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Stage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Stage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    controllerStreamId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    commandBitId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    responseBitId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    demandBitId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    demandResponseBitId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    longStageThresholdSeconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unservedStageThresholdSeconds: jspb.Message.getFieldWithDefault(msg, 9, 0),
    longStageThresholdOffPeakSeconds: jspb.Message.getFieldWithDefault(msg, 10, 0),
    longStageThresholdOvernightSeconds: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Stage}
 */
proto.vivacity.config.Stage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Stage;
  return proto.vivacity.config.Stage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Stage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Stage}
 */
proto.vivacity.config.Stage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerStreamId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandBitId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResponseBitId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDemandBitId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDemandResponseBitId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLongStageThresholdSeconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedStageThresholdSeconds(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLongStageThresholdOffPeakSeconds(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLongStageThresholdOvernightSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Stage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Stage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Stage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Stage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStageNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getControllerStreamId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCommandBitId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getResponseBitId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDemandBitId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDemandResponseBitId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLongStageThresholdSeconds();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUnservedStageThresholdSeconds();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLongStageThresholdOffPeakSeconds();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLongStageThresholdOvernightSeconds();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 stage_number = 2;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getStageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setStageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 controller_stream_id = 3;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getControllerStreamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setControllerStreamId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 command_bit_id = 4;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getCommandBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setCommandBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 response_bit_id = 5;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getResponseBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setResponseBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 demand_bit_id = 6;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getDemandBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setDemandBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 demand_response_bit_id = 7;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getDemandResponseBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setDemandResponseBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 long_stage_threshold_seconds = 8;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getLongStageThresholdSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setLongStageThresholdSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 unserved_stage_threshold_seconds = 9;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getUnservedStageThresholdSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setUnservedStageThresholdSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 long_stage_threshold_off_peak_seconds = 10;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getLongStageThresholdOffPeakSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setLongStageThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 long_stage_threshold_overnight_seconds = 11;
 * @return {number}
 */
proto.vivacity.config.Stage.prototype.getLongStageThresholdOvernightSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Stage} returns this
 */
proto.vivacity.config.Stage.prototype.setLongStageThresholdOvernightSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Phase.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Phase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Phase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Phase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseLetter: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minGreenSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    demandResponseBitId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    controllerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    unservedMaximumPhaseThresholdPeakSeconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    unservedMaximumPhaseThresholdOffPeakSeconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unservedMaximumPhaseThresholdOvernightSeconds: jspb.Message.getFieldWithDefault(msg, 9, 0),
    unservedDemandedPhaseThresholdPeakSeconds: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unservedDemandedPhaseThresholdOffPeakSeconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    unservedDemandedPhaseThresholdOvernightSeconds: jspb.Message.getFieldWithDefault(msg, 12, 0),
    type: jspb.Message.getFieldWithDefault(msg, 13, 0),
    appearanceType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    terminationType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pedestrianDemandDelayMilliseconds: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pedestrianDemandHoldMilliseconds: jspb.Message.getFieldWithDefault(msg, 17, 0),
    pedestrianClearanceMaxMilliseconds: jspb.Message.getFieldWithDefault(msg, 18, 0),
    pedestrianClearanceDelayGapChangeMilliseconds: jspb.Message.getFieldWithDefault(msg, 19, 0),
    pedestrianClearanceDelayMaxGapChangeMilliseconds: jspb.Message.getFieldWithDefault(msg, 20, 0),
    pedestrianClearanceMinRedMilliseconds: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Phase}
 */
proto.vivacity.config.Phase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Phase;
  return proto.vivacity.config.Phase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Phase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Phase}
 */
proto.vivacity.config.Phase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseLetter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinGreenSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDemandResponseBitId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedMaximumPhaseThresholdPeakSeconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedMaximumPhaseThresholdOffPeakSeconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedMaximumPhaseThresholdOvernightSeconds(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedDemandedPhaseThresholdPeakSeconds(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedDemandedPhaseThresholdOffPeakSeconds(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnservedDemandedPhaseThresholdOvernightSeconds(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.config.Phase.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 14:
      var value = /** @type {!proto.vivacity.config.Phase.AppearanceType} */ (reader.readEnum());
      msg.setAppearanceType(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.config.Phase.TerminationType} */ (reader.readEnum());
      msg.setTerminationType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianDemandDelayMilliseconds(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianDemandHoldMilliseconds(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianClearanceMaxMilliseconds(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianClearanceDelayGapChangeMilliseconds(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianClearanceDelayMaxGapChangeMilliseconds(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPedestrianClearanceMinRedMilliseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Phase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Phase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Phase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Phase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPhaseLetter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinGreenSeconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDemandResponseBitId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getUnservedMaximumPhaseThresholdPeakSeconds();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUnservedMaximumPhaseThresholdOffPeakSeconds();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUnservedMaximumPhaseThresholdOvernightSeconds();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUnservedDemandedPhaseThresholdPeakSeconds();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getUnservedDemandedPhaseThresholdOffPeakSeconds();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getUnservedDemandedPhaseThresholdOvernightSeconds();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getAppearanceType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getTerminationType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getPedestrianDemandDelayMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPedestrianDemandHoldMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPedestrianClearanceMaxMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getPedestrianClearanceDelayGapChangeMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getPedestrianClearanceDelayMaxGapChangeMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getPedestrianClearanceMinRedMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.Phase.Type = {
  UNKNOWN: 0,
  TRAFFIC: 1,
  FILTER_ARROW: 2,
  INDICATIVE_ARROW: 3,
  DUMMY: 4,
  SWITCHED_SIGN: 5,
  PEDESTRIAN: 6,
  PUFFIN: 7,
  TOUCAN_NEAR_SIDE: 8,
  TOUCAN_FAR_SIDE: 9
};

/**
 * @enum {number}
 */
proto.vivacity.config.Phase.AppearanceType = {
  ALWAYS: 0,
  DEMANDED_AT_START_OF_STAGE: 1,
  DEMANDED_UNTIL_END_OF_STAGE: 2,
  DEMANDED_DURING_STAGE_UNTIL_WINDOW_TIME_EXPIRY: 3
};

/**
 * @enum {number}
 */
proto.vivacity.config.Phase.TerminationType = {
  AT_END_OF_STAGE: 0,
  ASSOCIATED_PHASE_GAINS_ROW: 1,
  ASSOCIATED_PHASE_LOSES_ROW: 2,
  AT_END_OF_MIN_GREEN: 4,
  AT_END_OF_MAX_GREEN: 5,
  SUBJECT_TO_SPECIAL_CONDITIONING: 6
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string phase_letter = 3;
 * @return {string}
 */
proto.vivacity.config.Phase.prototype.getPhaseLetter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPhaseLetter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_green_seconds = 4;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getMinGreenSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setMinGreenSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 demand_response_bit_id = 5;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getDemandResponseBitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setDemandResponseBitId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 controller_id = 6;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 unserved_maximum_phase_threshold_peak_seconds = 7;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedMaximumPhaseThresholdPeakSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedMaximumPhaseThresholdPeakSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 unserved_maximum_phase_threshold_off_peak_seconds = 8;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedMaximumPhaseThresholdOffPeakSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedMaximumPhaseThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 unserved_maximum_phase_threshold_overnight_seconds = 9;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedMaximumPhaseThresholdOvernightSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedMaximumPhaseThresholdOvernightSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 unserved_demanded_phase_threshold_peak_seconds = 10;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedDemandedPhaseThresholdPeakSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedDemandedPhaseThresholdPeakSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 unserved_demanded_phase_threshold_off_peak_seconds = 11;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedDemandedPhaseThresholdOffPeakSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedDemandedPhaseThresholdOffPeakSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 unserved_demanded_phase_threshold_overnight_seconds = 12;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getUnservedDemandedPhaseThresholdOvernightSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setUnservedDemandedPhaseThresholdOvernightSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Type type = 13;
 * @return {!proto.vivacity.config.Phase.Type}
 */
proto.vivacity.config.Phase.prototype.getType = function() {
  return /** @type {!proto.vivacity.config.Phase.Type} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.config.Phase.Type} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional AppearanceType appearance_type = 14;
 * @return {!proto.vivacity.config.Phase.AppearanceType}
 */
proto.vivacity.config.Phase.prototype.getAppearanceType = function() {
  return /** @type {!proto.vivacity.config.Phase.AppearanceType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vivacity.config.Phase.AppearanceType} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setAppearanceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional TerminationType termination_type = 15;
 * @return {!proto.vivacity.config.Phase.TerminationType}
 */
proto.vivacity.config.Phase.prototype.getTerminationType = function() {
  return /** @type {!proto.vivacity.config.Phase.TerminationType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.config.Phase.TerminationType} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setTerminationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 pedestrian_demand_delay_milliseconds = 16;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianDemandDelayMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianDemandDelayMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 pedestrian_demand_hold_milliseconds = 17;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianDemandHoldMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianDemandHoldMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 pedestrian_clearance_max_milliseconds = 18;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianClearanceMaxMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianClearanceMaxMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 pedestrian_clearance_delay_gap_change_milliseconds = 19;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianClearanceDelayGapChangeMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianClearanceDelayGapChangeMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 pedestrian_clearance_delay_max_gap_change_milliseconds = 20;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianClearanceDelayMaxGapChangeMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianClearanceDelayMaxGapChangeMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 pedestrian_clearance_min_red_milliseconds = 21;
 * @return {number}
 */
proto.vivacity.config.Phase.prototype.getPedestrianClearanceMinRedMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Phase} returns this
 */
proto.vivacity.config.Phase.prototype.setPedestrianClearanceMinRedMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ControlReplyBit.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ControlReplyBit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ControlReplyBit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControlReplyBit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bitName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    letters: jspb.Message.getFieldWithDefault(msg, 3, ""),
    numbers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    viuPinNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    controllerPinName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    controlReplyBitDirection: jspb.Message.getFieldWithDefault(msg, 7, 0),
    inverted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    special: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    controllerId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ControlReplyBit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ControlReplyBit;
  return proto.vivacity.config.ControlReplyBit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ControlReplyBit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ControlReplyBit}
 */
proto.vivacity.config.ControlReplyBit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLetters(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumbers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViuPinNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerPinName(value);
      break;
    case 7:
      var value = /** @type {!proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection} */ (reader.readEnum());
      msg.setControlReplyBitDirection(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInverted(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpecial(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ControlReplyBit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ControlReplyBit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ControlReplyBit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControlReplyBit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBitName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLetters();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumbers();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getViuPinNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getControllerPinName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getControlReplyBitDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInverted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSpecial();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection = {
  CONTROL: 0,
  REPLY: 1
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ControlReplyBit.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string bit_name = 2;
 * @return {string}
 */
proto.vivacity.config.ControlReplyBit.prototype.getBitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setBitName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string letters = 3;
 * @return {string}
 */
proto.vivacity.config.ControlReplyBit.prototype.getLetters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setLetters = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 numbers = 4;
 * @return {number}
 */
proto.vivacity.config.ControlReplyBit.prototype.getNumbers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setNumbers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 viu_pin_number = 5;
 * @return {number}
 */
proto.vivacity.config.ControlReplyBit.prototype.getViuPinNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setViuPinNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string controller_pin_name = 6;
 * @return {string}
 */
proto.vivacity.config.ControlReplyBit.prototype.getControllerPinName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setControllerPinName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ControlReplyBitDirection control_reply_bit_direction = 7;
 * @return {!proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection}
 */
proto.vivacity.config.ControlReplyBit.prototype.getControlReplyBitDirection = function() {
  return /** @type {!proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vivacity.config.ControlReplyBit.ControlReplyBitDirection} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setControlReplyBitDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool inverted = 8;
 * @return {boolean}
 */
proto.vivacity.config.ControlReplyBit.prototype.getInverted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setInverted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool special = 9;
 * @return {boolean}
 */
proto.vivacity.config.ControlReplyBit.prototype.getSpecial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setSpecial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional uint32 controller_id = 11;
 * @return {number}
 */
proto.vivacity.config.ControlReplyBit.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControlReplyBit} returns this
 */
proto.vivacity.config.ControlReplyBit.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.StageToPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.StageToPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.StageToPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.StageToPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isPhaseDemandingStage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.StageToPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.StageToPhase;
  return proto.vivacity.config.StageToPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.StageToPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.StageToPhase}
 */
proto.vivacity.config.StageToPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStageId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPhaseDemandingStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.StageToPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.StageToPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.StageToPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.StageToPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStageId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsPhaseDemandingStage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 phase_id = 1;
 * @return {number}
 */
proto.vivacity.config.StageToPhase.prototype.getPhaseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.StageToPhase} returns this
 */
proto.vivacity.config.StageToPhase.prototype.setPhaseId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 stage_id = 2;
 * @return {number}
 */
proto.vivacity.config.StageToPhase.prototype.getStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.StageToPhase} returns this
 */
proto.vivacity.config.StageToPhase.prototype.setStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_phase_demanding_stage = 3;
 * @return {boolean}
 */
proto.vivacity.config.StageToPhase.prototype.getIsPhaseDemandingStage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.StageToPhase} returns this
 */
proto.vivacity.config.StageToPhase.prototype.setIsPhaseDemandingStage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramToControllerStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramToControllerStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToControllerStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controllerStreamId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.VisionProgramToControllerStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramToControllerStream;
  return proto.vivacity.config.VisionProgramToControllerStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramToControllerStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramToControllerStream}
 */
proto.vivacity.config.VisionProgramToControllerStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramToControllerStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramToControllerStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToControllerStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getControllerStreamId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToControllerStream} returns this
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 controller_stream_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.getControllerStreamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToControllerStream} returns this
 */
proto.vivacity.config.VisionProgramToControllerStream.prototype.setControllerStreamId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.PhaseToImageSpaceZone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.PhaseToImageSpaceZone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PhaseToImageSpaceZone.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageSpaceZoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    failHighAggregatedValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    junctionZoneTypeId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.PhaseToImageSpaceZone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.PhaseToImageSpaceZone;
  return proto.vivacity.config.PhaseToImageSpaceZone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.PhaseToImageSpaceZone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone}
 */
proto.vivacity.config.PhaseToImageSpaceZone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageSpaceZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFailHighAggregatedValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJunctionZoneTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.PhaseToImageSpaceZone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.PhaseToImageSpaceZone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PhaseToImageSpaceZone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageSpaceZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPhaseId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFailHighAggregatedValue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getJunctionZoneTypeId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.PhaseToImageSpaceZone.JunctionZoneTypes = {
  UNKNOWN_ZONE_TYPE: 0,
  STOPLINE_APPROACH: 1,
  UPSTREAM: 2,
  PEDESTRIAN_WAITING: 3,
  PEDESTRIAN_ON_CROSSING: 4
};

/**
 * optional uint32 image_space_zone_id = 1;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.getImageSpaceZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone} returns this
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.setImageSpaceZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 phase_id = 2;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.getPhaseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone} returns this
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.setPhaseId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 fail_high_aggregated_value = 3;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.getFailHighAggregatedValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone} returns this
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.setFailHighAggregatedValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 junction_zone_type_id = 4;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.getJunctionZoneTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceZone} returns this
 */
proto.vivacity.config.PhaseToImageSpaceZone.prototype.setJunctionZoneTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.PhaseToImageSpaceCountline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.PhaseToImageSpaceCountline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PhaseToImageSpaceCountline.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageSpaceCountlineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    failHighAggregatedValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inboundDirection: jspb.Message.getFieldWithDefault(msg, 4, 0),
    junctionCountlineTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.PhaseToImageSpaceCountline;
  return proto.vivacity.config.PhaseToImageSpaceCountline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.PhaseToImageSpaceCountline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageSpaceCountlineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFailHighAggregatedValue(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} */ (reader.readEnum());
      msg.setInboundDirection(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJunctionCountlineTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.PhaseToImageSpaceCountline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.PhaseToImageSpaceCountline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PhaseToImageSpaceCountline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageSpaceCountlineId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPhaseId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFailHighAggregatedValue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getInboundDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJunctionCountlineTypeId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.JunctionCountlineTypes = {
  UNKNOWN_COUNTLINE_TYPE: 0,
  STOPLINE: 1,
  STOPLINE_APPROACH_ENTRY: 2,
  UPSTREAM: 3
};

/**
 * optional uint32 image_space_countline_id = 1;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.getImageSpaceCountlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline} returns this
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.setImageSpaceCountlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 phase_id = 2;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.getPhaseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline} returns this
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.setPhaseId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 fail_high_aggregated_value = 3;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.getFailHighAggregatedValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline} returns this
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.setFailHighAggregatedValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional vivacity.core.CountlineCrossing.CrossingsDirection inbound_direction = 4;
 * @return {!proto.vivacity.core.CountlineCrossing.CrossingsDirection}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.getInboundDirection = function() {
  return /** @type {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline} returns this
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.setInboundDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 junction_countline_type_id = 5;
 * @return {number}
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.getJunctionCountlineTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PhaseToImageSpaceCountline} returns this
 */
proto.vivacity.config.PhaseToImageSpaceCountline.prototype.setJunctionCountlineTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.StageTransition.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.StageTransition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.StageTransition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.StageTransition.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentStageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextStageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    viaStageId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.StageTransition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.StageTransition;
  return proto.vivacity.config.StageTransition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.StageTransition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.StageTransition}
 */
proto.vivacity.config.StageTransition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentStageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextStageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViaStageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.StageTransition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.StageTransition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.StageTransition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.StageTransition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentStageId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNextStageId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getViaStageId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional uint32 current_stage_id = 1;
 * @return {number}
 */
proto.vivacity.config.StageTransition.prototype.getCurrentStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.StageTransition} returns this
 */
proto.vivacity.config.StageTransition.prototype.setCurrentStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 next_stage_id = 2;
 * @return {number}
 */
proto.vivacity.config.StageTransition.prototype.getNextStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.StageTransition} returns this
 */
proto.vivacity.config.StageTransition.prototype.setNextStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 via_stage_id = 3;
 * @return {number}
 */
proto.vivacity.config.StageTransition.prototype.getViaStageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.StageTransition} returns this
 */
proto.vivacity.config.StageTransition.prototype.setViaStageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.SetControlControllerStream.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.SetControlControllerStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.SetControlControllerStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.SetControlControllerStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.SetControlControllerStream}
 */
proto.vivacity.config.SetControlControllerStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.SetControlControllerStream;
  return proto.vivacity.config.SetControlControllerStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.SetControlControllerStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.SetControlControllerStream}
 */
proto.vivacity.config.SetControlControllerStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.SetControlControllerStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.SetControlControllerStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.SetControlControllerStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.SetControlControllerStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.SetControlControllerStream.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.SetControlControllerStream} returns this
 */
proto.vivacity.config.SetControlControllerStream.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.vivacity.config.SetControlControllerStream.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.SetControlControllerStream} returns this
 */
proto.vivacity.config.SetControlControllerStream.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.config);
