import React, { FC, FormEvent, useRef } from "react";
import { useStores } from "../../hooks/useStores.hook";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FreneticityButton } from "../styledElements";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";
import { ClassifyingDetectorClassTypeNumber, classLookup } from "../../workers/utils";
import { observer } from "mobx-react";
import _ from "lodash";
import { CVRunCountlineCrossing } from "../../interfaces";

interface CloneCVCrossingPlateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CloneCVCrossingPlateModal: FC<CloneCVCrossingPlateModalProps> = observer(({ isOpen, onClose }) => {
  const { playerUIStore } = useStores();

  const lastCrossing = useRef<CVRunCountlineCrossing | null>(null);

  const cvCrossing = playerUIStore.lastCVCrossingForClone;
  if (!cvCrossing) {
    return null;
  }

  const classes = Object.keys(ClassifyingDetectorClassTypes).map(className => {
    const classNumber = ClassifyingDetectorClassTypes[className] as ClassifyingDetectorClassTypeNumber;
    return (
      <MenuItem key={classNumber} value={classNumber}>
        {classLookup[classNumber]}
      </MenuItem>
    );
  });

  const plateList: string[] = [];

  if (cvCrossing.topRankedPlate) {
    plateList.push(cvCrossing.topRankedPlate);
  }
  plateList.push(...cvCrossing.otherPlates);

  const plates = plateList.map(plateText => {
    return (
      <MenuItem key={plateText} value={plateText}>
        {plateText}
      </MenuItem>
    );
  });

  if (plateList.length && lastCrossing.current?.frameNumber !== cvCrossing.frameNumber) {
    lastCrossing.current = cvCrossing;
    playerUIStore.setSelectedPlateForPlateClone(plateList[0]);
  }
  const onSubmit = (
    e: FormEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement> | FormEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    if (playerUIStore.selectedPlateForPlateClone !== "") {
      e.currentTarget.blur();
      const cvCrossingClone = JSON.parse(JSON.stringify(cvCrossing)) as CVRunCountlineCrossing;
      if (cvCrossing.anprVehicleClass !== playerUIStore.selectedClassTypeForPlateClone) {
        cvCrossingClone.anprVehicleClass = playerUIStore.selectedClassTypeForPlateClone;
      }
      playerUIStore.setSelectedClassTypeForPlateClone(0);
      playerUIStore.cloneCVCrossingWithPlate(playerUIStore.selectedPlateForPlateClone, cvCrossingClone);
      onClose();
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        playerUIStore.setSelectedClassTypeForPlateClone(0);
        onClose();
      }}
    >
      <DialogTitle>Clone CV Crossing</DialogTitle>
      <DialogContent>
        <Typography>Choose the correct plate read:</Typography>
        <Grid spacing={3} direction="column" justifyContent={"space-evenly"} container>
          <Grid item>
            <FormControl>
              <FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={playerUIStore.selectedPlateForPlateClone}
                  label="Plate Read"
                  onChange={e => {
                    playerUIStore.setSelectedPlateForPlateClone(e.target.value as string);
                  }}
                >
                  {plates}
                </Select>
              </FormLabel>
            </FormControl>
            <FormControl>
              <FormLabel>
                <Typography>Or type your own:</Typography>

                <Input
                  value={playerUIStore.selectedPlateForPlateClone}
                  onChange={e => {
                    playerUIStore.setSelectedPlateForPlateClone(
                      _.replace(_.upperCase(e.target.value as string), " ", "")
                    );
                  }}
                />
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Typography>Adjust the class if required:</Typography>
              <FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={playerUIStore.selectedClassTypeForPlateClone}
                  label="Class Type"
                  onChange={e => {
                    playerUIStore.setSelectedClassTypeForPlateClone(
                      e.target.value as ClassifyingDetectorClassTypeNumber
                    );
                  }}
                >
                  {classes}
                </Select>
              </FormLabel>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FreneticityButton
          onClick={() => {
            onClose();
            playerUIStore.setSelectedClassTypeForPlateClone(0);
          }}
        >
          Cancel
        </FreneticityButton>
        <FreneticityButton disabled={playerUIStore.selectedPlateForPlateClone === ""} onClick={onSubmit}>
          {playerUIStore.selectedPlateForPlateClone !== "" ? "Clone" : "Pick one of the plate reads"}
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});
