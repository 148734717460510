import React, { FC, useState } from "react";
import { useStores } from "../../hooks/useStores.hook";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { FreneticityButton } from "../styledElements";
import { classLookup } from "../../workers/utils";
import _ from "lodash";

interface InsertCrossingWithPlateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InsertCrossingWithPlateModal: FC<InsertCrossingWithPlateModalProps> = ({ isOpen, onClose }) => {
  const { playerUIStore } = useStores();

  const [plateRead, setPlateRead] = useState<string>("");

  if (playerUIStore.lastClockwiseForAddingCrossing === null) {
    return null;
  }
  if (playerUIStore.lastClassForAddingCrossing === null) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>
          Insert {classLookup[playerUIStore.lastClassForAddingCrossing]} (
          {playerUIStore.lastClockwiseForAddingCrossing ? "clockwise" : "anticlockwise"}) crossing
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>
            <Typography>Type the ANPR Plate:</Typography>

            <Input
              value={plateRead}
              onChange={e => {
                setPlateRead(_.replace(_.upperCase(e.target.value as string), " ", ""));
              }}
            />
          </FormLabel>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          disabled={plateRead === ""}
          type={"submit"}
          autoFocus={true}
          onClick={e => {
            if (plateRead !== "") {
              e.currentTarget.blur();
              playerUIStore.saveCrossingWithPlate(plateRead);
              setPlateRead("");
              onClose();
            }
          }}
        >
          Add Crossing
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
};
