import React, { FC } from "react";
import { observer } from "mobx-react";
import * as PIXI from "pixi.js";
import { BitmapText } from "react-pixi-fiber/index";
import randomColor from "randomcolor";

import Polygon from "./Polygon/index";
import { ZoneID } from "../../../domain";
import { ImageSpaceZone } from "../../../interfaces/imageSpaceZone.interface";

const fontName = "TotalsFontRight";

const fontSize = 28;

const textStyle = {
  fill: 0xffffff,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleWithName = {
  ...textStyle,
  fontName: fontName,
  align: "right",
};

interface zoneProps {
  imageSpaceZones: Map<ZoneID, ImageSpaceZone>;
  videoWidth: number;
  videoHeight: number;
  zoneType: "mask" | "turning";
  onClick?: (zoneId: number) => void;
}

export const DrawnZone: FC<zoneProps> = observer(({ imageSpaceZones, videoHeight, videoWidth, zoneType, onClick }) => {
  const mapOfColoursByType = new Map<string, number>([
    ["standardMask", 0x444445],
    ["lethalMask", 0xf69a9a],
  ]);

  const mapOfAlphasByType = new Map<string, number>([
    ["standardMask", 0.85],
    ["lethalMask", 0.75],
    ["turningZone", 0.7],
  ]);

  const getColor = (type: typeof zoneType, isLethal: boolean, zoneId: number) => {
    if (type === "mask") {
      return isLethal ? mapOfColoursByType.get("lethalMask")! : mapOfColoursByType.get("standardMask")!;
    }
    const randomColour = randomColor({
      luminosity: "bright",
      seed: zoneId * zoneId,
    });
    const hexColour = "0x" + randomColour.slice(1);
    return hexColour;
  };

  const getAlpha = (type: typeof zoneType, isLethal: boolean) => {
    if (type === "mask") {
      return isLethal ? mapOfAlphasByType.get("lethalMask") : mapOfAlphasByType.get("standardMask");
    }
    return mapOfAlphasByType.get("turningZone");
  };

  const getCentrePoint = (points: number[]): number[] => {
    const xPoints = points.filter((value, index) => index % 2 === 0);
    const yPoints = points.filter((value, index) => index % 2 === 1);
    return [
      xPoints.reduce((sum, current) => sum + current, 0) / xPoints.length,
      yPoints.reduce((sum, current) => sum + current, 0) / yPoints.length,
    ];
  };

  const zones: JSX.Element[] = [];

  imageSpaceZones.forEach((zone, i) => {
    zones.push(
      onClick ? (
        <Polygon
          key={i}
          points={zone.geometry}
          fillColor={getColor(zoneType, zone.isLethalMask ? zone.isLethalMask : false, zone.zoneId) as number}
          fillAlpha={getAlpha(zoneType, zone.isLethalMask ? zone.isLethalMask : false)}
          lineColor={0x000000}
          lineWidth={3}
          fillOuter={zoneType === "turning" ? zone.deleteInsideZone : !zone.deleteInsideZone}
          playerWidth={videoWidth}
          playerHeight={videoHeight}
          onClick={() => onClick(zone.zoneId)}
        />
      ) : (
        <Polygon
          key={i}
          points={zone.geometry}
          fillColor={getColor(zoneType, zone.isLethalMask ? zone.isLethalMask : false, zone.zoneId) as number}
          fillAlpha={getAlpha(zoneType, zone.isLethalMask ? zone.isLethalMask : false)}
          lineColor={0x000000}
          lineWidth={3}
          fillOuter={zoneType === "turning" ? zone.deleteInsideZone : !zone.deleteInsideZone}
          playerWidth={videoWidth}
          playerHeight={videoHeight}
        />
      )
    );
    const centrePoint = getCentrePoint(zone.geometry);
    zones.push(
      <BitmapText
        fontName={fontName}
        text={`${zone.zoneId}`}
        style={textStyleWithName}
        x={centrePoint[0]}
        y={centrePoint[1]}
        key={"centre-point-text-for-zone-" + zone.zoneId}
      />
    );
  });
  return <>{zones}</>;
});
