import ReconnectingWebSocket from "reconnecting-websocket";
import { action, observable, when } from "mobx";
import { Buffer } from "buffer";

import { WebSocketsAuthentication } from "../vivacity/core/websockets_pb";

import { EventEmitterStore } from "./eventEmitter.store";
import { RootStore } from "./root.store";
import {
  assertComputerVisionRunProgressUpdateHydration,
  assertCVRunCreationHydration,
} from "../interfaces/hydration/type-assertions";
import {
  computerVisionRunProgressUpdateHydrationToComputerVisionRunProgressUpdate,
  cvRunHydrationToCVRun,
} from "../interfaces/hydration/utils";
import { errString } from "./utils/errorString";
import { UniversalEnvelope } from "../vivacity/universal_envelope_pb";
import { universalEnvelopeMessageType } from "../enums/proto.enum";
import { SnappiVideoCapture } from "../vivacity/core/snappi_pb";
import base64ArrayBuffer from "./utils/base64";

enum WebSocketConnectionStatus {
  CONNECTING = WebSocket.CONNECTING,
  OPEN = WebSocket.OPEN,
  CLOSING = WebSocket.CLOSING,
  CLOSED = WebSocket.CLOSED,
  NOT_STARTED = 4,
  UNKNOWN = 5,
}

export enum WebsocketConnectionEndpoint {
  CV_RUN_CREATE = "wss://validation-apiDOT_ENVIRONMENT.vivacitylabs.com/computer_vision_run_updates/create",
  CV_RUN_PROGRESS_UPDATE = "wss://validation-apiDOT_ENVIRONMENT.vivacitylabs.com/computer_vision_run_updates/progress",
  VIDEO_STATUS_UPDATE = "wss://validation-apiDOT_ENVIRONMENT.vivacitylabs.com/video_status_updates/video_status_updates",
}

export class WebsocketConnectionStore extends EventEmitterStore {
  connection: ReconnectingWebSocket;

  @observable
  connectionStatus = WebSocketConnectionStatus.NOT_STARTED;
  websocketConnectionEndpoint!: WebsocketConnectionEndpoint;

  constructor(rootStore: RootStore, websocketConnectionEndpoint: WebsocketConnectionEndpoint) {
    super(rootStore);
    this.websocketConnectionEndpoint = websocketConnectionEndpoint;

    let hostEnv = "";
    if (window.location.host === "validation.staging.vivacitylabs.com") {
      hostEnv = ".staging";
    } else if (window.location.host === "validation.dev.vivacitylabs.com") {
      hostEnv = ".dev";
    } else if (window.location.host === "validation.vivacitylabs.com") {
      hostEnv = "";
    }
    const endpointForEnv = websocketConnectionEndpoint.replace("DOT_ENVIRONMENT", hostEnv);

    this.connection = new ReconnectingWebSocket(endpointForEnv);

    this.connection.addEventListener("close", action(this.handleClose.bind(this)));
    this.connection.addEventListener("open", action(this.handleConnect.bind(this)));
    this.connection.addEventListener("error", action(this.handleConnectFailure.bind(this)));
    this.connection.addEventListener("message", action(this.handleMessage.bind(this)));
    this.connection.binaryType = "arraybuffer";
  }

  @action
  closeConnection() {
    this.connection.close(4000, "connection closed by client");
  }

  @action
  handleClose(e) {
    this.updateConnectionStatus();
    if (e.code >= 4000) {
      console.log(`websocket server connection closed: ${e.reason}`);
    }

    this.emit("close", e);
  }

  @action
  handleConnect() {
    when(
      () => this.rootStore.apiStore.jwt !== "",
      () => {
        const msg = new WebSocketsAuthentication();
        const userId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
        msg.setUserId(`${userId}-browser@vivacitylabs.com`);
        msg.setJwt(this.rootStore.apiStore.jwt);
        const data = msg.serializeBinary();
        this.connection.send(data);

        this.emit("open");
        this.updateConnectionStatus();
      }
    );
  }

  @action
  handleConnectFailure() {
    this.updateConnectionStatus();
  }

  @action.bound
  handleMessage(message: MessageEvent) {
    try {
      switch (this.websocketConnectionEndpoint) {
        case WebsocketConnectionEndpoint.CV_RUN_PROGRESS_UPDATE: {
          const hydration = assertComputerVisionRunProgressUpdateHydration(
            JSON.parse(Buffer.from(message.data).toString())
          );
          const data = computerVisionRunProgressUpdateHydrationToComputerVisionRunProgressUpdate(hydration);
          this.rootStore.cvRunStore.handleComputerVisionRunProgressUpdate(data);
          break;
        }
        case WebsocketConnectionEndpoint.CV_RUN_CREATE: {
          const hydration = assertCVRunCreationHydration(JSON.parse(Buffer.from(message.data).toString()));
          const video = this.rootStore.videoStore.videos.get(hydration.video_id);
          this.rootStore.cvRunStore.setComputerVisionRunById(hydration.id, {
            ...cvRunHydrationToCVRun(hydration, hydration.id, hydration.video_id, hydration.vpid, video?.triggerReason),
            thumbnail: video?.thumbnail,
          });
          if (video && !video.computerVisionRuns) {
            video.computerVisionRuns = [hydration.id];
          } else {
            video?.computerVisionRuns?.push(hydration.id);
          }
          break;
        }
        case WebsocketConnectionEndpoint.VIDEO_STATUS_UPDATE: {
          let universalEnvelope: UniversalEnvelope;
          try {
            universalEnvelope = UniversalEnvelope.deserializeBinary(new Uint8Array(message.data));
          } catch (e) {
            throw new Error(
              `Failed to parse protobuf from data: (${errString(e)}): ${base64ArrayBuffer(message.data)}`
            );
          }
          if (universalEnvelope.getMessageType() !== UniversalEnvelope.MessageType.SNAPPI_VIDEO_CAPTURE) {
            throw new Error(
              "Expected universal envelope to be of type SNAPPI_VIDEO_CAPTURE, but got " +
                universalEnvelopeMessageType[universalEnvelope.getMessageType()]
            );
          }
          const snappiMessage = universalEnvelope.getSnappiVideoCapture()?.toObject();
          if (!snappiMessage) {
            throw new Error("Expected universal envelope to contain SnappiVideoCapture message, but it was missing");
          }
          switch (snappiMessage.updateType) {
            case SnappiVideoCapture.UpdateType.UNKNOWN: {
              throw new Error("Got an unknown update type for SnappiVideoCapture");
            }
            case SnappiVideoCapture.UpdateType.RECORDING_REQUESTED: {
              const recordingRequested = snappiMessage.recordingRequested;
              if (!recordingRequested) {
                throw new Error("Missing RecordingRequested message");
              }
              this.rootStore.videoStore.upsertVideo(snappiMessage.videoId, snappiMessage.requestId, recordingRequested);
              break;
            }
            case SnappiVideoCapture.UpdateType.RECORDING_STARTED: {
              const recordingStarted = snappiMessage.recordingStarted;
              if (!recordingStarted) {
                throw new Error("Missing RecordingStarted message");
              }
              this.rootStore.videoStore.upsertVideo(snappiMessage.videoId, snappiMessage.requestId, recordingStarted);
              break;
            }
            case SnappiVideoCapture.UpdateType.RECORDING_UPDATE: {
              const recordingUpdate = snappiMessage.recordingUpdate;
              if (!recordingUpdate) {
                throw new Error("Missing RecordingUpdate message");
              }

              const existingVideo = this.rootStore.videoStore.videos.get(snappiMessage.videoId);
              if (existingVideo) {
                if (recordingUpdate.thumbnail) {
                  fetch("data:image/jpg;base64," + recordingUpdate.thumbnail)
                    .then(async res => res.blob())
                    .then(
                      action(newBlob => {
                        this.rootStore.videoStore.upsertThumbnail(snappiMessage.videoId, newBlob);
                      })
                    );
                }
                existingVideo.recordingProgress = recordingUpdate.progress;
                if (
                  existingVideo.status !== "RECORDING_COMPLETE" &&
                  existingVideo.status !== "RECORDING_FAILED" &&
                  existingVideo.status !== "UPLOAD_CREATED" &&
                  existingVideo.status !== "UPLOADING" &&
                  existingVideo.status !== "UPLOADING_COMPLETE" &&
                  existingVideo.status !== "UPLOADING_FAILED"
                ) {
                  existingVideo.status = "RECORDING";
                }
              }
              break;
            }
            case SnappiVideoCapture.UpdateType.UPLOAD_CREATED: {
              const uploadCreated = snappiMessage.uploadCreated;
              if (!uploadCreated) {
                throw new Error("Missing UploadCreated message");
              }
              const existingVideo = this.rootStore.videoStore.videos.get(snappiMessage.videoId);
              if (existingVideo) {
                existingVideo.startFrame = uploadCreated.startFrame;
                existingVideo.endFrame = uploadCreated.endFrame;
                if (
                  existingVideo.status !== "UPLOADING" &&
                  existingVideo.status !== "UPLOADING_COMPLETE" &&
                  existingVideo.status !== "UPLOADING_FAILED"
                ) {
                  existingVideo.status = "UPLOAD_CREATED";
                }
              }
              break;
            }
            case SnappiVideoCapture.UpdateType.UPLOAD_UPDATE: {
              const uploadUpdate = snappiMessage.uploadUpdate;
              if (!uploadUpdate) {
                throw new Error("Missing UploadUpdate message");
              }
              const existingVideo = this.rootStore.videoStore.videos.get(snappiMessage.videoId);
              if (existingVideo) {
                existingVideo.uploadingProgress = uploadUpdate.progress;
                if (existingVideo.status !== "UPLOADING_COMPLETE" && existingVideo.status !== "UPLOADING_FAILED") {
                  existingVideo.status = "UPLOADING";
                }
              }
              break;
            }
            case SnappiVideoCapture.UpdateType.UPLOAD_COMPLETE: {
              const uploadComplete = snappiMessage.uploadComplete;
              if (!uploadComplete) {
                throw new Error("Missing UploadComplete message");
              }
              const existingVideo = this.rootStore.videoStore.videos.get(snappiMessage.videoId);
              if (existingVideo) {
                existingVideo.endAt = new Date(uploadComplete.endMicroseconds / 1000);
                existingVideo.startAt = new Date(uploadComplete.startMicroseconds / 1000);
                existingVideo.status = "UPLOADING_COMPLETE";
              }
              break;
            }
            default:
              throw new Error("WTF2?");
          }
          break;
        }
        default:
          throw new Error("WTF?");
      }
    } catch (err) {
      this.rootStore.entitiesStore.setHydrationErrors(
        `Could not handleMessage for Websockets endpoint (${this.websocketConnectionEndpoint}): ${errString(err)}`
      );
    }
  }

  @action
  updateConnectionStatus() {
    switch (this.connection.readyState) {
      case this.connection.CLOSED:
        this.connectionStatus = WebSocketConnectionStatus.CLOSED;
        break;
      case this.connection.CLOSING:
        this.connectionStatus = WebSocketConnectionStatus.CLOSING;
        break;
      case this.connection.OPEN:
        this.connectionStatus = WebSocketConnectionStatus.OPEN;
        break;
      case this.connection.CONNECTING:
        this.connectionStatus = WebSocketConnectionStatus.CONNECTING;
        break;
      default:
        this.connectionStatus = WebSocketConnectionStatus.UNKNOWN;
    }
  }

  @action
  forceReconnection() {
    this.connection.reconnect();
  }

  @action
  sendMessage(message: ArrayBufferLike) {
    this.connection.send(message);
  }
}
