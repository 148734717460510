import { ValidationMode } from "../../enums";
import { UrlQueryParams } from "../../interfaces";
import { nullToUndefined } from "../../interfaces/hydration/utils";

const DEFAULT_URL_PARAMS: UrlQueryParams = {
  selectedVisionProgram: undefined,
  selectedVideo: undefined,
  selectedComputerVisionRun: undefined,
  selectedValidationRun: undefined,
  selectedCountline: undefined,
  selectedValidationMode: ValidationMode.CountlineValidation,
  search: undefined,
};

export const getDefaultUrlParams = () => {
  return DEFAULT_URL_PARAMS;
};

export const urlToStoreMapping = {
  selectedVisionProgram: {
    storeName: "selectedVisionProgram",
    getter: "selectedVisionProgramValue",
    cleaner: value => nullToUndefined(value),
  },

  selectedVideo: {
    storeName: "selectedVideo",
    getter: "selectedVideoValue",
    cleaner: value => nullToUndefined(value),
  },

  selectedComputerVisionRun: {
    storeName: "selectedComputerVisionRun",
    getter: "selectedComputerVisionRunValue",
    cleaner: value => nullToUndefined(value),
  },

  selectedValidationRun: {
    storeName: "selectedValidationRun",
    getter: "selectedValidationRunValue",
    cleaner: value => nullToUndefined(value),
  },

  selectedCountline: {
    storeName: "selectedCountline",
    getter: "selectedCountlineValue",
    cleaner: value => nullToUndefined(value),
  },

  selectedValidationMode: {
    storeName: "selectedValidationMode",
    getter: "selectedValidationModeValue",
    cleaner: value => nullToUndefined(value),
  },

  search: {
    storeName: "search",
    getter: "searchValue",
    cleaner: value => nullToUndefined(value),
  },
};
