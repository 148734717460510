/* eslint-disable */
// source: vivacity/core/vision_program_windowed_features.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_zonal_windowed_features_pb = require('../../vivacity/core/zonal_windowed_features_pb.js');
goog.object.extend(proto, vivacity_core_zonal_windowed_features_pb);
var vivacity_core_countline_windowed_features_pb = require('../../vivacity/core/countline_windowed_features_pb.js');
goog.object.extend(proto, vivacity_core_countline_windowed_features_pb);
var vivacity_core_detection_quality_pb = require('../../vivacity/core/detection_quality_pb.js');
goog.object.extend(proto, vivacity_core_detection_quality_pb);
goog.exportSymbol('proto.vivacity.core.VisionProgramWindowedFeatures', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.VisionProgramWindowedFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.VisionProgramWindowedFeatures.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.VisionProgramWindowedFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.VisionProgramWindowedFeatures.displayName = 'proto.vivacity.core.VisionProgramWindowedFeatures';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.VisionProgramWindowedFeatures.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.VisionProgramWindowedFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.VisionProgramWindowedFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VisionProgramWindowedFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    windowStartTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    windowLengthMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    zonalWindowedFeaturesList: jspb.Message.toObjectList(msg.getZonalWindowedFeaturesList(),
    vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.toObject, includeInstance),
    countlineWindowedFeaturesList: jspb.Message.toObjectList(msg.getCountlineWindowedFeaturesList(),
    vivacity_core_countline_windowed_features_pb.CountlineWindowedFeatures.toObject, includeInstance),
    messageCreationTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    detectionQuality: (f = msg.getDetectionQuality()) && vivacity_core_detection_quality_pb.DetectionQuality.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.VisionProgramWindowedFeatures;
  return proto.vivacity.core.VisionProgramWindowedFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.VisionProgramWindowedFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWindowStartTimestampMicroseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowLengthMicroseconds(value);
      break;
    case 5:
      var value = new vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures;
      reader.readMessage(value,vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.deserializeBinaryFromReader);
      msg.addZonalWindowedFeatures(value);
      break;
    case 6:
      var value = new vivacity_core_countline_windowed_features_pb.CountlineWindowedFeatures;
      reader.readMessage(value,vivacity_core_countline_windowed_features_pb.CountlineWindowedFeatures.deserializeBinaryFromReader);
      msg.addCountlineWindowedFeatures(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMessageCreationTimestampMicroseconds(value);
      break;
    case 8:
      var value = new vivacity_core_detection_quality_pb.DetectionQuality;
      reader.readMessage(value,vivacity_core_detection_quality_pb.DetectionQuality.deserializeBinaryFromReader);
      msg.setDetectionQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.VisionProgramWindowedFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.VisionProgramWindowedFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VisionProgramWindowedFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getWindowStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getWindowLengthMicroseconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getZonalWindowedFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.serializeBinaryToWriter
    );
  }
  f = message.getCountlineWindowedFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      vivacity_core_countline_windowed_features_pb.CountlineWindowedFeatures.serializeBinaryToWriter
    );
  }
  f = message.getMessageCreationTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDetectionQuality();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_detection_quality_pb.DetectionQuality.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TracingContext tracing_ctx = 2;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 2));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
*/
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 window_start_timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getWindowStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setWindowStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 window_length_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getWindowLengthMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setWindowLengthMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ZonalWindowedFeatures zonal_windowed_features = 5;
 * @return {!Array<!proto.vivacity.core.ZonalWindowedFeatures>}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getZonalWindowedFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalWindowedFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalWindowedFeatures>} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
*/
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setZonalWindowedFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.ZonalWindowedFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalWindowedFeatures}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.addZonalWindowedFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.ZonalWindowedFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.clearZonalWindowedFeaturesList = function() {
  return this.setZonalWindowedFeaturesList([]);
};


/**
 * repeated CountlineWindowedFeatures countline_windowed_features = 6;
 * @return {!Array<!proto.vivacity.core.CountlineWindowedFeatures>}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getCountlineWindowedFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.CountlineWindowedFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_countline_windowed_features_pb.CountlineWindowedFeatures, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.CountlineWindowedFeatures>} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
*/
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setCountlineWindowedFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.core.CountlineWindowedFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.CountlineWindowedFeatures}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.addCountlineWindowedFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.core.CountlineWindowedFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.clearCountlineWindowedFeaturesList = function() {
  return this.setCountlineWindowedFeaturesList([]);
};


/**
 * optional uint64 message_creation_timestamp_microseconds = 7;
 * @return {number}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getMessageCreationTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setMessageCreationTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional DetectionQuality detection_quality = 8;
 * @return {?proto.vivacity.core.DetectionQuality}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.getDetectionQuality = function() {
  return /** @type{?proto.vivacity.core.DetectionQuality} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detection_quality_pb.DetectionQuality, 8));
};


/**
 * @param {?proto.vivacity.core.DetectionQuality|undefined} value
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
*/
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.setDetectionQuality = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VisionProgramWindowedFeatures} returns this
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.clearDetectionQuality = function() {
  return this.setDetectionQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VisionProgramWindowedFeatures.prototype.hasDetectionQuality = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.vivacity.core);
