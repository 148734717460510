import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import React from "react";
import ReactPlayer from "react-player";
import * as PIXI from "pixi.js";
import {
  BoxDetails,
  CountlineID,
  CountlineValidationRunID,
  FrameInterval,
  FrameNumber,
  SnappedVideoTime,
  TrackHeadDisplayFuncEnum,
  TrackID,
  TrackPair,
  ZoneID,
} from "../domain";
import _, { round } from "lodash";
import { CountlineConfig, Point, ZoneConfig } from "../interfaces/hydration";
import {
  buildTrackBoolFunc,
  buildTrackNumberFunc,
  buildTrackTextFunc,
  ClassifyingDetectorClassTypeNumber,
  classLookup,
  DisplayFuncsText,
  getNextDTF,
  getTrackText,
} from "../workers/utils";
import { errString } from "./utils/errorString";

import * as exampleTrackHead from "../workers/exampleTrackHead.json";
import * as exampleDTF from "../workers/exampleDTF.json";
import { DetectorTrackerFrame } from "../vivacity/core/detector_tracker_frame_pb";
import { TrackHead } from "../vivacity/core/track_head_pb";
import { colourMap } from "../workers/colourMap";
import { crc24 } from "crc";
import {
  ComputerVisionRunTurningMovements,
  CountlineValidationRun,
  CVRunCountlineCrossing,
  ValidationRunCountlineCrossing,
  Video,
} from "../interfaces";
import { ClassifyingDetectorClassTypes } from "../vivacity/core/classifying_detector_class_types_pb";
import { ApplicationKeyMap, getApplicationKeyMap, KeyCombination, recordKeyCombination } from "react-hotkeys";
import { assertMultiExtendedKeyMap, ExtendedKeyMap } from "../interfaces/hydration/type-assertions";
import { ReactKeyNamesToMousetrapDictionary } from "../components/PlayerUI/KeyboardPopover/reactToMousetrapNames";
import { Stage } from "react-pixi-fiber/index";
import { createDefaultKeyMap, DefaultKeymap } from "../components/PlayerUI/KeyboardPopover/keymaps";
import { transposeMap, TriggerReason } from "../enums/proto.enum";
import { ImageSpaceZone } from "../interfaces/imageSpaceZone.interface";
import { SceneCaptureTriggerReason } from "../vivacity/core/scene_capture_trigger_reasons_pb";
import {
  defaultTrackHeadTextFunc,
  nearMissTrackHeadShouldDisplayFunc,
  nearMissTrackHeadTextFunc,
} from "../defaultDisplayTextFuncs";

type BoolFunc = (prev: boolean) => boolean;
type NumFunc = (prev: number) => number;

const preventDefaultHandlers = (
  playerUIStore: PlayerUIStore,
  handlers: { [key: string]: (keyEvent?: KeyboardEvent) => void }
) => {
  const newHandlers: { [key: string]: (keyEvent?: KeyboardEvent) => void } = {};
  for (const [keyboardAction, handler] of Object.entries(handlers)) {
    newHandlers[keyboardAction] = event => {
      if (event) {
        if (keyboardAction !== "SWALLOW_DEFAULTS_WHILE_RECORDING" || playerUIStore.isRecordingKeymap) {
          // Prevent defaults except for special keys, which should only be prevented while recording a new key sequence
          event.preventDefault();
        }
        event.stopPropagation();
      }

      if (!playerUIStore.isRecordingKeymap) {
        // Only call the registered handler if we're not recording
        handler.bind(playerUIStore)(event);
      }
    };
  }
  return newHandlers;
};

export class PlayerUIStore extends ChildStore {
  public playerRef: React.RefObject<ReactPlayer> | null = null;
  public stageRef: React.RefObject<Stage> | null = null;

  // Entity selection
  @observable public videoURL = "";

  @observable public pixiVideoTexture: PIXI.Texture<PIXI.Resource> | null = null;

  // Video Metadata

  // Estimated since it can come from 4 places with increasing confidence:
  //  4) Complete guess based on video duration and a reasonable FPS (e.g. 15?)
  //  3) Video DB num buffers
  //  2) Video DB end_frame - start_frame if available
  //  1) CV Run processed DTFs
  @observable public estimatedTotalFrames = 0;
  @observable public actualTotalFrames = 0;
  @observable public videoDurationSeconds = 0;
  @observable public videoWidth = 1920; // Non-zero defaults until the video is actually loaded
  @observable public videoHeight = 1080;
  @observable public use1920x1080 = true;

  // Used to calculate an estimated latency by video being progressed ahead of frame number being advanced
  private lastRenderPerformanceTime: DOMHighResTimeStamp = 0;
  private lastProgressPerformanceTime: DOMHighResTimeStamp = 0;

  @observable public playing = false;
  @observable public playbackRate = 1;
  @observable public playbackRateVisual = 1;
  @observable public videoCurrentTimeSeconds = 0;
  @observable public videoLoadedTimeSeconds = 0;

  @observable public timelineWidth = 0;
  @observable public timelineZoomLevel = 0.1;
  @observable public timelineIsDragging = false;

  @observable public frameNumberOfInterestStart = 0; // Used for selecting time ranges on the timeline
  @observable public frameNumberOfInterestEnd = 0;

  // UI state
  @observable public currentFrameNumber = 0;
  @observable public throttledCurrentFrameNumber = -1;

  @observable seenClasses: ClassifyingDetectorClassTypeNumber[] = [];

  // Textures
  @observable public thumbnailsByDuration: Map<number, HTMLImageElement> = new Map<number, HTMLImageElement>();

  @observable public videoBlur = 0;

  @observable public runningTotalsTextAlpha = 0.75;

  // Geometries
  public countlineGeometries: Map<CountlineID, number[]> = new Map<CountlineID, number[]>();
  public zoneGeometries: Map<ZoneID, number[]> = new Map<ZoneID, number[]>();
  public imageSpaceMasks: Map<ZoneID, ImageSpaceZone> = new Map<ZoneID, ImageSpaceZone>();
  public imageSpaceTurningZones: Map<ZoneID, ImageSpaceZone> = new Map<ZoneID, ImageSpaceZone>();
  public tailGeometries: Map<TrackID, number[]> = new Map<TrackID, number[]>();
  public tailColours: Map<TrackID, number> = new Map<TrackID, number>();
  public tailStartFrames: Map<TrackID, FrameNumber> = new Map<TrackID, FrameNumber>();
  public activeTracksByFrame: Map<FrameNumber, TrackID[]> = new Map<FrameNumber, TrackID[]>();
  public boundingBoxesByFrame: Map<FrameNumber, BoxDetails[]> = new Map<FrameNumber, BoxDetails[]>();
  @observable public mouseOverByTrack: Map<TrackID, boolean> = new Map<TrackID, boolean>();
  @observable public mouseOverByCountlineID: Map<CountlineID, boolean> = new Map<CountlineID, boolean>();

  // Video timestamp conversions
  public unsnappedUnixTimestampsByFrame: Map<FrameNumber, number> = new Map<FrameNumber, number>();

  public framesByVideoTimestampMicroseconds: Map<SnappedVideoTime, FrameNumber> = new Map<
    SnappedVideoTime,
    FrameNumber
  >();

  public videoTimestampMicrosecondsByFrame: Map<FrameNumber, SnappedVideoTime> = new Map<
    FrameNumber,
    SnappedVideoTime
  >();
  @observable public frameAvgIntervalMicroseconds = 62500; // Will be overwritten once we have parsed DTFs

  // The starting byte index of each binary encoded DTF by framenumber
  @observable public dtfBufferOffsetsByFrameNumber = new Map<FrameNumber, number>();
  public dtfBuffer: Uint8Array | null = null;

  @observable public nearMissIncidents = new Map<TrackPair, FrameInterval>();

  // progress variables
  @observable public keyboardVisible = false;

  @observable public zoomEnabled = false;
  @observable public showTrackTails = true;
  @observable public showTrackBoxes = true;
  @observable public showTrackFootprints = true;
  @observable public showCountlines = true;
  @observable public showMasks = false;
  @observable public showTurningZones = false;
  @observable public showNearMissZones = false;
  @observable public showCVRunCrossings = true;

  @observable public readOnlyMode = false;
  @observable public playUntilNextCVCrossingMode = false;
  @observable public nextCVCrossingFrame = 0;

  @observable public playUntilNextCVCrossingClasses = new Set<ClassifyingDetectorClassTypeNumber>();
  @observable public isPlayUntilNextCVCrossingClassesModalOpen = false;

  @observable public isResultsModalOpen = false;
  @observable public isNotesModalOpen = false;
  @observable public isEditTrackHeadDisplayFuncsModalOpen = false;
  @observable public isInsertCrossingModalOpen = false;
  @observable public isInsertCrossingWithPlateModalOpen = false;
  @observable public isCloneCVCrossingWithPlateModalOpen = false;
  @observable public selectedClassTypeForPlateClone: ClassifyingDetectorClassTypeNumber = 0;
  @observable public selectedPlateForPlateClone = "";
  @observable public isSaveModalOpen = false;
  @observable public isNewCVRunModalOpen = false;

  @observable public errors = "";

  @observable public snackbarText = "";

  @observable public usePoseGroundCentre = false;

  @observable public trackHeadDisplayFuncs: DisplayFuncsText = {
    trackHeadTextFunc: "",
    trackHeadShouldDisplayFunc: "",
    trackHeadShouldDisplayFootprintFunc: "",
    trackHeadLineWidthFunc: "",
    trackHeadLineColourFunc: "",
    trackHeadLineAlphaFunc: "",
    trackHeadFillColourFunc: "",
    trackHeadFillAlphaFunc: "",
    trackHeadFootprintLineWidthFunc: "",
    trackHeadFootprintLineColourFunc: "",
    trackHeadFootprintLineAlphaFunc: "",
    trackHeadFootprintFillColourFunc: "",
    trackHeadFootprintFillAlphaFunc: "",
    trackHeadTextColourFunc: "",
    trackHeadTextSizeFunc: "",
    trackHeadTextPositionFunc: "",
    trackHeadTextAlphaFunc: "",
    trackHeadTextExpressionOnly: true,
    trackHeadShouldDisplayExpressionOnly: true,
    trackHeadLineWidthExpressionOnly: true,
    trackHeadLineColourExpressionOnly: true,
    trackHeadLineAlphaExpressionOnly: true,
    trackHeadFillColourExpressionOnly: true,
    trackHeadFillAlphaExpressionOnly: true,
    trackHeadTextColourExpressionOnly: true,
    trackHeadTextAlphaExpressionOnly: true,
    trackHeadTextSizeExpressionOnly: true,
    trackHeadTextPositionExpressionOnly: true,
  };

  @observable public trackHeadDisplayFuncsErrors: DisplayFuncsText = {
    trackHeadTextFunc: "",
    trackHeadShouldDisplayFunc: "",
    trackHeadShouldDisplayFootprintFunc: "",
    trackHeadLineWidthFunc: "",
    trackHeadLineColourFunc: "",
    trackHeadLineAlphaFunc: "",
    trackHeadFillColourFunc: "",
    trackHeadFillAlphaFunc: "",
    trackHeadFootprintLineWidthFunc: "",
    trackHeadFootprintLineColourFunc: "",
    trackHeadFootprintLineAlphaFunc: "",
    trackHeadFootprintFillColourFunc: "",
    trackHeadFootprintFillAlphaFunc: "",
    trackHeadTextPositionFunc: "",
    trackHeadTextSizeFunc: "",
    trackHeadTextColourFunc: "",
    trackHeadTextAlphaFunc: "",
  };

  @computed get hasTrackHeadDisplayFuncError(): boolean {
    return (
      (this.trackHeadDisplayFuncsErrors.trackHeadTextFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc ??
        this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc ??
        "") !== ""
    );
  }

  getTrackHeadDisplayFuncError(func: TrackHeadDisplayFuncEnum): string {
    switch (func) {
      case TrackHeadDisplayFuncEnum.TrackText:
        return this.trackHeadDisplayFuncsErrors.trackHeadTextFunc;
      case TrackHeadDisplayFuncEnum.ShouldDisplay:
        return this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc;
      case TrackHeadDisplayFuncEnum.LineWidth:
        return this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc;
      case TrackHeadDisplayFuncEnum.LineColour:
        return this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc;
      case TrackHeadDisplayFuncEnum.LineAlpha:
        return this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc;
      case TrackHeadDisplayFuncEnum.FillColour:
        return this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc;
      case TrackHeadDisplayFuncEnum.FillAlpha:
        return this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc;
      case TrackHeadDisplayFuncEnum.FootprintLineWidth:
        return this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc;
      case TrackHeadDisplayFuncEnum.FootprintLineColour:
        return this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc;
      case TrackHeadDisplayFuncEnum.FootprintLineAlpha:
        return this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc;
      case TrackHeadDisplayFuncEnum.FootprintFillColour:
        return this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc;
      case TrackHeadDisplayFuncEnum.FootprintFillAlpha:
        return this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc;
      case TrackHeadDisplayFuncEnum.TextColour:
        return this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc;
      case TrackHeadDisplayFuncEnum.TextAlpha:
        return this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc;
      case TrackHeadDisplayFuncEnum.TextSize:
        return this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc;
      case TrackHeadDisplayFuncEnum.TextPosition:
        return this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc;
      default:
        return "";
    }
  }

  public lastClickedTrackHead: TrackHead.AsObject = exampleTrackHead as unknown as TrackHead.AsObject;

  public lastClickedDTF: DetectorTrackerFrame.AsObject = exampleDTF as unknown as DetectorTrackerFrame.AsObject;

  public nextCVCrossingForEZMode: CVRunCountlineCrossing | null = null;

  public lastCVCrossingForClone: CVRunCountlineCrossing | null = null;

  public lastClassForAddingCrossing: ClassifyingDetectorClassTypeNumber | null = null;

  public lastClockwiseForAddingCrossing: boolean | null = null;

  @observable
  public lastClickedCVCrossingForPopper: CVRunCountlineCrossing | null = null;
  @observable
  public lastClickedCVCrossingForPopperIsZoomed = false;

  @observable
  public keepLastClickedCVCrossingPopperOpen = false;

  @observable
  public lastClickedValidationRunCrossingForPopper: ValidationRunCountlineCrossing | null = null;

  @observable
  public lastClickedValidationRunCrossingForPopperIsZoomed = false;

  public availableKeyMaps: Map<string, ExtendedKeyMap> = new Map<string, ExtendedKeyMap>();

  @observable
  public currentKeyMapName = "";

  @observable
  public currentKeyMap: ExtendedKeyMap = DefaultKeymap;

  public keyboardHandlers = preventDefaultHandlers(this, {
    PLAY_PAUSE: () => {
      this.togglePlaying();
    },
    STEP_FORWARD_2_SECONDS: () => {
      this.seekToTimeByOffset(2);
    },
    STEP_BACKWARD_2_SECONDS: () => {
      this.seekToTimeByOffset(-2);
    },
    STEP_FORWARD_30_SECONDS: () => {
      this.seekToTimeByOffset(30);
    },
    STEP_BACKWARD_30_SECONDS: () => {
      this.seekToTimeByOffset(-30);
    },
    STEP_FORWARD_1_FRAME: () => {
      this.play1Frame(0.5, 60);
    },
    STEP_BACKWARD_1_FRAME: () => {
      this.seekToFrameByOffset(-1);
    },
    JUMP_TO_NEXT_CV_CROSSING: () => {
      const { frameNumber, crossing } = this.getNextCVCrossingFrame();
      if (this.playUntilNextCVCrossingMode) {
        this.lastClickedCVCrossingForPopper = crossing;
      }
      this.seekToFrame(frameNumber);
    },
    JUMP_TO_PREVIOUS_CV_CROSSING: () => {
      const { frameNumber, crossing } = this.getPreviousCVCrossingFrame();
      if (this.playUntilNextCVCrossingMode) {
        this.lastClickedCVCrossingForPopper = crossing;
      }
      this.seekToFrame(frameNumber);
    },
    JUMP_TO_NEXT_VALIDATION_CROSSING: () => {
      this.seekToFrame(this.getNextValidationRunCrossingFrame());
    },
    JUMP_TO_PREVIOUS_VALIDATION_CROSSING: () => {
      this.seekToFrame(this.getPreviousValidationRunCrossingFrame());
    },
    DELETE_LAST_CROSSING: () => this.deleteLastCrossing(),
    DELETE_NEXT_CROSSING: () => this.deleteNextCrossing(),
    INSERT_CROSSING_DIALOG: () => {
      this.setIsInsertCrossingModalOpen(true);
    },
    INSERT_SAME_AS_LAST_CV_CROSSING: () => {
      this.clonePreviousCVCrossing();
    },
    EXIT_PLAYER: () => {
      const navigate = this.rootStore.navigate.current;
      if (navigate) {
        navigate(-1);
      }
    },
    PLAYBACK_FASTER: () => {
      this.multiplyPlaybackRate(1.1);
    },
    PLAYBACK_SLOWER: () => {
      this.multiplyPlaybackRate(1 / 1.1);
    },
    CAPTURE_IMAGE: () => {
      this.copyPlayerSnapshotToClipboard();
    },
    TOGGLE_SHOW_COUNTLINES: () => {
      this.setShowCountlines(prev => !prev);
    },
    TOGGLE_SHOW_TRACK_TAILS: () => {
      this.setShowTrackTails(prev => !prev);
    },
    TOGGLE_SHOW_BOXES: () => {
      this.setShowTrackBoxes(prev => !prev);
    },
    TOGGLE_ZOOM_ENABLED: () => {
      this.setZoomEnabled(prev => !prev);
    },
    TOGGLE_PLAY_UNTIL_NEXT_CV_CROSSING_MODE: () => {
      this.setPlayUntilNextCVCrossingMode(prev => !prev);
    },
    ADD_NOTES: () => {
      this.setIsNotesModalOpen(true);
    },
    SHOW_RESULTS_DIALOG: () => {
      this.setIsResultsModalOpen(true);
    },
    EDIT_DISPLAY_SETTINGS: () => {
      this.setIsEditTrackHeadDisplayFuncsModalOpen(true);
    },
    SHOW_SAVE_DIALOG: () => {
      this.setIsSaveModalOpen(true);
    },

    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `ADD_${classType}_COUNT_CLOCKWISE`,
          () => this.addCrossing(ClassifyingDetectorClassTypes[classType], true),
        ];
      })
    ),
    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `ADD_${classType}_COUNT_ANTICLOCKWISE`,
          () => this.addCrossing(ClassifyingDetectorClassTypes[classType], false),
        ];
      })
    ),
    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `DELETE_LAST_${classType}_COUNT_CLOCKWISE`,
          () => this.deleteLastCrossing(ClassifyingDetectorClassTypes[classType], true),
        ];
      })
    ),
    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `DELETE_LAST_${classType}_COUNT_ANTICLOCKWISE`,
          () => this.deleteLastCrossing(ClassifyingDetectorClassTypes[classType], false),
        ];
      })
    ),
    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `ADD_${classType}_COUNT_CLOCKWISE_WITH_PLATE`,
          () => this.addCrossingWithPlate(ClassifyingDetectorClassTypes[classType], true),
        ];
      })
    ),
    ...Object.fromEntries(
      Object.keys(ClassifyingDetectorClassTypes).map(classType => {
        return [
          `ADD_${classType}_COUNT_ANTICLOCKWISE_WITH_PLATE`,
          () => this.addCrossingWithPlate(ClassifyingDetectorClassTypes[classType], false),
        ];
      })
    ),
    SWALLOW_DEFAULTS_WHILE_RECORDING: e => {},
  });

  @observable isRecordingKeymap = false;
  @observable recordingHandlerName = "";

  public recordingCancel?: () => void;

  private throttledSetPlaybackRate = _.throttle(action(this.setPlaybackRateUnthrottled.bind(this)), 100);

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  init() {
    action(() => {
      this.currentKeyMapName = "Traffic 13";
      this.availableKeyMaps.set("Traffic 13", DefaultKeymap);
      this.availableKeyMaps.set("Traffic 32", createDefaultKeyMap());
      this.availableKeyMaps.set("CRT 11", createDefaultKeyMap());
      Object.values(ClassifyingDetectorClassTypes)
        .filter(num => num < 10000)
        .forEach(v => this.playUntilNextCVCrossingClasses.add(v));
    })();
  }

  public setLastRenderPerformanceTime() {
    this.lastRenderPerformanceTime = performance.now();
  }

  public testTextFunc(tpl: string, expressionOnly: boolean): [value: string, error: string] {
    try {
      const func = buildTrackTextFunc(tpl, expressionOnly);
      const text = getTrackText(
        func,
        this.lastClickedTrackHead as TrackHead.AsObject,
        this.lastClickedDTF as DetectorTrackerFrame.AsObject
      );
      if (!text.startsWith("ERROR")) {
        return [text, ""];
      } else {
        return ["", text];
      }
    } catch (e) {
      return ["", errString(e)];
    }
  }

  public testBoolFunc(tpl: string, expressionOnly: boolean): [value: boolean, error: string] {
    try {
      const func = buildTrackBoolFunc(tpl, expressionOnly);
      const bool = func(
        this.lastClickedTrackHead as TrackHead.AsObject,
        this.lastClickedDTF as DetectorTrackerFrame.AsObject,
        classLookup,
        transposeMap,
        window.proto,
        _
      );
      if (typeof bool !== "boolean") {
        return [false, "expected return type to be boolean, got " + typeof bool];
      } else {
        return [bool, ""];
      }
    } catch (e) {
      return [false, errString(e)];
    }
  }

  public testNumberFunc(tpl: string, expressionOnly: boolean): [value: number, error: string] {
    try {
      const func = buildTrackNumberFunc(tpl, expressionOnly);
      const num = func(
        this.lastClickedTrackHead as TrackHead.AsObject,
        this.lastClickedDTF,
        classLookup,
        transposeMap,
        window.proto,
        _,
        crc24,
        colourMap
      );
      if (typeof num !== "number") {
        return [0, "expected return type to be number, got " + typeof num];
      } else {
        return [num, ""];
      }
    } catch (e) {
      return [0, errString(e)];
    }
  }

  @action
  public setUsePoseGroundCentre(value: boolean) {
    this.usePoseGroundCentre = value;
  }

  @action
  public setTrackHeadTextFunc(tpl: string): boolean {
    const [, error] = this.testTextFunc(tpl, this.trackHeadDisplayFuncs.trackHeadTextExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextFunc = "";
      this.trackHeadDisplayFuncs.trackHeadTextFunc = tpl;
      return true;
    }
  }

  @action
  public setTrackHeadTextExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadTextExpressionOnly = expressionOnly;
    const [, error] = this.testTextFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextFunc = "";
    }
  }

  @action
  public setTrackHeadShouldDisplayFunc(tpl: string): boolean {
    const [, error] = this.testBoolFunc(tpl, this.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadShouldDisplayFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadShouldDisplayExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly = expressionOnly;
    const [, error] = this.testBoolFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = "";
    }
  }

  @action
  public setTrackHeadShouldDisplayFootprintFunc(tpl: string): boolean {
    const [, error] = this.testBoolFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFootprintFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFootprintFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadShouldDisplayFootprintExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintExpressionOnly = expressionOnly;
    const [, error] = this.testBoolFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadShouldDisplayFunc = "";
    }
  }

  @action
  public setTrackHeadLineWidthFunc(tpl: string): boolean {
    const [, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadLineWidthExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadLineWidthFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadLineWidthExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadLineWidthExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadLineWidthFunc = "";
    }
  }

  @action
  public setTrackHeadLineColourFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadLineColourExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc = error;
      return false;
    } else if (num > 2 ** 24 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc =
        "expected number to be a 24-bit colour but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadLineColourFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadLineColourExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadLineColourExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadLineColourFunc = "";
    }
  }

  @action
  public setTrackHeadLineAlphaFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadLineAlphaExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc = error;
      return false;
    } else if (num > 1 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc =
        "expected number to be between 0-1 but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadLineAlphaFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadLineAlphaExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadLineAlphaExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadLineAlphaFunc = "";
    }
  }

  @action
  public setTrackHeadFillColourFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintFillColourExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc = error;
      return false;
    } else if (num > 2 ** 24 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc =
        "expected number to be a 24-bit colour but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFillColourFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFillColourExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFillColourExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFillColourFunc = "";
    }
  }

  @action
  public setTrackHeadFillAlphaFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadFillAlphaExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc = error;
      return false;
    } else if (num > 1 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc =
        "expected number to be between 0-1 but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFillAlphaFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFillAlphaExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFillAlphaExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFillAlphaFunc = "";
    }
  }

  @action
  public setTrackHeadFootprintLineWidthFunc(tpl: string): boolean {
    const [, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintLineWidthExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFootprintLineWidthFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFootprintLineWidthExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFootprintLineWidthExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineWidthFunc = "";
    }
  }

  @action
  public setTrackHeadFootprintLineColourFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintLineColourExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc = error;
      return false;
    } else if (num > 2 ** 24 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc =
        "expected number to be a 24-bit colour but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFootprintLineColourFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFootprintLineColourExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFootprintLineColourExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineColourFunc = "";
    }
  }

  @action
  public setTrackHeadFootprintLineAlphaFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintLineAlphaExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc = error;
      return false;
    } else if (num > 1 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc =
        "expected number to be between 0-1 but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFootprintLineAlphaFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFootprintLineAlphaExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFootprintLineAlphaExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintLineAlphaFunc = "";
    }
  }

  @action
  public setTrackHeadFootprintFillColourFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintFillColourExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc = error;
      return false;
    } else if (num > 2 ** 24 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc =
        "expected number to be a 24-bit colour but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFootprintFillColourFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFootprintFillColourExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFootprintFillColourExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillColourFunc = "";
    }
  }

  @action
  public setTrackHeadFootprintFillAlphaFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadFootprintFillAlphaExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc = error;
      return false;
    } else if (num > 1 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc =
        "expected number to be between 0-1 but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadFootprintFillAlphaFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadFootprintFillAlphaExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadFootprintFillAlphaExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadFootprintFillAlphaFunc = "";
    }
  }

  @action
  public setTrackHeadTextColourFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(
      tpl,
      this.trackHeadDisplayFuncs.trackHeadTextColourExpressionOnly ?? false
    );
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc = error;
      return false;
    } else if (num > 2 ** 24 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc =
        "expected number to be a 24-bit colour but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadTextColourFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadTextColourExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadTextColourExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextColourFunc = "";
    }
  }

  @action
  public setTrackHeadTextAlphaFunc(tpl: string): boolean {
    const [num, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadTextAlphaExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc = error;
      return false;
    } else if (num > 1 || num < 0) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc =
        "expected number to be between 0-1 but got " + num.toString(10);
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadTextAlphaFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadTextAlphaExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadTextAlphaExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextAlphaFunc = "";
    }
  }

  @action
  public setTrackHeadTextSizeFunc(tpl: string): boolean {
    const [, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadTextSizeExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadTextSizeFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadTextSizeExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadTextSizeExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextSizeFunc = "";
    }
  }

  @action
  public setTrackHeadTextPositionFunc(tpl: string): boolean {
    const [, error] = this.testNumberFunc(tpl, this.trackHeadDisplayFuncs.trackHeadTextPositionExpressionOnly ?? false);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc = error;
      return false;
    } else {
      this.trackHeadDisplayFuncs.trackHeadTextPositionFunc = tpl;
      this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc = "";
      return true;
    }
  }

  @action
  public setTrackHeadTextPositionExpressionOnly(tpl: string, expressionOnly: boolean) {
    this.trackHeadDisplayFuncs.trackHeadTextPositionExpressionOnly = expressionOnly;
    const [, error] = this.testNumberFunc(tpl, expressionOnly);
    if (error) {
      this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc = error;
    } else {
      this.trackHeadDisplayFuncsErrors.trackHeadTextPositionFunc = "";
    }
  }

  @action
  setReactPlayerRef(player: React.RefObject<ReactPlayer>) {
    this.playerRef = player;
  }

  @action
  setStageRef(stage: React.RefObject<Stage>) {
    this.stageRef = stage;
  }

  @action setZoomEnabled(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.zoomEnabled = visible;
    } else {
      this.zoomEnabled = visible(this.zoomEnabled);
    }
  }

  @action setKeyboardVisibility(visible: boolean) {
    this.keyboardVisible = visible;
  }

  @action setKeyboardLayout(keyboardLayout: string) {
    if (keyboardLayout === "") {
      return;
    }
    const newKeyMap = this.availableKeyMaps.get(keyboardLayout);
    if (newKeyMap) {
      this.currentKeyMapName = keyboardLayout;
      this.currentKeyMap = newKeyMap;
    } else {
      const freshKeyMap = createDefaultKeyMap();
      this.availableKeyMaps.set(keyboardLayout, freshKeyMap);
      this.currentKeyMapName = keyboardLayout;
      this.currentKeyMap = freshKeyMap;
    }
  }

  @action setShowTrackTails(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showTrackTails = visible;
    } else {
      this.showTrackTails = visible(this.showTrackTails);
    }
  }

  @action setShowTrackBoxes(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showTrackBoxes = visible;
    } else {
      this.showTrackBoxes = visible(this.showTrackBoxes);
    }
  }

  @action setShowTrackFootprints(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showTrackFootprints = visible;
    } else {
      this.showTrackFootprints = visible(this.showTrackFootprints);
    }
  }

  @action setShowCountlines(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showCountlines = visible;
    } else {
      this.showCountlines = visible(this.showCountlines);
    }
  }

  @action setShowMasks(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showMasks = visible;
    } else {
      this.showMasks = visible(this.showMasks);
    }
  }

  @action setShowTurningZones(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showTurningZones = visible;
    } else {
      this.showTurningZones = visible(this.showTurningZones);
    }
  }

  @action setShowNearMissZones(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showNearMissZones = visible;
    } else {
      this.showNearMissZones = visible(this.showNearMissZones);
    }
  }

  @action setShowCVRunCrossings(visible: boolean | BoolFunc) {
    if (typeof visible === "boolean") {
      this.showCVRunCrossings = visible;
    } else {
      this.showCVRunCrossings = visible(this.showCVRunCrossings);
    }
  }

  @action setIsResultsModalOpen(visible: boolean) {
    if (!this.rootStore.urlStore.selectedComputerVisionRun) {
      this.setError("Results not available without a CV run selected");
    }
    this.isResultsModalOpen = visible;
  }

  @action setIsNotesModalOpen(visible: boolean) {
    if (!this.rootStore.urlStore.selectedValidationRun) {
      this.setError("Notes not available without a validation run selected");
    }
    this.isNotesModalOpen = visible;
  }

  @action setIsSaveModalOpen(visible: boolean) {
    this.isSaveModalOpen = visible;
  }

  @action setIsNewCVRunModalOpen(visible: boolean) {
    this.isNewCVRunModalOpen = visible;
  }

  @action setIsEditTrackHeadDisplayFuncsModalOpen(visible: boolean) {
    this.isEditTrackHeadDisplayFuncsModalOpen = visible;
  }

  @action setIsInsertCrossingModalOpen(visible: boolean) {
    this.isInsertCrossingModalOpen = visible;
  }

  @action setIsCloneCVCrossingWithPlateModalOpen(visible: boolean) {
    this.isCloneCVCrossingWithPlateModalOpen = visible;
  }

  @action setSelectedClassTypeForPlateClone(classType: ClassifyingDetectorClassTypeNumber) {
    this.selectedClassTypeForPlateClone = classType;
  }

  @action setSelectedPlateForPlateClone(plate: string) {
    this.selectedPlateForPlateClone = plate;
  }

  @action setIsInsertCrossingWithPlateModalOpen(visible: boolean) {
    this.isInsertCrossingWithPlateModalOpen = visible;
  }

  @action
  public togglePlaying() {
    this.lastProgressPerformanceTime = performance.now();
    this.lastRenderPerformanceTime = performance.now();

    this.playing = !this.playing;
    this.handlePlayUntilNextCVCrossing();
  }

  @action handlePlayUntilNextCVCrossing(isSeekingInVideo?: boolean) {
    if (this.playing && this.playUntilNextCVCrossingMode && !isSeekingInVideo) {
      if (!this.keepLastClickedCVCrossingPopperOpen) {
        this.lastClickedCVCrossingForPopper = null;
        this.lastClickedCVCrossingForPopperIsZoomed = false;
      }
      const { frameNumber, crossing } = this.getNextCVCrossingFrame();
      if (frameNumber === this.currentFrameNumber && this.errors !== "" && crossing?.frameNumber !== frameNumber) {
        this.nextCVCrossingFrame = 0;
        this.nextCVCrossingForEZMode = null;
        this.setError("");
      } else {
        this.nextCVCrossingFrame = frameNumber;
        this.nextCVCrossingForEZMode = crossing;
        if (this.keepLastClickedCVCrossingPopperOpen) {
          this.lastClickedCVCrossingForPopper = crossing;
        }
      }
    }
  }

  @action setKeepLastClickedCVCrossingPopperOpen(val: boolean) {
    this.keepLastClickedCVCrossingPopperOpen = val;
  }

  @action
  public setPlaybackRate(playbackRate: number) {
    this.playbackRateVisual = playbackRate;
    this.throttledSetPlaybackRate(playbackRate);
  }

  @action
  private setPlaybackRateUnthrottled(playbackRate: number) {
    this.playbackRateVisual = playbackRate;
    this.playbackRate = playbackRate;
  }

  @action
  public multiplyPlaybackRate(multiplier: number) {
    this.setPlaybackRateUnthrottled(Math.max(1 / 16, Math.min(this.playbackRate * multiplier, 16)));
  }

  @action setTimelineWidth(width: number) {
    this.timelineWidth = width;
  }

  @action setTimelineZoomLevel(zoom: number | NumFunc) {
    if (typeof zoom === "number") {
      this.timelineZoomLevel = zoom;
    } else {
      this.timelineZoomLevel = zoom(this.timelineZoomLevel);
    }
  }

  @action setTimelineIsDragging(isDragging: boolean | BoolFunc) {
    if (typeof isDragging === "boolean") {
      this.timelineIsDragging = isDragging;
    } else {
      this.timelineIsDragging = isDragging(this.timelineIsDragging);
    }
  }

  @action setValidatedFrameNumberOfInterestStart(val: number) {
    let newVal = val;
    if (val < 0) {
      newVal = 0;
    }
    if (val > this.totalFrames) {
      newVal = this.totalFrames;
    }
    if (newVal > this.frameNumberOfInterestEnd) {
      this.setFrameNumberOfInterestStart(this.frameNumberOfInterestEnd);
      this.setFrameNumberOfInterestEnd(newVal);
    } else {
      this.setFrameNumberOfInterestStart(newVal);
    }
  }

  @action setValidatedFrameNumberOfInterestEnd(val: number) {
    let newVal = val;
    if (val < 0) {
      newVal = 0;
    }
    if (val > this.totalFrames) {
      newVal = this.totalFrames;
    }
    if (newVal < this.frameNumberOfInterestStart) {
      this.setFrameNumberOfInterestEnd(this.frameNumberOfInterestStart);
      this.setFrameNumberOfInterestStart(newVal);
    } else {
      this.setFrameNumberOfInterestEnd(newVal);
    }
  }

  @action setFrameNumberOfInterestStart(val: number) {
    this.frameNumberOfInterestStart = val;
  }

  @action setFrameNumberOfInterestEnd(val: number) {
    this.frameNumberOfInterestEnd = val;
  }

  parseDTFFromFrameNumber(frameNumber: FrameNumber): DetectorTrackerFrame | undefined {
    const offset = this.dtfBufferOffsetsByFrameNumber.get(frameNumber);
    if (offset && this.dtfBuffer) {
      const [dtf, nextOffset] = getNextDTF(this.dtfBuffer, offset);
      return dtf;
    } else {
      return undefined;
    }
  }

  @action setLastClickedTrackHead(trackID: number) {
    const dtf = this.parseDTFFromFrameNumber(this.currentFrameNumber);
    if (dtf) {
      dtf.getTrackHeadsList().forEach(trackHead => {
        if (trackHead.getTrackNumber() === trackID) {
          this.lastClickedTrackHead = trackHead.toObject();
          this.lastClickedDTF = dtf.toObject();
        }
      });
    }
  }

  @action
  public setVideoDuration(duration: number, isDTFsInitialised: boolean, dtfLoadingFraction: number) {
    this.videoDurationSeconds = duration;
    if (this.estimatedTotalFrames === 0) {
      // Take a wild guess just so we have some frame numbers to play with
      this.estimatedTotalFrames = Math.round(this.videoDurationSeconds * 15);
    }

    if (this.actualTotalFrames === 0) {
      this.frameNumberOfInterestEnd = this.estimatedTotalFrames;
    }
    if (!isDTFsInitialised) {
      const frameInterval = round((duration * 1e6) / this.estimatedTotalFrames, 0);
      this.frameAvgIntervalMicroseconds = frameInterval;
      if (dtfLoadingFraction !== 1 || !isDTFsInitialised) {
        this.framesByVideoTimestampMicroseconds.clear();
        this.videoTimestampMicrosecondsByFrame.clear();
        // Populate some dummy frame numbers if DTFs aren't available
        for (let i = 0; i < this.estimatedTotalFrames; i++) {
          this.videoTimestampMicrosecondsByFrame.set(i, frameInterval * i);
          this.framesByVideoTimestampMicroseconds.set(frameInterval * i, i);
        }
      }
    }
  }

  @computed get totalFrames(): number {
    return this.actualTotalFrames > 0 ? this.actualTotalFrames : this.estimatedTotalFrames;
  }

  @computed get progressFraction(): number {
    return this.currentFrameNumber / this.totalFrames;
  }

  @computed get timelineLinePosX(): number {
    return this.timelineWidth * this.progressFraction;
  }

  @computed get timelineZoomBoxWidth(): number {
    return this.timelineWidth * this.timelineZoomLevel;
  }

  @computed get timelineZoomBoxXRight(): number {
    return Math.max(this.timelineWidth, this.timelineLinePosX - this.timelineZoomBoxWidth / 2);
  }

  @computed get timelineZoomBoxXLeft(): number {
    return Math.min(
      Math.max(0, this.timelineLinePosX - this.timelineZoomBoxWidth / 2),
      this.timelineZoomBoxXRight - this.timelineZoomBoxWidth
    );
  }

  @computed get timelineZoomedTimeCursorX(): number {
    return this.timelineWidth * ((this.timelineLinePosX - this.timelineZoomBoxXLeft) / this.timelineZoomBoxWidth);
  }

  @computed get timelineLowerBound(): number {
    return this.timelineZoomBoxXLeft / this.timelineWidth;
  }

  @computed get timelineUpperBound(): number {
    return (this.timelineZoomBoxXLeft + this.timelineZoomBoxWidth) / this.timelineWidth;
  }

  @action
  public setVideoDimensions(width: number, height: number) {
    this.videoWidth = width;
    this.videoHeight = height;
  }

  @action
  public setVideo(video: Video) {
    if (this.videoURL !== video.downloadUrl && video.downloadUrl) {
      if (this.estimatedTotalFrames === 0) {
        this.estimatedTotalFrames = (video.endFrame ?? 0) - (video.startFrame ?? 0);
        this.frameNumberOfInterestEnd = this.estimatedTotalFrames;
      }
      this.actualTotalFrames = 0;
      this.thumbnailsByDuration.clear();
      this.videoDurationSeconds = 0;
      this.videoURL = video.downloadUrl;
      this.dtfBuffer = null;
      this.lastClickedCVCrossingForPopper = null;
      this.lastClickedCVCrossingForPopperIsZoomed = false;
      this.lastClickedValidationRunCrossingForPopper = null;
      this.lastClickedValidationRunCrossingForPopperIsZoomed = false;
      this.countlineGeometries.clear();
      this.tailGeometries.clear();
      this.tailColours.clear();
      this.tailStartFrames.clear();
      this.activeTracksByFrame.clear();
      this.boundingBoxesByFrame.clear();
      this.mouseOverByTrack.clear();
      this.mouseOverByCountlineID.clear();
      this.unsnappedUnixTimestampsByFrame.clear();
      this.framesByVideoTimestampMicroseconds.clear();
      this.videoTimestampMicrosecondsByFrame.clear();
      this.frameAvgIntervalMicroseconds = 62500;
      this.dtfBufferOffsetsByFrameNumber.clear();
      this.seenClasses = [];

      if (video.triggerReason.includes("NEAR_MISS")) {
        this.trackHeadDisplayFuncs.trackHeadTextExpressionOnly = false;
        this.setTrackHeadTextFunc(nearMissTrackHeadTextFunc);
        this.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly = true;
        this.setTrackHeadShouldDisplayFunc(nearMissTrackHeadShouldDisplayFunc);
        this.setShowCountlines(false);
        this.setShowTrackTails(false);
      } else {
        this.trackHeadDisplayFuncs.trackHeadTextExpressionOnly = true;
        this.setTrackHeadTextFunc(defaultTrackHeadTextFunc);
        this.trackHeadDisplayFuncs.trackHeadShouldDisplayExpressionOnly = true;
        this.setTrackHeadShouldDisplayFunc("true");
        this.trackHeadDisplayFuncs.trackHeadShouldDisplayFootprintExpressionOnly = true;
        this.setTrackHeadShouldDisplayFootprintFunc("true");
        this.setShowCountlines(true);
        this.setShowTrackTails(true);
      }
    }
  }

  @action setUse1920x1080(value: boolean) {
    this.use1920x1080 = value;
  }

  @action handleTimelineClick(e: PIXI.InteractionEvent) {
    e.stopPropagation();
    this.setTimelineIsDragging(false);
    this.seekToFraction(e.data.global.x / this.timelineWidth);
  }

  @action handleTimelineClickZoomed(e: PIXI.InteractionEvent) {
    e.stopPropagation();
    const fraction = e.data.global.x / this.timelineWidth;
    this.seekToFraction(fraction * (this.timelineUpperBound - this.timelineLowerBound) + this.timelineLowerBound);
  }

  @action handleCVCrossingClick(e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) {
    this.lastClickedCVCrossingForPopper = crossing;
    this.lastClickedCVCrossingForPopperIsZoomed = false;
    this.handleTimelineClick(e);
  }

  @action handleZoomedCVCrossingClick(e: PIXI.InteractionEvent, crossing: CVRunCountlineCrossing) {
    this.lastClickedCVCrossingForPopper = crossing;
    this.lastClickedCVCrossingForPopperIsZoomed = true;
    this.handleTimelineClickZoomed(e);
  }

  @action handleValidationCrossingClick(e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) {
    if (e.data.originalEvent.shiftKey) {
      const [clValRun, err] = this.getCountlineValidationRun();
      if (err || !clValRun) {
        this.setError(err);
        return;
      }
      this.rootStore.countlineValidationRunStore.deleteCountlineValidationCrossing(clValRun.id, crossing.frameNumber);
      e.stopPropagation();
    } else {
      this.lastClickedValidationRunCrossingForPopper = crossing;
      this.lastClickedValidationRunCrossingForPopperIsZoomed = false;
      this.handleTimelineClick(e);
    }
  }

  @action handleZoomedValidationCrossingClick(e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) {
    if (e.data.originalEvent.shiftKey) {
      const [clValRun, err] = this.getCountlineValidationRun();
      if (err || !clValRun) {
        this.setError(err);
        return;
      }
      this.rootStore.countlineValidationRunStore.deleteCountlineValidationCrossing(clValRun.id, crossing.frameNumber);
      e.stopPropagation();
    } else {
      this.lastClickedValidationRunCrossingForPopper = crossing;
      this.lastClickedValidationRunCrossingForPopperIsZoomed = true;
      this.handleTimelineClickZoomed(e);
    }
  }

  @action clearLastClickedCVCrossingForPopper() {
    this.lastClickedCVCrossingForPopper = null;
    this.lastClickedCVCrossingForPopperIsZoomed = false;
  }

  @action clearLastClickedValidationRunCrossingForPopper() {
    this.lastClickedValidationRunCrossingForPopper = null;
    this.lastClickedValidationRunCrossingForPopperIsZoomed = false;
  }

  @action deleteLastClickedValidationRunCrossing() {
    if (this.lastClickedValidationRunCrossingForPopper) {
      const [clValRun, err] = this.getCountlineValidationRun();
      if (err || !clValRun) {
        this.setError(err);
        return;
      }
      this.rootStore.countlineValidationRunStore.deleteCountlineValidationCrossing(
        clValRun.id,
        this.lastClickedValidationRunCrossingForPopper.frameNumber
      );
    }
    this.lastClickedValidationRunCrossingForPopper = null;
    this.lastClickedValidationRunCrossingForPopperIsZoomed = false;
  }

  @action
  public addThumbnail(duration: number, thumbnail: string) {
    const image = new Image();
    image.src = thumbnail;
    this.thumbnailsByDuration.set(duration, image);
  }

  @action
  public setCurrentFrame(frameNumber: number) {
    this.currentFrameNumber = frameNumber;
    this.throttledSetCurrentFrameNumber(frameNumber);
  }

  private throttledSetCurrentFrameNumber = _.throttle(action(this.setThrottledCurrentFrameNumber.bind(this)), 500, {
    leading: true,
  });

  @action
  setThrottledCurrentFrameNumber(frameNumber: FrameNumber) {
    this.throttledCurrentFrameNumber = frameNumber;
  }

  @action
  public setCurrentTime(playedTimeSeconds: number, loadedTimeSeconds: number) {
    this.videoCurrentTimeSeconds = playedTimeSeconds;
    this.videoLoadedTimeSeconds = loadedTimeSeconds;
    const latencyAdjustment = this.playing
      ? Math.max(0, ((this.lastRenderPerformanceTime - this.lastProgressPerformanceTime) * this.playbackRate) / 1000)
      : 0;

    this.lastProgressPerformanceTime = performance.now();
    const prevFrameNumber = this.currentFrameNumber;
    this.setCurrentFrame(
      this.getFrameFromVideoTimestampMicroseconds(
        Math.max(0, Math.min(playedTimeSeconds + latencyAdjustment, this.videoDurationSeconds)) * 1e6
      )
    );
    if (this.playUntilNextCVCrossingMode && this.nextCVCrossingFrame !== 0) {
      if (this.nextCVCrossingFrame < this.currentFrameNumber && this.nextCVCrossingFrame >= prevFrameNumber) {
        (this.playerRef?.current?.getInternalPlayer() as HTMLVideoElement | undefined)?.pause();
        this.playing = false;
        this.lastClickedCVCrossingForPopper = this.nextCVCrossingForEZMode;
        if (!navigator.userAgent.startsWith("Mac") && this.currentFrameNumber !== this.nextCVCrossingFrame) {
          const oldPlaybackRate = this.playbackRate;
          this.seekToFrame(this.nextCVCrossingFrame);
          this.playbackRate = oldPlaybackRate;
        } else {
          this.play1Frame(1 / 16, 60);
        }
      }
    }
  }

  @action
  public setMouseOverTrack(trackID: TrackID, val: boolean) {
    this.mouseOverByTrack.set(trackID, val);
  }

  @action
  public setMouseOverCountline(countlineID: CountlineID, val: boolean) {
    this.mouseOverByCountlineID.set(countlineID, val);
  }

  denormalisePoint = (point: Point, width: number, height: number): Point => {
    const { x, y } = point;
    return {
      x: (x * width) / 16384,
      y: (y * height) / 16384,
    };
  };

  @action
  public clearCountlineGeometries() {
    this.countlineGeometries.clear();
  }

  @action
  public clearCountlineGeometry(countlineID: CountlineID) {
    this.countlineGeometries.delete(countlineID);
  }

  @action
  public setCountlineGeometryFromConfig(countline: CountlineConfig, width: number, height: number) {
    const geometry: number[] = [];
    const points = countline.points.map(point => this.denormalisePoint(point, width, height));
    const pairs = _.zip(points, points.slice(1));
    let i = 0;
    pairs.forEach(([point1, point2]) => {
      if (countline.ignore_alternate_line_segments) {
        if (point1 && point2 && i % 2 === 0) {
          geometry.push(point1.x, point1.y);
          geometry.push(point2.x, point2.y);
        }
      } else {
        if (point1 && point2) {
          geometry.push(point1.x, point1.y);
          geometry.push(point2.x, point2.y);
        }
      }
      i += 1;
    });

    this.countlineGeometries.set(countline.countline_id, geometry);
  }

  @action
  public setImageSpaceMasksFromConfig(MaskZone: ZoneConfig, width: number, height: number) {
    const geometry: number[] = [];
    const points = MaskZone.geometry_ring.map(point => this.denormalisePoint(point, width, height));
    const pairs = _.zip(points, points.slice(1));
    pairs.forEach(([point1, point2]) => {
      if (point1 && point2) {
        geometry.push(point1.x, point1.y);
        geometry.push(point2.x, point2.y);
      }
    });

    const imageSpaceMask: ImageSpaceZone = {
      zoneId: MaskZone.zone_id,
      geometry,
      isLethalMask: MaskZone.is_lethal_mask,
      deleteInsideZone: MaskZone.delete_inside_zone,
      zoneCheckPointProportion: MaskZone.zone_check_point_proportion,
    };

    this.imageSpaceMasks.set(imageSpaceMask.zoneId, imageSpaceMask);
  }

  @action
  public setImageSpaceTurningZonesFromConfig(TurningZone: ZoneConfig, width: number, height: number) {
    const geometry: number[] = [];
    const points = TurningZone.geometry_ring.map(point => this.denormalisePoint(point, width, height));
    const pairs = _.zip(points, points.slice(1));
    pairs.forEach(([point1, point2]) => {
      if (point1 && point2) {
        geometry.push(point1.x, point1.y);
        geometry.push(point2.x, point2.y);
      }
    });

    const imageSpaceTurningZone: ImageSpaceZone = {
      zoneId: TurningZone.zone_id,
      geometry,
      isLethalMask: false,
      deleteInsideZone: false,
      zoneCheckPointProportion: TurningZone.zone_check_point_proportion,
    };

    this.imageSpaceTurningZones.set(imageSpaceTurningZone.zoneId, imageSpaceTurningZone);
  }

  getFrameFromVideoTimestampMicroseconds(videoTimestampMicro: number): FrameNumber {
    const snapped = this.snapTimestampMicro(videoTimestampMicro);
    const frameNumber = this.framesByVideoTimestampMicroseconds.get(snapped);
    if (frameNumber === undefined) {
      if (snapped === 0) {
        return 0;
      }
      console.error(`WTF - timestamp ${videoTimestampMicro} (snapped = ${snapped}) gave no frame???`);
      throw new Error(`WTF - timestamp ${videoTimestampMicro} (snapped = ${snapped}) gave no frame???`);
    }
    return frameNumber;
  }

  @action
  public seekToTimeByOffset(offsetSeconds: number) {
    this.playerRef?.current?.seekTo(
      Math.min(this.videoDurationSeconds, Math.max(this.videoCurrentTimeSeconds + offsetSeconds, 0)),
      "seconds"
    );
    if (!this.playerRef?.current) {
      console.error("NO PLAYER REF???");
    }
    this.play1Frame(1 / 16, 60);
  }

  @action
  public seekToFrameByOffset(offset: number) {
    this.playing = false;
    const upperLimit = this.totalFrames;

    const newFrameNumber = Math.max(
      0,
      Math.min(upperLimit, this.currentFrameNumber + offset + (this.currentFrameNumber === 0 ? 1 : 0))
    );
    this.seekToFrame(newFrameNumber);
  }

  @action seekToFraction(fraction: number) {
    if (!this.playerRef?.current) {
      console.error("NO PLAYER REF???");
    }
    const oldBoolVal = this.playUntilNextCVCrossingMode;
    this.setPlayUntilNextCVCrossingMode(false);
    this.playerRef?.current?.seekTo(Math.min(0.9999999, Math.max(0.0000001, fraction)), "fraction");
    this.play1Frame(1, 60, oldBoolVal);
  }

  @action
  public play1Frame(playbackRate: number, timeout: number, shouldSetPlayUntilNextCrossing?: boolean) {
    // Make the video update to the current frame by playing for 30ms
    const oldPlaying = this.playing;
    const oldPlaybackRate = this.playbackRate;

    if (!this.playerRef?.current) {
      console.error("NO PLAYER REF???");
    }
    // DANGEROUS!
    // Bypass the react props to prevent re-renders, just hijack the internal video player to play for 30ms
    const video = this.playerRef?.current?.getInternalPlayer() as HTMLVideoElement;
    if (video) {
      video.playbackRate = playbackRate;

      const setPlaying = () => {
        video.playbackRate = oldPlaybackRate;
        if (!oldPlaying) {
          video.pause();
        }
        this.handlePlayUntilNextCVCrossing(shouldSetPlayUntilNextCrossing);
      };

      video
        .play()
        .catch(e => {
          if (e instanceof DOMException) {
            // This is fine
          } else {
            console.error(e);
            console.error(e.stackTrace);
          }
        })
        .finally(() => {
          if (shouldSetPlayUntilNextCrossing) {
            this.setPlayUntilNextCVCrossingMode(shouldSetPlayUntilNextCrossing);
          }
          return setTimeout(setPlaying, timeout);
        });
    } else {
      console.error("No video???");
    }
  }

  @action
  public seekToFrame = (frameNumber: number) => {
    const videoTimestamp = frameNumber === 0 ? 0 : this.videoTimestampMicrosecondsByFrame.get(frameNumber);
    if (videoTimestamp === undefined) {
      console.error(`no video time found for frame ${frameNumber}`);
      return;
    }

    this.playerRef?.current?.seekTo(videoTimestamp / 1e6, "seconds");
    this.setCurrentFrame(frameNumber);
    this.play1Frame(1 / 16, 30);
  };

  @action.bound setError(err: string) {
    if (this.errors === err && err !== "") {
      this.errors = err + "...";
    } else {
      this.errors = err;
    }
  }

  @action setSnackbarText(text: string) {
    this.snackbarText = text;
  }

  snapTimestampMicro(videoTimestamp: number): number {
    return round(videoTimestamp / this.frameAvgIntervalMicroseconds, 0) * this.frameAvgIntervalMicroseconds;
  }

  @computed get thumbailsInOrder(): HTMLImageElement[] {
    // TODO - limit the number of them by snapping to the desired timestamps
    const thumbImages: { img: HTMLImageElement; timestamp: number }[] = [];
    this.thumbnailsByDuration.forEach((thumbnail, time) => {
      thumbImages.push({ img: thumbnail, timestamp: time });
    });

    return thumbImages
      .sort((a, b) => {
        return a.timestamp - b.timestamp;
      })
      .map(thumb => {
        return thumb.img;
      });
  }

  @action
  public toggleZoomEnabled = () => {
    this.zoomEnabled = !this.zoomEnabled;
  };

  @action public setKeyboardVisible = () => {
    this.setKeyboardVisibility(true);
  };

  @action public toggleShowTrackTails = () => {
    this.setShowTrackTails(prev => !prev);
  };

  @action public toggleShowTrackBoxes = () => {
    this.setShowTrackBoxes(prev => !prev);
  };

  @action public toggleShowTrackFootprints = () => {
    this.setShowTrackFootprints(prev => !prev);
  };

  @action public toggleShowCountlines = () => {
    this.setShowCountlines(prev => !prev);
  };

  @action public toggleShowMasks = () => {
    this.setShowMasks(prev => !prev);
  };

  @action public toggleShowTurningZones = () => {
    this.setShowTurningZones(prev => !prev);
  };

  @action public toggleShowNearMissZones = () => {
    this.setShowNearMissZones(prev => !prev);
  };

  @action public setVideoBlur = (value: number) => {
    this.videoBlur = value;
  };

  @action public setRunningTotalsTextAlpha = (value: number) => {
    this.runningTotalsTextAlpha = value;
  };

  @action
  public addCrossingWithPlate(classType: ClassifyingDetectorClassTypeNumber, clockwise: boolean) {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }

    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    this.playing = false;
    this.lastClassForAddingCrossing = classType;
    this.lastClockwiseForAddingCrossing = clockwise;
    this.setIsInsertCrossingWithPlateModalOpen(true);
  }

  @action saveCrossingWithPlate(plate: string) {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    if (this.lastClockwiseForAddingCrossing === null) {
      this.setError("Inconsistent state - playerUIStore got an undefined lastClockwiseForAddingCrossing");
      return;
    }
    if (this.lastClassForAddingCrossing === null) {
      this.setError("Inconsistent state - playerUIStore got an undefined lastClassForAddingCrossing");
      return;
    }
    this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
      clValRun.id,
      this.currentFrameNumber,
      Math.round(this.videoCurrentTimeSeconds * 1e6),
      this.lastClassForAddingCrossing,
      this.lastClockwiseForAddingCrossing,
      plate
    );
    this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
      clValRun.id,
      this.currentFrameNumber,
      Math.round(this.videoCurrentTimeSeconds * 1e6),
      ClassifyingDetectorClassTypes.LICENSE_PLATE,
      this.lastClockwiseForAddingCrossing,
      plate,
      this.lastClassForAddingCrossing
    );
  }

  @action
  public addCrossing(classType: ClassifyingDetectorClassTypeNumber, clockwise: boolean) {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
      clValRun.id,
      this.currentFrameNumber,
      Math.round(this.videoCurrentTimeSeconds * 1e6),
      classType,
      clockwise,
      ""
    );
    if (!this.playing) {
      this.play1Frame(1, 30);
    }
    this.seenClasses.push(classType);
    this.seenClasses = _.uniq(this.seenClasses);
  }

  @action
  public clonePreviousCVCrossing() {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }

    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    const lastCrossing = _.last(
      this.getCVRunCrossings().filter(crossing => crossing.frameNumber <= this.currentFrameNumber)
    );
    if (lastCrossing) {
      this.cloneCVCrossing(lastCrossing);
    } else {
      this.setError("No previous CV crossing found");
    }
  }

  @action.bound cloneCVCrossing(cvCrossing: CVRunCountlineCrossing) {
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.validationRunCountlineCrossings.get(cvCrossing.frameNumber)) {
      this.setError(`Crossing already exists at frame ${cvCrossing.frameNumber}`);
      const previousError = this.errors;
      setTimeout(() => (previousError === this.errors ? this.setError(``) : null), 2000);
      return;
    }

    if (cvCrossing.otherPlates.length === 0) {
      if (cvCrossing.trackClass === ClassifyingDetectorClassTypes.LICENSE_PLATE) {
        // If it's a plate, add both the plate and vehicle classes
        this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
          clValRun.id,
          cvCrossing.frameNumber,
          Math.round(this.videoCurrentTimeSeconds * 1e6),
          cvCrossing.trackClass,
          cvCrossing.clockwise,
          cvCrossing.topRankedPlate ?? "",
          cvCrossing.anprVehicleClass
        );
        if (cvCrossing.anprVehicleClass) {
          this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
            clValRun.id,
            cvCrossing.frameNumber,
            Math.round(this.videoCurrentTimeSeconds * 1e6),
            cvCrossing.anprVehicleClass,
            cvCrossing.clockwise,
            cvCrossing.topRankedPlate ?? ""
          );
        }
      } else {
        if (cvCrossing.topRankedPlate) {
          // If it's a vehicle class with a plate read, add both the plate and the vehicle
          this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
            clValRun.id,
            cvCrossing.frameNumber,
            Math.round(this.videoCurrentTimeSeconds * 1e6),
            cvCrossing.trackClass,
            cvCrossing.clockwise,
            cvCrossing.topRankedPlate
          );

          this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
            clValRun.id,
            cvCrossing.frameNumber,
            Math.round(this.videoCurrentTimeSeconds * 1e6),
            ClassifyingDetectorClassTypes.LICENSE_PLATE,
            cvCrossing.clockwise,
            cvCrossing.topRankedPlate,
            cvCrossing.anprVehicleClass
          );
        } else {
          this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
            clValRun.id,
            cvCrossing.frameNumber,
            Math.round(this.videoCurrentTimeSeconds * 1e6),
            cvCrossing.trackClass,
            cvCrossing.clockwise,
            ""
          );
        }
      }
    } else {
      if (this.isCloneCVCrossingWithPlateModalOpen && this.selectedPlateForPlateClone) {
        const cvCrossingClone = JSON.parse(JSON.stringify(cvCrossing)) as CVRunCountlineCrossing;
        this.cloneCVCrossingWithPlate(this.selectedPlateForPlateClone, cvCrossingClone);
        this.setIsCloneCVCrossingWithPlateModalOpen(false);
        this.lastCVCrossingForClone = null;
        this.setSelectedClassTypeForPlateClone(0);
        this.setSelectedPlateForPlateClone("");
        this.playing = true;
      } else {
        this.playing = false;
        this.lastCVCrossingForClone = cvCrossing;
        if (cvCrossing.anprVehicleClass) {
          this.setSelectedClassTypeForPlateClone(cvCrossing.anprVehicleClass);
        }
        this.setIsCloneCVCrossingWithPlateModalOpen(true);
      }
    }
  }

  @action
  public cloneCVCrossingWithPlate(plate: string, crossing: CVRunCountlineCrossing) {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    if (crossing.trackClass === ClassifyingDetectorClassTypes.LICENSE_PLATE) {
      this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
        clValRun.id,
        crossing.frameNumber,
        Math.round(this.videoCurrentTimeSeconds * 1e6),
        crossing.trackClass,
        crossing.clockwise,
        plate,
        crossing.anprVehicleClass
      );
      if (crossing.anprVehicleClass) {
        this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
          clValRun.id,
          crossing.frameNumber,
          Math.round(this.videoCurrentTimeSeconds * 1e6),
          crossing.anprVehicleClass,
          crossing.clockwise,
          plate
        );
      }
    } else {
      this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
        clValRun.id,
        crossing.frameNumber,
        Math.round(this.videoCurrentTimeSeconds * 1e6),
        ClassifyingDetectorClassTypes.LICENSE_PLATE,
        crossing.clockwise,
        plate,
        crossing.anprVehicleClass
      );
      this.rootStore.countlineValidationRunStore.addCountlineValidationCrossing(
        clValRun.id,
        crossing.frameNumber,
        Math.round(this.videoCurrentTimeSeconds * 1e6),
        crossing.trackClass,
        crossing.clockwise,
        plate,
        crossing.anprVehicleClass
      );
    }
  }

  @action
  public deleteLastCrossing(classType?: number, clockwise?: boolean) {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    const crossings = this.getValidationRunCrossings(clValRun);
    // get all matching crossing before current frame
    const allowedCrossings = crossings.filter(
      c =>
        (!classType || c.detectionClassV2Id === classType) &&
        (clockwise === undefined || c.clockwise === clockwise) &&
        c.frameNumber <= this.currentFrameNumber
    );
    const last = _.last(allowedCrossings);
    if (last) {
      const maxFramesAgo = 250;
      const framesAgo = this.currentFrameNumber - last.frameNumber;
      if (framesAgo > maxFramesAgo) {
        this.setError(
          `Last crossing is ${framesAgo} frames ago - if you're sure you want to delete it,\nrewind to within ${maxFramesAgo} frames of it`
        );
      }
      this.rootStore.countlineValidationRunStore.deleteCountlineValidationCrossing(clValRun.id, last.frameNumber);
    } else {
      if (!classType) {
        this.setError(`No crossing found, cannot delete`);
      } else {
        this.setError(
          `No ${clockwise !== undefined ? (clockwise ? "clockwise" : "anti-clockwise") : ""} ${_.lowerFirst(
            classLookup[classType]
          )} crossing found, cannot delete`
        );
      }
    }
  }

  @action
  public deleteNextCrossing() {
    if (this.readOnlyMode) {
      this.setError("Can't add crossing in view only mode");
      return;
    }
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return;
    }
    if (clValRun.status === "COMPLETED") {
      this.setError("Can't add crossing to a completed countline validation run");
      return;
    }
    const crossings = this.getValidationRunCrossings(clValRun);

    // get all matching crossing before current frame
    const allowedCrossings = crossings.filter(c => c.frameNumber >= this.currentFrameNumber);
    const first = _.first(allowedCrossings);
    if (first) {
      this.rootStore.countlineValidationRunStore.deleteCountlineValidationCrossing(clValRun.id, first.frameNumber);
    } else {
      this.setError(`No crossing found, cannot delete`);
    }
  }

  public getValidationRunCrossings(clValRun: CountlineValidationRun): ValidationRunCountlineCrossing[] {
    const crossings: ValidationRunCountlineCrossing[] = [];
    clValRun.validationRunCountlineCrossings.forEach(v => {
      crossings.push(v);
    });
    crossings.sort((a, b) => a.actualFrameNumber - b.actualFrameNumber);
    return crossings;
  }

  public getNextValidationRunCrossingFrame(): FrameNumber {
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return this.currentFrameNumber;
    }
    const crossings = this.getValidationRunCrossings(clValRun);
    const firstCrossing = _.first(crossings.filter(crossing => crossing.frameNumber > this.currentFrameNumber));
    if (firstCrossing) {
      return firstCrossing.frameNumber;
    } else {
      this.setError(`No crossings found after frame ${this.currentFrameNumber}`);
      return this.currentFrameNumber;
    }
  }

  public getPreviousValidationRunCrossingFrame(): FrameNumber {
    const [clValRun, err] = this.getCountlineValidationRun();
    if (err || !clValRun) {
      this.setError(err);
      return this.currentFrameNumber;
    }
    const crossings = this.getValidationRunCrossings(clValRun);
    const lastCrossing = _.last(crossings.filter(crossing => crossing.frameNumber <= this.currentFrameNumber));
    if (lastCrossing) {
      return lastCrossing.frameNumber;
    } else {
      this.setError(`No crossings found before frame ${this.currentFrameNumber}`);
      return this.currentFrameNumber;
    }
  }

  @computed
  public get CVRunCrossingsRunningTotals(): Map<
    FrameNumber,
    Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
  > {
    const cvRunRunningTotals: Map<
      FrameNumber,
      Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
    > = new Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>();
    const selectedCVRun = this.rootStore.urlStore.selectedComputerVisionRun;
    if (!selectedCVRun) {
      return cvRunRunningTotals;
    }
    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(selectedCVRun);
    if (!cvRun) {
      return cvRunRunningTotals;
    }
    const seenClassesMap: Map<ClassifyingDetectorClassTypeNumber, boolean> = new Map();

    this.seenClasses.forEach(classNumber => seenClassesMap.set(classNumber, true));

    cvRun.countlineCrossings.forEach(crossingsByFrameNumber => {
      crossingsByFrameNumber.forEach(crossing => {
        seenClassesMap.set(crossing.trackClass, true);
      });
    });

    const accumulator: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();

    cvRun.countlineCrossings.forEach((crossingsByFrameNumber, countlineID) => {
      const accClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accAntiClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
      seenClassesMap.forEach((b, classNumber) => {
        accAntiClockwise.set(classNumber, 0);
        accClockwise.set(classNumber, 0);
      });
      accByDirection.set(true, accClockwise);
      accByDirection.set(false, accAntiClockwise);
      accumulator.set(countlineID, accByDirection);
    });

    for (let frameNumber = 0; frameNumber <= this.totalFrames; frameNumber++) {
      const clCrossings: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();
      cvRunRunningTotals.set(frameNumber, clCrossings);
      cvRun.countlineCrossings.forEach((crossingsByFrameNumber, countlineID) => {
        const clCrossingsByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
        clCrossings.set(countlineID, clCrossingsByDirection);

        const clAccumulator = accumulator.get(countlineID);
        if (clAccumulator) {
          const clAccumulatorClockwise = clAccumulator.get(true);
          const clAccumulatorAntiClockwise = clAccumulator.get(false);

          if (clAccumulatorClockwise && clAccumulatorAntiClockwise) {
            const crossing = crossingsByFrameNumber.get(frameNumber);
            if (crossing) {
              const maybeCurrentCount = crossing.clockwise
                ? clAccumulatorClockwise.get(crossing.trackClass)
                : clAccumulatorAntiClockwise.get(crossing.trackClass);
              const currentCount = maybeCurrentCount !== undefined ? maybeCurrentCount : 0;
              if (crossing.clockwise) {
                clAccumulatorClockwise.set(crossing.trackClass, currentCount + 1);
              } else {
                clAccumulatorAntiClockwise.set(crossing.trackClass, currentCount + 1);
              }
            }
            const clCrossingsByClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
              clAccumulatorClockwise
            );
            const clCrossingsByAnticlockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
              clAccumulatorAntiClockwise
            );

            clCrossingsByDirection.set(true, clCrossingsByClockwise);
            clCrossingsByDirection.set(false, clCrossingsByAnticlockwise);
          }
        }
      });
    }
    return cvRunRunningTotals;
  }

  @computed
  public get ValidationRunCrossingsRunningTotals(): Map<
    FrameNumber,
    Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
  > {
    const validationRunTotals: Map<
      FrameNumber,
      Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
    > = new Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>();
    const selectedValRun = this.rootStore.urlStore.selectedValidationRun;
    if (!selectedValRun) {
      return validationRunTotals;
    }
    const valRun = this.rootStore.validationRunStore.validationRuns.get(selectedValRun);
    if (!valRun) {
      return validationRunTotals;
    }

    const seenClassesMap: Map<ClassifyingDetectorClassTypeNumber, boolean> = new Map();

    this.seenClasses.forEach(classNumber => seenClassesMap.set(classNumber, true));

    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(valRun.cvRunID);

    // Get all classes seen by CV Run
    if (cvRun) {
      cvRun.countlineCrossings.forEach(crossingsByFrameNumber => {
        crossingsByFrameNumber.forEach(crossing => {
          seenClassesMap.set(crossing.trackClass, true);
        });
      });
    }

    // Get all classes seen by all countline validation runs
    valRun.countlineValidationRuns?.forEach(clValRunID => {
      const clValRun = this.rootStore.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
      if (clValRun) {
        clValRun.validationRunCountlineCrossings.forEach(crossing => {
          seenClassesMap.set(crossing.detectionClassV2Id, true);
        });
      }
    });

    const accumulator: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();

    valRun.countlineValidationRuns?.forEach(clValRunID => {
      // TODO - I hate this
      const countlineID = parseInt(clValRunID.split("-")[1], 10);
      const accClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accAntiClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
      seenClassesMap.forEach((b, classNumber) => {
        accAntiClockwise.set(classNumber, 0);
        accClockwise.set(classNumber, 0);
      });
      accByDirection.set(true, accClockwise);
      accByDirection.set(false, accAntiClockwise);
      accumulator.set(countlineID, accByDirection);
    });

    for (let frameNumber = 0; frameNumber <= this.totalFrames; frameNumber++) {
      const clCrossings: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();
      validationRunTotals.set(frameNumber, clCrossings);

      valRun.countlineValidationRuns?.forEach(clValRunID => {
        // TODO - I hate this
        const countlineID = parseInt(clValRunID.split("-")[1], 10);
        const clValRun = this.rootStore.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
        if (clValRun) {
          const clCrossingsByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
          clCrossings.set(countlineID, clCrossingsByDirection);

          const clAccumulator = accumulator.get(countlineID);
          const crossingsByFrameNumber = clValRun.validationRunCountlineCrossings;
          if (crossingsByFrameNumber && clAccumulator) {
            const clAccumulatorClockwise = clAccumulator.get(true);
            const clAccumulatorAntiClockwise = clAccumulator.get(false);

            if (clAccumulatorClockwise && clAccumulatorAntiClockwise) {
              const crossing = crossingsByFrameNumber.get(frameNumber);
              if (crossing) {
                const maybeCurrentCount = crossing.clockwise
                  ? clAccumulatorClockwise.get(crossing.detectionClassV2Id)
                  : clAccumulatorAntiClockwise.get(crossing.detectionClassV2Id);
                const currentCount = maybeCurrentCount !== undefined ? maybeCurrentCount : 0;
                if (crossing.clockwise) {
                  clAccumulatorClockwise.set(crossing.detectionClassV2Id, currentCount + 1);
                } else {
                  clAccumulatorAntiClockwise.set(crossing.detectionClassV2Id, currentCount + 1);
                }
              }
              const clCrossingsByClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
                clAccumulatorClockwise
              );
              const clCrossingsByAnticlockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
                clAccumulatorAntiClockwise
              );

              clCrossingsByDirection.set(true, clCrossingsByClockwise);
              clCrossingsByDirection.set(false, clCrossingsByAnticlockwise);
            }
          }
        }
      });
    }
    return validationRunTotals;
  }

  @computed
  public get currentCVRunCrossingsRunningTotals(): Map<
    CountlineID,
    Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>
  > {
    const maybe = this.CVRunCrossingsRunningTotals.get(this.currentFrameNumber);
    if (maybe) {
      return maybe;
    } else {
      return new Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>();
    }
  }

  @computed
  public get currentValidationRunCrossingsRunningTotals(): Map<
    CountlineID,
    Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>
  > {
    const maybe = this.ValidationRunCrossingsRunningTotals.get(this.currentFrameNumber);
    if (maybe) {
      return maybe;
    } else {
      return new Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>();
    }
  }

  public getCVRunCrossings(): CVRunCountlineCrossing[] {
    const selectedCVRun = this.rootStore.urlStore.selectedComputerVisionRun;
    if (!selectedCVRun) {
      this.setError("No CV run ID selected");
      return [];
    }
    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(selectedCVRun);
    if (!cvRun) {
      this.setError(`CV run with ID ${selectedCVRun} not found`);
      return [];
    }

    const selectedCountlineID = this.rootStore.urlStore.selectedCountline;
    const crossings: CVRunCountlineCrossing[] = [];
    if (selectedCountlineID) {
      cvRun.countlineCrossings.get(selectedCountlineID)?.forEach(v => {
        crossings.push(v);
      });
    } else {
      this.setError("No countline selected");
      return [];
    }
    crossings.sort((a, b) => a.actualFrameNumber - b.actualFrameNumber);
    return crossings;
  }

  public getCVRunTurningMovements(): ComputerVisionRunTurningMovements | undefined {
    const selectedCVRun = this.rootStore.urlStore.selectedComputerVisionRun;
    if (!selectedCVRun) {
      this.setError("No CV run ID selected");
      return undefined;
    }
    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(selectedCVRun);
    if (!cvRun) {
      this.setError(`CV run with ID ${selectedCVRun} not found`);
      return undefined;
    }

    return cvRun.turningMovements && cvRun.turningMovements.size > 0 ? cvRun.turningMovements : undefined;
  }

  public getNextCVCrossingFrame(): { frameNumber: FrameNumber; crossing: CVRunCountlineCrossing | null } {
    const firstCrossing = this.getCVRunCrossings().find(crossing => {
      return (
        this.playUntilNextCVCrossingClasses.has(crossing.trackClass) && crossing.frameNumber > this.currentFrameNumber
      );
    });
    if (firstCrossing) {
      return { frameNumber: firstCrossing.frameNumber, crossing: firstCrossing };
    } else {
      if (this.errors === "") {
        this.setError(`No crossings found after frame ${this.currentFrameNumber}`);
      }
      return { frameNumber: this.currentFrameNumber, crossing: null };
    }
  }

  public getPreviousCVCrossingFrame(): { frameNumber: FrameNumber; crossing: CVRunCountlineCrossing | null } {
    const lastCrossing = _.last(
      this.getCVRunCrossings().filter(
        crossing =>
          this.playUntilNextCVCrossingClasses.has(crossing.trackClass) &&
          crossing.frameNumber <= this.currentFrameNumber
      )
    );
    if (lastCrossing) {
      return { frameNumber: lastCrossing.frameNumber, crossing: lastCrossing };
    } else {
      if (this.errors === "") {
        this.setError(`No crossings found before frame ${this.currentFrameNumber}`);
      }
      return { frameNumber: this.currentFrameNumber, crossing: null };
    }
  }

  public getCountlineValidationRun(): [CountlineValidationRun | undefined, string] {
    const selectedValidationRunID = this.rootStore.urlStore.selectedValidationRun;
    const selectedCountlineID = this.rootStore.urlStore.selectedCountline;
    if (selectedValidationRunID) {
      const selectedValidationRun = this.rootStore.validationRunStore.validationRuns.get(selectedValidationRunID);
      if (selectedValidationRun) {
        if (selectedCountlineID) {
          const clValRunID: CountlineValidationRunID = `${selectedValidationRunID}-${selectedCountlineID}`;

          const clValRun = this.rootStore.countlineValidationRunStore.countlineValidationRuns.get(clValRunID);
          if (clValRun) {
            return [clValRun, ""];
          } else {
            return [undefined, `No such countline validation run ID (${clValRunID})`];
          }
        } else {
          return [undefined, "No countline selected"];
        }
      } else {
        return [undefined, `Validation run ${selectedValidationRunID} does not exist`];
      }
    } else {
      return [undefined, "No validation run selected"];
    }
  }

  public getApplicationKeymap(): ApplicationKeyMap {
    return getApplicationKeyMap();
  }

  @action deleteSequenceFromKeyMap(actionName: string, sequence: string) {
    _.remove(this.currentKeyMap[actionName].sequences, s => s === sequence);
    this.availableKeyMaps.set(this.currentKeyMapName, this.currentKeyMap);
  }

  @action
  public toggleRecordingKeyMap(actionName: string, handler?: (keyCombination: KeyCombination) => void) {
    // This is a very handy reference:
    // https://www.toptal.com/developers/keycode/for/a
    if (!this.isRecordingKeymap) {
      this.recordingCancel = recordKeyCombination(
        action(keyCombination => {
          this.isRecordingKeymap = false;
          this.recordingCancel = undefined;
          const replacementMap = Object.fromEntries(
            Object.keys(keyCombination.keys).map(key => [key, ReactKeyNamesToMousetrapDictionary[key] ?? key])
          );

          Object.keys(replacementMap).forEach(from => {
            keyCombination.id = (keyCombination.id as string).replace(from, replacementMap[from]);
          });
          let keyMapExistsElsewhere = "";
          Object.keys(this.currentKeyMap).forEach(handlerName => {
            if (
              handlerName !== "SWALLOW_DEFAULTS_WHILE_RECORDING" &&
              this.currentKeyMap[handlerName].sequences.find(s => s === keyCombination.id)
            ) {
              keyMapExistsElsewhere = _.upperFirst(_.lowerCase(handlerName));
            }
          });
          if (keyMapExistsElsewhere) {
            this.setError(
              `Key combination "${keyCombination.id}" already in use by "${keyMapExistsElsewhere}",\nplease delete it there before attempting to use it here`
            );
            return;
          }

          this.currentKeyMap[actionName].sequences.push(keyCombination.id);
          this.availableKeyMaps.set(this.currentKeyMapName, this.currentKeyMap);
          if (handler) {
            handler(keyCombination);
          }
        })
      );
      this.recordingHandlerName = actionName;
      this.isRecordingKeymap = true;
    } else {
      if (this.recordingCancel !== undefined) {
        this.recordingCancel();
      }
      this.isRecordingKeymap = false;
      this.recordingHandlerName = actionName;
    }
  }

  @action
  public loadKeyMap(savedKeyMap: string) {
    try {
      const parsed = assertMultiExtendedKeyMap(JSON.parse(savedKeyMap));
      this.availableKeyMaps.clear();

      Object.keys(parsed).forEach(keymapName => {
        this.availableKeyMaps.set(keymapName, parsed[keymapName]);
      });
      const first = Object.keys(parsed)[0];

      this.currentKeyMap = parsed[first];
      this.currentKeyMapName = first;
    } catch (e) {
      this.setError(errString(e));
      console.error(e);
    }
  }

  @action
  public async copyKeyMap() {
    this.availableKeyMaps.set(this.currentKeyMapName, this.currentKeyMap);
    await navigator.clipboard.writeText(
      JSON.stringify(Object.fromEntries(this.availableKeyMaps.entries()), null, "  ")
    );
  }

  @action
  public async pasteKeyMap() {
    const text = await navigator.clipboard.readText();
    this.loadKeyMap(text);
  }

  @action
  public async copyPlayerSnapshotToClipboard() {
    const canvas: HTMLCanvasElement = this.stageRef?.current?._app.current?.renderer?.plugins?.extract?.canvas(
      this.stageRef?.current?._app.current?.stage
    );
    if (canvas) {
      canvas.toBlob(async blob => {
        if (blob) {
          const clippy = new ClipboardItem({
            "image/png": blob,
          });
          await navigator.clipboard.write([clippy]);
          this.setSnackbarText("Copied image to clipboard");
        }
      }, "image/png");
    }
  }

  @action setReadOnlyMode(val: boolean | BoolFunc) {
    if (typeof val === "boolean") {
      this.readOnlyMode = val;
    } else {
      this.readOnlyMode = val(this.readOnlyMode);
    }
  }

  @action setPlayUntilNextCVCrossingMode(val: boolean | BoolFunc) {
    let newVal: boolean;
    if (typeof val === "boolean") {
      newVal = val;
    } else {
      newVal = val(this.playUntilNextCVCrossingMode);
    }

    this.playUntilNextCVCrossingMode = newVal;
    this.handlePlayUntilNextCVCrossing();
  }

  @action setPlayUntilNextCVCrossingClass(classNumber: ClassifyingDetectorClassTypeNumber, isSet: boolean) {
    if (!isSet) {
      this.playUntilNextCVCrossingClasses.delete(classNumber);
    } else {
      this.playUntilNextCVCrossingClasses.add(classNumber);
    }
  }

  @action setIsPlayUntilNextCVCrossingClassesModalOpen(visible: boolean) {
    if (!this.rootStore.urlStore.selectedComputerVisionRun) {
      this.setError("Results not available without a CV run selected");
    }
    this.isPlayUntilNextCVCrossingClassesModalOpen = visible;
  }

  @action setPixiVideoTexture(texture: PIXI.Texture<PIXI.Resource>) {
    this.pixiVideoTexture = texture;
  }

  @action setTailGeometries(tailGeometries: Map<number, number[]>) {
    this.tailGeometries = tailGeometries;
  }

  @action setTailColours(tailColours: Map<number, number>) {
    this.tailColours = tailColours;
  }

  @action setTailStartFrames(tailStartFrames: Map<number, number>) {
    this.tailStartFrames = tailStartFrames;
  }

  @action setActiveTracksByFrame(activeTracks: Map<number, number[]>) {
    this.activeTracksByFrame = activeTracks;
  }

  @action setUnsnappedUnixTimestampsByFrame(unsnappedUnixTimestamps: Map<number, number>) {
    this.unsnappedUnixTimestampsByFrame = unsnappedUnixTimestamps;
  }

  @action setBoundingBoxesByFrame(boundingBoxes: Map<number, BoxDetails[]>) {
    this.boundingBoxesByFrame = boundingBoxes;
  }

  @action setActualTotalFrames(totalFrames: number) {
    this.actualTotalFrames = totalFrames;
  }

  @action setFramesByVideoTimestampMicroseconds(frames: Map<number, number>) {
    this.framesByVideoTimestampMicroseconds = frames;
  }

  @action setVideoTimestampMicrosecondsByFrame(timestampByFrame: Map<number, number>) {
    this.videoTimestampMicrosecondsByFrame = timestampByFrame;
  }

  @action setFrameAvgIntervalMicroseconds(frameAvg: number) {
    this.frameAvgIntervalMicroseconds = frameAvg;
  }

  @action setDTFBufferOffsetsByFrameNumber(bufferOffsets: Map<number, number>) {
    this.dtfBufferOffsetsByFrameNumber = bufferOffsets;
  }

  @action setDTFBuffer(buffer: Uint8Array) {
    this.dtfBuffer = buffer;
  }

  @action setNearMissIncidents(incidents: Map<string, FrameInterval>) {
    this.nearMissIncidents = incidents;
  }
}
