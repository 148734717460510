import { createAssertType } from "typescript-is";
import {
  SignedVideoURL,
  VPHydrationWrapper,
  VPIDListHydration,
  ValidationRunHydration,
  ValidationCrossingsHydration,
  CVRunCountlineCrossingsCountlineHydration,
  ValidationRunsHydration,
  CountlineValidationNotesHydration,
  CVRunCreationHydration,
  ComputerVisionRunProgressUpdateHydration,
  CVRunDetailsHydration,
  CVRunTurningMovementsStartZoneHydration,
} from "./index";
import { KeyMapOptions, KeySequence, MouseTrapKeySequence } from "react-hotkeys";
import { GCSListResponse } from "./gcs";

// Bypass type checking by creating dummy functions if TYPECHECK env is missing
export const assertVPIDListHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<VPIDListHydration>()
  : (data: object) => data as VPIDListHydration;

export const assertVPHydrationWrapper = process.env.REACT_APP_TYPECHECK
  ? createAssertType<VPHydrationWrapper>()
  : (data: object) => data as VPHydrationWrapper;

export const assertSignedVideoURL = process.env.REACT_APP_TYPECHECK
  ? createAssertType<SignedVideoURL>()
  : (data: object) => data as SignedVideoURL;

export const assertValidationRunHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ValidationRunHydration>()
  : (data: object) => data as ValidationRunHydration;

export const assertValidationRunsHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ValidationRunsHydration>()
  : (data: object) => data as ValidationRunsHydration;

export const assertCVRunCountlineCrossingHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<CVRunCountlineCrossingsCountlineHydration>()
  : (data: object) => data as CVRunCountlineCrossingsCountlineHydration;

export const assertCVRunTurningMovementHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<CVRunTurningMovementsStartZoneHydration>()
  : (data: object) => data as CVRunTurningMovementsStartZoneHydration;

export const assertValidationCrossingsHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ValidationCrossingsHydration>()
  : (data: object) => data as ValidationCrossingsHydration;

export const assertCountlineValidationNotesHydrationHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<CountlineValidationNotesHydration[]>()
  : (data: object) => data as CountlineValidationNotesHydration[];

export const assertCVRunCreationHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<CVRunCreationHydration>()
  : (data: object) => data as CVRunCreationHydration;

export const assertGCSGCSListResponse = process.env.REACT_APP_TYPECHECK
  ? createAssertType<GCSListResponse>()
  : (data: object) => data as GCSListResponse;

export const assertCVRunDetailsHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<CVRunDetailsHydration>()
  : (data: object) => data as CVRunDetailsHydration;

// Duplicate this interface from react-hotkeys but with a more constrained type for convenience
export interface ExtendedKeyMapOptions extends KeyMapOptions {
  sequences: MouseTrapKeySequence[];
  name: string;
  group: string;
  extraData?: string;
  description: string;
}

export interface ExtendedKeyMapOptionsWithName extends ExtendedKeyMapOptions {
  handlerName: string;
}

export type ExtendedKeyMap = { [key: string]: ExtendedKeyMapOptions };

export type MultiExtendedKeyMap = { [key: string]: ExtendedKeyMap };

export const assertExtendedKeyMapOptions = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ExtendedKeyMapOptions>()
  : (data: KeySequence) => data as ExtendedKeyMapOptions;

export const assertExtendedKeyMap = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ExtendedKeyMap>()
  : (data: object) => data as ExtendedKeyMap;

export const assertMultiExtendedKeyMap = process.env.REACT_APP_TYPECHECK
  ? createAssertType<MultiExtendedKeyMap>()
  : (data: object) => data as MultiExtendedKeyMap;

export const assertComputerVisionRunProgressUpdateHydration = process.env.REACT_APP_TYPECHECK
  ? createAssertType<ComputerVisionRunProgressUpdateHydration>()
  : (data: object) => data as ComputerVisionRunProgressUpdateHydration;
