import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { FreneticityButton } from "../styledElements";
import { useStores } from "../../hooks/useStores.hook";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../enums";
import { NEW_VALIDATION_RUN_MODAL_EVENT } from "../../stores/validationCreationUI.store";
import { observer } from "mobx-react";

export const CountlineSelectionModal: FC<{}> = observer(() => {
  const [omittedCountlines, setOmittedCountlines] = useState<number[]>([]);
  const { entitiesStore, urlStore, validationCreationUIStore } = useStores();
  const navigate = useNavigate();

  const isCountlineOmitted = countline =>
    Boolean(omittedCountlines.find(selectedCountline => selectedCountline === countline));

  const onChange = countline => {
    if (isCountlineOmitted(countline)) {
      const newOmittedCountlines: number[] = [];
      omittedCountlines.forEach(omittedCountline => {
        if (omittedCountline !== countline) {
          newOmittedCountlines.push(omittedCountline);
        }
      });
      setOmittedCountlines(newOmittedCountlines);
    } else {
      setOmittedCountlines([...omittedCountlines, countline]);
    }
  };

  const onSubmit = async () => {
    if (validationCreationUIStore.computerVisionRun === undefined) {
      return;
    }
    const validationId = await entitiesStore.startValidationRun(
      validationCreationUIStore.computerVisionRun.id,
      omittedCountlines
    );

    const newParams = {
      selectedVisionProgram: validationCreationUIStore.computerVisionRun.vpid,
      selectedValidationRun: validationId,
      selectedComputerVisionRun: validationCreationUIStore.computerVisionRun.id,
      selectedVideo: validationCreationUIStore.computerVisionRun.videoID,
    };

    urlStore.setParams(newParams, "replaceIn");
    navigate(
      `${RoutePath.Player}?${new URLSearchParams(
        Object.entries(newParams).reduce((agg, [k, v]) => {
          agg[k] = v?.toString(10) ?? "";
          return agg;
        }, {})
      )}`
    );
    validationCreationUIStore.setCountlineSelectionModalIsOpen(false);
  };

  const onClose = () => {
    const event = new CustomEvent(NEW_VALIDATION_RUN_MODAL_EVENT, { detail: { isOpen: false } });
    window.dispatchEvent(event);
  };

  return (
    <Dialog open={validationCreationUIStore.countlineSelectionModalIsOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>Countline Selection</Typography>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {validationCreationUIStore.countlineIDs.map(countline => (
            <FormControlLabel
              key={countline}
              control={<Checkbox size="small" />}
              label={<Typography variant="body2">{countline}</Typography>}
              checked={!isCountlineOmitted(countline)}
              onChange={() => onChange(countline)}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          onClick={async () => onSubmit()}
          disabled={omittedCountlines.length === validationCreationUIStore.countlineIDs.length}
        >
          Create Validation
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});
