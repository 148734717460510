import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  Stack,
  ButtonGroup,
  Button,
  FormLabel,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  ListItemIcon,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CommuteIcon from "@mui/icons-material/Commute";
import { useStores } from "../../hooks/useStores.hook";

import { FreneticityButton } from "../styledElements";
import { CountlineValidationRun } from "../../interfaces";
import { SaveConfirmationModal } from "./SaveConfirmationModal";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";
import { ClassifyingDetectorClassTypeNumber } from "../../workers/utils";

interface SaveModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SaveModal: FC<SaveModalProps> = observer(({ isOpen, onClose }) => {
  const { urlStore, validationRunStore, countlineValidationRunStore, playerUIStore } = useStores();
  const [isSaving, setIsSaving] = useState(false);
  const [proposedValidationResult, setProposedValidationResult] = useState<boolean>();
  const [isCustomValidationRun, setCustomValidationRun] = useState<boolean>(false);

  const allClassIds = Object.values(ClassifyingDetectorClassTypes);
  const allClassNames = Object.keys(ClassifyingDetectorClassTypes);

  const mapOfAllClassesById = new Map<number, string>();
  allClassIds.map((id, index) => {
    if (id < 10000 && id > 0) {
      mapOfAllClassesById.set(id, allClassNames[index]);
    }
  });

  const classIdsBeingUsedWithEasyMode = Array.from(playerUIStore.playUntilNextCVCrossingClasses).filter(id => id !== 0);

  if (!urlStore.selectedValidationRun) {
    return null;
  }
  const validationRun = validationRunStore.validationRuns.get(urlStore.selectedValidationRun);
  if (!validationRun) {
    return null;
  }
  const countlineValidationRunIDs = validationRun.countlineValidationRuns || [];
  const countlineValidationRuns = countlineValidationRunIDs
    .reduce((agg: CountlineValidationRun[], id) => {
      const run = countlineValidationRunStore.countlineValidationRuns.get(id);
      if (run) {
        agg.push(run);
      }
      return agg;
    }, [])
    .filter(run => run.status !== "OMITTED");

  const handleUpdateCountlineValidationRun = (countlineId: number, passed: boolean) => async () => {
    setIsSaving(true);
    await countlineValidationRunStore.updateCountlineValidationRunStatus(validationRun.id, countlineId, passed);
    setIsSaving(false);
  };

  const handleReopenCountlineValidationRun = (countlineId: number) => async () => {
    setIsSaving(true);
    await countlineValidationRunStore.reopenCountlineValidationRun(validationRun.id, countlineId);
    setIsSaving(false);
  };

  const handleUpdateValidationRunProposal = (passed: boolean) => async () => {
    setIsSaving(true);
    setProposedValidationResult(passed);
  };

  const handleUpdateValidationRunConfirmation = async (confirmed: boolean) => {
    if (confirmed && proposedValidationResult !== undefined) {
      await validationRunStore.updateValidationRunStatus(
        validationRun.id,
        proposedValidationResult,
        isCustomValidationRun ? classIdsBeingUsedWithEasyMode.join(",") : ""
      );
    }
    setProposedValidationResult(undefined);
    setIsSaving(false);
  };

  const isGloballyDisabled = isSaving || validationRun.status !== "IN_PROGRESS";
  const allCounlineValidationRunsComplete =
    countlineValidationRuns.reduce((sum, r) => sum + (r.status === "COMPLETED" ? 1 : 0), 0) ===
    countlineValidationRuns.length;

  const handleToggleAll = (toggleOn: boolean) => {
    allClassIds.map(id => {
      if (id < 10000 && id > 0) {
        playerUIStore.setPlayUntilNextCVCrossingClass(id as ClassifyingDetectorClassTypeNumber, toggleOn);
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Save</DialogTitle>
      {Object.keys(ClassifyingDetectorClassTypes).map((className, index) => {
        {
          <Button value={index}> {className} </Button>;
        }
      })}
      <DialogContent>
        {countlineValidationRuns.map(run => {
          const countlineId = parseInt(run.id.split("-")[1], 10);
          return (
            <Stack key={run.id} direction={"row"} justifyContent="center" spacing={2} style={{ marginBottom: 8 }}>
              <FormControl>
                <FormLabel>
                  <Typography>Countline {countlineId}</Typography>
                </FormLabel>
                <ButtonGroup>
                  <Button
                    variant={run.status === "IN_PROGRESS" ? "contained" : "outlined"}
                    disabled={isGloballyDisabled}
                    onClick={handleReopenCountlineValidationRun(countlineId)}
                  >
                    In Progress
                  </Button>
                  <Button
                    variant={run.status === "COMPLETED" && run.passed ? "contained" : "outlined"}
                    disabled={isGloballyDisabled}
                    color="success"
                    onClick={handleUpdateCountlineValidationRun(countlineId, true)}
                  >
                    Passed
                  </Button>
                  <Button
                    variant={run.status === "COMPLETED" && !run.passed ? "contained" : "outlined"}
                    disabled={isGloballyDisabled}
                    color="error"
                    onClick={handleUpdateCountlineValidationRun(countlineId, false)}
                  >
                    Failed
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Stack>
          );
        })}
        <Stack direction={"row"} justifyContent="center" style={{ marginTop: 32 }}>
          <FormControl>
            <FormLabel>
              <Typography>Validation {validationRun.id}</Typography>
            </FormLabel>
            <ButtonGroup>
              <Button
                variant={validationRun.status === "IN_PROGRESS" ? "contained" : "outlined"}
                disabled={isGloballyDisabled || !allCounlineValidationRunsComplete}
              >
                In Progress
              </Button>
              <Button
                variant={validationRun.status === "COMPLETED" && validationRun.passed ? "contained" : "outlined"}
                disabled={isGloballyDisabled || !allCounlineValidationRunsComplete}
                color="success"
                onClick={handleUpdateValidationRunProposal(true)}
              >
                Passed
              </Button>
              <Button
                variant={validationRun.status === "COMPLETED" && !validationRun.passed ? "contained" : "outlined"}
                disabled={isGloballyDisabled || !allCounlineValidationRunsComplete}
                color="error"
                onClick={handleUpdateValidationRunProposal(false)}
              >
                Failed
              </Button>
            </ButtonGroup>
          </FormControl>
        </Stack>
        <Stack direction={"row"} justifyContent="center" style={{ marginTop: 32 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={isCustomValidationRun || Array.isArray(validationRun.customValidatedClasses)}
                  disabled={isGloballyDisabled}
                  onChange={state => setCustomValidationRun(state.target.checked)}
                />
              }
              label="Custom Validated Classes"
            />

            <Select
              multiple
              disabled={!isCustomValidationRun || isGloballyDisabled}
              value={classIdsBeingUsedWithEasyMode.map(id => mapOfAllClassesById.get(id))}
              renderValue={value => {
                if (isGloballyDisabled) {
                  return validationRun.customValidatedClasses?.map(id => mapOfAllClassesById.get(id)).join(" | ");
                }
                return isCustomValidationRun ? value.join(" | ") : "Not Applicable";
              }}
              size={"small"}
            >
              <MenuItem key={0} value={0}>
                <ListItemText primary={"Toggle All"} />
                <Checkbox
                  checked={mapOfAllClassesById.size === classIdsBeingUsedWithEasyMode.length}
                  onChange={state => handleToggleAll(state.target.checked)}
                />
              </MenuItem>
              {Array.from(mapOfAllClassesById.entries()).map(entry => {
                return (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    <ListItemIcon>{entry[0] < 10000 && <CommuteIcon />}</ListItemIcon>
                    <ListItemText primary={entry[1]} />
                    <Checkbox
                      checked={classIdsBeingUsedWithEasyMode.includes(entry[0] as ClassifyingDetectorClassTypeNumber)}
                      onChange={() =>
                        playerUIStore.setPlayUntilNextCVCrossingClass(
                          entry[0] as ClassifyingDetectorClassTypeNumber,
                          !classIdsBeingUsedWithEasyMode.includes(entry[0] as ClassifyingDetectorClassTypeNumber)
                        )
                      }
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <FreneticityButton
          onClick={() => {
            playerUIStore.setIsNewCVRunModalOpen(true);
          }}
          disabled={!isGloballyDisabled}
        >
          New CV Run
        </FreneticityButton>
        <FreneticityButton onClick={onClose}>OK</FreneticityButton>
      </DialogActions>
      <SaveConfirmationModal
        isOpen={proposedValidationResult !== undefined}
        onClose={handleUpdateValidationRunConfirmation}
        proposedValidationResult={proposedValidationResult}
      />
    </Dialog>
  );
});
