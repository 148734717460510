import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "./root.store";
import { ChildStore } from "./child.store";
import { ComputerVisionRun, Video } from "../interfaces";
import { CountlineID, CVRunID, FrameNumber, VideoID } from "../domain";
import { handleNon200 } from "./utils/promiseHandlers";
import { errString } from "./utils/errorString";
import {
  assertCVRunCountlineCrossingHydration,
  assertCVRunCreationHydration,
  assertCVRunDetailsHydration,
  assertCVRunTurningMovementHydration,
} from "../interfaces/hydration/type-assertions";
import {
  CVRunCountlineCrossingsCountlineHydrationToComputerVisionRunCountlineCrossings,
  cvRunHydrationToCVRun,
  CVRunTurningMovementStartZoneToComputerVisionRunTurningMovements,
  nullToUndefined,
} from "../interfaces/hydration/utils";
import { getApiEndpoint, ApiEndpointPath } from "./apiStore";
import { ComputerVisionRunProgressUpdate } from "../interfaces/computerVisionRunProgressUpdate.interface";
import { RoutePath } from "../enums";
import { ClassifyingDetectorClassTypeNumber } from "../workers/utils";

export const NEW_CV_RUN_EVENT = "NEW_CV_RUN_EVENT";
export const RESET_CV_RUN_ATTEMPT_COUNTER_EVENT = "RESET_CV_RUN_ATTEMPT_COUNTER_EVENT";
export const CV_RUN_VISIBILITY_EVENT = "CV_RUN_VISIBILITY_EVENT";
export const CV_RUN_FETCH_DETAILS_EVENT = "CV_RUN_FETCH_DETAILS_EVENT";
export interface NewCVRunEvent {
  videoId: VideoID;
  supermarioValuesTemplatedYaml?: string;
  supermarioImage?: string;
}

export interface CVRunResetCounterEvent {
  computerVisionRunId: CVRunID;
}

export interface CVRunVisibilityEvent {
  computerVisionRunId: CVRunID;
  skipValidation: boolean;
}

export interface CVRunFetchDetailsEvent {
  computerVisionRunId: CVRunID;
}

export class CVRunStore extends ChildStore {
  SENMAN_TEST_TEMPLATE_ID = 110;

  @observable
  computerVisionRuns = new Map<CVRunID, ComputerVisionRun>();
  @observable
  hydratedComputerVisionRuns = new Map<CVRunID, boolean>();

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @computed
  get computerVisionRunEntries(): ComputerVisionRun[] {
    return Array.from(this.computerVisionRuns.values()).sort((a, b) => a.id - b.id);
  }

  computerVisionRunsByIds(cvRunIds: CVRunID[]): ComputerVisionRun[] {
    return cvRunIds
      .map(id => this.computerVisionRuns.get(id))
      .filter(cvRun => cvRun !== undefined) as ComputerVisionRun[];
  }

  @action
  init() {
    window.addEventListener(NEW_CV_RUN_EVENT, ((e: CustomEvent<NewCVRunEvent>) => {
      const { videoId } = e.detail;
      const video = this.rootStore.videoStore.videos.get(videoId);
      if (!video) {
        console.error(`Error: Unable to find video for videoId ${videoId}`);
        return;
      }

      if (video.status !== "UPLOADING_COMPLETE") {
        console.error(`Error: Unable to start new CV run for ${videoId} with status: ${video.status}`);
        return;
      }

      this.createNewCVRun(video, e.detail).then(() => {});
    }) as EventListener);

    window.addEventListener(RESET_CV_RUN_ATTEMPT_COUNTER_EVENT, ((e: CustomEvent<CVRunFetchDetailsEvent>) => {
      const { computerVisionRunId } = e.detail;
      const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(computerVisionRunId);
      if (!cvRun) {
        console.error(`Error: Unable to find CV Run with ID ${computerVisionRunId}`);
        return;
      }

      if (cvRun.status !== "FAILED") {
        console.error(`Error: Can only reset attempts counter if CV Run has failed but its status is: ${cvRun.status}`);
        return;
      }

      this.resetCVRunCounter(cvRun).then(() => {});
    }) as EventListener);

    window.addEventListener(CV_RUN_VISIBILITY_EVENT, ((e: CustomEvent<CVRunVisibilityEvent>) => {
      const { computerVisionRunId, skipValidation } = e.detail;
      const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(computerVisionRunId);
      if (!cvRun) {
        console.error(`Error: Unable to find CV run for Id ${computerVisionRunId}`);
        return;
      }

      if (cvRun.skipValidation === skipValidation) {
        console.warn(`Warning: CV run with id ${computerVisionRunId} is already with desired visibility. skipping.`);
        return;
      }

      this.updateCVRunVisibility(cvRun, skipValidation).then(() => {});
    }) as EventListener);

    window.addEventListener(CV_RUN_FETCH_DETAILS_EVENT, ((e: CustomEvent<CVRunFetchDetailsEvent>) => {
      const { computerVisionRunId } = e.detail;
      this.fetchCvRunDetails(computerVisionRunId);
    }) as EventListener);
  }

  @action
  async createNewCVRun(video: Video, { supermarioValuesTemplatedYaml, supermarioImage }: NewCVRunEvent) {
    const urlSearchParams = new URLSearchParams({
      video_id: video.id.toString(10),
      priority: "100",
      force_duplicate: "true",
      output_drawing_video: "false",
      skip_validation: "false",
    });

    if (this.rootStore.entitiesStore.vpidList.find(vp => vp.id === video.vpid)?.enableTesting) {
      urlSearchParams.set("template_id", this.SENMAN_TEST_TEMPLATE_ID.toString(10));
    }

    let apiEndpointPath = ApiEndpointPath.CREATE_CV_RUN;
    let requestBody = "";

    if (supermarioValuesTemplatedYaml !== undefined && supermarioImage !== undefined) {
      apiEndpointPath = ApiEndpointPath.CREATE_CV_RUN_WITH_CONFIG;
      urlSearchParams.set("supermario_image", supermarioImage);

      try {
        requestBody = supermarioValuesTemplatedYaml;
      } catch (e) {
        const err = new Error(`Error: Unable to parse supermario values: ${supermarioValuesTemplatedYaml}`);
        console.error(err);
        return Promise.reject(err);
      }
    } else if (supermarioValuesTemplatedYaml !== undefined || supermarioImage !== undefined) {
      const err = new Error(
        `Error: supermarioValuesTemplatedYaml and supermarioImage must be both defined or undefined`
      );
      console.error(err);
      return Promise.reject(err);
    }

    return this.rootStore.apiStore
      .authenticatedFetch(getApiEndpoint(apiEndpointPath) + "?" + urlSearchParams.toString(), {
        method: "POST",
        body: requestBody,
      })
      .then(handleNon200)
      .then(async res => res.json())
      .then(res => assertCVRunCreationHydration(res))
      .then(res => {
        this.rootStore.cvRunStore.setComputerVisionRunById(res.id, {
          ...cvRunHydrationToCVRun(res, res.id, video.id, video.vpid, video.triggerReason),
          vpid: video.vpid,
          videoID: video.id,
          thumbnail: video.thumbnail,
          videoDownloadURL: video.downloadUrl,
        });
        if (!video.computerVisionRuns) {
          video.computerVisionRuns = [res.id];
        } else {
          video.computerVisionRuns.push(res.id);
        }

        const navigate = this.rootStore.navigate.current;
        if (navigate) {
          const newParams = {
            selectedVideo: video.id,
            selectedVisionProgram: video.vpid,
          };
          this.rootStore.urlStore.setParams(newParams, "replaceIn");
          navigate(
            `${RoutePath.ComputerVisionRuns}?${new URLSearchParams(
              Object.entries(newParams).reduce((agg, [k, v]) => {
                agg[k] = v?.toString(10) ?? "";
                return agg;
              }, {})
            )}`
          );
        }
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not createNewCVRun(): " + errString(err))
        )
      );
  }

  @action
  async resetCVRunCounter(cvRun: ComputerVisionRun) {
    const urlSearchParams = new URLSearchParams({
      computer_vision_run_id: cvRun.id.toString(10),
    }).toString();

    return this.rootStore.apiStore
      .authenticatedFetch(getApiEndpoint(ApiEndpointPath.RESET_CV_RUN_ATTEMPT_COUNTER) + "?" + urlSearchParams, {
        method: "POST",
      })
      .then(handleNon200)
      .then(res => {
        this.rootStore.cvRunStore.setComputerVisionRunById(cvRun.id, {
          ...cvRun,
          status: "PENDING",
          skipValidation: false,
        });
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not resetCVRunCounter(): " + errString(err))
        )
      );
  }

  @action
  async updateCVRunVisibility(cvRun: ComputerVisionRun, skipValidation: boolean) {
    return this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.CHANGE_CV_RUN_VISIBILITY) +
          "?" +
          new URLSearchParams({
            computer_vision_run_id: cvRun.id.toString(10),
            skip_validation: String(skipValidation),
          }).toString(),
        { method: "POST" }
      )
      .then(handleNon200)
      .then(() => {
        cvRun.skipValidation = skipValidation;
        this.computerVisionRuns.set(cvRun.id, cvRun);
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not updateCVRunVisibility(): " + errString(err))
        )
      );
  }

  @action
  setComputerVisionRunById(id: CVRunID, value: ComputerVisionRun) {
    const existingValidationRuns = this.computerVisionRuns.get(id)?.validationRuns;
    const validationRuns = Array.from(new Set([...(existingValidationRuns || []), ...(value.validationRuns || [])]));

    const countlineCrossings = this.computerVisionRuns.get(id)?.countlineCrossings;
    const thumbnail = this.computerVisionRuns.get(id)?.thumbnail;
    const videoDownloadURL = this.computerVisionRuns.get(id)?.videoDownloadURL;
    const turningMovements = this.computerVisionRuns.get(id)?.turningMovements;
    if (turningMovements && turningMovements.size) {
      if (countlineCrossings && countlineCrossings.size) {
        this.computerVisionRuns.set(id, {
          ...value,
          countlineCrossings,
          thumbnail,
          videoDownloadURL,
          validationRuns,
          turningMovements,
        });
      } else {
        this.computerVisionRuns.set(id, {
          ...value,
          thumbnail,
          videoDownloadURL,
          validationRuns,
          turningMovements,
        });
      }
    } else {
      if (countlineCrossings && countlineCrossings.size) {
        this.computerVisionRuns.set(id, {
          ...value,
          countlineCrossings,
          thumbnail,
          videoDownloadURL,
          validationRuns,
        });
      } else {
        this.computerVisionRuns.set(id, { ...value, thumbnail, videoDownloadURL, validationRuns });
      }
    }
  }

  @action fetchCvRunDetails(cvRunID: CVRunID) {
    const cvRun = this.computerVisionRuns.get(cvRunID);

    if (!cvRun) {
      throw new Error(`this shouldn't be possible: cvRun ${cvRunID} not in store`);
    }

    this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.FETCH_CV_RUN_DETAILS) +
          "?" +
          new URLSearchParams({
            computer_vision_run_id: cvRunID.toString(10),
          })
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then((data: object) => {
        const cvRunDetails = assertCVRunDetailsHydration(data);
        this.setComputerVisionRunById(cvRunID, {
          ...cvRun,
          processorID: nullToUndefined(cvRunDetails.processor_id),
          supermarioValues: nullToUndefined(cvRunDetails.supermario_values),
          lastReportedError: nullToUndefined(cvRunDetails.last_reported_error),
          lastRunStdout: nullToUndefined(cvRunDetails.last_run_stdout),
          lastRunStderr: nullToUndefined(cvRunDetails.last_run_stderr),
          createdBy: nullToUndefined(cvRunDetails.created_by),
          createdAt: nullToUndefined(cvRunDetails.created_at),
        });
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not fetchCvRunDetails(): " + errString(err))
        )
      );
  }

  @action
  async hydrateCvRun(cvRunID: CVRunID) {
    const cvRunHydrated = this.hydratedComputerVisionRuns.get(cvRunID);
    if (cvRunHydrated) {
      return;
    }

    await this.fetchCvCountlineCrossings(cvRunID);
    await this.fetchCvTurningMovements(cvRunID);
    this.hydratedComputerVisionRuns.set(cvRunID, true);
  }

  @action
  async fetchCvCountlineCrossings(cvRunID: CVRunID) {
    const cvRun = this.computerVisionRuns.get(cvRunID);

    if (!cvRun) {
      throw new Error(`this shouldn't be possible: cvRun ${cvRunID} not in store`);
    }

    await this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.FETCH_CV_RUN_CROSSINGS) +
          "?" +
          new URLSearchParams({
            computer_vision_run_id: cvRunID.toString(10),
          })
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then((data: object) => {
        const CVRunCountlineCrossingHydrationData = assertCVRunCountlineCrossingHydration(data);
        const CVRunCountlineCrossingsMap =
          CVRunCountlineCrossingsCountlineHydrationToComputerVisionRunCountlineCrossings(
            CVRunCountlineCrossingHydrationData
          );

        this.setComputerVisionRunById(cvRunID, {
          ...cvRun,
          countlineCrossings: CVRunCountlineCrossingsMap,
        });
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not fetchCvCountlineCrossings(): " + errString(err))
        )
      );
  }

  @action
  async fetchCvTurningMovements(cvRunID: CVRunID) {
    const cvRun = this.computerVisionRuns.get(cvRunID);

    if (!cvRun) {
      throw new Error(`this shouldn't be possible: cvRun ${cvRunID} not in store`);
    }
    await this.rootStore.apiStore
      .authenticatedFetch(
        getApiEndpoint(ApiEndpointPath.FETCH_TURNING_MOVEMENTS) +
          "?" +
          new URLSearchParams({
            computer_vision_run_id: cvRunID.toString(10),
          })
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then((data: object) => {
        // This will do, but probably want a better way of going about empty responses.
        if (Object.keys(data).length === 0) {
          return;
        }

        const CVRunTurningMovementHydrationData = assertCVRunTurningMovementHydration(data);
        const CVRunTurningMovementsMap = CVRunTurningMovementStartZoneToComputerVisionRunTurningMovements(
          CVRunTurningMovementHydrationData
        );

        this.setComputerVisionRunById(cvRunID, {
          ...cvRun,
          turningMovements: CVRunTurningMovementsMap,
        });
      })
      .catch(
        action(async err =>
          this.rootStore.entitiesStore.setHydrationErrors("Could not fetchCvTurningMovements(): " + errString(err))
        )
      );
  }

  @computed
  get unvalidatedComputerVisionRuns() {
    const unvalidatedComputerVisionRunIds: number[] = [];
    this.computerVisionRuns.forEach(computerVisionRun => {
      const incompleteValidationRuns = this.rootStore.validationRunStore.incompleteValidationRuns;
      let isValidationRunsIncomplete = false;
      computerVisionRun.validationRuns?.forEach(runId => {
        if (incompleteValidationRuns.includes(runId)) {
          isValidationRunsIncomplete = true;
        }
      });
      if (
        !computerVisionRun.validationRuns ||
        computerVisionRun.validationRuns.length === 0 ||
        isValidationRunsIncomplete
      ) {
        unvalidatedComputerVisionRunIds.push(computerVisionRun.id);
      }
    });
    return unvalidatedComputerVisionRunIds;
  }

  @action
  public async CVRunCrossingsRunningTotals(
    cvRunId?: number
  ): Promise<Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>> {
    const cvRunRunningTotals: Map<
      FrameNumber,
      Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>
    > = new Map<FrameNumber, Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>();

    let selectedCVRun: number | undefined;
    if (!cvRunId) {
      selectedCVRun = this.rootStore.urlStore.selectedComputerVisionRun;
    } else {
      selectedCVRun = cvRunId;
    }

    if (!selectedCVRun) {
      return cvRunRunningTotals;
    }

    await this.hydrateCvRun(selectedCVRun);
    const cvRun = this.computerVisionRuns.get(selectedCVRun);

    if (!cvRun) {
      return cvRunRunningTotals;
    }
    const seenClassesMap: Map<ClassifyingDetectorClassTypeNumber, boolean> = new Map();

    const crossingsByCountline = this.computerVisionRuns.get(selectedCVRun)?.countlineCrossings;
    if (crossingsByCountline) {
      crossingsByCountline.forEach(frameNumber => {
        frameNumber.forEach(cvCrossing => {
          seenClassesMap.set(cvCrossing.trackClass, true);
        });
      });
    }

    cvRun.countlineCrossings.forEach(crossingsByFrameNumber => {
      crossingsByFrameNumber.forEach(crossing => {
        seenClassesMap.set(crossing.trackClass, true);
      });
    });

    const accumulator: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();

    cvRun.countlineCrossings.forEach((crossingsByFrameNumber, countlineID) => {
      const accClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accAntiClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map();
      const accByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
      seenClassesMap.forEach((b, classNumber) => {
        accAntiClockwise.set(classNumber, 0);
        accClockwise.set(classNumber, 0);
      });
      accByDirection.set(true, accClockwise);
      accByDirection.set(false, accAntiClockwise);
      accumulator.set(countlineID, accByDirection);
    });

    const videoID = this.rootStore.urlStore.selectedVideo;

    if (!videoID) {
      return cvRunRunningTotals;
    }

    const relativeEndFrame = this.rootStore.videoStore.fetchVideoTotalFrames(videoID);

    for (let frameNumber = 0; frameNumber <= relativeEndFrame; frameNumber++) {
      const clCrossings: Map<CountlineID, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>> = new Map();
      cvRunRunningTotals.set(frameNumber, clCrossings);
      cvRun.countlineCrossings.forEach((crossingsByFrameNumber, countlineID) => {
        const clCrossingsByDirection: Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>> = new Map();
        clCrossings.set(countlineID, clCrossingsByDirection);

        const clAccumulator = accumulator.get(countlineID);
        if (clAccumulator) {
          const clAccumulatorClockwise = clAccumulator.get(true);
          const clAccumulatorAntiClockwise = clAccumulator.get(false);

          if (clAccumulatorClockwise && clAccumulatorAntiClockwise) {
            const crossing = crossingsByFrameNumber.get(frameNumber);
            if (crossing) {
              const maybeCurrentCount = crossing.clockwise
                ? clAccumulatorClockwise.get(crossing.trackClass)
                : clAccumulatorAntiClockwise.get(crossing.trackClass);
              const currentCount = maybeCurrentCount !== undefined ? maybeCurrentCount : 0;
              if (crossing.clockwise) {
                clAccumulatorClockwise.set(crossing.trackClass, currentCount + 1);
              } else {
                clAccumulatorAntiClockwise.set(crossing.trackClass, currentCount + 1);
              }
            }
            const clCrossingsByClockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
              clAccumulatorClockwise
            );
            const clCrossingsByAnticlockwise: Map<ClassifyingDetectorClassTypeNumber, number> = new Map(
              clAccumulatorAntiClockwise
            );

            clCrossingsByDirection.set(true, clCrossingsByClockwise);
            clCrossingsByDirection.set(false, clCrossingsByAnticlockwise);
          }
        }
      });
    }
    return cvRunRunningTotals;
  }

  @action
  handleComputerVisionRunProgressUpdate(update: ComputerVisionRunProgressUpdate) {
    const cvRun = this.computerVisionRuns.get(update.id);
    if (!cvRun) {
      return;
    }
    if (update.errorString) {
      cvRun.status = "FAILED";
      return;
    }
    cvRun.status = update.status;
    cvRun.processingPercent = update.processingPercent;
  }
}
