import React, { FC } from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import { Alert, Grid, IconButton, Slide, Snackbar, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStores } from "../../hooks/useStores.hook";
import { SnackbarOrigin } from "@mui/material/Snackbar/Snackbar";

const anchorOrigin: SnackbarOrigin = {
  vertical: "top",
  horizontal: "center",
};

export const SuccessSnackbar: FC<{}> = observer(() => {
  const { playerUIStore } = useStores();
  const onClose = action(() => playerUIStore.setSnackbarText(""));
  const transition = props => <Slide {...props} direction="down" />;
  return (
    <Snackbar
      open={playerUIStore.snackbarText !== ""}
      anchorOrigin={anchorOrigin}
      autoHideDuration={10000}
      onClose={onClose}
      TransitionComponent={transition}
    >
      <StyledAlert variant="filled" severity={"success"}>
        <Grid container justifyContent="flex-end" justifyItems={"center"} justifySelf={"center"}>
          <Grid item>
            <Typography>{playerUIStore.snackbarText}</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </StyledAlert>
    </Snackbar>
  );
});

export const ErrorSnackbar: FC<{}> = observer(() => {
  const { playerUIStore, entitiesStore } = useStores();
  const transition = props => <Slide {...props} direction="down" />;
  return (
    <Snackbar
      open={playerUIStore.errors !== "" || entitiesStore.hydrationErrors !== ""}
      anchorOrigin={anchorOrigin}
      autoHideDuration={10000}
      TransitionComponent={transition}
    >
      <StyledAlert variant="filled" severity={"error"}>
        <Grid container justifyContent="flex-end" justifyItems={"center"} justifySelf={"center"}>
          <Grid item>
            <Typography>
              {playerUIStore.errors}
              {entitiesStore.hydrationErrors}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={action(() => {
                playerUIStore.setError("");
                if (entitiesStore.hydrationErrors) {
                  entitiesStore.setHydrationErrors("");
                }
              })}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </StyledAlert>
    </Snackbar>
  );
});

const StyledAlert = styled(Alert)({ whiteSpace: "pre-wrap" });
