import * as PIXI from "pixi.js";
import React, { PropsWithChildren } from "react";
import { Container, Sprite } from "react-pixi-fiber/index";
import { getColourFromClass } from "../../../workers/colourMap";
import { ValidationRunCountlineCrossing } from "../../../interfaces";
import { StoresContext } from "../../../contexts/stores.context";
import { observer } from "mobx-react";

type ValidationRunCrossingsProps = {
  width: number;
  storesContext: StoresContext;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  alpha: number;
};

export const ValidationRunCrossings: React.FunctionComponent<PropsWithChildren<ValidationRunCrossingsProps>> = observer(
  props => {
    const { urlStore, countlineValidationRunStore, playerUIStore } = props.storesContext;

    if (
      urlStore.selectedComputerVisionRun === undefined ||
      urlStore.selectedCountline === undefined ||
      urlStore.selectedValidationRun === undefined
    ) {
      return null;
    }

    const clValRun = countlineValidationRunStore.getCountlineValidationRun(
      urlStore.selectedValidationRun,
      urlStore.selectedCountline
    );

    if (!clValRun) {
      // TODO - should we warn about this?
      return null;
    }

    const validationRunCrossings: ValidationRunCountlineCrossing[] = [];
    clValRun.validationRunCountlineCrossings.forEach(v => {
      validationRunCrossings.push(v);
    });

    const totalFrames = playerUIStore.totalFrames;
    return (
      <Container>
        {validationRunCrossings.map(crossing => {
          return (
            <ValidationRunCrossing
              positionX={(props.width * crossing.frameNumber) / totalFrames}
              crossing={crossing}
              frameNumber={crossing.frameNumber}
              pointerDown={props.pointerDown}
              pointerUp={props.pointerUp}
              key={"validation-run-" + crossing.frameNumber}
              alpha={props.alpha}
            />
          );
        })}
      </Container>
    );
  }
);

export type ValidationRunCrossingProps = {
  frameNumber: number;
  positionX: number;
  crossing: ValidationRunCountlineCrossing;
  pointerUp?: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  alpha: number;
};

export const ValidationRunCrossing: React.FunctionComponent<PropsWithChildren<ValidationRunCrossingProps>> = (
  props: PropsWithChildren<ValidationRunCrossingProps>
) => {
  const pointerUp = (e: PIXI.InteractionEvent) => {
    if (props.pointerUp) {
      props.pointerUp(e, props.crossing);
    }
  };
  const pointerDown = (e: PIXI.InteractionEvent) => {
    props.pointerDown(e, props.crossing);
  };
  return (
    <>
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={props.crossing.saved ? getColourFromClass(props.crossing.detectionClassV2Id) : 0xff0000}
        x={props.positionX - 2.5}
        y={props.crossing.clockwise ? 20 : 35}
        width={8}
        height={5}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={props.crossing.saved ? getColourFromClass(props.crossing.detectionClassV2Id) : 0xff0000}
        x={props.positionX}
        y={20}
        width={3}
        height={20}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
    </>
  );
};

type ZoomedValidationRunCrossingsProps = {
  storesContext: StoresContext;
  pointerUp: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  alpha: number;
};

export const ZoomedValidationRunCrossings: React.FunctionComponent<
  PropsWithChildren<ZoomedValidationRunCrossingsProps>
> = observer(props => {
  const { urlStore, countlineValidationRunStore, playerUIStore } = props.storesContext;

  if (
    urlStore.selectedComputerVisionRun === undefined ||
    urlStore.selectedCountline === undefined ||
    urlStore.selectedValidationRun === undefined
  ) {
    return null;
  }

  const clValRun = countlineValidationRunStore.getCountlineValidationRun(
    urlStore.selectedValidationRun,
    urlStore.selectedCountline
  );

  if (!clValRun) {
    // TODO - should we warn about this?
    return null;
  }

  const zoomedValidationRunCrossings: ValidationRunCountlineCrossing[] = [];
  clValRun.validationRunCountlineCrossings.forEach((v, frameNumber) => {
    const fraction = frameNumber / playerUIStore.totalFrames;
    if (fraction >= playerUIStore.timelineLowerBound && fraction <= playerUIStore.timelineUpperBound) {
      zoomedValidationRunCrossings.push(v);
    }
  });

  return (
    <Container>
      {zoomedValidationRunCrossings.map(crossing => {
        return (
          <ZoomedValidationRunCrossing
            key={"zoomed-validation-run-" + crossing.frameNumber.toString()}
            frameNumber={crossing.frameNumber}
            crossing={crossing}
            pointerUp={props.pointerUp}
            pointerDown={props.pointerDown}
            totalFrames={playerUIStore.totalFrames}
            width={playerUIStore.timelineWidth}
            lowerBound={playerUIStore.timelineLowerBound}
            upperBound={playerUIStore.timelineUpperBound}
            alpha={props.alpha}
          />
        );
      })}
    </Container>
  );
});
export type ZoomedValidationRunCrossingProps = {
  frameNumber: number;
  totalFrames: number;
  width: number;
  lowerBound: number;
  upperBound: number;
  crossing: ValidationRunCountlineCrossing;
  pointerUp: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  pointerDown: (e: PIXI.InteractionEvent, crossing: ValidationRunCountlineCrossing) => void;
  alpha: number;
};

export const ZoomedValidationRunCrossing: React.FunctionComponent<
  PropsWithChildren<ZoomedValidationRunCrossingProps>
> = (props: PropsWithChildren<ZoomedValidationRunCrossingProps>) => {
  const fraction = props.frameNumber / props.totalFrames;
  const xPos = (props.width * (fraction - props.lowerBound)) / (props.upperBound - props.lowerBound);
  const pointerUp = (e: PIXI.InteractionEvent) => {
    props.pointerUp(e, props.crossing);
  };
  const pointerDown = (e: PIXI.InteractionEvent) => {
    props.pointerDown(e, props.crossing);
  };
  return (
    <>
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={props.crossing.saved ? getColourFromClass(props.crossing.detectionClassV2Id) : 0xff0000}
        x={xPos - 2.5}
        y={props.crossing.clockwise ? 50 : 55}
        width={8}
        height={5}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
      <Sprite
        cursor={"pointer"}
        texture={PIXI.Texture.WHITE}
        tint={props.crossing.saved ? getColourFromClass(props.crossing.detectionClassV2Id) : 0xff0000}
        x={xPos}
        y={50}
        width={3}
        height={10}
        alpha={props.alpha}
        interactive={true}
        pointerdown={pointerDown}
        pointerup={pointerUp}
      />
    </>
  );
};
