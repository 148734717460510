import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toJS } from "mobx";
import { Link } from "react-router-dom";
import { RoutePath, ValidationMode } from "../../enums";
import { FreneticityButton } from "../styledElements";
import { URL_QUERY_PARAMS } from "../../stores/url.store";
import { Button, Grid, Tooltip } from "@mui/material";
import { ComputerVisionRun, ValidationRun } from "../../interfaces";
import { useStores } from "../../hooks/useStores.hook";
import { CloneModal, CountlineSelectionModal, FormatResultsModal } from "../modals";
import {
  NewValidationRunModalEvent,
  NEW_VALIDATION_RUN_MODAL_EVENT,
  CLONE_VALIDATION_RUN_MODAL_EVENT,
  CloneValidationRunModalEvent,
} from "../../stores/validationCreationUI.store";
import _ from "lodash";
import LinearProgress from "@mui/material/LinearProgress";
import { ThumbnailButton } from "./VideoTable";
import InfoIcon from "@mui/icons-material/Info";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ErrorIcon from "@mui/icons-material/Error";
import {
  CVRunVisibilityEvent,
  CV_RUN_VISIBILITY_EVENT,
  CVRunFetchDetailsEvent,
  CV_RUN_FETCH_DETAILS_EVENT,
  RESET_CV_RUN_ATTEMPT_COUNTER_EVENT,
  CVRunResetCounterEvent,
} from "../../stores/cvRun.store";
import { CVRunDetailsModal } from "../modals/CVRunDetailsModal";
import { CVRunErrorsModal } from "../modals/CVRunErrorsModal";

interface ComputerVisionRunTableProps {
  displayHiddenCVRuns: boolean;
}

const idColumn: GridColDef<TableComputerVisionRun, number> = {
  field: "id",
  headerName: "CVRun ID",
  flex: 0.14,
  valueFormatter: v => v.value,
};

const rawDtfsFromSensorColumn: GridColDef<TableComputerVisionRun> = {
  field: "rawDtfsFromSensor",
  headerName: "Sensor DTFs",
  flex: 0.12,
  renderCell: ({ row }) => (row.rawDtfsFromSensor ? <CheckBoxIcon color={"primary"} /> : ""),
};

const vpidColumn: GridColDef<TableComputerVisionRun> = {
  field: "vpid",
  headerName: "VPID",
  flex: 0.1,
  renderCell: ({ row }) => (row.vpid ? row.vpid : ""),
};

const videoColumn: GridColDef<TableComputerVisionRun> = {
  field: "videoID",
  headerName: "Video ID",
  flex: 0.1,
  renderCell: ({ row }) => (row.videoID ? row.videoID : ""),
};

const actionsColumn: GridColDef<TableComputerVisionRun> = {
  field: "actions",
  headerName: "Actions",
  sortable: false,
  filterable: false,
  flex: 1,
  renderCell: ({ row }) => {
    const params = new URLSearchParams(location.search);
    params.set(URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN, row.id.toString(10));
    if (row.vpid) {
      params.set(URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM, row.vpid.toString(10));
    }
    if (row.videoID) {
      params.set(URL_QUERY_PARAMS.SELECTED_VIDEO, row.videoID.toString(10));
    }
    const hasCountlines = row.imageSpaceCountlines && row.imageSpaceCountlines.countlines.length > 0;

    const handleOpenNewModal = () => {
      const newValidationModalEvent: NewValidationRunModalEvent = {
        isOpen: true,
        computerVisionRun: row,
        countlinesIDs: row.imageSpaceCountlines?.countlines.map(countline => countline.countline_id) || [],
      };
      const event = new CustomEvent(NEW_VALIDATION_RUN_MODAL_EVENT, { detail: newValidationModalEvent });
      window.dispatchEvent(event);
    };

    const handleOpenCloneModal = () => {
      const cloneValidationModalEvent: CloneValidationRunModalEvent = {
        isOpen: true,
        targetComputerVisionRun: row,
      };
      const event = new CustomEvent(CLONE_VALIDATION_RUN_MODAL_EVENT, { detail: cloneValidationModalEvent });
      window.dispatchEvent(event);
    };

    const handleChangeCVRunVisibility = () => {
      const cvRunVisibilityEvent: CVRunVisibilityEvent = {
        computerVisionRunId: row.id,
        skipValidation: !row.skipValidation,
      };
      const event = new CustomEvent(CV_RUN_VISIBILITY_EVENT, { detail: cvRunVisibilityEvent });
      window.dispatchEvent(event);
    };

    const handleResetCVRunAttemptCounter = () => {
      const resetCVRunAttemptCounterEvent: CVRunResetCounterEvent = {
        computerVisionRunId: row.id,
      };
      const event = new CustomEvent(RESET_CV_RUN_ATTEMPT_COUNTER_EVENT, { detail: resetCVRunAttemptCounterEvent });
      window.dispatchEvent(event);
    };

    return (
      <Grid container alignItems={"center"} justifyContent={"start"}>
        <Grid item>
          <Tooltip enterDelay={2000} title="Watch CV Run" arrow>
            {row.videoDownloadURL ? (
              <Link
                to={{
                  pathname: RoutePath.Player,
                  search: params.toString(),
                }}
                style={{ textDecoration: "none" }}
              >
                <ThumbnailButton row={row} />
              </Link>
            ) : (
              <ThumbnailButton row={row} />
            )}
          </Tooltip>
        </Grid>
        <Grid container item flexBasis={"80%"} spacing={1}>
          {row.childValidationRunCount > 0 && (
            <Grid item>
              <Link
                to={{
                  pathname: RoutePath.ValidationRuns,
                  search: params.toString(),
                }}
                style={{ textDecoration: "none" }}
              >
                <FreneticityButton variant="outlined">Validations</FreneticityButton>
              </Link>
            </Grid>
          )}
          <Grid item>
            <a
              href={`https://centricity.vivacitylabs.com/visionProgram/${row.vpid}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <FreneticityButton variant="outlined">CM</FreneticityButton>
            </a>
          </Grid>
          <Grid item>
            <FreneticityButton variant="outlined" color="warning" onClick={handleChangeCVRunVisibility}>
              {row.skipValidation ? "Show" : "Hide"}
            </FreneticityButton>
          </Grid>
          {row.status === "COMPLETED" && (
            <>
              {hasCountlines && (
                <Grid item>
                  <FreneticityButton onClick={handleOpenNewModal}>New Validation</FreneticityButton>
                </Grid>
              )}
              {row.siblingChildValidationRunCount > 0 && (
                <Grid item>
                  <FreneticityButton onClick={handleOpenCloneModal}>New Validation From...</FreneticityButton>
                </Grid>
              )}
            </>
          )}
          {row.status === "FAILED" && (
            <Grid item>
              <FreneticityButton onClick={handleResetCVRunAttemptCounter}>Re-Run</FreneticityButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  },
};

interface ComputerVisionRunChild {
  childValidationRunCount: number;
  siblingChildValidationRunCount: number;
  latestChildValidationRun: ValidationRun;
}

type TableComputerVisionRun = ComputerVisionRun & ComputerVisionRunChild;

export const ComputerVisionRunTable: FC<ComputerVisionRunTableProps> = observer(({ displayHiddenCVRuns }) => {
  const { entitiesStore, urlStore } = useStores();

  const { selectedValidationMode } = urlStore;

  const isNearMissMode = selectedValidationMode === ValidationMode.NearMissValidation;

  const [isCVRunDetailsModalOpen, setIsCVRunDetailsModalOpen] = useState(false);
  const [showErrorsForCVRunDetails, setShowErrorsForCVRunDetails] = useState(false);
  const [isFormatResultsModalOpen, setIsFormatResultsModalOpen] = useState(false);
  const [selectedCVRunResults, setSelectedCVRunResults] = useState<TableComputerVisionRun>();

  const [currentCVRun, setCurrentCVRun] = useState(0);

  let cvRuns = entitiesStore.filteredComputerVisionRuns;

  if (!displayHiddenCVRuns) {
    cvRuns = cvRuns.filter(cvRun => !cvRun.skipValidation);
  }

  // Force a re-render of the whole table when thumbnails update, because cell renderers aren't observers
  cvRuns.forEach(cvRun => {
    const dummyThumbnailRead = cvRun.thumbnail;
    const blah = cvRun.extraThumbnails.map(thumb => thumb.thumbnail);
  });

  const tableInfo: TableComputerVisionRun[] = cvRuns
    .filter(({ isNearMiss }) => (isNearMiss ? isNearMissMode : !isNearMissMode))
    .map(cvRun => {
      const childValidationRuns = entitiesStore.getValidationRunsForCVRunID(cvRun.id);
      const childValidationRunCount = childValidationRuns.length;
      const latestChildValidationRun = childValidationRuns.sort((a, b) => b.id - a.id)[0];
      const siblingChildValidationRunCount = entitiesStore
        .getValidationRunsForVideoID(cvRun.videoID)
        .filter(r => r.cvRunID !== cvRun.id).length;
      return {
        ...cvRun,
        childValidationRunCount,
        siblingChildValidationRunCount,
        latestChildValidationRun,
      };
    });

  const onCloseCVRunDetailsAndErrorsModal = () => {
    setIsCVRunDetailsModalOpen(false);
    setShowErrorsForCVRunDetails(false);
  };
  const latestValidationStatusColumn: GridColDef<TableComputerVisionRun> = {
    field: "latestValidationStatus",
    headerName: "Latest Validation Status",
    flex: 0.35,
    renderCell: ({ row }) => {
      return (
        <Grid>
          {row.childValidationRunCount > 0 ? (
            <Grid item>
              <Grid item alignContent={"center"}>
                {row.latestChildValidationRun.passed
                  ? `Passed`
                  : row.latestChildValidationRun.status === `COMPLETED`
                  ? `Failed`
                  : `In Progress`}
                <FreneticityButton
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setIsFormatResultsModalOpen(true);
                    setSelectedCVRunResults(row);
                  }}
                >
                  Validation Results
                </FreneticityButton>
              </Grid>
            </Grid>
          ) : (
            `N/A`
          )}
        </Grid>
      );
    },
  };

  const statusColumn: GridColDef<TableComputerVisionRun> = {
    field: "status",
    headerName: "Status",
    flex: 0.2,
    renderCell: ({ row }) => {
      const detailEvent: CVRunFetchDetailsEvent = {
        computerVisionRunId: row.id,
      };

      const handleShowDetailsOrErrors = (modalType: string) => {
        const fetchEvent = new CustomEvent(CV_RUN_FETCH_DETAILS_EVENT, { detail: detailEvent });
        window.dispatchEvent(fetchEvent);
        setCurrentCVRun(detailEvent.computerVisionRunId);
        if (modalType === "detail") {
          setIsCVRunDetailsModalOpen(true);
          setShowErrorsForCVRunDetails(false);
        }
        if (modalType === "error") {
          setIsCVRunDetailsModalOpen(false);
          setShowErrorsForCVRunDetails(true);
        }
      };

      if (row.status === "PROCESSING") {
        return (
          <div style={{ width: "100%" }}>
            Processing {row.processingPercent}%
            <LinearProgress variant="determinate" value={row.processingPercent} />
          </div>
        );
      }
      let errorButton: JSX.Element | null = null;
      if (row.status === "FAILED") {
        errorButton = (
          <Button style={{ padding: "0px", minWidth: "30px" }} onClick={() => handleShowDetailsOrErrors("error")}>
            <ErrorIcon color={"error"} opacity={0.4} />
          </Button>
        );
      }
      return (
        <Grid container justifyContent={"space-between"}>
          <Grid item>{_.upperFirst(_.lowerCase(row.status))}</Grid>
          <Grid item>
            {errorButton}
            <Button style={{ padding: "0px", minWidth: "30px" }} onClick={() => handleShowDetailsOrErrors("detail")}>
              <InfoIcon color={"primary"} opacity={0.4} />
            </Button>
          </Grid>
        </Grid>
      );
    },
  };

  return (
    <>
      <DataGrid
        rows={toJS(tableInfo)}
        columns={[
          idColumn,
          rawDtfsFromSensorColumn,
          vpidColumn,
          videoColumn,
          statusColumn,
          latestValidationStatusColumn,
          actionsColumn,
        ]}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        getRowHeight={() => "auto"}
      />
      <CVRunDetailsModal
        isOpen={isCVRunDetailsModalOpen}
        onClose={() => onCloseCVRunDetailsAndErrorsModal()}
        currentCVRun={currentCVRun}
      />
      <CVRunErrorsModal
        isOpen={showErrorsForCVRunDetails}
        onClose={() => onCloseCVRunDetailsAndErrorsModal()}
        currentCVRun={currentCVRun}
      />
      <CountlineSelectionModal />
      <CloneModal />
      <FormatResultsModal
        isOpen={isFormatResultsModalOpen}
        onClose={() => setIsFormatResultsModalOpen(false)}
        selectedCVRunOutsideOfVideo={selectedCVRunResults}
        validationRunOutsideOfVideo={selectedCVRunResults?.latestChildValidationRun.id}
      />
    </>
  );
});
