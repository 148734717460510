import { observer } from "mobx-react";
import React, { FC, useRef, useState } from "react";

import Keyboard from "react-simple-keyboard";
import { KeyboardOptions } from "react-simple-keyboard/build/interfaces";
import { useStores } from "../../../hooks/useStores.hook";
import { assertExtendedKeyMapOptions, ExtendedKeyMap } from "../../../interfaces/hydration/type-assertions";
import _ from "lodash";
import { backspaceButton, downButton, leftButton, returnButton, rightButton, upButton } from "./icons";

const generateKey = (biggerLetter: string, mainText?: string, smallText?: string, tooltip?: string): string =>
  `<span class="kbb" title="${tooltip ?? ""}">
     <span class=${`kbb-big-letter${mainText ? "" : "-inactive"}`}>${biggerLetter}</span>
     ${mainText ? `<span class="kbb-main-text">${mainText}</span>` : ""}
     ${smallText ? `<span class="kbb-small-text ${mainText ? "" : "kbb-small-text-grow"}">${smallText}</span>` : ``}
   </span>`;

export const VirtualKeyToMousetrapConversion = {
  "{escape}": "esc",
  "{tab}": "tab",
  "{backspace}": "backspace",
  "{enter}": "enter",
  "{capslock}": "capslock",
  "{shiftleft}": "LShift",
  "{shiftright}": "RShift",
  "{controlleft}": "LControl",
  "{controlright}": "RControl",
  "{altleft}": "LAlt",
  "{altright}": "RAlt",
  "{altrightgr}": "RAltGraph",
  "{metaleft}": "LMeta",
  "{metaright}": "RMeta",
  "{space}": "space",
  "{numpaddivide}": "Numpad/",
  "{numlock}": "NumLock",
  "{arrowup}": "up",
  "{arrowleft}": "left",
  "{arrowdown}": "down",
  "{arrowright}": "right",
  "{prtscr}": "PrintScreen",
  "{scrolllock}": "ScrollLock",
  "{pause}": "Pause",
  "{numpadmultiply}": "Numpad*",
  "{numpadsubtract}": "Numpad-",
  "{numpadadd}": "Numpad+",
  "{numpadenter}": "NumpadEnter",
  "{numpaddecimal}": ".",
  "{numpad0}": "Numpad0",
  "{numpad1}": "Numpad1",
  "{numpad2}": "Numpad2",
  "{numpad3}": "Numpad3",
  "{numpad4}": "Numpad4",
  "{numpad5}": "Numpad5",
  "{numpad6}": "Numpad6",
  "{numpad7}": "Numpad7",
  "{numpad8}": "Numpad8",
  "{numpad9}": "Numpad9",
  "{numpadinsert}": "NumpadInsert",
  "{numpaddelete}": "Numpad ", // WTF is going on with this one?
  "{numpadend}": "NumpadEnd",
  "{numpadarrowdown}": "NumpadArrowDown",
  "{numpadpagedown}": "NumpadPageDown",
  "{numpadarrowleft}": "NumpadArrowLeft",
  "{numpadclear}": "NumpadClear",
  "{numpadarrowright}": "NumpadArrowRight",
  "{numpadhome}": "NumpadHome",
  "{numpadarrowup}": "NumpadArrowUp",
  "{numpadpageup}": "NumpadPageUp",
  "{+}": "plus",
  "{pageup}": "pageup",
  "{pagedown}": "pagedown",
};

export const DefaultDisplay = {
  "{escape}": "esc ⎋",
  "{tab}": "tab ⇥",
  "{backspace}": backspaceButton,
  "{enter}": returnButton,
  "{capslock}": "caps lock ⇪",
  "{shiftleft}": "shift ⇧",
  "{shiftright}": "shift ⇧",
  "{controlleft}": "ctrl ⌃",
  "{controlright}": "ctrl ⌃",
  "{altleft}": "alt ⌥",
  "{altright}": "alt ⌥",
  "{altrightgr}": "alt gr",
  "{metaleft}": "cmd ⌘",
  "{metaright}": "cmd ⌘",
  "{space}": " ",
  "{f1}": "F1",
  "{f2}": "F2",
  "{f3}": "F3",
  "{f4}": "F4",
  "{f5}": "F5",
  "{f6}": "F6",
  "{f7}": "F7",
  "{f8}": "F8",
  "{f9}": "F9",
  "{f10}": "F10",
  "{f11}": "F11",
  "{f12}": "F12",
  "{numpaddivide}": "/",
  "{numlock}": "lock",
  "{arrowup}": upButton,
  "{arrowleft}": leftButton,
  "{arrowdown}": downButton,
  "{arrowright}": rightButton,
  "{prtscr}": "print",
  "{scrolllock}": "scroll",
  "{pause}": "pause",
  "{insert}": "ins",
  "{home}": "home",
  "{pageup}": "up",
  "{delete}": "del",
  "{end}": "end",
  "{pagedown}": "down",
  "{numpadmultiply}": "*",
  "{numpadsubtract}": "-",
  "{numpadadd}": "+",
  "{numpadenter}": "enter",
  "{numpaddecimal}": ".",
  "{numpad0}": "0",
  "{numpad1}": "1",
  "{numpad2}": "2",
  "{numpad3}": "3",
  "{numpad4}": "4",
  "{numpad5}": "5",
  "{numpad6}": "6",
  "{numpad7}": "7",
  "{numpad8}": "8",
  "{numpad9}": "9",
  "{numpadinsert}": "ins",
  "{numpaddelete}": "del",
  "{numpadend}": "end",
  "{numpadarrowdown}": downButton,
  "{numpadpagedown}": "down",
  "{numpadarrowleft}": leftButton,
  "{numpadclear}": "clear",
  "{numpadarrowright}": rightButton,
  "{numpadhome}": "home",
  "{numpadarrowup}": upButton,
  "{numpadpageup}": "up",
  "{0}": "0",
  "{1}": "1",
  "{2}": "2",
  "{3}": "3",
  "{4}": "4",
  "{5}": "5",
  "{6}": "6",
  "{7}": "7",
  "{8}": "8",
  "{9}": "9",
  "{`}": "`",
  "{!}": "!",
  '{"}': '"',
  "{£}": "£",
  "{$}": "$",
  "{%}": "%",
  "{^}": "^",
  "{&}": "&",
  "{*}": "*",
  "{(}": "(",
  "{)}": ")",
  "{_}": "_",
  "{+}": "+",
  "{¬}": "¬",
  "{#}": "#",
  "{:}": ":",
  "{@}": "@",
  "{~}": "~",
  "{|}": "|",
  "{{}": "{",
  "{}}": "}",
  "{-}": "-",
  "{=}": "=",
  "{;}": ";",
  "{'}": "'",
  "{\\}": "\\",
  "{[}": "[",
  "{]}": "]",
  "{,}": ",",
  "{.}": ".",
  "{/}": "/",
  "{<}": "<",
  "{>}": ">",
  "{?}": "?",
  "{q}": "q",
  "{w}": "w",
  "{e}": "e",
  "{r}": "r",
  "{t}": "t",
  "{y}": "y",
  "{u}": "u",
  "{i}": "i",
  "{o}": "o",
  "{p}": "p",
  "{a}": "a",
  "{s}": "s",
  "{d}": "d",
  "{f}": "f",
  "{g}": "g",
  "{h}": "h",
  "{j}": "j",
  "{k}": "k",
  "{l}": "l",
  "{z}": "z",
  "{x}": "x",
  "{c}": "c",
  "{v}": "v",
  "{b}": "b",
  "{n}": "n",
  "{m}": "m",
  "{Q}": "Q",
  "{W}": "W",
  "{E}": "E",
  "{R}": "R",
  "{T}": "T",
  "{Y}": "Y",
  "{U}": "U",
  "{I}": "I",
  "{O}": "O",
  "{P}": "P",
  "{A}": "A",
  "{S}": "S",
  "{D}": "D",
  "{F}": "F",
  "{G}": "G",
  "{H}": "H",
  "{J}": "J",
  "{K}": "K",
  "{L}": "L",
  "{Z}": "Z",
  "{X}": "X",
  "{C}": "C",
  "{V}": "V",
  "{B}": "B",
  "{N}": "N",
  "{M}": "M",
};

export const MouseTrapToVirtualKeyConversion = _.invert({ ...DefaultDisplay, ...VirtualKeyToMousetrapConversion });

type ModifierKeyState = {
  capslockPressed: boolean;
  numlockPressed: boolean;
  leftShiftPressed: boolean;
  leftCtrlPressed: boolean;
  leftAltPressed: boolean;
  leftMetaPressed: boolean;
  rightShiftPressed: boolean;
  rightCtrlPressed: boolean;
  rightAltPressed: boolean;
  rightAltGrPressed: boolean;
  rightMetaPressed: boolean;
};

const checkIfKeyIsReady = (
  keyMap: ExtendedKeyMap,
  modifiers: ModifierKeyState,
  onReady: (virtualKey: string, handlerName: string) => void
) => {
  const {
    capslockPressed,
    numlockPressed,
    leftShiftPressed,
    leftCtrlPressed,
    leftAltPressed,
    leftMetaPressed,
    rightShiftPressed,
    rightCtrlPressed,
    rightAltPressed,
    rightAltGrPressed,
    rightMetaPressed,
  } = modifiers;

  Object.keys(keyMap).map(handlerName => {
    const handlerDetails = assertExtendedKeyMapOptions(keyMap[handlerName]);
    handlerDetails.sequences.map(sequence => {
      if (handlerName !== "SWALLOW_DEFAULTS_WHILE_RECORDING") {
        const boundKeys = (sequence as string).split("+");
        // check if modifier keys are pressed in virtual keyboard, and gobble those from the sequence
        if (capslockPressed) {
          _.pull(boundKeys, "capslock");
        }
        if (leftShiftPressed) {
          _.pull(boundKeys, "LShift");
        }
        if (rightShiftPressed) {
          _.pull(boundKeys, "RShift");
        }
        if (numlockPressed) {
          _.pull(boundKeys, "NumLock");
        }
        if (leftCtrlPressed) {
          _.pull(boundKeys, "LControl");
        }
        if (rightCtrlPressed) {
          _.pull(boundKeys, "RControl");
        }
        if (leftAltPressed) {
          _.pull(boundKeys, "LAlt");
        }
        if (rightAltPressed) {
          _.pull(boundKeys, "RAlt");
        }
        if (rightAltGrPressed) {
          _.pull(boundKeys, "RAltGraph");
        }
        if (leftMetaPressed) {
          _.pull(boundKeys, "LMeta");
        }
        if (rightMetaPressed) {
          _.pull(boundKeys, "RMeta");
        }
        if (boundKeys.length === 1) {
          // If there's only 1 key left in the sequence, then replace the display for that key with the handler details
          const virtualKey = MouseTrapToVirtualKeyConversion[boundKeys[0]];
          onReady(virtualKey, handlerName);
        }
      }
    });
  });
};

export const FullKeyboard: FC<{}> = observer(() => {
  const { playerUIStore } = useStores();
  const [capslockPressed, setCapslockPressed] = useState<boolean>(false);
  const [numlockPressed, setNumlockPressed] = useState<boolean>(true);
  const [leftShiftPressed, setLeftShiftPressed] = useState<boolean>(false);
  const [leftCtrlPressed, setLeftCtrlPressed] = useState<boolean>(false);
  const [leftAltPressed, setLeftAltPressed] = useState<boolean>(false);
  const [leftMetaPressed, setLeftMetaPressed] = useState<boolean>(false);
  const [rightShiftPressed, setRightShiftPressed] = useState<boolean>(false);
  const [rightCtrlPressed, setRightCtrlPressed] = useState<boolean>(false);
  const [rightAltPressed, setRightAltPressed] = useState<boolean>(false);
  const [rightAltGrPressed, setRightAltGrPressed] = useState<boolean>(false);
  const [rightMetaPressed, setRightMetaPressed] = useState<boolean>(false);

  let keyboard = null;

  const modifierState: ModifierKeyState = {
    capslockPressed,
    numlockPressed,
    leftShiftPressed,
    leftCtrlPressed,
    leftAltPressed,
    leftMetaPressed,
    rightShiftPressed,
    rightCtrlPressed,
    rightAltPressed,
    rightAltGrPressed,
    rightMetaPressed,
  };

  const onKeyPress = (input: string, e?: MouseEvent) => {
    if (input === "{capslock}") {
      setCapslockPressed(prev => !prev);
    }
    if (input === "{numlock}") {
      setNumlockPressed(prev => !prev);
    }
    if (input === "{shiftleft}") {
      setLeftShiftPressed(prev => !prev);
    }
    if (input === "{shiftright}") {
      setRightShiftPressed(prev => !prev);
    }
    if (input === "{controlleft}") {
      setLeftCtrlPressed(prev => !prev);
    }
    if (input === "{controlright}") {
      setRightCtrlPressed(prev => !prev);
    }
    if (input === "{altleft}") {
      setLeftAltPressed(prev => !prev);
    }
    if (input === "{altright}") {
      setRightAltPressed(prev => !prev);
    }
    if (input === "{altrightgr}") {
      setRightAltGrPressed(prev => !prev);
    }
    if (input === "{metaleft}") {
      setLeftMetaPressed(prev => !prev);
    }
    if (input === "{metaright}") {
      setRightMetaPressed(prev => !prev);
    }
    checkIfKeyIsReady(playerUIStore.currentKeyMap, modifierState, (virtualKey, handlerName) => {
      if (virtualKey === input) {
        // TODO - should we create a synthetic Keyboard event and pass it in?
        playerUIStore.keyboardHandlers[handlerName]();
      }
    });
  };

  const newPressedButtons: string[] = [];

  if (capslockPressed) {
    newPressedButtons.push("{capslock}");
  }
  if (leftShiftPressed) {
    newPressedButtons.push("{shiftleft}");
  }
  if (rightShiftPressed) {
    newPressedButtons.push("{shiftright}");
  }
  if (numlockPressed) {
    newPressedButtons.push("{numlock}");
  }
  if (leftCtrlPressed) {
    newPressedButtons.push("{controlleft}");
  }
  if (rightCtrlPressed) {
    newPressedButtons.push("{controlright}");
  }
  if (leftAltPressed) {
    newPressedButtons.push("{altleft}");
  }
  if (rightAltPressed) {
    newPressedButtons.push("{altright}");
  }
  if (rightAltGrPressed) {
    newPressedButtons.push("{altrightgr}");
  }
  if (leftMetaPressed) {
    newPressedButtons.push("{metaleft}");
  }
  if (rightMetaPressed) {
    newPressedButtons.push("{metaright}");
  }

  const newDisplayMap = {
    ...DefaultDisplay,
  };

  checkIfKeyIsReady(playerUIStore.currentKeyMap, modifierState, (virtualKey, handlerName) => {
    newDisplayMap[virtualKey] = generateKey(
      DefaultDisplay[virtualKey],
      playerUIStore.currentKeyMap[handlerName].name,
      playerUIStore.currentKeyMap[handlerName].extraData,
      playerUIStore.currentKeyMap[handlerName].description
    );
  });

  const layoutName = leftShiftPressed || rightShiftPressed || capslockPressed ? "shift" : "default";

  const numPadLayoutName = numlockPressed ? "default" : "not_numlock";

  const commonKeyboardOptions: KeyboardOptions = {
    onKeyPress,
    keyboardRef: k => {
      keyboard = k;
    },
    buttonTheme: [],
    theme: "simple-keyboard hg-theme-default hg-layout-default",
    physicalKeyboardHighlight: true,
    syncInstanceInputs: true,
    stopMouseUpPropagation: true,
    stopMouseDownPropagation: true,
    preventMouseUpDefault: true,
    preventMouseDownDefault: true,
  };
  if (newPressedButtons.length) {
    commonKeyboardOptions.buttonTheme?.push({
      class: "selectedButton",
      buttons: newPressedButtons.join(" "),
    });
  }

  const keyboardOptions = {
    ...commonKeyboardOptions,
    layout: {
      default: [
        "{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}",
        "{`} {1} {2} {3} {4} {5} {6} {7} {8} {9} {0} {-} {=} {backspace}",
        "{tab} {q} {w} {e} {r} {t} {y} {u} {i} {o} {p} {[} {]}",
        "{capslock} {a} {s} {d} {f} {g} {h} {j} {k} {l} {;} {'} {#} {enter}",
        "{shiftleft} {\\} {z} {x} {c} {v} {b} {n} {m} {,} {.} {/} {shiftright}",
        "{controlleft} {altleft} {metaleft} {space} {metaright} {altright} {altrightgr} {controlright}",
      ],
      shift: [
        "{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}",
        `{¬} {!} {"} {£} {$} {%} {^} {&} {*} {(} {)} {_} {+} {backspace}`,
        "{tab} {Q} {W} {E} {R} {T} {Y} {U} {I} {O} {P} {{} {}}",
        "{capslock} {A} {S} {D} {F} {G} {H} {J} {K} {L} {:} {@} {~} {enter}",
        "{shiftleft} {|} {Z} {X} {C} {V} {B} {N} {M} {<} {>} {?} {shiftright}",
        "{controlleft} {altleft} {metaleft} {space} {metaright} {altright} {altrightgr} {controlright}",
      ],
    },
    display: newDisplayMap,
  };

  const keyboardControlPadOptions = {
    ...commonKeyboardOptions,
    layout: {
      default: ["{prtscr} {scrolllock} {pause}", "{insert} {home} {pageup}", "{delete} {end} {pagedown}"],
    },
    display: keyboardOptions.display,
  };

  const keyboardArrowsOptions = {
    ...commonKeyboardOptions,
    layout: {
      default: ["{arrowup}", "{arrowleft} {arrowdown} {arrowright}"],
    },
    display: keyboardOptions.display,
  };

  const keyboardNumPadOptions = {
    ...commonKeyboardOptions,
    layout: {
      default: [
        "{numlock} {numpaddivide} {numpadmultiply}",
        "{numpad7} {numpad8} {numpad9}",
        "{numpad4} {numpad5} {numpad6}",
        "{numpad1} {numpad2} {numpad3}",
        "{numpad0} {numpaddecimal}",
      ],
      not_numlock: [
        "{numlock} {numpaddivide} {numpadmultiply}",
        "{numpadhome} {numpadarrowup} {numpadpageup}",
        "{numpadarrowleft} {numpadclear} {numpadarrowright}",
        "{numpadend} {numpadarrowdown} {numpadpagedown}",
        "{numpadinsert} {numpaddelete}",
      ],
    },
    display: keyboardOptions.display,
  };

  const keyboardNumPadEndOptions = {
    ...commonKeyboardOptions,
    layout: {
      default: ["{numpadsubtract}", "{numpadadd}", "{numpadenter}"],
    },
    display: keyboardOptions.display,
  };
  return (
    <div>
      <div className={"keyboardContainer"}>
        <Keyboard baseClass={"simple-keyboard-main"} layoutName={layoutName} {...keyboardOptions} />

        <div className="controlArrows">
          <Keyboard baseClass={"simple-keyboard-control"} {...keyboardControlPadOptions} />
          <Keyboard baseClass={"simple-keyboard-arrows"} {...keyboardArrowsOptions} />
        </div>

        <div className="numPad">
          <Keyboard baseClass={"simple-keyboard-numpad"} layoutName={numPadLayoutName} {...keyboardNumPadOptions} />
          <Keyboard baseClass={"simple-keyboard-numpadEnd"} {...keyboardNumPadEndOptions} />
        </div>
      </div>
    </div>
  );
});
