import React, { FC, useEffect } from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RoutePath } from "./enums";
import { StoresProvider } from "./providers/Stores.provider";
import { QueryParamProvider } from "use-query-params";
import { createRoot } from "react-dom/client";
import { VideosLayout } from "./layouts/Videos.layout";
import { VisionProgramsLayout } from "./layouts/VisionPrograms.layout";
import { ValidationRunsLayout } from "./layouts/ValidationRuns.layout";
import { ComputerVisionRunsLayout } from "./layouts/ComputerVisionRuns.layout";
import { JwtProvider } from "./providers/Jwt.provider";
import { HydrationProvider } from "./providers/Hydration.provider";
import PlayerUI from "./components/PlayerUI";

// TODO - don't forget to remove this once finished
// import whyDidYouRender from '@welldone-software/why-did-you-render';
// /** @jsxImportSource @welldone-software/why-did-you-render */
// if (process.env.NODE_ENV === "development") {
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

export const App: FC = () => {
  useEffect(() => {
    document.title = "Freneticity 2 - Revenge of the Countline";
  }, []);

  return (
    <BrowserRouter>
      <QueryParamProvider>
        <StoresProvider>
          <JwtProvider>
            <HydrationProvider>
              <Routes>
                <Route key={RoutePath.Root} path={RoutePath.Root} element={<VisionProgramsLayout />} />
                <Route key={RoutePath.Player} path={RoutePath.Player} element={<PlayerUI />} />
                <Route key={RoutePath.Videos} path={RoutePath.Videos} element={<VideosLayout />} />
                <Route
                  key={RoutePath.ComputerVisionRuns}
                  path={RoutePath.ComputerVisionRuns}
                  element={<ComputerVisionRunsLayout />}
                />

                <Route
                  key={RoutePath.ValidationRuns}
                  path={RoutePath.ValidationRuns}
                  element={<ValidationRunsLayout />}
                />
              </Routes>
            </HydrationProvider>
          </JwtProvider>
        </StoresProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <App />
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
