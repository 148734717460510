import { ClassifyingDetectorClassTypeNumber } from "./utils";

export const colourMap: { [key: number]: number } = {
  1: 0x00a0df,
  2: 0x2e3c99,
  3: 0x93ba4a,
  4: 0xc73f6d,
  5: 0xffc8cf,
  6: 0x97a2ad,
  7: 0x6b9a4a,
  8: 0x477d49,
  9: 0x78848e,
  10: 0x5b6670,
  11: 0xa02a4d,
  12: 0x78152e,
  13: 0x51000e,
  14: 0x91e7ff,
  15: 0x61cff4,
  16: 0x30b8ea,
  17: 0x007cb2,
  18: 0x005784,
  19: 0x003357,
  20: 0x1a227f,
  21: 0x00005d,
  22: 0xec9aaf,
  23: 0xda6d8e,
  24: 0xd7e3ef,
  25: 0xb6c2ce,
  26: 0x246149,
  27: 0x004448,
  28: 0x0c171f,
  29: 0x0c171f,
  30: 0x0c171f,
  31: 0x0c171f,
  32: 0x0c171f,
};

export const getColourFromClass = (classID: ClassifyingDetectorClassTypeNumber): number => {
  return colourMap[classID] ?? 0x000000;
};
