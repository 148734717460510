import { CVRunID } from "../domain";
import { TriggerReason, TriggerType, VideoStatus } from "../enums";
import { NearMissEventV2 } from "../vivacity/core/track_head_pb";

export interface Video {
  id: number;
  vpid: number;
  requestId: string;
  thumbnailUrl: string;
  rawVideoPath: string | undefined;
  downloadUrl: string | undefined;
  bitrate: number;
  buffers: number;
  frameRateNumerator: number;
  frameRateDenominator: number;
  height: number;
  width: number;
  meta: object | undefined;
  recordingProgress: number | undefined;
  uploadingProgress: number | undefined;
  triggerReason: TriggerReason;
  triggerType: TriggerType;
  status: VideoStatus;
  // Should probably think about using Luxon
  startAt: Date | undefined;
  endAt: Date | undefined;
  startFrame: number | undefined;
  endFrame: number | undefined;
  totalBytes: number | undefined;
  bucketPath: string | undefined;
  updatedAt: Date | undefined;
  requestedBy: string;
  updatedBy: string | undefined;
  computerVisionRuns: CVRunID[] | undefined;
  errors: string | undefined;
  thumbnail: string | undefined;
  extraThumbnails: ExtraThumbnail[];
  shouldRetain: boolean;
  ogDtfNearMissEvents: NearMissEventV2[];
}

export interface ExtraThumbnail {
  thumbnail: string;
  generation: number;
}

export const VideoSearchableFields: { [fieldName in keyof Video]: boolean } = {
  id: true,
  vpid: true,
  requestId: false,
  thumbnailUrl: false,
  rawVideoPath: false,
  downloadUrl: false,
  bitrate: false,
  buffers: false,
  frameRateNumerator: false,
  frameRateDenominator: false,
  height: false,
  width: false,
  meta: false,
  recordingProgress: false,
  uploadingProgress: false,
  triggerReason: true,
  triggerType: true,
  status: true,
  startAt: true,
  endAt: true,
  startFrame: false,
  endFrame: false,
  totalBytes: false,
  bucketPath: false,
  updatedAt: false,
  requestedBy: true,
  updatedBy: true,
  computerVisionRuns: true,
  errors: true,
  thumbnail: false,
  extraThumbnails: false,
  shouldRetain: false,
  ogDtfNearMissEvents: true,
};
