/* eslint-disable */
// source: vivacity/config/pipeline.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_entity_templates_pb = require('../../vivacity/config/entity_templates_pb.js');
goog.object.extend(proto, vivacity_config_entity_templates_pb);
var vivacity_config_config_message_pb = require('../../vivacity/config/config_message_pb.js');
goog.object.extend(proto, vivacity_config_config_message_pb);
goog.exportSymbol('proto.vivacity.config.PipelineReconfigured', null, global);
goog.exportSymbol('proto.vivacity.config.ReconfigurePipeline', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ReconfigurePipeline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ReconfigurePipeline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ReconfigurePipeline.displayName = 'proto.vivacity.config.ReconfigurePipeline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.PipelineReconfigured = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.PipelineReconfigured, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.PipelineReconfigured.displayName = 'proto.vivacity.config.PipelineReconfigured';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ReconfigurePipeline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ReconfigurePipeline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ReconfigurePipeline.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ReconfigurePipeline}
 */
proto.vivacity.config.ReconfigurePipeline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ReconfigurePipeline;
  return proto.vivacity.config.ReconfigurePipeline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ReconfigurePipeline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ReconfigurePipeline}
 */
proto.vivacity.config.ReconfigurePipeline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (reader.readEnum());
      msg.setEntityType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ReconfigurePipeline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ReconfigurePipeline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ReconfigurePipeline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional EntityTemplateType.EntityType entity_type = 1;
 * @return {!proto.vivacity.config.EntityTemplateType.EntityType}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getEntityType = function() {
  return /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.EntityTemplateType.EntityType} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 entity_id = 2;
 * @return {number}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string correlation_id = 3;
 * @return {string}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OriginatingSystem originating_system = 4;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 user_id = 5;
 * @return {number}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 timestamp_microseconds = 6;
 * @return {number}
 */
proto.vivacity.config.ReconfigurePipeline.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ReconfigurePipeline} returns this
 */
proto.vivacity.config.ReconfigurePipeline.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.PipelineReconfigured.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.PipelineReconfigured.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.PipelineReconfigured} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PipelineReconfigured.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconfigurePipeline: (f = msg.getReconfigurePipeline()) && proto.vivacity.config.ReconfigurePipeline.toObject(includeInstance, f),
    errors: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.PipelineReconfigured}
 */
proto.vivacity.config.PipelineReconfigured.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.PipelineReconfigured;
  return proto.vivacity.config.PipelineReconfigured.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.PipelineReconfigured} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.PipelineReconfigured}
 */
proto.vivacity.config.PipelineReconfigured.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.ReconfigurePipeline;
      reader.readMessage(value,proto.vivacity.config.ReconfigurePipeline.deserializeBinaryFromReader);
      msg.setReconfigurePipeline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrors(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.PipelineReconfigured.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.PipelineReconfigured.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.PipelineReconfigured} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.PipelineReconfigured.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconfigurePipeline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.ReconfigurePipeline.serializeBinaryToWriter
    );
  }
  f = message.getErrors();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional ReconfigurePipeline reconfigure_pipeline = 1;
 * @return {?proto.vivacity.config.ReconfigurePipeline}
 */
proto.vivacity.config.PipelineReconfigured.prototype.getReconfigurePipeline = function() {
  return /** @type{?proto.vivacity.config.ReconfigurePipeline} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.ReconfigurePipeline, 1));
};


/**
 * @param {?proto.vivacity.config.ReconfigurePipeline|undefined} value
 * @return {!proto.vivacity.config.PipelineReconfigured} returns this
*/
proto.vivacity.config.PipelineReconfigured.prototype.setReconfigurePipeline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.PipelineReconfigured} returns this
 */
proto.vivacity.config.PipelineReconfigured.prototype.clearReconfigurePipeline = function() {
  return this.setReconfigurePipeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.PipelineReconfigured.prototype.hasReconfigurePipeline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string errors = 2;
 * @return {string}
 */
proto.vivacity.config.PipelineReconfigured.prototype.getErrors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.PipelineReconfigured} returns this
 */
proto.vivacity.config.PipelineReconfigured.prototype.setErrors = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.config.PipelineReconfigured.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.PipelineReconfigured} returns this
 */
proto.vivacity.config.PipelineReconfigured.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.vivacity.config);
