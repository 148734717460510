import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";

import { useStores } from "../../hooks/useStores.hook";

import { FreneticityButton } from "../styledElements";
import { TransitionProps } from "@mui/material/transitions";

export const EasyDeleteValidationRunConfirmationModal: FC = observer(() => {
  const { validationRunStore } = useStores();

  const onClose = () => {
    validationRunStore.setProposedDeletionValidationRunID(undefined);
  };

  const handleConfirm = () => {
    validationRunStore.deleteProposedValidationRun();
  };

  return (
    <Dialog
      open={!!validationRunStore.proposedDeletionValidationRunID && validationRunStore.easyDeletionMode}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <b>Validation {validationRunStore.proposedDeletionValidationRunID}</b>?
        <br />
        <br />
        This operation is final and cannot be undone.
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton color="error" onClick={handleConfirm}>
          Confirm
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
