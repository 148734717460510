import { Cancel, Search } from "@mui/icons-material";
import {
  IconButton,
  InputBase,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  styled,
} from "@mui/material";
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";
import { useStores } from "../hooks/useStores.hook";

interface FilterSearchBarProps {
  filter: string;
  onFilterChange: (filter: string) => void;
  placeholderText: string;
  defaultFilter: string;
  disabled?: boolean;
  enterToFilter?: boolean;
}

export const FilterSearchBar: FC<FilterSearchBarProps> = ({
  filter,
  onFilterChange,
  placeholderText,
  defaultFilter,
  disabled,
  enterToFilter,
}) => {
  const { urlStore } = useStores();
  const [localFilter, setLocalFilter] = useState<string>("");

  useEffect(() => {
    setLocalFilter(filter);
    urlStore.setParams({ search: filter === "" ? undefined : filter }, "replaceIn");
  }, [filter]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    setLocalFilter(value);
    if (!enterToFilter) {
      onFilterChange(value);
    }
  };

  const onFilterInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.keyCode === 27) {
      onClearFilter();
    }
  };

  const onClearFilter = (): void => {
    setLocalFilter(defaultFilter);
    onFilterChange(defaultFilter);
  };

  const onEnter = (eventKey: KeyboardEvent<HTMLInputElement>): void => {
    if (eventKey.key === "Enter" && enterToFilter) {
      onFilterChange(localFilter);
    }
  };

  return (
    <SearchBar>
      <SearchIcon aria-label="search">
        <Search color="disabled" />
      </SearchIcon>
      <ListItemText
        primary={
          <FullWidthInputBase
            onKeyUp={onEnter}
            placeholder={placeholderText}
            inputProps={{ "aria-label": "search for asset" }}
            onChange={onInputChange}
            onKeyDown={onFilterInputKeyDown}
            value={localFilter}
            disabled={disabled}
          />
        }
      />
      <StyledListItemSecondaryAction>
        {localFilter && (
          <IconButton title="Clear filter" size="small" onClick={onClearFilter} disabled={disabled}>
            <Cancel />
          </IconButton>
        )}
      </StyledListItemSecondaryAction>
    </SearchBar>
  );
};

const SearchBar = styled(ListItem)(({ theme }) => ({
  height: theme.spacing(7),
})) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

const SearchIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(4),
  marginRight: theme.spacing(1 / 2),
}));

const FullWidthInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  height: theme.spacing(3),
}));

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
  right: -theme.spacing(3.5),
}));
