import { ClassifyingDetectorClassTypes } from "../vivacity/core/classifying_detector_class_types_pb";
import { UniversalEnvelope } from "../vivacity/universal_envelope_pb";
import { SnappiVideoCaptureRecordingRequested } from "../vivacity/core/snappi_pb";
import { SceneCaptureTriggerReason } from "../vivacity/core/scene_capture_trigger_reasons_pb";
import { NearMissPair } from "../vivacity/core/track_head_pb";

export type transposerFunc = typeof transposeMap;

export const transposeMap = <K extends string, V extends number>(originalMap: { [key in K]: V }): { [key in V]: K } => {
  return Object.keys(originalMap)
    .map(key => [originalMap[key], key])
    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {} as { [key in V]: K });
};

export const classNameByID = transposeMap(ClassifyingDetectorClassTypes);

export const universalEnvelopeMessageType = transposeMap(UniversalEnvelope.MessageType);
export const VideoType = transposeMap(SnappiVideoCaptureRecordingRequested.VideoType);
export const TriggerReason = transposeMap(SceneCaptureTriggerReason);
export const NearMissPairMap = transposeMap(NearMissPair);
