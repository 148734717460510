import React from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ElectricScooterIcon from "@mui/icons-material/ElectricScooter";
import MopedIcon from "@mui/icons-material/Moped";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ElectricRickshawIcon from "@mui/icons-material/ElectricRickshaw";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import StrollerIcon from "@mui/icons-material/Stroller";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import PetsIcon from "@mui/icons-material/Pets";
import AbcIcon from "@mui/icons-material/Abc";
import GrassIcon from "@mui/icons-material/Grass";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import { ClassifyingDetectorClassTypeNumber, classLookup } from "../../../workers/utils";
import { classNameByID } from "../../../enums/proto.enum";
import { colourMap } from "../../../workers/colourMap";

export const iconMap = {
  PEDESTRIAN: <EmojiPeopleIcon />,
  CYCLIST: <DirectionsBikeIcon />,
  MOTORBIKE: <TwoWheelerIcon />,
  CAR: <DirectionsCarIcon />,
  TAXI: <LocalTaxiIcon />,
  VAN: <AirportShuttleIcon />,
  MINIBUS: <AirportShuttleIcon />,
  BUS: <DirectionsBusFilledIcon />,
  RIGID: <LocalShippingIcon />,
  TRUCK: <LocalShippingIcon />,
  EMERGENCY_CAR: <LocalHospitalIcon />,
  EMERGENCY_VAN: <LocalHospitalIcon />,
  FIRE_ENGINE: <FireExtinguisherIcon />,
  WHEELCHAIR: <AccessibleForwardIcon />,
  MOBILITY_SCOOTER: <MopedIcon />,
  PUSHCHAIR: <StrollerIcon />,
  JOGGER: <DirectionsRunIcon />,
  E_SCOOTER: <ElectricScooterIcon />,
  PUSH_SCOOTER: <BikeScooterIcon />,
  RENTAL_BIKE: <DirectionsBikeIcon />,
  CARGO_COURIER_BIKE: <ElectricRickshawIcon />,
  TAXI_ELECTRIC: <ElectricCarIcon />,
  TAXI_OTHER: <LocalTaxiIcon />,
  VAN_SMALL: <DirectionsCarIcon />,
  VAN_LUTON: <LocalShippingIcon />,
  BUS_COACH: <DirectionsBusFilledIcon />,
  BUS_LONDON: <DirectionsBusFilledIcon />,
  TOWED_TRAILER: <RvHookupIcon />,
  TRACTOR: <AgricultureIcon />,
  AGRICULTURAL_VEHICLE: <AgricultureIcon />,
  HORSE_RIDER: <BedroomBabyIcon />,
  DOG: <PetsIcon />,
  LICENSE_PLATE: <AbcIcon />,
  POWERED_WATERCRAFT: <DirectionsBoatIcon />,
  UNPOWERED_WATERCRAFT: <HouseboatIcon />,
  PLAYER: <DirectionsRunIcon />,
  MAINTENANCE_VEHICLE: <AgricultureIcon />,
  LAWNMOWER: <GrassIcon />,
};

export function getIconForClass(classID: ClassifyingDetectorClassTypeNumber, props: object): JSX.Element {
  const element = iconMap[classNameByID[classID]];
  if (!element) {
    return <DeviceUnknownIcon />;
  }
  return React.cloneElement(element, props);
}
