/* eslint-disable */
// source: vivacity/core/tensor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.core.DataType', null, global);
goog.exportSymbol('proto.vivacity.core.Tensor', null, global);
goog.exportSymbol('proto.vivacity.core.TensorShape', null, global);
goog.exportSymbol('proto.vivacity.core.TensorShape.Dim', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TensorShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TensorShape.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TensorShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TensorShape.displayName = 'proto.vivacity.core.TensorShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TensorShape.Dim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.TensorShape.Dim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TensorShape.Dim.displayName = 'proto.vivacity.core.TensorShape.Dim';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Tensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.Tensor.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.Tensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Tensor.displayName = 'proto.vivacity.core.Tensor';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TensorShape.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TensorShape.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TensorShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TensorShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TensorShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimList: jspb.Message.toObjectList(msg.getDimList(),
    proto.vivacity.core.TensorShape.Dim.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TensorShape}
 */
proto.vivacity.core.TensorShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TensorShape;
  return proto.vivacity.core.TensorShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TensorShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TensorShape}
 */
proto.vivacity.core.TensorShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.vivacity.core.TensorShape.Dim;
      reader.readMessage(value,proto.vivacity.core.TensorShape.Dim.deserializeBinaryFromReader);
      msg.addDim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TensorShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TensorShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TensorShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TensorShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.TensorShape.Dim.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TensorShape.Dim.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TensorShape.Dim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TensorShape.Dim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TensorShape.Dim.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TensorShape.Dim}
 */
proto.vivacity.core.TensorShape.Dim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TensorShape.Dim;
  return proto.vivacity.core.TensorShape.Dim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TensorShape.Dim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TensorShape.Dim}
 */
proto.vivacity.core.TensorShape.Dim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TensorShape.Dim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TensorShape.Dim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TensorShape.Dim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TensorShape.Dim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 size = 1;
 * @return {number}
 */
proto.vivacity.core.TensorShape.Dim.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TensorShape.Dim} returns this
 */
proto.vivacity.core.TensorShape.Dim.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.core.TensorShape.Dim.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.TensorShape.Dim} returns this
 */
proto.vivacity.core.TensorShape.Dim.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Dim dim = 2;
 * @return {!Array<!proto.vivacity.core.TensorShape.Dim>}
 */
proto.vivacity.core.TensorShape.prototype.getDimList = function() {
  return /** @type{!Array<!proto.vivacity.core.TensorShape.Dim>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TensorShape.Dim, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.TensorShape.Dim>} value
 * @return {!proto.vivacity.core.TensorShape} returns this
*/
proto.vivacity.core.TensorShape.prototype.setDimList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.TensorShape.Dim=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TensorShape.Dim}
 */
proto.vivacity.core.TensorShape.prototype.addDim = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.TensorShape.Dim, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TensorShape} returns this
 */
proto.vivacity.core.TensorShape.prototype.clearDimList = function() {
  return this.setDimList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.Tensor.repeatedFields_ = [13,5,6,7,8,9,10,11,12,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Tensor.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Tensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Tensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Tensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dims: (f = msg.getDims()) && proto.vivacity.core.TensorShape.toObject(includeInstance, f),
    rawTensorContent: msg.getRawTensorContent_asB64(),
    halfValList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    floatValList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    doubleValList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    intValList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    stringValList: msg.getStringValList_asB64(),
    scomplexValList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    int64ValList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    boolValList: (f = jspb.Message.getRepeatedBooleanField(msg, 11)) == null ? undefined : f,
    dcomplexValList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null ? undefined : f,
    uint32ValList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    uint64ValList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    tensorName: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Tensor}
 */
proto.vivacity.core.Tensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Tensor;
  return proto.vivacity.core.Tensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Tensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Tensor}
 */
proto.vivacity.core.Tensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.DataType} */ (reader.readEnum());
      msg.setDtype(value);
      break;
    case 2:
      var value = new proto.vivacity.core.TensorShape;
      reader.readMessage(value,proto.vivacity.core.TensorShape.deserializeBinaryFromReader);
      msg.setDims(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawTensorContent(value);
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHalfVal(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFloatVal(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDoubleVal(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIntVal(values[i]);
      }
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addStringVal(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScomplexVal(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInt64Val(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBoolVal(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDcomplexVal(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUint32Val(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUint64Val(values[i]);
      }
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTensorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Tensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Tensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Tensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Tensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDims();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.TensorShape.serializeBinaryToWriter
    );
  }
  f = message.getRawTensorContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getHalfValList();
  if (f.length > 0) {
    writer.writePackedInt32(
      13,
      f
    );
  }
  f = message.getFloatValList();
  if (f.length > 0) {
    writer.writePackedFloat(
      5,
      f
    );
  }
  f = message.getDoubleValList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getIntValList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getStringValList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      8,
      f
    );
  }
  f = message.getScomplexValList();
  if (f.length > 0) {
    writer.writePackedFloat(
      9,
      f
    );
  }
  f = message.getInt64ValList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getBoolValList();
  if (f.length > 0) {
    writer.writePackedBool(
      11,
      f
    );
  }
  f = message.getDcomplexValList();
  if (f.length > 0) {
    writer.writePackedDouble(
      12,
      f
    );
  }
  f = message.getUint32ValList();
  if (f.length > 0) {
    writer.writePackedUint32(
      16,
      f
    );
  }
  f = message.getUint64ValList();
  if (f.length > 0) {
    writer.writePackedUint64(
      17,
      f
    );
  }
  f = message.getTensorName();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional DataType dtype = 1;
 * @return {!proto.vivacity.core.DataType}
 */
proto.vivacity.core.Tensor.prototype.getDtype = function() {
  return /** @type {!proto.vivacity.core.DataType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.DataType} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TensorShape dims = 2;
 * @return {?proto.vivacity.core.TensorShape}
 */
proto.vivacity.core.Tensor.prototype.getDims = function() {
  return /** @type{?proto.vivacity.core.TensorShape} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TensorShape, 2));
};


/**
 * @param {?proto.vivacity.core.TensorShape|undefined} value
 * @return {!proto.vivacity.core.Tensor} returns this
*/
proto.vivacity.core.Tensor.prototype.setDims = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearDims = function() {
  return this.setDims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Tensor.prototype.hasDims = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes raw_tensor_content = 4;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.Tensor.prototype.getRawTensorContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes raw_tensor_content = 4;
 * This is a type-conversion wrapper around `getRawTensorContent()`
 * @return {string}
 */
proto.vivacity.core.Tensor.prototype.getRawTensorContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawTensorContent()));
};


/**
 * optional bytes raw_tensor_content = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawTensorContent()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.Tensor.prototype.getRawTensorContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawTensorContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setRawTensorContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * repeated int32 half_val = 13;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getHalfValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setHalfValList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addHalfVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearHalfValList = function() {
  return this.setHalfValList([]);
};


/**
 * repeated float float_val = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getFloatValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setFloatValList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addFloatVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearFloatValList = function() {
  return this.setFloatValList([]);
};


/**
 * repeated double double_val = 6;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getDoubleValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setDoubleValList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addDoubleVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearDoubleValList = function() {
  return this.setDoubleValList([]);
};


/**
 * repeated int32 int_val = 7;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getIntValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setIntValList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addIntVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearIntValList = function() {
  return this.setIntValList([]);
};


/**
 * repeated bytes string_val = 8;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.vivacity.core.Tensor.prototype.getStringValList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * repeated bytes string_val = 8;
 * This is a type-conversion wrapper around `getStringValList()`
 * @return {!Array<string>}
 */
proto.vivacity.core.Tensor.prototype.getStringValList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getStringValList()));
};


/**
 * repeated bytes string_val = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStringValList()`
 * @return {!Array<!Uint8Array>}
 */
proto.vivacity.core.Tensor.prototype.getStringValList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getStringValList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setStringValList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addStringVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearStringValList = function() {
  return this.setStringValList([]);
};


/**
 * repeated float scomplex_val = 9;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getScomplexValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setScomplexValList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addScomplexVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearScomplexValList = function() {
  return this.setScomplexValList([]);
};


/**
 * repeated int64 int64_val = 10;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getInt64ValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setInt64ValList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addInt64Val = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearInt64ValList = function() {
  return this.setInt64ValList([]);
};


/**
 * repeated bool bool_val = 11;
 * @return {!Array<boolean>}
 */
proto.vivacity.core.Tensor.prototype.getBoolValList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 11));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setBoolValList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addBoolVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearBoolValList = function() {
  return this.setBoolValList([]);
};


/**
 * repeated double dcomplex_val = 12;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getDcomplexValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setDcomplexValList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addDcomplexVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearDcomplexValList = function() {
  return this.setDcomplexValList([]);
};


/**
 * repeated uint32 uint32_val = 16;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getUint32ValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setUint32ValList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addUint32Val = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearUint32ValList = function() {
  return this.setUint32ValList([]);
};


/**
 * repeated uint64 uint64_val = 17;
 * @return {!Array<number>}
 */
proto.vivacity.core.Tensor.prototype.getUint64ValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setUint64ValList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.addUint64Val = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.clearUint64ValList = function() {
  return this.setUint64ValList([]);
};


/**
 * optional string tensor_name = 30;
 * @return {string}
 */
proto.vivacity.core.Tensor.prototype.getTensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Tensor} returns this
 */
proto.vivacity.core.Tensor.prototype.setTensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * @enum {number}
 */
proto.vivacity.core.DataType = {
  DT_INVALID: 0,
  DT_FLOAT: 1,
  DT_DOUBLE: 2,
  DT_INT32: 3,
  DT_UINT8: 4,
  DT_INT16: 5,
  DT_INT8: 6,
  DT_STRING: 7,
  DT_COMPLEX64: 8,
  DT_INT64: 9,
  DT_BOOL: 10,
  DT_UINT16: 17,
  DT_COMPLEX128: 18,
  DT_HALF: 19,
  DT_UINT32: 22,
  DT_UINT64: 23
};

goog.object.extend(exports, proto.vivacity.core);
