import { CountlineValidationRunID } from "../domain";
import { ValidationRunStatus } from "../enums";
import { ExtraThumbnail } from "./video.interface";

export interface ValidationRun {
  id: number;
  vpid: number;
  videoID: number;
  cvRunID: number;
  status: ValidationRunStatus;
  passed: boolean | undefined;
  startedByUserId: string;
  startedByUserEmail: string;
  completedByUserId: string | undefined;
  completedByUserEmail: string | undefined;
  startedAt: Date | undefined;
  completedAt: Date | undefined;
  deletedAt: Date | undefined;
  countlineValidationRuns: CountlineValidationRunID[];
  thumbnail: string | undefined;
  extraThumbnails: ExtraThumbnail[];
  videoDownloadURL: string | undefined;
  customValidatedClasses: number[] | undefined;
}

export const ValidationRunSearchableFields: { [fieldName in keyof ValidationRun]: boolean } = {
  id: true,
  vpid: true,
  videoID: true,
  cvRunID: true,
  status: true,
  startedByUserId: true,
  startedByUserEmail: true,
  completedAt: false,
  completedByUserEmail: false,
  completedByUserId: false,
  countlineValidationRuns: false,
  deletedAt: false,
  passed: false,
  startedAt: false,
  thumbnail: false,
  extraThumbnails: false,
  videoDownloadURL: false,
  customValidatedClasses: false,
};
