import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import "react-simple-keyboard/build/css/index.css";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import { useStores } from "../../../hooks/useStores.hook";
import { action } from "mobx";
import "./Keyboard.css";
import { FullKeyboard } from "./FullKeyboard";
import { EditKeyMapModal } from "../../modals/EditKeyMapModal";
import { FreneticityButton } from "../../styledElements";
import _ from "lodash";

const filter = createFilterOptions<string>();

export const KeyboardPopover: FC<{}> = observer(() => {
  const { playerUIStore } = useStores();
  const [keyMapEditModalOpen, setKeyMapEditModalOpen] = useState(false);
  const [keyMapName, setKeyMapName] = useState<string>(playerUIStore.currentKeyMapName);
  const [createNewKeyMapDialogOpen, setCreateNewKeyMapDialogOpen] = useState<boolean>(false);
  const [dialogValue, setDialogValue] = useState("");

  const handleNewKeyMapDialogClose = () => {
    setDialogValue("");
    setCreateNewKeyMapDialogOpen(false);
  };

  const handleNewKeyMapDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setKeyMapName(dialogValue);
    handleNewKeyMapDialogClose();
    playerUIStore.setKeyboardLayout(dialogValue);
    setKeyMapEditModalOpen(true);
  };

  const keyMapNames: string[] = [];
  playerUIStore.availableKeyMaps.forEach((v, name) => {
    keyMapNames.push(name);
  });

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={playerUIStore.keyboardVisible}
      onClose={action(() => playerUIStore.setKeyboardVisibility(false))}
      onOpen={action(() => playerUIStore.setKeyboardVisibility(true))}
      style={{ height: "200px" }}
    >
      <Paper style={{ position: "absolute", right: 0 }} className={"numPad"}>
        <Grid container justifyContent="center" alignItems="center" width={"100%"}>
          <Grid item flexGrow={1}>
            <Autocomplete
              id="keymap-selector"
              freeSolo
              disableClearable
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={keyMapNames}
              style={{ margin: "5px" }}
              value={keyMapName}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              size={"small"}
              onChange={(event, newValue) => {
                if (typeof newValue === "string" && !_.includes(keyMapNames, newValue)) {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setCreateNewKeyMapDialogOpen(true);
                    setDialogValue(newValue);
                  });
                } else {
                  setKeyMapName(newValue);
                  playerUIStore.setKeyboardLayout(newValue);
                }
              }}
              renderInput={params => <TextField {...params} label="KeyMap" />}
            />
            <Dialog open={createNewKeyMapDialogOpen} onClose={handleNewKeyMapDialogClose}>
              <form onSubmit={handleNewKeyMapDialogSubmit}>
                <DialogTitle>Add a new key kap</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you want to create a new key map?</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue}
                    onChange={event => setDialogValue(event.target.value)}
                    label="Key Map Name"
                    type="text"
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleNewKeyMapDialogClose}>Cancel</Button>
                  <Button type="submit">Create</Button>
                </DialogActions>
              </form>
            </Dialog>
          </Grid>

          <Grid item>
            <FreneticityButton
              style={{ margin: "5px" }}
              size={"small"}
              onClick={action(() => {
                if (keyMapName) {
                  playerUIStore.setKeyboardLayout(keyMapName);
                  setKeyMapEditModalOpen(true);
                }
              })}
            >
              Edit Key Map
            </FreneticityButton>
          </Grid>
        </Grid>
      </Paper>

      <FullKeyboard />
      <EditKeyMapModal isOpen={keyMapEditModalOpen} onClose={() => setKeyMapEditModalOpen(false)} />
    </SwipeableDrawer>
  );
});
