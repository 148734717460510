import { observer } from "mobx-react";
import { BitmapText } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import React, { FC, Fragment } from "react";
import { PlayerUIStore } from "../../../stores/playerUI.store";
import { TextStyle } from "pixi.js";
import { ITextStyle } from "@pixi/text";

const fontName = "CornerFont";

const cornerTextStyle: TextStyle | Partial<ITextStyle> = {
  fill: 0xffffff,
  fontSize: 28,
  align: "left",
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const cornerTextStyleWithName = {
  ...cornerTextStyle,
  fontName: fontName, // This is strange...
};

PIXI.BitmapFont.from(fontName, cornerTextStyle, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:/"],
});

export const CornerText: FC<{ playerUIStore: PlayerUIStore }> = observer(({ playerUIStore }) => {
  const frameTimeReal = playerUIStore.unsnappedUnixTimestampsByFrame.get(playerUIStore.currentFrameNumber);
  let frameTimeHuman = "";
  if (frameTimeReal) {
    const dateVal = new Date(frameTimeReal / 1000);
    frameTimeHuman = dateVal.toLocaleString() + "." + ("00" + dateVal.getMilliseconds()).slice(-3);
  }
  return (
    <Fragment>
      <BitmapText
        alpha={playerUIStore.runningTotalsTextAlpha}
        key={"top-right-text"}
        fontName={fontName}
        text={frameTimeHuman}
        y={0}
        x={playerUIStore.use1920x1080 ? 1920 - 330 : playerUIStore.videoWidth - 330}
        style={cornerTextStyleWithName}
      />
      <BitmapText
        alpha={playerUIStore.runningTotalsTextAlpha}
        key={"top-left-text"}
        fontName={fontName}
        text={`Frame ${playerUIStore.currentFrameNumber} - ${playerUIStore.videoCurrentTimeSeconds.toFixed(3)}s`}
        style={cornerTextStyleWithName}
      />
    </Fragment>
  );
});
