import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useState } from "react";
import { VideoTable } from "../components/tables/VideoTable";
import { FilterSearchBar } from "../controllers/FilterSearchBar";
import { useStores } from "../hooks/useStores.hook";
import { Alert, AlertTitle } from "@mui/material";
import { PageHeader } from "../components/pageHeader/PageHeader";
import { useLocation } from "react-router-dom";
import { URL_QUERY_PARAMS } from "../stores/url.store";

export const VideosLayout: FC<{}> = observer(() => {
  const { entitiesStore } = useStores();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get(URL_QUERY_PARAMS.SEARCH);
  const [searchString, setSearchString] = useState<string>(search ?? "");

  const debouncedSetFilter = debounce(setSearchString, 300);
  const onSearchStringChange = useCallback(
    (newSearchString: string): void => {
      debouncedSetFilter(newSearchString);
    },
    [debouncedSetFilter]
  );

  return (
    <>
      {entitiesStore.hydrationErrors !== "" ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {entitiesStore.hydrationErrors}
        </Alert>
      ) : null}
      <PageHeader breadcrumbsDepth={1} />
      <FilterSearchBar
        filter={searchString}
        onFilterChange={onSearchStringChange}
        placeholderText={"Search"}
        defaultFilter=""
      />
      <VideoTable />
    </>
  );
});
