import { CountlineID, FrameInterval, FrameNumber, SnappedVideoTime, TrackID, TrackPair, VPID } from "./index";
import { CountlineCrossing } from "../vivacity/core/countline_crossing_pb";
import * as vivacity_core_classifying_detector_class_types_pb from "../vivacity/core/classifying_detector_class_types_pb";

export interface FootprintGeometry {
  frontLeftX: number;
  frontLeftY: number;
  frontRightX: number;
  frontRightY: number;
  backLeftX: number;
  backLeftY: number;
  backRightX: number;
  backRightY: number;
  groundCentreX: number;
  groundCentreY: number;
}
export interface Footprint extends FootprintGeometry {
  lineColor: number;
  lineWidth: number;
  lineAlpha: number;
  fillColor: number;
  fillAlpha: number;
  shouldDisplayFootprint: boolean;
}

export interface BoxDetails {
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  lineColor: number;
  lineWidth: number;
  lineAlpha: number;
  fillColor: number;
  fillAlpha: number;
  textColour: number;
  textAlpha: number;
  textPosition: number;
  textSize: number;
  shouldDisplay: boolean;
  trackID: TrackID;
  footprint?: Footprint;
}

export enum WorkerResponseType {
  progressUpdate,
  fullPayload,
  error,
}

export interface DTFWorkerResponsePayload {
  type: WorkerResponseType;
  progressUpdate?: ProgressUpdatePayload;
  fullPayload?: CompleteDTFExtractionPayload;
  error?: string;
}

export interface ProgressUpdatePayload {
  loadFraction: number;
  offset: number;
  numFramesDecoded: number;
}

export interface CompleteDTFExtractionPayload {
  tailGeometries: Map<TrackID, number[]>;
  tailColours: Map<TrackID, number>;
  tailStartFrames: Map<TrackID, FrameNumber>;
  activeTracksByFrame: Map<FrameNumber, TrackID[]>;
  unsnappedUnixTimestampsByFrame: Map<FrameNumber, number>;
  boundingBoxesByFrame: Map<FrameNumber, BoxDetails[]>;
  totalFrames: number;
  framesByVideoTimestamp: Map<SnappedVideoTime, FrameNumber>;
  videoTimestampByFrame: Map<FrameNumber, SnappedVideoTime>;
  dtfBufferOffsetsByFrameNumber: Map<FrameNumber, number>;
  trueAvgIntervalMicroseconds: number;
  nearMissIncidents: Map<TrackPair, FrameInterval>;
  dtfBuffer: ArrayBuffer;
}

// TODO - move these somewhere or get rid?
export interface CrossingDetails {
  countline: CountlineID;
  frameNumber: FrameNumber;
  trackNumber: TrackID;
  direction: CountlineCrossing.CrossingsDirectionMap[keyof CountlineCrossing.CrossingsDirectionMap];
  roadUserClass: vivacity_core_classifying_detector_class_types_pb.ClassifyingDetectorClassTypesMap[keyof vivacity_core_classifying_detector_class_types_pb.ClassifyingDetectorClassTypesMap];
  vpid: VPID;
}
