import { EventEmitter } from "events";

import { RootStore } from "./root.store";

export abstract class EventEmitterStore extends EventEmitter {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }
}
