/* eslint-disable */
// source: vivacity/core/zonal_windowed_occupancy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
goog.exportSymbol('proto.vivacity.core.ClassOccupancy', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalWindowedOccupancy', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassOccupancy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ClassOccupancy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassOccupancy.displayName = 'proto.vivacity.core.ClassOccupancy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalWindowedOccupancy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.ZonalWindowedOccupancy.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.ZonalWindowedOccupancy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalWindowedOccupancy.displayName = 'proto.vivacity.core.ZonalWindowedOccupancy';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassOccupancy.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassOccupancy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassOccupancy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassOccupancy.toObject = function(includeInstance, msg) {
  var f, obj = {
    classId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meanNumberObjects: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    meanNumberStoppedObjects: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassOccupancy}
 */
proto.vivacity.core.ClassOccupancy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassOccupancy;
  return proto.vivacity.core.ClassOccupancy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassOccupancy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassOccupancy}
 */
proto.vivacity.core.ClassOccupancy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanNumberObjects(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanNumberStoppedObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassOccupancy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassOccupancy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassOccupancy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassOccupancy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMeanNumberObjects();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMeanNumberStoppedObjects();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class_id = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.ClassOccupancy.prototype.getClassId = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.ClassOccupancy} returns this
 */
proto.vivacity.core.ClassOccupancy.prototype.setClassId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float mean_number_objects = 2;
 * @return {number}
 */
proto.vivacity.core.ClassOccupancy.prototype.getMeanNumberObjects = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassOccupancy} returns this
 */
proto.vivacity.core.ClassOccupancy.prototype.setMeanNumberObjects = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float mean_number_stopped_objects = 3;
 * @return {number}
 */
proto.vivacity.core.ClassOccupancy.prototype.getMeanNumberStoppedObjects = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassOccupancy} returns this
 */
proto.vivacity.core.ClassOccupancy.prototype.setMeanNumberStoppedObjects = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.ZonalWindowedOccupancy.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalWindowedOccupancy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalWindowedOccupancy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedOccupancy.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    windowStartTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    windowLengthMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classOccupanciesList: jspb.Message.toObjectList(msg.getClassOccupanciesList(),
    proto.vivacity.core.ClassOccupancy.toObject, includeInstance),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy}
 */
proto.vivacity.core.ZonalWindowedOccupancy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalWindowedOccupancy;
  return proto.vivacity.core.ZonalWindowedOccupancy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalWindowedOccupancy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy}
 */
proto.vivacity.core.ZonalWindowedOccupancy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWindowStartTimestampMicroseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowLengthMicroseconds(value);
      break;
    case 5:
      var value = new proto.vivacity.core.ClassOccupancy;
      reader.readMessage(value,proto.vivacity.core.ClassOccupancy.deserializeBinaryFromReader);
      msg.addClassOccupancies(value);
      break;
    case 6:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalWindowedOccupancy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalWindowedOccupancy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalWindowedOccupancy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWindowStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getWindowLengthMicroseconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getClassOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vivacity.core.ClassOccupancy.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 zone_id = 1;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 window_start_timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getWindowStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setWindowStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 window_length_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getWindowLengthMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setWindowLengthMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ClassOccupancy class_occupancies = 5;
 * @return {!Array<!proto.vivacity.core.ClassOccupancy>}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getClassOccupanciesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassOccupancy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ClassOccupancy, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassOccupancy>} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
*/
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setClassOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.ClassOccupancy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassOccupancy}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.addClassOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.ClassOccupancy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.clearClassOccupanciesList = function() {
  return this.setClassOccupanciesList([]);
};


/**
 * optional TracingContext tracing_ctx = 6;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 6));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
*/
proto.vivacity.core.ZonalWindowedOccupancy.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.ZonalWindowedOccupancy} returns this
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.ZonalWindowedOccupancy.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.vivacity.core);
