import { Button, ButtonProps, styled } from "@mui/material";
import React, { FC } from "react";

export const FreneticityButton: FC<ButtonProps> = ({ ...rest }) => {
  return <StyledButton {...rest} variant={rest.variant ?? "contained"} size="small" />;
};

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
}));
