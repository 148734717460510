import { createContext, RefObject } from "react";
import { UrlStore } from "../stores/url.store";
import { CVRunStore } from "../stores/cvRun.store";
import { CountlineValidationRunStore } from "../stores/countlineValidationRun.store";
import { VideoStore } from "../stores/video.store";
import { ValidationRunStore } from "../stores/validationRun.store";
import { EntitiesStore } from "../stores/entities.store";
import { PlayerUIStore } from "../stores/playerUI.store";
import { ApiStore } from "../stores/apiStore";
import { NavigateFunction } from "react-router/lib/hooks";
import { ValidationCreationUIStore } from "../stores/validationCreationUI.store";
import { ZoneStore } from "../stores/zones.store";

export interface StoresContext {
  navigate: RefObject<NavigateFunction>;
  urlStore: UrlStore;
  entitiesStore: EntitiesStore;
  cvRunStore: CVRunStore;
  countlineValidationRunStore: CountlineValidationRunStore;
  videoStore: VideoStore;
  zoneStore: ZoneStore;
  validationRunStore: ValidationRunStore;
  playerUIStore: PlayerUIStore;
  apiStore: ApiStore;
  validationCreationUIStore: ValidationCreationUIStore;
}

export const storesContext = createContext<StoresContext | null>(null);
