import { FreneticityButton } from "../styledElements";
import React, { FC, useState } from "react";
import { TableBreadcrumbs } from "./TableBreadcrumbs";
import { ValidationModeToggle } from "./ValidationModeToggle";
import { ResearchActionsModal } from "../modals";

interface PageHeaderProps {
  breadcrumbsDepth?: number;
}

export const PageHeader: FC<PageHeaderProps> = ({ breadcrumbsDepth = 0 }) => {
  const [researchModalOpen, setResearchModalOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 6, boxSizing: "border-box" }}
      >
        <TableBreadcrumbs depth={breadcrumbsDepth} />
        <div style={{ display: "grid" }}>
          <ValidationModeToggle />
          <FreneticityButton
            onClick={() => setResearchModalOpen(!researchModalOpen)}
            style={{ minWidth: "105px", justifySelf: "right" }}
          >
            {" "}
            Research{" "}
          </FreneticityButton>
          <ResearchActionsModal isOpen={researchModalOpen} onClose={() => setResearchModalOpen(false)} />
        </div>
      </div>
    </div>
  );
};
