import React, { FC } from "react";
import { BitmapText, Container } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import { classLookup } from "../../../workers/utils";
import { CVRunTurningMovement } from "../../../interfaces/cvRunTurningMovement.interface";
import { TurningMovementStartZone } from "../../../domain";

const fontName = "TotalsFontRight";

const fontSize = 24;

const textStyle = {
  fill: 0xffffff,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleWithName = {
  ...textStyle,
  fontName: fontName,
  align: "right",
};

PIXI.BitmapFont.from(fontName, textStyle, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:/"],
});

export const TurningMovementTotals: FC<{
  startZoneId: TurningMovementStartZone | undefined;
  turningMovementsPerStartZone: Map<number, CVRunTurningMovement[]>;
}> = ({ startZoneId, turningMovementsPerStartZone }) => {
  const totalTurningCountsContainer: JSX.Element[][] = [[]];
  const perTurningMovementCounts: JSX.Element[] = [];

  const countsPerClassPerMovement = (endZoneID: number, trackClass: number | null): number => {
    const filteredTurningMovements = turningMovementsPerStartZone.get(endZoneID)!;
    const turningMovementsToReturn = filteredTurningMovements.filter(movement => movement.trackClass === trackClass);
    return turningMovementsToReturn.length;
  };

  if (startZoneId) {
    let rowCounter = 1;
    perTurningMovementCounts.push(
      <BitmapText
        fontName={fontName}
        text={`Start Zone: ${startZoneId}`}
        style={textStyleWithName}
        x={0}
        y={32 * rowCounter}
        key={"start-zone-" + startZoneId}
      />
    );
    rowCounter += 1;
    turningMovementsPerStartZone.forEach((arrayOfTurningMovements, endZoneId) => {
      const arrayOfClasses: number[] = [];
      perTurningMovementCounts.push(
        <BitmapText
          fontName={fontName}
          text={`End Zone: ${endZoneId}`}
          style={textStyleWithName}
          x={20}
          y={32 * rowCounter}
          key={"end-zone-" + endZoneId}
        />
      );
      rowCounter += 1;

      arrayOfTurningMovements.forEach(turningMovement => {
        if (turningMovement.trackClass) {
          if (!arrayOfClasses.includes(turningMovement.trackClass)) {
            arrayOfClasses.push(turningMovement.trackClass);

            perTurningMovementCounts.push(
              <BitmapText
                fontName={fontName}
                text={`${classLookup[turningMovement.trackClass!]}`}
                style={textStyleWithName}
                x={50}
                y={32 * rowCounter}
                key={`start-zone-${startZoneId}-end-zone-${endZoneId}-class-name-${
                  classLookup[turningMovement.trackClass!]
                }`}
              />
            );
            perTurningMovementCounts.push(
              <BitmapText
                fontName={fontName}
                text={`${countsPerClassPerMovement(endZoneId, turningMovement.trackClass)}`}
                style={textStyleWithName}
                x={180}
                y={32 * rowCounter}
                key={`start-zone-${startZoneId}-end-zone-${endZoneId}-count-per-class-${
                  classLookup[turningMovement.trackClass!]
                }`}
              />
            );
            rowCounter += 1;
          }
        }
      });
    });
    totalTurningCountsContainer.push(perTurningMovementCounts);
    return <Container>{totalTurningCountsContainer}</Container>;
  }
  return <Container></Container>;
};
