import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  styled,
  Box,
  Alert,
  AlertTitle,
  Grid,
  ListItem,
  List,
  Stack,
  Paper,
} from "@mui/material";

import { useStores } from "../../hooks/useStores.hook";

import { FreneticityButton } from "../styledElements";
import { CountlineValidationRunID } from "../../domain";
import { action } from "mobx";

interface NotesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NotesModal: FC<NotesModalProps> = observer(({ isOpen, onClose }) => {
  const [notesText, setNotesText] = useState<string>("");
  const { urlStore, playerUIStore, countlineValidationRunStore } = useStores();

  if (!urlStore.selectedValidationRun || !urlStore.selectedCountline) {
    return null;
  }

  const clValRunID = `${urlStore.selectedValidationRun}-${urlStore.selectedCountline}`;
  const validationRun = countlineValidationRunStore.countlineValidationRuns.get(clValRunID);

  if (!validationRun) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Notes</DialogTitle>
      {countlineValidationRunStore.failedToSaveMessage !== "" ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {countlineValidationRunStore.failedToSaveMessage}
        </Alert>
      ) : null}

      <DialogContent>
        <Stack spacing={2}>
          {validationRun.notes?.map(note => {
            return (
              <Paper key={note.timestamp} style={{ padding: "10px" }}>
                <Typography variant={"body2"}>{note.note}</Typography>

                <Typography variant={"body2"} fontSize={"10px"} textAlign={"right"}>
                  {note.timestamp} ({note.email})
                </Typography>
              </Paper>
            );
          })}
        </Stack>

        <StyledFormControl>
          {playerUIStore.readOnlyMode ? null : (
            <TextField multiline minRows={10} value={notesText} onChange={e => setNotesText(e.target.value)} />
          )}
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
        <FreneticityButton
          disabled={playerUIStore.readOnlyMode}
          onClick={action(() => {
            if (notesText && !playerUIStore.readOnlyMode) {
              countlineValidationRunStore.updateValidationRunNote(clValRunID, notesText).then(() => {
                if (countlineValidationRunStore.failedToSaveMessage === "") {
                  setNotesText("");
                }
              });
            }
          })}
        >
          Save
        </FreneticityButton>
      </DialogActions>
    </Dialog>
  );
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
});
