import { debounce } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { DecodedValueMap, NumberParam, StringParam, UrlUpdateType } from "use-query-params";
import { RoutePath, ValidationMode } from "../enums";
import { UrlQueryParams } from "../interfaces";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import { getDefaultUrlParams, QueryParams, SetQueryParams, urlToStoreMapping, ValidationModeParam } from "./utils";

export enum URL_QUERY_PARAMS {
  SELECTED_VISION_PROGRAM = "selectedVisionProgram",
  SELECTED_VIDEO = "selectedVideo",
  SELECTED_COMPUTER_VISION_RUN = "selectedComputerVisionRun",
  SELECTED_VALIDATION_RUN = "selectedValidationRun",
  SELECTED_COUNTLINE = "selectedCountline",
  SELECTED_VALIDATION_MODE = "selectedValidationMode",
  SEARCH = "search",
}

export class UrlStore extends ChildStore implements UrlQueryParams {
  @observable selectedVisionProgram: number | undefined = 0;
  @observable selectedVideo: number | undefined = 0;
  @observable selectedComputerVisionRun: number | undefined = 0;
  @observable selectedValidationRun: number | undefined = 0;
  @observable selectedCountline: number | undefined = 0;
  @observable selectedValidationMode: ValidationMode = ValidationMode.CountlineValidation;
  @observable search: string | undefined = "";
  @observable breadcrumbsRoot: RoutePath = RoutePath.Root;

  private debouncedReduceUrlQueryParams = debounce(action(this.reduceUrlQueryParams), 100);

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  static queryParamDefinition() {
    return {
      [URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM]: NumberParam,
      [URL_QUERY_PARAMS.SELECTED_VIDEO]: NumberParam,
      [URL_QUERY_PARAMS.SELECTED_COMPUTER_VISION_RUN]: NumberParam,
      [URL_QUERY_PARAMS.SELECTED_VALIDATION_RUN]: NumberParam,
      [URL_QUERY_PARAMS.SELECTED_COUNTLINE]: NumberParam,
      [URL_QUERY_PARAMS.SELECTED_VALIDATION_MODE]: ValidationModeParam,
      [URL_QUERY_PARAMS.SEARCH]: StringParam,
    };
  }

  init(urlQueryParams: DecodedValueMap<QueryParams>, setUrlQueryParams: SetQueryParams): void {
    this.saveInitialUrlQueryParamsToStore(urlQueryParams);
    this.setBreadcrumbsRoot(RoutePath.Root);
    this.setUrlQueryParams = setUrlQueryParams;
  }

  @action.bound
  setParams(newParams: Partial<UrlQueryParams>, updateType: UrlUpdateType): void {
    const defaultParams: UrlQueryParams = getDefaultUrlParams();
    let newParamsPresent = false;
    Object.keys(defaultParams).forEach(k => {
      if (this[k] !== newParams[k]) {
        newParamsPresent = true;
      }
    });
    if (!newParamsPresent) {
      return;
    }

    this.setObservableQueryParams(newParams, updateType);
    this.debouncedReduceUrlQueryParams(updateType);
  }

  @action
  setBreadcrumbsRoot(routePath: RoutePath): void {
    this.breadcrumbsRoot = routePath;
  }

  reduceUrlQueryParams(updateType: UrlUpdateType): void {
    const defaultParams: UrlQueryParams = getDefaultUrlParams();
    const newParams = Object.entries(defaultParams).reduce<Partial<UrlQueryParams>>((agg, [paramName]) => {
      agg[paramName] = this[paramName];
      return agg;
    }, {});

    this.setUrlQueryParams(newParams, updateType);
  }

  private setUrlQueryParams: SetQueryParams = () => {
    return;
  };

  private parseUrlQueryParams(urlQueryParams: Partial<DecodedValueMap<QueryParams>>): Partial<UrlQueryParams> {
    return Object.entries(urlQueryParams).reduce<Partial<UrlQueryParams>>((agg, [paramName, value]) => {
      type StoreMappingKey = keyof typeof urlToStoreMapping;
      const { cleaner } = urlToStoreMapping[paramName as StoreMappingKey];
      let cleanValue;
      if (cleaner) {
        cleanValue = cleaner(value);
      }
      agg[paramName as keyof UrlQueryParams] = cleanValue;
      return agg;
    }, {});
  }

  @action
  private saveInitialUrlQueryParamsToStore(urlQueryParams: Partial<DecodedValueMap<QueryParams>>): void {
    const parsedQueryParams = this.parseUrlQueryParams(urlQueryParams);
    Object.entries(parsedQueryParams).forEach(([param, value]) => {
      this[param] = value;
    });
  }

  @action
  private setObservableQueryParams(newParams: Partial<UrlQueryParams>, updateType: UrlUpdateType): void {
    const defaultParams: UrlQueryParams = getDefaultUrlParams();
    Object.entries(defaultParams).forEach(([paramName, defaultValue]) => {
      if (Object.keys(newParams).includes(paramName)) {
        this[paramName] = newParams[paramName];
      } else {
        if (updateType === "push" || updateType === "replace") {
          this[paramName] = defaultValue;
        }
      }
    });
  }
}
