/* eslint-disable */
// source: vivacity/config_v2/test_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.TestChildEntity', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TestParentEntity', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TestParentEntity.Enum', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TestReferenceEntity', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TestReferenceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.TestReferenceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TestReferenceEntity.displayName = 'proto.vivacity.config_v2.TestReferenceEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TestParentEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.TestParentEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TestParentEntity.displayName = 'proto.vivacity.config_v2.TestParentEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TestChildEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.TestChildEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TestChildEntity.displayName = 'proto.vivacity.config_v2.TestChildEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.displayName = 'proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TestReferenceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TestReferenceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestReferenceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    int32Value: (f = msg.getInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    int64Value: (f = msg.getInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.toObject(includeInstance, f),
    uInt32Value: (f = msg.getUInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    uInt64Value: (f = msg.getUInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.toObject(includeInstance, f),
    boolValue: (f = msg.getBoolValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    stringValue: (f = msg.getStringValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    jsonValue: (f = msg.getJsonValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    enumValueId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    enumValueString: jspb.Message.getFieldWithDefault(msg, 15, 0),
    enumValueTimescale: jspb.Message.getFieldWithDefault(msg, 16, 0),
    uuidValue: (f = msg.getUuidValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    geometryIntegerPoint: (f = msg.getGeometryIntegerPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.toObject(includeInstance, f),
    geometryIntegerPath: (f = msg.getGeometryIntegerPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    geometryIntegerPolygon: (f = msg.getGeometryIntegerPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.toObject(includeInstance, f),
    geometryDoublePoint: (f = msg.getGeometryDoublePoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.toObject(includeInstance, f),
    geometryDoublePath: (f = msg.getGeometryDoublePath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    geometryDoublePolygon: (f = msg.getGeometryDoublePolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.toObject(includeInstance, f),
    geographyPoint: (f = msg.getGeographyPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    geographyPath: (f = msg.getGeographyPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.toObject(includeInstance, f),
    geographyPolygon: (f = msg.getGeographyPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity}
 */
proto.vivacity.config_v2.TestReferenceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TestReferenceEntity;
  return proto.vivacity.config_v2.TestReferenceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TestReferenceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity}
 */
proto.vivacity.config_v2.TestReferenceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setInt32Value(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader);
      msg.setInt64Value(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUInt32Value(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader);
      msg.setUInt64Value(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setBoolValue(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setStringValue(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setJsonValue(value);
      break;
    case 14:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueId(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueString(value);
      break;
    case 16:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueTimescale(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setUuidValue(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader);
      msg.setGeometryIntegerPoint(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setGeometryIntegerPath(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader);
      msg.setGeometryIntegerPolygon(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader);
      msg.setGeometryDoublePoint(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setGeometryDoublePath(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader);
      msg.setGeometryDoublePolygon(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setGeographyPoint(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader);
      msg.setGeographyPath(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader);
      msg.setGeographyPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TestReferenceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TestReferenceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestReferenceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInt32Value();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getInt64Value();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Value();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt64Value();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getBoolValue();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStringValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getJsonValue();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getUuidValue();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPoint();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPath();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPolygon();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePoint();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePath();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePolygon();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPoint();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPath();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPolygon();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.FloatValue float_value = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getFloatValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue double_value = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getDoubleValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setDoubleValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearDoubleValue = function() {
  return this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value int32_value = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearInt32Value = function() {
  return this.setInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.Int64Value int64_value = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearInt64Value = function() {
  return this.setInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value u_int32_value = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getUInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setUInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearUInt32Value = function() {
  return this.setUInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasUInt32Value = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.UInt64Value u_int64_value = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getUInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setUInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearUInt64Value = function() {
  return this.setUInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasUInt64Value = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue bool_value = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getBoolValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setBoolValue = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearBoolValue = function() {
  return this.setBoolValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.StringValue string_value = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getStringValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setStringValue = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearStringValue = function() {
  return this.setStringValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp_value = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getTimestampValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setTimestampValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue json_value = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getJsonValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setJsonValue = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearJsonValue = function() {
  return this.setJsonValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasJsonValue = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_id = 14;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getEnumValueId = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.setEnumValueId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearEnumValueId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasEnumValueId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_string = 15;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getEnumValueString = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.setEnumValueString = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearEnumValueString = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasEnumValueString = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_timescale = 16;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getEnumValueTimescale = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.setEnumValueTimescale = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearEnumValueTimescale = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasEnumValueTimescale = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue uuid_value = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getUuidValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setUuidValue = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearUuidValue = function() {
  return this.setUuidValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasUuidValue = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Point geometry_integer_point = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryIntegerPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryIntegerPoint = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryIntegerPoint = function() {
  return this.setGeometryIntegerPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryIntegerPoint = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Path geometry_integer_path = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryIntegerPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryIntegerPath = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryIntegerPath = function() {
  return this.setGeometryIntegerPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryIntegerPath = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Polygon geometry_integer_polygon = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryIntegerPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryIntegerPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryIntegerPolygon = function() {
  return this.setGeometryIntegerPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryIntegerPolygon = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Point geometry_double_point = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryDoublePoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryDoublePoint = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryDoublePoint = function() {
  return this.setGeometryDoublePoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryDoublePoint = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Path geometry_double_path = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryDoublePath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryDoublePath = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryDoublePath = function() {
  return this.setGeometryDoublePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryDoublePath = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Polygon geometry_double_polygon = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeometryDoublePolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeometryDoublePolygon = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeometryDoublePolygon = function() {
  return this.setGeometryDoublePolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeometryDoublePolygon = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point geography_point = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeographyPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeographyPoint = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeographyPoint = function() {
  return this.setGeographyPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeographyPoint = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Path geography_path = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeographyPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeographyPath = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeographyPath = function() {
  return this.setGeographyPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeographyPath = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Polygon geography_polygon = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.getGeographyPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestReferenceEntity.prototype.setGeographyPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.clearGeographyPolygon = function() {
  return this.setGeographyPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestReferenceEntity.prototype.hasGeographyPolygon = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TestParentEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TestParentEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestParentEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    referenceKeyId: (f = msg.getReferenceKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    foreignKeyId: (f = msg.getForeignKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    int32Value: (f = msg.getInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    int64Value: (f = msg.getInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.toObject(includeInstance, f),
    uInt32Value: (f = msg.getUInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    uInt32Array: (f = msg.getUInt32Array()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.toObject(includeInstance, f),
    uInt64Value: (f = msg.getUInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.toObject(includeInstance, f),
    boolValue: (f = msg.getBoolValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    stringValue: (f = msg.getStringValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    stringArray: (f = msg.getStringArray()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    jsonValue: (f = msg.getJsonValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    enumValueId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    enumValueString: jspb.Message.getFieldWithDefault(msg, 17, 0),
    enumValueTimescale: jspb.Message.getFieldWithDefault(msg, 18, 0),
    uuidValue: (f = msg.getUuidValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    geometryIntegerPoint: (f = msg.getGeometryIntegerPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.toObject(includeInstance, f),
    geometryIntegerPath: (f = msg.getGeometryIntegerPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    geometryIntegerPolygon: (f = msg.getGeometryIntegerPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.toObject(includeInstance, f),
    geometryDoublePoint: (f = msg.getGeometryDoublePoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.toObject(includeInstance, f),
    geometryDoublePath: (f = msg.getGeometryDoublePath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    geometryDoublePolygon: (f = msg.getGeometryDoublePolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.toObject(includeInstance, f),
    geographyPoint: (f = msg.getGeographyPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    geographyPath: (f = msg.getGeographyPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.toObject(includeInstance, f),
    geographyPolygon: (f = msg.getGeographyPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TestParentEntity}
 */
proto.vivacity.config_v2.TestParentEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TestParentEntity;
  return proto.vivacity.config_v2.TestParentEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TestParentEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TestParentEntity}
 */
proto.vivacity.config_v2.TestParentEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setReferenceKeyId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setForeignKeyId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setInt32Value(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader);
      msg.setInt64Value(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUInt32Value(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.deserializeBinaryFromReader);
      msg.setUInt32Array(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader);
      msg.setUInt64Value(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setBoolValue(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setStringValue(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setStringArray(value);
      break;
    case 14:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    case 15:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setJsonValue(value);
      break;
    case 16:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueId(value);
      break;
    case 17:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueString(value);
      break;
    case 18:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueTimescale(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setUuidValue(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader);
      msg.setGeometryIntegerPoint(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setGeometryIntegerPath(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader);
      msg.setGeometryIntegerPolygon(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader);
      msg.setGeometryDoublePoint(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setGeometryDoublePath(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader);
      msg.setGeometryDoublePolygon(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setGeographyPoint(value);
      break;
    case 27:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader);
      msg.setGeographyPath(value);
      break;
    case 28:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader);
      msg.setGeographyPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TestParentEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TestParentEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestParentEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getReferenceKeyId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getForeignKeyId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInt32Value();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getInt64Value();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Value();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Array();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.serializeBinaryToWriter
    );
  }
  f = message.getUInt64Value();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getBoolValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStringValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStringArray();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getJsonValue();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getUuidValue();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPoint();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPath();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPolygon();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePoint();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePath();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePolygon();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPoint();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPath();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPolygon();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.TestParentEntity.Enum = {
  UNKNOWN: 0,
  ENUM_ONE: 1,
  ENUM_TWO: 2
};

/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id reference_key__id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getReferenceKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setReferenceKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearReferenceKeyId = function() {
  return this.setReferenceKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasReferenceKeyId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id foreign_key__id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getForeignKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setForeignKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearForeignKeyId = function() {
  return this.setForeignKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasForeignKeyId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.FloatValue float_value = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getFloatValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue double_value = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getDoubleValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setDoubleValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearDoubleValue = function() {
  return this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value int32_value = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearInt32Value = function() {
  return this.setInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.Int64Value int64_value = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearInt64Value = function() {
  return this.setInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value u_int32_value = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getUInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setUInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearUInt32Value = function() {
  return this.setUInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasUInt32Value = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Array u_int32_array = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getUInt32Array = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setUInt32Array = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearUInt32Array = function() {
  return this.setUInt32Array(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasUInt32Array = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.UInt64Value u_int64_value = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getUInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setUInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearUInt64Value = function() {
  return this.setUInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasUInt64Value = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue bool_value = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getBoolValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setBoolValue = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearBoolValue = function() {
  return this.setBoolValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.StringValue string_value = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getStringValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setStringValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearStringValue = function() {
  return this.setStringValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.StringArray string_array = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getStringArray = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setStringArray = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearStringArray = function() {
  return this.setStringArray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasStringArray = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp_value = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getTimestampValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setTimestampValue = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue json_value = 15;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getJsonValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 15));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setJsonValue = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearJsonValue = function() {
  return this.setJsonValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasJsonValue = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Enum enum_value_id = 16;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getEnumValueId = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.setEnumValueId = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearEnumValueId = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasEnumValueId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Enum enum_value_string = 17;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getEnumValueString = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.setEnumValueString = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearEnumValueString = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasEnumValueString = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Enum enum_value_timescale = 18;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getEnumValueTimescale = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.setEnumValueTimescale = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearEnumValueTimescale = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasEnumValueTimescale = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue uuid_value = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getUuidValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setUuidValue = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearUuidValue = function() {
  return this.setUuidValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasUuidValue = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Point geometry_integer_point = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryIntegerPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryIntegerPoint = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryIntegerPoint = function() {
  return this.setGeometryIntegerPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryIntegerPoint = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Path geometry_integer_path = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryIntegerPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryIntegerPath = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryIntegerPath = function() {
  return this.setGeometryIntegerPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryIntegerPath = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Polygon geometry_integer_polygon = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryIntegerPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryIntegerPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryIntegerPolygon = function() {
  return this.setGeometryIntegerPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryIntegerPolygon = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Point geometry_double_point = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryDoublePoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryDoublePoint = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryDoublePoint = function() {
  return this.setGeometryDoublePoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryDoublePoint = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Path geometry_double_path = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryDoublePath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryDoublePath = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryDoublePath = function() {
  return this.setGeometryDoublePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryDoublePath = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Polygon geometry_double_polygon = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeometryDoublePolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeometryDoublePolygon = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeometryDoublePolygon = function() {
  return this.setGeometryDoublePolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeometryDoublePolygon = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point geography_point = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeographyPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeographyPoint = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeographyPoint = function() {
  return this.setGeographyPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeographyPoint = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Path geography_path = 27;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeographyPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path, 27));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeographyPath = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeographyPath = function() {
  return this.setGeographyPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeographyPath = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Polygon geography_polygon = 28;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.getGeographyPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon, 28));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntity.prototype.setGeographyPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntity.prototype.clearGeographyPolygon = function() {
  return this.setGeographyPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntity.prototype.hasGeographyPolygon = function() {
  return jspb.Message.getField(this, 28) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TestChildEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TestChildEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestChildEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    referenceKeyId: (f = msg.getReferenceKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    int32Value: (f = msg.getInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    int64Value: (f = msg.getInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.toObject(includeInstance, f),
    uInt32Value: (f = msg.getUInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    uInt64Value: (f = msg.getUInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.toObject(includeInstance, f),
    boolValue: (f = msg.getBoolValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    stringValue: (f = msg.getStringValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    jsonValue: (f = msg.getJsonValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    enumValueId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    enumValueString: jspb.Message.getFieldWithDefault(msg, 15, 0),
    enumValueTimescale: jspb.Message.getFieldWithDefault(msg, 16, 0),
    uuidValue: (f = msg.getUuidValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    geometryIntegerPoint: (f = msg.getGeometryIntegerPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.toObject(includeInstance, f),
    geometryIntegerPath: (f = msg.getGeometryIntegerPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    geometryIntegerPolygon: (f = msg.getGeometryIntegerPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.toObject(includeInstance, f),
    geometryDoublePoint: (f = msg.getGeometryDoublePoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.toObject(includeInstance, f),
    geometryDoublePath: (f = msg.getGeometryDoublePath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    geometryDoublePolygon: (f = msg.getGeometryDoublePolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.toObject(includeInstance, f),
    geographyPoint: (f = msg.getGeographyPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    geographyPath: (f = msg.getGeographyPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.toObject(includeInstance, f),
    geographyPolygon: (f = msg.getGeographyPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TestChildEntity}
 */
proto.vivacity.config_v2.TestChildEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TestChildEntity;
  return proto.vivacity.config_v2.TestChildEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TestChildEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TestChildEntity}
 */
proto.vivacity.config_v2.TestChildEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setReferenceKeyId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setInt32Value(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader);
      msg.setInt64Value(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUInt32Value(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader);
      msg.setUInt64Value(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setBoolValue(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setStringValue(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setJsonValue(value);
      break;
    case 14:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueId(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueString(value);
      break;
    case 16:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueTimescale(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setUuidValue(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader);
      msg.setGeometryIntegerPoint(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setGeometryIntegerPath(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader);
      msg.setGeometryIntegerPolygon(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader);
      msg.setGeometryDoublePoint(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setGeometryDoublePath(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader);
      msg.setGeometryDoublePolygon(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setGeographyPoint(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader);
      msg.setGeographyPath(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader);
      msg.setGeographyPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TestChildEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TestChildEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestChildEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getReferenceKeyId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInt32Value();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getInt64Value();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Value();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt64Value();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getBoolValue();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStringValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getJsonValue();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getUuidValue();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPoint();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPath();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPolygon();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePoint();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePath();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePolygon();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPoint();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPath();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPolygon();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id reference_key__id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getReferenceKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setReferenceKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearReferenceKeyId = function() {
  return this.setReferenceKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasReferenceKeyId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.FloatValue float_value = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getFloatValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue double_value = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getDoubleValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setDoubleValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearDoubleValue = function() {
  return this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value int32_value = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearInt32Value = function() {
  return this.setInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.Int64Value int64_value = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearInt64Value = function() {
  return this.setInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value u_int32_value = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getUInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setUInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearUInt32Value = function() {
  return this.setUInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasUInt32Value = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.UInt64Value u_int64_value = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getUInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setUInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearUInt64Value = function() {
  return this.setUInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasUInt64Value = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue bool_value = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getBoolValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setBoolValue = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearBoolValue = function() {
  return this.setBoolValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.StringValue string_value = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getStringValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setStringValue = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearStringValue = function() {
  return this.setStringValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp_value = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getTimestampValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setTimestampValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue json_value = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getJsonValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setJsonValue = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearJsonValue = function() {
  return this.setJsonValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasJsonValue = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_id = 14;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getEnumValueId = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.setEnumValueId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearEnumValueId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasEnumValueId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_string = 15;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getEnumValueString = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.setEnumValueString = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearEnumValueString = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasEnumValueString = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_timescale = 16;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getEnumValueTimescale = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.setEnumValueTimescale = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearEnumValueTimescale = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasEnumValueTimescale = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue uuid_value = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getUuidValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setUuidValue = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearUuidValue = function() {
  return this.setUuidValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasUuidValue = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Point geometry_integer_point = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryIntegerPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryIntegerPoint = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryIntegerPoint = function() {
  return this.setGeometryIntegerPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryIntegerPoint = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Path geometry_integer_path = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryIntegerPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryIntegerPath = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryIntegerPath = function() {
  return this.setGeometryIntegerPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryIntegerPath = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Polygon geometry_integer_polygon = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryIntegerPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryIntegerPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryIntegerPolygon = function() {
  return this.setGeometryIntegerPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryIntegerPolygon = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Point geometry_double_point = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryDoublePoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryDoublePoint = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryDoublePoint = function() {
  return this.setGeometryDoublePoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryDoublePoint = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Path geometry_double_path = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryDoublePath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryDoublePath = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryDoublePath = function() {
  return this.setGeometryDoublePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryDoublePath = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Polygon geometry_double_polygon = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeometryDoublePolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeometryDoublePolygon = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeometryDoublePolygon = function() {
  return this.setGeometryDoublePolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeometryDoublePolygon = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point geography_point = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeographyPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeographyPoint = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeographyPoint = function() {
  return this.setGeographyPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeographyPoint = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Path geography_path = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeographyPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeographyPath = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeographyPath = function() {
  return this.setGeographyPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeographyPath = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Polygon geography_polygon = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.getGeographyPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
*/
proto.vivacity.config_v2.TestChildEntity.prototype.setGeographyPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestChildEntity} returns this
 */
proto.vivacity.config_v2.TestChildEntity.prototype.clearGeographyPolygon = function() {
  return this.setGeographyPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestChildEntity.prototype.hasGeographyPolygon = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentEntityForeignKeyId: (f = msg.getParentEntityForeignKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    referenceEntityForeignKeyId: (f = msg.getReferenceEntityForeignKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    childEntityReferenceKeyId: (f = msg.getChildEntityReferenceKeyId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    int32Value: (f = msg.getInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    int64Value: (f = msg.getInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.toObject(includeInstance, f),
    uInt32Value: (f = msg.getUInt32Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    uInt64Value: (f = msg.getUInt64Value()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.toObject(includeInstance, f),
    boolValue: (f = msg.getBoolValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    stringValue: (f = msg.getStringValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    jsonValue: (f = msg.getJsonValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    enumValueId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    enumValueString: jspb.Message.getFieldWithDefault(msg, 15, 0),
    enumValueTimescale: jspb.Message.getFieldWithDefault(msg, 16, 0),
    uuidValue: (f = msg.getUuidValue()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    geometryIntegerPoint: (f = msg.getGeometryIntegerPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.toObject(includeInstance, f),
    geometryIntegerPath: (f = msg.getGeometryIntegerPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    geometryIntegerPolygon: (f = msg.getGeometryIntegerPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.toObject(includeInstance, f),
    geometryDoublePoint: (f = msg.getGeometryDoublePoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.toObject(includeInstance, f),
    geometryDoublePath: (f = msg.getGeometryDoublePath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    geometryDoublePolygon: (f = msg.getGeometryDoublePolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.toObject(includeInstance, f),
    geographyPoint: (f = msg.getGeographyPoint()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    geographyPath: (f = msg.getGeographyPath()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.toObject(includeInstance, f),
    geographyPolygon: (f = msg.getGeographyPolygon()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity;
  return proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setParentEntityForeignKeyId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setReferenceEntityForeignKeyId(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setChildEntityReferenceKeyId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setInt32Value(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader);
      msg.setInt64Value(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUInt32Value(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader);
      msg.setUInt64Value(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setBoolValue(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setStringValue(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setJsonValue(value);
      break;
    case 14:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueId(value);
      break;
    case 15:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueString(value);
      break;
    case 16:
      var value = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (reader.readEnum());
      msg.setEnumValueTimescale(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setUuidValue(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader);
      msg.setGeometryIntegerPoint(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setGeometryIntegerPath(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader);
      msg.setGeometryIntegerPolygon(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader);
      msg.setGeometryDoublePoint(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setGeometryDoublePath(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader);
      msg.setGeometryDoublePolygon(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setGeographyPoint(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader);
      msg.setGeographyPath(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader);
      msg.setGeographyPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentEntityForeignKeyId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getReferenceEntityForeignKeyId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getChildEntityReferenceKeyId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInt32Value();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getInt64Value();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Value();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt64Value();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getBoolValue();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStringValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getJsonValue();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getUuidValue();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPoint();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPath();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPolygon();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePoint();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePath();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePolygon();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPoint();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPath();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPolygon();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id parent_entity_foreign_key__id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getParentEntityForeignKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setParentEntityForeignKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearParentEntityForeignKeyId = function() {
  return this.setParentEntityForeignKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasParentEntityForeignKeyId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.ForeignKey.Id reference_entity_foreign_key__id = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getReferenceEntityForeignKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ForeignKey.Id, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setReferenceEntityForeignKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearReferenceEntityForeignKeyId = function() {
  return this.setReferenceEntityForeignKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasReferenceEntityForeignKeyId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.ReferenceKey.Id child_entity_reference_key__id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getChildEntityReferenceKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.ReferenceKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setChildEntityReferenceKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearChildEntityReferenceKeyId = function() {
  return this.setChildEntityReferenceKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasChildEntityReferenceKeyId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.FloatValue float_value = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getFloatValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.FloatValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.DoubleValue double_value = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getDoubleValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.DoubleValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setDoubleValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearDoubleValue = function() {
  return this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.Int32Value int32_value = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int32Value, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearInt32Value = function() {
  return this.setInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.Int64Value int64_value = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Int64Value, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearInt64Value = function() {
  return this.setInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value u_int32_value = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getUInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setUInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearUInt32Value = function() {
  return this.setUInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasUInt32Value = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.UInt64Value u_int64_value = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getUInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt64Value, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setUInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearUInt64Value = function() {
  return this.setUInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasUInt64Value = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue bool_value = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getBoolValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setBoolValue = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearBoolValue = function() {
  return this.setBoolValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.StringValue string_value = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getStringValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setStringValue = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearStringValue = function() {
  return this.setStringValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp_value = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getTimestampValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setTimestampValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue json_value = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getJsonValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setJsonValue = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearJsonValue = function() {
  return this.setJsonValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasJsonValue = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_id = 14;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getEnumValueId = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setEnumValueId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearEnumValueId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasEnumValueId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_string = 15;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getEnumValueString = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setEnumValueString = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearEnumValueString = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasEnumValueString = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TestParentEntity.Enum enum_value_timescale = 16;
 * @return {!proto.vivacity.config_v2.TestParentEntity.Enum}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getEnumValueTimescale = function() {
  return /** @type {!proto.vivacity.config_v2.TestParentEntity.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vivacity.config_v2.TestParentEntity.Enum} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setEnumValueTimescale = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearEnumValueTimescale = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasEnumValueTimescale = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue uuid_value = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getUuidValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setUuidValue = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearUuidValue = function() {
  return this.setUuidValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasUuidValue = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Point geometry_integer_point = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryIntegerPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Point, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryIntegerPoint = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryIntegerPoint = function() {
  return this.setGeometryIntegerPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryIntegerPoint = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Path geometry_integer_path = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryIntegerPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Path, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryIntegerPath = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryIntegerPath = function() {
  return this.setGeometryIntegerPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryIntegerPath = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.GeometryInteger.Polygon geometry_integer_polygon = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryIntegerPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryInteger.Polygon, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryIntegerPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryIntegerPolygon = function() {
  return this.setGeometryIntegerPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryIntegerPolygon = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Point geometry_double_point = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryDoublePoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Point, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryDoublePoint = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryDoublePoint = function() {
  return this.setGeometryDoublePoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryDoublePoint = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Path geometry_double_path = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryDoublePath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Path, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryDoublePath = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryDoublePath = function() {
  return this.setGeometryDoublePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryDoublePath = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.GeometryDouble.Polygon geometry_double_polygon = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeometryDoublePolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.GeometryDouble.Polygon, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeometryDoublePolygon = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeometryDoublePolygon = function() {
  return this.setGeometryDoublePolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeometryDoublePolygon = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point geography_point = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeographyPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeographyPoint = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeographyPoint = function() {
  return this.setGeographyPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeographyPoint = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Path geography_path = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeographyPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Path, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeographyPath = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeographyPath = function() {
  return this.setGeographyPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeographyPath = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Polygon geography_polygon = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.getGeographyPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Polygon, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
*/
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.setGeographyPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity} returns this
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.clearGeographyPolygon = function() {
  return this.setGeographyPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TestParentEntityToTestReferenceEntity.prototype.hasGeographyPolygon = function() {
  return jspb.Message.getField(this, 26) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
