export * from "./dtfParsing";
export * from "./trackHeadDisplayFunc.enum";

export type VPID = number;
export type VPIDStr = string;
export type ProjectId = string;
export type SensorID = number;
export type CVRunID = number;
export type CVRunIDStr = string;
export type ValidationRunID = number;
export type ValidationRunIDStr = string;
export type ValidationRunIDArray = number[];
export type researchGrade = "DO_NOT_USE" | "UNREVIEWED" | "ACCEPTABLE" | "GOLD_STANDARD";
export type CountlineID = number;
export type CountlineIDStr = string;
export type CountlineValidationRunID = string; // Composite, made by combination of ValidationRunID and CountlineID with a hyphen in between
export type ComputerVisionRunID = number;
export type VideoID = number;
export type VideoIDStr = string;
export type ZoneID = number;
export type FrameNumber = number;
export type FrameNumberStr = string;
export type TrackID = number;
export type SnappedVideoTime = number;
export type SupermarioImage = string;
export type TrackPair = string; // Composite, made by combination of two tracks with a hyphen in between
export type TurningMovementStartZoneStr = string;
export type TurningMovementStartZone = number;
export type TurningMovementEndZone = number;

export interface FrameInterval {
  start: FrameNumber;
  end: FrameNumber;
}
export interface VPIDInfo {
  id: VPID;
  hardwareID: number;
  project: string;
  sensorNumber: number;
  cameraNumber: number;
  isNearMissConfigured: boolean;
  enableTesting: boolean;
  modifiedAt: Date;
}

export const VPIDInfoSearchableFields: { [fieldName in keyof VPIDInfo]: boolean } = {
  id: true,
  hardwareID: true,
  sensorNumber: true,
  cameraNumber: true,
  project: true,
  isNearMissConfigured: false,
  enableTesting: false,
  modifiedAt: false,
};
