import React, { FC } from "react";
import { observer } from "mobx-react";
import Polygon from "./Polygon/index";
import { ZoneID } from "../../../domain";
import { VPZone } from "../../../interfaces";
import { Point } from "../../../interfaces/hydration";

interface NearMissZonesProps {
  vpZones: Map<ZoneID, VPZone>;
  videoWidth: number;
  videoHeight: number;
}

export const NearMissZones: FC<NearMissZonesProps> = observer(({ vpZones, videoHeight, videoWidth }) => {
  const maskAlpha = 0.5;
  const maskColour = 0x444445;
  const nearMissZones = Array.from(vpZones)
    .map(([_, zone]) => zone)
    .filter(zone => zone.isNearMiss);
  return (
    <>
      {nearMissZones.map(zone => {
        return (
          <Polygon
            key={zone.id}
            points={geometryToPoints(zone.geometry, videoWidth, videoHeight)}
            fillColor={maskColour}
            fillAlpha={maskAlpha}
            lineColor={0x000000}
            lineWidth={3}
            playerWidth={videoWidth}
            playerHeight={videoHeight}
          />
        );
      })}
    </>
  );
});

const geometryToPoints = (geometry: Point[], width: number, height: number) => {
  const points: number[] = [];
  geometry
    .map(point => denormalisePoint(point, width, height))
    .forEach(point => {
      points.push(point.x);
      points.push(point.y);
    });
  return points;
};

const denormalisePoint = (point: Point, width: number, height: number): Point => {
  const { x, y } = point;
  return {
    x: (x * width) / 16384,
    y: (y * height) / 16384,
  };
};
