import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { FC, useState } from "react";
import { FreneticityButton } from "../styledElements";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/useStores.hook";
import { researchGrade } from "../../domain";
import BiotechIcon from "@mui/icons-material/Biotech";

interface ResearchActionsModalsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ResearchActionsModal: FC<ResearchActionsModalsProps> = observer(({ isOpen, onClose }) => {
  const { validationRunStore } = useStores();
  type fetchResponseType = "not_saving" | "success" | "warning" | "error";

  const [gradeToSend, setGradeToSend] = useState<researchGrade>("UNREVIEWED");
  const [validationRunIds, setValidationRunIds] = useState<string>("");
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingType, setSavingType] = useState<fetchResponseType>("not_saving");

  const handleInputValidation = (inputText: string) => {
    setValidationRunIds(inputText);
    setIsInputValid(
      inputText
        .split(",")
        .map(entry => isNaN(Number(entry)))
        .includes(true)
    );
  };

  const handleUpdateResearchGrades = async () => {
    setIsSaving(true);

    const toUpload = validationRunIds.split(",").map(entry => parseInt(entry, 10));
    const stringOfSuccessfulValidationRunIds = await validationRunStore.updateValidationRunResearchGrade(
      toUpload,
      gradeToSend
    );
    const arrayOfSuccessfulValidationRunIds = stringOfSuccessfulValidationRunIds
      ?.substring(1, stringOfSuccessfulValidationRunIds.length - 1)
      .split(",");

    const validationRunIdsWhichFailed = toUpload.filter(
      value => !arrayOfSuccessfulValidationRunIds?.includes(value.toString(10))
    );

    if (validationRunIdsWhichFailed.length === 0) {
      setSavingType("success");
      setValidationRunIds("");
    } else if (validationRunIdsWhichFailed.length !== toUpload.length) {
      setSavingType("warning");
      setValidationRunIds(validationRunIdsWhichFailed.join(", "));
    } else {
      setSavingType("error");
    }

    setIsSaving(false);
  };

  return (
    <Dialog open={isOpen} maxWidth={"sm"} fullWidth={true} onClose={onClose}>
      <DialogTitle>
        <h4 style={{ marginTop: "16px", marginBottom: "16px" }}>
          <BiotechIcon fontSize="large" color="primary" /> Reasearch Actions
        </h4>
      </DialogTitle>
      <DialogContent>
        <h5 style={{ marginTop: "0px" }}>Bulk Edit Research Grades</h5>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Tooltip title={"Comma seperated list e.g 123, 456"} arrow>
              <TextField
                label="Validation Run ID's"
                variant="outlined"
                fullWidth
                required
                value={validationRunIds}
                onChange={listOfIds => handleInputValidation(listOfIds.target.value)}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Select
              value={gradeToSend}
              onChange={changedInput => setGradeToSend(changedInput.target.value as researchGrade)}
            >
              <MenuItem value={"UNREVIEWED"}>Unreviewed</MenuItem>
              <MenuItem value={"DO_NOT_USE"}>Do Not Use</MenuItem>
              <MenuItem value={"ACCEPTABLE"}>Acceptable</MenuItem>
              <MenuItem value={"GOLD_STANDARD"}>Gold Standard</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FreneticityButton disabled={isInputValid || isSaving} onClick={async () => handleUpdateResearchGrades()}>
          Save
        </FreneticityButton>
        <FreneticityButton onClick={onClose}>Close</FreneticityButton>
      </DialogActions>
      <Snackbar open={savingType !== "not_saving"} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => {
            setSavingType("not_saving");
          }}
          severity={savingType !== "not_saving" ? savingType : "info"}
          sx={{ width: "100%" }}
        >
          {savingType === "error" && "Unable to save research grades"}
          {savingType === "warning" && `Validation Run IDs ${validationRunIds} are not valid`}
          {savingType === "success" && "Success saving research grades"}
        </Alert>
      </Snackbar>
    </Dialog>
  );
});
