import { CustomPIXIComponent, applyDisplayObjectProps } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";

export interface PolygonProperties extends Partial<PIXI.Graphics> {
  points: number[];
  fillColor?: number;
  fillAlpha?: number;
  lineColor: number;
  lineWidth: number;
  lineAlpha?: number;
  fillOuter?: boolean;
  playerWidth?: number;
  playerHeight?: number;
  onClick?: () => void;
}

export default CustomPIXIComponent(
  {
    customDisplayObject: () => new PIXI.Graphics(),
    customApplyProps: function (
      instance: PIXI.Graphics,
      oldProps: PolygonProperties | undefined,
      newProps: PolygonProperties
    ) {
      const {
        points,
        fillColor,
        fillAlpha,
        lineColor,
        lineWidth,
        lineAlpha,
        fillOuter,
        playerWidth,
        playerHeight,
        onClick,
        ...newPropsRest
      } = newProps;

      function onButtonDown() {
        if (onClick) {
          onClick();
        }
      }

      if (oldProps !== undefined) {
        instance.clear();
      }

      if (fillColor) {
        instance.beginFill(fillColor, fillAlpha);
      }

      instance.lineStyle(lineWidth, lineColor, lineAlpha === undefined ? 1 : lineAlpha, 1, true);
      if (!fillOuter) {
        instance.drawPolygon(points);
      } else if (playerWidth && playerHeight) {
        instance.moveTo(0, 0);
        instance.lineTo(playerWidth, 0);
        instance.lineTo(playerWidth, playerHeight);
        instance.lineTo(0, playerHeight);
        instance.lineTo(0, 0);
        instance.beginHole();
        instance.drawPolygon(points);
        instance.endHole();
        instance.endFill();
      }

      if (fillColor) {
        instance.endFill();
      }
      instance.interactive = true;
      instance.cursor = "pointer";
      instance.on("mousedown", onButtonDown);

      if (oldProps) {
        const {
          points: oldPoints,
          fillColor: oldFill,
          lineColor: oldLineColour,
          lineWidth: oldLineWidth,
          ...oldPropsRest
        } = oldProps;

        applyDisplayObjectProps(instance.name, instance, oldProps, newProps);
      }
    },
  },
  "Polygon"
);
