import { action, computed, makeObservable, observable } from "mobx";
import {
  CVRunID,
  VideoID,
  VPID,
  VPIDInfo,
  ComputerVisionRunID,
  CountlineID,
  VPIDInfoSearchableFields,
} from "../domain";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";
import {
  countlineValidationRunHydrationToCountlineValidationRun,
  cvRunHydrationToCVRun,
  validationRunHydrationToValidationRun,
  videoHydrationToVideo,
  zoneHydrationToVPZone,
} from "../interfaces/hydration/utils";
import {
  assertVPHydrationWrapper,
  assertVPIDListHydration,
  assertValidationRunsHydration,
} from "../interfaces/hydration/type-assertions";
import {
  ComputerVisionRun,
  ComputerVisionRunSearchableFields,
  ValidationRun,
  ValidationRunSearchableFields,
  Video,
  VideoSearchableFields,
} from "../interfaces";
import { getApiEndpoint, ApiEndpointPath } from "./apiStore";
import { handleNon200 } from "./utils/promiseHandlers";
import { errString } from "./utils/errorString";
import { getFilteredResults } from "./utils/filterUtils";

export class EntitiesStore extends ChildStore {
  @observable public hydrationErrors: string;
  @observable public vpidList: VPIDInfo[] = [];
  @observable public videosByVPID: Map<VPID, Set<VideoID>> = new Map<VPID, Set<VideoID>>();
  public hydratedVPIDs: Map<VPID, boolean> = new Map<VPID, boolean>();

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.hydrationErrors = "";
    makeObservable(this);
  }

  init() {
    this.fetchAllVPIDs();
  }

  @action.bound fetchAllVPIDs() {
    this.rootStore.apiStore
      .authenticatedFetch(getApiEndpoint(ApiEndpointPath.ALL_VISION_PROGRAMS))
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(
        action(async (data: object) => {
          const vpidList = assertVPIDListHydration(data);

          for (const vpidStr of Object.keys(vpidList)) {
            const vpid = parseInt(vpidStr, 10);
            const info = vpidList[vpidStr];
            this.vpidList.push({
              modifiedAt: new Date(info.modified_at),
              cameraNumber: info.camera_number,
              hardwareID: info.hardware_id,
              id: vpid,
              project: info.project_name,
              sensorNumber: info.sensor_number,
              isNearMissConfigured: info.is_near_miss_configured || false,
              enableTesting: info.enable_testing,
            });
          }
        })
      )

      .catch(action(err => this.setHydrationErrors("Could not fetchAllVPIDs(): " + errString(err))));
  }

  @action.bound
  async startValidationRun(
    computer_vision_run_id: ComputerVisionRunID,
    omitted_image_space_countline_ids?: CountlineID[]
  ): Promise<number | undefined> {
    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(computer_vision_run_id);
    if (!cvRun) {
      throw new Error(`Could not find CV run with ID ${computer_vision_run_id} in stores...`);
    }
    let ValidationRunIDToReturn: number | undefined;
    const omitted_countline_ids = omitted_image_space_countline_ids ? omitted_image_space_countline_ids.toString() : "";
    await this.rootStore.apiStore
      .authenticatedFetch(
        `${getApiEndpoint(ApiEndpointPath.START_VALIDATION_RUN)}?${new URLSearchParams({
          computer_vision_run_id: computer_vision_run_id.toString(10),
          omitted_image_space_countline_ids: omitted_countline_ids,
        }).toString()}`,
        { method: "POST" }
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(
        action(async (data: object) => {
          const validationRunHydrationObj = assertValidationRunsHydration(data);
          const validationRunIDStr = Object.keys(validationRunHydrationObj)[0];
          const validationRunID = parseInt(validationRunIDStr, 10);
          ValidationRunIDToReturn = validationRunID;
          const validationRunHydration = validationRunHydrationObj[validationRunIDStr];
          if (validationRunHydration.countline_validation_runs) {
            for (const countlineIDStr of Object.keys(validationRunHydration.countline_validation_runs)) {
              const countlineID = parseInt(countlineIDStr, 10);
              const countlineValidationRunHydration = validationRunHydration.countline_validation_runs[countlineIDStr];
              const countlineValidationRun = countlineValidationRunHydrationToCountlineValidationRun(
                countlineValidationRunHydration,
                validationRunID,
                countlineID
              );
              if (omitted_image_space_countline_ids?.includes(countlineID)) {
                countlineValidationRun.status = "OMITTED";
              }
              this.rootStore.countlineValidationRunStore.setCountlineValidationRun(
                countlineValidationRun.id,
                countlineValidationRun
              );
            }
          }
          this.rootStore.validationRunStore.setValidationRun(validationRunID, {
            ...validationRunHydrationToValidationRun(
              validationRunHydration,
              validationRunID,
              computer_vision_run_id,
              cvRun.videoID,
              cvRun.vpid
            ),
            thumbnail: cvRun.thumbnail,
            cvRunID: computer_vision_run_id,
          });
          if (!cvRun.validationRuns) {
            cvRun.validationRuns = [validationRunID];
          } else {
            cvRun.validationRuns.push(validationRunID);
          }
        })
      )
      .catch(action(err => this.setHydrationErrors("Could not startValidationRun(): " + errString(err))));
    return ValidationRunIDToReturn;
  }

  @action.bound
  async cloneValidation(
    computer_vision_run_id: ComputerVisionRunID,
    cloning_validation_run_id: ComputerVisionRunID,
    cloning_image_space_countline_ids: CountlineID[]
  ) {
    const cvRun = this.rootStore.cvRunStore.computerVisionRuns.get(computer_vision_run_id);
    if (!cvRun) {
      throw new Error(`Could not find CV run with ID ${computer_vision_run_id} in stores...`);
    }
    let ValidationRunIDToReturn: number | undefined;
    await this.rootStore.apiStore
      .authenticatedFetch(
        `${getApiEndpoint(ApiEndpointPath.CLONE_VALIDATION)}?${new URLSearchParams({
          computer_vision_run_id: computer_vision_run_id.toString(10),
          cloning_validation_run_id: cloning_validation_run_id.toString(10),
          cloning_image_space_countline_ids: cloning_image_space_countline_ids.toString(),
        }).toString()}`,
        { method: "POST" }
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(
        action(async (data: object) => {
          const validationRunHydrationObj = assertValidationRunsHydration(data);
          const validationRunIDStr = Object.keys(validationRunHydrationObj)[0];
          const validationRunID = parseInt(validationRunIDStr, 10);
          ValidationRunIDToReturn = validationRunID;
          const validationRunHydration = validationRunHydrationObj[validationRunIDStr];
          if (validationRunHydration.countline_validation_runs) {
            for (const countlineIDStr of Object.keys(validationRunHydration.countline_validation_runs)) {
              const countlineID = parseInt(countlineIDStr, 10);
              const countlineValidationRunHydration = validationRunHydration.countline_validation_runs[countlineIDStr];
              const countlineValidationRun = countlineValidationRunHydrationToCountlineValidationRun(
                countlineValidationRunHydration,
                validationRunID,
                countlineID
              );
              this.rootStore.countlineValidationRunStore.setCountlineValidationRun(
                countlineValidationRun.id,
                countlineValidationRun
              );
            }
          }
          this.rootStore.validationRunStore.setValidationRun(validationRunID, {
            ...validationRunHydrationToValidationRun(
              validationRunHydration,
              validationRunID,
              computer_vision_run_id,
              cvRun.videoID,
              cvRun.vpid
            ),
            cvRunID: computer_vision_run_id,
          });
        })
      )
      .catch(action(err => this.setHydrationErrors("Could not cloneValidation(): " + errString(err))));
    return ValidationRunIDToReturn;
  }

  @action.bound
  async fetchMetadataForVPID(id: VPID) {
    await this.rootStore.apiStore
      .authenticatedFetch(
        `${getApiEndpoint(ApiEndpointPath.VISION_PROGRAM)}?${new URLSearchParams({
          vp_id: id.toString(10),
        }).toString()}`
      )
      .then(handleNon200)
      .then(async resp => resp.json())
      .then(
        action(async (data: object) => {
          const hydrationData = assertVPHydrationWrapper(data);
          for (const vpidStr of Object.keys(hydrationData)) {
            const vpid = parseInt(vpidStr, 10);
            const vpHydration = hydrationData[vpidStr];

            if (vpHydration.videos) {
              for (const videoIDStr of Object.keys(vpHydration.videos)) {
                const videoID = parseInt(videoIDStr, 10);
                const videoHydration = vpHydration.videos[videoIDStr];

                this.rootStore.videoStore.setVideo(videoID, {
                  ...videoHydrationToVideo(videoHydration, videoID, vpid),
                  vpid: vpid,
                });

                this.updateVideoByVPID(vpid, videoID);

                if (videoHydration.computer_vision_runs) {
                  for (const cvRunIDStr of Object.keys(videoHydration.computer_vision_runs)) {
                    const cvRunID = parseInt(cvRunIDStr, 10);
                    const cvRunHydration = videoHydration.computer_vision_runs[cvRunIDStr];
                    this.rootStore.cvRunStore.setComputerVisionRunById(cvRunID, {
                      ...cvRunHydrationToCVRun(cvRunHydration, cvRunID, videoID, vpid, videoHydration.trigger_reason),
                      vpid: vpid,
                      videoID: videoID,
                    });

                    if (cvRunHydration.validation_runs) {
                      for (const validationRunIDStr of Object.keys(cvRunHydration.validation_runs)) {
                        const validationRunID = parseInt(validationRunIDStr, 10);
                        const validationRunHydration = cvRunHydration.validation_runs[validationRunIDStr];
                        this.rootStore.validationRunStore.setValidationRun(validationRunID, {
                          ...validationRunHydrationToValidationRun(
                            validationRunHydration,
                            validationRunID,
                            cvRunID,
                            videoID,
                            vpid
                          ),
                          vpid: vpid,
                          videoID: videoID,
                          cvRunID: cvRunID,
                        });

                        if (validationRunHydration.countline_validation_runs) {
                          for (const countlineIDStr of Object.keys(validationRunHydration.countline_validation_runs)) {
                            const countlineID = parseInt(countlineIDStr, 10);
                            const countlineValidationRunHydration =
                              validationRunHydration.countline_validation_runs[countlineIDStr];

                            const countlineValidationRun = countlineValidationRunHydrationToCountlineValidationRun(
                              countlineValidationRunHydration,
                              validationRunID,
                              countlineID
                            );
                            this.rootStore.countlineValidationRunStore.setCountlineValidationRun(
                              countlineValidationRun.id,
                              countlineValidationRun
                            );
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (vpHydration.zones) {
              for (const zoneIdString of Object.keys(vpHydration.zones)) {
                const zoneId = parseInt(zoneIdString, 10);
                const zoneHydration = vpHydration.zones[zoneIdString];
                this.rootStore.zoneStore.setZone(zoneId, { ...zoneHydrationToVPZone(zoneHydration, vpid) });
              }
            }
            this.hydratedVPIDs.set(vpid, true);
          }
        })
      )
      .catch(action(err => this.setHydrationErrors("Could not fetchMetadataForVPID(): " + errString(err))));
  }

  @action.bound updateVideoByVPID(vpid: VPID, videoID: VideoID) {
    const videosAlreadyAdded = this.videosByVPID.get(vpid);
    if (videosAlreadyAdded) {
      videosAlreadyAdded.add(videoID);
    } else {
      this.videosByVPID.set(vpid, new Set([videoID]));
    }
  }

  @action
  setHydrationErrors(err: string) {
    console.error("FECK", err);
    this.hydrationErrors = err;
  }

  /**
   * Getters
   */
  @computed
  get videos(): Video[] {
    if (this.rootStore.urlStore.selectedVisionProgram) {
      return this.getVideosForVPID(this.rootStore.urlStore.selectedVisionProgram);
    } else {
      // TODO: hook videos with incomplete validations
      return [];
    }
  }

  @computed
  get filteredVideos(): Video[] {
    const searchString = this.rootStore.urlStore.search;

    if (!searchString) {
      return this.videos;
    }

    return getFilteredResults(this.videos, searchString, VideoSearchableFields);
  }

  @computed
  get computerVisionRuns(): ComputerVisionRun[] {
    if (this.rootStore.urlStore.selectedVideo) {
      return this.getComputerVisionRunsForVideoID(this.rootStore.urlStore.selectedVideo);
    } else if (this.rootStore.urlStore.selectedVisionProgram) {
      return this.getComputerVisionRunsForVPID(this.rootStore.urlStore.selectedVisionProgram);
    }
    return [];
  }

  @computed
  get filteredComputerVisionRuns(): ComputerVisionRun[] {
    const searchString = this.rootStore.urlStore.search;

    if (!searchString) {
      return this.computerVisionRuns;
    }

    return getFilteredResults(this.computerVisionRuns, searchString, ComputerVisionRunSearchableFields);
  }

  @computed
  get unvalidatedComputerVisionRuns(): ComputerVisionRun[] {
    const computerVisionRuns: ComputerVisionRun[] = [];
    const retrievedRuns = this.rootStore.cvRunStore.computerVisionRunsByIds(
      this.rootStore.cvRunStore.unvalidatedComputerVisionRuns
    );
    retrievedRuns.forEach(run => {
      if (run !== undefined) {
        computerVisionRuns.push(run);
      }
    });
    return computerVisionRuns;
  }

  @computed
  get filteredUnvalidatedComputerVisionRuns(): ComputerVisionRun[] {
    const searchString = this.rootStore.urlStore.search;

    if (!searchString) {
      return this.unvalidatedComputerVisionRuns;
    }

    return getFilteredResults(this.unvalidatedComputerVisionRuns, searchString, ComputerVisionRunSearchableFields);
  }

  @computed
  get validationRuns(): ValidationRun[] {
    if (this.rootStore.urlStore.selectedComputerVisionRun) {
      return this.getValidationRunsForCVRunID(this.rootStore.urlStore.selectedComputerVisionRun);
    } else if (this.rootStore.urlStore.selectedVideo) {
      return this.getValidationRunsForVideoID(this.rootStore.urlStore.selectedVideo);
    } else if (this.rootStore.urlStore.selectedVisionProgram) {
      return this.getValidationRunsForVPID(this.rootStore.urlStore.selectedVisionProgram);
    }
    return [];
  }

  @computed
  get filteredValidationRuns(): ValidationRun[] {
    const searchString = this.rootStore.urlStore.search;

    if (!searchString) {
      return this.validationRuns;
    }

    return getFilteredResults(this.validationRuns, searchString, ValidationRunSearchableFields);
  }

  getVideosForVPID = (vpid: VPID): Video[] => {
    const videos: Video[] = [];
    this.videosByVPID.get(vpid)?.forEach(videoID => {
      const video = this.rootStore.videoStore.videos.get(videoID);
      if (video) {
        videos.push(video);
      }
    });
    return videos;
  };

  @computed
  get filteredVisionPrograms(): VPIDInfo[] {
    const searchString = this.rootStore.urlStore.search;

    if (!searchString) {
      return this.vpidList;
    }

    return getFilteredResults(this.vpidList, searchString, VPIDInfoSearchableFields);
  }

  getComputerVisionRunsForVPID = (vpid: VPID): ComputerVisionRun[] => {
    const computerVisionRuns: ComputerVisionRun[] = [];
    this.videosByVPID.get(vpid)?.forEach(videoID => {
      computerVisionRuns.push(...this.getComputerVisionRunsForVideoID(videoID));
    });
    return computerVisionRuns;
  };

  getComputerVisionRunsForVideoID = (videoID: VideoID): ComputerVisionRun[] => {
    const computerVisionRuns: ComputerVisionRun[] = [];
    const video = this.rootStore.videoStore.videos.get(videoID);
    video?.computerVisionRuns?.forEach(cvRunID => {
      const computerVisionRun = this.rootStore.cvRunStore.computerVisionRuns.get(cvRunID);
      if (computerVisionRun) {
        computerVisionRuns.push(computerVisionRun);
      }
    });
    return computerVisionRuns;
  };

  getValidationRunsForVPID = (vpid: VPID): ValidationRun[] => {
    const validationRuns: ValidationRun[] = [];
    this.videosByVPID.get(vpid)?.forEach(videoID => {
      validationRuns.push(...this.getValidationRunsForVideoID(videoID));
    });
    return validationRuns;
  };

  getValidationRunsForVideoID = (videoID: VideoID): ValidationRun[] => {
    const validationRuns: ValidationRun[] = [];
    const video = this.rootStore.videoStore.videos.get(videoID);
    video?.computerVisionRuns?.forEach(cvRunID => {
      validationRuns.push(...this.getValidationRunsForCVRunID(cvRunID));
    });
    return validationRuns;
  };

  getValidationRunsForCVRunID = (cvRunID: CVRunID): ValidationRun[] => {
    const validationRuns: ValidationRun[] = [];
    const computerVisionRun = this.rootStore.cvRunStore.computerVisionRuns.get(cvRunID);
    if (!computerVisionRun) {
      return validationRuns;
    }
    computerVisionRun?.validationRuns?.forEach(validationRunID => {
      const validationRun = this.rootStore.validationRunStore.validationRuns.get(validationRunID);
      if (validationRun && validationRun.deletedAt === undefined) {
        validationRuns.push(validationRun);
      }
    });
    return validationRuns;
  };
}
