import React, { FC } from "react";

import { Container, Sprite, Stage } from "react-pixi-fiber/index";
import { observer } from "mobx-react";
import { PlayerUIStore } from "../../../stores/playerUI.store";

type VideoCropProps = {
  playerUIStore: PlayerUIStore;
};

const emptyStage = (
  <Stage
    style={{ width: 0 + "px", height: 0 + "px" }}
    options={{
      antialias: true,
      backgroundColor: 0xf4f7ff,
      width: 0,
      height: 0,
    }}
  >
    <Container>
      <Sprite x={0} y={0} />
    </Container>
  </Stage>
);

export const VideoCrop: FC<VideoCropProps> = observer(({ playerUIStore }) => {
  const cvCrossing = playerUIStore.lastClickedCVCrossingForPopper;
  if (!cvCrossing) {
    return emptyStage;
  }

  const boundingBoxes = playerUIStore.boundingBoxesByFrame.get(playerUIStore.currentFrameNumber);
  if (!boundingBoxes) {
    return emptyStage;
  }
  const bbox = boundingBoxes.find(box => box.trackID === cvCrossing.trackNumber);

  if (!bbox) {
    return emptyStage;
  }

  const paddingX = bbox.width * 0.2;
  const paddingY = bbox.height * 0.2;

  const bboxX = Math.max(bbox.x - paddingX / 2, 0);
  const bboxY = Math.max(bbox.y - paddingY / 2, 0);
  let bboxWidth = bbox.width + paddingX;
  let bboxHeight = bbox.height + paddingY;
  if (bboxWidth + bboxX >= playerUIStore.videoWidth) {
    bboxWidth = playerUIStore.videoWidth - bboxX;
  }
  if (bboxHeight + bboxY >= playerUIStore.videoHeight) {
    bboxHeight = playerUIStore.videoHeight - bboxY;
  }
  const aspectRatio = bboxWidth / bboxHeight;

  const width = aspectRatio > 1 ? Math.min(bboxWidth, 300) : Math.min(bboxHeight, 300) * aspectRatio;
  const height = aspectRatio > 1 ? width / aspectRatio : Math.min(bboxHeight, 300);

  return (
    <Stage
      style={{ width: width + "px", height: height + "px" }}
      options={{
        antialias: true,
        backgroundColor: 0xf4f7ff,
        width: bboxWidth,
        height: bboxHeight,
      }}
    >
      <Container>
        <Sprite
          x={-bboxX}
          y={-bboxY}
          texture={playerUIStore.pixiVideoTexture ?? undefined}
          width={playerUIStore.pixiVideoTexture?.width}
          height={playerUIStore.pixiVideoTexture?.height}
        />
      </Container>
    </Stage>
  );
});
