import { action, configure } from "mobx";
import { UrlStore } from "./url.store";
import { CVRunStore } from "./cvRun.store";
import { CountlineValidationRunStore } from "./countlineValidationRun.store";
import { VideoStore } from "./video.store";
import { ValidationRunStore } from "./validationRun.store";
import { EntitiesStore } from "./entities.store";
import { PlayerUIStore } from "./playerUI.store";
import { ApiStore } from "./apiStore";
import { RefObject } from "react";
import { NavigateFunction } from "react-router/lib/hooks";
import { ValidationCreationUIStore } from "./validationCreationUI.store";
import { WebsocketConnectionEndpoint, WebsocketConnectionStore } from "./websocketConnection.store";
import { ZoneStore } from "./zones.store";

export class RootStore {
  private static instance: RootStore;
  public navigate!: RefObject<NavigateFunction>;
  public urlStore!: UrlStore;
  public entitiesStore!: EntitiesStore;
  public cvRunStore!: CVRunStore;
  public countlineValidationRunStore!: CountlineValidationRunStore;
  public videoStore!: VideoStore;
  public zoneStore!: ZoneStore;
  public validationRunStore!: ValidationRunStore;
  public playerUIStore!: PlayerUIStore;
  public apiStore!: ApiStore;
  public validationCreationUIStore!: ValidationCreationUIStore;
  public cvRunProgressUpdatesWebsocketConnectionStore!: WebsocketConnectionStore;
  public cvRunCreateWebsocketConnectionStore!: WebsocketConnectionStore;
  public videoStatusWebsocketConnectionStore!: WebsocketConnectionStore;

  private constructor(navigateFunction: RefObject<NavigateFunction>) {
    configure({
      enforceActions: "always",
      reactionRequiresObservable: true,
      computedRequiresReaction: true,
      observableRequiresReaction: true,
    });

    action(() => {
      // F***ing mobx
      this.navigate = navigateFunction;
      this.urlStore = new UrlStore(this);
      this.entitiesStore = new EntitiesStore(this);
      this.cvRunStore = new CVRunStore(this);
      this.countlineValidationRunStore = new CountlineValidationRunStore(this);
      this.videoStore = new VideoStore(this);
      this.zoneStore = new ZoneStore(this);
      this.validationRunStore = new ValidationRunStore(this);
      this.playerUIStore = new PlayerUIStore(this);
      this.apiStore = new ApiStore(this);
      this.validationCreationUIStore = new ValidationCreationUIStore(this);
      this.cvRunProgressUpdatesWebsocketConnectionStore = new WebsocketConnectionStore(
        this,
        WebsocketConnectionEndpoint.CV_RUN_PROGRESS_UPDATE
      );
      this.cvRunCreateWebsocketConnectionStore = new WebsocketConnectionStore(
        this,
        WebsocketConnectionEndpoint.CV_RUN_CREATE
      );
      this.videoStatusWebsocketConnectionStore = new WebsocketConnectionStore(
        this,
        WebsocketConnectionEndpoint.VIDEO_STATUS_UPDATE
      );
    }).bind(this)();
  }

  static async instantiate(navigateFunction: RefObject<NavigateFunction>) {
    if (RootStore.instance) {
      return RootStore.instance;
    }

    const rootStore = new RootStore(navigateFunction);
    RootStore.instance = rootStore;
    return rootStore;
  }
}
